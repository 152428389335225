import { useCallback, useEffect } from "react";
import i18next from "i18next";
import useLocalStorage from "../../../modules/common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../../modules/common/constants/Storage";
import useNotification from "../../../modules/notification/hooks/useNotification";
import { buildInfoNotification } from "../../../modules/notification/helpers/notificationFactory";
import useProcessTracker from "../../core/hooks/useProcessTracker";

const FEATURE_FLAG_ONBOARDING_TOUR = import.meta.env.VITE_FEATURE_FLAG_ONBOARDING_TOUR === "true";

export const enum ChecklistItem {
  vehicleSearch = "vehicleSearch",
  vehicleComparison = "vehicleComparison",
  dealerSearch = "dealerSearch",
  registration = "registration",
}

type Competition = {
  checklistItems: Record<ChecklistItem, boolean>;
  finished: boolean;
};

export function useCompetition() {
  const defaultValue: Competition = {
    checklistItems: {
      [ChecklistItem.vehicleSearch]: false,
      [ChecklistItem.vehicleComparison]: false,
      [ChecklistItem.dealerSearch]: false,
      [ChecklistItem.registration]: false,
    },
    finished: false,
  };

  const [competition, setCompetition] = useLocalStorage(STORAGE_KEYS.COMPETITION, defaultValue);
  const { checklistItems, finished } = competition;

  const finishCompetition = () => {
    setCompetition({ ...competition, finished: true });
    closeProcess();
  };

  const allItemsChecked = Object.values(checklistItems).every((status: boolean) => status);

  const competitionActive = !finished && FEATURE_FLAG_ONBOARDING_TOUR;
  const pushNotification = useNotification();

  const numberOfCheckedItems = Object.values(checklistItems).filter((status: boolean) => status).length;

  const notify = useCallback(
    (notifyText: string) => {
      const checkLastItem = numberOfCheckedItems === Object.keys(checklistItems).length - 1;
      // overwrite notifyText when checking off the last one

      if (checkLastItem) {
        pushNotification(
          buildInfoNotification({
            icon: "checkbook",
            text: i18next.t("CHECKLIST CHECKED ALL DONE"),
          }),
        );
      } else {
        pushNotification(buildInfoNotification({ icon: "checkbook", text: notifyText }));
      }
    },
    [checklistItems, pushNotification, numberOfCheckedItems],
  );

  const checkItem = useCallback(
    (item: ChecklistItem, notifyText: string) => {
      // abort when competitionActive not active
      if (!competitionActive) return;
      // abort when already checked
      if (checklistItems[item]) return;

      setCompetition({ ...competition, checklistItems: { ...checklistItems, [item]: true } });
      notify(notifyText);
    },
    [competition, checklistItems, competitionActive, setCompetition, notify],
  );

  const useCheckItem = (item: ChecklistItem, notifyText: string) => {
    useEffect(() => {
      checkItem(item, notifyText);
    }, [item, notifyText]);
  };

  const { closeProcess } = useProcessTracker();
  const useBackOnCompetitionNotActive = () => {
    useEffect(() => {
      if (!competitionActive) {
        closeProcess();
      }
    }, []);
  };

  return {
    checklistItems,
    allItemsChecked,
    finishCompetition,
    competitionActive,
    checkItem,
    useCheckItem,
    numberOfCheckedItems,
    useBackOnCompetitionNotActive,
  };
}
