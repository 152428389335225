import { DeepPartial } from "../common/types/DeepPartial";
import { DataLayer } from "./types/DataLayer";

export const dataLayer = (dataLayerParameters: DeepPartial<DataLayer>[] = []) => {
  window.dataLayer = window.dataLayer || [];
  const windowDataLayer = window.dataLayer;
  dataLayerParameters.forEach(parameter => {
    windowDataLayer.push(parameter);
  });
};
