import React from "react";
import i18next from "i18next";
import classNames from "classnames";
import { IMAGE_TRANSFORM_TYPES } from "../../visual-components/components/Image";
import { getVideoIdForUrl } from "../../../magazine/services/video-helper";
import Gallery from "../../visual-components/components/Gallery";
import { ImageMedia, VideoMedia } from "../../common/components/Slideshow";
import { ButtonLink } from "../../visual-components/components/Button";
import ContextMenu from "../../visual-components/components/form/ContextMenu";
import { AugmentedDetailVehicle } from "../types/vehicle";
import useGeneralInquiryLink from "../../../processes/general-inquiry/hooks/useGeneralInquiryLink";
import useVehicleCtaItems from "../hooks/useVehicleCtaItems";
import BaseTechnicalData from "./BaseTechnicalData";
import DetailHeroMetaNav from "./DetailHeroMetaNav";
import DetailHeroTiles from "./DetailHeroTiles";
import DetailHeroStickyBar from "./DetailHeroStickyBar";

type Props = {
  vehicle: AugmentedDetailVehicle;
  // todo: this is a temporary quick fix to prevent having a wrong share link on whitelabel, see: https://3.basecamp.com/3101128/buckets/25078318/todos/5687994862
  hideShare?: boolean;
  comparisonList?: {
    inList: boolean;
    toggle: (title: string, vehicleId: string) => void;
  };
  favoriteList?: {
    inList: boolean;
    toggle: (title: string, vehicleId: string) => void;
  };
  footerRef?: React.RefObject<HTMLDivElement>;
};

const DetailHero: React.FC<Props> = ({ vehicle, hideShare = false, comparisonList, favoriteList, footerRef }) => {
  const media = [
    ...vehicle.imageUrls.map(url => ({ baseSrc: url, alt: `${vehicle.brand?.name} ${vehicle.title}` })),
    // no caption available for vehicle videos
    ...vehicle.videoUrls
      .map(url => (getVideoIdForUrl(url) ? { videoId: getVideoIdForUrl(url), caption: "" } : null))
      .filter((item): item is VideoMedia => item !== null),
  ];
  const { baseSrc, alt } = (media.find(item => "baseSrc" in item && "alt" in item) as ImageMedia) ?? {
    baseSrc: "",
    alt: "",
  };
  const heroTilesRef = React.useRef<HTMLDivElement>(null);

  const buildGeneralInquiryLink = useGeneralInquiryLink();

  const { inquireItem, testDriveItem, dealerPhoneItem } = useVehicleCtaItems(vehicle);

  return (
    <section className={classNames("detail__section detail__hero", { "n-a": !vehicle.enabled })}>
      <Gallery
        hideCaption
        className="detail-hero__media"
        mainSlideshowImageSizes={IMAGE_TRANSFORM_TYPES.detailHeroImage}
        media={media}
        overlaySlideshowImageSizes={IMAGE_TRANSFORM_TYPES.detailHeroImageFullRes}
        reserved={vehicle.reserved}
        title={vehicle.title}
        overlayHeader={{
          title: vehicle.title,
          subline: vehicle.brand?.name ?? "",
        }}
      />
      {vehicle.enabled ? (
        <>
          <div className="detail-vdp__main-info">
            <header className="detail-vdp-header">
              <div className="detail-vdp__brand">{vehicle.brand?.name}</div>
              <h1 className="detail-vdp__title">{vehicle.title}</h1>
              {vehicle.reserved ? null : (
                <ContextMenu
                  icon="mail"
                  items={dealerPhoneItem ? [dealerPhoneItem, inquireItem, testDriveItem] : [inquireItem, testDriveItem]}
                  text={i18next.t("CONTACT DEALER")}
                />
              )}
              <DetailHeroMetaNav
                comparisonList={comparisonList}
                favoriteList={favoriteList}
                hideShare={hideShare}
                vehicle={vehicle}
              />
            </header>
            <DetailHeroTiles heroTilesRef={heroTilesRef} vehicle={vehicle} />
            {vehicle.reserved ? (
              <div className="detail-vdp--reserved">
                <p>{i18next.t("TEXT VEHICLE ALREADY RESERVED")}</p>
                <ButtonLink
                  secondary
                  className="link-icon"
                  icon="mail"
                  {...buildGeneralInquiryLink({
                    slug: vehicle.dealer.slug,
                    id: vehicle.dealer.insideId,
                    vehicleId: vehicle.vehicleId,
                  })}
                >
                  <span className="link-icon__text">{i18next.t("INQUIRE")}</span>
                </ButtonLink>
              </div>
            ) : null}
          </div>
          <BaseTechnicalData vehicle={vehicle} />
          {!vehicle.reserved && footerRef ? (
            <DetailHeroStickyBar
              alt={alt}
              footerRef={footerRef}
              heroTilesRef={heroTilesRef}
              imgSrc={baseSrc}
              vehicle={vehicle}
            />
          ) : null}
        </>
      ) : (
        <div className="detail-vdp__main-info">
          <header className="detail-vdp-header">
            <div className="detail-vdp__brand">{vehicle.brand?.name}</div>
            <h1 className="detail-vdp__title">{vehicle.title}</h1>
          </header>
          <div className="detail__btn-wrap">
            <div>{i18next.t("NOT AVAILABLE")}</div>
          </div>
        </div>
      )}
    </section>
  );
};

export default DetailHero;
