import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../modules/common/constants/configuration";

export type TokenResponse = {
  access_token: string;
  refresh_token: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    token: builder.query<TokenResponse, { code: string }>({
      query: ({ code }) => ({ method: "POST", url: "/token", body: { code } }),
    }),
  }),
});

export const useTokenQuery = authApi.useTokenQuery;
