import React, { ReactNode, useEffect } from "react";
import useCarousel from "../../common/hooks/useCarousel";
import CarouselArrows from "../../common/components/CarouselArrows";
import VehiclesCarouselEmpty, { VehiclesCarouselEmptyBackground } from "./VehiclesCarouselEmpty";

type Props = {
  totalItems?: number;
  children: ReactNode;
  title: string;
  emptyText?: string;
  emptyChildren?: ReactNode;
  emptyBackground?: VehiclesCarouselEmptyBackground;
  resetScrollPositionTrigger?: string;
};

const VehiclesCarousel: React.FC<Props> = ({
  totalItems = 0,
  children,
  title,
  emptyText,
  emptyChildren,
  emptyBackground,
  resetScrollPositionTrigger,
}) => {
  const { carouselScrollContainerRef, scrollPosition, next, prev, scrollTo } = useCarousel(totalItems, ".card");

  useEffect(() => {
    if (resetScrollPositionTrigger) {
      scrollTo(0);
    }
  }, [resetScrollPositionTrigger, scrollTo]);

  if (totalItems === 0) {
    return (
      <VehiclesCarouselEmpty background={emptyBackground} text={emptyText}>
        {emptyChildren}
      </VehiclesCarouselEmpty>
    );
  }

  return (
    <div className="section-carousel__carousel-outerwrap">
      <CarouselArrows
        next={next}
        nextClassName="carousel__nav carousel__nav--right"
        prev={prev}
        prevClassName="carousel__nav carousel__nav--left"
        scrollPosition={scrollPosition}
        totalItems={totalItems}
        trackingTitle={title}
      />
      <div ref={carouselScrollContainerRef} className="section-carousel__carousel-wrap">
        {children}
      </div>
    </div>
  );
};

export default VehiclesCarousel;
