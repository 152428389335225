import { HistoryState } from "../../../router/constants/State";
import usePathBuilder from "../../../router/hooks/usePathBuilder";

function useMatchmakerLink(): {
  to: string;
  state: Record<string, number>;
  rel: "nofollow";
} {
  const { matchmakerPath } = usePathBuilder();

  return {
    to: matchmakerPath(),
    state: { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 },
    rel: "nofollow",
  };
}

export default useMatchmakerLink;
