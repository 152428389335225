import { KeyboardEvent } from "react";
import { Key } from "../../common/constants/Key";

function useSelectKeyboard(onChooseFirstItem: () => boolean, onPrevNextNavigation?: (isNext: boolean) => void) {
  return {
    onKeyDown: (e: KeyboardEvent<HTMLInputElement>): boolean => {
      switch (e.key) {
        case Key.Enter:
          return onChooseFirstItem();
        case Key.Escape:
          return true;
        case Key.ArrowUp:
          onPrevNextNavigation?.(false);
          return false;
        case Key.ArrowDown:
          onPrevNextNavigation?.(true);
          return false;
      }
      return false;
    },
  };
}

export default useSelectKeyboard;
