import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { MutableRefObject, ReactNode, useEffect, useMemo, useRef } from "react";
import Locale from "../../common/constants/Locale";
import useLanguage from "../../../router/hooks/useLanguage";
import { hygraphApi } from "../../hygraph/services/hygraphApi";
import { createNullableContext } from "../../common/helpers/contextCreator";
import useEvent from "../../common/hooks/useEvent";
import useCurrentPage from "../../../router/hooks/useCurrentPage";
import { PAGE_DEFINITION } from "../../../router/constants/Page";
import { Params } from "../../../router/constants/Params";

type LanguageSwitcherCallback = (lang: Locale) => string;

const [LanguageChangeUrlBuilderContext, useLanguageChangeUrlBuilderContext] = createNullableContext<{
  switcher: MutableRefObject<LanguageSwitcherCallback | null>;
  configureSwitcher: (newSwitcher: LanguageSwitcherCallback | null) => void;
}>("LanguageChangeUrlBuilderContext");

/**
 * This component can be used to implement a custom "change language" url builder.
 * @param urlBuilder
 * @constructor
 */
export const LanguageChangeUrlBuilderInjector = ({ urlBuilder }: { urlBuilder: (lang: Locale) => string }) => {
  const { configureSwitcher } = useLanguageChangeUrlBuilderContext();
  const urlBuilderEvent = useEvent(urlBuilder);
  useEffect(() => {
    configureSwitcher(urlBuilderEvent);
    return () => {
      configureSwitcher(null);
    };
  }, [configureSwitcher, urlBuilderEvent]);
  return null;
};

export const LanguageChangeUrlBuilderContextProvider = ({ children }: { children: ReactNode }) => {
  const languageSwitcherRef = useRef<LanguageSwitcherCallback | null>(null);
  const configureSwitcher = useEvent((newLanguageSwitcher: LanguageSwitcherCallback | null) => {
    languageSwitcherRef.current = newLanguageSwitcher;
  });
  const value = useMemo(
    () => ({ switcher: languageSwitcherRef, configureSwitcher }),
    [languageSwitcherRef, configureSwitcher],
  );
  return <LanguageChangeUrlBuilderContext.Provider value={value}>{children}</LanguageChangeUrlBuilderContext.Provider>;
};

function useLanguageChange() {
  const navigate = useNavigate();
  const location = useLocation();

  const { switcher } = useLanguageChangeUrlBuilderContext();

  const locales = Object.values(Locale);

  const currentLocale = useLanguage();
  const dispatch = useDispatch();

  const currentPage = useCurrentPage();

  const changeLanguage = async (language: Locale) => {
    await i18next.changeLanguage(language);
    dispatch(hygraphApi.util.invalidateTags(["languageDependent"]));

    const newUrl = switcher?.current?.(language);
    if (newUrl) {
      navigate(newUrl, { replace: true });
    } else {
      const currentPageConfig = currentPage ? PAGE_DEFINITION[currentPage] : undefined;
      // per default assume language to be first item in path
      let langIndex = 0;
      if (currentPageConfig) {
        langIndex = currentPageConfig.route.indexOf(Params.lang);
      }
      // no language in path
      if (langIndex === -1) {
        return;
      }
      const locationParts = location.pathname.split("/");
      // index 0 corresponds to leading "/", therefore add 1
      locationParts[langIndex + 1] = language;
      navigate(locationParts.join("/"), { replace: true });
    }
  };

  return {
    currentLocale,
    locales,
    changeLanguage,
  };
}

export default useLanguageChange;
