import React, { ReactNode } from "react";
import classNames from "classnames";

type Props = {
  children: ReactNode;
  reserved: boolean;
};

const DetailHeroTileWrap: React.FC<Props> = ({ children, reserved }) => {
  return (
    <div
      className={classNames("price-tile detail-vdp__price-tile", {
        "price-tile--reserved": reserved,
      })}
    >
      {children}
    </div>
  );
};

export default DetailHeroTileWrap;
