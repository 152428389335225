import { gql } from "graphql-request";

const imageFragment = gql`
  fragment ImageParts on Image {
    caption
    altText
    wideInContent
    media {
      ... on AssetComponent {
        asset {
          id
          handle
          url
        }
      }
    }
  }
`;

export default imageFragment;
