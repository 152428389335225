import { RefObject, useEffect, useRef } from "react";
import useEvent from "./useEvent";

// calls toggle function when a click is performed outside the container ref, make sure to assign the container ref
function useClickAwayHandler<T extends Element, T2 extends Element = HTMLDivElement>(
  toggle: () => void,
  isActive: boolean,
  additionalRef?: RefObject<T2>,
): RefObject<T> {
  const container = useRef<T>(null);
  const toggleEvent = useEvent(toggle);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const listener = (e: MouseEvent) => {
      const targetNode = e.target;
      if (
        targetNode instanceof Node &&
        !container.current?.contains(targetNode) &&
        !additionalRef?.current?.contains(targetNode)
      ) {
        toggleEvent();
      }
    };

    // use mousedown in favor of click to prevent calling listener multiple times in case of checkbox click (e.g. in multi select)
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [isActive, additionalRef, toggleEvent]);
  return container;
}

export default useClickAwayHandler;
