import React from "react";
import i18next from "i18next";

type Props = {
  isReserved?: boolean;
};

const ReservedTag: React.FC<Props> = ({ isReserved }) => {
  if (isReserved) {
    return <div className="reserved-tag">{i18next.t("RESERVED")}</div>;
  }
  return null;
};

export default ReservedTag;
