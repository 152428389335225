import { FILTER_TYPE_CONFIGURATION_MAP } from "../services/filterTypeConfiguration";
import { FILTER_DEFINITIONS } from "../services/filterDefinition";
import { getConfigurationCount } from "./filterTypeHelpers";

export function getActiveAppliedFilterItems(
  appliedFilters: Record<string, string | string[]>,
): [string, string | string[]][] {
  return Array.from(Object.entries(appliedFilters))
    .filter(([, value]) => !!value)
    .filter(([queryParam, val]) => {
      return getConfigurationCount(FILTER_TYPE_CONFIGURATION_MAP[FILTER_DEFINITIONS[queryParam].type], val) > 0;
    });
}
