import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { AlgoliaRequestOptions } from "./search";

type AlgoliaFetcher<T extends AlgoliaRequestOptions> = (query: string, requestOptions: T) => Promise<any>;

export const algoliaBaseQuery =
  <T extends AlgoliaRequestOptions>(fetcher: AlgoliaFetcher<T>): BaseQueryFn<{ query: string; options: T }> =>
  async ({ query, options }) => {
    try {
      const result = await fetcher(query, options);
      return { data: result };
    } catch (error) {
      return { error };
    }
  };

type MultiIndexAlgoliaFetcher<T> = (requestOptions: T) => Promise<any>;

export const algoliaMultiIndexBaseQuery =
  <I, O>(fetcher: MultiIndexAlgoliaFetcher<I>): BaseQueryFn<I, O> =>
  async options => {
    try {
      const result = await fetcher(options);
      return { data: result };
    } catch (error) {
      return { error };
    }
  };
