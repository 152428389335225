import Locale from "../../common/constants/Locale";

export enum WarrantyType {
  FROM_TAKEOVER = 1,
  FROM_REGISTRATION = 2,
  FROM_DATE = 3,
}

export type Warranty =
  | {
      typeId: WarrantyType.FROM_TAKEOVER | WarrantyType.FROM_REGISTRATION;
      months: number;
      maxKm: number;
      date: number | null;
      text: Record<Locale, null | string>;
    }
  | {
      typeId: WarrantyType.FROM_DATE;
      date: number;
      months: number | null;
      maxKm: number | null;
      text: Record<Locale, null | string>;
    };
