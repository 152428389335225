import React from "react";
import classNames from "classnames";

import Compare from "../../../template/private/assets/img/layout/inline/icon-compare.svg?react";
import Heart from "../../../template/private/assets/img/layout/inline/icon-heart.svg?react";
import Share from "../../../template/private/assets/img/layout/inline/icon-share.svg?react";
import Printer from "../../../template/private/assets/img/layout/inline/icon-printer.svg?react";

type SvgType = "compare" | "heart" | "share" | "printer";

const SVG_MAP: Record<SvgType, React.FC> = {
  compare: Compare,
  heart: Heart,
  share: Share,
  printer: Printer,
};

type Props = {
  type: SvgType;
  className?: string;
};

const InlineSvg: React.FC<Props> = ({ type, className }) => {
  const Component = SVG_MAP[type];
  return (
    <div className={classNames("inline-svg", `inline-svg--${type}`, className)}>
      <Component />
    </div>
  );
};

export default InlineSvg;
