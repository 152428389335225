import { FilterTypeDefinition } from "../types/filterTypes";
import { GroupSelectFilterDef } from "../types/filterDefinitionTypes";
import { buildFilter } from "../../../algolia/services/buildFilter";
import { arrayLengthExtractor } from "../../helpers/arrayLengthExtractor";
import { AggregatedGroupFilterItem } from "../reference-data-aggregator/types";
import { getNormalizedFacetValue } from "../../helpers/facetApplierHelpers";
import { logger } from "../../../common/scripts/logger";
import { notNil } from "../../../common/helpers/isNil";
import getUniqueEntries from "../../../common/helpers/getUniqueEntries";
import {
  AppliedTag,
  buildGroupAppliedTagList,
  getNameForGroupedReferenceId,
  mapAppliedTagToQueryParam,
  TagType,
} from "./groupSelectFilterTypeHelpers";

export const groupSelectFilterType: FilterTypeDefinition<GroupSelectFilterDef, string[]> = {
  getConfigurationCount: arrayLengthExtractor,
  paramType: "stringArray",
  getConfigurationLabels: (val, formatters, filter, aggregatedData) =>
    val.map(tag => getNameForGroupedReferenceId(tag, filter, aggregatedData)),
  getSearchQueries: (param, filterDefinition, filterData): string[] => {
    const appliedTagList = buildGroupAppliedTagList(param);
    return [
      "(" +
        appliedTagList
          .map(tag =>
            buildFilter(
              tag.type === "c" ? filterDefinition.childFacetKey : filterDefinition.groupFacetKey,
              getNameForGroupedReferenceId(mapAppliedTagToQueryParam(tag), filterDefinition, filterData),
            ),
          )
          .join(" OR ") +
        ")",
    ];
  },
  facetMapping: {
    getKeys: (filterDef): string[] => {
      return [filterDef.groupFacetKey, filterDef.childFacetKey];
    },
    getAppliedValues: (matchingFacetFilters, appliedQueryParams, aggregatedData, filterDef): [string, string][] => {
      const facetValueTuples = matchingFacetFilters.map(facetFilter => getNormalizedFacetValue(facetFilter));

      const appliedFacetsAsTuple = appliedQueryParams.map((value): [string, string] => [filterDef.queryParam, value]);

      const newFacets = facetValueTuples
        .map((facetValueTuple): [string, string] | null => {
          const [facetKey, normalizedName] = facetValueTuple;
          const isGroupItem = facetKey === filterDef.groupFacetKey;
          const findMatchingChildItem = (item: AggregatedGroupFilterItem) =>
            item.children.find(item => item.nameNormalized === normalizedName);
          let matchesNormalizedName = (item: AggregatedGroupFilterItem): boolean =>
            findMatchingChildItem(item) !== undefined;
          if (isGroupItem) {
            matchesNormalizedName = (item: AggregatedGroupFilterItem): boolean =>
              item.nameNormalized === normalizedName;
          }
          const item = aggregatedData[filterDef.dataKey].list.find(matchesNormalizedName);
          if (!item) {
            logger.warn(`groupSelectFilterType: unknown ${filterDef.dataKey}: ${normalizedName} - ${isGroupItem}`, {
              normalizedName,
              isGroupItem,
            });
            return null;
          }

          let appliedTag: AppliedTag = { type: TagType.Group, value: item.value };

          if (!isGroupItem) {
            const childItem = findMatchingChildItem(item);
            if (childItem) {
              const isSingleChild = item.children.length === 1;
              if (isSingleChild) {
                // if single child is detected the group should be applied instead of the child
                // this ensures that rendering the applied filter will show the group name
                appliedTag = { type: TagType.Group, value: item.value };
              } else {
                appliedTag = { type: TagType.Child, value: childItem.value };
              }
            } else {
              // this should never occur
              logger.warn(`groupSelectFilterType: cannot find child item ${normalizedName}`, {
                normalizedName,
              });
            }
          }
          return [filterDef.queryParam, mapAppliedTagToQueryParam(appliedTag)];
        })
        .filter(notNil);

      return getUniqueEntries([...newFacets, ...appliedFacetsAsTuple]);
    },
  },
};
