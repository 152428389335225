import { useCallback } from "react";
import { NumberParam, QueryParamConfig, useQueryParams } from "use-query-params";

export const PAGE_QUERY_PARAM = "page";

function useFilterQueryParam(queryParam: string, queryParamConfig: QueryParamConfig<any, any>) {
  const [queryParams, setQueryParams] = useQueryParams({
    [queryParam]: queryParamConfig,
    [PAGE_QUERY_PARAM]: NumberParam,
  });
  const setQueryParam = useCallback(
    (value: any) => {
      setQueryParams({
        [PAGE_QUERY_PARAM]: undefined,
        [queryParam]: value,
      });
    },
    [setQueryParams, queryParam],
  );
  return [queryParams[queryParam], setQueryParam];
}

export default useFilterQueryParam;
