import classNames from "classnames";
import React from "react";

type Color = "gradient" | "grey" | "black" | "white";

type Props = {
  color?: Color;
  className?: string;
  children?: React.ReactNode;
};

const CLASS_BY_COLOR: Record<Color, string> = {
  gradient: "divider-gradient",
  grey: "divider-grey",
  black: "divider-black",
  white: "divider-white",
};

export const Divider: React.FC<Props> = ({ color = "gradient", className, children }) => {
  const dividerClass = CLASS_BY_COLOR[color];

  return <div className={classNames(dividerClass, className)}>{children}</div>;
};

export default Divider;
