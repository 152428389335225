import { useSelector } from "react-redux";
import { AuthInfo } from "../types";
import { selectUserId } from "../authSelectors";

function useAuth(): AuthInfo | null {
  const userId = useSelector(selectUserId);
  if (!userId) {
    return null;
  }
  return {
    userId,
  };
}

export default useAuth;
