import React from "react";
import classNames from "classnames";
import useClickAwayHandler from "../../../common/hooks/useClickAwayHandler";
import { Icon } from "../../../common/types/Icons";

type Props = {
  text: string;
  icon: Icon;
  items: {
    icon: Icon;
    label: string;
    onClick?: (ev: React.MouseEvent<HTMLSpanElement>) => void;
  }[];
};

/**
 * Similar to custom-select__dropdown (note the double underscore), but different…
 * Primarily, it is used as a context menu instead of a value select.
 */
const ContextMenu: React.FC<Props> = ({ text, icon, items }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const menuRef = useClickAwayHandler<HTMLDivElement>(toggleMenu, isMenuOpen);
  return (
    <>
      <div ref={menuRef} className="custom-select custom-select--dropdown custom-select--fill">
        <button className="custom-select__field" onClick={toggleMenu}>
          <span className={classNames("ifont", "custom-select__icon", `ifont--${icon}`)} />
          <div className="custom-select-field__value ">{text}</div>
          <span className="ifont ifont--angle_down custom-select__icon" />
        </button>
        <div className={classNames("custom-select__dropdown", { open: isMenuOpen })}>
          <button className="custom-select-dropdown__field" onClick={toggleMenu}>
            <span className={classNames("ifont", "custom-select__icon", `ifont--${icon}`)} />
            <div className="custom-select-field__value ">{text}</div>
            <span className="ifont ifont--angle_up custom-select__icon" />
          </button>

          <ul className="custom-select-dropdown__list">
            {items.map(({ icon, label, onClick }) => (
              <li key={label} className="custom-select__option custom-select__option--icon" onClick={onClick}>
                <span className={classNames("ifont", "custom-select__icon", `ifont--${icon}`)} />
                {label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContextMenu;
