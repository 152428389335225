import { Currency } from "../../../hygraph/vo";
import { getUri } from "../../../vehicle/helpers/getUri";
import { VehicleItem } from "../../types/ECommerce";
import { lookupItemListName } from "../../constants/TrackingReferrer";
import { IntersectionTrackingVehicle } from "./types/IntersectionTypes";

export const buildViewItem = (vehicle: IntersectionTrackingVehicle, page?: number): VehicleItem => {
  const uri = getUri();

  const {
    vehicleId,
    priceB2CGross,
    leasingRateFrom,
    model,
    brand,
    title,
    vehicleCondition,
    vehicleType,
    bodyType,
    fuelType,
    transmission,
    dealerId,
    dealerName,
    dealerCity,
    exteriorColor,
    trackingReferrer,
    ps,
  } = vehicle;

  return {
    item_name: model ?? "",
    item_id: vehicleId ?? 0,
    price: priceB2CGross ?? 0,
    quantity: 1,
    currency: Currency.Chf,
    item_brand: brand ?? "",
    item_variant: title ?? "",
    item_list_id: uri ?? "",
    item_list_name: lookupItemListName(trackingReferrer),
    page_number: page,
    price_leasing: leasingRateFrom ?? 0,
    item_category: vehicleCondition ?? "",
    item_category2: vehicleType ?? "",
    item_category3: bodyType ?? "",
    item_category4: fuelType ?? "",
    item_category5: transmission ?? "",
    location_id: dealerCity ?? "",
    dealer: `${dealerId} ${dealerName}`,
    vehicle_color: exteriorColor ?? "",
    vehicle_ps: ps ?? 0,
  };
};
