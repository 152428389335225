import { BASE_TECHNICAL_DATA, TECHNICAL_DATA, TechnicalDataItem } from "../services/technicalData";
import { notNil } from "../../common/helpers/isNil";
import { DetailVehicle } from "../types/vehicle";
import { Formatters } from "../../common/hooks/useFormatter";
import Locale from "../../common/constants/Locale";

// helper to be used in all places where we need to check if a technical data item has data, used for easy refactoring
export const hasTechnicalData = notNil;

export const getTechnicalItems = (vehicle: DetailVehicle): TechnicalDataItem[] => {
  return TECHNICAL_DATA.map((technicalData): [any, TechnicalDataItem] => [technicalData.data(vehicle), technicalData])
    .filter(([data]) => hasTechnicalData(data))
    .map(([, technicalData]): TechnicalDataItem => technicalData);
};

export const getBaseTechnicalItems = (vehicle: DetailVehicle, shownItems: number): TechnicalDataItem[] => {
  return BASE_TECHNICAL_DATA.map((technicalData): [any, TechnicalDataItem] => [
    technicalData.data(vehicle),
    technicalData,
  ])
    .filter(([data]) => hasTechnicalData(data))
    .slice(0, shownItems)
    .map(([, technicalData]): TechnicalDataItem => technicalData);
};

const isComplexDataType = (data: any): boolean => {
  // null is also of type object, therefore we'll double-check
  return typeof data === "object" && hasTechnicalData(data);
};

export const areAllContentsIdenticalForTechnicalDataItem = (
  item: TechnicalDataItem,
  vehicles: DetailVehicle[],
  formatters: Formatters,
  language: Locale,
) => {
  const firstItem = item.data(vehicles[0]);
  let firstFormattedData = firstItem;
  if (isComplexDataType(firstItem)) {
    firstFormattedData = item.format?.(firstItem, formatters, vehicles[0], language);
  }
  return vehicles.every(vehicle => {
    const data = item.data(vehicle);
    if (isComplexDataType(data)) {
      return item.format?.(data, formatters, vehicle, language) === firstFormattedData;
    }
    return data === firstItem;
  });
};
