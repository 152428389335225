import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

type Props = {
  currentItem: number;
  totalItems: number;
  scrollTo: (i: number) => void;
  className?: string;
};

const SHOWN_DOTS = 3;
const LOOKAHEAD_ITEMS = 1;

const CarouselNavDot: React.FC<Props> = ({ currentItem, totalItems, scrollTo, className = "nav-dot" }) => {
  let paginationStart = Math.max(currentItem - LOOKAHEAD_ITEMS, 0);
  if (paginationStart + SHOWN_DOTS >= totalItems) {
    paginationStart = Math.max(totalItems - SHOWN_DOTS, 0);
  }

  const activePages = Math.min(totalItems, SHOWN_DOTS);
  const paginationItems = Array.from(Array(activePages), (_, i) => paginationStart + i);

  const hasPriorImages = paginationItems[0] && paginationItems[0] !== 0;
  if (hasPriorImages) {
    paginationItems.unshift(paginationItems[0] - 1);
  }
  const hasSubsequentImages = paginationItems[paginationItems.length - 1] < totalItems - 1;
  if (hasSubsequentImages) {
    paginationItems.push(paginationItems[paginationItems.length - 1] + 1);
  }
  return totalItems > 1 ? (
    <ul className={className}>
      {paginationItems.map((imageIndex, arrayIndex) => (
        <motion.li
          key={`${imageIndex}`}
          layout
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          layoutDependency={paginationItems.join("-")}
          className={classNames("nav-dot__item", {
            active: currentItem === imageIndex,
            "nav-dot__item--sml":
              (hasPriorImages && arrayIndex === 0) ||
              (hasSubsequentImages && arrayIndex === paginationItems.length - 1),
          })}
          onClick={() => {
            scrollTo(imageIndex);
          }}
        />
      ))}
    </ul>
  ) : null;
};

export default CarouselNavDot;
