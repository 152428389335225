import { SearchParams } from "../../../router/constants/SearchParams";

export type UtmParams = {
  source?: string;
  campaign?: string;
};

export enum UtmCampaignType {
  Whitelabel = "whitelabel-module",
  Banner = "banner-module",
  VehicleRow = "vehicle-row",
}

export const setUtmParams = (pathToExtend: string, utmParams: UtmParams = {}) => {
  const url = new URL(pathToExtend);

  if (utmParams.source) {
    url.searchParams.set(SearchParams.utmSource, utmParams.source);
  }

  url.searchParams.set(SearchParams.utmMedium, "affiliate");

  if (utmParams.campaign) {
    url.searchParams.set(SearchParams.utmCampaign, utmParams.campaign);
  }

  return url.toString();
};
