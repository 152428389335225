import { AugmentedDetailVehicle } from "../../../vehicle/types/vehicle";
import { Currency } from "../../../hygraph/vo";
import { getDiscount } from "../common/getDiscount";
import { getUri } from "../../../vehicle/helpers/getUri";
import { ECommerce } from "../../types/ECommerce";
import { buildDealerName } from "../dealerTrack/buildDealerName";

export const getAddToCartTrackEcommerce = (vehicle: AugmentedDetailVehicle): ECommerce => {
  const {
    currency,
    priceB2cGross,
    title,
    transmission,
    dealer,
    exteriorColor,
    ps,
    kw,
    model,
    vehicleId,
    brand,
    vehicleCondition,
    vehicleType,
    bodyType,
    fuelType,
    leasingRateFrom,
  } = vehicle;

  const initialGrossPrice = vehicle.priceInitialB2cGross ?? 0;
  const url = getUri();

  return {
    ecommerce: {
      currency: currency ?? Currency.Chf,
      value: priceB2cGross ?? 0,
      items: [
        {
          currency: currency ?? Currency.Chf,
          dealer: buildDealerName(dealer),
          discount: getDiscount(priceB2cGross, initialGrossPrice),
          item_brand: brand?.name ?? "",
          item_category2: vehicleType?.name ?? "",
          item_category3: bodyType?.name ?? "",
          item_category4: fuelType?.name ?? "",
          item_category5: transmission?.name ?? "",
          item_category: vehicleCondition?.name ?? "",
          item_id: vehicleId,
          item_list_id: url,
          item_name: model?.name ?? "",
          item_variant: title,
          location_id: dealer?.city ?? "",
          price: initialGrossPrice,
          price_leasing: leasingRateFrom ?? -1,
          quantity: 1,
          vehicle_color: exteriorColor?.name ?? "",
          vehicle_kw: kw ?? -1,
          vehicle_ps: ps ?? -1,
        },
      ],
    },
  };
};
