import React, { useEffect, useState } from "react";
import classNames from "classnames";
import i18next from "i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { UNKNOWN_PLACE, useFindPlaceQuery, useSearchPlacesQuery } from "../services/geoApi";
import useDebounce, { INPUT_DEBOUNCE_DELAY } from "../../common/hooks/useDebounce";
import useEvent from "../../common/hooks/useEvent";
import SingleOptionSelect, { SelectItem } from "../../visual-components/components/form/SingleOptionSelect";
import { GEO_FILTER_SEPARATOR } from "../constants";
import { getCurrentPosition } from "../services/getCurrentPosition";
import { GeoLocation } from "./GeoPopup";

type Props = {
  geoLocation: GeoLocation | null;
  findPlaceQuery: [string, string] | typeof skipToken;
  setFindPlaceQuery: (query: [string, string] | typeof skipToken) => void;
  resetGeoLocation: () => void;
  setGeoLocation: ({ lat, lng }: { lat: string; lng: string }) => void;
  setResolvedGeoLocation: (geoLocation: GeoLocation) => void;
  hideClear?: boolean;
  bg?: boolean;
};

type GeoLocationState = "unknown" | "pending" | "failed" | "succeeded";

// do not start search with fewer than n characters
const MIN_SEARCH_INPUT_LENGTH = 3;

const GeoInputField: React.FC<Props> = ({
  geoLocation,
  resetGeoLocation,
  setGeoLocation,
  setResolvedGeoLocation,
  findPlaceQuery,
  setFindPlaceQuery,
  hideClear = false,
  bg = false,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [geoLocationState, setGeoLocationState] = useState<GeoLocationState>("unknown");
  const postedQuery = useDebounce(searchInput, INPUT_DEBOUNCE_DELAY);
  const { lat, lng, displayName } = geoLocation ?? { lat: "", lng: "", displayName: null };

  useEffect(() => {
    if (lat && lng && !displayName) {
      setFindPlaceQuery([lat, lng]);
    } else {
      setFindPlaceQuery(skipToken);
    }
  }, [lat, lng, displayName, setFindPlaceQuery]);

  const hasEnteredEnoughCharactersToLoadData = postedQuery.length >= MIN_SEARCH_INPUT_LENGTH;
  const { data: places } = useSearchPlacesQuery(hasEnteredEnoughCharactersToLoadData ? postedQuery : skipToken);
  const { currentData: foundPlace } = useFindPlaceQuery(findPlaceQuery);

  const persistFoundPlaceInQuery = useEvent(() => {
    if (foundPlace === UNKNOWN_PLACE) {
      setResolvedGeoLocation({ lat, lng, displayName: i18next.t("UNKNOWN PLACE") });
    } else if (foundPlace) {
      setResolvedGeoLocation({ lat, lng, displayName: foundPlace.attrs.label });
    }
  });

  useEffect(() => {
    if (foundPlace) {
      persistFoundPlaceInQuery();
    }
  }, [foundPlace, persistFoundPlaceInQuery]);

  const placeOptions: SelectItem<{ lat: number | string; lng: number | string }>[] =
    places?.map(({ attrs: { label, lat, lon } }) => {
      const geoLabel = label;
      return {
        value: `${lat}${GEO_FILTER_SEPARATOR}${lon}${GEO_FILTER_SEPARATOR}${displayName}`,
        data: { lat, lng: lon },
        name: geoLabel,
      };
    }) || [];

  return (
    <>
      <SingleOptionSelect
        bg={bg}
        label={i18next.t("LOCATION TITLE")}
        options={hasEnteredEnoughCharactersToLoadData ? placeOptions : []}
        placeholder={i18next.t("ZIP / PLACE")}
        search={{ value: searchInput, setValue: setSearchInput }}
        customDropdownIndicator={
          displayName && !hideClear ? (
            <button
              className="ifont ifont--close custom-select__icon"
              type="button"
              onClick={e => {
                e.stopPropagation();
                resetGeoLocation();
              }}
            />
          ) : (
            <span className="ifont ifont--search custom-select__icon" />
          )
        }
        customLeftIndicator={
          <button
            aria-label={i18next.t("GET CURRENT LOCATION")}
            className={classNames("ifont ifont--locator input-location__icon", geoLocationState)}
            title={geoLocationState === "failed" ? i18next.t("GEOLOCATION ACCESS FAILED") : undefined}
            type="button"
            onClick={e => {
              // preventing propagation would not be necessary if html was structured semantically correct
              e.stopPropagation();
              setGeoLocationState("pending");
              getCurrentPosition({ askIfNotGranted: true })
                .then(location => {
                  setGeoLocationState("succeeded");
                  setGeoLocation(location);
                })
                .catch(() => {
                  setGeoLocationState("failed");
                });
            }}
          />
        }
        info={
          hasEnteredEnoughCharactersToLoadData
            ? undefined
            : i18next.t("ENTER MIN N CHARACTERS", { value: MIN_SEARCH_INPUT_LENGTH })
        }
        value={{
          value: `${lat}${GEO_FILTER_SEPARATOR}${lng}${GEO_FILTER_SEPARATOR}${displayName}`,
          data: { lat, lng },
          name: displayName ?? "",
        }}
        onChange={({ data, name }) => {
          setResolvedGeoLocation({ lat: `${data!.lat}`, lng: `${data!.lng}`, displayName: name });
        }}
      />
      {geoLocationState === "failed" ? (
        <div className="custom-select-error-message">
          <span className="ifont ifont--alert" />
          {i18next.t("GEOLOCATION ACCESS FAILED ENHANCED")}
        </div>
      ) : null}
    </>
  );
};

export default GeoInputField;
