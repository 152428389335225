import { useState } from "react";
import { SelectItem } from "../../visual-components/components/form/SingleOptionSelect";
import filterDuplicatesByName from "../../common/helpers/filterDuplicatesByName";

function useFilterSearch<T extends { name: string }>(options: T[]): [string, (val: string) => void, SelectItem[]] {
  const [searchInput, setSearchInput] = useState("");

  const normalizedSearchInput = searchInput.trim().toLowerCase();

  // todo: consider throttling / debouncing
  const filteredOptions =
    normalizedSearchInput.length > 0
      ? options.filter(({ name }) => name.toLowerCase().includes(normalizedSearchInput))
      : options;
  const uniqueOptions = filterDuplicatesByName(filteredOptions);
  return [searchInput, setSearchInput, uniqueOptions];
}

export default useFilterSearch;
