import { VEHICLE_FACETS } from "../../../algolia/services/vehicleFacetKeys";

export const MODEL_BRAND_SEPARATOR = "|";

export const BRAND_FACET_KEY = "brand";
export const MODEL_FACET_KEY = "model";
export const BRAND_MODEL_FACET_KEY = VEHICLE_FACETS.BRAND_MODEL;

export const buildBrandModelFacetFilter = (brand: string, model: string) => {
  return `${brand}${MODEL_BRAND_SEPARATOR}${model}`;
};

export const getBrandModelCount = (queryParts: string[]) => {
  let count = 0;
  const hasSelectedBrand = !!queryParts[0];
  if (queryParts.length > 1) {
    count = queryParts.length - 1;
  } else if (hasSelectedBrand) {
    count = 1;
  }
  return count;
};
