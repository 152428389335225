import { useEffect } from "react";
import { Key } from "../constants/Key";
import useEvent from "./useEvent";

function useEscapeKeyListener(onEscapeKey: () => void, isActive: boolean = true): void {
  const onEscapeKeyEvent = useEvent(onEscapeKey);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const listener = (event: KeyboardEvent) => {
      if (event.key === Key.Escape) {
        onEscapeKeyEvent();
      }
    };
    document.addEventListener("keyup", listener);
    return () => {
      document.removeEventListener("keyup", listener);
    };
  }, [isActive, onEscapeKeyEvent]);
}

export default useEscapeKeyListener;
