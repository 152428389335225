import React from "react";
import i18next from "i18next";
import { getGeoFilterFormattedLabel } from "../../services/filter-types/geoFilterType";
import { TagRemover } from "./TagRemover";
import { FilterBlock } from "./FilterBlock";

type Props = {
  value: string;
  queryParam: string;
  updateFilters: (q: Record<string, any>) => void;
};

export const SelectedGeo: React.FC<Props> = ({ value, queryParam, updateFilters }) => {
  const label = getGeoFilterFormattedLabel(value);
  return (
    <FilterBlock label={i18next.t("LOCATION TITLE")}>
      <TagRemover
        label={label}
        onClick={() => {
          updateFilters({
            [queryParam]: undefined,
          });
        }}
      />
    </FilterBlock>
  );
};
