import React from "react";
import i18next from "i18next";
import { FilterReferenceData } from "..//services/reference-data-aggregator/types";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import Button from "../../visual-components/components/Button";
import Divider from "../../cms/components/Divider";
import FilterRenderer from "./FilterRenderer";

type Props = {
  filterData: FilterReferenceData;
  appliedFilters?: Record<string, any>;
  onShowSearchOverlay: () => void;
  showSaveSearchOverlay?: () => void;
  searchData: VehicleSearchResponse | undefined;
};

const FilterPanel: React.FC<Props> = ({
  filterData,
  appliedFilters,
  onShowSearchOverlay,
  showSaveSearchOverlay,
  searchData,
}) => {
  return (
    <div className="filter">
      <FilterRenderer appliedFilters={appliedFilters} filterData={filterData} searchData={searchData} view="base" />
      <Divider />
      <div className="filter__block-save">
        <Button transparent onClick={onShowSearchOverlay}>
          {i18next.t("SHOW ALL FILTERS")}
        </Button>
        {showSaveSearchOverlay ? (
          <Button className="btn--link" icon="bell" onClick={showSaveSearchOverlay}>
            {i18next.t("SAVE SEARCH")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default FilterPanel;
