import React, { forwardRef, HTMLAttributeAnchorTarget, ReactNode } from "react";
import ContextLink from "./ContextLink";

type Props = {
  enabled: boolean;
  to: string;
  children: ReactNode;
  className?: string;
  state?: any;
  onPointerUp?: () => void;
  onPointerDown?: () => void;
  target?: HTMLAttributeAnchorTarget;
};

const OptionalLink = forwardRef<HTMLAnchorElement, Props>(
  ({ enabled, to, children, className, state, ...rest }, ref) => {
    return enabled ? (
      <ContextLink ref={ref} className={className} to={to} {...rest}>
        {children}
      </ContextLink>
    ) : (
      <div className={className}>{children}</div>
    );
  },
);

export default OptionalLink;
