export default function filterDuplicatesByName<
  T extends {
    name: string;
  },
>(list: T[]): T[] {
  const uniqueValues = new Set();
  const filteredArray: T[] = [];

  for (const obj of list) {
    const value = obj.name;
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      filteredArray.push(obj);
    }
  }

  return filteredArray;
}
