export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Hex: { input: any; output: any; }
  Json: { input: any; output: any; }
  Long: { input: any; output: any; }
  RGBAHue: { input: any; output: any; }
  RGBATransparency: { input: any; output: any; }
  RichTextAST: { input: any; output: any; }
};

export type AbsoluteUrlLink = Entity & {
  __typename?: 'AbsoluteUrlLink';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  openInNewTab: Scalars['Boolean']['output'];
  /** System stage field */
  stage: Stage;
  urlLink: UrlComponent;
};


export type AbsoluteUrlLinkUrlLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AbsoluteUrlLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AbsoluteUrlLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type AbsoluteUrlLinkConnection = {
  __typename?: 'AbsoluteUrlLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AbsoluteUrlLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AbsoluteUrlLinkCreateInput = {
  openInNewTab: Scalars['Boolean']['input'];
  urlLink: UrlComponentCreateOneInlineInput;
};

export type AbsoluteUrlLinkCreateManyInlineInput = {
  /** Create and connect multiple existing AbsoluteUrlLink documents */
  create?: InputMaybe<Array<AbsoluteUrlLinkCreateInput>>;
};

export type AbsoluteUrlLinkCreateOneInlineInput = {
  /** Create and connect one AbsoluteUrlLink document */
  create?: InputMaybe<AbsoluteUrlLinkCreateInput>;
};

export type AbsoluteUrlLinkCreateWithPositionInput = {
  /** Document to create */
  data: AbsoluteUrlLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AbsoluteUrlLinkEdge = {
  __typename?: 'AbsoluteUrlLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AbsoluteUrlLink;
};

/** Identifies documents */
export type AbsoluteUrlLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink?: InputMaybe<UrlComponentWhereInput>;
};

export enum AbsoluteUrlLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC'
}

export type AbsoluteUrlLinkParent = Link;

export type AbsoluteUrlLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type AbsoluteUrlLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type AbsoluteUrlLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing AbsoluteUrlLinkParent documents */
  create?: InputMaybe<Array<AbsoluteUrlLinkParentCreateInput>>;
};

export type AbsoluteUrlLinkParentCreateOneInlineInput = {
  /** Create and connect one AbsoluteUrlLinkParent document */
  create?: InputMaybe<AbsoluteUrlLinkParentCreateInput>;
};

export type AbsoluteUrlLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type AbsoluteUrlLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type AbsoluteUrlLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple AbsoluteUrlLinkParent component instances */
  create?: InputMaybe<Array<AbsoluteUrlLinkParentCreateWithPositionInput>>;
  /** Delete multiple AbsoluteUrlLinkParent documents */
  delete?: InputMaybe<Array<AbsoluteUrlLinkParentWhereUniqueInput>>;
  /** Update multiple AbsoluteUrlLinkParent component instances */
  update?: InputMaybe<Array<AbsoluteUrlLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AbsoluteUrlLinkParent component instances */
  upsert?: InputMaybe<Array<AbsoluteUrlLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AbsoluteUrlLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type AbsoluteUrlLinkParentUpdateOneInlineInput = {
  /** Create and connect one AbsoluteUrlLinkParent document */
  create?: InputMaybe<AbsoluteUrlLinkParentCreateInput>;
  /** Delete currently connected AbsoluteUrlLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AbsoluteUrlLinkParent document */
  update?: InputMaybe<AbsoluteUrlLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AbsoluteUrlLinkParent document */
  upsert?: InputMaybe<AbsoluteUrlLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type AbsoluteUrlLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type AbsoluteUrlLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type AbsoluteUrlLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type AbsoluteUrlLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type AbsoluteUrlLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type AbsoluteUrlLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type AbsoluteUrlLinkUpdateInput = {
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink?: InputMaybe<UrlComponentUpdateOneInlineInput>;
};

export type AbsoluteUrlLinkUpdateManyInlineInput = {
  /** Create and connect multiple AbsoluteUrlLink component instances */
  create?: InputMaybe<Array<AbsoluteUrlLinkCreateWithPositionInput>>;
  /** Delete multiple AbsoluteUrlLink documents */
  delete?: InputMaybe<Array<AbsoluteUrlLinkWhereUniqueInput>>;
  /** Update multiple AbsoluteUrlLink component instances */
  update?: InputMaybe<Array<AbsoluteUrlLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AbsoluteUrlLink component instances */
  upsert?: InputMaybe<Array<AbsoluteUrlLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AbsoluteUrlLinkUpdateManyInput = {
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AbsoluteUrlLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AbsoluteUrlLinkUpdateManyInput;
  /** Document search */
  where: AbsoluteUrlLinkWhereInput;
};

export type AbsoluteUrlLinkUpdateOneInlineInput = {
  /** Create and connect one AbsoluteUrlLink document */
  create?: InputMaybe<AbsoluteUrlLinkCreateInput>;
  /** Delete currently connected AbsoluteUrlLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AbsoluteUrlLink document */
  update?: InputMaybe<AbsoluteUrlLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AbsoluteUrlLink document */
  upsert?: InputMaybe<AbsoluteUrlLinkUpsertWithNestedWhereUniqueInput>;
};

export type AbsoluteUrlLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AbsoluteUrlLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AbsoluteUrlLinkWhereUniqueInput;
};

export type AbsoluteUrlLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AbsoluteUrlLinkUpdateInput;
  /** Unique document search */
  where: AbsoluteUrlLinkWhereUniqueInput;
};

export type AbsoluteUrlLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: AbsoluteUrlLinkCreateInput;
  /** Update document if it exists */
  update: AbsoluteUrlLinkUpdateInput;
};

export type AbsoluteUrlLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AbsoluteUrlLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AbsoluteUrlLinkWhereUniqueInput;
};

export type AbsoluteUrlLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AbsoluteUrlLinkUpsertInput;
  /** Unique document search */
  where: AbsoluteUrlLinkWhereUniqueInput;
};

/** Identifies documents */
export type AbsoluteUrlLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AbsoluteUrlLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  urlLink?: InputMaybe<UrlComponentWhereInput>;
};

/** References AbsoluteUrlLink record uniquely */
export type AbsoluteUrlLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AccordionChapter = Entity & Node & {
  __typename?: 'AccordionChapter';
  accordionChapterLists: Array<AccordionChapterList>;
  content?: Maybe<RichText>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AccordionChapter>;
  /** List of AccordionChapter versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AccordionChapter>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type AccordionChapterAccordionChapterListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AccordionChapterListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterListWhereInput>;
};


export type AccordionChapterCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionChapterDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type AccordionChapterHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type AccordionChapterLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type AccordionChapterPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionChapterScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type AccordionChapterUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AccordionChapterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionChapterWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionChapterConnection = {
  __typename?: 'AccordionChapterConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionChapterEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionChapterCreateInput = {
  accordionChapterLists?: InputMaybe<AccordionChapterListCreateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AccordionChapterCreateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionChapterCreateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionChapterCreateLocalizationInput = {
  /** Localization input */
  data: AccordionChapterCreateLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AccordionChapterCreateLocalizationInput>>;
};

export type AccordionChapterCreateManyInlineInput = {
  /** Connect multiple existing AccordionChapter documents */
  connect?: InputMaybe<Array<AccordionChapterWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionChapter documents */
  create?: InputMaybe<Array<AccordionChapterCreateInput>>;
};

export type AccordionChapterCreateOneInlineInput = {
  /** Connect one existing AccordionChapter document */
  connect?: InputMaybe<AccordionChapterWhereUniqueInput>;
  /** Create and connect one AccordionChapter document */
  create?: InputMaybe<AccordionChapterCreateInput>;
};

/** An edge in a connection. */
export type AccordionChapterEdge = {
  __typename?: 'AccordionChapterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccordionChapter;
};

export type AccordionChapterList = Entity & Node & {
  __typename?: 'AccordionChapterList';
  accordionChapters: Array<AccordionChapter>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AccordionChapterList>;
  /** List of AccordionChapterList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AccordionChapterList>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type AccordionChapterListAccordionChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AccordionChapterOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterWhereInput>;
};


export type AccordionChapterListCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionChapterListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type AccordionChapterListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type AccordionChapterListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type AccordionChapterListPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionChapterListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type AccordionChapterListUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AccordionChapterListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AccordionChapterListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionChapterListWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionChapterListConnection = {
  __typename?: 'AccordionChapterListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionChapterListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionChapterListCreateInput = {
  accordionChapters?: InputMaybe<AccordionChapterCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AccordionChapterListCreateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionChapterListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionChapterListCreateLocalizationInput = {
  /** Localization input */
  data: AccordionChapterListCreateLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AccordionChapterListCreateLocalizationInput>>;
};

export type AccordionChapterListCreateManyInlineInput = {
  /** Connect multiple existing AccordionChapterList documents */
  connect?: InputMaybe<Array<AccordionChapterListWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionChapterList documents */
  create?: InputMaybe<Array<AccordionChapterListCreateInput>>;
};

export type AccordionChapterListCreateOneInlineInput = {
  /** Connect one existing AccordionChapterList document */
  connect?: InputMaybe<AccordionChapterListWhereUniqueInput>;
  /** Create and connect one AccordionChapterList document */
  create?: InputMaybe<AccordionChapterListCreateInput>;
};

/** An edge in a connection. */
export type AccordionChapterListEdge = {
  __typename?: 'AccordionChapterListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccordionChapterList;
};

/** Identifies documents */
export type AccordionChapterListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionChapters_every?: InputMaybe<AccordionChapterWhereInput>;
  accordionChapters_none?: InputMaybe<AccordionChapterWhereInput>;
  accordionChapters_some?: InputMaybe<AccordionChapterWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionChapterListWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionChapterListWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionChapterListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AccordionChapterListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AccordionChapterListUpdateInput = {
  accordionChapters?: InputMaybe<AccordionChapterUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AccordionChapterListUpdateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterListUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterListUpdateLocalizationInput = {
  data: AccordionChapterListUpdateLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AccordionChapterListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionChapterListUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AccordionChapterListUpsertLocalizationInput>>;
};

export type AccordionChapterListUpdateManyInlineInput = {
  /** Connect multiple existing AccordionChapterList documents */
  connect?: InputMaybe<Array<AccordionChapterListConnectInput>>;
  /** Create and connect multiple AccordionChapterList documents */
  create?: InputMaybe<Array<AccordionChapterListCreateInput>>;
  /** Delete multiple AccordionChapterList documents */
  delete?: InputMaybe<Array<AccordionChapterListWhereUniqueInput>>;
  /** Disconnect multiple AccordionChapterList documents */
  disconnect?: InputMaybe<Array<AccordionChapterListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionChapterList documents */
  set?: InputMaybe<Array<AccordionChapterListWhereUniqueInput>>;
  /** Update multiple AccordionChapterList documents */
  update?: InputMaybe<Array<AccordionChapterListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AccordionChapterList documents */
  upsert?: InputMaybe<Array<AccordionChapterListUpsertWithNestedWhereUniqueInput>>;
};

export type AccordionChapterListUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AccordionChapterListUpdateManyLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterListUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterListUpdateManyLocalizationInput = {
  data: AccordionChapterListUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionChapterListUpdateManyLocalizationInput>>;
};

export type AccordionChapterListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionChapterListUpdateManyInput;
  /** Document search */
  where: AccordionChapterListWhereInput;
};

export type AccordionChapterListUpdateOneInlineInput = {
  /** Connect existing AccordionChapterList document */
  connect?: InputMaybe<AccordionChapterListWhereUniqueInput>;
  /** Create and connect one AccordionChapterList document */
  create?: InputMaybe<AccordionChapterListCreateInput>;
  /** Delete currently connected AccordionChapterList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AccordionChapterList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AccordionChapterList document */
  update?: InputMaybe<AccordionChapterListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionChapterList document */
  upsert?: InputMaybe<AccordionChapterListUpsertWithNestedWhereUniqueInput>;
};

export type AccordionChapterListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionChapterListUpdateInput;
  /** Unique document search */
  where: AccordionChapterListWhereUniqueInput;
};

export type AccordionChapterListUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionChapterListCreateInput;
  /** Update document if it exists */
  update: AccordionChapterListUpdateInput;
};

export type AccordionChapterListUpsertLocalizationInput = {
  create: AccordionChapterListCreateLocalizationDataInput;
  locale: Locale;
  update: AccordionChapterListUpdateLocalizationDataInput;
};

export type AccordionChapterListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionChapterListUpsertInput;
  /** Unique document search */
  where: AccordionChapterListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AccordionChapterListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AccordionChapterListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionChapters_every?: InputMaybe<AccordionChapterWhereInput>;
  accordionChapters_none?: InputMaybe<AccordionChapterWhereInput>;
  accordionChapters_some?: InputMaybe<AccordionChapterWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionChapterListWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionChapterListWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionChapterListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AccordionChapterListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AccordionChapterListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AccordionChapterList record uniquely */
export type AccordionChapterListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type AccordionChapterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionChapterLists_every?: InputMaybe<AccordionChapterListWhereInput>;
  accordionChapterLists_none?: InputMaybe<AccordionChapterListWhereInput>;
  accordionChapterLists_some?: InputMaybe<AccordionChapterListWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionChapterWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionChapterWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionChapterWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AccordionChapterOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AccordionChapterUpdateInput = {
  accordionChapterLists?: InputMaybe<AccordionChapterListUpdateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AccordionChapterUpdateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterUpdateLocalizationInput = {
  data: AccordionChapterUpdateLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AccordionChapterCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionChapterUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AccordionChapterUpsertLocalizationInput>>;
};

export type AccordionChapterUpdateManyInlineInput = {
  /** Connect multiple existing AccordionChapter documents */
  connect?: InputMaybe<Array<AccordionChapterConnectInput>>;
  /** Create and connect multiple AccordionChapter documents */
  create?: InputMaybe<Array<AccordionChapterCreateInput>>;
  /** Delete multiple AccordionChapter documents */
  delete?: InputMaybe<Array<AccordionChapterWhereUniqueInput>>;
  /** Disconnect multiple AccordionChapter documents */
  disconnect?: InputMaybe<Array<AccordionChapterWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionChapter documents */
  set?: InputMaybe<Array<AccordionChapterWhereUniqueInput>>;
  /** Update multiple AccordionChapter documents */
  update?: InputMaybe<Array<AccordionChapterUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AccordionChapter documents */
  upsert?: InputMaybe<Array<AccordionChapterUpsertWithNestedWhereUniqueInput>>;
};

export type AccordionChapterUpdateManyInput = {
  /** content input for default locale (de) */
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AccordionChapterUpdateManyLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionChapterUpdateManyLocalizationInput = {
  data: AccordionChapterUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AccordionChapterUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionChapterUpdateManyLocalizationInput>>;
};

export type AccordionChapterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionChapterUpdateManyInput;
  /** Document search */
  where: AccordionChapterWhereInput;
};

export type AccordionChapterUpdateOneInlineInput = {
  /** Connect existing AccordionChapter document */
  connect?: InputMaybe<AccordionChapterWhereUniqueInput>;
  /** Create and connect one AccordionChapter document */
  create?: InputMaybe<AccordionChapterCreateInput>;
  /** Delete currently connected AccordionChapter document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AccordionChapter document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AccordionChapter document */
  update?: InputMaybe<AccordionChapterUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionChapter document */
  upsert?: InputMaybe<AccordionChapterUpsertWithNestedWhereUniqueInput>;
};

export type AccordionChapterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionChapterUpdateInput;
  /** Unique document search */
  where: AccordionChapterWhereUniqueInput;
};

export type AccordionChapterUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionChapterCreateInput;
  /** Update document if it exists */
  update: AccordionChapterUpdateInput;
};

export type AccordionChapterUpsertLocalizationInput = {
  create: AccordionChapterCreateLocalizationDataInput;
  locale: Locale;
  update: AccordionChapterUpdateLocalizationDataInput;
};

export type AccordionChapterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionChapterUpsertInput;
  /** Unique document search */
  where: AccordionChapterWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AccordionChapterWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AccordionChapterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionChapterLists_every?: InputMaybe<AccordionChapterListWhereInput>;
  accordionChapterLists_none?: InputMaybe<AccordionChapterListWhereInput>;
  accordionChapterLists_some?: InputMaybe<AccordionChapterListWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionChapterWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionChapterWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionChapterWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AccordionChapterWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionChapterWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionChapterWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionChapterWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AccordionChapterWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AccordionChapter record uniquely */
export type AccordionChapterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AccordionSml = Entity & {
  __typename?: 'AccordionSml';
  icon: Asset;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AccordionSml>;
  /** System stage field */
  stage: Stage;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type AccordionSmlIconArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionSmlLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type AccordionSmlConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionSmlWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionSmlConnection = {
  __typename?: 'AccordionSmlConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionSmlEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionSmlCreateInput = {
  icon: AssetCreateOneInlineInput;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AccordionSmlCreateLocalizationsInput>;
  /** text input for default locale (de) */
  text: Scalars['String']['input'];
  /** title input for default locale (de) */
  title: Scalars['String']['input'];
};

export type AccordionSmlCreateLocalizationDataInput = {
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type AccordionSmlCreateLocalizationInput = {
  /** Localization input */
  data: AccordionSmlCreateLocalizationDataInput;
  locale: Locale;
};

export type AccordionSmlCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AccordionSmlCreateLocalizationInput>>;
};

export type AccordionSmlCreateManyInlineInput = {
  /** Create and connect multiple existing AccordionSml documents */
  create?: InputMaybe<Array<AccordionSmlCreateInput>>;
};

export type AccordionSmlCreateOneInlineInput = {
  /** Create and connect one AccordionSml document */
  create?: InputMaybe<AccordionSmlCreateInput>;
};

export type AccordionSmlCreateWithPositionInput = {
  /** Document to create */
  data: AccordionSmlCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AccordionSmlEdge = {
  __typename?: 'AccordionSmlEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccordionSml;
};

export type AccordionSmlList = Entity & Node & {
  __typename?: 'AccordionSmlList';
  accordionSmls: Array<AccordionSml>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AccordionSmlList>;
  /** List of AccordionSmlList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  titleAndLead: TitleAndLead;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type AccordionSmlListAccordionSmlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AccordionSmlOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionSmlWhereInput>;
};


export type AccordionSmlListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionSmlListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type AccordionSmlListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type AccordionSmlListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionSmlListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type AccordionSmlListTitleAndLeadArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AccordionSmlListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AccordionSmlListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionSmlListWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionSmlListConnection = {
  __typename?: 'AccordionSmlListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionSmlListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionSmlListCreateInput = {
  accordionSmls?: InputMaybe<AccordionSmlCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AccordionSmlListCreateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  titleAndLead: TitleAndLeadCreateOneInlineInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionSmlListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccordionSmlListCreateLocalizationInput = {
  /** Localization input */
  data: AccordionSmlListCreateLocalizationDataInput;
  locale: Locale;
};

export type AccordionSmlListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AccordionSmlListCreateLocalizationInput>>;
};

export type AccordionSmlListCreateManyInlineInput = {
  /** Connect multiple existing AccordionSmlList documents */
  connect?: InputMaybe<Array<AccordionSmlListWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionSmlList documents */
  create?: InputMaybe<Array<AccordionSmlListCreateInput>>;
};

export type AccordionSmlListCreateOneInlineInput = {
  /** Connect one existing AccordionSmlList document */
  connect?: InputMaybe<AccordionSmlListWhereUniqueInput>;
  /** Create and connect one AccordionSmlList document */
  create?: InputMaybe<AccordionSmlListCreateInput>;
};

/** An edge in a connection. */
export type AccordionSmlListEdge = {
  __typename?: 'AccordionSmlListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccordionSmlList;
};

/** Identifies documents */
export type AccordionSmlListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionSmls_every?: InputMaybe<AccordionSmlWhereInput>;
  accordionSmls_none?: InputMaybe<AccordionSmlWhereInput>;
  accordionSmls_some?: InputMaybe<AccordionSmlWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionSmlListWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionSmlListWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionSmlListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AccordionSmlListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AccordionSmlListUpdateInput = {
  accordionSmls?: InputMaybe<AccordionSmlUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AccordionSmlListUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  titleAndLead?: InputMaybe<TitleAndLeadUpdateOneInlineInput>;
};

export type AccordionSmlListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AccordionSmlListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type AccordionSmlListUpdateManyInlineInput = {
  /** Connect multiple existing AccordionSmlList documents */
  connect?: InputMaybe<Array<AccordionSmlListConnectInput>>;
  /** Create and connect multiple AccordionSmlList documents */
  create?: InputMaybe<Array<AccordionSmlListCreateInput>>;
  /** Delete multiple AccordionSmlList documents */
  delete?: InputMaybe<Array<AccordionSmlListWhereUniqueInput>>;
  /** Disconnect multiple AccordionSmlList documents */
  disconnect?: InputMaybe<Array<AccordionSmlListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionSmlList documents */
  set?: InputMaybe<Array<AccordionSmlListWhereUniqueInput>>;
  /** Update multiple AccordionSmlList documents */
  update?: InputMaybe<Array<AccordionSmlListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AccordionSmlList documents */
  upsert?: InputMaybe<Array<AccordionSmlListUpsertWithNestedWhereUniqueInput>>;
};

export type AccordionSmlListUpdateManyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionSmlListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionSmlListUpdateManyInput;
  /** Document search */
  where: AccordionSmlListWhereInput;
};

export type AccordionSmlListUpdateOneInlineInput = {
  /** Connect existing AccordionSmlList document */
  connect?: InputMaybe<AccordionSmlListWhereUniqueInput>;
  /** Create and connect one AccordionSmlList document */
  create?: InputMaybe<AccordionSmlListCreateInput>;
  /** Delete currently connected AccordionSmlList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AccordionSmlList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AccordionSmlList document */
  update?: InputMaybe<AccordionSmlListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionSmlList document */
  upsert?: InputMaybe<AccordionSmlListUpsertWithNestedWhereUniqueInput>;
};

export type AccordionSmlListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionSmlListUpdateInput;
  /** Unique document search */
  where: AccordionSmlListWhereUniqueInput;
};

export type AccordionSmlListUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionSmlListCreateInput;
  /** Update document if it exists */
  update: AccordionSmlListUpdateInput;
};

export type AccordionSmlListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionSmlListUpsertInput;
  /** Unique document search */
  where: AccordionSmlListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AccordionSmlListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AccordionSmlListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionSmlListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  accordionSmls_every?: InputMaybe<AccordionSmlWhereInput>;
  accordionSmls_none?: InputMaybe<AccordionSmlWhereInput>;
  accordionSmls_some?: InputMaybe<AccordionSmlWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AccordionSmlListWhereStageInput>;
  documentInStages_none?: InputMaybe<AccordionSmlListWhereStageInput>;
  documentInStages_some?: InputMaybe<AccordionSmlListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AccordionSmlListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionSmlListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionSmlListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionSmlListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AccordionSmlListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AccordionSmlList record uniquely */
export type AccordionSmlListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type AccordionSmlManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum AccordionSmlOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type AccordionSmlParent = AccordionSmlList;

export type AccordionSmlParentConnectInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListConnectInput>;
};

export type AccordionSmlParentCreateInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListCreateInput>;
};

export type AccordionSmlParentCreateManyInlineInput = {
  /** Connect multiple existing AccordionSmlParent documents */
  connect?: InputMaybe<Array<AccordionSmlParentWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionSmlParent documents */
  create?: InputMaybe<Array<AccordionSmlParentCreateInput>>;
};

export type AccordionSmlParentCreateOneInlineInput = {
  /** Connect one existing AccordionSmlParent document */
  connect?: InputMaybe<AccordionSmlParentWhereUniqueInput>;
  /** Create and connect one AccordionSmlParent document */
  create?: InputMaybe<AccordionSmlParentCreateInput>;
};

export type AccordionSmlParentUpdateInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateInput>;
};

export type AccordionSmlParentUpdateManyInlineInput = {
  /** Connect multiple existing AccordionSmlParent documents */
  connect?: InputMaybe<Array<AccordionSmlParentConnectInput>>;
  /** Create and connect multiple AccordionSmlParent documents */
  create?: InputMaybe<Array<AccordionSmlParentCreateInput>>;
  /** Delete multiple AccordionSmlParent documents */
  delete?: InputMaybe<Array<AccordionSmlParentWhereUniqueInput>>;
  /** Disconnect multiple AccordionSmlParent documents */
  disconnect?: InputMaybe<Array<AccordionSmlParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionSmlParent documents */
  set?: InputMaybe<Array<AccordionSmlParentWhereUniqueInput>>;
  /** Update multiple AccordionSmlParent documents */
  update?: InputMaybe<Array<AccordionSmlParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AccordionSmlParent documents */
  upsert?: InputMaybe<Array<AccordionSmlParentUpsertWithNestedWhereUniqueInput>>;
};

export type AccordionSmlParentUpdateManyWithNestedWhereInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateManyWithNestedWhereInput>;
};

export type AccordionSmlParentUpdateOneInlineInput = {
  /** Connect existing AccordionSmlParent document */
  connect?: InputMaybe<AccordionSmlParentWhereUniqueInput>;
  /** Create and connect one AccordionSmlParent document */
  create?: InputMaybe<AccordionSmlParentCreateInput>;
  /** Delete currently connected AccordionSmlParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AccordionSmlParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AccordionSmlParent document */
  update?: InputMaybe<AccordionSmlParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionSmlParent document */
  upsert?: InputMaybe<AccordionSmlParentUpsertWithNestedWhereUniqueInput>;
};

export type AccordionSmlParentUpdateWithNestedWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateWithNestedWhereUniqueInput>;
};

export type AccordionSmlParentUpsertWithNestedWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpsertWithNestedWhereUniqueInput>;
};

export type AccordionSmlParentWhereInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListWhereInput>;
};

export type AccordionSmlParentWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListWhereUniqueInput>;
};

export type AccordionSmlUpdateInput = {
  icon?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AccordionSmlUpdateLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionSmlUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionSmlUpdateLocalizationInput = {
  data: AccordionSmlUpdateLocalizationDataInput;
  locale: Locale;
};

export type AccordionSmlUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AccordionSmlCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionSmlUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AccordionSmlUpsertLocalizationInput>>;
};

export type AccordionSmlUpdateManyInlineInput = {
  /** Create and connect multiple AccordionSml component instances */
  create?: InputMaybe<Array<AccordionSmlCreateWithPositionInput>>;
  /** Delete multiple AccordionSml documents */
  delete?: InputMaybe<Array<AccordionSmlWhereUniqueInput>>;
  /** Update multiple AccordionSml component instances */
  update?: InputMaybe<Array<AccordionSmlUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AccordionSml component instances */
  upsert?: InputMaybe<Array<AccordionSmlUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AccordionSmlUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AccordionSmlUpdateManyLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionSmlUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AccordionSmlUpdateManyLocalizationInput = {
  data: AccordionSmlUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AccordionSmlUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionSmlUpdateManyLocalizationInput>>;
};

export type AccordionSmlUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionSmlUpdateManyInput;
  /** Document search */
  where: AccordionSmlWhereInput;
};

export type AccordionSmlUpdateOneInlineInput = {
  /** Create and connect one AccordionSml document */
  create?: InputMaybe<AccordionSmlCreateInput>;
  /** Delete currently connected AccordionSml document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AccordionSml document */
  update?: InputMaybe<AccordionSmlUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionSml document */
  upsert?: InputMaybe<AccordionSmlUpsertWithNestedWhereUniqueInput>;
};

export type AccordionSmlUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AccordionSmlUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionSmlWhereUniqueInput;
};

export type AccordionSmlUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionSmlUpdateInput;
  /** Unique document search */
  where: AccordionSmlWhereUniqueInput;
};

export type AccordionSmlUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionSmlCreateInput;
  /** Update document if it exists */
  update: AccordionSmlUpdateInput;
};

export type AccordionSmlUpsertLocalizationInput = {
  create: AccordionSmlCreateLocalizationDataInput;
  locale: Locale;
  update: AccordionSmlUpdateLocalizationDataInput;
};

export type AccordionSmlUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AccordionSmlUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionSmlWhereUniqueInput;
};

export type AccordionSmlUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionSmlUpsertInput;
  /** Unique document search */
  where: AccordionSmlWhereUniqueInput;
};

/** Identifies documents */
export type AccordionSmlWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionSmlWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References AccordionSml record uniquely */
export type AccordionSmlWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Ad = Entity & Node & {
  __typename?: 'Ad';
  adTarget?: Maybe<AdadTargetUnion>;
  brands: Array<Brand>;
  campaign: Scalars['String']['output'];
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  ctaLink: Link;
  dealers: Array<Dealer>;
  /** Get the document in other stages */
  documentInStages: Array<Ad>;
  headline: Scalars['String']['output'];
  /** List of Ad versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** Ratio 50:17 */
  imageDesktop?: Maybe<Image>;
  /** Ratio 4:3 */
  imageMobile?: Maybe<Image>;
  languages: Array<Language>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Ad>;
  models: Array<Model>;
  name: Scalars['String']['output'];
  negativeFontColor: Scalars['Boolean']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  showSponsoringTag: Scalars['Boolean']['output'];
  /** System stage field */
  stage: Stage;
  subline: Scalars['String']['output'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type AdAdTargetArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandWhereInput>;
};


export type AdCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AdCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdCtaLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdDealersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type AdDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type AdHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type AdImageDesktopArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdImageMobileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type AdModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelWhereInput>;
};


export type AdPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AdPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type AdUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type AdUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AdConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AdWhereUniqueInput;
};

/** A connection to a list of items. */
export type AdConnection = {
  __typename?: 'AdConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AdEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AdCreateInput = {
  adTarget?: InputMaybe<AdadTargetUnionCreateOneInlineInput>;
  brands?: InputMaybe<BrandCreateManyInlineInput>;
  campaign: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  ctaLink: LinkCreateOneInlineInput;
  dealers?: InputMaybe<DealerCreateManyInlineInput>;
  /** headline input for default locale (de) */
  headline: Scalars['String']['input'];
  imageDesktop?: InputMaybe<ImageCreateOneInlineInput>;
  imageMobile?: InputMaybe<ImageCreateOneInlineInput>;
  languages?: InputMaybe<Array<Language>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AdCreateLocalizationsInput>;
  models?: InputMaybe<ModelCreateManyInlineInput>;
  name: Scalars['String']['input'];
  negativeFontColor: Scalars['Boolean']['input'];
  showSponsoringTag: Scalars['Boolean']['input'];
  /** subline input for default locale (de) */
  subline: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  headline: Scalars['String']['input'];
  subline: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AdCreateLocalizationInput = {
  /** Localization input */
  data: AdCreateLocalizationDataInput;
  locale: Locale;
};

export type AdCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AdCreateLocalizationInput>>;
};

export type AdCreateManyInlineInput = {
  /** Connect multiple existing Ad documents */
  connect?: InputMaybe<Array<AdWhereUniqueInput>>;
  /** Create and connect multiple existing Ad documents */
  create?: InputMaybe<Array<AdCreateInput>>;
};

export type AdCreateOneInlineInput = {
  /** Connect one existing Ad document */
  connect?: InputMaybe<AdWhereUniqueInput>;
  /** Create and connect one Ad document */
  create?: InputMaybe<AdCreateInput>;
};

/** An edge in a connection. */
export type AdEdge = {
  __typename?: 'AdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Ad;
};

/** Identifies documents */
export type AdManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  /** All values in which the modular component is connected to the given models */
  adTarget?: InputMaybe<AdadTargetUnionWhereInput>;
  /** All values in which the union is empty. */
  adTarget_empty?: InputMaybe<Scalars['Boolean']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  campaign_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  campaign_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  campaign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  campaign_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  campaign_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  campaign_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  campaign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  campaign_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  campaign_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  documentInStages_every?: InputMaybe<AdWhereStageInput>;
  documentInStages_none?: InputMaybe<AdWhereStageInput>;
  documentInStages_some?: InputMaybe<AdWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  negativeFontColor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  negativeFontColor_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showSponsoringTag?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showSponsoringTag_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AdOrderByInput {
  CampaignAsc = 'campaign_ASC',
  CampaignDesc = 'campaign_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguagesAsc = 'languages_ASC',
  LanguagesDesc = 'languages_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NegativeFontColorAsc = 'negativeFontColor_ASC',
  NegativeFontColorDesc = 'negativeFontColor_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowSponsoringTagAsc = 'showSponsoringTag_ASC',
  ShowSponsoringTagDesc = 'showSponsoringTag_DESC',
  SublineAsc = 'subline_ASC',
  SublineDesc = 'subline_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type AdTargetHero = Entity & {
  __typename?: 'AdTargetHero';
  geo?: Maybe<TargetGeo>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** as a percentage (0 - 100) */
  maxShare: Scalars['Int']['output'];
  /** System stage field */
  stage: Stage;
};


export type AdTargetHeroGeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AdTargetHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AdTargetHeroWhereUniqueInput;
};

/** A connection to a list of items. */
export type AdTargetHeroConnection = {
  __typename?: 'AdTargetHeroConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AdTargetHeroEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AdTargetHeroCreateInput = {
  geo?: InputMaybe<TargetGeoCreateOneInlineInput>;
  maxShare: Scalars['Int']['input'];
};

export type AdTargetHeroCreateManyInlineInput = {
  /** Create and connect multiple existing AdTargetHero documents */
  create?: InputMaybe<Array<AdTargetHeroCreateInput>>;
};

export type AdTargetHeroCreateOneInlineInput = {
  /** Create and connect one AdTargetHero document */
  create?: InputMaybe<AdTargetHeroCreateInput>;
};

export type AdTargetHeroCreateWithPositionInput = {
  /** Document to create */
  data: AdTargetHeroCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AdTargetHeroEdge = {
  __typename?: 'AdTargetHeroEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdTargetHero;
};

/** Identifies documents */
export type AdTargetHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum AdTargetHeroOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaxShareAsc = 'maxShare_ASC',
  MaxShareDesc = 'maxShare_DESC'
}

export type AdTargetHeroParent = Ad;

export type AdTargetHeroParentConnectInput = {
  Ad?: InputMaybe<AdConnectInput>;
};

export type AdTargetHeroParentCreateInput = {
  Ad?: InputMaybe<AdCreateInput>;
};

export type AdTargetHeroParentCreateManyInlineInput = {
  /** Connect multiple existing AdTargetHeroParent documents */
  connect?: InputMaybe<Array<AdTargetHeroParentWhereUniqueInput>>;
  /** Create and connect multiple existing AdTargetHeroParent documents */
  create?: InputMaybe<Array<AdTargetHeroParentCreateInput>>;
};

export type AdTargetHeroParentCreateOneInlineInput = {
  /** Connect one existing AdTargetHeroParent document */
  connect?: InputMaybe<AdTargetHeroParentWhereUniqueInput>;
  /** Create and connect one AdTargetHeroParent document */
  create?: InputMaybe<AdTargetHeroParentCreateInput>;
};

export type AdTargetHeroParentUpdateInput = {
  Ad?: InputMaybe<AdUpdateInput>;
};

export type AdTargetHeroParentUpdateManyInlineInput = {
  /** Connect multiple existing AdTargetHeroParent documents */
  connect?: InputMaybe<Array<AdTargetHeroParentConnectInput>>;
  /** Create and connect multiple AdTargetHeroParent documents */
  create?: InputMaybe<Array<AdTargetHeroParentCreateInput>>;
  /** Delete multiple AdTargetHeroParent documents */
  delete?: InputMaybe<Array<AdTargetHeroParentWhereUniqueInput>>;
  /** Disconnect multiple AdTargetHeroParent documents */
  disconnect?: InputMaybe<Array<AdTargetHeroParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AdTargetHeroParent documents */
  set?: InputMaybe<Array<AdTargetHeroParentWhereUniqueInput>>;
  /** Update multiple AdTargetHeroParent documents */
  update?: InputMaybe<Array<AdTargetHeroParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AdTargetHeroParent documents */
  upsert?: InputMaybe<Array<AdTargetHeroParentUpsertWithNestedWhereUniqueInput>>;
};

export type AdTargetHeroParentUpdateManyWithNestedWhereInput = {
  Ad?: InputMaybe<AdUpdateManyWithNestedWhereInput>;
};

export type AdTargetHeroParentUpdateOneInlineInput = {
  /** Connect existing AdTargetHeroParent document */
  connect?: InputMaybe<AdTargetHeroParentWhereUniqueInput>;
  /** Create and connect one AdTargetHeroParent document */
  create?: InputMaybe<AdTargetHeroParentCreateInput>;
  /** Delete currently connected AdTargetHeroParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AdTargetHeroParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetHeroParent document */
  update?: InputMaybe<AdTargetHeroParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetHeroParent document */
  upsert?: InputMaybe<AdTargetHeroParentUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetHeroParentUpdateWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
};

export type AdTargetHeroParentUpsertWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetHeroParentWhereInput = {
  Ad?: InputMaybe<AdWhereInput>;
};

export type AdTargetHeroParentWhereUniqueInput = {
  Ad?: InputMaybe<AdWhereUniqueInput>;
};

export type AdTargetHeroUpdateInput = {
  geo?: InputMaybe<TargetGeoUpdateOneInlineInput>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
};

export type AdTargetHeroUpdateManyInlineInput = {
  /** Create and connect multiple AdTargetHero component instances */
  create?: InputMaybe<Array<AdTargetHeroCreateWithPositionInput>>;
  /** Delete multiple AdTargetHero documents */
  delete?: InputMaybe<Array<AdTargetHeroWhereUniqueInput>>;
  /** Update multiple AdTargetHero component instances */
  update?: InputMaybe<Array<AdTargetHeroUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AdTargetHero component instances */
  upsert?: InputMaybe<Array<AdTargetHeroUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AdTargetHeroUpdateManyInput = {
  maxShare?: InputMaybe<Scalars['Int']['input']>;
};

export type AdTargetHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AdTargetHeroUpdateManyInput;
  /** Document search */
  where: AdTargetHeroWhereInput;
};

export type AdTargetHeroUpdateOneInlineInput = {
  /** Create and connect one AdTargetHero document */
  create?: InputMaybe<AdTargetHeroCreateInput>;
  /** Delete currently connected AdTargetHero document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetHero document */
  update?: InputMaybe<AdTargetHeroUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetHero document */
  upsert?: InputMaybe<AdTargetHeroUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetHeroUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AdTargetHeroUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetHeroWhereUniqueInput;
};

export type AdTargetHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AdTargetHeroUpdateInput;
  /** Unique document search */
  where: AdTargetHeroWhereUniqueInput;
};

export type AdTargetHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: AdTargetHeroCreateInput;
  /** Update document if it exists */
  update: AdTargetHeroUpdateInput;
};

export type AdTargetHeroUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AdTargetHeroUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetHeroWhereUniqueInput;
};

export type AdTargetHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AdTargetHeroUpsertInput;
  /** Unique document search */
  where: AdTargetHeroWhereUniqueInput;
};

/** Identifies documents */
export type AdTargetHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** References AdTargetHero record uniquely */
export type AdTargetHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AdTargetTile = Entity & {
  __typename?: 'AdTargetTile';
  bodyTypeGroups: Array<BodyTypeGroup>;
  brands: Array<Brand>;
  geo?: Maybe<TargetGeo>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AdTargetTile>;
  /** as a percentage (0 - 100) */
  maxShare: Scalars['Int']['output'];
  models: Array<Model>;
  /** Spitzmarke */
  pointe: Scalars['String']['output'];
  /** System stage field */
  stage: Stage;
  vehicleConditions: Array<VehicleCondition>;
};


export type AdTargetTileBodyTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BodyTypeGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeGroupWhereInput>;
};


export type AdTargetTileBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandWhereInput>;
};


export type AdTargetTileGeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdTargetTileLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type AdTargetTileModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelWhereInput>;
};


export type AdTargetTileVehicleConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VehicleConditionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleConditionWhereInput>;
};

export type AdTargetTileConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AdTargetTileWhereUniqueInput;
};

/** A connection to a list of items. */
export type AdTargetTileConnection = {
  __typename?: 'AdTargetTileConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AdTargetTileEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AdTargetTileCreateInput = {
  bodyTypeGroups?: InputMaybe<BodyTypeGroupCreateManyInlineInput>;
  brands?: InputMaybe<BrandCreateManyInlineInput>;
  geo?: InputMaybe<TargetGeoCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AdTargetTileCreateLocalizationsInput>;
  maxShare: Scalars['Int']['input'];
  models?: InputMaybe<ModelCreateManyInlineInput>;
  /** pointe input for default locale (de) */
  pointe: Scalars['String']['input'];
  vehicleConditions?: InputMaybe<VehicleConditionCreateManyInlineInput>;
};

export type AdTargetTileCreateLocalizationDataInput = {
  pointe: Scalars['String']['input'];
};

export type AdTargetTileCreateLocalizationInput = {
  /** Localization input */
  data: AdTargetTileCreateLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AdTargetTileCreateLocalizationInput>>;
};

export type AdTargetTileCreateManyInlineInput = {
  /** Create and connect multiple existing AdTargetTile documents */
  create?: InputMaybe<Array<AdTargetTileCreateInput>>;
};

export type AdTargetTileCreateOneInlineInput = {
  /** Create and connect one AdTargetTile document */
  create?: InputMaybe<AdTargetTileCreateInput>;
};

export type AdTargetTileCreateWithPositionInput = {
  /** Document to create */
  data: AdTargetTileCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AdTargetTileEdge = {
  __typename?: 'AdTargetTileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdTargetTile;
};

export type AdTargetTileHomeRow = Entity & {
  __typename?: 'AdTargetTileHomeRow';
  geo?: Maybe<TargetGeo>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AdTargetTileHomeRow>;
  /** as a percentage (0 - 100) */
  maxShare: Scalars['Int']['output'];
  pointe: Scalars['String']['output'];
  /** System stage field */
  stage: Stage;
};


export type AdTargetTileHomeRowGeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type AdTargetTileHomeRowLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type AdTargetTileHomeRowConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AdTargetTileHomeRowWhereUniqueInput;
};

/** A connection to a list of items. */
export type AdTargetTileHomeRowConnection = {
  __typename?: 'AdTargetTileHomeRowConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AdTargetTileHomeRowEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AdTargetTileHomeRowCreateInput = {
  geo?: InputMaybe<TargetGeoCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AdTargetTileHomeRowCreateLocalizationsInput>;
  maxShare: Scalars['Int']['input'];
  /** pointe input for default locale (de) */
  pointe: Scalars['String']['input'];
};

export type AdTargetTileHomeRowCreateLocalizationDataInput = {
  pointe: Scalars['String']['input'];
};

export type AdTargetTileHomeRowCreateLocalizationInput = {
  /** Localization input */
  data: AdTargetTileHomeRowCreateLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileHomeRowCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AdTargetTileHomeRowCreateLocalizationInput>>;
};

export type AdTargetTileHomeRowCreateManyInlineInput = {
  /** Create and connect multiple existing AdTargetTileHomeRow documents */
  create?: InputMaybe<Array<AdTargetTileHomeRowCreateInput>>;
};

export type AdTargetTileHomeRowCreateOneInlineInput = {
  /** Create and connect one AdTargetTileHomeRow document */
  create?: InputMaybe<AdTargetTileHomeRowCreateInput>;
};

export type AdTargetTileHomeRowCreateWithPositionInput = {
  /** Document to create */
  data: AdTargetTileHomeRowCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AdTargetTileHomeRowEdge = {
  __typename?: 'AdTargetTileHomeRowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdTargetTileHomeRow;
};

/** Identifies documents */
export type AdTargetTileHomeRowManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum AdTargetTileHomeRowOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaxShareAsc = 'maxShare_ASC',
  MaxShareDesc = 'maxShare_DESC',
  PointeAsc = 'pointe_ASC',
  PointeDesc = 'pointe_DESC'
}

export type AdTargetTileHomeRowParent = Ad;

export type AdTargetTileHomeRowParentConnectInput = {
  Ad?: InputMaybe<AdConnectInput>;
};

export type AdTargetTileHomeRowParentCreateInput = {
  Ad?: InputMaybe<AdCreateInput>;
};

export type AdTargetTileHomeRowParentCreateManyInlineInput = {
  /** Connect multiple existing AdTargetTileHomeRowParent documents */
  connect?: InputMaybe<Array<AdTargetTileHomeRowParentWhereUniqueInput>>;
  /** Create and connect multiple existing AdTargetTileHomeRowParent documents */
  create?: InputMaybe<Array<AdTargetTileHomeRowParentCreateInput>>;
};

export type AdTargetTileHomeRowParentCreateOneInlineInput = {
  /** Connect one existing AdTargetTileHomeRowParent document */
  connect?: InputMaybe<AdTargetTileHomeRowParentWhereUniqueInput>;
  /** Create and connect one AdTargetTileHomeRowParent document */
  create?: InputMaybe<AdTargetTileHomeRowParentCreateInput>;
};

export type AdTargetTileHomeRowParentUpdateInput = {
  Ad?: InputMaybe<AdUpdateInput>;
};

export type AdTargetTileHomeRowParentUpdateManyInlineInput = {
  /** Connect multiple existing AdTargetTileHomeRowParent documents */
  connect?: InputMaybe<Array<AdTargetTileHomeRowParentConnectInput>>;
  /** Create and connect multiple AdTargetTileHomeRowParent documents */
  create?: InputMaybe<Array<AdTargetTileHomeRowParentCreateInput>>;
  /** Delete multiple AdTargetTileHomeRowParent documents */
  delete?: InputMaybe<Array<AdTargetTileHomeRowParentWhereUniqueInput>>;
  /** Disconnect multiple AdTargetTileHomeRowParent documents */
  disconnect?: InputMaybe<Array<AdTargetTileHomeRowParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AdTargetTileHomeRowParent documents */
  set?: InputMaybe<Array<AdTargetTileHomeRowParentWhereUniqueInput>>;
  /** Update multiple AdTargetTileHomeRowParent documents */
  update?: InputMaybe<Array<AdTargetTileHomeRowParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AdTargetTileHomeRowParent documents */
  upsert?: InputMaybe<Array<AdTargetTileHomeRowParentUpsertWithNestedWhereUniqueInput>>;
};

export type AdTargetTileHomeRowParentUpdateManyWithNestedWhereInput = {
  Ad?: InputMaybe<AdUpdateManyWithNestedWhereInput>;
};

export type AdTargetTileHomeRowParentUpdateOneInlineInput = {
  /** Connect existing AdTargetTileHomeRowParent document */
  connect?: InputMaybe<AdTargetTileHomeRowParentWhereUniqueInput>;
  /** Create and connect one AdTargetTileHomeRowParent document */
  create?: InputMaybe<AdTargetTileHomeRowParentCreateInput>;
  /** Delete currently connected AdTargetTileHomeRowParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AdTargetTileHomeRowParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetTileHomeRowParent document */
  update?: InputMaybe<AdTargetTileHomeRowParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetTileHomeRowParent document */
  upsert?: InputMaybe<AdTargetTileHomeRowParentUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileHomeRowParentUpdateWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
};

export type AdTargetTileHomeRowParentUpsertWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileHomeRowParentWhereInput = {
  Ad?: InputMaybe<AdWhereInput>;
};

export type AdTargetTileHomeRowParentWhereUniqueInput = {
  Ad?: InputMaybe<AdWhereUniqueInput>;
};

export type AdTargetTileHomeRowUpdateInput = {
  geo?: InputMaybe<TargetGeoUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AdTargetTileHomeRowUpdateLocalizationsInput>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileHomeRowUpdateLocalizationDataInput = {
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileHomeRowUpdateLocalizationInput = {
  data: AdTargetTileHomeRowUpdateLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileHomeRowUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AdTargetTileHomeRowCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AdTargetTileHomeRowUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AdTargetTileHomeRowUpsertLocalizationInput>>;
};

export type AdTargetTileHomeRowUpdateManyInlineInput = {
  /** Create and connect multiple AdTargetTileHomeRow component instances */
  create?: InputMaybe<Array<AdTargetTileHomeRowCreateWithPositionInput>>;
  /** Delete multiple AdTargetTileHomeRow documents */
  delete?: InputMaybe<Array<AdTargetTileHomeRowWhereUniqueInput>>;
  /** Update multiple AdTargetTileHomeRow component instances */
  update?: InputMaybe<Array<AdTargetTileHomeRowUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AdTargetTileHomeRow component instances */
  upsert?: InputMaybe<Array<AdTargetTileHomeRowUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AdTargetTileHomeRowUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AdTargetTileHomeRowUpdateManyLocalizationsInput>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileHomeRowUpdateManyLocalizationDataInput = {
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileHomeRowUpdateManyLocalizationInput = {
  data: AdTargetTileHomeRowUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileHomeRowUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AdTargetTileHomeRowUpdateManyLocalizationInput>>;
};

export type AdTargetTileHomeRowUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AdTargetTileHomeRowUpdateManyInput;
  /** Document search */
  where: AdTargetTileHomeRowWhereInput;
};

export type AdTargetTileHomeRowUpdateOneInlineInput = {
  /** Create and connect one AdTargetTileHomeRow document */
  create?: InputMaybe<AdTargetTileHomeRowCreateInput>;
  /** Delete currently connected AdTargetTileHomeRow document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetTileHomeRow document */
  update?: InputMaybe<AdTargetTileHomeRowUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetTileHomeRow document */
  upsert?: InputMaybe<AdTargetTileHomeRowUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileHomeRowUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AdTargetTileHomeRowUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetTileHomeRowWhereUniqueInput;
};

export type AdTargetTileHomeRowUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AdTargetTileHomeRowUpdateInput;
  /** Unique document search */
  where: AdTargetTileHomeRowWhereUniqueInput;
};

export type AdTargetTileHomeRowUpsertInput = {
  /** Create document if it didn't exist */
  create: AdTargetTileHomeRowCreateInput;
  /** Update document if it exists */
  update: AdTargetTileHomeRowUpdateInput;
};

export type AdTargetTileHomeRowUpsertLocalizationInput = {
  create: AdTargetTileHomeRowCreateLocalizationDataInput;
  locale: Locale;
  update: AdTargetTileHomeRowUpdateLocalizationDataInput;
};

export type AdTargetTileHomeRowUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AdTargetTileHomeRowUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetTileHomeRowWhereUniqueInput;
};

export type AdTargetTileHomeRowUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AdTargetTileHomeRowUpsertInput;
  /** Unique document search */
  where: AdTargetTileHomeRowWhereUniqueInput;
};

/** Identifies documents */
export type AdTargetTileHomeRowWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetTileHomeRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  pointe?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  pointe_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  pointe_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  pointe_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  pointe_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  pointe_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  pointe_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  pointe_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  pointe_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  pointe_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References AdTargetTileHomeRow record uniquely */
export type AdTargetTileHomeRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type AdTargetTileManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  bodyTypeGroups_every?: InputMaybe<BodyTypeGroupWhereInput>;
  bodyTypeGroups_none?: InputMaybe<BodyTypeGroupWhereInput>;
  bodyTypeGroups_some?: InputMaybe<BodyTypeGroupWhereInput>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  vehicleConditions_every?: InputMaybe<VehicleConditionWhereInput>;
  vehicleConditions_none?: InputMaybe<VehicleConditionWhereInput>;
  vehicleConditions_some?: InputMaybe<VehicleConditionWhereInput>;
};

export enum AdTargetTileOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaxShareAsc = 'maxShare_ASC',
  MaxShareDesc = 'maxShare_DESC',
  PointeAsc = 'pointe_ASC',
  PointeDesc = 'pointe_DESC'
}

export type AdTargetTileParent = Ad;

export type AdTargetTileParentConnectInput = {
  Ad?: InputMaybe<AdConnectInput>;
};

export type AdTargetTileParentCreateInput = {
  Ad?: InputMaybe<AdCreateInput>;
};

export type AdTargetTileParentCreateManyInlineInput = {
  /** Connect multiple existing AdTargetTileParent documents */
  connect?: InputMaybe<Array<AdTargetTileParentWhereUniqueInput>>;
  /** Create and connect multiple existing AdTargetTileParent documents */
  create?: InputMaybe<Array<AdTargetTileParentCreateInput>>;
};

export type AdTargetTileParentCreateOneInlineInput = {
  /** Connect one existing AdTargetTileParent document */
  connect?: InputMaybe<AdTargetTileParentWhereUniqueInput>;
  /** Create and connect one AdTargetTileParent document */
  create?: InputMaybe<AdTargetTileParentCreateInput>;
};

export type AdTargetTileParentUpdateInput = {
  Ad?: InputMaybe<AdUpdateInput>;
};

export type AdTargetTileParentUpdateManyInlineInput = {
  /** Connect multiple existing AdTargetTileParent documents */
  connect?: InputMaybe<Array<AdTargetTileParentConnectInput>>;
  /** Create and connect multiple AdTargetTileParent documents */
  create?: InputMaybe<Array<AdTargetTileParentCreateInput>>;
  /** Delete multiple AdTargetTileParent documents */
  delete?: InputMaybe<Array<AdTargetTileParentWhereUniqueInput>>;
  /** Disconnect multiple AdTargetTileParent documents */
  disconnect?: InputMaybe<Array<AdTargetTileParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AdTargetTileParent documents */
  set?: InputMaybe<Array<AdTargetTileParentWhereUniqueInput>>;
  /** Update multiple AdTargetTileParent documents */
  update?: InputMaybe<Array<AdTargetTileParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AdTargetTileParent documents */
  upsert?: InputMaybe<Array<AdTargetTileParentUpsertWithNestedWhereUniqueInput>>;
};

export type AdTargetTileParentUpdateManyWithNestedWhereInput = {
  Ad?: InputMaybe<AdUpdateManyWithNestedWhereInput>;
};

export type AdTargetTileParentUpdateOneInlineInput = {
  /** Connect existing AdTargetTileParent document */
  connect?: InputMaybe<AdTargetTileParentWhereUniqueInput>;
  /** Create and connect one AdTargetTileParent document */
  create?: InputMaybe<AdTargetTileParentCreateInput>;
  /** Delete currently connected AdTargetTileParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AdTargetTileParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetTileParent document */
  update?: InputMaybe<AdTargetTileParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetTileParent document */
  upsert?: InputMaybe<AdTargetTileParentUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileParentUpdateWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
};

export type AdTargetTileParentUpsertWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileParentWhereInput = {
  Ad?: InputMaybe<AdWhereInput>;
};

export type AdTargetTileParentWhereUniqueInput = {
  Ad?: InputMaybe<AdWhereUniqueInput>;
};

export type AdTargetTileUpdateInput = {
  bodyTypeGroups?: InputMaybe<BodyTypeGroupUpdateManyInlineInput>;
  brands?: InputMaybe<BrandUpdateManyInlineInput>;
  geo?: InputMaybe<TargetGeoUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AdTargetTileUpdateLocalizationsInput>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<ModelUpdateManyInlineInput>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
  vehicleConditions?: InputMaybe<VehicleConditionUpdateManyInlineInput>;
};

export type AdTargetTileUpdateLocalizationDataInput = {
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileUpdateLocalizationInput = {
  data: AdTargetTileUpdateLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AdTargetTileCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AdTargetTileUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AdTargetTileUpsertLocalizationInput>>;
};

export type AdTargetTileUpdateManyInlineInput = {
  /** Create and connect multiple AdTargetTile component instances */
  create?: InputMaybe<Array<AdTargetTileCreateWithPositionInput>>;
  /** Delete multiple AdTargetTile documents */
  delete?: InputMaybe<Array<AdTargetTileWhereUniqueInput>>;
  /** Update multiple AdTargetTile component instances */
  update?: InputMaybe<Array<AdTargetTileUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AdTargetTile component instances */
  upsert?: InputMaybe<Array<AdTargetTileUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AdTargetTileUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AdTargetTileUpdateManyLocalizationsInput>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileUpdateManyLocalizationDataInput = {
  pointe?: InputMaybe<Scalars['String']['input']>;
};

export type AdTargetTileUpdateManyLocalizationInput = {
  data: AdTargetTileUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AdTargetTileUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AdTargetTileUpdateManyLocalizationInput>>;
};

export type AdTargetTileUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AdTargetTileUpdateManyInput;
  /** Document search */
  where: AdTargetTileWhereInput;
};

export type AdTargetTileUpdateOneInlineInput = {
  /** Create and connect one AdTargetTile document */
  create?: InputMaybe<AdTargetTileCreateInput>;
  /** Delete currently connected AdTargetTile document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdTargetTile document */
  update?: InputMaybe<AdTargetTileUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdTargetTile document */
  upsert?: InputMaybe<AdTargetTileUpsertWithNestedWhereUniqueInput>;
};

export type AdTargetTileUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AdTargetTileUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetTileWhereUniqueInput;
};

export type AdTargetTileUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AdTargetTileUpdateInput;
  /** Unique document search */
  where: AdTargetTileWhereUniqueInput;
};

export type AdTargetTileUpsertInput = {
  /** Create document if it didn't exist */
  create: AdTargetTileCreateInput;
  /** Update document if it exists */
  update: AdTargetTileUpdateInput;
};

export type AdTargetTileUpsertLocalizationInput = {
  create: AdTargetTileCreateLocalizationDataInput;
  locale: Locale;
  update: AdTargetTileUpdateLocalizationDataInput;
};

export type AdTargetTileUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AdTargetTileUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AdTargetTileWhereUniqueInput;
};

export type AdTargetTileUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AdTargetTileUpsertInput;
  /** Unique document search */
  where: AdTargetTileWhereUniqueInput;
};

/** Identifies documents */
export type AdTargetTileWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdTargetTileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  bodyTypeGroups_every?: InputMaybe<BodyTypeGroupWhereInput>;
  bodyTypeGroups_none?: InputMaybe<BodyTypeGroupWhereInput>;
  bodyTypeGroups_some?: InputMaybe<BodyTypeGroupWhereInput>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  geo?: InputMaybe<TargetGeoWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  maxShare?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxShare_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxShare_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxShare_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxShare_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxShare_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxShare_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxShare_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  pointe?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  pointe_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  pointe_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  pointe_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  pointe_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  pointe_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  pointe_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  pointe_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  pointe_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  pointe_starts_with?: InputMaybe<Scalars['String']['input']>;
  vehicleConditions_every?: InputMaybe<VehicleConditionWhereInput>;
  vehicleConditions_none?: InputMaybe<VehicleConditionWhereInput>;
  vehicleConditions_some?: InputMaybe<VehicleConditionWhereInput>;
};

/** References AdTargetTile record uniquely */
export type AdTargetTileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AdUpdateInput = {
  adTarget?: InputMaybe<AdadTargetUnionUpdateOneInlineInput>;
  brands?: InputMaybe<BrandUpdateManyInlineInput>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkUpdateOneInlineInput>;
  dealers?: InputMaybe<DealerUpdateManyInlineInput>;
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>;
  imageDesktop?: InputMaybe<ImageUpdateOneInlineInput>;
  imageMobile?: InputMaybe<ImageUpdateOneInlineInput>;
  languages?: InputMaybe<Array<Language>>;
  /** Manage document localizations */
  localizations?: InputMaybe<AdUpdateLocalizationsInput>;
  models?: InputMaybe<ModelUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  negativeFontColor?: InputMaybe<Scalars['Boolean']['input']>;
  showSponsoringTag?: InputMaybe<Scalars['Boolean']['input']>;
  /** subline input for default locale (de) */
  subline?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>;
  subline?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateLocalizationInput = {
  data: AdUpdateLocalizationDataInput;
  locale: Locale;
};

export type AdUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AdCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AdUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AdUpsertLocalizationInput>>;
};

export type AdUpdateManyInlineInput = {
  /** Connect multiple existing Ad documents */
  connect?: InputMaybe<Array<AdConnectInput>>;
  /** Create and connect multiple Ad documents */
  create?: InputMaybe<Array<AdCreateInput>>;
  /** Delete multiple Ad documents */
  delete?: InputMaybe<Array<AdWhereUniqueInput>>;
  /** Disconnect multiple Ad documents */
  disconnect?: InputMaybe<Array<AdWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Ad documents */
  set?: InputMaybe<Array<AdWhereUniqueInput>>;
  /** Update multiple Ad documents */
  update?: InputMaybe<Array<AdUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Ad documents */
  upsert?: InputMaybe<Array<AdUpsertWithNestedWhereUniqueInput>>;
};

export type AdUpdateManyInput = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AdUpdateManyLocalizationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  negativeFontColor?: InputMaybe<Scalars['Boolean']['input']>;
  showSponsoringTag?: InputMaybe<Scalars['Boolean']['input']>;
  /** subline input for default locale (de) */
  subline?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateManyLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>;
  subline?: InputMaybe<Scalars['String']['input']>;
};

export type AdUpdateManyLocalizationInput = {
  data: AdUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AdUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AdUpdateManyLocalizationInput>>;
};

export type AdUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AdUpdateManyInput;
  /** Document search */
  where: AdWhereInput;
};

export type AdUpdateOneInlineInput = {
  /** Connect existing Ad document */
  connect?: InputMaybe<AdWhereUniqueInput>;
  /** Create and connect one Ad document */
  create?: InputMaybe<AdCreateInput>;
  /** Delete currently connected Ad document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Ad document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Ad document */
  update?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Ad document */
  upsert?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
};

export type AdUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AdUpdateInput;
  /** Unique document search */
  where: AdWhereUniqueInput;
};

export type AdUpsertInput = {
  /** Create document if it didn't exist */
  create: AdCreateInput;
  /** Update document if it exists */
  update: AdUpdateInput;
};

export type AdUpsertLocalizationInput = {
  create: AdCreateLocalizationDataInput;
  locale: Locale;
  update: AdUpdateLocalizationDataInput;
};

export type AdUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AdUpsertInput;
  /** Unique document search */
  where: AdWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AdWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AdWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  /** All values in which the modular component is connected to the given models */
  adTarget?: InputMaybe<AdadTargetUnionWhereInput>;
  /** All values in which the union is empty. */
  adTarget_empty?: InputMaybe<Scalars['Boolean']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  campaign?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  campaign_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  campaign_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  campaign_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  campaign_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  campaign_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  campaign_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  campaign_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  campaign_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  campaign_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  documentInStages_every?: InputMaybe<AdWhereStageInput>;
  documentInStages_none?: InputMaybe<AdWhereStageInput>;
  documentInStages_some?: InputMaybe<AdWhereStageInput>;
  headline?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  headline_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  headline_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  headline_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  headline_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  headline_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  headline_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  headline_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  negativeFontColor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  negativeFontColor_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showSponsoringTag?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showSponsoringTag_not?: InputMaybe<Scalars['Boolean']['input']>;
  subline?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  subline_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  subline_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  subline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subline_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  subline_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  subline_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  subline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  subline_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  subline_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AdWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AdWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AdWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AdWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AdWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Ad record uniquely */
export type AdWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AdadTargetUnion = AdTargetHero | AdTargetTile | AdTargetTileHomeRow;

export type AdadTargetUnionConnectInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroConnectInput>;
  AdTargetTile?: InputMaybe<AdTargetTileConnectInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowConnectInput>;
};

export type AdadTargetUnionCreateInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroCreateInput>;
  AdTargetTile?: InputMaybe<AdTargetTileCreateInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowCreateInput>;
};

export type AdadTargetUnionCreateManyInlineInput = {
  /** Create and connect multiple existing AdadTargetUnion documents */
  create?: InputMaybe<Array<AdadTargetUnionCreateInput>>;
};

export type AdadTargetUnionCreateOneInlineInput = {
  /** Create and connect one AdadTargetUnion document */
  create?: InputMaybe<AdadTargetUnionCreateInput>;
};

export type AdadTargetUnionCreateWithPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroCreateWithPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileCreateWithPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowCreateWithPositionInput>;
};

export type AdadTargetUnionUpdateInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateInput>;
};

export type AdadTargetUnionUpdateManyInlineInput = {
  /** Create and connect multiple AdadTargetUnion component instances */
  create?: InputMaybe<Array<AdadTargetUnionCreateWithPositionInput>>;
  /** Delete multiple AdadTargetUnion documents */
  delete?: InputMaybe<Array<AdadTargetUnionWhereUniqueInput>>;
  /** Update multiple AdadTargetUnion component instances */
  update?: InputMaybe<Array<AdadTargetUnionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AdadTargetUnion component instances */
  upsert?: InputMaybe<Array<AdadTargetUnionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AdadTargetUnionUpdateManyWithNestedWhereInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateManyWithNestedWhereInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateManyWithNestedWhereInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateManyWithNestedWhereInput>;
};

export type AdadTargetUnionUpdateOneInlineInput = {
  /** Create and connect one AdadTargetUnion document */
  create?: InputMaybe<AdadTargetUnionCreateInput>;
  /** Delete currently connected AdadTargetUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AdadTargetUnion document */
  update?: InputMaybe<AdadTargetUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AdadTargetUnion document */
  upsert?: InputMaybe<AdadTargetUnionUpsertWithNestedWhereUniqueInput>;
};

export type AdadTargetUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateWithNestedWhereUniqueAndPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateWithNestedWhereUniqueAndPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type AdadTargetUnionUpdateWithNestedWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateWithNestedWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateWithNestedWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateWithNestedWhereUniqueInput>;
};

export type AdadTargetUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpsertWithNestedWhereUniqueAndPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpsertWithNestedWhereUniqueAndPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type AdadTargetUnionUpsertWithNestedWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpsertWithNestedWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpsertWithNestedWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpsertWithNestedWhereUniqueInput>;
};

export type AdadTargetUnionWhereInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroWhereInput>;
  AdTargetTile?: InputMaybe<AdTargetTileWhereInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowWhereInput>;
};

export type AdadTargetUnionWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowWhereUniqueInput>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int']['output'];
};

export type AppLink = Entity & {
  __typename?: 'AppLink';
  /** Example: "?brandModel=1&sortBy=ps_desc" */
  additionalQueryParameters?: Maybe<Scalars['String']['output']>;
  appRoute: AppRoute;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AppLink>;
  name?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
};


export type AppLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type AppLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AppLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type AppLinkConnection = {
  __typename?: 'AppLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AppLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AppLinkCreateInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  appRoute: AppRoute;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AppLinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkCreateLocalizationInput = {
  /** Localization input */
  data: AppLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type AppLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AppLinkCreateLocalizationInput>>;
};

export type AppLinkCreateManyInlineInput = {
  /** Create and connect multiple existing AppLink documents */
  create?: InputMaybe<Array<AppLinkCreateInput>>;
};

export type AppLinkCreateOneInlineInput = {
  /** Create and connect one AppLink document */
  create?: InputMaybe<AppLinkCreateInput>;
};

export type AppLinkCreateWithPositionInput = {
  /** Document to create */
  data: AppLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AppLinkEdge = {
  __typename?: 'AppLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AppLink;
};

/** Identifies documents */
export type AppLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  additionalQueryParameters_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  additionalQueryParameters_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  additionalQueryParameters_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  additionalQueryParameters_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  additionalQueryParameters_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  additionalQueryParameters_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  additionalQueryParameters_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  additionalQueryParameters_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  additionalQueryParameters_starts_with?: InputMaybe<Scalars['String']['input']>;
  appRoute?: InputMaybe<AppRoute>;
  /** All values that are contained in given list. */
  appRoute_in?: InputMaybe<Array<InputMaybe<AppRoute>>>;
  /** Any other value that exists and is not equal to the given value. */
  appRoute_not?: InputMaybe<AppRoute>;
  /** All values that are not contained in given list. */
  appRoute_not_in?: InputMaybe<Array<InputMaybe<AppRoute>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum AppLinkOrderByInput {
  AdditionalQueryParametersAsc = 'additionalQueryParameters_ASC',
  AdditionalQueryParametersDesc = 'additionalQueryParameters_DESC',
  AppRouteAsc = 'appRoute_ASC',
  AppRouteDesc = 'appRoute_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type AppLinkParent = Link;

export type AppLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type AppLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type AppLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing AppLinkParent documents */
  create?: InputMaybe<Array<AppLinkParentCreateInput>>;
};

export type AppLinkParentCreateOneInlineInput = {
  /** Create and connect one AppLinkParent document */
  create?: InputMaybe<AppLinkParentCreateInput>;
};

export type AppLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type AppLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type AppLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple AppLinkParent component instances */
  create?: InputMaybe<Array<AppLinkParentCreateWithPositionInput>>;
  /** Delete multiple AppLinkParent documents */
  delete?: InputMaybe<Array<AppLinkParentWhereUniqueInput>>;
  /** Update multiple AppLinkParent component instances */
  update?: InputMaybe<Array<AppLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AppLinkParent component instances */
  upsert?: InputMaybe<Array<AppLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AppLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type AppLinkParentUpdateOneInlineInput = {
  /** Create and connect one AppLinkParent document */
  create?: InputMaybe<AppLinkParentCreateInput>;
  /** Delete currently connected AppLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AppLinkParent document */
  update?: InputMaybe<AppLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AppLinkParent document */
  upsert?: InputMaybe<AppLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type AppLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type AppLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type AppLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type AppLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type AppLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type AppLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type AppLinkUpdateInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  appRoute?: InputMaybe<AppRoute>;
  /** Manage document localizations */
  localizations?: InputMaybe<AppLinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkUpdateLocalizationInput = {
  data: AppLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type AppLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AppLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AppLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AppLinkUpsertLocalizationInput>>;
};

export type AppLinkUpdateManyInlineInput = {
  /** Create and connect multiple AppLink component instances */
  create?: InputMaybe<Array<AppLinkCreateWithPositionInput>>;
  /** Delete multiple AppLink documents */
  delete?: InputMaybe<Array<AppLinkWhereUniqueInput>>;
  /** Update multiple AppLink component instances */
  update?: InputMaybe<Array<AppLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AppLink component instances */
  upsert?: InputMaybe<Array<AppLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AppLinkUpdateManyInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  appRoute?: InputMaybe<AppRoute>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AppLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AppLinkUpdateManyLocalizationInput = {
  data: AppLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AppLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AppLinkUpdateManyLocalizationInput>>;
};

export type AppLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AppLinkUpdateManyInput;
  /** Document search */
  where: AppLinkWhereInput;
};

export type AppLinkUpdateOneInlineInput = {
  /** Create and connect one AppLink document */
  create?: InputMaybe<AppLinkCreateInput>;
  /** Delete currently connected AppLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AppLink document */
  update?: InputMaybe<AppLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AppLink document */
  upsert?: InputMaybe<AppLinkUpsertWithNestedWhereUniqueInput>;
};

export type AppLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AppLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AppLinkWhereUniqueInput;
};

export type AppLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AppLinkUpdateInput;
  /** Unique document search */
  where: AppLinkWhereUniqueInput;
};

export type AppLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: AppLinkCreateInput;
  /** Update document if it exists */
  update: AppLinkUpdateInput;
};

export type AppLinkUpsertLocalizationInput = {
  create: AppLinkCreateLocalizationDataInput;
  locale: Locale;
  update: AppLinkUpdateLocalizationDataInput;
};

export type AppLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AppLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AppLinkWhereUniqueInput;
};

export type AppLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AppLinkUpsertInput;
  /** Unique document search */
  where: AppLinkWhereUniqueInput;
};

/** Identifies documents */
export type AppLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AppLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  additionalQueryParameters_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  additionalQueryParameters_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  additionalQueryParameters_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  additionalQueryParameters_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  additionalQueryParameters_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  additionalQueryParameters_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  additionalQueryParameters_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  additionalQueryParameters_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  additionalQueryParameters_starts_with?: InputMaybe<Scalars['String']['input']>;
  appRoute?: InputMaybe<AppRoute>;
  /** All values that are contained in given list. */
  appRoute_in?: InputMaybe<Array<InputMaybe<AppRoute>>>;
  /** Any other value that exists and is not equal to the given value. */
  appRoute_not?: InputMaybe<AppRoute>;
  /** All values that are not contained in given list. */
  appRoute_not_in?: InputMaybe<Array<InputMaybe<AppRoute>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References AppLink record uniquely */
export type AppLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AppRoute {
  BrandList = 'brandList',
  Cockpit = 'cockpit',
  DealerLocator = 'dealerLocator',
  Favorites = 'favorites',
  FfuVehicleEvaluation = 'ffuVehicleEvaluation',
  Home = 'home',
  MagazineOverview = 'magazineOverview',
  MatchMaker = 'matchMaker',
  PaPromotionList = 'paPromotionList',
  SavedSearches = 'savedSearches',
  Search = 'search',
  TourChecklist = 'tourChecklist',
  VehicleComparison = 'vehicleComparison',
  VehicleEstimation = 'vehicleEstimation',
  VehicleEvaluationIntro = 'vehicleEvaluationIntro'
}

export type Article = Entity & Node & {
  __typename?: 'Article';
  abstract: Scalars['String']['output'];
  autor: Scalars['String']['output'];
  canonicalUrl?: Maybe<UrlComponent>;
  content: Array<ArticleContentRichText>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  date: Scalars['Date']['output'];
  /** Get the document in other stages */
  documentInStages: Array<Article>;
  editor: Scalars['String']['output'];
  /** List of Article versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywordList?: Maybe<KeywordList>;
  /** Add any keywords with inline Embed. */
  keywords?: Maybe<ArticleKeywordsRichText>;
  languages: Array<Language>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Article>;
  /** Spitzmarke */
  pointe: Scalars['String']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  publisher?: Maybe<Scalars['String']['output']>;
  scheduledIn: Array<ScheduledOperation>;
  slug: Scalars['String']['output'];
  /** System stage field */
  stage: Stage;
  title: Scalars['String']['output'];
  titleImage?: Maybe<Image>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type ArticleCanonicalUrlArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ArticleCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ArticleHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ArticleKeywordListArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ArticlePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ArticlePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ArticleTitleImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ArticleUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ArticleContentRichText = {
  __typename?: 'ArticleContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String']['output'];
  json: Scalars['RichTextAST']['output'];
  /** Returns Markdown representation */
  markdown: Scalars['String']['output'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output'];
  references: Array<ArticleContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output'];
};


export type ArticleContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleContentRichTextEmbeddedTypes = AccordionChapterList | Button | Citatum | FactList | FeaturedVehiclesRow | Gallery | Image | ImageTextComboList | QuestionAndAnswerList | SpecialBox | Video;

export type ArticleCreateInput = {
  /** abstract input for default locale (de) */
  abstract: Scalars['String']['input'];
  autor: Scalars['String']['input'];
  canonicalUrl?: InputMaybe<UrlComponentCreateOneInlineInput>;
  cll54scy909cu01uo89fx7opj?: InputMaybe<ArticleLinkCreateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['Date']['input'];
  editor: Scalars['String']['input'];
  keywordList?: InputMaybe<KeywordListCreateOneInlineInput>;
  keywords?: InputMaybe<Scalars['RichTextAST']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCreateLocalizationsInput>;
  /** pointe input for default locale (de) */
  pointe: Scalars['String']['input'];
  publisher?: InputMaybe<Scalars['String']['input']>;
  /** slug input for default locale (de) */
  slug: Scalars['String']['input'];
  /** title input for default locale (de) */
  title: Scalars['String']['input'];
  titleImage?: InputMaybe<ImageCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleCreateLocalizationDataInput = {
  abstract: Scalars['String']['input'];
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  pointe: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ArticleCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCreateLocalizationDataInput;
  locale: Locale;
};

export type ArticleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
};

export type ArticleCreateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Create and connect multiple existing Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
};

export type ArticleCreateOneInlineInput = {
  /** Connect one existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Article;
};

export type ArticleKeywordsRichText = {
  __typename?: 'ArticleKeywordsRichText';
  /** Returns HTMl representation */
  html: Scalars['String']['output'];
  json: Scalars['RichTextAST']['output'];
  /** Returns Markdown representation */
  markdown: Scalars['String']['output'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output'];
  references: Array<ArticleKeywordsRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output'];
};


export type ArticleKeywordsRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleKeywordsRichTextEmbeddedTypes = BodyType | Brand | DriveType | FuelType | Model | Transmission | VehicleClass;

export type ArticleLink = Entity & {
  __typename?: 'ArticleLink';
  article?: Maybe<Article>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ArticleLink>;
  name?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
};


export type ArticleLinkArticleArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ArticleLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type ArticleLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleLinkConnection = {
  __typename?: 'ArticleLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ArticleLinkCreateInput = {
  article?: InputMaybe<ArticleCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleLinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkCreateLocalizationInput = {
  /** Localization input */
  data: ArticleLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type ArticleLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleLinkCreateLocalizationInput>>;
};

export type ArticleLinkCreateManyInlineInput = {
  /** Create and connect multiple existing ArticleLink documents */
  create?: InputMaybe<Array<ArticleLinkCreateInput>>;
};

export type ArticleLinkCreateOneInlineInput = {
  /** Create and connect one ArticleLink document */
  create?: InputMaybe<ArticleLinkCreateInput>;
};

export type ArticleLinkCreateWithPositionInput = {
  /** Document to create */
  data: ArticleLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ArticleLinkEdge = {
  __typename?: 'ArticleLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ArticleLink;
};

/** Identifies documents */
export type ArticleLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  article?: InputMaybe<ArticleWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum ArticleLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type ArticleLinkParent = Link;

export type ArticleLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type ArticleLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type ArticleLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing ArticleLinkParent documents */
  create?: InputMaybe<Array<ArticleLinkParentCreateInput>>;
};

export type ArticleLinkParentCreateOneInlineInput = {
  /** Create and connect one ArticleLinkParent document */
  create?: InputMaybe<ArticleLinkParentCreateInput>;
};

export type ArticleLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type ArticleLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type ArticleLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple ArticleLinkParent component instances */
  create?: InputMaybe<Array<ArticleLinkParentCreateWithPositionInput>>;
  /** Delete multiple ArticleLinkParent documents */
  delete?: InputMaybe<Array<ArticleLinkParentWhereUniqueInput>>;
  /** Update multiple ArticleLinkParent component instances */
  update?: InputMaybe<Array<ArticleLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ArticleLinkParent component instances */
  upsert?: InputMaybe<Array<ArticleLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ArticleLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type ArticleLinkParentUpdateOneInlineInput = {
  /** Create and connect one ArticleLinkParent document */
  create?: InputMaybe<ArticleLinkParentCreateInput>;
  /** Delete currently connected ArticleLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ArticleLinkParent document */
  update?: InputMaybe<ArticleLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleLinkParent document */
  upsert?: InputMaybe<ArticleLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type ArticleLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type ArticleLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type ArticleLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type ArticleLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type ArticleLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type ArticleLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type ArticleLinkUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleLinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkUpdateLocalizationInput = {
  data: ArticleLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type ArticleLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ArticleLinkUpsertLocalizationInput>>;
};

export type ArticleLinkUpdateManyInlineInput = {
  /** Create and connect multiple ArticleLink component instances */
  create?: InputMaybe<Array<ArticleLinkCreateWithPositionInput>>;
  /** Delete multiple ArticleLink documents */
  delete?: InputMaybe<Array<ArticleLinkWhereUniqueInput>>;
  /** Update multiple ArticleLink component instances */
  update?: InputMaybe<Array<ArticleLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ArticleLink component instances */
  upsert?: InputMaybe<Array<ArticleLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ArticleLinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleLinkUpdateManyLocalizationInput = {
  data: ArticleLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ArticleLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleLinkUpdateManyLocalizationInput>>;
};

export type ArticleLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleLinkUpdateManyInput;
  /** Document search */
  where: ArticleLinkWhereInput;
};

export type ArticleLinkUpdateOneInlineInput = {
  /** Create and connect one ArticleLink document */
  create?: InputMaybe<ArticleLinkCreateInput>;
  /** Delete currently connected ArticleLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ArticleLink document */
  update?: InputMaybe<ArticleLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleLink document */
  upsert?: InputMaybe<ArticleLinkUpsertWithNestedWhereUniqueInput>;
};

export type ArticleLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ArticleLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ArticleLinkWhereUniqueInput;
};

export type ArticleLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleLinkUpdateInput;
  /** Unique document search */
  where: ArticleLinkWhereUniqueInput;
};

export type ArticleLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleLinkCreateInput;
  /** Update document if it exists */
  update: ArticleLinkUpdateInput;
};

export type ArticleLinkUpsertLocalizationInput = {
  create: ArticleLinkCreateLocalizationDataInput;
  locale: Locale;
  update: ArticleLinkUpdateLocalizationDataInput;
};

export type ArticleLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ArticleLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ArticleLinkWhereUniqueInput;
};

export type ArticleLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleLinkUpsertInput;
  /** Unique document search */
  where: ArticleLinkWhereUniqueInput;
};

/** Identifies documents */
export type ArticleLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  article?: InputMaybe<ArticleWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References ArticleLink record uniquely */
export type ArticleLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type ArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  autor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  autor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  autor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  autor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  autor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  autor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  autor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  autor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  autor_starts_with?: InputMaybe<Scalars['String']['input']>;
  canonicalUrl?: InputMaybe<UrlComponentWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  editor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editor_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordList?: InputMaybe<KeywordListWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  publisher_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  publisher_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  publisher_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  publisher_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  publisher_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  publisher_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  publisher_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  publisher_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  publisher_starts_with?: InputMaybe<Scalars['String']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleImage?: InputMaybe<ImageWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ArticleOrderByInput {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  AutorAsc = 'autor_ASC',
  AutorDesc = 'autor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  EditorAsc = 'editor_ASC',
  EditorDesc = 'editor_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguagesAsc = 'languages_ASC',
  LanguagesDesc = 'languages_DESC',
  PointeAsc = 'pointe_ASC',
  PointeDesc = 'pointe_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PublisherAsc = 'publisher_ASC',
  PublisherDesc = 'publisher_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ArticleUpdateInput = {
  /** abstract input for default locale (de) */
  abstract?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  canonicalUrl?: InputMaybe<UrlComponentUpdateOneInlineInput>;
  cll54scy909cu01uo89fx7opj?: InputMaybe<ArticleLinkUpdateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  editor?: InputMaybe<Scalars['String']['input']>;
  keywordList?: InputMaybe<KeywordListUpdateOneInlineInput>;
  keywords?: InputMaybe<Scalars['RichTextAST']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleUpdateLocalizationsInput>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  titleImage?: InputMaybe<ImageUpdateOneInlineInput>;
};

export type ArticleUpdateLocalizationDataInput = {
  abstract?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  pointe?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleUpdateLocalizationInput = {
  data: ArticleUpdateLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ArticleUpsertLocalizationInput>>;
};

export type ArticleUpdateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleConnectInput>>;
  /** Create and connect multiple Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
  /** Delete multiple Article documents */
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Disconnect multiple Article documents */
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Article documents */
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Update multiple Article documents */
  update?: InputMaybe<Array<ArticleUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Article documents */
  upsert?: InputMaybe<Array<ArticleUpsertWithNestedWhereUniqueInput>>;
};

export type ArticleUpdateManyInput = {
  /** abstract input for default locale (de) */
  abstract?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  editor?: InputMaybe<Scalars['String']['input']>;
  keywords?: InputMaybe<Scalars['RichTextAST']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleUpdateManyLocalizationsInput>;
  /** pointe input for default locale (de) */
  pointe?: InputMaybe<Scalars['String']['input']>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleUpdateManyLocalizationDataInput = {
  abstract?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  pointe?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleUpdateManyLocalizationInput = {
  data: ArticleUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateManyLocalizationInput>>;
};

export type ArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleUpdateManyInput;
  /** Document search */
  where: ArticleWhereInput;
};

export type ArticleUpdateOneInlineInput = {
  /** Connect existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
  /** Delete currently connected Article document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Article document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Article document */
  update?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Article document */
  upsert?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
};

export type ArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleUpdateInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

export type ArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCreateInput;
  /** Update document if it exists */
  update: ArticleUpdateInput;
};

export type ArticleUpsertLocalizationInput = {
  create: ArticleCreateLocalizationDataInput;
  locale: Locale;
  update: ArticleUpdateLocalizationDataInput;
};

export type ArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleUpsertInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ArticleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  abstract?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  abstract_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  abstract_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  abstract_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  abstract_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  abstract_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  abstract_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  abstract_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  abstract_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  abstract_starts_with?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  autor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  autor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  autor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  autor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  autor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  autor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  autor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  autor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  autor_starts_with?: InputMaybe<Scalars['String']['input']>;
  canonicalUrl?: InputMaybe<UrlComponentWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  editor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editor_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordList?: InputMaybe<KeywordListWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  pointe?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  pointe_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  pointe_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  pointe_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  pointe_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  pointe_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  pointe_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  pointe_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  pointe_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  pointe_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  publisher?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  publisher_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  publisher_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  publisher_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  publisher_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  publisher_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  publisher_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  publisher_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  publisher_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  publisher_starts_with?: InputMaybe<Scalars['String']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleImage?: InputMaybe<ImageWhereInput>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Article record uniquely */
export type ArticleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Asset system model */
export type Asset = Entity & Node & {
  __typename?: 'Asset';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Asset>;
  /** The file name */
  fileName: Scalars['String']['output'];
  /** The file handle */
  handle: Scalars['String']['output'];
  /** The height of the file */
  height?: Maybe<Scalars['Float']['output']>;
  /** List of Asset versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Asset>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** The file size */
  size?: Maybe<Scalars['Float']['output']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String']['output'];
  /** The file width */
  width?: Maybe<Scalars['Float']['output']>;
};


/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};

export type AssetComponent = Entity & {
  __typename?: 'AssetComponent';
  asset: Asset;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System stage field */
  stage: Stage;
};


export type AssetComponentAssetArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AssetComponentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetComponentWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetComponentConnection = {
  __typename?: 'AssetComponentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetComponentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetComponentCreateInput = {
  asset: AssetCreateOneInlineInput;
};

export type AssetComponentCreateManyInlineInput = {
  /** Create and connect multiple existing AssetComponent documents */
  create?: InputMaybe<Array<AssetComponentCreateInput>>;
};

export type AssetComponentCreateOneInlineInput = {
  /** Create and connect one AssetComponent document */
  create?: InputMaybe<AssetComponentCreateInput>;
};

export type AssetComponentCreateWithPositionInput = {
  /** Document to create */
  data: AssetComponentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AssetComponentEdge = {
  __typename?: 'AssetComponentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AssetComponent;
};

/** Identifies documents */
export type AssetComponentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  asset?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssetComponentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type AssetComponentParent = Image | Video;

export type AssetComponentParentConnectInput = {
  Image?: InputMaybe<ImageConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type AssetComponentParentCreateInput = {
  Image?: InputMaybe<ImageCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type AssetComponentParentCreateManyInlineInput = {
  /** Connect multiple existing AssetComponentParent documents */
  connect?: InputMaybe<Array<AssetComponentParentWhereUniqueInput>>;
  /** Create and connect multiple existing AssetComponentParent documents */
  create?: InputMaybe<Array<AssetComponentParentCreateInput>>;
};

export type AssetComponentParentCreateOneInlineInput = {
  /** Connect one existing AssetComponentParent document */
  connect?: InputMaybe<AssetComponentParentWhereUniqueInput>;
  /** Create and connect one AssetComponentParent document */
  create?: InputMaybe<AssetComponentParentCreateInput>;
};

export type AssetComponentParentUpdateInput = {
  Image?: InputMaybe<ImageUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type AssetComponentParentUpdateManyInlineInput = {
  /** Connect multiple existing AssetComponentParent documents */
  connect?: InputMaybe<Array<AssetComponentParentConnectInput>>;
  /** Create and connect multiple AssetComponentParent documents */
  create?: InputMaybe<Array<AssetComponentParentCreateInput>>;
  /** Delete multiple AssetComponentParent documents */
  delete?: InputMaybe<Array<AssetComponentParentWhereUniqueInput>>;
  /** Disconnect multiple AssetComponentParent documents */
  disconnect?: InputMaybe<Array<AssetComponentParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AssetComponentParent documents */
  set?: InputMaybe<Array<AssetComponentParentWhereUniqueInput>>;
  /** Update multiple AssetComponentParent documents */
  update?: InputMaybe<Array<AssetComponentParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AssetComponentParent documents */
  upsert?: InputMaybe<Array<AssetComponentParentUpsertWithNestedWhereUniqueInput>>;
};

export type AssetComponentParentUpdateManyWithNestedWhereInput = {
  Image?: InputMaybe<ImageUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type AssetComponentParentUpdateOneInlineInput = {
  /** Connect existing AssetComponentParent document */
  connect?: InputMaybe<AssetComponentParentWhereUniqueInput>;
  /** Create and connect one AssetComponentParent document */
  create?: InputMaybe<AssetComponentParentCreateInput>;
  /** Delete currently connected AssetComponentParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected AssetComponentParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AssetComponentParent document */
  update?: InputMaybe<AssetComponentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AssetComponentParent document */
  upsert?: InputMaybe<AssetComponentParentUpsertWithNestedWhereUniqueInput>;
};

export type AssetComponentParentUpdateWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type AssetComponentParentUpsertWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type AssetComponentParentWhereInput = {
  Image?: InputMaybe<ImageWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type AssetComponentParentWhereUniqueInput = {
  Image?: InputMaybe<ImageWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type AssetComponentUpdateInput = {
  asset?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type AssetComponentUpdateManyInlineInput = {
  /** Create and connect multiple AssetComponent component instances */
  create?: InputMaybe<Array<AssetComponentCreateWithPositionInput>>;
  /** Delete multiple AssetComponent documents */
  delete?: InputMaybe<Array<AssetComponentWhereUniqueInput>>;
  /** Update multiple AssetComponent component instances */
  update?: InputMaybe<Array<AssetComponentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple AssetComponent component instances */
  upsert?: InputMaybe<Array<AssetComponentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type AssetComponentUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type AssetComponentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetComponentUpdateManyInput;
  /** Document search */
  where: AssetComponentWhereInput;
};

export type AssetComponentUpdateOneInlineInput = {
  /** Create and connect one AssetComponent document */
  create?: InputMaybe<AssetComponentCreateInput>;
  /** Delete currently connected AssetComponent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single AssetComponent document */
  update?: InputMaybe<AssetComponentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AssetComponent document */
  upsert?: InputMaybe<AssetComponentUpsertWithNestedWhereUniqueInput>;
};

export type AssetComponentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AssetComponentUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AssetComponentWhereUniqueInput;
};

export type AssetComponentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetComponentUpdateInput;
  /** Unique document search */
  where: AssetComponentWhereUniqueInput;
};

export type AssetComponentUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetComponentCreateInput;
  /** Update document if it exists */
  update: AssetComponentUpdateInput;
};

export type AssetComponentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AssetComponentUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AssetComponentWhereUniqueInput;
};

export type AssetComponentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetComponentUpsertInput;
  /** Unique document search */
  where: AssetComponentWhereUniqueInput;
};

/** Identifies documents */
export type AssetComponentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  asset?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

/** References AssetComponent record uniquely */
export type AssetComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  assetAssetComponent?: InputMaybe<AssetComponentCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName: Scalars['String']['input'];
  handle: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  iconAccordionSml?: InputMaybe<AccordionSmlCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName: Scalars['String']['input'];
  handle: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AssetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC'
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssetUpdateInput = {
  assetAssetComponent?: InputMaybe<AssetComponentUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  iconAccordionSml?: InputMaybe<AccordionSmlUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars['String']['input']>;
  handle?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  width?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Banner = Entity & Node & {
  __typename?: 'Banner';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Banner>;
  domains: Array<Scalars['String']['output']>;
  /** List of Banner versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  slides: Array<BannerSlide>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  utmSource: Scalars['String']['output'];
};


export type BannerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BannerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type BannerHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type BannerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BannerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type BannerSlidesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BannerSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BannerSlideWhereInput>;
};


export type BannerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type BannerConnection = {
  __typename?: 'BannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BannerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  domains: Array<Scalars['String']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BannerCreateLocalizationsInput>;
  slides?: InputMaybe<BannerSlideCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  utmSource: Scalars['String']['input'];
};

export type BannerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BannerCreateLocalizationInput = {
  /** Localization input */
  data: BannerCreateLocalizationDataInput;
  locale: Locale;
};

export type BannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BannerCreateLocalizationInput>>;
};

export type BannerCreateManyInlineInput = {
  /** Connect multiple existing Banner documents */
  connect?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Create and connect multiple existing Banner documents */
  create?: InputMaybe<Array<BannerCreateInput>>;
};

export type BannerCreateOneInlineInput = {
  /** Connect one existing Banner document */
  connect?: InputMaybe<BannerWhereUniqueInput>;
  /** Create and connect one Banner document */
  create?: InputMaybe<BannerCreateInput>;
};

/** An edge in a connection. */
export type BannerEdge = {
  __typename?: 'BannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Banner;
};

/** Identifies documents */
export type BannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BannerWhereStageInput>;
  documentInStages_none?: InputMaybe<BannerWhereStageInput>;
  documentInStages_some?: InputMaybe<BannerWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  domains_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  domains_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  domains_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  domains_not?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slides_every?: InputMaybe<BannerSlideWhereInput>;
  slides_none?: InputMaybe<BannerSlideWhereInput>;
  slides_some?: InputMaybe<BannerSlideWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  utmSource_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  utmSource_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  utmSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  utmSource_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  utmSource_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  utmSource_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  utmSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  utmSource_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  utmSource_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum BannerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DomainsAsc = 'domains_ASC',
  DomainsDesc = 'domains_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UtmSourceAsc = 'utmSource_ASC',
  UtmSourceDesc = 'utmSource_DESC'
}

export type BannerSlide = Entity & {
  __typename?: 'BannerSlide';
  ctaLabel: Scalars['String']['output'];
  ctaLink: Link;
  displayDuration: Scalars['Int']['output'];
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageDesktop?: Maybe<Image>;
  imageMobile?: Maybe<Image>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BannerSlide>;
  searchTermString?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String']['output'];
};


export type BannerSlideCtaLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BannerSlideImageDesktopArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BannerSlideImageMobileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BannerSlideLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type BannerSlideConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BannerSlideWhereUniqueInput;
};

/** A connection to a list of items. */
export type BannerSlideConnection = {
  __typename?: 'BannerSlideConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BannerSlideEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BannerSlideCreateInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel: Scalars['String']['input'];
  ctaLink: LinkCreateOneInlineInput;
  displayDuration: Scalars['Int']['input'];
  imageDesktop?: InputMaybe<ImageCreateOneInlineInput>;
  imageMobile?: InputMaybe<ImageCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BannerSlideCreateLocalizationsInput>;
  searchTermString?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title: Scalars['String']['input'];
};

export type BannerSlideCreateLocalizationDataInput = {
  ctaLabel: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type BannerSlideCreateLocalizationInput = {
  /** Localization input */
  data: BannerSlideCreateLocalizationDataInput;
  locale: Locale;
};

export type BannerSlideCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BannerSlideCreateLocalizationInput>>;
};

export type BannerSlideCreateManyInlineInput = {
  /** Create and connect multiple existing BannerSlide documents */
  create?: InputMaybe<Array<BannerSlideCreateInput>>;
};

export type BannerSlideCreateOneInlineInput = {
  /** Create and connect one BannerSlide document */
  create?: InputMaybe<BannerSlideCreateInput>;
};

export type BannerSlideCreateWithPositionInput = {
  /** Document to create */
  data: BannerSlideCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BannerSlideEdge = {
  __typename?: 'BannerSlideEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BannerSlide;
};

/** Identifies documents */
export type BannerSlideManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  displayDuration_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  displayDuration_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  displayDuration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  displayDuration_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  displayDuration_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  displayDuration_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  displayDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
  searchTermString?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  searchTermString_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  searchTermString_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  searchTermString_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  searchTermString_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  searchTermString_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  searchTermString_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  searchTermString_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  searchTermString_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  searchTermString_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum BannerSlideOrderByInput {
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DisplayDurationAsc = 'displayDuration_ASC',
  DisplayDurationDesc = 'displayDuration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SearchTermStringAsc = 'searchTermString_ASC',
  SearchTermStringDesc = 'searchTermString_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type BannerSlideParent = Banner | ContentConfiguration;

export type BannerSlideParentConnectInput = {
  Banner?: InputMaybe<BannerConnectInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationConnectInput>;
};

export type BannerSlideParentCreateInput = {
  Banner?: InputMaybe<BannerCreateInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationCreateInput>;
};

export type BannerSlideParentCreateManyInlineInput = {
  /** Connect multiple existing BannerSlideParent documents */
  connect?: InputMaybe<Array<BannerSlideParentWhereUniqueInput>>;
  /** Create and connect multiple existing BannerSlideParent documents */
  create?: InputMaybe<Array<BannerSlideParentCreateInput>>;
};

export type BannerSlideParentCreateOneInlineInput = {
  /** Connect one existing BannerSlideParent document */
  connect?: InputMaybe<BannerSlideParentWhereUniqueInput>;
  /** Create and connect one BannerSlideParent document */
  create?: InputMaybe<BannerSlideParentCreateInput>;
};

export type BannerSlideParentUpdateInput = {
  Banner?: InputMaybe<BannerUpdateInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateInput>;
};

export type BannerSlideParentUpdateManyInlineInput = {
  /** Connect multiple existing BannerSlideParent documents */
  connect?: InputMaybe<Array<BannerSlideParentConnectInput>>;
  /** Create and connect multiple BannerSlideParent documents */
  create?: InputMaybe<Array<BannerSlideParentCreateInput>>;
  /** Delete multiple BannerSlideParent documents */
  delete?: InputMaybe<Array<BannerSlideParentWhereUniqueInput>>;
  /** Disconnect multiple BannerSlideParent documents */
  disconnect?: InputMaybe<Array<BannerSlideParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BannerSlideParent documents */
  set?: InputMaybe<Array<BannerSlideParentWhereUniqueInput>>;
  /** Update multiple BannerSlideParent documents */
  update?: InputMaybe<Array<BannerSlideParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BannerSlideParent documents */
  upsert?: InputMaybe<Array<BannerSlideParentUpsertWithNestedWhereUniqueInput>>;
};

export type BannerSlideParentUpdateManyWithNestedWhereInput = {
  Banner?: InputMaybe<BannerUpdateManyWithNestedWhereInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateManyWithNestedWhereInput>;
};

export type BannerSlideParentUpdateOneInlineInput = {
  /** Connect existing BannerSlideParent document */
  connect?: InputMaybe<BannerSlideParentWhereUniqueInput>;
  /** Create and connect one BannerSlideParent document */
  create?: InputMaybe<BannerSlideParentCreateInput>;
  /** Delete currently connected BannerSlideParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected BannerSlideParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BannerSlideParent document */
  update?: InputMaybe<BannerSlideParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BannerSlideParent document */
  upsert?: InputMaybe<BannerSlideParentUpsertWithNestedWhereUniqueInput>;
};

export type BannerSlideParentUpdateWithNestedWhereUniqueInput = {
  Banner?: InputMaybe<BannerUpdateWithNestedWhereUniqueInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateWithNestedWhereUniqueInput>;
};

export type BannerSlideParentUpsertWithNestedWhereUniqueInput = {
  Banner?: InputMaybe<BannerUpsertWithNestedWhereUniqueInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationUpsertWithNestedWhereUniqueInput>;
};

export type BannerSlideParentWhereInput = {
  Banner?: InputMaybe<BannerWhereInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationWhereInput>;
};

export type BannerSlideParentWhereUniqueInput = {
  Banner?: InputMaybe<BannerWhereUniqueInput>;
  ContentConfiguration?: InputMaybe<ContentConfigurationWhereUniqueInput>;
};

export type BannerSlideUpdateInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkUpdateOneInlineInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  imageDesktop?: InputMaybe<ImageUpdateOneInlineInput>;
  imageMobile?: InputMaybe<ImageUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<BannerSlideUpdateLocalizationsInput>;
  searchTermString?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BannerSlideUpdateLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BannerSlideUpdateLocalizationInput = {
  data: BannerSlideUpdateLocalizationDataInput;
  locale: Locale;
};

export type BannerSlideUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BannerSlideCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BannerSlideUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BannerSlideUpsertLocalizationInput>>;
};

export type BannerSlideUpdateManyInlineInput = {
  /** Create and connect multiple BannerSlide component instances */
  create?: InputMaybe<Array<BannerSlideCreateWithPositionInput>>;
  /** Delete multiple BannerSlide documents */
  delete?: InputMaybe<Array<BannerSlideWhereUniqueInput>>;
  /** Update multiple BannerSlide component instances */
  update?: InputMaybe<Array<BannerSlideUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple BannerSlide component instances */
  upsert?: InputMaybe<Array<BannerSlideUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type BannerSlideUpdateManyInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BannerSlideUpdateManyLocalizationsInput>;
  searchTermString?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BannerSlideUpdateManyLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BannerSlideUpdateManyLocalizationInput = {
  data: BannerSlideUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BannerSlideUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BannerSlideUpdateManyLocalizationInput>>;
};

export type BannerSlideUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BannerSlideUpdateManyInput;
  /** Document search */
  where: BannerSlideWhereInput;
};

export type BannerSlideUpdateOneInlineInput = {
  /** Create and connect one BannerSlide document */
  create?: InputMaybe<BannerSlideCreateInput>;
  /** Delete currently connected BannerSlide document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BannerSlide document */
  update?: InputMaybe<BannerSlideUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BannerSlide document */
  upsert?: InputMaybe<BannerSlideUpsertWithNestedWhereUniqueInput>;
};

export type BannerSlideUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BannerSlideUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BannerSlideWhereUniqueInput;
};

export type BannerSlideUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BannerSlideUpdateInput;
  /** Unique document search */
  where: BannerSlideWhereUniqueInput;
};

export type BannerSlideUpsertInput = {
  /** Create document if it didn't exist */
  create: BannerSlideCreateInput;
  /** Update document if it exists */
  update: BannerSlideUpdateInput;
};

export type BannerSlideUpsertLocalizationInput = {
  create: BannerSlideCreateLocalizationDataInput;
  locale: Locale;
  update: BannerSlideUpdateLocalizationDataInput;
};

export type BannerSlideUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BannerSlideUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BannerSlideWhereUniqueInput;
};

export type BannerSlideUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BannerSlideUpsertInput;
  /** Unique document search */
  where: BannerSlideWhereUniqueInput;
};

/** Identifies documents */
export type BannerSlideWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerSlideWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  ctaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  ctaLabel_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  ctaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ctaLabel_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  ctaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  ctaLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  ctaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  ctaLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  ctaLabel_starts_with?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  displayDuration_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  displayDuration_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  displayDuration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  displayDuration_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  displayDuration_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  displayDuration_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  displayDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
  searchTermString?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  searchTermString_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  searchTermString_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  searchTermString_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  searchTermString_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  searchTermString_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  searchTermString_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  searchTermString_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  searchTermString_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  searchTermString_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References BannerSlide record uniquely */
export type BannerSlideWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type BannerUpdateInput = {
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Manage document localizations */
  localizations?: InputMaybe<BannerUpdateLocalizationsInput>;
  slides?: InputMaybe<BannerSlideUpdateManyInlineInput>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type BannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BannerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type BannerUpdateManyInlineInput = {
  /** Connect multiple existing Banner documents */
  connect?: InputMaybe<Array<BannerConnectInput>>;
  /** Create and connect multiple Banner documents */
  create?: InputMaybe<Array<BannerCreateInput>>;
  /** Delete multiple Banner documents */
  delete?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Disconnect multiple Banner documents */
  disconnect?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Banner documents */
  set?: InputMaybe<Array<BannerWhereUniqueInput>>;
  /** Update multiple Banner documents */
  update?: InputMaybe<Array<BannerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Banner documents */
  upsert?: InputMaybe<Array<BannerUpsertWithNestedWhereUniqueInput>>;
};

export type BannerUpdateManyInput = {
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type BannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BannerUpdateManyInput;
  /** Document search */
  where: BannerWhereInput;
};

export type BannerUpdateOneInlineInput = {
  /** Connect existing Banner document */
  connect?: InputMaybe<BannerWhereUniqueInput>;
  /** Create and connect one Banner document */
  create?: InputMaybe<BannerCreateInput>;
  /** Delete currently connected Banner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Banner document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Banner document */
  update?: InputMaybe<BannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Banner document */
  upsert?: InputMaybe<BannerUpsertWithNestedWhereUniqueInput>;
};

export type BannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BannerUpdateInput;
  /** Unique document search */
  where: BannerWhereUniqueInput;
};

export type BannerUpsertInput = {
  /** Create document if it didn't exist */
  create: BannerCreateInput;
  /** Update document if it exists */
  update: BannerUpdateInput;
};

export type BannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BannerUpsertInput;
  /** Unique document search */
  where: BannerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BannerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type BannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BannerWhereStageInput>;
  documentInStages_none?: InputMaybe<BannerWhereStageInput>;
  documentInStages_some?: InputMaybe<BannerWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  domains_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  domains_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  domains_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  domains_not?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slides_every?: InputMaybe<BannerSlideWhereInput>;
  slides_none?: InputMaybe<BannerSlideWhereInput>;
  slides_some?: InputMaybe<BannerSlideWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  utmSource_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  utmSource_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  utmSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  utmSource_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  utmSource_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  utmSource_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  utmSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  utmSource_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  utmSource_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BannerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BannerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Banner record uniquely */
export type BannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long']['output'];
};

/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyType = Entity & Node & {
  __typename?: 'BodyType';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<BodyType>;
  group?: Maybe<BodyTypeGroup>;
  /** List of BodyType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BodyType>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Compact, Convertible, Bus, Coupe, etc. */
export type BodyTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BodyTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BodyTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type BodyTypeConnection = {
  __typename?: 'BodyTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BodyTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BodyTypeCreateInput = {
  cl16i847fhshj01ywc2ff74bs?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<BodyTypeGroupCreateOneInlineInput>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BodyTypeCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BodyTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BodyTypeCreateLocalizationInput = {
  /** Localization input */
  data: BodyTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BodyTypeCreateLocalizationInput>>;
};

export type BodyTypeCreateManyInlineInput = {
  /** Connect multiple existing BodyType documents */
  connect?: InputMaybe<Array<BodyTypeWhereUniqueInput>>;
  /** Create and connect multiple existing BodyType documents */
  create?: InputMaybe<Array<BodyTypeCreateInput>>;
};

export type BodyTypeCreateOneInlineInput = {
  /** Connect one existing BodyType document */
  connect?: InputMaybe<BodyTypeWhereUniqueInput>;
  /** Create and connect one BodyType document */
  create?: InputMaybe<BodyTypeCreateInput>;
};

/** An edge in a connection. */
export type BodyTypeEdge = {
  __typename?: 'BodyTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BodyType;
};

export type BodyTypeGroup = Entity & Node & {
  __typename?: 'BodyTypeGroup';
  basicFilterValue: Scalars['Boolean']['output'];
  bodyTypes: Array<BodyType>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<BodyTypeGroup>;
  groupId: Scalars['Int']['output'];
  /** List of BodyTypeGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BodyTypeGroup>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type BodyTypeGroupBodyTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BodyTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeWhereInput>;
};


export type BodyTypeGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BodyTypeGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BodyTypeGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type BodyTypeGroupHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type BodyTypeGroupKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type BodyTypeGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type BodyTypeGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BodyTypeGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BodyTypeGroupScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type BodyTypeGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BodyTypeGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BodyTypeGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BodyTypeGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type BodyTypeGroupConnection = {
  __typename?: 'BodyTypeGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BodyTypeGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BodyTypeGroupCreateInput = {
  basicFilterValue: Scalars['Boolean']['input'];
  bodyTypes?: InputMaybe<BodyTypeCreateManyInlineInput>;
  cltsf3ke101b206vy55pjeg7r?: InputMaybe<AdTargetTileCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  groupId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BodyTypeGroupCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BodyTypeGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BodyTypeGroupCreateLocalizationInput = {
  /** Localization input */
  data: BodyTypeGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BodyTypeGroupCreateLocalizationInput>>;
};

export type BodyTypeGroupCreateManyInlineInput = {
  /** Connect multiple existing BodyTypeGroup documents */
  connect?: InputMaybe<Array<BodyTypeGroupWhereUniqueInput>>;
  /** Create and connect multiple existing BodyTypeGroup documents */
  create?: InputMaybe<Array<BodyTypeGroupCreateInput>>;
};

export type BodyTypeGroupCreateOneInlineInput = {
  /** Connect one existing BodyTypeGroup document */
  connect?: InputMaybe<BodyTypeGroupWhereUniqueInput>;
  /** Create and connect one BodyTypeGroup document */
  create?: InputMaybe<BodyTypeGroupCreateInput>;
};

/** An edge in a connection. */
export type BodyTypeGroupEdge = {
  __typename?: 'BodyTypeGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BodyTypeGroup;
};

/** Identifies documents */
export type BodyTypeGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  bodyTypes_every?: InputMaybe<BodyTypeWhereInput>;
  bodyTypes_none?: InputMaybe<BodyTypeWhereInput>;
  bodyTypes_some?: InputMaybe<BodyTypeWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BodyTypeGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<BodyTypeGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<BodyTypeGroupWhereStageInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BodyTypeGroupOrderByInput {
  BasicFilterValueAsc = 'basicFilterValue_ASC',
  BasicFilterValueDesc = 'basicFilterValue_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GroupIdAsc = 'groupId_ASC',
  GroupIdDesc = 'groupId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BodyTypeGroupUpdateInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  bodyTypes?: InputMaybe<BodyTypeUpdateManyInlineInput>;
  cltsf3ke101b206vy55pjeg7r?: InputMaybe<AdTargetTileUpdateManyInlineInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<BodyTypeGroupUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeGroupUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeGroupUpdateLocalizationInput = {
  data: BodyTypeGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BodyTypeGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BodyTypeGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BodyTypeGroupUpsertLocalizationInput>>;
};

export type BodyTypeGroupUpdateManyInlineInput = {
  /** Connect multiple existing BodyTypeGroup documents */
  connect?: InputMaybe<Array<BodyTypeGroupConnectInput>>;
  /** Create and connect multiple BodyTypeGroup documents */
  create?: InputMaybe<Array<BodyTypeGroupCreateInput>>;
  /** Delete multiple BodyTypeGroup documents */
  delete?: InputMaybe<Array<BodyTypeGroupWhereUniqueInput>>;
  /** Disconnect multiple BodyTypeGroup documents */
  disconnect?: InputMaybe<Array<BodyTypeGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BodyTypeGroup documents */
  set?: InputMaybe<Array<BodyTypeGroupWhereUniqueInput>>;
  /** Update multiple BodyTypeGroup documents */
  update?: InputMaybe<Array<BodyTypeGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BodyTypeGroup documents */
  upsert?: InputMaybe<Array<BodyTypeGroupUpsertWithNestedWhereUniqueInput>>;
};

export type BodyTypeGroupUpdateManyInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BodyTypeGroupUpdateManyLocalizationsInput>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeGroupUpdateManyLocalizationDataInput = {
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeGroupUpdateManyLocalizationInput = {
  data: BodyTypeGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BodyTypeGroupUpdateManyLocalizationInput>>;
};

export type BodyTypeGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BodyTypeGroupUpdateManyInput;
  /** Document search */
  where: BodyTypeGroupWhereInput;
};

export type BodyTypeGroupUpdateOneInlineInput = {
  /** Connect existing BodyTypeGroup document */
  connect?: InputMaybe<BodyTypeGroupWhereUniqueInput>;
  /** Create and connect one BodyTypeGroup document */
  create?: InputMaybe<BodyTypeGroupCreateInput>;
  /** Delete currently connected BodyTypeGroup document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected BodyTypeGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BodyTypeGroup document */
  update?: InputMaybe<BodyTypeGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BodyTypeGroup document */
  upsert?: InputMaybe<BodyTypeGroupUpsertWithNestedWhereUniqueInput>;
};

export type BodyTypeGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BodyTypeGroupUpdateInput;
  /** Unique document search */
  where: BodyTypeGroupWhereUniqueInput;
};

export type BodyTypeGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: BodyTypeGroupCreateInput;
  /** Update document if it exists */
  update: BodyTypeGroupUpdateInput;
};

export type BodyTypeGroupUpsertLocalizationInput = {
  create: BodyTypeGroupCreateLocalizationDataInput;
  locale: Locale;
  update: BodyTypeGroupUpdateLocalizationDataInput;
};

export type BodyTypeGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BodyTypeGroupUpsertInput;
  /** Unique document search */
  where: BodyTypeGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BodyTypeGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type BodyTypeGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  bodyTypes_every?: InputMaybe<BodyTypeWhereInput>;
  bodyTypes_none?: InputMaybe<BodyTypeWhereInput>;
  bodyTypes_some?: InputMaybe<BodyTypeWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BodyTypeGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<BodyTypeGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<BodyTypeGroupWhereStageInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BodyTypeGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BodyTypeGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References BodyTypeGroup record uniquely */
export type BodyTypeGroupWhereUniqueInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type BodyTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BodyTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<BodyTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<BodyTypeWhereStageInput>;
  group?: InputMaybe<BodyTypeGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BodyTypeOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BodyTypeUpdateInput = {
  cl16i847fhshj01ywc2ff74bs?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<BodyTypeGroupUpdateOneInlineInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<BodyTypeUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeUpdateLocalizationInput = {
  data: BodyTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BodyTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BodyTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BodyTypeUpsertLocalizationInput>>;
};

export type BodyTypeUpdateManyInlineInput = {
  /** Connect multiple existing BodyType documents */
  connect?: InputMaybe<Array<BodyTypeConnectInput>>;
  /** Create and connect multiple BodyType documents */
  create?: InputMaybe<Array<BodyTypeCreateInput>>;
  /** Delete multiple BodyType documents */
  delete?: InputMaybe<Array<BodyTypeWhereUniqueInput>>;
  /** Disconnect multiple BodyType documents */
  disconnect?: InputMaybe<Array<BodyTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BodyType documents */
  set?: InputMaybe<Array<BodyTypeWhereUniqueInput>>;
  /** Update multiple BodyType documents */
  update?: InputMaybe<Array<BodyTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BodyType documents */
  upsert?: InputMaybe<Array<BodyTypeUpsertWithNestedWhereUniqueInput>>;
};

export type BodyTypeUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BodyTypeUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type BodyTypeUpdateManyLocalizationInput = {
  data: BodyTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BodyTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BodyTypeUpdateManyLocalizationInput>>;
};

export type BodyTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BodyTypeUpdateManyInput;
  /** Document search */
  where: BodyTypeWhereInput;
};

export type BodyTypeUpdateOneInlineInput = {
  /** Connect existing BodyType document */
  connect?: InputMaybe<BodyTypeWhereUniqueInput>;
  /** Create and connect one BodyType document */
  create?: InputMaybe<BodyTypeCreateInput>;
  /** Delete currently connected BodyType document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected BodyType document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BodyType document */
  update?: InputMaybe<BodyTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BodyType document */
  upsert?: InputMaybe<BodyTypeUpsertWithNestedWhereUniqueInput>;
};

export type BodyTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BodyTypeUpdateInput;
  /** Unique document search */
  where: BodyTypeWhereUniqueInput;
};

export type BodyTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: BodyTypeCreateInput;
  /** Update document if it exists */
  update: BodyTypeUpdateInput;
};

export type BodyTypeUpsertLocalizationInput = {
  create: BodyTypeCreateLocalizationDataInput;
  locale: Locale;
  update: BodyTypeUpdateLocalizationDataInput;
};

export type BodyTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BodyTypeUpsertInput;
  /** Unique document search */
  where: BodyTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BodyTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type BodyTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<BodyTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<BodyTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<BodyTypeWhereStageInput>;
  group?: InputMaybe<BodyTypeGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BodyTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BodyTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BodyTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BodyTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BodyTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References BodyType record uniquely */
export type BodyTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Brand = Entity & Node & {
  __typename?: 'Brand';
  campaignBanners: Array<Ad>;
  code?: Maybe<Scalars['String']['output']>;
  contentTeaserHeadline?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealers: Array<Dealer>;
  description?: Maybe<Scalars['String']['output']>;
  /** Get the document in other stages */
  documentInStages: Array<Brand>;
  extended: Scalars['Boolean']['output'];
  heroPictureAltText?: Maybe<Scalars['String']['output']>;
  heroPictureUrl?: Maybe<Scalars['String']['output']>;
  /** List of Brand versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Brand>;
  logoPictureUrl?: Maybe<Scalars['String']['output']>;
  mipsId?: Maybe<Scalars['Int']['output']>;
  models: Array<Model>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  paPromotions: Array<PaPromotion>;
  pageExtension?: Maybe<GeneratedPageExtension>;
  pageModelsExtension?: Maybe<GeneratedPageExtension>;
  pagePromotionsExtension?: Maybe<GeneratedPageExtension>;
  pageVehiclesExtension?: Maybe<GeneratedPageExtension>;
  pages: Array<Page>;
  popular: Scalars['Boolean']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  qualityCheckeds: Array<QualityChecked>;
  scheduledIn: Array<ScheduledOperation>;
  showOnAllBrands: Scalars['Boolean']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type BrandCampaignBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AdOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdWhereInput>;
};


export type BrandCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BrandCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandDealersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type BrandDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type BrandHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type BrandKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type BrandLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type BrandModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelWhereInput>;
};


export type BrandPaPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PaPromotionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaPromotionWhereInput>;
};


export type BrandPageExtensionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandPageModelsExtensionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandPagePromotionsExtensionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandPageVehiclesExtensionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type BrandPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BrandPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandQualityCheckedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<QualityCheckedOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QualityCheckedWhereInput>;
};


export type BrandScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type BrandUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type BrandUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BrandConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BrandWhereUniqueInput;
};

/** A connection to a list of items. */
export type BrandConnection = {
  __typename?: 'BrandConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BrandEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BrandCreateInput = {
  campaignBanners?: InputMaybe<AdCreateManyInlineInput>;
  cl16ai8hyhmos01za1vey8a0r?: InputMaybe<VehicleCreateManyInlineInput>;
  cll54ta72092l01uo4gmjg5im?: InputMaybe<BrandLinkCreateManyInlineInput>;
  cltflnsi000x107w0gr9e209t?: InputMaybe<SalesAdvisorCreateManyInlineInput>;
  cltsf2e9h018s06vy23y6fi0b?: InputMaybe<AdTargetTileCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** contentTeaserHeadline input for default locale (de) */
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealers?: InputMaybe<DealerCreateManyInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  extended: Scalars['Boolean']['input'];
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BrandCreateLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<ModelCreateManyInlineInput>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  paPromotions?: InputMaybe<PaPromotionCreateManyInlineInput>;
  pageExtension?: InputMaybe<GeneratedPageExtensionCreateOneInlineInput>;
  pageModelsExtension?: InputMaybe<GeneratedPageExtensionCreateOneInlineInput>;
  pagePromotionsExtension?: InputMaybe<GeneratedPageExtensionCreateOneInlineInput>;
  pageVehiclesExtension?: InputMaybe<GeneratedPageExtensionCreateOneInlineInput>;
  pages?: InputMaybe<PageCreateManyInlineInput>;
  popular: Scalars['Boolean']['input'];
  qualityCheckeds?: InputMaybe<QualityCheckedCreateManyInlineInput>;
  showOnAllBrands: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BrandCreateLocalizationDataInput = {
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BrandCreateLocalizationInput = {
  /** Localization input */
  data: BrandCreateLocalizationDataInput;
  locale: Locale;
};

export type BrandCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BrandCreateLocalizationInput>>;
};

export type BrandCreateManyInlineInput = {
  /** Connect multiple existing Brand documents */
  connect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  /** Create and connect multiple existing Brand documents */
  create?: InputMaybe<Array<BrandCreateInput>>;
};

export type BrandCreateOneInlineInput = {
  /** Connect one existing Brand document */
  connect?: InputMaybe<BrandWhereUniqueInput>;
  /** Create and connect one Brand document */
  create?: InputMaybe<BrandCreateInput>;
};

/** An edge in a connection. */
export type BrandEdge = {
  __typename?: 'BrandEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Brand;
};

export type BrandLink = Entity & {
  __typename?: 'BrandLink';
  brand?: Maybe<Brand>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BrandLink>;
  name?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
};


export type BrandLinkBrandArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type BrandLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type BrandLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BrandLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type BrandLinkConnection = {
  __typename?: 'BrandLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BrandLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BrandLinkCreateInput = {
  brand?: InputMaybe<BrandCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BrandLinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkCreateLocalizationInput = {
  /** Localization input */
  data: BrandLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type BrandLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BrandLinkCreateLocalizationInput>>;
};

export type BrandLinkCreateManyInlineInput = {
  /** Create and connect multiple existing BrandLink documents */
  create?: InputMaybe<Array<BrandLinkCreateInput>>;
};

export type BrandLinkCreateOneInlineInput = {
  /** Create and connect one BrandLink document */
  create?: InputMaybe<BrandLinkCreateInput>;
};

export type BrandLinkCreateWithPositionInput = {
  /** Document to create */
  data: BrandLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BrandLinkEdge = {
  __typename?: 'BrandLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BrandLink;
};

/** Identifies documents */
export type BrandLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum BrandLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type BrandLinkParent = Link;

export type BrandLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type BrandLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type BrandLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing BrandLinkParent documents */
  create?: InputMaybe<Array<BrandLinkParentCreateInput>>;
};

export type BrandLinkParentCreateOneInlineInput = {
  /** Create and connect one BrandLinkParent document */
  create?: InputMaybe<BrandLinkParentCreateInput>;
};

export type BrandLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type BrandLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type BrandLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple BrandLinkParent component instances */
  create?: InputMaybe<Array<BrandLinkParentCreateWithPositionInput>>;
  /** Delete multiple BrandLinkParent documents */
  delete?: InputMaybe<Array<BrandLinkParentWhereUniqueInput>>;
  /** Update multiple BrandLinkParent component instances */
  update?: InputMaybe<Array<BrandLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple BrandLinkParent component instances */
  upsert?: InputMaybe<Array<BrandLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type BrandLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type BrandLinkParentUpdateOneInlineInput = {
  /** Create and connect one BrandLinkParent document */
  create?: InputMaybe<BrandLinkParentCreateInput>;
  /** Delete currently connected BrandLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BrandLinkParent document */
  update?: InputMaybe<BrandLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BrandLinkParent document */
  upsert?: InputMaybe<BrandLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type BrandLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type BrandLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type BrandLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type BrandLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type BrandLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type BrandLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type BrandLinkUpdateInput = {
  brand?: InputMaybe<BrandUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<BrandLinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkUpdateLocalizationInput = {
  data: BrandLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type BrandLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BrandLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BrandLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BrandLinkUpsertLocalizationInput>>;
};

export type BrandLinkUpdateManyInlineInput = {
  /** Create and connect multiple BrandLink component instances */
  create?: InputMaybe<Array<BrandLinkCreateWithPositionInput>>;
  /** Delete multiple BrandLink documents */
  delete?: InputMaybe<Array<BrandLinkWhereUniqueInput>>;
  /** Update multiple BrandLink component instances */
  update?: InputMaybe<Array<BrandLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple BrandLink component instances */
  upsert?: InputMaybe<Array<BrandLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type BrandLinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BrandLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandLinkUpdateManyLocalizationInput = {
  data: BrandLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BrandLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BrandLinkUpdateManyLocalizationInput>>;
};

export type BrandLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BrandLinkUpdateManyInput;
  /** Document search */
  where: BrandLinkWhereInput;
};

export type BrandLinkUpdateOneInlineInput = {
  /** Create and connect one BrandLink document */
  create?: InputMaybe<BrandLinkCreateInput>;
  /** Delete currently connected BrandLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single BrandLink document */
  update?: InputMaybe<BrandLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BrandLink document */
  upsert?: InputMaybe<BrandLinkUpsertWithNestedWhereUniqueInput>;
};

export type BrandLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BrandLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BrandLinkWhereUniqueInput;
};

export type BrandLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BrandLinkUpdateInput;
  /** Unique document search */
  where: BrandLinkWhereUniqueInput;
};

export type BrandLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: BrandLinkCreateInput;
  /** Update document if it exists */
  update: BrandLinkUpdateInput;
};

export type BrandLinkUpsertLocalizationInput = {
  create: BrandLinkCreateLocalizationDataInput;
  locale: Locale;
  update: BrandLinkUpdateLocalizationDataInput;
};

export type BrandLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BrandLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BrandLinkWhereUniqueInput;
};

export type BrandLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BrandLinkUpsertInput;
  /** Unique document search */
  where: BrandLinkWhereUniqueInput;
};

/** Identifies documents */
export type BrandLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrandLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References BrandLink record uniquely */
export type BrandLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type BrandManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrandWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrandWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrandWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  documentInStages_every?: InputMaybe<BrandWhereStageInput>;
  documentInStages_none?: InputMaybe<BrandWhereStageInput>;
  documentInStages_some?: InputMaybe<BrandWhereStageInput>;
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  extended_not?: InputMaybe<Scalars['Boolean']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  mipsId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  mipsId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  mipsId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  mipsId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  mipsId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  mipsId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  mipsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  paPromotions_every?: InputMaybe<PaPromotionWhereInput>;
  paPromotions_none?: InputMaybe<PaPromotionWhereInput>;
  paPromotions_some?: InputMaybe<PaPromotionWhereInput>;
  pageExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pageModelsExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pagePromotionsExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pageVehiclesExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  popular_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityCheckeds_every?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_none?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_some?: InputMaybe<QualityCheckedWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showOnAllBrands?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showOnAllBrands_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BrandOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  ContentTeaserHeadlineAsc = 'contentTeaserHeadline_ASC',
  ContentTeaserHeadlineDesc = 'contentTeaserHeadline_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExtendedAsc = 'extended_ASC',
  ExtendedDesc = 'extended_DESC',
  HeroPictureAltTextAsc = 'heroPictureAltText_ASC',
  HeroPictureAltTextDesc = 'heroPictureAltText_DESC',
  HeroPictureUrlAsc = 'heroPictureUrl_ASC',
  HeroPictureUrlDesc = 'heroPictureUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  LogoPictureUrlAsc = 'logoPictureUrl_ASC',
  LogoPictureUrlDesc = 'logoPictureUrl_DESC',
  MipsIdAsc = 'mipsId_ASC',
  MipsIdDesc = 'mipsId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PopularAsc = 'popular_ASC',
  PopularDesc = 'popular_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowOnAllBrandsAsc = 'showOnAllBrands_ASC',
  ShowOnAllBrandsDesc = 'showOnAllBrands_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type BrandUpdateInput = {
  campaignBanners?: InputMaybe<AdUpdateManyInlineInput>;
  cl16ai8hyhmos01za1vey8a0r?: InputMaybe<VehicleUpdateManyInlineInput>;
  cll54ta72092l01uo4gmjg5im?: InputMaybe<BrandLinkUpdateManyInlineInput>;
  cltflnsi000x107w0gr9e209t?: InputMaybe<SalesAdvisorUpdateManyInlineInput>;
  cltsf2e9h018s06vy23y6fi0b?: InputMaybe<AdTargetTileUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** contentTeaserHeadline input for default locale (de) */
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  dealers?: InputMaybe<DealerUpdateManyInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<BrandUpdateLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  models?: InputMaybe<ModelUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  paPromotions?: InputMaybe<PaPromotionUpdateManyInlineInput>;
  pageExtension?: InputMaybe<GeneratedPageExtensionUpdateOneInlineInput>;
  pageModelsExtension?: InputMaybe<GeneratedPageExtensionUpdateOneInlineInput>;
  pagePromotionsExtension?: InputMaybe<GeneratedPageExtensionUpdateOneInlineInput>;
  pageVehiclesExtension?: InputMaybe<GeneratedPageExtensionUpdateOneInlineInput>;
  pages?: InputMaybe<PageUpdateManyInlineInput>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  qualityCheckeds?: InputMaybe<QualityCheckedUpdateManyInlineInput>;
  showOnAllBrands?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateLocalizationDataInput = {
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateLocalizationInput = {
  data: BrandUpdateLocalizationDataInput;
  locale: Locale;
};

export type BrandUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BrandCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BrandUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BrandUpsertLocalizationInput>>;
};

export type BrandUpdateManyInlineInput = {
  /** Connect multiple existing Brand documents */
  connect?: InputMaybe<Array<BrandConnectInput>>;
  /** Create and connect multiple Brand documents */
  create?: InputMaybe<Array<BrandCreateInput>>;
  /** Delete multiple Brand documents */
  delete?: InputMaybe<Array<BrandWhereUniqueInput>>;
  /** Disconnect multiple Brand documents */
  disconnect?: InputMaybe<Array<BrandWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Brand documents */
  set?: InputMaybe<Array<BrandWhereUniqueInput>>;
  /** Update multiple Brand documents */
  update?: InputMaybe<Array<BrandUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Brand documents */
  upsert?: InputMaybe<Array<BrandUpsertWithNestedWhereUniqueInput>>;
};

export type BrandUpdateManyInput = {
  /** contentTeaserHeadline input for default locale (de) */
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BrandUpdateManyLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  showOnAllBrands?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandUpdateManyLocalizationDataInput = {
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
};

export type BrandUpdateManyLocalizationInput = {
  data: BrandUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BrandUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BrandUpdateManyLocalizationInput>>;
};

export type BrandUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BrandUpdateManyInput;
  /** Document search */
  where: BrandWhereInput;
};

export type BrandUpdateOneInlineInput = {
  /** Connect existing Brand document */
  connect?: InputMaybe<BrandWhereUniqueInput>;
  /** Create and connect one Brand document */
  create?: InputMaybe<BrandCreateInput>;
  /** Delete currently connected Brand document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Brand document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Brand document */
  update?: InputMaybe<BrandUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Brand document */
  upsert?: InputMaybe<BrandUpsertWithNestedWhereUniqueInput>;
};

export type BrandUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BrandUpdateInput;
  /** Unique document search */
  where: BrandWhereUniqueInput;
};

export type BrandUpsertInput = {
  /** Create document if it didn't exist */
  create: BrandCreateInput;
  /** Update document if it exists */
  update: BrandUpdateInput;
};

export type BrandUpsertLocalizationInput = {
  create: BrandCreateLocalizationDataInput;
  locale: Locale;
  update: BrandUpdateLocalizationDataInput;
};

export type BrandUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BrandUpsertInput;
  /** Unique document search */
  where: BrandWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BrandWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type BrandWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrandWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrandWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrandWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  contentTeaserHeadline?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  contentTeaserHeadline_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  contentTeaserHeadline_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  contentTeaserHeadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  contentTeaserHeadline_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  contentTeaserHeadline_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  contentTeaserHeadline_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  contentTeaserHeadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  contentTeaserHeadline_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  contentTeaserHeadline_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  documentInStages_every?: InputMaybe<BrandWhereStageInput>;
  documentInStages_none?: InputMaybe<BrandWhereStageInput>;
  documentInStages_some?: InputMaybe<BrandWhereStageInput>;
  extended?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  extended_not?: InputMaybe<Scalars['Boolean']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureAltText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureAltText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureAltText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureAltText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureAltText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureAltText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureAltText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureAltText_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  mipsId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  mipsId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  mipsId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  mipsId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  mipsId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  mipsId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  mipsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  models_every?: InputMaybe<ModelWhereInput>;
  models_none?: InputMaybe<ModelWhereInput>;
  models_some?: InputMaybe<ModelWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  paPromotions_every?: InputMaybe<PaPromotionWhereInput>;
  paPromotions_none?: InputMaybe<PaPromotionWhereInput>;
  paPromotions_some?: InputMaybe<PaPromotionWhereInput>;
  pageExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pageModelsExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pagePromotionsExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pageVehiclesExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  popular?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  popular_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityCheckeds_every?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_none?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_some?: InputMaybe<QualityCheckedWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showOnAllBrands?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showOnAllBrands_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BrandWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrandWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrandWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrandWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BrandWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Brand record uniquely */
export type BrandWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Button = Entity & Node & {
  __typename?: 'Button';
  buttonRows: Array<ButtonRow>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Button>;
  /** List of Button versions */
  history: Array<Version>;
  icon?: Maybe<Icon>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  link: Link;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Button>;
  noBorder: Scalars['Boolean']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  secondary: Scalars['Boolean']['output'];
  /** System stage field */
  stage: Stage;
  transparent: Scalars['Boolean']['output'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type ButtonButtonRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ButtonRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonRowWhereInput>;
};


export type ButtonCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ButtonCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ButtonDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ButtonHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ButtonLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ButtonLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ButtonPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ButtonPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ButtonScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ButtonUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ButtonUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ButtonConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ButtonWhereUniqueInput;
};

/** A connection to a list of items. */
export type ButtonConnection = {
  __typename?: 'ButtonConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ButtonEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ButtonCreateInput = {
  buttonRows?: InputMaybe<ButtonRowCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  icon?: InputMaybe<Icon>;
  /** label input for default locale (de) */
  label: Scalars['String']['input'];
  link: LinkCreateOneInlineInput;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ButtonCreateLocalizationsInput>;
  noBorder: Scalars['Boolean']['input'];
  secondary: Scalars['Boolean']['input'];
  transparent: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ButtonCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  label: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ButtonCreateLocalizationInput = {
  /** Localization input */
  data: ButtonCreateLocalizationDataInput;
  locale: Locale;
};

export type ButtonCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ButtonCreateLocalizationInput>>;
};

export type ButtonCreateManyInlineInput = {
  /** Connect multiple existing Button documents */
  connect?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Create and connect multiple existing Button documents */
  create?: InputMaybe<Array<ButtonCreateInput>>;
};

export type ButtonCreateOneInlineInput = {
  /** Connect one existing Button document */
  connect?: InputMaybe<ButtonWhereUniqueInput>;
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
};

/** An edge in a connection. */
export type ButtonEdge = {
  __typename?: 'ButtonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Button;
};

/** Identifies documents */
export type ButtonManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttonRows_every?: InputMaybe<ButtonRowWhereInput>;
  buttonRows_none?: InputMaybe<ButtonRowWhereInput>;
  buttonRows_some?: InputMaybe<ButtonRowWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ButtonWhereStageInput>;
  documentInStages_none?: InputMaybe<ButtonWhereStageInput>;
  documentInStages_some?: InputMaybe<ButtonWhereStageInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  link?: InputMaybe<LinkWhereInput>;
  noBorder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  noBorder_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  secondary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  secondary_not?: InputMaybe<Scalars['Boolean']['input']>;
  transparent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  transparent_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ButtonOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  NoBorderAsc = 'noBorder_ASC',
  NoBorderDesc = 'noBorder_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SecondaryAsc = 'secondary_ASC',
  SecondaryDesc = 'secondary_DESC',
  TransparentAsc = 'transparent_ASC',
  TransparentDesc = 'transparent_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ButtonRow = Entity & Node & {
  __typename?: 'ButtonRow';
  buttons: Array<Button>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ButtonRow>;
  /** List of ButtonRow versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type ButtonRowButtonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ButtonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonWhereInput>;
};


export type ButtonRowCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ButtonRowDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ButtonRowHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ButtonRowPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ButtonRowScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ButtonRowUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ButtonRowConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ButtonRowWhereUniqueInput;
};

/** A connection to a list of items. */
export type ButtonRowConnection = {
  __typename?: 'ButtonRowConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ButtonRowEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ButtonRowCreateInput = {
  buttons?: InputMaybe<ButtonCreateManyInlineInput>;
  clmynk7vk055201uq20zpe91u?: InputMaybe<CitatumCreateManyInlineInput>;
  cln038k6t07ym01ug476q6eqh?: InputMaybe<ImageTextComboCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ButtonRowCreateManyInlineInput = {
  /** Connect multiple existing ButtonRow documents */
  connect?: InputMaybe<Array<ButtonRowWhereUniqueInput>>;
  /** Create and connect multiple existing ButtonRow documents */
  create?: InputMaybe<Array<ButtonRowCreateInput>>;
};

export type ButtonRowCreateOneInlineInput = {
  /** Connect one existing ButtonRow document */
  connect?: InputMaybe<ButtonRowWhereUniqueInput>;
  /** Create and connect one ButtonRow document */
  create?: InputMaybe<ButtonRowCreateInput>;
};

/** An edge in a connection. */
export type ButtonRowEdge = {
  __typename?: 'ButtonRowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ButtonRow;
};

/** Identifies documents */
export type ButtonRowManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttons_every?: InputMaybe<ButtonWhereInput>;
  buttons_none?: InputMaybe<ButtonWhereInput>;
  buttons_some?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ButtonRowWhereStageInput>;
  documentInStages_none?: InputMaybe<ButtonRowWhereStageInput>;
  documentInStages_some?: InputMaybe<ButtonRowWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ButtonRowOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ButtonRowUpdateInput = {
  buttons?: InputMaybe<ButtonUpdateManyInlineInput>;
  clmynk7vk055201uq20zpe91u?: InputMaybe<CitatumUpdateManyInlineInput>;
  cln038k6t07ym01ug476q6eqh?: InputMaybe<ImageTextComboUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ButtonRowUpdateManyInlineInput = {
  /** Connect multiple existing ButtonRow documents */
  connect?: InputMaybe<Array<ButtonRowConnectInput>>;
  /** Create and connect multiple ButtonRow documents */
  create?: InputMaybe<Array<ButtonRowCreateInput>>;
  /** Delete multiple ButtonRow documents */
  delete?: InputMaybe<Array<ButtonRowWhereUniqueInput>>;
  /** Disconnect multiple ButtonRow documents */
  disconnect?: InputMaybe<Array<ButtonRowWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ButtonRow documents */
  set?: InputMaybe<Array<ButtonRowWhereUniqueInput>>;
  /** Update multiple ButtonRow documents */
  update?: InputMaybe<Array<ButtonRowUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ButtonRow documents */
  upsert?: InputMaybe<Array<ButtonRowUpsertWithNestedWhereUniqueInput>>;
};

export type ButtonRowUpdateManyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ButtonRowUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ButtonRowUpdateManyInput;
  /** Document search */
  where: ButtonRowWhereInput;
};

export type ButtonRowUpdateOneInlineInput = {
  /** Connect existing ButtonRow document */
  connect?: InputMaybe<ButtonRowWhereUniqueInput>;
  /** Create and connect one ButtonRow document */
  create?: InputMaybe<ButtonRowCreateInput>;
  /** Delete currently connected ButtonRow document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ButtonRow document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ButtonRow document */
  update?: InputMaybe<ButtonRowUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ButtonRow document */
  upsert?: InputMaybe<ButtonRowUpsertWithNestedWhereUniqueInput>;
};

export type ButtonRowUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ButtonRowUpdateInput;
  /** Unique document search */
  where: ButtonRowWhereUniqueInput;
};

export type ButtonRowUpsertInput = {
  /** Create document if it didn't exist */
  create: ButtonRowCreateInput;
  /** Update document if it exists */
  update: ButtonRowUpdateInput;
};

export type ButtonRowUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ButtonRowUpsertInput;
  /** Unique document search */
  where: ButtonRowWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ButtonRowWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ButtonRowWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttons_every?: InputMaybe<ButtonWhereInput>;
  buttons_none?: InputMaybe<ButtonWhereInput>;
  buttons_some?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ButtonRowWhereStageInput>;
  documentInStages_none?: InputMaybe<ButtonRowWhereStageInput>;
  documentInStages_some?: InputMaybe<ButtonRowWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ButtonRowWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonRowWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonRowWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonRowWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ButtonRowWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ButtonRow record uniquely */
export type ButtonRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ButtonUpdateInput = {
  buttonRows?: InputMaybe<ButtonRowUpdateManyInlineInput>;
  icon?: InputMaybe<Icon>;
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<LinkUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ButtonUpdateLocalizationsInput>;
  noBorder?: InputMaybe<Scalars['Boolean']['input']>;
  secondary?: InputMaybe<Scalars['Boolean']['input']>;
  transparent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ButtonUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ButtonUpdateLocalizationInput = {
  data: ButtonUpdateLocalizationDataInput;
  locale: Locale;
};

export type ButtonUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ButtonCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ButtonUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ButtonUpsertLocalizationInput>>;
};

export type ButtonUpdateManyInlineInput = {
  /** Connect multiple existing Button documents */
  connect?: InputMaybe<Array<ButtonConnectInput>>;
  /** Create and connect multiple Button documents */
  create?: InputMaybe<Array<ButtonCreateInput>>;
  /** Delete multiple Button documents */
  delete?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Disconnect multiple Button documents */
  disconnect?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Button documents */
  set?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Update multiple Button documents */
  update?: InputMaybe<Array<ButtonUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Button documents */
  upsert?: InputMaybe<Array<ButtonUpsertWithNestedWhereUniqueInput>>;
};

export type ButtonUpdateManyInput = {
  icon?: InputMaybe<Icon>;
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ButtonUpdateManyLocalizationsInput>;
  noBorder?: InputMaybe<Scalars['Boolean']['input']>;
  secondary?: InputMaybe<Scalars['Boolean']['input']>;
  transparent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ButtonUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ButtonUpdateManyLocalizationInput = {
  data: ButtonUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ButtonUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ButtonUpdateManyLocalizationInput>>;
};

export type ButtonUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ButtonUpdateManyInput;
  /** Document search */
  where: ButtonWhereInput;
};

export type ButtonUpdateOneInlineInput = {
  /** Connect existing Button document */
  connect?: InputMaybe<ButtonWhereUniqueInput>;
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
  /** Delete currently connected Button document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Button document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Button document */
  update?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Button document */
  upsert?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
};

export type ButtonUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ButtonUpdateInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpsertInput = {
  /** Create document if it didn't exist */
  create: ButtonCreateInput;
  /** Update document if it exists */
  update: ButtonUpdateInput;
};

export type ButtonUpsertLocalizationInput = {
  create: ButtonCreateLocalizationDataInput;
  locale: Locale;
  update: ButtonUpdateLocalizationDataInput;
};

export type ButtonUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ButtonUpsertInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ButtonWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ButtonWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttonRows_every?: InputMaybe<ButtonRowWhereInput>;
  buttonRows_none?: InputMaybe<ButtonRowWhereInput>;
  buttonRows_some?: InputMaybe<ButtonRowWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ButtonWhereStageInput>;
  documentInStages_none?: InputMaybe<ButtonWhereStageInput>;
  documentInStages_some?: InputMaybe<ButtonWhereStageInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<LinkWhereInput>;
  noBorder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  noBorder_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  secondary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  secondary_not?: InputMaybe<Scalars['Boolean']['input']>;
  transparent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  transparent_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ButtonWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ButtonWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Button record uniquely */
export type ButtonWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Citatum = Entity & Node & {
  __typename?: 'Citatum';
  autor?: Maybe<Scalars['String']['output']>;
  buttonRow?: Maybe<ButtonRow>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Citatum>;
  /** List of Citatum versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Citatum>;
  noQuotationMarks: Scalars['Boolean']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Source */
  reference?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  text: Scalars['String']['output'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  url?: Maybe<Scalars['String']['output']>;
};


export type CitatumButtonRowArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type CitatumCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type CitatumCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type CitatumDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type CitatumHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type CitatumLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type CitatumPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type CitatumPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type CitatumScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type CitatumUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type CitatumUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CitatumConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CitatumWhereUniqueInput;
};

/** A connection to a list of items. */
export type CitatumConnection = {
  __typename?: 'CitatumConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CitatumEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CitatumCreateInput = {
  autor?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CitatumCreateLocalizationsInput>;
  noQuotationMarks: Scalars['Boolean']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  /** role input for default locale (de) */
  role?: InputMaybe<Scalars['String']['input']>;
  /** text input for default locale (de) */
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CitatumCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CitatumCreateLocalizationInput = {
  /** Localization input */
  data: CitatumCreateLocalizationDataInput;
  locale: Locale;
};

export type CitatumCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CitatumCreateLocalizationInput>>;
};

export type CitatumCreateManyInlineInput = {
  /** Connect multiple existing Citatum documents */
  connect?: InputMaybe<Array<CitatumWhereUniqueInput>>;
  /** Create and connect multiple existing Citatum documents */
  create?: InputMaybe<Array<CitatumCreateInput>>;
};

export type CitatumCreateOneInlineInput = {
  /** Connect one existing Citatum document */
  connect?: InputMaybe<CitatumWhereUniqueInput>;
  /** Create and connect one Citatum document */
  create?: InputMaybe<CitatumCreateInput>;
};

/** An edge in a connection. */
export type CitatumEdge = {
  __typename?: 'CitatumEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Citatum;
};

/** Identifies documents */
export type CitatumManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CitatumWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CitatumWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CitatumWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  autor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  autor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  autor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  autor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  autor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  autor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  autor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  autor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  autor_starts_with?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CitatumWhereStageInput>;
  documentInStages_none?: InputMaybe<CitatumWhereStageInput>;
  documentInStages_some?: InputMaybe<CitatumWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  noQuotationMarks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  noQuotationMarks_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  reference_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  reference_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  reference_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  reference_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  reference_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  reference_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  reference_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  reference_starts_with?: InputMaybe<Scalars['String']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum CitatumOrderByInput {
  AutorAsc = 'autor_ASC',
  AutorDesc = 'autor_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NoQuotationMarksAsc = 'noQuotationMarks_ASC',
  NoQuotationMarksDesc = 'noQuotationMarks_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReferenceAsc = 'reference_ASC',
  ReferenceDesc = 'reference_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type CitatumUpdateInput = {
  autor?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CitatumUpdateLocalizationsInput>;
  noQuotationMarks?: InputMaybe<Scalars['Boolean']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  /** role input for default locale (de) */
  role?: InputMaybe<Scalars['String']['input']>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CitatumUpdateLocalizationDataInput = {
  role?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CitatumUpdateLocalizationInput = {
  data: CitatumUpdateLocalizationDataInput;
  locale: Locale;
};

export type CitatumUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CitatumCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CitatumUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CitatumUpsertLocalizationInput>>;
};

export type CitatumUpdateManyInlineInput = {
  /** Connect multiple existing Citatum documents */
  connect?: InputMaybe<Array<CitatumConnectInput>>;
  /** Create and connect multiple Citatum documents */
  create?: InputMaybe<Array<CitatumCreateInput>>;
  /** Delete multiple Citatum documents */
  delete?: InputMaybe<Array<CitatumWhereUniqueInput>>;
  /** Disconnect multiple Citatum documents */
  disconnect?: InputMaybe<Array<CitatumWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Citatum documents */
  set?: InputMaybe<Array<CitatumWhereUniqueInput>>;
  /** Update multiple Citatum documents */
  update?: InputMaybe<Array<CitatumUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Citatum documents */
  upsert?: InputMaybe<Array<CitatumUpsertWithNestedWhereUniqueInput>>;
};

export type CitatumUpdateManyInput = {
  autor?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CitatumUpdateManyLocalizationsInput>;
  noQuotationMarks?: InputMaybe<Scalars['Boolean']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  /** role input for default locale (de) */
  role?: InputMaybe<Scalars['String']['input']>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CitatumUpdateManyLocalizationDataInput = {
  role?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CitatumUpdateManyLocalizationInput = {
  data: CitatumUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CitatumUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CitatumUpdateManyLocalizationInput>>;
};

export type CitatumUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CitatumUpdateManyInput;
  /** Document search */
  where: CitatumWhereInput;
};

export type CitatumUpdateOneInlineInput = {
  /** Connect existing Citatum document */
  connect?: InputMaybe<CitatumWhereUniqueInput>;
  /** Create and connect one Citatum document */
  create?: InputMaybe<CitatumCreateInput>;
  /** Delete currently connected Citatum document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Citatum document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Citatum document */
  update?: InputMaybe<CitatumUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Citatum document */
  upsert?: InputMaybe<CitatumUpsertWithNestedWhereUniqueInput>;
};

export type CitatumUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CitatumUpdateInput;
  /** Unique document search */
  where: CitatumWhereUniqueInput;
};

export type CitatumUpsertInput = {
  /** Create document if it didn't exist */
  create: CitatumCreateInput;
  /** Update document if it exists */
  update: CitatumUpdateInput;
};

export type CitatumUpsertLocalizationInput = {
  create: CitatumCreateLocalizationDataInput;
  locale: Locale;
  update: CitatumUpdateLocalizationDataInput;
};

export type CitatumUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CitatumUpsertInput;
  /** Unique document search */
  where: CitatumWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CitatumWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type CitatumWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CitatumWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CitatumWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CitatumWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  autor?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  autor_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  autor_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  autor_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  autor_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  autor_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  autor_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  autor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  autor_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  autor_starts_with?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CitatumWhereStageInput>;
  documentInStages_none?: InputMaybe<CitatumWhereStageInput>;
  documentInStages_some?: InputMaybe<CitatumWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  noQuotationMarks?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  noQuotationMarks_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  reference_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  reference_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  reference_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  reference_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  reference_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  reference_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  reference_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  reference_starts_with?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  role_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  role_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  role_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  role_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  role_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  role_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  role_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  role_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  role_starts_with?: InputMaybe<Scalars['String']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CitatumWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CitatumWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CitatumWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CitatumWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CitatumWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Citatum record uniquely */
export type CitatumWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color';
  css: Scalars['String']['output'];
  hex: Scalars['Hex']['output'];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars['Hex']['input']>;
  rgba?: InputMaybe<RgbaInput>;
};

/** green, red, blue, etc. */
export type ColorNorm = Entity & Node & {
  __typename?: 'ColorNorm';
  code?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Color>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ColorNorm>;
  /** List of ColorNorm versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ColorNorm>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** green, red, blue, etc. */
export type ColorNormCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** green, red, blue, etc. */
export type ColorNormCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** green, red, blue, etc. */
export type ColorNormDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** green, red, blue, etc. */
export type ColorNormHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** green, red, blue, etc. */
export type ColorNormLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** green, red, blue, etc. */
export type ColorNormPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** green, red, blue, etc. */
export type ColorNormPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** green, red, blue, etc. */
export type ColorNormScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** green, red, blue, etc. */
export type ColorNormUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** green, red, blue, etc. */
export type ColorNormUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ColorNormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ColorNormWhereUniqueInput;
};

/** A connection to a list of items. */
export type ColorNormConnection = {
  __typename?: 'ColorNormConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ColorNormEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ColorNormCreateInput = {
  cl16i9swxi45l01z03666b0zx?: InputMaybe<VehicleCreateManyInlineInput>;
  cl16ibv3nhvms01z1eyeygjwk?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<ColorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ColorNormCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ColorNormCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ColorNormCreateLocalizationInput = {
  /** Localization input */
  data: ColorNormCreateLocalizationDataInput;
  locale: Locale;
};

export type ColorNormCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ColorNormCreateLocalizationInput>>;
};

export type ColorNormCreateManyInlineInput = {
  /** Connect multiple existing ColorNorm documents */
  connect?: InputMaybe<Array<ColorNormWhereUniqueInput>>;
  /** Create and connect multiple existing ColorNorm documents */
  create?: InputMaybe<Array<ColorNormCreateInput>>;
};

export type ColorNormCreateOneInlineInput = {
  /** Connect one existing ColorNorm document */
  connect?: InputMaybe<ColorNormWhereUniqueInput>;
  /** Create and connect one ColorNorm document */
  create?: InputMaybe<ColorNormCreateInput>;
};

/** An edge in a connection. */
export type ColorNormEdge = {
  __typename?: 'ColorNormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ColorNorm;
};

/** Identifies documents */
export type ColorNormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ColorNormWhereStageInput>;
  documentInStages_none?: InputMaybe<ColorNormWhereStageInput>;
  documentInStages_some?: InputMaybe<ColorNormWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ColorNormOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ColorNormUpdateInput = {
  cl16i9swxi45l01z03666b0zx?: InputMaybe<VehicleUpdateManyInlineInput>;
  cl16ibv3nhvms01z1eyeygjwk?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<ColorInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ColorNormUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorNormUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorNormUpdateLocalizationInput = {
  data: ColorNormUpdateLocalizationDataInput;
  locale: Locale;
};

export type ColorNormUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ColorNormCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ColorNormUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ColorNormUpsertLocalizationInput>>;
};

export type ColorNormUpdateManyInlineInput = {
  /** Connect multiple existing ColorNorm documents */
  connect?: InputMaybe<Array<ColorNormConnectInput>>;
  /** Create and connect multiple ColorNorm documents */
  create?: InputMaybe<Array<ColorNormCreateInput>>;
  /** Delete multiple ColorNorm documents */
  delete?: InputMaybe<Array<ColorNormWhereUniqueInput>>;
  /** Disconnect multiple ColorNorm documents */
  disconnect?: InputMaybe<Array<ColorNormWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ColorNorm documents */
  set?: InputMaybe<Array<ColorNormWhereUniqueInput>>;
  /** Update multiple ColorNorm documents */
  update?: InputMaybe<Array<ColorNormUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ColorNorm documents */
  upsert?: InputMaybe<Array<ColorNormUpsertWithNestedWhereUniqueInput>>;
};

export type ColorNormUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<ColorInput>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ColorNormUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorNormUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorNormUpdateManyLocalizationInput = {
  data: ColorNormUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ColorNormUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ColorNormUpdateManyLocalizationInput>>;
};

export type ColorNormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ColorNormUpdateManyInput;
  /** Document search */
  where: ColorNormWhereInput;
};

export type ColorNormUpdateOneInlineInput = {
  /** Connect existing ColorNorm document */
  connect?: InputMaybe<ColorNormWhereUniqueInput>;
  /** Create and connect one ColorNorm document */
  create?: InputMaybe<ColorNormCreateInput>;
  /** Delete currently connected ColorNorm document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ColorNorm document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ColorNorm document */
  update?: InputMaybe<ColorNormUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColorNorm document */
  upsert?: InputMaybe<ColorNormUpsertWithNestedWhereUniqueInput>;
};

export type ColorNormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ColorNormUpdateInput;
  /** Unique document search */
  where: ColorNormWhereUniqueInput;
};

export type ColorNormUpsertInput = {
  /** Create document if it didn't exist */
  create: ColorNormCreateInput;
  /** Update document if it exists */
  update: ColorNormUpdateInput;
};

export type ColorNormUpsertLocalizationInput = {
  create: ColorNormCreateLocalizationDataInput;
  locale: Locale;
  update: ColorNormUpdateLocalizationDataInput;
};

export type ColorNormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ColorNormUpsertInput;
  /** Unique document search */
  where: ColorNormWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ColorNormWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ColorNormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorNormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ColorNormWhereStageInput>;
  documentInStages_none?: InputMaybe<ColorNormWhereStageInput>;
  documentInStages_some?: InputMaybe<ColorNormWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ColorNormWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorNormWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorNormWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorNormWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ColorNormWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ColorNorm record uniquely */
export type ColorNormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

/** Metallic, Solid, Matte, etc. */
export type ColorType = Entity & Node & {
  __typename?: 'ColorType';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ColorType>;
  /** List of ColorType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ColorType>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Metallic, Solid, Matte, etc. */
export type ColorTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ColorTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ColorTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type ColorTypeConnection = {
  __typename?: 'ColorTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ColorTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ColorTypeCreateInput = {
  cl16i9702hsiu01yw6lkfeh9e?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ColorTypeCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ColorTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ColorTypeCreateLocalizationInput = {
  /** Localization input */
  data: ColorTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type ColorTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ColorTypeCreateLocalizationInput>>;
};

export type ColorTypeCreateManyInlineInput = {
  /** Connect multiple existing ColorType documents */
  connect?: InputMaybe<Array<ColorTypeWhereUniqueInput>>;
  /** Create and connect multiple existing ColorType documents */
  create?: InputMaybe<Array<ColorTypeCreateInput>>;
};

export type ColorTypeCreateOneInlineInput = {
  /** Connect one existing ColorType document */
  connect?: InputMaybe<ColorTypeWhereUniqueInput>;
  /** Create and connect one ColorType document */
  create?: InputMaybe<ColorTypeCreateInput>;
};

/** An edge in a connection. */
export type ColorTypeEdge = {
  __typename?: 'ColorTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ColorType;
};

/** Identifies documents */
export type ColorTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ColorTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<ColorTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<ColorTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ColorTypeOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ColorTypeUpdateInput = {
  cl16i9702hsiu01yw6lkfeh9e?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ColorTypeUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorTypeUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorTypeUpdateLocalizationInput = {
  data: ColorTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type ColorTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ColorTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ColorTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ColorTypeUpsertLocalizationInput>>;
};

export type ColorTypeUpdateManyInlineInput = {
  /** Connect multiple existing ColorType documents */
  connect?: InputMaybe<Array<ColorTypeConnectInput>>;
  /** Create and connect multiple ColorType documents */
  create?: InputMaybe<Array<ColorTypeCreateInput>>;
  /** Delete multiple ColorType documents */
  delete?: InputMaybe<Array<ColorTypeWhereUniqueInput>>;
  /** Disconnect multiple ColorType documents */
  disconnect?: InputMaybe<Array<ColorTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ColorType documents */
  set?: InputMaybe<Array<ColorTypeWhereUniqueInput>>;
  /** Update multiple ColorType documents */
  update?: InputMaybe<Array<ColorTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ColorType documents */
  upsert?: InputMaybe<Array<ColorTypeUpsertWithNestedWhereUniqueInput>>;
};

export type ColorTypeUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ColorTypeUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorTypeUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type ColorTypeUpdateManyLocalizationInput = {
  data: ColorTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ColorTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ColorTypeUpdateManyLocalizationInput>>;
};

export type ColorTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ColorTypeUpdateManyInput;
  /** Document search */
  where: ColorTypeWhereInput;
};

export type ColorTypeUpdateOneInlineInput = {
  /** Connect existing ColorType document */
  connect?: InputMaybe<ColorTypeWhereUniqueInput>;
  /** Create and connect one ColorType document */
  create?: InputMaybe<ColorTypeCreateInput>;
  /** Delete currently connected ColorType document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ColorType document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ColorType document */
  update?: InputMaybe<ColorTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColorType document */
  upsert?: InputMaybe<ColorTypeUpsertWithNestedWhereUniqueInput>;
};

export type ColorTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ColorTypeUpdateInput;
  /** Unique document search */
  where: ColorTypeWhereUniqueInput;
};

export type ColorTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: ColorTypeCreateInput;
  /** Update document if it exists */
  update: ColorTypeUpdateInput;
};

export type ColorTypeUpsertLocalizationInput = {
  create: ColorTypeCreateLocalizationDataInput;
  locale: Locale;
  update: ColorTypeUpdateLocalizationDataInput;
};

export type ColorTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ColorTypeUpsertInput;
  /** Unique document search */
  where: ColorTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ColorTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ColorTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ColorTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<ColorTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<ColorTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ColorTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColorTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColorTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColorTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ColorTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ColorType record uniquely */
export type ColorTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars['ID']['input']>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars['ID']['input']>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars['Boolean']['input']>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentConfiguration = Entity & Node & {
  __typename?: 'ContentConfiguration';
  aboutCarmarket?: Maybe<Page>;
  aboutTourChecklist?: Maybe<Page>;
  brandListHeroImage?: Maybe<Image>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ContentConfiguration>;
  footerGeneralPageLinks: Array<PageLink>;
  footerYourVehiclePageLinks: Array<PageLink>;
  /** List of ContentConfiguration versions */
  history: Array<Version>;
  homeHeroImage?: Maybe<Image>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ContentConfiguration>;
  magazineHeroImage?: Maybe<Image>;
  privacyPolicy?: Maybe<Page>;
  promotionsHeroImage?: Maybe<Image>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  querySuggestions: Array<Scalars['String']['output']>;
  /** Only one content configuration allowed! */
  root: Scalars['Boolean']['output'];
  scheduledIn: Array<ScheduledOperation>;
  servicePageLinks: Array<PageLink>;
  slides: Array<BannerSlide>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  vehicleBuy?: Maybe<Page>;
  vehicleEvaluation?: Maybe<Page>;
  vehicleInsurance?: Maybe<Page>;
  vehicleLease?: Maybe<Page>;
};


export type ContentConfigurationAboutCarmarketArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationAboutTourChecklistArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationBrandListHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ContentConfigurationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ContentConfigurationFooterGeneralPageLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageLinkWhereInput>;
};


export type ContentConfigurationFooterYourVehiclePageLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageLinkWhereInput>;
};


export type ContentConfigurationHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ContentConfigurationHomeHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ContentConfigurationMagazineHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationPrivacyPolicyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationPromotionsHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ContentConfigurationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ContentConfigurationServicePageLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageLinkWhereInput>;
};


export type ContentConfigurationSlidesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BannerSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BannerSlideWhereInput>;
};


export type ContentConfigurationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ContentConfigurationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationVehicleBuyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationVehicleEvaluationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationVehicleInsuranceArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ContentConfigurationVehicleLeaseArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ContentConfigurationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ContentConfigurationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ContentConfigurationConnection = {
  __typename?: 'ContentConfigurationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ContentConfigurationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ContentConfigurationCreateInput = {
  aboutCarmarket?: InputMaybe<PageCreateOneInlineInput>;
  aboutTourChecklist?: InputMaybe<PageCreateOneInlineInput>;
  brandListHeroImage?: InputMaybe<ImageCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  footerGeneralPageLinks?: InputMaybe<PageLinkCreateManyInlineInput>;
  footerYourVehiclePageLinks?: InputMaybe<PageLinkCreateManyInlineInput>;
  homeHeroImage?: InputMaybe<ImageCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ContentConfigurationCreateLocalizationsInput>;
  magazineHeroImage?: InputMaybe<ImageCreateOneInlineInput>;
  privacyPolicy?: InputMaybe<PageCreateOneInlineInput>;
  promotionsHeroImage?: InputMaybe<ImageCreateOneInlineInput>;
  /** querySuggestions input for default locale (de) */
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
  root: Scalars['Boolean']['input'];
  servicePageLinks?: InputMaybe<PageLinkCreateManyInlineInput>;
  slides?: InputMaybe<BannerSlideCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleBuy?: InputMaybe<PageCreateOneInlineInput>;
  vehicleEvaluation?: InputMaybe<PageCreateOneInlineInput>;
  vehicleInsurance?: InputMaybe<PageCreateOneInlineInput>;
  vehicleLease?: InputMaybe<PageCreateOneInlineInput>;
};

export type ContentConfigurationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ContentConfigurationCreateLocalizationInput = {
  /** Localization input */
  data: ContentConfigurationCreateLocalizationDataInput;
  locale: Locale;
};

export type ContentConfigurationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ContentConfigurationCreateLocalizationInput>>;
};

export type ContentConfigurationCreateManyInlineInput = {
  /** Connect multiple existing ContentConfiguration documents */
  connect?: InputMaybe<Array<ContentConfigurationWhereUniqueInput>>;
  /** Create and connect multiple existing ContentConfiguration documents */
  create?: InputMaybe<Array<ContentConfigurationCreateInput>>;
};

export type ContentConfigurationCreateOneInlineInput = {
  /** Connect one existing ContentConfiguration document */
  connect?: InputMaybe<ContentConfigurationWhereUniqueInput>;
  /** Create and connect one ContentConfiguration document */
  create?: InputMaybe<ContentConfigurationCreateInput>;
};

/** An edge in a connection. */
export type ContentConfigurationEdge = {
  __typename?: 'ContentConfigurationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ContentConfiguration;
};

/** Identifies documents */
export type ContentConfigurationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  aboutCarmarket?: InputMaybe<PageWhereInput>;
  aboutTourChecklist?: InputMaybe<PageWhereInput>;
  brandListHeroImage?: InputMaybe<ImageWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ContentConfigurationWhereStageInput>;
  documentInStages_none?: InputMaybe<ContentConfigurationWhereStageInput>;
  documentInStages_some?: InputMaybe<ContentConfigurationWhereStageInput>;
  footerGeneralPageLinks_every?: InputMaybe<PageLinkWhereInput>;
  footerGeneralPageLinks_none?: InputMaybe<PageLinkWhereInput>;
  footerGeneralPageLinks_some?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_every?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_none?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_some?: InputMaybe<PageLinkWhereInput>;
  homeHeroImage?: InputMaybe<ImageWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  magazineHeroImage?: InputMaybe<ImageWhereInput>;
  privacyPolicy?: InputMaybe<PageWhereInput>;
  promotionsHeroImage?: InputMaybe<ImageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  root_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  servicePageLinks_every?: InputMaybe<PageLinkWhereInput>;
  servicePageLinks_none?: InputMaybe<PageLinkWhereInput>;
  servicePageLinks_some?: InputMaybe<PageLinkWhereInput>;
  slides_every?: InputMaybe<BannerSlideWhereInput>;
  slides_none?: InputMaybe<BannerSlideWhereInput>;
  slides_some?: InputMaybe<BannerSlideWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vehicleBuy?: InputMaybe<PageWhereInput>;
  vehicleEvaluation?: InputMaybe<PageWhereInput>;
  vehicleInsurance?: InputMaybe<PageWhereInput>;
  vehicleLease?: InputMaybe<PageWhereInput>;
};

export enum ContentConfigurationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  QuerySuggestionsAsc = 'querySuggestions_ASC',
  QuerySuggestionsDesc = 'querySuggestions_DESC',
  RootAsc = 'root_ASC',
  RootDesc = 'root_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ContentConfigurationUpdateInput = {
  aboutCarmarket?: InputMaybe<PageUpdateOneInlineInput>;
  aboutTourChecklist?: InputMaybe<PageUpdateOneInlineInput>;
  brandListHeroImage?: InputMaybe<ImageUpdateOneInlineInput>;
  footerGeneralPageLinks?: InputMaybe<PageLinkUpdateManyInlineInput>;
  footerYourVehiclePageLinks?: InputMaybe<PageLinkUpdateManyInlineInput>;
  homeHeroImage?: InputMaybe<ImageUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ContentConfigurationUpdateLocalizationsInput>;
  magazineHeroImage?: InputMaybe<ImageUpdateOneInlineInput>;
  privacyPolicy?: InputMaybe<PageUpdateOneInlineInput>;
  promotionsHeroImage?: InputMaybe<ImageUpdateOneInlineInput>;
  /** querySuggestions input for default locale (de) */
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
  servicePageLinks?: InputMaybe<PageLinkUpdateManyInlineInput>;
  slides?: InputMaybe<BannerSlideUpdateManyInlineInput>;
  vehicleBuy?: InputMaybe<PageUpdateOneInlineInput>;
  vehicleEvaluation?: InputMaybe<PageUpdateOneInlineInput>;
  vehicleInsurance?: InputMaybe<PageUpdateOneInlineInput>;
  vehicleLease?: InputMaybe<PageUpdateOneInlineInput>;
};

export type ContentConfigurationUpdateLocalizationDataInput = {
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentConfigurationUpdateLocalizationInput = {
  data: ContentConfigurationUpdateLocalizationDataInput;
  locale: Locale;
};

export type ContentConfigurationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ContentConfigurationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ContentConfigurationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ContentConfigurationUpsertLocalizationInput>>;
};

export type ContentConfigurationUpdateManyInlineInput = {
  /** Connect multiple existing ContentConfiguration documents */
  connect?: InputMaybe<Array<ContentConfigurationConnectInput>>;
  /** Create and connect multiple ContentConfiguration documents */
  create?: InputMaybe<Array<ContentConfigurationCreateInput>>;
  /** Delete multiple ContentConfiguration documents */
  delete?: InputMaybe<Array<ContentConfigurationWhereUniqueInput>>;
  /** Disconnect multiple ContentConfiguration documents */
  disconnect?: InputMaybe<Array<ContentConfigurationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ContentConfiguration documents */
  set?: InputMaybe<Array<ContentConfigurationWhereUniqueInput>>;
  /** Update multiple ContentConfiguration documents */
  update?: InputMaybe<Array<ContentConfigurationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ContentConfiguration documents */
  upsert?: InputMaybe<Array<ContentConfigurationUpsertWithNestedWhereUniqueInput>>;
};

export type ContentConfigurationUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ContentConfigurationUpdateManyLocalizationsInput>;
  /** querySuggestions input for default locale (de) */
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentConfigurationUpdateManyLocalizationDataInput = {
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentConfigurationUpdateManyLocalizationInput = {
  data: ContentConfigurationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ContentConfigurationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ContentConfigurationUpdateManyLocalizationInput>>;
};

export type ContentConfigurationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ContentConfigurationUpdateManyInput;
  /** Document search */
  where: ContentConfigurationWhereInput;
};

export type ContentConfigurationUpdateOneInlineInput = {
  /** Connect existing ContentConfiguration document */
  connect?: InputMaybe<ContentConfigurationWhereUniqueInput>;
  /** Create and connect one ContentConfiguration document */
  create?: InputMaybe<ContentConfigurationCreateInput>;
  /** Delete currently connected ContentConfiguration document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ContentConfiguration document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ContentConfiguration document */
  update?: InputMaybe<ContentConfigurationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ContentConfiguration document */
  upsert?: InputMaybe<ContentConfigurationUpsertWithNestedWhereUniqueInput>;
};

export type ContentConfigurationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ContentConfigurationUpdateInput;
  /** Unique document search */
  where: ContentConfigurationWhereUniqueInput;
};

export type ContentConfigurationUpsertInput = {
  /** Create document if it didn't exist */
  create: ContentConfigurationCreateInput;
  /** Update document if it exists */
  update: ContentConfigurationUpdateInput;
};

export type ContentConfigurationUpsertLocalizationInput = {
  create: ContentConfigurationCreateLocalizationDataInput;
  locale: Locale;
  update: ContentConfigurationUpdateLocalizationDataInput;
};

export type ContentConfigurationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ContentConfigurationUpsertInput;
  /** Unique document search */
  where: ContentConfigurationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ContentConfigurationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ContentConfigurationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentConfigurationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  aboutCarmarket?: InputMaybe<PageWhereInput>;
  aboutTourChecklist?: InputMaybe<PageWhereInput>;
  brandListHeroImage?: InputMaybe<ImageWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ContentConfigurationWhereStageInput>;
  documentInStages_none?: InputMaybe<ContentConfigurationWhereStageInput>;
  documentInStages_some?: InputMaybe<ContentConfigurationWhereStageInput>;
  footerGeneralPageLinks_every?: InputMaybe<PageLinkWhereInput>;
  footerGeneralPageLinks_none?: InputMaybe<PageLinkWhereInput>;
  footerGeneralPageLinks_some?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_every?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_none?: InputMaybe<PageLinkWhereInput>;
  footerYourVehiclePageLinks_some?: InputMaybe<PageLinkWhereInput>;
  homeHeroImage?: InputMaybe<ImageWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  magazineHeroImage?: InputMaybe<ImageWhereInput>;
  privacyPolicy?: InputMaybe<PageWhereInput>;
  promotionsHeroImage?: InputMaybe<ImageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  querySuggestions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  querySuggestions_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  querySuggestions_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  querySuggestions_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  querySuggestions_not?: InputMaybe<Array<Scalars['String']['input']>>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  root_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  servicePageLinks_every?: InputMaybe<PageLinkWhereInput>;
  servicePageLinks_none?: InputMaybe<PageLinkWhereInput>;
  servicePageLinks_some?: InputMaybe<PageLinkWhereInput>;
  slides_every?: InputMaybe<BannerSlideWhereInput>;
  slides_none?: InputMaybe<BannerSlideWhereInput>;
  slides_some?: InputMaybe<BannerSlideWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  vehicleBuy?: InputMaybe<PageWhereInput>;
  vehicleEvaluation?: InputMaybe<PageWhereInput>;
  vehicleInsurance?: InputMaybe<PageWhereInput>;
  vehicleLease?: InputMaybe<PageWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ContentConfigurationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentConfigurationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentConfigurationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentConfigurationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ContentConfigurationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ContentConfiguration record uniquely */
export type ContentConfigurationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  root?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ContractOption {
  Buy = 'BUY',
  Lease = 'LEASE'
}

export enum Country {
  Ch = 'CH',
  Li = 'LI'
}

export enum Currency {
  Chf = 'CHF'
}

export type Dealer = Entity & Node & {
  __typename?: 'Dealer';
  brands: Array<Brand>;
  calltrackingPhone?: Maybe<Scalars['String']['output']>;
  calltrackingPoolId?: Maybe<Scalars['Int']['output']>;
  calltrackingSubscriberId?: Maybe<Scalars['Int']['output']>;
  campaignBanners: Array<Ad>;
  carmarketReturnPolicy: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealerEmployees: Array<DealerEmployee>;
  /** Get the document in other stages */
  documentInStages: Array<Dealer>;
  email?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  externalIds?: Maybe<Scalars['Json']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  /** Google Maps Place ID */
  gPlaceId?: Maybe<Scalars['String']['output']>;
  /** Google Reviews */
  gReviews?: Maybe<Scalars['Json']['output']>;
  geolocation?: Maybe<Location>;
  /** List of Dealer versions */
  history: Array<Version>;
  homeDeliveryAvailable?: Maybe<Scalars['Boolean']['output']>;
  homeDeliveryFee?: Maybe<Scalars['Json']['output']>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageUrls: Array<Scalars['String']['output']>;
  insideId: Scalars['Int']['output'];
  introText?: Maybe<Scalars['String']['output']>;
  introTitle?: Maybe<Scalars['String']['output']>;
  keywordLists: Array<KeywordList>;
  languages: Array<Language>;
  leasingApplicationAvailable: Scalars['Boolean']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Dealer>;
  logoPictureUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newVehicleTcUrl?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['Json']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  primaryLanguage?: Maybe<Language>;
  privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  salesAdvisor?: Maybe<Employee>;
  salesAdvisorList?: Maybe<SalesAdvisorList>;
  scheduledIn: Array<ScheduledOperation>;
  services: Array<Service>;
  slug?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  street?: Maybe<Scalars['String']['output']>;
  subname?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  urlFacebook?: Maybe<Scalars['String']['output']>;
  urlGoogle?: Maybe<Scalars['String']['output']>;
  urlInstagram?: Maybe<Scalars['String']['output']>;
  urlSnapchat?: Maybe<Scalars['String']['output']>;
  urlTwitter?: Maybe<Scalars['String']['output']>;
  urlWebsite?: Maybe<Scalars['String']['output']>;
  urlYoutube?: Maybe<Scalars['String']['output']>;
  usedVehicleTcUrl?: Maybe<Scalars['String']['output']>;
  vehicles: Array<Vehicle>;
  whitelabels: Array<Whitelabel>;
  workingHoursExceptions?: Maybe<Scalars['Json']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type DealerBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandWhereInput>;
};


export type DealerCampaignBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AdOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdWhereInput>;
};


export type DealerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DealerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerDealerEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerEmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerEmployeeWhereInput>;
};


export type DealerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type DealerHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type DealerKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type DealerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type DealerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DealerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerSalesAdvisorArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerSalesAdvisorListArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type DealerServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};


export type DealerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DealerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VehicleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleWhereInput>;
};


export type DealerWhitelabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<WhitelabelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhitelabelWhereInput>;
};

export type DealerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DealerWhereUniqueInput;
};

/** A connection to a list of items. */
export type DealerConnection = {
  __typename?: 'DealerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DealerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DealerCreateInput = {
  brands?: InputMaybe<BrandCreateManyInlineInput>;
  calltrackingPhone?: InputMaybe<Scalars['String']['input']>;
  calltrackingPoolId?: InputMaybe<Scalars['Int']['input']>;
  calltrackingSubscriberId?: InputMaybe<Scalars['Int']['input']>;
  campaignBanners?: InputMaybe<AdCreateManyInlineInput>;
  carmarketReturnPolicy: Scalars['Boolean']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  clxmyi2wx00zx07vyau72hsw5?: InputMaybe<DealerLinkCreateManyInlineInput>;
  country?: InputMaybe<Country>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealerEmployees?: InputMaybe<DealerEmployeeCreateManyInlineInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  externalIds?: InputMaybe<Scalars['Json']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  gPlaceId?: InputMaybe<Scalars['String']['input']>;
  geolocation?: InputMaybe<LocationInput>;
  homeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  homeDeliveryFee?: InputMaybe<Scalars['Json']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  insideId: Scalars['Int']['input'];
  /** introText input for default locale (de) */
  introText?: InputMaybe<Scalars['String']['input']>;
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>;
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  languages?: InputMaybe<Array<Language>>;
  leasingApplicationAvailable: Scalars['Boolean']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DealerCreateLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  /** newVehicleTcUrl input for default locale (de) */
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<Scalars['Json']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Language>;
  /** privacyPolicyUrl input for default locale (de) */
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  salesAdvisor?: InputMaybe<EmployeeCreateOneInlineInput>;
  salesAdvisorList?: InputMaybe<SalesAdvisorListCreateOneInlineInput>;
  services?: InputMaybe<ServiceCreateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  subname?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  urlFacebook?: InputMaybe<Scalars['String']['input']>;
  urlGoogle?: InputMaybe<Scalars['String']['input']>;
  urlInstagram?: InputMaybe<Scalars['String']['input']>;
  urlSnapchat?: InputMaybe<Scalars['String']['input']>;
  urlTwitter?: InputMaybe<Scalars['String']['input']>;
  urlWebsite?: InputMaybe<Scalars['String']['input']>;
  urlYoutube?: InputMaybe<Scalars['String']['input']>;
  /** usedVehicleTcUrl input for default locale (de) */
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<VehicleCreateManyInlineInput>;
  whitelabels?: InputMaybe<WhitelabelCreateManyInlineInput>;
  workingHoursExceptions?: InputMaybe<Scalars['Json']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DealerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  introText?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
};

export type DealerCreateLocalizationInput = {
  /** Localization input */
  data: DealerCreateLocalizationDataInput;
  locale: Locale;
};

export type DealerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DealerCreateLocalizationInput>>;
};

export type DealerCreateManyInlineInput = {
  /** Connect multiple existing Dealer documents */
  connect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  /** Create and connect multiple existing Dealer documents */
  create?: InputMaybe<Array<DealerCreateInput>>;
};

export type DealerCreateOneInlineInput = {
  /** Connect one existing Dealer document */
  connect?: InputMaybe<DealerWhereUniqueInput>;
  /** Create and connect one Dealer document */
  create?: InputMaybe<DealerCreateInput>;
};

/** An edge in a connection. */
export type DealerEdge = {
  __typename?: 'DealerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Dealer;
};

export type DealerEmployee = Entity & Node & {
  __typename?: 'DealerEmployee';
  /** dealerId-employeeId */
  concatInsideId: Scalars['String']['output'];
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealer?: Maybe<Dealer>;
  departments: Array<Department>;
  /** Get the document in other stages */
  documentInStages: Array<DealerEmployee>;
  employee?: Maybe<Employee>;
  /** List of DealerEmployee versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type DealerEmployeeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerEmployeeDealerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerEmployeeDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DepartmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DepartmentWhereInput>;
};


export type DealerEmployeeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type DealerEmployeeEmployeeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerEmployeeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type DealerEmployeePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerEmployeeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type DealerEmployeeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DealerEmployeeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DealerEmployeeWhereUniqueInput;
};

/** A connection to a list of items. */
export type DealerEmployeeConnection = {
  __typename?: 'DealerEmployeeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DealerEmployeeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DealerEmployeeCreateInput = {
  concatInsideId: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealer?: InputMaybe<DealerCreateOneInlineInput>;
  departments?: InputMaybe<DepartmentCreateManyInlineInput>;
  employee?: InputMaybe<EmployeeCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DealerEmployeeCreateManyInlineInput = {
  /** Connect multiple existing DealerEmployee documents */
  connect?: InputMaybe<Array<DealerEmployeeWhereUniqueInput>>;
  /** Create and connect multiple existing DealerEmployee documents */
  create?: InputMaybe<Array<DealerEmployeeCreateInput>>;
};

export type DealerEmployeeCreateOneInlineInput = {
  /** Connect one existing DealerEmployee document */
  connect?: InputMaybe<DealerEmployeeWhereUniqueInput>;
  /** Create and connect one DealerEmployee document */
  create?: InputMaybe<DealerEmployeeCreateInput>;
};

/** An edge in a connection. */
export type DealerEmployeeEdge = {
  __typename?: 'DealerEmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DealerEmployee;
};

/** Identifies documents */
export type DealerEmployeeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  concatInsideId?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  concatInsideId_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  concatInsideId_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  concatInsideId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  concatInsideId_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  concatInsideId_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  concatInsideId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  concatInsideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  concatInsideId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  concatInsideId_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealer?: InputMaybe<DealerWhereInput>;
  departments_every?: InputMaybe<DepartmentWhereInput>;
  departments_none?: InputMaybe<DepartmentWhereInput>;
  departments_some?: InputMaybe<DepartmentWhereInput>;
  documentInStages_every?: InputMaybe<DealerEmployeeWhereStageInput>;
  documentInStages_none?: InputMaybe<DealerEmployeeWhereStageInput>;
  documentInStages_some?: InputMaybe<DealerEmployeeWhereStageInput>;
  employee?: InputMaybe<EmployeeWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum DealerEmployeeOrderByInput {
  ConcatInsideIdAsc = 'concatInsideId_ASC',
  ConcatInsideIdDesc = 'concatInsideId_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DealerEmployeeUpdateInput = {
  concatInsideId?: InputMaybe<Scalars['String']['input']>;
  dealer?: InputMaybe<DealerUpdateOneInlineInput>;
  departments?: InputMaybe<DepartmentUpdateManyInlineInput>;
  employee?: InputMaybe<EmployeeUpdateOneInlineInput>;
};

export type DealerEmployeeUpdateManyInlineInput = {
  /** Connect multiple existing DealerEmployee documents */
  connect?: InputMaybe<Array<DealerEmployeeConnectInput>>;
  /** Create and connect multiple DealerEmployee documents */
  create?: InputMaybe<Array<DealerEmployeeCreateInput>>;
  /** Delete multiple DealerEmployee documents */
  delete?: InputMaybe<Array<DealerEmployeeWhereUniqueInput>>;
  /** Disconnect multiple DealerEmployee documents */
  disconnect?: InputMaybe<Array<DealerEmployeeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DealerEmployee documents */
  set?: InputMaybe<Array<DealerEmployeeWhereUniqueInput>>;
  /** Update multiple DealerEmployee documents */
  update?: InputMaybe<Array<DealerEmployeeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DealerEmployee documents */
  upsert?: InputMaybe<Array<DealerEmployeeUpsertWithNestedWhereUniqueInput>>;
};

export type DealerEmployeeUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type DealerEmployeeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DealerEmployeeUpdateManyInput;
  /** Document search */
  where: DealerEmployeeWhereInput;
};

export type DealerEmployeeUpdateOneInlineInput = {
  /** Connect existing DealerEmployee document */
  connect?: InputMaybe<DealerEmployeeWhereUniqueInput>;
  /** Create and connect one DealerEmployee document */
  create?: InputMaybe<DealerEmployeeCreateInput>;
  /** Delete currently connected DealerEmployee document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected DealerEmployee document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single DealerEmployee document */
  update?: InputMaybe<DealerEmployeeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DealerEmployee document */
  upsert?: InputMaybe<DealerEmployeeUpsertWithNestedWhereUniqueInput>;
};

export type DealerEmployeeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DealerEmployeeUpdateInput;
  /** Unique document search */
  where: DealerEmployeeWhereUniqueInput;
};

export type DealerEmployeeUpsertInput = {
  /** Create document if it didn't exist */
  create: DealerEmployeeCreateInput;
  /** Update document if it exists */
  update: DealerEmployeeUpdateInput;
};

export type DealerEmployeeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DealerEmployeeUpsertInput;
  /** Unique document search */
  where: DealerEmployeeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DealerEmployeeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type DealerEmployeeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerEmployeeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  concatInsideId?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  concatInsideId_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  concatInsideId_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  concatInsideId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  concatInsideId_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  concatInsideId_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  concatInsideId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  concatInsideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  concatInsideId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  concatInsideId_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealer?: InputMaybe<DealerWhereInput>;
  departments_every?: InputMaybe<DepartmentWhereInput>;
  departments_none?: InputMaybe<DepartmentWhereInput>;
  departments_some?: InputMaybe<DepartmentWhereInput>;
  documentInStages_every?: InputMaybe<DealerEmployeeWhereStageInput>;
  documentInStages_none?: InputMaybe<DealerEmployeeWhereStageInput>;
  documentInStages_some?: InputMaybe<DealerEmployeeWhereStageInput>;
  employee?: InputMaybe<EmployeeWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DealerEmployeeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerEmployeeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerEmployeeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerEmployeeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DealerEmployeeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References DealerEmployee record uniquely */
export type DealerEmployeeWhereUniqueInput = {
  concatInsideId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type DealerLink = Entity & {
  __typename?: 'DealerLink';
  /**
   * Only valid for Tab=1
   * Example: "brandModel=58%7C1416"
   */
  additionalQueryParameters?: Maybe<Scalars['String']['output']>;
  dealer?: Maybe<Dealer>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<DealerLink>;
  name?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  tab: Scalars['Int']['output'];
};


export type DealerLinkDealerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DealerLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type DealerLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DealerLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type DealerLinkConnection = {
  __typename?: 'DealerLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DealerLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DealerLinkCreateInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  dealer?: InputMaybe<DealerCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DealerLinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  tab: Scalars['Int']['input'];
};

export type DealerLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DealerLinkCreateLocalizationInput = {
  /** Localization input */
  data: DealerLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type DealerLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DealerLinkCreateLocalizationInput>>;
};

export type DealerLinkCreateManyInlineInput = {
  /** Create and connect multiple existing DealerLink documents */
  create?: InputMaybe<Array<DealerLinkCreateInput>>;
};

export type DealerLinkCreateOneInlineInput = {
  /** Create and connect one DealerLink document */
  create?: InputMaybe<DealerLinkCreateInput>;
};

export type DealerLinkCreateWithPositionInput = {
  /** Document to create */
  data: DealerLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DealerLinkEdge = {
  __typename?: 'DealerLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DealerLink;
};

/** Identifies documents */
export type DealerLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  additionalQueryParameters_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  additionalQueryParameters_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  additionalQueryParameters_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  additionalQueryParameters_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  additionalQueryParameters_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  additionalQueryParameters_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  additionalQueryParameters_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  additionalQueryParameters_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  additionalQueryParameters_starts_with?: InputMaybe<Scalars['String']['input']>;
  dealer?: InputMaybe<DealerWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  tab?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  tab_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  tab_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  tab_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  tab_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  tab_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  tab_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  tab_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum DealerLinkOrderByInput {
  AdditionalQueryParametersAsc = 'additionalQueryParameters_ASC',
  AdditionalQueryParametersDesc = 'additionalQueryParameters_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TabAsc = 'tab_ASC',
  TabDesc = 'tab_DESC'
}

export type DealerLinkParent = Link;

export type DealerLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type DealerLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type DealerLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing DealerLinkParent documents */
  create?: InputMaybe<Array<DealerLinkParentCreateInput>>;
};

export type DealerLinkParentCreateOneInlineInput = {
  /** Create and connect one DealerLinkParent document */
  create?: InputMaybe<DealerLinkParentCreateInput>;
};

export type DealerLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type DealerLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type DealerLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple DealerLinkParent component instances */
  create?: InputMaybe<Array<DealerLinkParentCreateWithPositionInput>>;
  /** Delete multiple DealerLinkParent documents */
  delete?: InputMaybe<Array<DealerLinkParentWhereUniqueInput>>;
  /** Update multiple DealerLinkParent component instances */
  update?: InputMaybe<Array<DealerLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple DealerLinkParent component instances */
  upsert?: InputMaybe<Array<DealerLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type DealerLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type DealerLinkParentUpdateOneInlineInput = {
  /** Create and connect one DealerLinkParent document */
  create?: InputMaybe<DealerLinkParentCreateInput>;
  /** Delete currently connected DealerLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single DealerLinkParent document */
  update?: InputMaybe<DealerLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DealerLinkParent document */
  upsert?: InputMaybe<DealerLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type DealerLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type DealerLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type DealerLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type DealerLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type DealerLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type DealerLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type DealerLinkUpdateInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  dealer?: InputMaybe<DealerUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<DealerLinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['Int']['input']>;
};

export type DealerLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DealerLinkUpdateLocalizationInput = {
  data: DealerLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type DealerLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DealerLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DealerLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DealerLinkUpsertLocalizationInput>>;
};

export type DealerLinkUpdateManyInlineInput = {
  /** Create and connect multiple DealerLink component instances */
  create?: InputMaybe<Array<DealerLinkCreateWithPositionInput>>;
  /** Delete multiple DealerLink documents */
  delete?: InputMaybe<Array<DealerLinkWhereUniqueInput>>;
  /** Update multiple DealerLink component instances */
  update?: InputMaybe<Array<DealerLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple DealerLink component instances */
  upsert?: InputMaybe<Array<DealerLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type DealerLinkUpdateManyInput = {
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DealerLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['Int']['input']>;
};

export type DealerLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DealerLinkUpdateManyLocalizationInput = {
  data: DealerLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DealerLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DealerLinkUpdateManyLocalizationInput>>;
};

export type DealerLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DealerLinkUpdateManyInput;
  /** Document search */
  where: DealerLinkWhereInput;
};

export type DealerLinkUpdateOneInlineInput = {
  /** Create and connect one DealerLink document */
  create?: InputMaybe<DealerLinkCreateInput>;
  /** Delete currently connected DealerLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single DealerLink document */
  update?: InputMaybe<DealerLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DealerLink document */
  upsert?: InputMaybe<DealerLinkUpsertWithNestedWhereUniqueInput>;
};

export type DealerLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DealerLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DealerLinkWhereUniqueInput;
};

export type DealerLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DealerLinkUpdateInput;
  /** Unique document search */
  where: DealerLinkWhereUniqueInput;
};

export type DealerLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: DealerLinkCreateInput;
  /** Update document if it exists */
  update: DealerLinkUpdateInput;
};

export type DealerLinkUpsertLocalizationInput = {
  create: DealerLinkCreateLocalizationDataInput;
  locale: Locale;
  update: DealerLinkUpdateLocalizationDataInput;
};

export type DealerLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DealerLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DealerLinkWhereUniqueInput;
};

export type DealerLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DealerLinkUpsertInput;
  /** Unique document search */
  where: DealerLinkWhereUniqueInput;
};

/** Identifies documents */
export type DealerLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  additionalQueryParameters?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  additionalQueryParameters_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  additionalQueryParameters_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  additionalQueryParameters_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  additionalQueryParameters_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  additionalQueryParameters_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  additionalQueryParameters_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  additionalQueryParameters_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  additionalQueryParameters_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  additionalQueryParameters_starts_with?: InputMaybe<Scalars['String']['input']>;
  dealer?: InputMaybe<DealerWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  tab?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  tab_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  tab_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  tab_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  tab_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  tab_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  tab_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  tab_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** References DealerLink record uniquely */
export type DealerLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type DealerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  calltrackingPhone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  calltrackingPhone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  calltrackingPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  calltrackingPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingPhone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  calltrackingPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  calltrackingPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  calltrackingPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  calltrackingPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  calltrackingPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  calltrackingPoolId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  calltrackingPoolId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  calltrackingPoolId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  calltrackingPoolId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  calltrackingPoolId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  calltrackingPoolId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingPoolId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  calltrackingPoolId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  calltrackingSubscriberId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  calltrackingSubscriberId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  calltrackingSubscriberId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  calltrackingSubscriberId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  calltrackingSubscriberId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  calltrackingSubscriberId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingSubscriberId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  calltrackingSubscriberId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  carmarketReturnPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  carmarketReturnPolicy_not?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  city_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  city_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  city_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  city_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  city_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  city_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  city_starts_with?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  /** All values that are contained in given list. */
  country_in?: InputMaybe<Array<InputMaybe<Country>>>;
  /** Any other value that exists and is not equal to the given value. */
  country_not?: InputMaybe<Country>;
  /** All values that are not contained in given list. */
  country_not_in?: InputMaybe<Array<InputMaybe<Country>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<DealerWhereStageInput>;
  documentInStages_none?: InputMaybe<DealerWhereStageInput>;
  documentInStages_some?: InputMaybe<DealerWhereStageInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values containing the given json path. */
  externalIds_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  externalIds_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  fax_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  fax_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  fax_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fax_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  fax_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  fax_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  fax_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  fax_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  fax_starts_with?: InputMaybe<Scalars['String']['input']>;
  gPlaceId?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  gPlaceId_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  gPlaceId_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  gPlaceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  gPlaceId_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  gPlaceId_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  gPlaceId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  gPlaceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  gPlaceId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  gPlaceId_starts_with?: InputMaybe<Scalars['String']['input']>;
  homeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  homeDeliveryAvailable_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values containing the given json path. */
  homeDeliveryFee_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  homeDeliveryFee_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  imageUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  imageUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  imageUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  imageUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  leasingApplicationAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingApplicationAvailable_not?: InputMaybe<Scalars['Boolean']['input']>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given json path. */
  openingHours_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  openingHours_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Language>;
  /** All values that are contained in given list. */
  primaryLanguage_in?: InputMaybe<Array<InputMaybe<Language>>>;
  /** Any other value that exists and is not equal to the given value. */
  primaryLanguage_not?: InputMaybe<Language>;
  /** All values that are not contained in given list. */
  primaryLanguage_not_in?: InputMaybe<Array<InputMaybe<Language>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisor?: InputMaybe<EmployeeWhereInput>;
  salesAdvisorList?: InputMaybe<SalesAdvisorListWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  services_every?: InputMaybe<ServiceWhereInput>;
  services_none?: InputMaybe<ServiceWhereInput>;
  services_some?: InputMaybe<ServiceWhereInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  street_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  street_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  street_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  street_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  street_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  street_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  street_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  street_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  street_starts_with?: InputMaybe<Scalars['String']['input']>;
  subname?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  subname_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  subname_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  subname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subname_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  subname_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  subname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  subname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  subname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  subname_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  urlFacebook?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlFacebook_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlFacebook_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlFacebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlFacebook_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlFacebook_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlFacebook_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlFacebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlFacebook_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlFacebook_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlGoogle?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlGoogle_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlGoogle_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlGoogle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlGoogle_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlGoogle_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlGoogle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlGoogle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlGoogle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlGoogle_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlInstagram?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlInstagram_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlInstagram_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlInstagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlInstagram_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlInstagram_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlInstagram_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlInstagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlInstagram_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlInstagram_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlSnapchat?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlSnapchat_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlSnapchat_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlSnapchat_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlSnapchat_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlSnapchat_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlSnapchat_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlSnapchat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlSnapchat_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlSnapchat_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlTwitter?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlTwitter_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlTwitter_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlTwitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlTwitter_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlTwitter_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlTwitter_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlTwitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlTwitter_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlTwitter_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlWebsite?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlWebsite_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlWebsite_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlWebsite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlWebsite_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlWebsite_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlWebsite_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlWebsite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlWebsite_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlWebsite_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlYoutube?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlYoutube_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlYoutube_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlYoutube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlYoutube_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlYoutube_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlYoutube_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlYoutube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlYoutube_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlYoutube_starts_with?: InputMaybe<Scalars['String']['input']>;
  vehicles_every?: InputMaybe<VehicleWhereInput>;
  vehicles_none?: InputMaybe<VehicleWhereInput>;
  vehicles_some?: InputMaybe<VehicleWhereInput>;
  whitelabels_every?: InputMaybe<WhitelabelWhereInput>;
  whitelabels_none?: InputMaybe<WhitelabelWhereInput>;
  whitelabels_some?: InputMaybe<WhitelabelWhereInput>;
  /** All values containing the given json path. */
  workingHoursExceptions_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  workingHoursExceptions_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  zip_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  zip_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  zip_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  zip_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  zip_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  zip_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  zip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  zip_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  zip_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum DealerOrderByInput {
  CalltrackingPhoneAsc = 'calltrackingPhone_ASC',
  CalltrackingPhoneDesc = 'calltrackingPhone_DESC',
  CalltrackingPoolIdAsc = 'calltrackingPoolId_ASC',
  CalltrackingPoolIdDesc = 'calltrackingPoolId_DESC',
  CalltrackingSubscriberIdAsc = 'calltrackingSubscriberId_ASC',
  CalltrackingSubscriberIdDesc = 'calltrackingSubscriberId_DESC',
  CarmarketReturnPolicyAsc = 'carmarketReturnPolicy_ASC',
  CarmarketReturnPolicyDesc = 'carmarketReturnPolicy_DESC',
  CityAsc = 'city_ASC',
  CityDesc = 'city_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  FaxAsc = 'fax_ASC',
  FaxDesc = 'fax_DESC',
  GPlaceIdAsc = 'gPlaceId_ASC',
  GPlaceIdDesc = 'gPlaceId_DESC',
  HomeDeliveryAvailableAsc = 'homeDeliveryAvailable_ASC',
  HomeDeliveryAvailableDesc = 'homeDeliveryAvailable_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageUrlsAsc = 'imageUrls_ASC',
  ImageUrlsDesc = 'imageUrls_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  IntroTextAsc = 'introText_ASC',
  IntroTextDesc = 'introText_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  LanguagesAsc = 'languages_ASC',
  LanguagesDesc = 'languages_DESC',
  LeasingApplicationAvailableAsc = 'leasingApplicationAvailable_ASC',
  LeasingApplicationAvailableDesc = 'leasingApplicationAvailable_DESC',
  LogoPictureUrlAsc = 'logoPictureUrl_ASC',
  LogoPictureUrlDesc = 'logoPictureUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NewVehicleTcUrlAsc = 'newVehicleTcUrl_ASC',
  NewVehicleTcUrlDesc = 'newVehicleTcUrl_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PrimaryLanguageAsc = 'primaryLanguage_ASC',
  PrimaryLanguageDesc = 'primaryLanguage_DESC',
  PrivacyPolicyUrlAsc = 'privacyPolicyUrl_ASC',
  PrivacyPolicyUrlDesc = 'privacyPolicyUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StreetAsc = 'street_ASC',
  StreetDesc = 'street_DESC',
  SubnameAsc = 'subname_ASC',
  SubnameDesc = 'subname_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlFacebookAsc = 'urlFacebook_ASC',
  UrlFacebookDesc = 'urlFacebook_DESC',
  UrlGoogleAsc = 'urlGoogle_ASC',
  UrlGoogleDesc = 'urlGoogle_DESC',
  UrlInstagramAsc = 'urlInstagram_ASC',
  UrlInstagramDesc = 'urlInstagram_DESC',
  UrlSnapchatAsc = 'urlSnapchat_ASC',
  UrlSnapchatDesc = 'urlSnapchat_DESC',
  UrlTwitterAsc = 'urlTwitter_ASC',
  UrlTwitterDesc = 'urlTwitter_DESC',
  UrlWebsiteAsc = 'urlWebsite_ASC',
  UrlWebsiteDesc = 'urlWebsite_DESC',
  UrlYoutubeAsc = 'urlYoutube_ASC',
  UrlYoutubeDesc = 'urlYoutube_DESC',
  UsedVehicleTcUrlAsc = 'usedVehicleTcUrl_ASC',
  UsedVehicleTcUrlDesc = 'usedVehicleTcUrl_DESC',
  ZipAsc = 'zip_ASC',
  ZipDesc = 'zip_DESC'
}

export type DealerUpdateInput = {
  brands?: InputMaybe<BrandUpdateManyInlineInput>;
  calltrackingPhone?: InputMaybe<Scalars['String']['input']>;
  calltrackingPoolId?: InputMaybe<Scalars['Int']['input']>;
  calltrackingSubscriberId?: InputMaybe<Scalars['Int']['input']>;
  campaignBanners?: InputMaybe<AdUpdateManyInlineInput>;
  carmarketReturnPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clxmyi2wx00zx07vyau72hsw5?: InputMaybe<DealerLinkUpdateManyInlineInput>;
  country?: InputMaybe<Country>;
  dealerEmployees?: InputMaybe<DealerEmployeeUpdateManyInlineInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  externalIds?: InputMaybe<Scalars['Json']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  gPlaceId?: InputMaybe<Scalars['String']['input']>;
  geolocation?: InputMaybe<LocationInput>;
  homeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  homeDeliveryFee?: InputMaybe<Scalars['Json']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** introText input for default locale (de) */
  introText?: InputMaybe<Scalars['String']['input']>;
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  languages?: InputMaybe<Array<Language>>;
  leasingApplicationAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<DealerUpdateLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** newVehicleTcUrl input for default locale (de) */
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<Scalars['Json']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Language>;
  /** privacyPolicyUrl input for default locale (de) */
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  salesAdvisor?: InputMaybe<EmployeeUpdateOneInlineInput>;
  salesAdvisorList?: InputMaybe<SalesAdvisorListUpdateOneInlineInput>;
  services?: InputMaybe<ServiceUpdateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  subname?: InputMaybe<Scalars['String']['input']>;
  urlFacebook?: InputMaybe<Scalars['String']['input']>;
  urlGoogle?: InputMaybe<Scalars['String']['input']>;
  urlInstagram?: InputMaybe<Scalars['String']['input']>;
  urlSnapchat?: InputMaybe<Scalars['String']['input']>;
  urlTwitter?: InputMaybe<Scalars['String']['input']>;
  urlWebsite?: InputMaybe<Scalars['String']['input']>;
  urlYoutube?: InputMaybe<Scalars['String']['input']>;
  /** usedVehicleTcUrl input for default locale (de) */
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  vehicles?: InputMaybe<VehicleUpdateManyInlineInput>;
  whitelabels?: InputMaybe<WhitelabelUpdateManyInlineInput>;
  workingHoursExceptions?: InputMaybe<Scalars['Json']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DealerUpdateLocalizationDataInput = {
  introText?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
};

export type DealerUpdateLocalizationInput = {
  data: DealerUpdateLocalizationDataInput;
  locale: Locale;
};

export type DealerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DealerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DealerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DealerUpsertLocalizationInput>>;
};

export type DealerUpdateManyInlineInput = {
  /** Connect multiple existing Dealer documents */
  connect?: InputMaybe<Array<DealerConnectInput>>;
  /** Create and connect multiple Dealer documents */
  create?: InputMaybe<Array<DealerCreateInput>>;
  /** Delete multiple Dealer documents */
  delete?: InputMaybe<Array<DealerWhereUniqueInput>>;
  /** Disconnect multiple Dealer documents */
  disconnect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Dealer documents */
  set?: InputMaybe<Array<DealerWhereUniqueInput>>;
  /** Update multiple Dealer documents */
  update?: InputMaybe<Array<DealerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Dealer documents */
  upsert?: InputMaybe<Array<DealerUpsertWithNestedWhereUniqueInput>>;
};

export type DealerUpdateManyInput = {
  calltrackingPhone?: InputMaybe<Scalars['String']['input']>;
  calltrackingPoolId?: InputMaybe<Scalars['Int']['input']>;
  carmarketReturnPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  externalIds?: InputMaybe<Scalars['Json']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  gPlaceId?: InputMaybe<Scalars['String']['input']>;
  geolocation?: InputMaybe<LocationInput>;
  homeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  homeDeliveryFee?: InputMaybe<Scalars['Json']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** introText input for default locale (de) */
  introText?: InputMaybe<Scalars['String']['input']>;
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Language>>;
  leasingApplicationAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DealerUpdateManyLocalizationsInput>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** newVehicleTcUrl input for default locale (de) */
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<Scalars['Json']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Language>;
  /** privacyPolicyUrl input for default locale (de) */
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  subname?: InputMaybe<Scalars['String']['input']>;
  urlFacebook?: InputMaybe<Scalars['String']['input']>;
  urlGoogle?: InputMaybe<Scalars['String']['input']>;
  urlInstagram?: InputMaybe<Scalars['String']['input']>;
  urlSnapchat?: InputMaybe<Scalars['String']['input']>;
  urlTwitter?: InputMaybe<Scalars['String']['input']>;
  urlWebsite?: InputMaybe<Scalars['String']['input']>;
  urlYoutube?: InputMaybe<Scalars['String']['input']>;
  /** usedVehicleTcUrl input for default locale (de) */
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  workingHoursExceptions?: InputMaybe<Scalars['Json']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type DealerUpdateManyLocalizationDataInput = {
  introText?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
};

export type DealerUpdateManyLocalizationInput = {
  data: DealerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DealerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DealerUpdateManyLocalizationInput>>;
};

export type DealerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DealerUpdateManyInput;
  /** Document search */
  where: DealerWhereInput;
};

export type DealerUpdateOneInlineInput = {
  /** Connect existing Dealer document */
  connect?: InputMaybe<DealerWhereUniqueInput>;
  /** Create and connect one Dealer document */
  create?: InputMaybe<DealerCreateInput>;
  /** Delete currently connected Dealer document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Dealer document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Dealer document */
  update?: InputMaybe<DealerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Dealer document */
  upsert?: InputMaybe<DealerUpsertWithNestedWhereUniqueInput>;
};

export type DealerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DealerUpdateInput;
  /** Unique document search */
  where: DealerWhereUniqueInput;
};

export type DealerUpsertInput = {
  /** Create document if it didn't exist */
  create: DealerCreateInput;
  /** Update document if it exists */
  update: DealerUpdateInput;
};

export type DealerUpsertLocalizationInput = {
  create: DealerCreateLocalizationDataInput;
  locale: Locale;
  update: DealerUpdateLocalizationDataInput;
};

export type DealerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DealerUpsertInput;
  /** Unique document search */
  where: DealerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DealerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type DealerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  calltrackingPhone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  calltrackingPhone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  calltrackingPhone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  calltrackingPhone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingPhone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  calltrackingPhone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  calltrackingPhone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  calltrackingPhone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  calltrackingPhone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  calltrackingPhone_starts_with?: InputMaybe<Scalars['String']['input']>;
  calltrackingPoolId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  calltrackingPoolId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  calltrackingPoolId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  calltrackingPoolId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  calltrackingPoolId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  calltrackingPoolId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingPoolId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  calltrackingPoolId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  calltrackingSubscriberId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  calltrackingSubscriberId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  calltrackingSubscriberId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  calltrackingSubscriberId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  calltrackingSubscriberId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  calltrackingSubscriberId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  calltrackingSubscriberId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  calltrackingSubscriberId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  carmarketReturnPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  carmarketReturnPolicy_not?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  city_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  city_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  city_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  city_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  city_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  city_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  city_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  city_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  city_starts_with?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Country>;
  /** All values that are contained in given list. */
  country_in?: InputMaybe<Array<InputMaybe<Country>>>;
  /** Any other value that exists and is not equal to the given value. */
  country_not?: InputMaybe<Country>;
  /** All values that are not contained in given list. */
  country_not_in?: InputMaybe<Array<InputMaybe<Country>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<DealerWhereStageInput>;
  documentInStages_none?: InputMaybe<DealerWhereStageInput>;
  documentInStages_some?: InputMaybe<DealerWhereStageInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values containing the given json path. */
  externalIds_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  externalIds_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  fax_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  fax_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  fax_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fax_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  fax_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  fax_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  fax_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  fax_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  fax_starts_with?: InputMaybe<Scalars['String']['input']>;
  gPlaceId?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  gPlaceId_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  gPlaceId_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  gPlaceId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  gPlaceId_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  gPlaceId_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  gPlaceId_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  gPlaceId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  gPlaceId_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  gPlaceId_starts_with?: InputMaybe<Scalars['String']['input']>;
  homeDeliveryAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  homeDeliveryAvailable_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** All values containing the given json path. */
  homeDeliveryFee_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  homeDeliveryFee_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  imageUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  imageUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  imageUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  imageUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  introText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  introText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  introText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  introText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  introText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  introText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  introText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  introText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  introText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  introText_starts_with?: InputMaybe<Scalars['String']['input']>;
  introTitle?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  introTitle_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  introTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  introTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  introTitle_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  introTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  introTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  introTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  introTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  introTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  leasingApplicationAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingApplicationAvailable_not?: InputMaybe<Scalars['Boolean']['input']>;
  logoPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  newVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  newVehicleTcUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  newVehicleTcUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  newVehicleTcUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  newVehicleTcUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  newVehicleTcUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  newVehicleTcUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  newVehicleTcUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  newVehicleTcUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  newVehicleTcUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given json path. */
  openingHours_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  openingHours_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>;
  primaryLanguage?: InputMaybe<Language>;
  /** All values that are contained in given list. */
  primaryLanguage_in?: InputMaybe<Array<InputMaybe<Language>>>;
  /** Any other value that exists and is not equal to the given value. */
  primaryLanguage_not?: InputMaybe<Language>;
  /** All values that are not contained in given list. */
  primaryLanguage_not_in?: InputMaybe<Array<InputMaybe<Language>>>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  privacyPolicyUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  privacyPolicyUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  privacyPolicyUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  privacyPolicyUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  privacyPolicyUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  privacyPolicyUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  privacyPolicyUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  privacyPolicyUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  privacyPolicyUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisor?: InputMaybe<EmployeeWhereInput>;
  salesAdvisorList?: InputMaybe<SalesAdvisorListWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  services_every?: InputMaybe<ServiceWhereInput>;
  services_none?: InputMaybe<ServiceWhereInput>;
  services_some?: InputMaybe<ServiceWhereInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  street_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  street_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  street_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  street_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  street_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  street_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  street_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  street_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  street_starts_with?: InputMaybe<Scalars['String']['input']>;
  subname?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  subname_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  subname_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  subname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subname_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  subname_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  subname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  subname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  subname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  subname_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  urlFacebook?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlFacebook_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlFacebook_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlFacebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlFacebook_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlFacebook_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlFacebook_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlFacebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlFacebook_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlFacebook_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlGoogle?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlGoogle_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlGoogle_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlGoogle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlGoogle_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlGoogle_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlGoogle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlGoogle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlGoogle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlGoogle_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlInstagram?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlInstagram_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlInstagram_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlInstagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlInstagram_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlInstagram_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlInstagram_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlInstagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlInstagram_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlInstagram_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlSnapchat?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlSnapchat_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlSnapchat_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlSnapchat_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlSnapchat_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlSnapchat_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlSnapchat_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlSnapchat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlSnapchat_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlSnapchat_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlTwitter?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlTwitter_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlTwitter_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlTwitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlTwitter_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlTwitter_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlTwitter_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlTwitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlTwitter_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlTwitter_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlWebsite?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlWebsite_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlWebsite_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlWebsite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlWebsite_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlWebsite_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlWebsite_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlWebsite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlWebsite_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlWebsite_starts_with?: InputMaybe<Scalars['String']['input']>;
  urlYoutube?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  urlYoutube_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  urlYoutube_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  urlYoutube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  urlYoutube_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  urlYoutube_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  urlYoutube_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  urlYoutube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  urlYoutube_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  urlYoutube_starts_with?: InputMaybe<Scalars['String']['input']>;
  usedVehicleTcUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  usedVehicleTcUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  usedVehicleTcUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  usedVehicleTcUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  usedVehicleTcUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  usedVehicleTcUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  usedVehicleTcUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  usedVehicleTcUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  usedVehicleTcUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  usedVehicleTcUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  vehicles_every?: InputMaybe<VehicleWhereInput>;
  vehicles_none?: InputMaybe<VehicleWhereInput>;
  vehicles_some?: InputMaybe<VehicleWhereInput>;
  whitelabels_every?: InputMaybe<WhitelabelWhereInput>;
  whitelabels_none?: InputMaybe<WhitelabelWhereInput>;
  whitelabels_some?: InputMaybe<WhitelabelWhereInput>;
  /** All values containing the given json path. */
  workingHoursExceptions_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  workingHoursExceptions_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  zip_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  zip_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  zip_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  zip_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  zip_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  zip_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  zip_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  zip_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  zip_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DealerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DealerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DealerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DealerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DealerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Dealer record uniquely */
export type DealerWhereUniqueInput = {
  calltrackingSubscriberId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Department = Entity & Node & {
  __typename?: 'Department';
  code: Scalars['String']['output'];
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealerEmployees: Array<DealerEmployee>;
  /** Get the document in other stages */
  documentInStages: Array<Department>;
  /** List of Department versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Department>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  showInTeamTab?: Maybe<Scalars['Boolean']['output']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type DepartmentCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DepartmentCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DepartmentDealerEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerEmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerEmployeeWhereInput>;
};


export type DepartmentDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type DepartmentHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type DepartmentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type DepartmentPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DepartmentPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type DepartmentScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type DepartmentUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type DepartmentUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DepartmentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DepartmentWhereUniqueInput;
};

/** A connection to a list of items. */
export type DepartmentConnection = {
  __typename?: 'DepartmentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DepartmentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DepartmentCreateInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealerEmployees?: InputMaybe<DealerEmployeeCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DepartmentCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  showInTeamTab?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DepartmentCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DepartmentCreateLocalizationInput = {
  /** Localization input */
  data: DepartmentCreateLocalizationDataInput;
  locale: Locale;
};

export type DepartmentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DepartmentCreateLocalizationInput>>;
};

export type DepartmentCreateManyInlineInput = {
  /** Connect multiple existing Department documents */
  connect?: InputMaybe<Array<DepartmentWhereUniqueInput>>;
  /** Create and connect multiple existing Department documents */
  create?: InputMaybe<Array<DepartmentCreateInput>>;
};

export type DepartmentCreateOneInlineInput = {
  /** Connect one existing Department document */
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  /** Create and connect one Department document */
  create?: InputMaybe<DepartmentCreateInput>;
};

/** An edge in a connection. */
export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Department;
};

/** Identifies documents */
export type DepartmentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<DepartmentWhereStageInput>;
  documentInStages_none?: InputMaybe<DepartmentWhereStageInput>;
  documentInStages_some?: InputMaybe<DepartmentWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showInTeamTab?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showInTeamTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum DepartmentOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowInTeamTabAsc = 'showInTeamTab_ASC',
  ShowInTeamTabDesc = 'showInTeamTab_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DepartmentUpdateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  dealerEmployees?: InputMaybe<DealerEmployeeUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<DepartmentUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  showInTeamTab?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DepartmentUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DepartmentUpdateLocalizationInput = {
  data: DepartmentUpdateLocalizationDataInput;
  locale: Locale;
};

export type DepartmentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DepartmentCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DepartmentUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DepartmentUpsertLocalizationInput>>;
};

export type DepartmentUpdateManyInlineInput = {
  /** Connect multiple existing Department documents */
  connect?: InputMaybe<Array<DepartmentConnectInput>>;
  /** Create and connect multiple Department documents */
  create?: InputMaybe<Array<DepartmentCreateInput>>;
  /** Delete multiple Department documents */
  delete?: InputMaybe<Array<DepartmentWhereUniqueInput>>;
  /** Disconnect multiple Department documents */
  disconnect?: InputMaybe<Array<DepartmentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Department documents */
  set?: InputMaybe<Array<DepartmentWhereUniqueInput>>;
  /** Update multiple Department documents */
  update?: InputMaybe<Array<DepartmentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Department documents */
  upsert?: InputMaybe<Array<DepartmentUpsertWithNestedWhereUniqueInput>>;
};

export type DepartmentUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<DepartmentUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  showInTeamTab?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DepartmentUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DepartmentUpdateManyLocalizationInput = {
  data: DepartmentUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DepartmentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DepartmentUpdateManyLocalizationInput>>;
};

export type DepartmentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DepartmentUpdateManyInput;
  /** Document search */
  where: DepartmentWhereInput;
};

export type DepartmentUpdateOneInlineInput = {
  /** Connect existing Department document */
  connect?: InputMaybe<DepartmentWhereUniqueInput>;
  /** Create and connect one Department document */
  create?: InputMaybe<DepartmentCreateInput>;
  /** Delete currently connected Department document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Department document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Department document */
  update?: InputMaybe<DepartmentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Department document */
  upsert?: InputMaybe<DepartmentUpsertWithNestedWhereUniqueInput>;
};

export type DepartmentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DepartmentUpdateInput;
  /** Unique document search */
  where: DepartmentWhereUniqueInput;
};

export type DepartmentUpsertInput = {
  /** Create document if it didn't exist */
  create: DepartmentCreateInput;
  /** Update document if it exists */
  update: DepartmentUpdateInput;
};

export type DepartmentUpsertLocalizationInput = {
  create: DepartmentCreateLocalizationDataInput;
  locale: Locale;
  update: DepartmentUpdateLocalizationDataInput;
};

export type DepartmentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DepartmentUpsertInput;
  /** Unique document search */
  where: DepartmentWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DepartmentWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type DepartmentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DepartmentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<DepartmentWhereStageInput>;
  documentInStages_none?: InputMaybe<DepartmentWhereStageInput>;
  documentInStages_some?: InputMaybe<DepartmentWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  position_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  position_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  position_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  position_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showInTeamTab?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showInTeamTab_not?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DepartmentWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DepartmentWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DepartmentWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DepartmentWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DepartmentWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Department record uniquely */
export type DepartmentWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum DocumentFileTypes {
  Doc = 'doc',
  Docx = 'docx',
  Html = 'html',
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Xls = 'xls',
  Xlsx = 'xlsx'
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  revision: Scalars['Int']['output'];
  stage: Stage;
};

/** All wheel drive, Front, etc. */
export type DriveType = Entity & Node & {
  __typename?: 'DriveType';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<DriveType>;
  /** List of DriveType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<DriveType>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All wheel drive, Front, etc. */
export type DriveTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** All wheel drive, Front, etc. */
export type DriveTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All wheel drive, Front, etc. */
export type DriveTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** All wheel drive, Front, etc. */
export type DriveTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** All wheel drive, Front, etc. */
export type DriveTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DriveTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DriveTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type DriveTypeConnection = {
  __typename?: 'DriveTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DriveTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DriveTypeCreateInput = {
  cl16j288lhxyr01zaaadefj2k?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DriveTypeCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DriveTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DriveTypeCreateLocalizationInput = {
  /** Localization input */
  data: DriveTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type DriveTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DriveTypeCreateLocalizationInput>>;
};

export type DriveTypeCreateManyInlineInput = {
  /** Connect multiple existing DriveType documents */
  connect?: InputMaybe<Array<DriveTypeWhereUniqueInput>>;
  /** Create and connect multiple existing DriveType documents */
  create?: InputMaybe<Array<DriveTypeCreateInput>>;
};

export type DriveTypeCreateOneInlineInput = {
  /** Connect one existing DriveType document */
  connect?: InputMaybe<DriveTypeWhereUniqueInput>;
  /** Create and connect one DriveType document */
  create?: InputMaybe<DriveTypeCreateInput>;
};

/** An edge in a connection. */
export type DriveTypeEdge = {
  __typename?: 'DriveTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DriveType;
};

/** Identifies documents */
export type DriveTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<DriveTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<DriveTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<DriveTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum DriveTypeOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type DriveTypeUpdateInput = {
  cl16j288lhxyr01zaaadefj2k?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<DriveTypeUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTypeUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTypeUpdateLocalizationInput = {
  data: DriveTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type DriveTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DriveTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DriveTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DriveTypeUpsertLocalizationInput>>;
};

export type DriveTypeUpdateManyInlineInput = {
  /** Connect multiple existing DriveType documents */
  connect?: InputMaybe<Array<DriveTypeConnectInput>>;
  /** Create and connect multiple DriveType documents */
  create?: InputMaybe<Array<DriveTypeCreateInput>>;
  /** Delete multiple DriveType documents */
  delete?: InputMaybe<Array<DriveTypeWhereUniqueInput>>;
  /** Disconnect multiple DriveType documents */
  disconnect?: InputMaybe<Array<DriveTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DriveType documents */
  set?: InputMaybe<Array<DriveTypeWhereUniqueInput>>;
  /** Update multiple DriveType documents */
  update?: InputMaybe<Array<DriveTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DriveType documents */
  upsert?: InputMaybe<Array<DriveTypeUpsertWithNestedWhereUniqueInput>>;
};

export type DriveTypeUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DriveTypeUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTypeUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type DriveTypeUpdateManyLocalizationInput = {
  data: DriveTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DriveTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DriveTypeUpdateManyLocalizationInput>>;
};

export type DriveTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DriveTypeUpdateManyInput;
  /** Document search */
  where: DriveTypeWhereInput;
};

export type DriveTypeUpdateOneInlineInput = {
  /** Connect existing DriveType document */
  connect?: InputMaybe<DriveTypeWhereUniqueInput>;
  /** Create and connect one DriveType document */
  create?: InputMaybe<DriveTypeCreateInput>;
  /** Delete currently connected DriveType document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected DriveType document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single DriveType document */
  update?: InputMaybe<DriveTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DriveType document */
  upsert?: InputMaybe<DriveTypeUpsertWithNestedWhereUniqueInput>;
};

export type DriveTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DriveTypeUpdateInput;
  /** Unique document search */
  where: DriveTypeWhereUniqueInput;
};

export type DriveTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: DriveTypeCreateInput;
  /** Update document if it exists */
  update: DriveTypeUpdateInput;
};

export type DriveTypeUpsertLocalizationInput = {
  create: DriveTypeCreateLocalizationDataInput;
  locale: Locale;
  update: DriveTypeUpdateLocalizationDataInput;
};

export type DriveTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DriveTypeUpsertInput;
  /** Unique document search */
  where: DriveTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DriveTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type DriveTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DriveTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<DriveTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<DriveTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<DriveTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DriveTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DriveTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DriveTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DriveTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DriveTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References DriveType record uniquely */
export type DriveTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

/** A+, A, B, C, etc. */
export type EmissionClass = Entity & Node & {
  __typename?: 'EmissionClass';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<EmissionClass>;
  /** List of EmissionClass versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<EmissionClass>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** A+, A, B, C, etc. */
export type EmissionClassCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** A+, A, B, C, etc. */
export type EmissionClassCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** A+, A, B, C, etc. */
export type EmissionClassDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** A+, A, B, C, etc. */
export type EmissionClassHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** A+, A, B, C, etc. */
export type EmissionClassLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** A+, A, B, C, etc. */
export type EmissionClassPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** A+, A, B, C, etc. */
export type EmissionClassPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** A+, A, B, C, etc. */
export type EmissionClassScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** A+, A, B, C, etc. */
export type EmissionClassUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** A+, A, B, C, etc. */
export type EmissionClassUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type EmissionClassConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmissionClassWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmissionClassConnection = {
  __typename?: 'EmissionClassConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmissionClassEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmissionClassCreateInput = {
  cl16j2j8rhx0a01z19wtmeq8y?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EmissionClassCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmissionClassCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmissionClassCreateLocalizationInput = {
  /** Localization input */
  data: EmissionClassCreateLocalizationDataInput;
  locale: Locale;
};

export type EmissionClassCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EmissionClassCreateLocalizationInput>>;
};

export type EmissionClassCreateManyInlineInput = {
  /** Connect multiple existing EmissionClass documents */
  connect?: InputMaybe<Array<EmissionClassWhereUniqueInput>>;
  /** Create and connect multiple existing EmissionClass documents */
  create?: InputMaybe<Array<EmissionClassCreateInput>>;
};

export type EmissionClassCreateOneInlineInput = {
  /** Connect one existing EmissionClass document */
  connect?: InputMaybe<EmissionClassWhereUniqueInput>;
  /** Create and connect one EmissionClass document */
  create?: InputMaybe<EmissionClassCreateInput>;
};

/** An edge in a connection. */
export type EmissionClassEdge = {
  __typename?: 'EmissionClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: EmissionClass;
};

/** Identifies documents */
export type EmissionClassManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmissionClassWhereStageInput>;
  documentInStages_none?: InputMaybe<EmissionClassWhereStageInput>;
  documentInStages_some?: InputMaybe<EmissionClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmissionClassOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EmissionClassUpdateInput = {
  cl16j2j8rhx0a01z19wtmeq8y?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<EmissionClassUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type EmissionClassUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type EmissionClassUpdateLocalizationInput = {
  data: EmissionClassUpdateLocalizationDataInput;
  locale: Locale;
};

export type EmissionClassUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EmissionClassCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<EmissionClassUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<EmissionClassUpsertLocalizationInput>>;
};

export type EmissionClassUpdateManyInlineInput = {
  /** Connect multiple existing EmissionClass documents */
  connect?: InputMaybe<Array<EmissionClassConnectInput>>;
  /** Create and connect multiple EmissionClass documents */
  create?: InputMaybe<Array<EmissionClassCreateInput>>;
  /** Delete multiple EmissionClass documents */
  delete?: InputMaybe<Array<EmissionClassWhereUniqueInput>>;
  /** Disconnect multiple EmissionClass documents */
  disconnect?: InputMaybe<Array<EmissionClassWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing EmissionClass documents */
  set?: InputMaybe<Array<EmissionClassWhereUniqueInput>>;
  /** Update multiple EmissionClass documents */
  update?: InputMaybe<Array<EmissionClassUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple EmissionClass documents */
  upsert?: InputMaybe<Array<EmissionClassUpsertWithNestedWhereUniqueInput>>;
};

export type EmissionClassUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<EmissionClassUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type EmissionClassUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type EmissionClassUpdateManyLocalizationInput = {
  data: EmissionClassUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type EmissionClassUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EmissionClassUpdateManyLocalizationInput>>;
};

export type EmissionClassUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmissionClassUpdateManyInput;
  /** Document search */
  where: EmissionClassWhereInput;
};

export type EmissionClassUpdateOneInlineInput = {
  /** Connect existing EmissionClass document */
  connect?: InputMaybe<EmissionClassWhereUniqueInput>;
  /** Create and connect one EmissionClass document */
  create?: InputMaybe<EmissionClassCreateInput>;
  /** Delete currently connected EmissionClass document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected EmissionClass document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single EmissionClass document */
  update?: InputMaybe<EmissionClassUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmissionClass document */
  upsert?: InputMaybe<EmissionClassUpsertWithNestedWhereUniqueInput>;
};

export type EmissionClassUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmissionClassUpdateInput;
  /** Unique document search */
  where: EmissionClassWhereUniqueInput;
};

export type EmissionClassUpsertInput = {
  /** Create document if it didn't exist */
  create: EmissionClassCreateInput;
  /** Update document if it exists */
  update: EmissionClassUpdateInput;
};

export type EmissionClassUpsertLocalizationInput = {
  create: EmissionClassCreateLocalizationDataInput;
  locale: Locale;
  update: EmissionClassUpdateLocalizationDataInput;
};

export type EmissionClassUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmissionClassUpsertInput;
  /** Unique document search */
  where: EmissionClassWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type EmissionClassWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type EmissionClassWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmissionClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmissionClassWhereStageInput>;
  documentInStages_none?: InputMaybe<EmissionClassWhereStageInput>;
  documentInStages_some?: InputMaybe<EmissionClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EmissionClassWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmissionClassWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmissionClassWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmissionClassWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EmissionClassWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References EmissionClass record uniquely */
export type EmissionClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Employee = Entity & Node & {
  __typename?: 'Employee';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealerEmployees: Array<DealerEmployee>;
  /** Get the document in other stages */
  documentInStages: Array<Employee>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  functionName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  /** List of Employee versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  salesAdvisorDealers: Array<Dealer>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type EmployeeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type EmployeeDealerEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerEmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerEmployeeWhereInput>;
};


export type EmployeeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type EmployeeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type EmployeePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type EmployeeSalesAdvisorDealersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type EmployeeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type EmployeeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type EmployeeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmployeeWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmployeeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmployeeCreateInput = {
  cltfln95z00w007w0hucp9ehn?: InputMaybe<SalesAdvisorCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealerEmployees?: InputMaybe<DealerEmployeeCreateManyInlineInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  functionName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  insideId: Scalars['Int']['input'];
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  salesAdvisorDealers?: InputMaybe<DealerCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EmployeeCreateManyInlineInput = {
  /** Connect multiple existing Employee documents */
  connect?: InputMaybe<Array<EmployeeWhereUniqueInput>>;
  /** Create and connect multiple existing Employee documents */
  create?: InputMaybe<Array<EmployeeCreateInput>>;
};

export type EmployeeCreateOneInlineInput = {
  /** Connect one existing Employee document */
  connect?: InputMaybe<EmployeeWhereUniqueInput>;
  /** Create and connect one Employee document */
  create?: InputMaybe<EmployeeCreateInput>;
};

/** An edge in a connection. */
export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Employee;
};

/** Identifies documents */
export type EmployeeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<EmployeeWhereStageInput>;
  documentInStages_none?: InputMaybe<EmployeeWhereStageInput>;
  documentInStages_some?: InputMaybe<EmployeeWhereStageInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  firstname_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  firstname_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  firstname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  firstname_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  firstname_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  firstname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  firstname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  firstname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  firstname_starts_with?: InputMaybe<Scalars['String']['input']>;
  functionName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  functionName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  functionName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  functionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  functionName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  functionName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  functionName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  functionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  functionName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  functionName_starts_with?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  /** All values that are contained in given list. */
  gender_in?: InputMaybe<Array<InputMaybe<Gender>>>;
  /** Any other value that exists and is not equal to the given value. */
  gender_not?: InputMaybe<Gender>;
  /** All values that are not contained in given list. */
  gender_not_in?: InputMaybe<Array<InputMaybe<Gender>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  mobile_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  mobile_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  mobile_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  mobile_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  mobile_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  mobile_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  profilePictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  profilePictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  profilePictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  profilePictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  profilePictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  profilePictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  profilePictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  profilePictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  profilePictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisorDealers_every?: InputMaybe<DealerWhereInput>;
  salesAdvisorDealers_none?: InputMaybe<DealerWhereInput>;
  salesAdvisorDealers_some?: InputMaybe<DealerWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmployeeOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  FunctionNameAsc = 'functionName_ASC',
  FunctionNameDesc = 'functionName_DESC',
  GenderAsc = 'gender_ASC',
  GenderDesc = 'gender_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  MobileAsc = 'mobile_ASC',
  MobileDesc = 'mobile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  ProfilePictureUrlAsc = 'profilePictureUrl_ASC',
  ProfilePictureUrlDesc = 'profilePictureUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type EmployeeUpdateInput = {
  cltfln95z00w007w0hucp9ehn?: InputMaybe<SalesAdvisorUpdateManyInlineInput>;
  dealerEmployees?: InputMaybe<DealerEmployeeUpdateManyInlineInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  functionName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  salesAdvisorDealers?: InputMaybe<DealerUpdateManyInlineInput>;
};

export type EmployeeUpdateManyInlineInput = {
  /** Connect multiple existing Employee documents */
  connect?: InputMaybe<Array<EmployeeConnectInput>>;
  /** Create and connect multiple Employee documents */
  create?: InputMaybe<Array<EmployeeCreateInput>>;
  /** Delete multiple Employee documents */
  delete?: InputMaybe<Array<EmployeeWhereUniqueInput>>;
  /** Disconnect multiple Employee documents */
  disconnect?: InputMaybe<Array<EmployeeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Employee documents */
  set?: InputMaybe<Array<EmployeeWhereUniqueInput>>;
  /** Update multiple Employee documents */
  update?: InputMaybe<Array<EmployeeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Employee documents */
  upsert?: InputMaybe<Array<EmployeeUpsertWithNestedWhereUniqueInput>>;
};

export type EmployeeUpdateManyInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  functionName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmployeeUpdateManyInput;
  /** Document search */
  where: EmployeeWhereInput;
};

export type EmployeeUpdateOneInlineInput = {
  /** Connect existing Employee document */
  connect?: InputMaybe<EmployeeWhereUniqueInput>;
  /** Create and connect one Employee document */
  create?: InputMaybe<EmployeeCreateInput>;
  /** Delete currently connected Employee document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Employee document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Employee document */
  update?: InputMaybe<EmployeeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Employee document */
  upsert?: InputMaybe<EmployeeUpsertWithNestedWhereUniqueInput>;
};

export type EmployeeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmployeeUpdateInput;
  /** Unique document search */
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpsertInput = {
  /** Create document if it didn't exist */
  create: EmployeeCreateInput;
  /** Update document if it exists */
  update: EmployeeUpdateInput;
};

export type EmployeeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmployeeUpsertInput;
  /** Unique document search */
  where: EmployeeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type EmployeeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type EmployeeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmployeeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealerEmployees_every?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_none?: InputMaybe<DealerEmployeeWhereInput>;
  dealerEmployees_some?: InputMaybe<DealerEmployeeWhereInput>;
  documentInStages_every?: InputMaybe<EmployeeWhereStageInput>;
  documentInStages_none?: InputMaybe<EmployeeWhereStageInput>;
  documentInStages_some?: InputMaybe<EmployeeWhereStageInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  firstname_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  firstname_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  firstname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  firstname_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  firstname_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  firstname_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  firstname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  firstname_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  firstname_starts_with?: InputMaybe<Scalars['String']['input']>;
  functionName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  functionName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  functionName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  functionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  functionName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  functionName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  functionName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  functionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  functionName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  functionName_starts_with?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  /** All values that are contained in given list. */
  gender_in?: InputMaybe<Array<InputMaybe<Gender>>>;
  /** Any other value that exists and is not equal to the given value. */
  gender_not?: InputMaybe<Gender>;
  /** All values that are not contained in given list. */
  gender_not_in?: InputMaybe<Array<InputMaybe<Gender>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  mobile_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  mobile_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  mobile_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  mobile_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  mobile_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  mobile_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>;
  profilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  profilePictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  profilePictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  profilePictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  profilePictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  profilePictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  profilePictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  profilePictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  profilePictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  profilePictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisorDealers_every?: InputMaybe<DealerWhereInput>;
  salesAdvisorDealers_none?: InputMaybe<DealerWhereInput>;
  salesAdvisorDealers_some?: InputMaybe<DealerWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EmployeeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmployeeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmployeeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmployeeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EmployeeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Employee record uniquely */
export type EmployeeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

/** An object with an ID */
export type Entity = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
  /** The Stage of an object */
  stage: Stage;
};

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
  AbsoluteUrlLink = 'AbsoluteUrlLink',
  AccordionChapter = 'AccordionChapter',
  AccordionChapterList = 'AccordionChapterList',
  AccordionSml = 'AccordionSml',
  AccordionSmlList = 'AccordionSmlList',
  Ad = 'Ad',
  AdTargetHero = 'AdTargetHero',
  AdTargetTile = 'AdTargetTile',
  AdTargetTileHomeRow = 'AdTargetTileHomeRow',
  AppLink = 'AppLink',
  Article = 'Article',
  ArticleLink = 'ArticleLink',
  /** Asset system model */
  Asset = 'Asset',
  AssetComponent = 'AssetComponent',
  Banner = 'Banner',
  BannerSlide = 'BannerSlide',
  /** Compact, Convertible, Bus, Coupe, etc. */
  BodyType = 'BodyType',
  BodyTypeGroup = 'BodyTypeGroup',
  Brand = 'Brand',
  BrandLink = 'BrandLink',
  Button = 'Button',
  ButtonRow = 'ButtonRow',
  Citatum = 'Citatum',
  /** green, red, blue, etc. */
  ColorNorm = 'ColorNorm',
  /** Metallic, Solid, Matte, etc. */
  ColorType = 'ColorType',
  ContentConfiguration = 'ContentConfiguration',
  Dealer = 'Dealer',
  DealerEmployee = 'DealerEmployee',
  DealerLink = 'DealerLink',
  Department = 'Department',
  /** All wheel drive, Front, etc. */
  DriveType = 'DriveType',
  /** A+, A, B, C, etc. */
  EmissionClass = 'EmissionClass',
  Employee = 'Employee',
  FactList = 'FactList',
  FeaturedVehiclesRow = 'FeaturedVehiclesRow',
  /** Diesel, Gas, Electric, etc. */
  FuelType = 'FuelType',
  FuelTypeGroup = 'FuelTypeGroup',
  Gallery = 'Gallery',
  GeneratedPageExtension = 'GeneratedPageExtension',
  HeroSlide = 'HeroSlide',
  HeroSlider = 'HeroSlider',
  Image = 'Image',
  ImageTextCombo = 'ImageTextCombo',
  ImageTextComboList = 'ImageTextComboList',
  KeywordList = 'KeywordList',
  Link = 'Link',
  MailtoLink = 'MailtoLink',
  Model = 'Model',
  ModelLink = 'ModelLink',
  OptionFacet = 'OptionFacet',
  PaPromotion = 'PaPromotion',
  Page = 'Page',
  PageLink = 'PageLink',
  /** Euro 1, Euro 2, etc. */
  PollutionClass = 'PollutionClass',
  QualityChecked = 'QualityChecked',
  QuestionAndAnswer = 'QuestionAndAnswer',
  QuestionAndAnswerList = 'QuestionAndAnswerList',
  Root = 'Root',
  SalesAdvisor = 'SalesAdvisor',
  SalesAdvisorList = 'SalesAdvisorList',
  /** Scheduled Operation system model */
  ScheduledOperation = 'ScheduledOperation',
  /** Scheduled Release system model */
  ScheduledRelease = 'ScheduledRelease',
  /** expressService, carWash, gasStation, etc. */
  Service = 'Service',
  SpecialBox = 'SpecialBox',
  TargetGeo = 'TargetGeo',
  TelLink = 'TelLink',
  TitleAndLead = 'TitleAndLead',
  /** Semi-Automatic, Manual Transmission, etc. */
  Transmission = 'Transmission',
  TransmissionGroup = 'TransmissionGroup',
  /** Alcantara, Cloth, Full Leather, etc. */
  Upholstery = 'Upholstery',
  UrlComponent = 'UrlComponent',
  /** User system model */
  User = 'User',
  Vehicle = 'Vehicle',
  /** Micro, Untere Mittelklasse, Oberklasse, etc. */
  VehicleClass = 'VehicleClass',
  /** New, Used, Demo, Salon, etc. */
  VehicleCondition = 'VehicleCondition',
  VehicleDiscount = 'VehicleDiscount',
  VehicleDiscountList = 'VehicleDiscountList',
  VehicleLeasingRateCalculation = 'VehicleLeasingRateCalculation',
  VehiclePromotion = 'VehiclePromotion',
  VehiclePromotionList = 'VehiclePromotionList',
  /** STOCK, ORDER, PIPELINE, etc. */
  VehicleStatus = 'VehicleStatus',
  /** Car, Commercial, etc. */
  VehicleType = 'VehicleType',
  Video = 'Video',
  Whitelabel = 'Whitelabel'
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
  /** The ID of an object */
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Locale>;
  stage: Stage;
  /** The Type name of an object */
  typename: EntityTypeName;
};

export type FactList = Entity & Node & {
  __typename?: 'FactList';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<FactList>;
  facts: Array<Scalars['String']['output']>;
  /** List of FactList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FactList>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type FactListCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FactListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FactListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type FactListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type FactListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type FactListPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FactListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FactListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type FactListUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FactListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FactListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FactListWhereUniqueInput;
};

/** A connection to a list of items. */
export type FactListConnection = {
  __typename?: 'FactListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FactListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FactListCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** facts input for default locale (de) */
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FactListCreateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FactListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FactListCreateLocalizationInput = {
  /** Localization input */
  data: FactListCreateLocalizationDataInput;
  locale: Locale;
};

export type FactListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FactListCreateLocalizationInput>>;
};

export type FactListCreateManyInlineInput = {
  /** Connect multiple existing FactList documents */
  connect?: InputMaybe<Array<FactListWhereUniqueInput>>;
  /** Create and connect multiple existing FactList documents */
  create?: InputMaybe<Array<FactListCreateInput>>;
};

export type FactListCreateOneInlineInput = {
  /** Connect one existing FactList document */
  connect?: InputMaybe<FactListWhereUniqueInput>;
  /** Create and connect one FactList document */
  create?: InputMaybe<FactListCreateInput>;
};

/** An edge in a connection. */
export type FactListEdge = {
  __typename?: 'FactListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FactList;
};

/** Identifies documents */
export type FactListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FactListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FactListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FactListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FactListWhereStageInput>;
  documentInStages_none?: InputMaybe<FactListWhereStageInput>;
  documentInStages_some?: InputMaybe<FactListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FactListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FactsAsc = 'facts_ASC',
  FactsDesc = 'facts_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FactListUpdateInput = {
  /** facts input for default locale (de) */
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Manage document localizations */
  localizations?: InputMaybe<FactListUpdateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FactListUpdateLocalizationDataInput = {
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FactListUpdateLocalizationInput = {
  data: FactListUpdateLocalizationDataInput;
  locale: Locale;
};

export type FactListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FactListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FactListUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FactListUpsertLocalizationInput>>;
};

export type FactListUpdateManyInlineInput = {
  /** Connect multiple existing FactList documents */
  connect?: InputMaybe<Array<FactListConnectInput>>;
  /** Create and connect multiple FactList documents */
  create?: InputMaybe<Array<FactListCreateInput>>;
  /** Delete multiple FactList documents */
  delete?: InputMaybe<Array<FactListWhereUniqueInput>>;
  /** Disconnect multiple FactList documents */
  disconnect?: InputMaybe<Array<FactListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FactList documents */
  set?: InputMaybe<Array<FactListWhereUniqueInput>>;
  /** Update multiple FactList documents */
  update?: InputMaybe<Array<FactListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FactList documents */
  upsert?: InputMaybe<Array<FactListUpsertWithNestedWhereUniqueInput>>;
};

export type FactListUpdateManyInput = {
  /** facts input for default locale (de) */
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FactListUpdateManyLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FactListUpdateManyLocalizationDataInput = {
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FactListUpdateManyLocalizationInput = {
  data: FactListUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FactListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FactListUpdateManyLocalizationInput>>;
};

export type FactListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FactListUpdateManyInput;
  /** Document search */
  where: FactListWhereInput;
};

export type FactListUpdateOneInlineInput = {
  /** Connect existing FactList document */
  connect?: InputMaybe<FactListWhereUniqueInput>;
  /** Create and connect one FactList document */
  create?: InputMaybe<FactListCreateInput>;
  /** Delete currently connected FactList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected FactList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single FactList document */
  update?: InputMaybe<FactListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FactList document */
  upsert?: InputMaybe<FactListUpsertWithNestedWhereUniqueInput>;
};

export type FactListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FactListUpdateInput;
  /** Unique document search */
  where: FactListWhereUniqueInput;
};

export type FactListUpsertInput = {
  /** Create document if it didn't exist */
  create: FactListCreateInput;
  /** Update document if it exists */
  update: FactListUpdateInput;
};

export type FactListUpsertLocalizationInput = {
  create: FactListCreateLocalizationDataInput;
  locale: Locale;
  update: FactListUpdateLocalizationDataInput;
};

export type FactListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FactListUpsertInput;
  /** Unique document search */
  where: FactListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FactListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type FactListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FactListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FactListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FactListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FactListWhereStageInput>;
  documentInStages_none?: InputMaybe<FactListWhereStageInput>;
  documentInStages_some?: InputMaybe<FactListWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  facts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  facts_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  facts_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  facts_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  facts_not?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FactListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FactListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FactListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FactListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FactListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FactList record uniquely */
export type FactListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FeaturedVehiclesRow = Entity & Node & {
  __typename?: 'FeaturedVehiclesRow';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<FeaturedVehiclesRow>;
  editorComment?: Maybe<Scalars['String']['output']>;
  /** List of FeaturedVehiclesRow versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywordList?: Maybe<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FeaturedVehiclesRow>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  titleLine1: Scalars['String']['output'];
  titleLine2?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type FeaturedVehiclesRowCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FeaturedVehiclesRowCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FeaturedVehiclesRowDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type FeaturedVehiclesRowHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type FeaturedVehiclesRowKeywordListArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FeaturedVehiclesRowLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type FeaturedVehiclesRowPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FeaturedVehiclesRowPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FeaturedVehiclesRowScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type FeaturedVehiclesRowUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FeaturedVehiclesRowUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedVehiclesRowConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FeaturedVehiclesRowWhereUniqueInput;
};

/** A connection to a list of items. */
export type FeaturedVehiclesRowConnection = {
  __typename?: 'FeaturedVehiclesRowConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FeaturedVehiclesRowEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FeaturedVehiclesRowCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  keywordList?: InputMaybe<KeywordListCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FeaturedVehiclesRowCreateLocalizationsInput>;
  /** titleLine1 input for default locale (de) */
  titleLine1: Scalars['String']['input'];
  /** titleLine2 input for default locale (de) */
  titleLine2?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeaturedVehiclesRowCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  titleLine1: Scalars['String']['input'];
  titleLine2?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FeaturedVehiclesRowCreateLocalizationInput = {
  /** Localization input */
  data: FeaturedVehiclesRowCreateLocalizationDataInput;
  locale: Locale;
};

export type FeaturedVehiclesRowCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FeaturedVehiclesRowCreateLocalizationInput>>;
};

export type FeaturedVehiclesRowCreateManyInlineInput = {
  /** Connect multiple existing FeaturedVehiclesRow documents */
  connect?: InputMaybe<Array<FeaturedVehiclesRowWhereUniqueInput>>;
  /** Create and connect multiple existing FeaturedVehiclesRow documents */
  create?: InputMaybe<Array<FeaturedVehiclesRowCreateInput>>;
};

export type FeaturedVehiclesRowCreateOneInlineInput = {
  /** Connect one existing FeaturedVehiclesRow document */
  connect?: InputMaybe<FeaturedVehiclesRowWhereUniqueInput>;
  /** Create and connect one FeaturedVehiclesRow document */
  create?: InputMaybe<FeaturedVehiclesRowCreateInput>;
};

/** An edge in a connection. */
export type FeaturedVehiclesRowEdge = {
  __typename?: 'FeaturedVehiclesRowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FeaturedVehiclesRow;
};

/** Identifies documents */
export type FeaturedVehiclesRowManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordList?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FeaturedVehiclesRowOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EditorCommentAsc = 'editorComment_ASC',
  EditorCommentDesc = 'editorComment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleLine1Asc = 'titleLine1_ASC',
  TitleLine1Desc = 'titleLine1_DESC',
  TitleLine2Asc = 'titleLine2_ASC',
  TitleLine2Desc = 'titleLine2_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FeaturedVehiclesRowUpdateInput = {
  editorComment?: InputMaybe<Scalars['String']['input']>;
  keywordList?: InputMaybe<KeywordListUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<FeaturedVehiclesRowUpdateLocalizationsInput>;
  /** titleLine1 input for default locale (de) */
  titleLine1?: InputMaybe<Scalars['String']['input']>;
  /** titleLine2 input for default locale (de) */
  titleLine2?: InputMaybe<Scalars['String']['input']>;
};

export type FeaturedVehiclesRowUpdateLocalizationDataInput = {
  titleLine1?: InputMaybe<Scalars['String']['input']>;
  titleLine2?: InputMaybe<Scalars['String']['input']>;
};

export type FeaturedVehiclesRowUpdateLocalizationInput = {
  data: FeaturedVehiclesRowUpdateLocalizationDataInput;
  locale: Locale;
};

export type FeaturedVehiclesRowUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FeaturedVehiclesRowCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FeaturedVehiclesRowUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FeaturedVehiclesRowUpsertLocalizationInput>>;
};

export type FeaturedVehiclesRowUpdateManyInlineInput = {
  /** Connect multiple existing FeaturedVehiclesRow documents */
  connect?: InputMaybe<Array<FeaturedVehiclesRowConnectInput>>;
  /** Create and connect multiple FeaturedVehiclesRow documents */
  create?: InputMaybe<Array<FeaturedVehiclesRowCreateInput>>;
  /** Delete multiple FeaturedVehiclesRow documents */
  delete?: InputMaybe<Array<FeaturedVehiclesRowWhereUniqueInput>>;
  /** Disconnect multiple FeaturedVehiclesRow documents */
  disconnect?: InputMaybe<Array<FeaturedVehiclesRowWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FeaturedVehiclesRow documents */
  set?: InputMaybe<Array<FeaturedVehiclesRowWhereUniqueInput>>;
  /** Update multiple FeaturedVehiclesRow documents */
  update?: InputMaybe<Array<FeaturedVehiclesRowUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FeaturedVehiclesRow documents */
  upsert?: InputMaybe<Array<FeaturedVehiclesRowUpsertWithNestedWhereUniqueInput>>;
};

export type FeaturedVehiclesRowUpdateManyInput = {
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FeaturedVehiclesRowUpdateManyLocalizationsInput>;
  /** titleLine1 input for default locale (de) */
  titleLine1?: InputMaybe<Scalars['String']['input']>;
  /** titleLine2 input for default locale (de) */
  titleLine2?: InputMaybe<Scalars['String']['input']>;
};

export type FeaturedVehiclesRowUpdateManyLocalizationDataInput = {
  titleLine1?: InputMaybe<Scalars['String']['input']>;
  titleLine2?: InputMaybe<Scalars['String']['input']>;
};

export type FeaturedVehiclesRowUpdateManyLocalizationInput = {
  data: FeaturedVehiclesRowUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FeaturedVehiclesRowUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FeaturedVehiclesRowUpdateManyLocalizationInput>>;
};

export type FeaturedVehiclesRowUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FeaturedVehiclesRowUpdateManyInput;
  /** Document search */
  where: FeaturedVehiclesRowWhereInput;
};

export type FeaturedVehiclesRowUpdateOneInlineInput = {
  /** Connect existing FeaturedVehiclesRow document */
  connect?: InputMaybe<FeaturedVehiclesRowWhereUniqueInput>;
  /** Create and connect one FeaturedVehiclesRow document */
  create?: InputMaybe<FeaturedVehiclesRowCreateInput>;
  /** Delete currently connected FeaturedVehiclesRow document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected FeaturedVehiclesRow document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single FeaturedVehiclesRow document */
  update?: InputMaybe<FeaturedVehiclesRowUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedVehiclesRow document */
  upsert?: InputMaybe<FeaturedVehiclesRowUpsertWithNestedWhereUniqueInput>;
};

export type FeaturedVehiclesRowUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FeaturedVehiclesRowUpdateInput;
  /** Unique document search */
  where: FeaturedVehiclesRowWhereUniqueInput;
};

export type FeaturedVehiclesRowUpsertInput = {
  /** Create document if it didn't exist */
  create: FeaturedVehiclesRowCreateInput;
  /** Update document if it exists */
  update: FeaturedVehiclesRowUpdateInput;
};

export type FeaturedVehiclesRowUpsertLocalizationInput = {
  create: FeaturedVehiclesRowCreateLocalizationDataInput;
  locale: Locale;
  update: FeaturedVehiclesRowUpdateLocalizationDataInput;
};

export type FeaturedVehiclesRowUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FeaturedVehiclesRowUpsertInput;
  /** Unique document search */
  where: FeaturedVehiclesRowWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FeaturedVehiclesRowWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type FeaturedVehiclesRowWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedVehiclesRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedVehiclesRowWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordList?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleLine1?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  titleLine1_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  titleLine1_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  titleLine1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  titleLine1_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  titleLine1_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  titleLine1_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  titleLine1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  titleLine1_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  titleLine1_starts_with?: InputMaybe<Scalars['String']['input']>;
  titleLine2?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  titleLine2_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  titleLine2_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  titleLine2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  titleLine2_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  titleLine2_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  titleLine2_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  titleLine2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  titleLine2_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  titleLine2_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FeaturedVehiclesRowWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedVehiclesRowWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedVehiclesRowWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedVehiclesRowWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FeaturedVehiclesRowWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FeaturedVehiclesRow record uniquely */
export type FeaturedVehiclesRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Diesel, Gas, Electric, etc. */
export type FuelType = Entity & Node & {
  __typename?: 'FuelType';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<FuelType>;
  group?: Maybe<FuelTypeGroup>;
  /** List of FuelType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FuelType>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Diesel, Gas, Electric, etc. */
export type FuelTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FuelTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FuelTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type FuelTypeConnection = {
  __typename?: 'FuelTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FuelTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FuelTypeCreateInput = {
  cl16idytshvpt01z19gg8ehto?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<FuelTypeGroupCreateOneInlineInput>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FuelTypeCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FuelTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FuelTypeCreateLocalizationInput = {
  /** Localization input */
  data: FuelTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FuelTypeCreateLocalizationInput>>;
};

export type FuelTypeCreateManyInlineInput = {
  /** Connect multiple existing FuelType documents */
  connect?: InputMaybe<Array<FuelTypeWhereUniqueInput>>;
  /** Create and connect multiple existing FuelType documents */
  create?: InputMaybe<Array<FuelTypeCreateInput>>;
};

export type FuelTypeCreateOneInlineInput = {
  /** Connect one existing FuelType document */
  connect?: InputMaybe<FuelTypeWhereUniqueInput>;
  /** Create and connect one FuelType document */
  create?: InputMaybe<FuelTypeCreateInput>;
};

/** An edge in a connection. */
export type FuelTypeEdge = {
  __typename?: 'FuelTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FuelType;
};

export type FuelTypeGroup = Entity & Node & {
  __typename?: 'FuelTypeGroup';
  basicFilterValue: Scalars['Boolean']['output'];
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<FuelTypeGroup>;
  fuelTypes: Array<FuelType>;
  groupId: Scalars['Int']['output'];
  /** List of FuelTypeGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FuelTypeGroup>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type FuelTypeGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FuelTypeGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FuelTypeGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type FuelTypeGroupFuelTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FuelTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FuelTypeWhereInput>;
};


export type FuelTypeGroupHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type FuelTypeGroupKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type FuelTypeGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type FuelTypeGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FuelTypeGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type FuelTypeGroupScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type FuelTypeGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type FuelTypeGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FuelTypeGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FuelTypeGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FuelTypeGroupConnection = {
  __typename?: 'FuelTypeGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FuelTypeGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FuelTypeGroupCreateInput = {
  basicFilterValue: Scalars['Boolean']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  fuelTypes?: InputMaybe<FuelTypeCreateManyInlineInput>;
  groupId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FuelTypeGroupCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FuelTypeGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FuelTypeGroupCreateLocalizationInput = {
  /** Localization input */
  data: FuelTypeGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FuelTypeGroupCreateLocalizationInput>>;
};

export type FuelTypeGroupCreateManyInlineInput = {
  /** Connect multiple existing FuelTypeGroup documents */
  connect?: InputMaybe<Array<FuelTypeGroupWhereUniqueInput>>;
  /** Create and connect multiple existing FuelTypeGroup documents */
  create?: InputMaybe<Array<FuelTypeGroupCreateInput>>;
};

export type FuelTypeGroupCreateOneInlineInput = {
  /** Connect one existing FuelTypeGroup document */
  connect?: InputMaybe<FuelTypeGroupWhereUniqueInput>;
  /** Create and connect one FuelTypeGroup document */
  create?: InputMaybe<FuelTypeGroupCreateInput>;
};

/** An edge in a connection. */
export type FuelTypeGroupEdge = {
  __typename?: 'FuelTypeGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FuelTypeGroup;
};

/** Identifies documents */
export type FuelTypeGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FuelTypeGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FuelTypeGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FuelTypeGroupWhereStageInput>;
  fuelTypes_every?: InputMaybe<FuelTypeWhereInput>;
  fuelTypes_none?: InputMaybe<FuelTypeWhereInput>;
  fuelTypes_some?: InputMaybe<FuelTypeWhereInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FuelTypeGroupOrderByInput {
  BasicFilterValueAsc = 'basicFilterValue_ASC',
  BasicFilterValueDesc = 'basicFilterValue_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GroupIdAsc = 'groupId_ASC',
  GroupIdDesc = 'groupId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FuelTypeGroupUpdateInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  fuelTypes?: InputMaybe<FuelTypeUpdateManyInlineInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<FuelTypeGroupUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeGroupUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeGroupUpdateLocalizationInput = {
  data: FuelTypeGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FuelTypeGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FuelTypeGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FuelTypeGroupUpsertLocalizationInput>>;
};

export type FuelTypeGroupUpdateManyInlineInput = {
  /** Connect multiple existing FuelTypeGroup documents */
  connect?: InputMaybe<Array<FuelTypeGroupConnectInput>>;
  /** Create and connect multiple FuelTypeGroup documents */
  create?: InputMaybe<Array<FuelTypeGroupCreateInput>>;
  /** Delete multiple FuelTypeGroup documents */
  delete?: InputMaybe<Array<FuelTypeGroupWhereUniqueInput>>;
  /** Disconnect multiple FuelTypeGroup documents */
  disconnect?: InputMaybe<Array<FuelTypeGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FuelTypeGroup documents */
  set?: InputMaybe<Array<FuelTypeGroupWhereUniqueInput>>;
  /** Update multiple FuelTypeGroup documents */
  update?: InputMaybe<Array<FuelTypeGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FuelTypeGroup documents */
  upsert?: InputMaybe<Array<FuelTypeGroupUpsertWithNestedWhereUniqueInput>>;
};

export type FuelTypeGroupUpdateManyInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FuelTypeGroupUpdateManyLocalizationsInput>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeGroupUpdateManyLocalizationDataInput = {
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeGroupUpdateManyLocalizationInput = {
  data: FuelTypeGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FuelTypeGroupUpdateManyLocalizationInput>>;
};

export type FuelTypeGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FuelTypeGroupUpdateManyInput;
  /** Document search */
  where: FuelTypeGroupWhereInput;
};

export type FuelTypeGroupUpdateOneInlineInput = {
  /** Connect existing FuelTypeGroup document */
  connect?: InputMaybe<FuelTypeGroupWhereUniqueInput>;
  /** Create and connect one FuelTypeGroup document */
  create?: InputMaybe<FuelTypeGroupCreateInput>;
  /** Delete currently connected FuelTypeGroup document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected FuelTypeGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single FuelTypeGroup document */
  update?: InputMaybe<FuelTypeGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FuelTypeGroup document */
  upsert?: InputMaybe<FuelTypeGroupUpsertWithNestedWhereUniqueInput>;
};

export type FuelTypeGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FuelTypeGroupUpdateInput;
  /** Unique document search */
  where: FuelTypeGroupWhereUniqueInput;
};

export type FuelTypeGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FuelTypeGroupCreateInput;
  /** Update document if it exists */
  update: FuelTypeGroupUpdateInput;
};

export type FuelTypeGroupUpsertLocalizationInput = {
  create: FuelTypeGroupCreateLocalizationDataInput;
  locale: Locale;
  update: FuelTypeGroupUpdateLocalizationDataInput;
};

export type FuelTypeGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FuelTypeGroupUpsertInput;
  /** Unique document search */
  where: FuelTypeGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FuelTypeGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type FuelTypeGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FuelTypeGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FuelTypeGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FuelTypeGroupWhereStageInput>;
  fuelTypes_every?: InputMaybe<FuelTypeWhereInput>;
  fuelTypes_none?: InputMaybe<FuelTypeWhereInput>;
  fuelTypes_some?: InputMaybe<FuelTypeWhereInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FuelTypeGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FuelTypeGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FuelTypeGroup record uniquely */
export type FuelTypeGroupWhereUniqueInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type FuelTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FuelTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<FuelTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<FuelTypeWhereStageInput>;
  group?: InputMaybe<FuelTypeGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FuelTypeOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type FuelTypeUpdateInput = {
  cl16idytshvpt01z19gg8ehto?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<FuelTypeGroupUpdateOneInlineInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FuelTypeUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeUpdateLocalizationInput = {
  data: FuelTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FuelTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FuelTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FuelTypeUpsertLocalizationInput>>;
};

export type FuelTypeUpdateManyInlineInput = {
  /** Connect multiple existing FuelType documents */
  connect?: InputMaybe<Array<FuelTypeConnectInput>>;
  /** Create and connect multiple FuelType documents */
  create?: InputMaybe<Array<FuelTypeCreateInput>>;
  /** Delete multiple FuelType documents */
  delete?: InputMaybe<Array<FuelTypeWhereUniqueInput>>;
  /** Disconnect multiple FuelType documents */
  disconnect?: InputMaybe<Array<FuelTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FuelType documents */
  set?: InputMaybe<Array<FuelTypeWhereUniqueInput>>;
  /** Update multiple FuelType documents */
  update?: InputMaybe<Array<FuelTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FuelType documents */
  upsert?: InputMaybe<Array<FuelTypeUpsertWithNestedWhereUniqueInput>>;
};

export type FuelTypeUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FuelTypeUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type FuelTypeUpdateManyLocalizationInput = {
  data: FuelTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FuelTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FuelTypeUpdateManyLocalizationInput>>;
};

export type FuelTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FuelTypeUpdateManyInput;
  /** Document search */
  where: FuelTypeWhereInput;
};

export type FuelTypeUpdateOneInlineInput = {
  /** Connect existing FuelType document */
  connect?: InputMaybe<FuelTypeWhereUniqueInput>;
  /** Create and connect one FuelType document */
  create?: InputMaybe<FuelTypeCreateInput>;
  /** Delete currently connected FuelType document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected FuelType document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single FuelType document */
  update?: InputMaybe<FuelTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FuelType document */
  upsert?: InputMaybe<FuelTypeUpsertWithNestedWhereUniqueInput>;
};

export type FuelTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FuelTypeUpdateInput;
  /** Unique document search */
  where: FuelTypeWhereUniqueInput;
};

export type FuelTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: FuelTypeCreateInput;
  /** Update document if it exists */
  update: FuelTypeUpdateInput;
};

export type FuelTypeUpsertLocalizationInput = {
  create: FuelTypeCreateLocalizationDataInput;
  locale: Locale;
  update: FuelTypeUpdateLocalizationDataInput;
};

export type FuelTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FuelTypeUpsertInput;
  /** Unique document search */
  where: FuelTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FuelTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type FuelTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FuelTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<FuelTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<FuelTypeWhereStageInput>;
  group?: InputMaybe<FuelTypeGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FuelTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FuelTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FuelTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FuelTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FuelTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FuelType record uniquely */
export type FuelTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Gallery = Entity & Node & {
  __typename?: 'Gallery';
  caption?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Gallery>;
  /** List of Gallery versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imagesAndVideos: Array<GalleryImagesAndVideos>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Gallery>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  wideInContent: Scalars['Boolean']['output'];
};


export type GalleryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GalleryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GalleryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type GalleryHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type GalleryImagesAndVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type GalleryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type GalleryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GalleryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GalleryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type GalleryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GalleryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type GalleryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GalleryWhereUniqueInput;
};

/** A connection to a list of items. */
export type GalleryConnection = {
  __typename?: 'GalleryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GalleryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GalleryCreateInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  imagesAndVideos?: InputMaybe<GalleryImagesAndVideosCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GalleryCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wideInContent: Scalars['Boolean']['input'];
};

export type GalleryCreateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GalleryCreateLocalizationInput = {
  /** Localization input */
  data: GalleryCreateLocalizationDataInput;
  locale: Locale;
};

export type GalleryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GalleryCreateLocalizationInput>>;
};

export type GalleryCreateManyInlineInput = {
  /** Connect multiple existing Gallery documents */
  connect?: InputMaybe<Array<GalleryWhereUniqueInput>>;
  /** Create and connect multiple existing Gallery documents */
  create?: InputMaybe<Array<GalleryCreateInput>>;
};

export type GalleryCreateOneInlineInput = {
  /** Connect one existing Gallery document */
  connect?: InputMaybe<GalleryWhereUniqueInput>;
  /** Create and connect one Gallery document */
  create?: InputMaybe<GalleryCreateInput>;
};

/** An edge in a connection. */
export type GalleryEdge = {
  __typename?: 'GalleryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Gallery;
};

export type GalleryImagesAndVideos = Image | Video;

export type GalleryImagesAndVideosConnectInput = {
  Image?: InputMaybe<ImageConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type GalleryImagesAndVideosCreateInput = {
  Image?: InputMaybe<ImageCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type GalleryImagesAndVideosCreateManyInlineInput = {
  /** Connect multiple existing GalleryImagesAndVideos documents */
  connect?: InputMaybe<Array<GalleryImagesAndVideosWhereUniqueInput>>;
  /** Create and connect multiple existing GalleryImagesAndVideos documents */
  create?: InputMaybe<Array<GalleryImagesAndVideosCreateInput>>;
};

export type GalleryImagesAndVideosCreateOneInlineInput = {
  /** Connect one existing GalleryImagesAndVideos document */
  connect?: InputMaybe<GalleryImagesAndVideosWhereUniqueInput>;
  /** Create and connect one GalleryImagesAndVideos document */
  create?: InputMaybe<GalleryImagesAndVideosCreateInput>;
};

export type GalleryImagesAndVideosUpdateInput = {
  Image?: InputMaybe<ImageUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type GalleryImagesAndVideosUpdateManyInlineInput = {
  /** Connect multiple existing GalleryImagesAndVideos documents */
  connect?: InputMaybe<Array<GalleryImagesAndVideosConnectInput>>;
  /** Create and connect multiple GalleryImagesAndVideos documents */
  create?: InputMaybe<Array<GalleryImagesAndVideosCreateInput>>;
  /** Delete multiple GalleryImagesAndVideos documents */
  delete?: InputMaybe<Array<GalleryImagesAndVideosWhereUniqueInput>>;
  /** Disconnect multiple GalleryImagesAndVideos documents */
  disconnect?: InputMaybe<Array<GalleryImagesAndVideosWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GalleryImagesAndVideos documents */
  set?: InputMaybe<Array<GalleryImagesAndVideosWhereUniqueInput>>;
  /** Update multiple GalleryImagesAndVideos documents */
  update?: InputMaybe<Array<GalleryImagesAndVideosUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GalleryImagesAndVideos documents */
  upsert?: InputMaybe<Array<GalleryImagesAndVideosUpsertWithNestedWhereUniqueInput>>;
};

export type GalleryImagesAndVideosUpdateManyWithNestedWhereInput = {
  Image?: InputMaybe<ImageUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type GalleryImagesAndVideosUpdateOneInlineInput = {
  /** Connect existing GalleryImagesAndVideos document */
  connect?: InputMaybe<GalleryImagesAndVideosWhereUniqueInput>;
  /** Create and connect one GalleryImagesAndVideos document */
  create?: InputMaybe<GalleryImagesAndVideosCreateInput>;
  /** Delete currently connected GalleryImagesAndVideos document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected GalleryImagesAndVideos document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single GalleryImagesAndVideos document */
  update?: InputMaybe<GalleryImagesAndVideosUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GalleryImagesAndVideos document */
  upsert?: InputMaybe<GalleryImagesAndVideosUpsertWithNestedWhereUniqueInput>;
};

export type GalleryImagesAndVideosUpdateWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type GalleryImagesAndVideosUpsertWithNestedWhereUniqueInput = {
  Image?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type GalleryImagesAndVideosWhereInput = {
  Image?: InputMaybe<ImageWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type GalleryImagesAndVideosWhereUniqueInput = {
  Image?: InputMaybe<ImageWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

/** Identifies documents */
export type GalleryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GalleryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GalleryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GalleryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GalleryWhereStageInput>;
  documentInStages_none?: InputMaybe<GalleryWhereStageInput>;
  documentInStages_some?: InputMaybe<GalleryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the union is empty */
  imagesAndVideos_empty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  imagesAndVideos_some?: InputMaybe<GalleryImagesAndVideosWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GalleryOrderByInput {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WideInContentAsc = 'wideInContent_ASC',
  WideInContentDesc = 'wideInContent_DESC'
}

export type GalleryUpdateInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  imagesAndVideos?: InputMaybe<GalleryImagesAndVideosUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<GalleryUpdateLocalizationsInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GalleryUpdateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type GalleryUpdateLocalizationInput = {
  data: GalleryUpdateLocalizationDataInput;
  locale: Locale;
};

export type GalleryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GalleryCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GalleryUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GalleryUpsertLocalizationInput>>;
};

export type GalleryUpdateManyInlineInput = {
  /** Connect multiple existing Gallery documents */
  connect?: InputMaybe<Array<GalleryConnectInput>>;
  /** Create and connect multiple Gallery documents */
  create?: InputMaybe<Array<GalleryCreateInput>>;
  /** Delete multiple Gallery documents */
  delete?: InputMaybe<Array<GalleryWhereUniqueInput>>;
  /** Disconnect multiple Gallery documents */
  disconnect?: InputMaybe<Array<GalleryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Gallery documents */
  set?: InputMaybe<Array<GalleryWhereUniqueInput>>;
  /** Update multiple Gallery documents */
  update?: InputMaybe<Array<GalleryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Gallery documents */
  upsert?: InputMaybe<Array<GalleryUpsertWithNestedWhereUniqueInput>>;
};

export type GalleryUpdateManyInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GalleryUpdateManyLocalizationsInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GalleryUpdateManyLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type GalleryUpdateManyLocalizationInput = {
  data: GalleryUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GalleryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GalleryUpdateManyLocalizationInput>>;
};

export type GalleryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GalleryUpdateManyInput;
  /** Document search */
  where: GalleryWhereInput;
};

export type GalleryUpdateOneInlineInput = {
  /** Connect existing Gallery document */
  connect?: InputMaybe<GalleryWhereUniqueInput>;
  /** Create and connect one Gallery document */
  create?: InputMaybe<GalleryCreateInput>;
  /** Delete currently connected Gallery document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Gallery document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Gallery document */
  update?: InputMaybe<GalleryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Gallery document */
  upsert?: InputMaybe<GalleryUpsertWithNestedWhereUniqueInput>;
};

export type GalleryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GalleryUpdateInput;
  /** Unique document search */
  where: GalleryWhereUniqueInput;
};

export type GalleryUpsertInput = {
  /** Create document if it didn't exist */
  create: GalleryCreateInput;
  /** Update document if it exists */
  update: GalleryUpdateInput;
};

export type GalleryUpsertLocalizationInput = {
  create: GalleryCreateLocalizationDataInput;
  locale: Locale;
  update: GalleryUpdateLocalizationDataInput;
};

export type GalleryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GalleryUpsertInput;
  /** Unique document search */
  where: GalleryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type GalleryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type GalleryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GalleryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GalleryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GalleryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  caption_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  caption_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  caption_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  caption_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  caption_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GalleryWhereStageInput>;
  documentInStages_none?: InputMaybe<GalleryWhereStageInput>;
  documentInStages_some?: InputMaybe<GalleryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the union is empty */
  imagesAndVideos_empty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  imagesAndVideos_some?: InputMaybe<GalleryImagesAndVideosWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type GalleryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GalleryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GalleryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GalleryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<GalleryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Gallery record uniquely */
export type GalleryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum Gender {
  Enby = 'enby',
  Female = 'female',
  Male = 'male'
}

export type GeneratedPageExtension = Entity & Node & {
  __typename?: 'GeneratedPageExtension';
  content: Array<GeneratedPageExtensionContentRichText>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<GeneratedPageExtension>;
  heroSlider?: Maybe<HeroSlider>;
  /** List of GeneratedPageExtension versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<GeneratedPageExtension>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  titleAndLead: TitleAndLead;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type GeneratedPageExtensionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GeneratedPageExtensionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GeneratedPageExtensionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type GeneratedPageExtensionHeroSliderArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GeneratedPageExtensionHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type GeneratedPageExtensionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type GeneratedPageExtensionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GeneratedPageExtensionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GeneratedPageExtensionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type GeneratedPageExtensionTitleAndLeadArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type GeneratedPageExtensionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type GeneratedPageExtensionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type GeneratedPageExtensionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GeneratedPageExtensionWhereUniqueInput;
};

/** A connection to a list of items. */
export type GeneratedPageExtensionConnection = {
  __typename?: 'GeneratedPageExtensionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GeneratedPageExtensionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GeneratedPageExtensionContentRichText = {
  __typename?: 'GeneratedPageExtensionContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String']['output'];
  json: Scalars['RichTextAST']['output'];
  /** Returns Markdown representation */
  markdown: Scalars['String']['output'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output'];
  references: Array<GeneratedPageExtensionContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output'];
};


export type GeneratedPageExtensionContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type GeneratedPageExtensionContentRichTextEmbeddedTypes = AccordionChapterList | AccordionSmlList | Button | ButtonRow | Citatum | FactList | FeaturedVehiclesRow | Gallery | Image | ImageTextComboList | QuestionAndAnswerList | SpecialBox | Video;

export type GeneratedPageExtensionCreateInput = {
  cm0l2vkb402yq07unfuhi2ovb?: InputMaybe<BrandCreateManyInlineInput>;
  cm0l2wd4g031007un08b62php?: InputMaybe<BrandCreateManyInlineInput>;
  cm0l2xad1033a07unamc783ae?: InputMaybe<BrandCreateManyInlineInput>;
  cm0l2zroo03bm07un3vyochq6?: InputMaybe<BrandCreateManyInlineInput>;
  cm0l317gs03dy07un506l1ggv?: InputMaybe<ModelCreateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  heroSlider?: InputMaybe<HeroSliderCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GeneratedPageExtensionCreateLocalizationsInput>;
  titleAndLead: TitleAndLeadCreateOneInlineInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GeneratedPageExtensionCreateLocalizationDataInput = {
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GeneratedPageExtensionCreateLocalizationInput = {
  /** Localization input */
  data: GeneratedPageExtensionCreateLocalizationDataInput;
  locale: Locale;
};

export type GeneratedPageExtensionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GeneratedPageExtensionCreateLocalizationInput>>;
};

export type GeneratedPageExtensionCreateManyInlineInput = {
  /** Connect multiple existing GeneratedPageExtension documents */
  connect?: InputMaybe<Array<GeneratedPageExtensionWhereUniqueInput>>;
  /** Create and connect multiple existing GeneratedPageExtension documents */
  create?: InputMaybe<Array<GeneratedPageExtensionCreateInput>>;
};

export type GeneratedPageExtensionCreateOneInlineInput = {
  /** Connect one existing GeneratedPageExtension document */
  connect?: InputMaybe<GeneratedPageExtensionWhereUniqueInput>;
  /** Create and connect one GeneratedPageExtension document */
  create?: InputMaybe<GeneratedPageExtensionCreateInput>;
};

/** An edge in a connection. */
export type GeneratedPageExtensionEdge = {
  __typename?: 'GeneratedPageExtensionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GeneratedPageExtension;
};

/** Identifies documents */
export type GeneratedPageExtensionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  documentInStages_none?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  documentInStages_some?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  heroSlider?: InputMaybe<HeroSliderWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GeneratedPageExtensionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type GeneratedPageExtensionUpdateInput = {
  cm0l2vkb402yq07unfuhi2ovb?: InputMaybe<BrandUpdateManyInlineInput>;
  cm0l2wd4g031007un08b62php?: InputMaybe<BrandUpdateManyInlineInput>;
  cm0l2xad1033a07unamc783ae?: InputMaybe<BrandUpdateManyInlineInput>;
  cm0l2zroo03bm07un3vyochq6?: InputMaybe<BrandUpdateManyInlineInput>;
  cm0l317gs03dy07un506l1ggv?: InputMaybe<ModelUpdateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  heroSlider?: InputMaybe<HeroSliderUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<GeneratedPageExtensionUpdateLocalizationsInput>;
  titleAndLead?: InputMaybe<TitleAndLeadUpdateOneInlineInput>;
};

export type GeneratedPageExtensionUpdateLocalizationDataInput = {
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
};

export type GeneratedPageExtensionUpdateLocalizationInput = {
  data: GeneratedPageExtensionUpdateLocalizationDataInput;
  locale: Locale;
};

export type GeneratedPageExtensionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GeneratedPageExtensionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<GeneratedPageExtensionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<GeneratedPageExtensionUpsertLocalizationInput>>;
};

export type GeneratedPageExtensionUpdateManyInlineInput = {
  /** Connect multiple existing GeneratedPageExtension documents */
  connect?: InputMaybe<Array<GeneratedPageExtensionConnectInput>>;
  /** Create and connect multiple GeneratedPageExtension documents */
  create?: InputMaybe<Array<GeneratedPageExtensionCreateInput>>;
  /** Delete multiple GeneratedPageExtension documents */
  delete?: InputMaybe<Array<GeneratedPageExtensionWhereUniqueInput>>;
  /** Disconnect multiple GeneratedPageExtension documents */
  disconnect?: InputMaybe<Array<GeneratedPageExtensionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GeneratedPageExtension documents */
  set?: InputMaybe<Array<GeneratedPageExtensionWhereUniqueInput>>;
  /** Update multiple GeneratedPageExtension documents */
  update?: InputMaybe<Array<GeneratedPageExtensionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple GeneratedPageExtension documents */
  upsert?: InputMaybe<Array<GeneratedPageExtensionUpsertWithNestedWhereUniqueInput>>;
};

export type GeneratedPageExtensionUpdateManyInput = {
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<GeneratedPageExtensionUpdateManyLocalizationsInput>;
};

export type GeneratedPageExtensionUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
};

export type GeneratedPageExtensionUpdateManyLocalizationInput = {
  data: GeneratedPageExtensionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type GeneratedPageExtensionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GeneratedPageExtensionUpdateManyLocalizationInput>>;
};

export type GeneratedPageExtensionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GeneratedPageExtensionUpdateManyInput;
  /** Document search */
  where: GeneratedPageExtensionWhereInput;
};

export type GeneratedPageExtensionUpdateOneInlineInput = {
  /** Connect existing GeneratedPageExtension document */
  connect?: InputMaybe<GeneratedPageExtensionWhereUniqueInput>;
  /** Create and connect one GeneratedPageExtension document */
  create?: InputMaybe<GeneratedPageExtensionCreateInput>;
  /** Delete currently connected GeneratedPageExtension document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected GeneratedPageExtension document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single GeneratedPageExtension document */
  update?: InputMaybe<GeneratedPageExtensionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GeneratedPageExtension document */
  upsert?: InputMaybe<GeneratedPageExtensionUpsertWithNestedWhereUniqueInput>;
};

export type GeneratedPageExtensionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GeneratedPageExtensionUpdateInput;
  /** Unique document search */
  where: GeneratedPageExtensionWhereUniqueInput;
};

export type GeneratedPageExtensionUpsertInput = {
  /** Create document if it didn't exist */
  create: GeneratedPageExtensionCreateInput;
  /** Update document if it exists */
  update: GeneratedPageExtensionUpdateInput;
};

export type GeneratedPageExtensionUpsertLocalizationInput = {
  create: GeneratedPageExtensionCreateLocalizationDataInput;
  locale: Locale;
  update: GeneratedPageExtensionUpdateLocalizationDataInput;
};

export type GeneratedPageExtensionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GeneratedPageExtensionUpsertInput;
  /** Unique document search */
  where: GeneratedPageExtensionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type GeneratedPageExtensionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type GeneratedPageExtensionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneratedPageExtensionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  documentInStages_none?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  documentInStages_some?: InputMaybe<GeneratedPageExtensionWhereStageInput>;
  heroSlider?: InputMaybe<HeroSliderWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type GeneratedPageExtensionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GeneratedPageExtensionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GeneratedPageExtensionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GeneratedPageExtensionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<GeneratedPageExtensionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References GeneratedPageExtension record uniquely */
export type GeneratedPageExtensionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type HeroSlide = Entity & {
  __typename?: 'HeroSlide';
  ctaLabel?: Maybe<Scalars['String']['output']>;
  ctaLink?: Maybe<Link>;
  displayDuration: Scalars['Int']['output'];
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageDesktop?: Maybe<Image>;
  imageMobile?: Maybe<Image>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<HeroSlide>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']['output']>;
};


export type HeroSlideCtaLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type HeroSlideImageDesktopArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type HeroSlideImageMobileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type HeroSlideLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type HeroSlideConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: HeroSlideWhereUniqueInput;
};

/** A connection to a list of items. */
export type HeroSlideConnection = {
  __typename?: 'HeroSlideConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<HeroSlideEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HeroSlideCreateInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkCreateOneInlineInput>;
  displayDuration: Scalars['Int']['input'];
  imageDesktop?: InputMaybe<ImageCreateOneInlineInput>;
  imageMobile?: InputMaybe<ImageCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeroSlideCreateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideCreateLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideCreateLocalizationInput = {
  /** Localization input */
  data: HeroSlideCreateLocalizationDataInput;
  locale: Locale;
};

export type HeroSlideCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeroSlideCreateLocalizationInput>>;
};

export type HeroSlideCreateManyInlineInput = {
  /** Create and connect multiple existing HeroSlide documents */
  create?: InputMaybe<Array<HeroSlideCreateInput>>;
};

export type HeroSlideCreateOneInlineInput = {
  /** Create and connect one HeroSlide document */
  create?: InputMaybe<HeroSlideCreateInput>;
};

export type HeroSlideCreateWithPositionInput = {
  /** Document to create */
  data: HeroSlideCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type HeroSlideEdge = {
  __typename?: 'HeroSlideEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HeroSlide;
};

/** Identifies documents */
export type HeroSlideManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  displayDuration_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  displayDuration_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  displayDuration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  displayDuration_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  displayDuration_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  displayDuration_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  displayDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
};

export enum HeroSlideOrderByInput {
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  DisplayDurationAsc = 'displayDuration_ASC',
  DisplayDurationDesc = 'displayDuration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type HeroSlideParent = HeroSlider;

export type HeroSlideParentConnectInput = {
  HeroSlider?: InputMaybe<HeroSliderConnectInput>;
};

export type HeroSlideParentCreateInput = {
  HeroSlider?: InputMaybe<HeroSliderCreateInput>;
};

export type HeroSlideParentCreateManyInlineInput = {
  /** Connect multiple existing HeroSlideParent documents */
  connect?: InputMaybe<Array<HeroSlideParentWhereUniqueInput>>;
  /** Create and connect multiple existing HeroSlideParent documents */
  create?: InputMaybe<Array<HeroSlideParentCreateInput>>;
};

export type HeroSlideParentCreateOneInlineInput = {
  /** Connect one existing HeroSlideParent document */
  connect?: InputMaybe<HeroSlideParentWhereUniqueInput>;
  /** Create and connect one HeroSlideParent document */
  create?: InputMaybe<HeroSlideParentCreateInput>;
};

export type HeroSlideParentUpdateInput = {
  HeroSlider?: InputMaybe<HeroSliderUpdateInput>;
};

export type HeroSlideParentUpdateManyInlineInput = {
  /** Connect multiple existing HeroSlideParent documents */
  connect?: InputMaybe<Array<HeroSlideParentConnectInput>>;
  /** Create and connect multiple HeroSlideParent documents */
  create?: InputMaybe<Array<HeroSlideParentCreateInput>>;
  /** Delete multiple HeroSlideParent documents */
  delete?: InputMaybe<Array<HeroSlideParentWhereUniqueInput>>;
  /** Disconnect multiple HeroSlideParent documents */
  disconnect?: InputMaybe<Array<HeroSlideParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing HeroSlideParent documents */
  set?: InputMaybe<Array<HeroSlideParentWhereUniqueInput>>;
  /** Update multiple HeroSlideParent documents */
  update?: InputMaybe<Array<HeroSlideParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple HeroSlideParent documents */
  upsert?: InputMaybe<Array<HeroSlideParentUpsertWithNestedWhereUniqueInput>>;
};

export type HeroSlideParentUpdateManyWithNestedWhereInput = {
  HeroSlider?: InputMaybe<HeroSliderUpdateManyWithNestedWhereInput>;
};

export type HeroSlideParentUpdateOneInlineInput = {
  /** Connect existing HeroSlideParent document */
  connect?: InputMaybe<HeroSlideParentWhereUniqueInput>;
  /** Create and connect one HeroSlideParent document */
  create?: InputMaybe<HeroSlideParentCreateInput>;
  /** Delete currently connected HeroSlideParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected HeroSlideParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single HeroSlideParent document */
  update?: InputMaybe<HeroSlideParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HeroSlideParent document */
  upsert?: InputMaybe<HeroSlideParentUpsertWithNestedWhereUniqueInput>;
};

export type HeroSlideParentUpdateWithNestedWhereUniqueInput = {
  HeroSlider?: InputMaybe<HeroSliderUpdateWithNestedWhereUniqueInput>;
};

export type HeroSlideParentUpsertWithNestedWhereUniqueInput = {
  HeroSlider?: InputMaybe<HeroSliderUpsertWithNestedWhereUniqueInput>;
};

export type HeroSlideParentWhereInput = {
  HeroSlider?: InputMaybe<HeroSliderWhereInput>;
};

export type HeroSlideParentWhereUniqueInput = {
  HeroSlider?: InputMaybe<HeroSliderWhereUniqueInput>;
};

export type HeroSlideUpdateInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkUpdateOneInlineInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  imageDesktop?: InputMaybe<ImageUpdateOneInlineInput>;
  imageMobile?: InputMaybe<ImageUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<HeroSlideUpdateLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideUpdateLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideUpdateLocalizationInput = {
  data: HeroSlideUpdateLocalizationDataInput;
  locale: Locale;
};

export type HeroSlideUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeroSlideCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<HeroSlideUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<HeroSlideUpsertLocalizationInput>>;
};

export type HeroSlideUpdateManyInlineInput = {
  /** Create and connect multiple HeroSlide component instances */
  create?: InputMaybe<Array<HeroSlideCreateWithPositionInput>>;
  /** Delete multiple HeroSlide documents */
  delete?: InputMaybe<Array<HeroSlideWhereUniqueInput>>;
  /** Update multiple HeroSlide component instances */
  update?: InputMaybe<Array<HeroSlideUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple HeroSlide component instances */
  upsert?: InputMaybe<Array<HeroSlideUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type HeroSlideUpdateManyInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<HeroSlideUpdateManyLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideUpdateManyLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSlideUpdateManyLocalizationInput = {
  data: HeroSlideUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type HeroSlideUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HeroSlideUpdateManyLocalizationInput>>;
};

export type HeroSlideUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeroSlideUpdateManyInput;
  /** Document search */
  where: HeroSlideWhereInput;
};

export type HeroSlideUpdateOneInlineInput = {
  /** Create and connect one HeroSlide document */
  create?: InputMaybe<HeroSlideCreateInput>;
  /** Delete currently connected HeroSlide document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single HeroSlide document */
  update?: InputMaybe<HeroSlideUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HeroSlide document */
  upsert?: InputMaybe<HeroSlideUpsertWithNestedWhereUniqueInput>;
};

export type HeroSlideUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HeroSlideUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: HeroSlideWhereUniqueInput;
};

export type HeroSlideUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeroSlideUpdateInput;
  /** Unique document search */
  where: HeroSlideWhereUniqueInput;
};

export type HeroSlideUpsertInput = {
  /** Create document if it didn't exist */
  create: HeroSlideCreateInput;
  /** Update document if it exists */
  update: HeroSlideUpdateInput;
};

export type HeroSlideUpsertLocalizationInput = {
  create: HeroSlideCreateLocalizationDataInput;
  locale: Locale;
  update: HeroSlideUpdateLocalizationDataInput;
};

export type HeroSlideUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HeroSlideUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: HeroSlideWhereUniqueInput;
};

export type HeroSlideUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeroSlideUpsertInput;
  /** Unique document search */
  where: HeroSlideWhereUniqueInput;
};

/** Identifies documents */
export type HeroSlideWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeroSlideWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  ctaLabel?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  ctaLabel_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  ctaLabel_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  ctaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ctaLabel_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  ctaLabel_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  ctaLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  ctaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  ctaLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  ctaLabel_starts_with?: InputMaybe<Scalars['String']['input']>;
  ctaLink?: InputMaybe<LinkWhereInput>;
  displayDuration?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  displayDuration_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  displayDuration_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  displayDuration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  displayDuration_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  displayDuration_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  displayDuration_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  displayDuration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageDesktop?: InputMaybe<ImageWhereInput>;
  imageMobile?: InputMaybe<ImageWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References HeroSlide record uniquely */
export type HeroSlideWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type HeroSlider = Entity & Node & {
  __typename?: 'HeroSlider';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<HeroSlider>;
  /** Only in CMS. Content is not rendered. */
  editorComment?: Maybe<Scalars['String']['output']>;
  generatedPageExtensions: Array<GeneratedPageExtension>;
  heroSlides: Array<HeroSlide>;
  /** List of HeroSlider versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  pages: Array<Page>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type HeroSliderCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type HeroSliderDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type HeroSliderGeneratedPageExtensionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<GeneratedPageExtensionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneratedPageExtensionWhereInput>;
};


export type HeroSliderHeroSlidesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<HeroSlideOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HeroSlideWhereInput>;
};


export type HeroSliderHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type HeroSliderPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type HeroSliderPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type HeroSliderScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type HeroSliderUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type HeroSliderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: HeroSliderWhereUniqueInput;
};

/** A connection to a list of items. */
export type HeroSliderConnection = {
  __typename?: 'HeroSliderConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<HeroSliderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type HeroSliderCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  generatedPageExtensions?: InputMaybe<GeneratedPageExtensionCreateManyInlineInput>;
  heroSlides?: InputMaybe<HeroSlideCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeroSliderCreateLocalizationsInput>;
  pages?: InputMaybe<PageCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HeroSliderCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HeroSliderCreateLocalizationInput = {
  /** Localization input */
  data: HeroSliderCreateLocalizationDataInput;
  locale: Locale;
};

export type HeroSliderCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeroSliderCreateLocalizationInput>>;
};

export type HeroSliderCreateManyInlineInput = {
  /** Connect multiple existing HeroSlider documents */
  connect?: InputMaybe<Array<HeroSliderWhereUniqueInput>>;
  /** Create and connect multiple existing HeroSlider documents */
  create?: InputMaybe<Array<HeroSliderCreateInput>>;
};

export type HeroSliderCreateOneInlineInput = {
  /** Connect one existing HeroSlider document */
  connect?: InputMaybe<HeroSliderWhereUniqueInput>;
  /** Create and connect one HeroSlider document */
  create?: InputMaybe<HeroSliderCreateInput>;
};

/** An edge in a connection. */
export type HeroSliderEdge = {
  __typename?: 'HeroSliderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HeroSlider;
};

/** Identifies documents */
export type HeroSliderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<HeroSliderWhereStageInput>;
  documentInStages_none?: InputMaybe<HeroSliderWhereStageInput>;
  documentInStages_some?: InputMaybe<HeroSliderWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  generatedPageExtensions_every?: InputMaybe<GeneratedPageExtensionWhereInput>;
  generatedPageExtensions_none?: InputMaybe<GeneratedPageExtensionWhereInput>;
  generatedPageExtensions_some?: InputMaybe<GeneratedPageExtensionWhereInput>;
  heroSlides_every?: InputMaybe<HeroSlideWhereInput>;
  heroSlides_none?: InputMaybe<HeroSlideWhereInput>;
  heroSlides_some?: InputMaybe<HeroSlideWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum HeroSliderOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EditorCommentAsc = 'editorComment_ASC',
  EditorCommentDesc = 'editorComment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type HeroSliderUpdateInput = {
  editorComment?: InputMaybe<Scalars['String']['input']>;
  generatedPageExtensions?: InputMaybe<GeneratedPageExtensionUpdateManyInlineInput>;
  heroSlides?: InputMaybe<HeroSlideUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<HeroSliderUpdateLocalizationsInput>;
  pages?: InputMaybe<PageUpdateManyInlineInput>;
};

export type HeroSliderUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeroSliderCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type HeroSliderUpdateManyInlineInput = {
  /** Connect multiple existing HeroSlider documents */
  connect?: InputMaybe<Array<HeroSliderConnectInput>>;
  /** Create and connect multiple HeroSlider documents */
  create?: InputMaybe<Array<HeroSliderCreateInput>>;
  /** Delete multiple HeroSlider documents */
  delete?: InputMaybe<Array<HeroSliderWhereUniqueInput>>;
  /** Disconnect multiple HeroSlider documents */
  disconnect?: InputMaybe<Array<HeroSliderWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing HeroSlider documents */
  set?: InputMaybe<Array<HeroSliderWhereUniqueInput>>;
  /** Update multiple HeroSlider documents */
  update?: InputMaybe<Array<HeroSliderUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple HeroSlider documents */
  upsert?: InputMaybe<Array<HeroSliderUpsertWithNestedWhereUniqueInput>>;
};

export type HeroSliderUpdateManyInput = {
  editorComment?: InputMaybe<Scalars['String']['input']>;
};

export type HeroSliderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeroSliderUpdateManyInput;
  /** Document search */
  where: HeroSliderWhereInput;
};

export type HeroSliderUpdateOneInlineInput = {
  /** Connect existing HeroSlider document */
  connect?: InputMaybe<HeroSliderWhereUniqueInput>;
  /** Create and connect one HeroSlider document */
  create?: InputMaybe<HeroSliderCreateInput>;
  /** Delete currently connected HeroSlider document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected HeroSlider document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single HeroSlider document */
  update?: InputMaybe<HeroSliderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single HeroSlider document */
  upsert?: InputMaybe<HeroSliderUpsertWithNestedWhereUniqueInput>;
};

export type HeroSliderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeroSliderUpdateInput;
  /** Unique document search */
  where: HeroSliderWhereUniqueInput;
};

export type HeroSliderUpsertInput = {
  /** Create document if it didn't exist */
  create: HeroSliderCreateInput;
  /** Update document if it exists */
  update: HeroSliderUpdateInput;
};

export type HeroSliderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeroSliderUpsertInput;
  /** Unique document search */
  where: HeroSliderWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type HeroSliderWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type HeroSliderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeroSliderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<HeroSliderWhereStageInput>;
  documentInStages_none?: InputMaybe<HeroSliderWhereStageInput>;
  documentInStages_some?: InputMaybe<HeroSliderWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  generatedPageExtensions_every?: InputMaybe<GeneratedPageExtensionWhereInput>;
  generatedPageExtensions_none?: InputMaybe<GeneratedPageExtensionWhereInput>;
  generatedPageExtensions_some?: InputMaybe<GeneratedPageExtensionWhereInput>;
  heroSlides_every?: InputMaybe<HeroSlideWhereInput>;
  heroSlides_none?: InputMaybe<HeroSlideWhereInput>;
  heroSlides_some?: InputMaybe<HeroSlideWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HeroSliderWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeroSliderWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeroSliderWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeroSliderWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HeroSliderWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References HeroSlider record uniquely */
export type HeroSliderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum Icon {
  Mail = 'mail',
  OpenExternal = 'open_external',
  Phone = 'phone'
}

export type Image = Entity & Node & {
  __typename?: 'Image';
  /** Text fot the alt attribute is useful for accessibility and SEO */
  altText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Image>;
  /** Only in CMS. Content is not rendered. */
  editorComment?: Maybe<Scalars['String']['output']>;
  galleries: Array<Gallery>;
  /** List of Image versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Image>;
  media?: Maybe<ImagemediaUnion>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  wideInContent: Scalars['Boolean']['output'];
};


export type ImageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ImageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ImageGalleriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GalleryWhereInput>;
};


export type ImageHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ImageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ImageMediaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ImagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ImageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ImageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ImageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageConnection = {
  __typename?: 'ImageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageCreateInput = {
  /** altText input for default locale (de) */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  cl1d4fb9a0cuz01xf410ye86o?: InputMaybe<ArticleCreateManyInlineInput>;
  cl9y0udkr16j101rthys48ouc?: InputMaybe<ContentConfigurationCreateManyInlineInput>;
  clkv6zz8t02o601unaita2gf2?: InputMaybe<BannerSlideCreateManyInlineInput>;
  clkwao3dv010301t4ac3k476w?: InputMaybe<BannerSlideCreateManyInlineInput>;
  cll0h9nsr1kdk01uj6zcz220n?: InputMaybe<ContentConfigurationCreateManyInlineInput>;
  cll0haawu1j6b01uj0j5v473d?: InputMaybe<ContentConfigurationCreateManyInlineInput>;
  cln0373ra07qg01ug8brd1c7l?: InputMaybe<ImageTextComboCreateManyInlineInput>;
  cltsas5sx009n06vy3b2m2aym?: InputMaybe<AdCreateManyInlineInput>;
  cltsat0m900aq06vy421l47vv?: InputMaybe<AdCreateManyInlineInput>;
  clu6u8wl201hy06ut4oe9h9be?: InputMaybe<PageCreateManyInlineInput>;
  clu6u87eg01gu06utd8sn7rx9?: InputMaybe<PageCreateManyInlineInput>;
  clz16ydjy006r07tbaq9a99va?: InputMaybe<ContentConfigurationCreateManyInlineInput>;
  cm0kwzncp02d207w2afx79t3l?: InputMaybe<HeroSlideCreateManyInlineInput>;
  cm0kx07cs02eu07w2em4d3nld?: InputMaybe<HeroSlideCreateManyInlineInput>;
  cm0kxlz5103dl07w2cu9c1j4x?: InputMaybe<TitleAndLeadCreateManyInlineInput>;
  cm0l32ewk03gb07un526hhpx5?: InputMaybe<ModelCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  galleries?: InputMaybe<GalleryCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ImageCreateLocalizationsInput>;
  media?: InputMaybe<ImagemediaUnionCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wideInContent: Scalars['Boolean']['input'];
};

export type ImageCreateLocalizationDataInput = {
  altText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImageCreateLocalizationInput = {
  /** Localization input */
  data: ImageCreateLocalizationDataInput;
  locale: Locale;
};

export type ImageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ImageCreateLocalizationInput>>;
};

export type ImageCreateManyInlineInput = {
  /** Connect multiple existing Image documents */
  connect?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Create and connect multiple existing Image documents */
  create?: InputMaybe<Array<ImageCreateInput>>;
};

export type ImageCreateOneInlineInput = {
  /** Connect one existing Image document */
  connect?: InputMaybe<ImageWhereUniqueInput>;
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
};

/** An edge in a connection. */
export type ImageEdge = {
  __typename?: 'ImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Image;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale'
}

/** Identifies documents */
export type ImageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ImageWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  galleries_every?: InputMaybe<GalleryWhereInput>;
  galleries_none?: InputMaybe<GalleryWhereInput>;
  galleries_some?: InputMaybe<GalleryWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  media?: InputMaybe<ImagemediaUnionWhereInput>;
  /** All values in which the union is empty. */
  media_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ImageOrderByInput {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EditorCommentAsc = 'editorComment_ASC',
  EditorCommentDesc = 'editorComment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WideInContentAsc = 'wideInContent_ASC',
  WideInContentDesc = 'wideInContent_DESC'
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageTextCombo = Entity & {
  __typename?: 'ImageTextCombo';
  buttonRow?: Maybe<ButtonRow>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ImageTextCombo>;
  /** System stage field */
  stage: Stage;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};


export type ImageTextComboButtonRowArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageTextComboImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageTextComboLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type ImageTextComboConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageTextComboWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageTextComboConnection = {
  __typename?: 'ImageTextComboConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageTextComboEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageTextComboCreateInput = {
  buttonRow?: InputMaybe<ButtonRowCreateOneInlineInput>;
  image?: InputMaybe<ImageCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ImageTextComboCreateLocalizationsInput>;
  /** text input for default locale (de) */
  text: Scalars['String']['input'];
  /** title input for default locale (de) */
  title: Scalars['String']['input'];
};

export type ImageTextComboCreateLocalizationDataInput = {
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ImageTextComboCreateLocalizationInput = {
  /** Localization input */
  data: ImageTextComboCreateLocalizationDataInput;
  locale: Locale;
};

export type ImageTextComboCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ImageTextComboCreateLocalizationInput>>;
};

export type ImageTextComboCreateManyInlineInput = {
  /** Create and connect multiple existing ImageTextCombo documents */
  create?: InputMaybe<Array<ImageTextComboCreateInput>>;
};

export type ImageTextComboCreateOneInlineInput = {
  /** Create and connect one ImageTextCombo document */
  create?: InputMaybe<ImageTextComboCreateInput>;
};

export type ImageTextComboCreateWithPositionInput = {
  /** Document to create */
  data: ImageTextComboCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ImageTextComboEdge = {
  __typename?: 'ImageTextComboEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ImageTextCombo;
};

export type ImageTextComboList = Entity & Node & {
  __typename?: 'ImageTextComboList';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ImageTextComboList>;
  firstImageLeft: Scalars['Boolean']['output'];
  /** List of ImageTextComboList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageTextCombos: Array<ImageTextCombo>;
  name?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  titleAndLead: TitleAndLead;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type ImageTextComboListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageTextComboListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ImageTextComboListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ImageTextComboListImageTextCombosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ImageTextComboOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageTextComboWhereInput>;
};


export type ImageTextComboListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageTextComboListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ImageTextComboListTitleAndLeadArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ImageTextComboListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ImageTextComboListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageTextComboListWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageTextComboListConnection = {
  __typename?: 'ImageTextComboListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageTextComboListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageTextComboListCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstImageLeft: Scalars['Boolean']['input'];
  imageTextCombos?: InputMaybe<ImageTextComboCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ImageTextComboListCreateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  titleAndLead: TitleAndLeadCreateOneInlineInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImageTextComboListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ImageTextComboListCreateLocalizationInput = {
  /** Localization input */
  data: ImageTextComboListCreateLocalizationDataInput;
  locale: Locale;
};

export type ImageTextComboListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ImageTextComboListCreateLocalizationInput>>;
};

export type ImageTextComboListCreateManyInlineInput = {
  /** Connect multiple existing ImageTextComboList documents */
  connect?: InputMaybe<Array<ImageTextComboListWhereUniqueInput>>;
  /** Create and connect multiple existing ImageTextComboList documents */
  create?: InputMaybe<Array<ImageTextComboListCreateInput>>;
};

export type ImageTextComboListCreateOneInlineInput = {
  /** Connect one existing ImageTextComboList document */
  connect?: InputMaybe<ImageTextComboListWhereUniqueInput>;
  /** Create and connect one ImageTextComboList document */
  create?: InputMaybe<ImageTextComboListCreateInput>;
};

/** An edge in a connection. */
export type ImageTextComboListEdge = {
  __typename?: 'ImageTextComboListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ImageTextComboList;
};

/** Identifies documents */
export type ImageTextComboListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ImageTextComboListWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageTextComboListWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageTextComboListWhereStageInput>;
  firstImageLeft?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  firstImageLeft_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageTextCombos_every?: InputMaybe<ImageTextComboWhereInput>;
  imageTextCombos_none?: InputMaybe<ImageTextComboWhereInput>;
  imageTextCombos_some?: InputMaybe<ImageTextComboWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ImageTextComboListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FirstImageLeftAsc = 'firstImageLeft_ASC',
  FirstImageLeftDesc = 'firstImageLeft_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ImageTextComboListUpdateInput = {
  firstImageLeft?: InputMaybe<Scalars['Boolean']['input']>;
  imageTextCombos?: InputMaybe<ImageTextComboUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ImageTextComboListUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  titleAndLead?: InputMaybe<TitleAndLeadUpdateOneInlineInput>;
};

export type ImageTextComboListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ImageTextComboListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type ImageTextComboListUpdateManyInlineInput = {
  /** Connect multiple existing ImageTextComboList documents */
  connect?: InputMaybe<Array<ImageTextComboListConnectInput>>;
  /** Create and connect multiple ImageTextComboList documents */
  create?: InputMaybe<Array<ImageTextComboListCreateInput>>;
  /** Delete multiple ImageTextComboList documents */
  delete?: InputMaybe<Array<ImageTextComboListWhereUniqueInput>>;
  /** Disconnect multiple ImageTextComboList documents */
  disconnect?: InputMaybe<Array<ImageTextComboListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ImageTextComboList documents */
  set?: InputMaybe<Array<ImageTextComboListWhereUniqueInput>>;
  /** Update multiple ImageTextComboList documents */
  update?: InputMaybe<Array<ImageTextComboListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ImageTextComboList documents */
  upsert?: InputMaybe<Array<ImageTextComboListUpsertWithNestedWhereUniqueInput>>;
};

export type ImageTextComboListUpdateManyInput = {
  firstImageLeft?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ImageTextComboListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageTextComboListUpdateManyInput;
  /** Document search */
  where: ImageTextComboListWhereInput;
};

export type ImageTextComboListUpdateOneInlineInput = {
  /** Connect existing ImageTextComboList document */
  connect?: InputMaybe<ImageTextComboListWhereUniqueInput>;
  /** Create and connect one ImageTextComboList document */
  create?: InputMaybe<ImageTextComboListCreateInput>;
  /** Delete currently connected ImageTextComboList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ImageTextComboList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ImageTextComboList document */
  update?: InputMaybe<ImageTextComboListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageTextComboList document */
  upsert?: InputMaybe<ImageTextComboListUpsertWithNestedWhereUniqueInput>;
};

export type ImageTextComboListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageTextComboListUpdateInput;
  /** Unique document search */
  where: ImageTextComboListWhereUniqueInput;
};

export type ImageTextComboListUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageTextComboListCreateInput;
  /** Update document if it exists */
  update: ImageTextComboListUpdateInput;
};

export type ImageTextComboListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageTextComboListUpsertInput;
  /** Unique document search */
  where: ImageTextComboListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ImageTextComboListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ImageTextComboListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageTextComboListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ImageTextComboListWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageTextComboListWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageTextComboListWhereStageInput>;
  firstImageLeft?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  firstImageLeft_not?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageTextCombos_every?: InputMaybe<ImageTextComboWhereInput>;
  imageTextCombos_none?: InputMaybe<ImageTextComboWhereInput>;
  imageTextCombos_some?: InputMaybe<ImageTextComboWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ImageTextComboListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageTextComboListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageTextComboListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageTextComboListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ImageTextComboListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ImageTextComboList record uniquely */
export type ImageTextComboListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type ImageTextComboManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ImageWhereInput>;
};

export enum ImageTextComboOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

export type ImageTextComboParent = ImageTextComboList;

export type ImageTextComboParentConnectInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListConnectInput>;
};

export type ImageTextComboParentCreateInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListCreateInput>;
};

export type ImageTextComboParentCreateManyInlineInput = {
  /** Connect multiple existing ImageTextComboParent documents */
  connect?: InputMaybe<Array<ImageTextComboParentWhereUniqueInput>>;
  /** Create and connect multiple existing ImageTextComboParent documents */
  create?: InputMaybe<Array<ImageTextComboParentCreateInput>>;
};

export type ImageTextComboParentCreateOneInlineInput = {
  /** Connect one existing ImageTextComboParent document */
  connect?: InputMaybe<ImageTextComboParentWhereUniqueInput>;
  /** Create and connect one ImageTextComboParent document */
  create?: InputMaybe<ImageTextComboParentCreateInput>;
};

export type ImageTextComboParentUpdateInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateInput>;
};

export type ImageTextComboParentUpdateManyInlineInput = {
  /** Connect multiple existing ImageTextComboParent documents */
  connect?: InputMaybe<Array<ImageTextComboParentConnectInput>>;
  /** Create and connect multiple ImageTextComboParent documents */
  create?: InputMaybe<Array<ImageTextComboParentCreateInput>>;
  /** Delete multiple ImageTextComboParent documents */
  delete?: InputMaybe<Array<ImageTextComboParentWhereUniqueInput>>;
  /** Disconnect multiple ImageTextComboParent documents */
  disconnect?: InputMaybe<Array<ImageTextComboParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ImageTextComboParent documents */
  set?: InputMaybe<Array<ImageTextComboParentWhereUniqueInput>>;
  /** Update multiple ImageTextComboParent documents */
  update?: InputMaybe<Array<ImageTextComboParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ImageTextComboParent documents */
  upsert?: InputMaybe<Array<ImageTextComboParentUpsertWithNestedWhereUniqueInput>>;
};

export type ImageTextComboParentUpdateManyWithNestedWhereInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateManyWithNestedWhereInput>;
};

export type ImageTextComboParentUpdateOneInlineInput = {
  /** Connect existing ImageTextComboParent document */
  connect?: InputMaybe<ImageTextComboParentWhereUniqueInput>;
  /** Create and connect one ImageTextComboParent document */
  create?: InputMaybe<ImageTextComboParentCreateInput>;
  /** Delete currently connected ImageTextComboParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ImageTextComboParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ImageTextComboParent document */
  update?: InputMaybe<ImageTextComboParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageTextComboParent document */
  upsert?: InputMaybe<ImageTextComboParentUpsertWithNestedWhereUniqueInput>;
};

export type ImageTextComboParentUpdateWithNestedWhereUniqueInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateWithNestedWhereUniqueInput>;
};

export type ImageTextComboParentUpsertWithNestedWhereUniqueInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListUpsertWithNestedWhereUniqueInput>;
};

export type ImageTextComboParentWhereInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListWhereInput>;
};

export type ImageTextComboParentWhereUniqueInput = {
  ImageTextComboList?: InputMaybe<ImageTextComboListWhereUniqueInput>;
};

export type ImageTextComboUpdateInput = {
  buttonRow?: InputMaybe<ButtonRowUpdateOneInlineInput>;
  image?: InputMaybe<ImageUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ImageTextComboUpdateLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImageTextComboUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImageTextComboUpdateLocalizationInput = {
  data: ImageTextComboUpdateLocalizationDataInput;
  locale: Locale;
};

export type ImageTextComboUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ImageTextComboCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ImageTextComboUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ImageTextComboUpsertLocalizationInput>>;
};

export type ImageTextComboUpdateManyInlineInput = {
  /** Create and connect multiple ImageTextCombo component instances */
  create?: InputMaybe<Array<ImageTextComboCreateWithPositionInput>>;
  /** Delete multiple ImageTextCombo documents */
  delete?: InputMaybe<Array<ImageTextComboWhereUniqueInput>>;
  /** Update multiple ImageTextCombo component instances */
  update?: InputMaybe<Array<ImageTextComboUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ImageTextCombo component instances */
  upsert?: InputMaybe<Array<ImageTextComboUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ImageTextComboUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ImageTextComboUpdateManyLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImageTextComboUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ImageTextComboUpdateManyLocalizationInput = {
  data: ImageTextComboUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ImageTextComboUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ImageTextComboUpdateManyLocalizationInput>>;
};

export type ImageTextComboUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageTextComboUpdateManyInput;
  /** Document search */
  where: ImageTextComboWhereInput;
};

export type ImageTextComboUpdateOneInlineInput = {
  /** Create and connect one ImageTextCombo document */
  create?: InputMaybe<ImageTextComboCreateInput>;
  /** Delete currently connected ImageTextCombo document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ImageTextCombo document */
  update?: InputMaybe<ImageTextComboUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageTextCombo document */
  upsert?: InputMaybe<ImageTextComboUpsertWithNestedWhereUniqueInput>;
};

export type ImageTextComboUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ImageTextComboUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ImageTextComboWhereUniqueInput;
};

export type ImageTextComboUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageTextComboUpdateInput;
  /** Unique document search */
  where: ImageTextComboWhereUniqueInput;
};

export type ImageTextComboUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageTextComboCreateInput;
  /** Update document if it exists */
  update: ImageTextComboUpdateInput;
};

export type ImageTextComboUpsertLocalizationInput = {
  create: ImageTextComboCreateLocalizationDataInput;
  locale: Locale;
  update: ImageTextComboUpdateLocalizationDataInput;
};

export type ImageTextComboUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ImageTextComboUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ImageTextComboWhereUniqueInput;
};

export type ImageTextComboUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageTextComboUpsertInput;
  /** Unique document search */
  where: ImageTextComboWhereUniqueInput;
};

/** Identifies documents */
export type ImageTextComboWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageTextComboWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  buttonRow?: InputMaybe<ButtonRowWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<ImageWhereInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References ImageTextCombo record uniquely */
export type ImageTextComboWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

export type ImageUpdateInput = {
  /** altText input for default locale (de) */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  cl1d4fb9a0cuz01xf410ye86o?: InputMaybe<ArticleUpdateManyInlineInput>;
  cl9y0udkr16j101rthys48ouc?: InputMaybe<ContentConfigurationUpdateManyInlineInput>;
  clkv6zz8t02o601unaita2gf2?: InputMaybe<BannerSlideUpdateManyInlineInput>;
  clkwao3dv010301t4ac3k476w?: InputMaybe<BannerSlideUpdateManyInlineInput>;
  cll0h9nsr1kdk01uj6zcz220n?: InputMaybe<ContentConfigurationUpdateManyInlineInput>;
  cll0haawu1j6b01uj0j5v473d?: InputMaybe<ContentConfigurationUpdateManyInlineInput>;
  cln0373ra07qg01ug8brd1c7l?: InputMaybe<ImageTextComboUpdateManyInlineInput>;
  cltsas5sx009n06vy3b2m2aym?: InputMaybe<AdUpdateManyInlineInput>;
  cltsat0m900aq06vy421l47vv?: InputMaybe<AdUpdateManyInlineInput>;
  clu6u8wl201hy06ut4oe9h9be?: InputMaybe<PageUpdateManyInlineInput>;
  clu6u87eg01gu06utd8sn7rx9?: InputMaybe<PageUpdateManyInlineInput>;
  clz16ydjy006r07tbaq9a99va?: InputMaybe<ContentConfigurationUpdateManyInlineInput>;
  cm0kwzncp02d207w2afx79t3l?: InputMaybe<HeroSlideUpdateManyInlineInput>;
  cm0kx07cs02eu07w2em4d3nld?: InputMaybe<HeroSlideUpdateManyInlineInput>;
  cm0kxlz5103dl07w2cu9c1j4x?: InputMaybe<TitleAndLeadUpdateManyInlineInput>;
  cm0l32ewk03gb07un526hhpx5?: InputMaybe<ModelUpdateManyInlineInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  galleries?: InputMaybe<GalleryUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ImageUpdateLocalizationsInput>;
  media?: InputMaybe<ImagemediaUnionUpdateOneInlineInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImageUpdateLocalizationDataInput = {
  altText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type ImageUpdateLocalizationInput = {
  data: ImageUpdateLocalizationDataInput;
  locale: Locale;
};

export type ImageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ImageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ImageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ImageUpsertLocalizationInput>>;
};

export type ImageUpdateManyInlineInput = {
  /** Connect multiple existing Image documents */
  connect?: InputMaybe<Array<ImageConnectInput>>;
  /** Create and connect multiple Image documents */
  create?: InputMaybe<Array<ImageCreateInput>>;
  /** Delete multiple Image documents */
  delete?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Disconnect multiple Image documents */
  disconnect?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Image documents */
  set?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Update multiple Image documents */
  update?: InputMaybe<Array<ImageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Image documents */
  upsert?: InputMaybe<Array<ImageUpsertWithNestedWhereUniqueInput>>;
};

export type ImageUpdateManyInput = {
  /** altText input for default locale (de) */
  altText?: InputMaybe<Scalars['String']['input']>;
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ImageUpdateManyLocalizationsInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImageUpdateManyLocalizationDataInput = {
  altText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type ImageUpdateManyLocalizationInput = {
  data: ImageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ImageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ImageUpdateManyLocalizationInput>>;
};

export type ImageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageUpdateManyInput;
  /** Document search */
  where: ImageWhereInput;
};

export type ImageUpdateOneInlineInput = {
  /** Connect existing Image document */
  connect?: InputMaybe<ImageWhereUniqueInput>;
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
  /** Delete currently connected Image document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Image document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Image document */
  update?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Image document */
  upsert?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
};

export type ImageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageUpdateInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

export type ImageUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageCreateInput;
  /** Update document if it exists */
  update: ImageUpdateInput;
};

export type ImageUpsertLocalizationInput = {
  create: ImageCreateLocalizationDataInput;
  locale: Locale;
  update: ImageUpdateLocalizationDataInput;
};

export type ImageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageUpsertInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ImageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ImageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  altText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  altText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  altText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  altText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  altText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  altText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  altText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  altText_starts_with?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  caption_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  caption_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  caption_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  caption_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  caption_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ImageWhereStageInput>;
  documentInStages_none?: InputMaybe<ImageWhereStageInput>;
  documentInStages_some?: InputMaybe<ImageWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  galleries_every?: InputMaybe<GalleryWhereInput>;
  galleries_none?: InputMaybe<GalleryWhereInput>;
  galleries_some?: InputMaybe<GalleryWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  media?: InputMaybe<ImagemediaUnionWhereInput>;
  /** All values in which the union is empty. */
  media_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ImageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ImageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Image record uniquely */
export type ImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ImagemediaUnion = AssetComponent | UrlComponent;

export type ImagemediaUnionConnectInput = {
  AssetComponent?: InputMaybe<AssetComponentConnectInput>;
  UrlComponent?: InputMaybe<UrlComponentConnectInput>;
};

export type ImagemediaUnionCreateInput = {
  AssetComponent?: InputMaybe<AssetComponentCreateInput>;
  UrlComponent?: InputMaybe<UrlComponentCreateInput>;
};

export type ImagemediaUnionCreateManyInlineInput = {
  /** Create and connect multiple existing ImagemediaUnion documents */
  create?: InputMaybe<Array<ImagemediaUnionCreateInput>>;
};

export type ImagemediaUnionCreateOneInlineInput = {
  /** Create and connect one ImagemediaUnion document */
  create?: InputMaybe<ImagemediaUnionCreateInput>;
};

export type ImagemediaUnionCreateWithPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentCreateWithPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentCreateWithPositionInput>;
};

export type ImagemediaUnionUpdateInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateInput>;
};

export type ImagemediaUnionUpdateManyInlineInput = {
  /** Create and connect multiple ImagemediaUnion component instances */
  create?: InputMaybe<Array<ImagemediaUnionCreateWithPositionInput>>;
  /** Delete multiple ImagemediaUnion documents */
  delete?: InputMaybe<Array<ImagemediaUnionWhereUniqueInput>>;
  /** Update multiple ImagemediaUnion component instances */
  update?: InputMaybe<Array<ImagemediaUnionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ImagemediaUnion component instances */
  upsert?: InputMaybe<Array<ImagemediaUnionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ImagemediaUnionUpdateManyWithNestedWhereInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateManyWithNestedWhereInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateManyWithNestedWhereInput>;
};

export type ImagemediaUnionUpdateOneInlineInput = {
  /** Create and connect one ImagemediaUnion document */
  create?: InputMaybe<ImagemediaUnionCreateInput>;
  /** Delete currently connected ImagemediaUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ImagemediaUnion document */
  update?: InputMaybe<ImagemediaUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImagemediaUnion document */
  upsert?: InputMaybe<ImagemediaUnionUpsertWithNestedWhereUniqueInput>;
};

export type ImagemediaUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type ImagemediaUnionUpdateWithNestedWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateWithNestedWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateWithNestedWhereUniqueInput>;
};

export type ImagemediaUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type ImagemediaUnionUpsertWithNestedWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentUpsertWithNestedWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentUpsertWithNestedWhereUniqueInput>;
};

export type ImagemediaUnionWhereInput = {
  AssetComponent?: InputMaybe<AssetComponentWhereInput>;
  UrlComponent?: InputMaybe<UrlComponentWhereInput>;
};

export type ImagemediaUnionWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentWhereUniqueInput>;
};

export type InsideHubApi_Promotion = {
  __typename?: 'InsideHubApi_Promotion';
  description?: Maybe<Scalars['String']['output']>;
  legalLine?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InsideHubApi_PromotionInput = {
  language: Scalars['String']['input'];
  promotionId: Scalars['String']['input'];
};

export type KeywordList = Entity & Node & {
  __typename?: 'KeywordList';
  articles: Array<Article>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<KeywordList>;
  editorComment?: Maybe<Scalars['String']['output']>;
  featuredVehiclesRows: Array<FeaturedVehiclesRow>;
  /** List of KeywordList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywords: Array<KeywordListKeyword>;
  keywordsString?: Maybe<Scalars['String']['output']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<KeywordList>;
  /** If the keywords are used for filtering, the filters are applied optionally. */
  optional: Scalars['Boolean']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type KeywordListArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleWhereInput>;
};


export type KeywordListCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type KeywordListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type KeywordListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type KeywordListFeaturedVehiclesRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FeaturedVehiclesRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeaturedVehiclesRowWhereInput>;
};


export type KeywordListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type KeywordListKeywordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type KeywordListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type KeywordListPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type KeywordListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type KeywordListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type KeywordListUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type KeywordListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type KeywordListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: KeywordListWhereUniqueInput;
};

/** A connection to a list of items. */
export type KeywordListConnection = {
  __typename?: 'KeywordListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<KeywordListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type KeywordListCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  featuredVehiclesRows?: InputMaybe<FeaturedVehiclesRowCreateManyInlineInput>;
  keywords?: InputMaybe<KeywordListKeywordCreateManyInlineInput>;
  /** keywordsString input for default locale (de) */
  keywordsString?: InputMaybe<Scalars['String']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<KeywordListCreateLocalizationsInput>;
  optional: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type KeywordListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  keywordsString?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type KeywordListCreateLocalizationInput = {
  /** Localization input */
  data: KeywordListCreateLocalizationDataInput;
  locale: Locale;
};

export type KeywordListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<KeywordListCreateLocalizationInput>>;
};

export type KeywordListCreateManyInlineInput = {
  /** Connect multiple existing KeywordList documents */
  connect?: InputMaybe<Array<KeywordListWhereUniqueInput>>;
  /** Create and connect multiple existing KeywordList documents */
  create?: InputMaybe<Array<KeywordListCreateInput>>;
};

export type KeywordListCreateOneInlineInput = {
  /** Connect one existing KeywordList document */
  connect?: InputMaybe<KeywordListWhereUniqueInput>;
  /** Create and connect one KeywordList document */
  create?: InputMaybe<KeywordListCreateInput>;
};

/** An edge in a connection. */
export type KeywordListEdge = {
  __typename?: 'KeywordListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: KeywordList;
};

export type KeywordListKeyword = BodyTypeGroup | Brand | Dealer | DriveType | FuelTypeGroup | Model | QualityChecked | TransmissionGroup | VehicleClass | VehicleCondition;

export type KeywordListKeywordConnectInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupConnectInput>;
  Brand?: InputMaybe<BrandConnectInput>;
  Dealer?: InputMaybe<DealerConnectInput>;
  DriveType?: InputMaybe<DriveTypeConnectInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupConnectInput>;
  Model?: InputMaybe<ModelConnectInput>;
  QualityChecked?: InputMaybe<QualityCheckedConnectInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupConnectInput>;
  VehicleClass?: InputMaybe<VehicleClassConnectInput>;
  VehicleCondition?: InputMaybe<VehicleConditionConnectInput>;
};

export type KeywordListKeywordCreateInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupCreateInput>;
  Brand?: InputMaybe<BrandCreateInput>;
  Dealer?: InputMaybe<DealerCreateInput>;
  DriveType?: InputMaybe<DriveTypeCreateInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupCreateInput>;
  Model?: InputMaybe<ModelCreateInput>;
  QualityChecked?: InputMaybe<QualityCheckedCreateInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupCreateInput>;
  VehicleClass?: InputMaybe<VehicleClassCreateInput>;
  VehicleCondition?: InputMaybe<VehicleConditionCreateInput>;
};

export type KeywordListKeywordCreateManyInlineInput = {
  /** Connect multiple existing KeywordListKeyword documents */
  connect?: InputMaybe<Array<KeywordListKeywordWhereUniqueInput>>;
  /** Create and connect multiple existing KeywordListKeyword documents */
  create?: InputMaybe<Array<KeywordListKeywordCreateInput>>;
};

export type KeywordListKeywordCreateOneInlineInput = {
  /** Connect one existing KeywordListKeyword document */
  connect?: InputMaybe<KeywordListKeywordWhereUniqueInput>;
  /** Create and connect one KeywordListKeyword document */
  create?: InputMaybe<KeywordListKeywordCreateInput>;
};

export type KeywordListKeywordUpdateInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupUpdateInput>;
  Brand?: InputMaybe<BrandUpdateInput>;
  Dealer?: InputMaybe<DealerUpdateInput>;
  DriveType?: InputMaybe<DriveTypeUpdateInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupUpdateInput>;
  Model?: InputMaybe<ModelUpdateInput>;
  QualityChecked?: InputMaybe<QualityCheckedUpdateInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupUpdateInput>;
  VehicleClass?: InputMaybe<VehicleClassUpdateInput>;
  VehicleCondition?: InputMaybe<VehicleConditionUpdateInput>;
};

export type KeywordListKeywordUpdateManyInlineInput = {
  /** Connect multiple existing KeywordListKeyword documents */
  connect?: InputMaybe<Array<KeywordListKeywordConnectInput>>;
  /** Create and connect multiple KeywordListKeyword documents */
  create?: InputMaybe<Array<KeywordListKeywordCreateInput>>;
  /** Delete multiple KeywordListKeyword documents */
  delete?: InputMaybe<Array<KeywordListKeywordWhereUniqueInput>>;
  /** Disconnect multiple KeywordListKeyword documents */
  disconnect?: InputMaybe<Array<KeywordListKeywordWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing KeywordListKeyword documents */
  set?: InputMaybe<Array<KeywordListKeywordWhereUniqueInput>>;
  /** Update multiple KeywordListKeyword documents */
  update?: InputMaybe<Array<KeywordListKeywordUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple KeywordListKeyword documents */
  upsert?: InputMaybe<Array<KeywordListKeywordUpsertWithNestedWhereUniqueInput>>;
};

export type KeywordListKeywordUpdateManyWithNestedWhereInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupUpdateManyWithNestedWhereInput>;
  Brand?: InputMaybe<BrandUpdateManyWithNestedWhereInput>;
  Dealer?: InputMaybe<DealerUpdateManyWithNestedWhereInput>;
  DriveType?: InputMaybe<DriveTypeUpdateManyWithNestedWhereInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupUpdateManyWithNestedWhereInput>;
  Model?: InputMaybe<ModelUpdateManyWithNestedWhereInput>;
  QualityChecked?: InputMaybe<QualityCheckedUpdateManyWithNestedWhereInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupUpdateManyWithNestedWhereInput>;
  VehicleClass?: InputMaybe<VehicleClassUpdateManyWithNestedWhereInput>;
  VehicleCondition?: InputMaybe<VehicleConditionUpdateManyWithNestedWhereInput>;
};

export type KeywordListKeywordUpdateOneInlineInput = {
  /** Connect existing KeywordListKeyword document */
  connect?: InputMaybe<KeywordListKeywordWhereUniqueInput>;
  /** Create and connect one KeywordListKeyword document */
  create?: InputMaybe<KeywordListKeywordCreateInput>;
  /** Delete currently connected KeywordListKeyword document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected KeywordListKeyword document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single KeywordListKeyword document */
  update?: InputMaybe<KeywordListKeywordUpdateWithNestedWhereUniqueInput>;
  /** Upsert single KeywordListKeyword document */
  upsert?: InputMaybe<KeywordListKeywordUpsertWithNestedWhereUniqueInput>;
};

export type KeywordListKeywordUpdateWithNestedWhereUniqueInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupUpdateWithNestedWhereUniqueInput>;
  Brand?: InputMaybe<BrandUpdateWithNestedWhereUniqueInput>;
  Dealer?: InputMaybe<DealerUpdateWithNestedWhereUniqueInput>;
  DriveType?: InputMaybe<DriveTypeUpdateWithNestedWhereUniqueInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupUpdateWithNestedWhereUniqueInput>;
  Model?: InputMaybe<ModelUpdateWithNestedWhereUniqueInput>;
  QualityChecked?: InputMaybe<QualityCheckedUpdateWithNestedWhereUniqueInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupUpdateWithNestedWhereUniqueInput>;
  VehicleClass?: InputMaybe<VehicleClassUpdateWithNestedWhereUniqueInput>;
  VehicleCondition?: InputMaybe<VehicleConditionUpdateWithNestedWhereUniqueInput>;
};

export type KeywordListKeywordUpsertWithNestedWhereUniqueInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupUpsertWithNestedWhereUniqueInput>;
  Brand?: InputMaybe<BrandUpsertWithNestedWhereUniqueInput>;
  Dealer?: InputMaybe<DealerUpsertWithNestedWhereUniqueInput>;
  DriveType?: InputMaybe<DriveTypeUpsertWithNestedWhereUniqueInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupUpsertWithNestedWhereUniqueInput>;
  Model?: InputMaybe<ModelUpsertWithNestedWhereUniqueInput>;
  QualityChecked?: InputMaybe<QualityCheckedUpsertWithNestedWhereUniqueInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupUpsertWithNestedWhereUniqueInput>;
  VehicleClass?: InputMaybe<VehicleClassUpsertWithNestedWhereUniqueInput>;
  VehicleCondition?: InputMaybe<VehicleConditionUpsertWithNestedWhereUniqueInput>;
};

export type KeywordListKeywordWhereInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupWhereInput>;
  Brand?: InputMaybe<BrandWhereInput>;
  Dealer?: InputMaybe<DealerWhereInput>;
  DriveType?: InputMaybe<DriveTypeWhereInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupWhereInput>;
  Model?: InputMaybe<ModelWhereInput>;
  QualityChecked?: InputMaybe<QualityCheckedWhereInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupWhereInput>;
  VehicleClass?: InputMaybe<VehicleClassWhereInput>;
  VehicleCondition?: InputMaybe<VehicleConditionWhereInput>;
};

export type KeywordListKeywordWhereUniqueInput = {
  BodyTypeGroup?: InputMaybe<BodyTypeGroupWhereUniqueInput>;
  Brand?: InputMaybe<BrandWhereUniqueInput>;
  Dealer?: InputMaybe<DealerWhereUniqueInput>;
  DriveType?: InputMaybe<DriveTypeWhereUniqueInput>;
  FuelTypeGroup?: InputMaybe<FuelTypeGroupWhereUniqueInput>;
  Model?: InputMaybe<ModelWhereUniqueInput>;
  QualityChecked?: InputMaybe<QualityCheckedWhereUniqueInput>;
  TransmissionGroup?: InputMaybe<TransmissionGroupWhereUniqueInput>;
  VehicleClass?: InputMaybe<VehicleClassWhereUniqueInput>;
  VehicleCondition?: InputMaybe<VehicleConditionWhereUniqueInput>;
};

/** Identifies documents */
export type KeywordListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<KeywordListWhereStageInput>;
  documentInStages_none?: InputMaybe<KeywordListWhereStageInput>;
  documentInStages_some?: InputMaybe<KeywordListWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuredVehiclesRows_every?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  featuredVehiclesRows_none?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  featuredVehiclesRows_some?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the union is empty */
  keywords_empty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  keywords_some?: InputMaybe<KeywordListKeywordWhereInput>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  optional_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum KeywordListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EditorCommentAsc = 'editorComment_ASC',
  EditorCommentDesc = 'editorComment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeywordsStringAsc = 'keywordsString_ASC',
  KeywordsStringDesc = 'keywordsString_DESC',
  OptionalAsc = 'optional_ASC',
  OptionalDesc = 'optional_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type KeywordListUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  featuredVehiclesRows?: InputMaybe<FeaturedVehiclesRowUpdateManyInlineInput>;
  keywords?: InputMaybe<KeywordListKeywordUpdateManyInlineInput>;
  /** keywordsString input for default locale (de) */
  keywordsString?: InputMaybe<Scalars['String']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<KeywordListUpdateLocalizationsInput>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KeywordListUpdateLocalizationDataInput = {
  keywordsString?: InputMaybe<Scalars['String']['input']>;
};

export type KeywordListUpdateLocalizationInput = {
  data: KeywordListUpdateLocalizationDataInput;
  locale: Locale;
};

export type KeywordListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<KeywordListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<KeywordListUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<KeywordListUpsertLocalizationInput>>;
};

export type KeywordListUpdateManyInlineInput = {
  /** Connect multiple existing KeywordList documents */
  connect?: InputMaybe<Array<KeywordListConnectInput>>;
  /** Create and connect multiple KeywordList documents */
  create?: InputMaybe<Array<KeywordListCreateInput>>;
  /** Delete multiple KeywordList documents */
  delete?: InputMaybe<Array<KeywordListWhereUniqueInput>>;
  /** Disconnect multiple KeywordList documents */
  disconnect?: InputMaybe<Array<KeywordListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing KeywordList documents */
  set?: InputMaybe<Array<KeywordListWhereUniqueInput>>;
  /** Update multiple KeywordList documents */
  update?: InputMaybe<Array<KeywordListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple KeywordList documents */
  upsert?: InputMaybe<Array<KeywordListUpsertWithNestedWhereUniqueInput>>;
};

export type KeywordListUpdateManyInput = {
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** keywordsString input for default locale (de) */
  keywordsString?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<KeywordListUpdateManyLocalizationsInput>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type KeywordListUpdateManyLocalizationDataInput = {
  keywordsString?: InputMaybe<Scalars['String']['input']>;
};

export type KeywordListUpdateManyLocalizationInput = {
  data: KeywordListUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type KeywordListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<KeywordListUpdateManyLocalizationInput>>;
};

export type KeywordListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: KeywordListUpdateManyInput;
  /** Document search */
  where: KeywordListWhereInput;
};

export type KeywordListUpdateOneInlineInput = {
  /** Connect existing KeywordList document */
  connect?: InputMaybe<KeywordListWhereUniqueInput>;
  /** Create and connect one KeywordList document */
  create?: InputMaybe<KeywordListCreateInput>;
  /** Delete currently connected KeywordList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected KeywordList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single KeywordList document */
  update?: InputMaybe<KeywordListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single KeywordList document */
  upsert?: InputMaybe<KeywordListUpsertWithNestedWhereUniqueInput>;
};

export type KeywordListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: KeywordListUpdateInput;
  /** Unique document search */
  where: KeywordListWhereUniqueInput;
};

export type KeywordListUpsertInput = {
  /** Create document if it didn't exist */
  create: KeywordListCreateInput;
  /** Update document if it exists */
  update: KeywordListUpdateInput;
};

export type KeywordListUpsertLocalizationInput = {
  create: KeywordListCreateLocalizationDataInput;
  locale: Locale;
  update: KeywordListUpdateLocalizationDataInput;
};

export type KeywordListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: KeywordListUpsertInput;
  /** Unique document search */
  where: KeywordListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type KeywordListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type KeywordListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeywordListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<KeywordListWhereStageInput>;
  documentInStages_none?: InputMaybe<KeywordListWhereStageInput>;
  documentInStages_some?: InputMaybe<KeywordListWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  featuredVehiclesRows_every?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  featuredVehiclesRows_none?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  featuredVehiclesRows_some?: InputMaybe<FeaturedVehiclesRowWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordsString?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  keywordsString_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  keywordsString_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  keywordsString_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  keywordsString_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  keywordsString_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  keywordsString_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  keywordsString_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  keywordsString_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  keywordsString_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values in which the union is empty */
  keywords_empty?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  keywords_some?: InputMaybe<KeywordListKeywordWhereInput>;
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  optional_not?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type KeywordListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeywordListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeywordListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeywordListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<KeywordListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References KeywordList record uniquely */
export type KeywordListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum Language {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it'
}

export type Link = Entity & {
  __typename?: 'Link';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System stage field */
  stage: Stage;
  to?: Maybe<LinktoUnion>;
};


export type LinkToArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type LinkConnection = {
  __typename?: 'LinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LinkCreateInput = {
  to?: InputMaybe<LinktoUnionCreateOneInlineInput>;
};

export type LinkCreateManyInlineInput = {
  /** Create and connect multiple existing Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
};

export type LinkCreateOneInlineInput = {
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
};

export type LinkCreateWithPositionInput = {
  /** Document to create */
  data: LinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LinkEdge = {
  __typename?: 'LinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Link;
};

/** Identifies documents */
export type LinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  to?: InputMaybe<LinktoUnionWhereInput>;
  /** All values in which the union is empty. */
  to_empty?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type LinkParent = Ad | BannerSlide | Button | HeroSlide;

export type LinkParentConnectInput = {
  Ad?: InputMaybe<AdConnectInput>;
  BannerSlide?: InputMaybe<BannerSlideConnectInput>;
  Button?: InputMaybe<ButtonConnectInput>;
  HeroSlide?: InputMaybe<HeroSlideConnectInput>;
};

export type LinkParentCreateInput = {
  Ad?: InputMaybe<AdCreateInput>;
  BannerSlide?: InputMaybe<BannerSlideCreateInput>;
  Button?: InputMaybe<ButtonCreateInput>;
  HeroSlide?: InputMaybe<HeroSlideCreateInput>;
};

export type LinkParentCreateManyInlineInput = {
  /** Connect multiple existing LinkParent documents */
  connect?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Create and connect multiple existing LinkParent documents */
  create?: InputMaybe<Array<LinkParentCreateInput>>;
};

export type LinkParentCreateOneInlineInput = {
  /** Connect one existing LinkParent document */
  connect?: InputMaybe<LinkParentWhereUniqueInput>;
  /** Create and connect one LinkParent document */
  create?: InputMaybe<LinkParentCreateInput>;
};

export type LinkParentUpdateInput = {
  Ad?: InputMaybe<AdUpdateInput>;
  BannerSlide?: InputMaybe<BannerSlideUpdateInput>;
  Button?: InputMaybe<ButtonUpdateInput>;
  HeroSlide?: InputMaybe<HeroSlideUpdateInput>;
};

export type LinkParentUpdateManyInlineInput = {
  /** Connect multiple existing LinkParent documents */
  connect?: InputMaybe<Array<LinkParentConnectInput>>;
  /** Create and connect multiple LinkParent documents */
  create?: InputMaybe<Array<LinkParentCreateInput>>;
  /** Delete multiple LinkParent documents */
  delete?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Disconnect multiple LinkParent documents */
  disconnect?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LinkParent documents */
  set?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Update multiple LinkParent documents */
  update?: InputMaybe<Array<LinkParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LinkParent documents */
  upsert?: InputMaybe<Array<LinkParentUpsertWithNestedWhereUniqueInput>>;
};

export type LinkParentUpdateManyWithNestedWhereInput = {
  Ad?: InputMaybe<AdUpdateManyWithNestedWhereInput>;
  BannerSlide?: InputMaybe<BannerSlideUpdateManyWithNestedWhereInput>;
  Button?: InputMaybe<ButtonUpdateManyWithNestedWhereInput>;
  HeroSlide?: InputMaybe<HeroSlideUpdateManyWithNestedWhereInput>;
};

export type LinkParentUpdateOneInlineInput = {
  /** Connect existing LinkParent document */
  connect?: InputMaybe<LinkParentWhereUniqueInput>;
  /** Create and connect one LinkParent document */
  create?: InputMaybe<LinkParentCreateInput>;
  /** Delete currently connected LinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected LinkParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single LinkParent document */
  update?: InputMaybe<LinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LinkParent document */
  upsert?: InputMaybe<LinkParentUpsertWithNestedWhereUniqueInput>;
};

export type LinkParentUpdateWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpdateWithNestedWhereUniqueInput>;
  BannerSlide?: InputMaybe<BannerSlideUpdateWithNestedWhereUniqueInput>;
  Button?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
  HeroSlide?: InputMaybe<HeroSlideUpdateWithNestedWhereUniqueInput>;
};

export type LinkParentUpsertWithNestedWhereUniqueInput = {
  Ad?: InputMaybe<AdUpsertWithNestedWhereUniqueInput>;
  BannerSlide?: InputMaybe<BannerSlideUpsertWithNestedWhereUniqueInput>;
  Button?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
  HeroSlide?: InputMaybe<HeroSlideUpsertWithNestedWhereUniqueInput>;
};

export type LinkParentWhereInput = {
  Ad?: InputMaybe<AdWhereInput>;
  BannerSlide?: InputMaybe<BannerSlideWhereInput>;
  Button?: InputMaybe<ButtonWhereInput>;
  HeroSlide?: InputMaybe<HeroSlideWhereInput>;
};

export type LinkParentWhereUniqueInput = {
  Ad?: InputMaybe<AdWhereUniqueInput>;
  BannerSlide?: InputMaybe<BannerSlideWhereUniqueInput>;
  Button?: InputMaybe<ButtonWhereUniqueInput>;
  HeroSlide?: InputMaybe<HeroSlideWhereUniqueInput>;
};

export type LinkUpdateInput = {
  to?: InputMaybe<LinktoUnionUpdateOneInlineInput>;
};

export type LinkUpdateManyInlineInput = {
  /** Create and connect multiple Link component instances */
  create?: InputMaybe<Array<LinkCreateWithPositionInput>>;
  /** Delete multiple Link documents */
  delete?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Update multiple Link component instances */
  update?: InputMaybe<Array<LinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Link component instances */
  upsert?: InputMaybe<Array<LinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type LinkUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type LinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LinkUpdateManyInput;
  /** Document search */
  where: LinkWhereInput;
};

export type LinkUpdateOneInlineInput = {
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
  /** Delete currently connected Link document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Link document */
  update?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Link document */
  upsert?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type LinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<LinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LinkWhereUniqueInput;
};

export type LinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LinkUpdateInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertInput = {
  /** Create document if it didn't exist */
  create: LinkCreateInput;
  /** Update document if it exists */
  update: LinkUpdateInput;
};

export type LinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<LinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LinkUpsertInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

/** Identifies documents */
export type LinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  to?: InputMaybe<LinktoUnionWhereInput>;
  /** All values in which the union is empty. */
  to_empty?: InputMaybe<Scalars['Boolean']['input']>;
};

/** References Link record uniquely */
export type LinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type LinktoUnion = AbsoluteUrlLink | AppLink | ArticleLink | BrandLink | DealerLink | MailtoLink | ModelLink | PageLink | TelLink;

export type LinktoUnionConnectInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkConnectInput>;
  AppLink?: InputMaybe<AppLinkConnectInput>;
  ArticleLink?: InputMaybe<ArticleLinkConnectInput>;
  BrandLink?: InputMaybe<BrandLinkConnectInput>;
  DealerLink?: InputMaybe<DealerLinkConnectInput>;
  MailtoLink?: InputMaybe<MailtoLinkConnectInput>;
  ModelLink?: InputMaybe<ModelLinkConnectInput>;
  PageLink?: InputMaybe<PageLinkConnectInput>;
  TelLink?: InputMaybe<TelLinkConnectInput>;
};

export type LinktoUnionCreateInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkCreateInput>;
  AppLink?: InputMaybe<AppLinkCreateInput>;
  ArticleLink?: InputMaybe<ArticleLinkCreateInput>;
  BrandLink?: InputMaybe<BrandLinkCreateInput>;
  DealerLink?: InputMaybe<DealerLinkCreateInput>;
  MailtoLink?: InputMaybe<MailtoLinkCreateInput>;
  ModelLink?: InputMaybe<ModelLinkCreateInput>;
  PageLink?: InputMaybe<PageLinkCreateInput>;
  TelLink?: InputMaybe<TelLinkCreateInput>;
};

export type LinktoUnionCreateManyInlineInput = {
  /** Create and connect multiple existing LinktoUnion documents */
  create?: InputMaybe<Array<LinktoUnionCreateInput>>;
};

export type LinktoUnionCreateOneInlineInput = {
  /** Create and connect one LinktoUnion document */
  create?: InputMaybe<LinktoUnionCreateInput>;
};

export type LinktoUnionCreateWithPositionInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkCreateWithPositionInput>;
  AppLink?: InputMaybe<AppLinkCreateWithPositionInput>;
  ArticleLink?: InputMaybe<ArticleLinkCreateWithPositionInput>;
  BrandLink?: InputMaybe<BrandLinkCreateWithPositionInput>;
  DealerLink?: InputMaybe<DealerLinkCreateWithPositionInput>;
  MailtoLink?: InputMaybe<MailtoLinkCreateWithPositionInput>;
  ModelLink?: InputMaybe<ModelLinkCreateWithPositionInput>;
  PageLink?: InputMaybe<PageLinkCreateWithPositionInput>;
  TelLink?: InputMaybe<TelLinkCreateWithPositionInput>;
};

export type LinktoUnionUpdateInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateInput>;
  AppLink?: InputMaybe<AppLinkUpdateInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpdateInput>;
  BrandLink?: InputMaybe<BrandLinkUpdateInput>;
  DealerLink?: InputMaybe<DealerLinkUpdateInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpdateInput>;
  ModelLink?: InputMaybe<ModelLinkUpdateInput>;
  PageLink?: InputMaybe<PageLinkUpdateInput>;
  TelLink?: InputMaybe<TelLinkUpdateInput>;
};

export type LinktoUnionUpdateManyInlineInput = {
  /** Create and connect multiple LinktoUnion component instances */
  create?: InputMaybe<Array<LinktoUnionCreateWithPositionInput>>;
  /** Delete multiple LinktoUnion documents */
  delete?: InputMaybe<Array<LinktoUnionWhereUniqueInput>>;
  /** Update multiple LinktoUnion component instances */
  update?: InputMaybe<Array<LinktoUnionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple LinktoUnion component instances */
  upsert?: InputMaybe<Array<LinktoUnionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type LinktoUnionUpdateManyWithNestedWhereInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateManyWithNestedWhereInput>;
  AppLink?: InputMaybe<AppLinkUpdateManyWithNestedWhereInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpdateManyWithNestedWhereInput>;
  BrandLink?: InputMaybe<BrandLinkUpdateManyWithNestedWhereInput>;
  DealerLink?: InputMaybe<DealerLinkUpdateManyWithNestedWhereInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpdateManyWithNestedWhereInput>;
  ModelLink?: InputMaybe<ModelLinkUpdateManyWithNestedWhereInput>;
  PageLink?: InputMaybe<PageLinkUpdateManyWithNestedWhereInput>;
  TelLink?: InputMaybe<TelLinkUpdateManyWithNestedWhereInput>;
};

export type LinktoUnionUpdateOneInlineInput = {
  /** Create and connect one LinktoUnion document */
  create?: InputMaybe<LinktoUnionCreateInput>;
  /** Delete currently connected LinktoUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single LinktoUnion document */
  update?: InputMaybe<LinktoUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LinktoUnion document */
  upsert?: InputMaybe<LinktoUnionUpsertWithNestedWhereUniqueInput>;
};

export type LinktoUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  AppLink?: InputMaybe<AppLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  BrandLink?: InputMaybe<BrandLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  DealerLink?: InputMaybe<DealerLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  ModelLink?: InputMaybe<ModelLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  PageLink?: InputMaybe<PageLinkUpdateWithNestedWhereUniqueAndPositionInput>;
  TelLink?: InputMaybe<TelLinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type LinktoUnionUpdateWithNestedWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateWithNestedWhereUniqueInput>;
  AppLink?: InputMaybe<AppLinkUpdateWithNestedWhereUniqueInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpdateWithNestedWhereUniqueInput>;
  BrandLink?: InputMaybe<BrandLinkUpdateWithNestedWhereUniqueInput>;
  DealerLink?: InputMaybe<DealerLinkUpdateWithNestedWhereUniqueInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpdateWithNestedWhereUniqueInput>;
  ModelLink?: InputMaybe<ModelLinkUpdateWithNestedWhereUniqueInput>;
  PageLink?: InputMaybe<PageLinkUpdateWithNestedWhereUniqueInput>;
  TelLink?: InputMaybe<TelLinkUpdateWithNestedWhereUniqueInput>;
};

export type LinktoUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  AppLink?: InputMaybe<AppLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  BrandLink?: InputMaybe<BrandLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  DealerLink?: InputMaybe<DealerLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  ModelLink?: InputMaybe<ModelLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  PageLink?: InputMaybe<PageLinkUpsertWithNestedWhereUniqueAndPositionInput>;
  TelLink?: InputMaybe<TelLinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type LinktoUnionUpsertWithNestedWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpsertWithNestedWhereUniqueInput>;
  AppLink?: InputMaybe<AppLinkUpsertWithNestedWhereUniqueInput>;
  ArticleLink?: InputMaybe<ArticleLinkUpsertWithNestedWhereUniqueInput>;
  BrandLink?: InputMaybe<BrandLinkUpsertWithNestedWhereUniqueInput>;
  DealerLink?: InputMaybe<DealerLinkUpsertWithNestedWhereUniqueInput>;
  MailtoLink?: InputMaybe<MailtoLinkUpsertWithNestedWhereUniqueInput>;
  ModelLink?: InputMaybe<ModelLinkUpsertWithNestedWhereUniqueInput>;
  PageLink?: InputMaybe<PageLinkUpsertWithNestedWhereUniqueInput>;
  TelLink?: InputMaybe<TelLinkUpsertWithNestedWhereUniqueInput>;
};

export type LinktoUnionWhereInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkWhereInput>;
  AppLink?: InputMaybe<AppLinkWhereInput>;
  ArticleLink?: InputMaybe<ArticleLinkWhereInput>;
  BrandLink?: InputMaybe<BrandLinkWhereInput>;
  DealerLink?: InputMaybe<DealerLinkWhereInput>;
  MailtoLink?: InputMaybe<MailtoLinkWhereInput>;
  ModelLink?: InputMaybe<ModelLinkWhereInput>;
  PageLink?: InputMaybe<PageLinkWhereInput>;
  TelLink?: InputMaybe<TelLinkWhereInput>;
};

export type LinktoUnionWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkWhereUniqueInput>;
  AppLink?: InputMaybe<AppLinkWhereUniqueInput>;
  ArticleLink?: InputMaybe<ArticleLinkWhereUniqueInput>;
  BrandLink?: InputMaybe<BrandLinkWhereUniqueInput>;
  DealerLink?: InputMaybe<DealerLinkWhereUniqueInput>;
  MailtoLink?: InputMaybe<MailtoLinkWhereUniqueInput>;
  ModelLink?: InputMaybe<ModelLinkWhereUniqueInput>;
  PageLink?: InputMaybe<PageLinkWhereUniqueInput>;
  TelLink?: InputMaybe<TelLinkWhereUniqueInput>;
};

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  De = 'de',
  Fr = 'fr',
  It = 'it'
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location';
  distance: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};


/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type MailtoLink = Entity & {
  __typename?: 'MailtoLink';
  emailAddress: Scalars['String']['output'];
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System stage field */
  stage: Stage;
};

export type MailtoLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MailtoLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type MailtoLinkConnection = {
  __typename?: 'MailtoLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MailtoLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MailtoLinkCreateInput = {
  emailAddress: Scalars['String']['input'];
};

export type MailtoLinkCreateManyInlineInput = {
  /** Create and connect multiple existing MailtoLink documents */
  create?: InputMaybe<Array<MailtoLinkCreateInput>>;
};

export type MailtoLinkCreateOneInlineInput = {
  /** Create and connect one MailtoLink document */
  create?: InputMaybe<MailtoLinkCreateInput>;
};

export type MailtoLinkCreateWithPositionInput = {
  /** Document to create */
  data: MailtoLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MailtoLinkEdge = {
  __typename?: 'MailtoLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: MailtoLink;
};

/** Identifies documents */
export type MailtoLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  emailAddress_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  emailAddress_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  emailAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  emailAddress_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  emailAddress_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  emailAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  emailAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  emailAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  emailAddress_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum MailtoLinkOrderByInput {
  EmailAddressAsc = 'emailAddress_ASC',
  EmailAddressDesc = 'emailAddress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type MailtoLinkParent = Link;

export type MailtoLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type MailtoLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type MailtoLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing MailtoLinkParent documents */
  create?: InputMaybe<Array<MailtoLinkParentCreateInput>>;
};

export type MailtoLinkParentCreateOneInlineInput = {
  /** Create and connect one MailtoLinkParent document */
  create?: InputMaybe<MailtoLinkParentCreateInput>;
};

export type MailtoLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type MailtoLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type MailtoLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple MailtoLinkParent component instances */
  create?: InputMaybe<Array<MailtoLinkParentCreateWithPositionInput>>;
  /** Delete multiple MailtoLinkParent documents */
  delete?: InputMaybe<Array<MailtoLinkParentWhereUniqueInput>>;
  /** Update multiple MailtoLinkParent component instances */
  update?: InputMaybe<Array<MailtoLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple MailtoLinkParent component instances */
  upsert?: InputMaybe<Array<MailtoLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type MailtoLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type MailtoLinkParentUpdateOneInlineInput = {
  /** Create and connect one MailtoLinkParent document */
  create?: InputMaybe<MailtoLinkParentCreateInput>;
  /** Delete currently connected MailtoLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single MailtoLinkParent document */
  update?: InputMaybe<MailtoLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MailtoLinkParent document */
  upsert?: InputMaybe<MailtoLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type MailtoLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type MailtoLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type MailtoLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type MailtoLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type MailtoLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type MailtoLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type MailtoLinkUpdateInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type MailtoLinkUpdateManyInlineInput = {
  /** Create and connect multiple MailtoLink component instances */
  create?: InputMaybe<Array<MailtoLinkCreateWithPositionInput>>;
  /** Delete multiple MailtoLink documents */
  delete?: InputMaybe<Array<MailtoLinkWhereUniqueInput>>;
  /** Update multiple MailtoLink component instances */
  update?: InputMaybe<Array<MailtoLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple MailtoLink component instances */
  upsert?: InputMaybe<Array<MailtoLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type MailtoLinkUpdateManyInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>;
};

export type MailtoLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MailtoLinkUpdateManyInput;
  /** Document search */
  where: MailtoLinkWhereInput;
};

export type MailtoLinkUpdateOneInlineInput = {
  /** Create and connect one MailtoLink document */
  create?: InputMaybe<MailtoLinkCreateInput>;
  /** Delete currently connected MailtoLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single MailtoLink document */
  update?: InputMaybe<MailtoLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MailtoLink document */
  upsert?: InputMaybe<MailtoLinkUpsertWithNestedWhereUniqueInput>;
};

export type MailtoLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MailtoLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MailtoLinkWhereUniqueInput;
};

export type MailtoLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MailtoLinkUpdateInput;
  /** Unique document search */
  where: MailtoLinkWhereUniqueInput;
};

export type MailtoLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: MailtoLinkCreateInput;
  /** Update document if it exists */
  update: MailtoLinkUpdateInput;
};

export type MailtoLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MailtoLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MailtoLinkWhereUniqueInput;
};

export type MailtoLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MailtoLinkUpsertInput;
  /** Unique document search */
  where: MailtoLinkWhereUniqueInput;
};

/** Identifies documents */
export type MailtoLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MailtoLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  emailAddress_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  emailAddress_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  emailAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  emailAddress_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  emailAddress_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  emailAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  emailAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  emailAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  emailAddress_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

/** References MailtoLink record uniquely */
export type MailtoLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum Marketplace {
  Al = 'AL',
  As24 = 'AS24',
  C4U = 'C4U',
  Cm = 'CM',
  Efw = 'EFW'
}

export type Model = Entity & Node & {
  __typename?: 'Model';
  brand?: Maybe<Brand>;
  campaignBanners: Array<Ad>;
  childModels: Array<Model>;
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  current: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** Get the document in other stages */
  documentInStages: Array<Model>;
  documents: Array<Scalars['Json']['output']>;
  heroPictureAltText?: Maybe<Scalars['String']['output']>;
  heroPictureUrl?: Maybe<Scalars['String']['output']>;
  /** List of Model versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Model>;
  mipsId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  pageExtension?: Maybe<GeneratedPageExtension>;
  pages: Array<Page>;
  parentInsideId?: Maybe<Scalars['Int']['output']>;
  parentModel?: Maybe<Model>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  showOnAllModels: Scalars['Boolean']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  thumbnailPictureAltText?: Maybe<Scalars['String']['output']>;
  thumbnailPictureExtended?: Maybe<Image>;
  thumbnailPictureUrl?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type ModelBrandArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelCampaignBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AdOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdWhereInput>;
};


export type ModelChildModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelWhereInput>;
};


export type ModelCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ModelCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type ModelHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type ModelKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type ModelLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ModelPageExtensionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type ModelParentModelArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ModelPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type ModelThumbnailPictureExtendedArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type ModelUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type ModelUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ModelConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ModelWhereUniqueInput;
};

/** A connection to a list of items. */
export type ModelConnection = {
  __typename?: 'ModelConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ModelEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ModelCreateInput = {
  brand?: InputMaybe<BrandCreateOneInlineInput>;
  campaignBanners?: InputMaybe<AdCreateManyInlineInput>;
  childModels?: InputMaybe<ModelCreateManyInlineInput>;
  cl16amqhkhizx01yw14ky1myh?: InputMaybe<VehicleCreateManyInlineInput>;
  cll54u4a209ps01ul4nes5d3q?: InputMaybe<ModelLinkCreateManyInlineInput>;
  cltsf2wny019x06vygqrt9x75?: InputMaybe<AdTargetTileCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  current: Scalars['Boolean']['input'];
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<Scalars['Json']['input']>>;
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ModelCreateLocalizationsInput>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  pageExtension?: InputMaybe<GeneratedPageExtensionCreateOneInlineInput>;
  pages?: InputMaybe<PageCreateManyInlineInput>;
  parentInsideId?: InputMaybe<Scalars['Int']['input']>;
  parentModel?: InputMaybe<ModelCreateOneInlineInput>;
  showOnAllModels: Scalars['Boolean']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
  /** thumbnailPictureAltText input for default locale (de) */
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureExtended?: InputMaybe<ImageCreateOneInlineInput>;
  thumbnailPictureUrl?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ModelCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ModelCreateLocalizationInput = {
  /** Localization input */
  data: ModelCreateLocalizationDataInput;
  locale: Locale;
};

export type ModelCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ModelCreateLocalizationInput>>;
};

export type ModelCreateManyInlineInput = {
  /** Connect multiple existing Model documents */
  connect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  /** Create and connect multiple existing Model documents */
  create?: InputMaybe<Array<ModelCreateInput>>;
};

export type ModelCreateOneInlineInput = {
  /** Connect one existing Model document */
  connect?: InputMaybe<ModelWhereUniqueInput>;
  /** Create and connect one Model document */
  create?: InputMaybe<ModelCreateInput>;
};

/** An edge in a connection. */
export type ModelEdge = {
  __typename?: 'ModelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Model;
};

export type ModelLink = Entity & {
  __typename?: 'ModelLink';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ModelLink>;
  model?: Maybe<Model>;
  name?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
};


export type ModelLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type ModelLinkModelArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ModelLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ModelLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type ModelLinkConnection = {
  __typename?: 'ModelLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ModelLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ModelLinkCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ModelLinkCreateLocalizationsInput>;
  model?: InputMaybe<ModelCreateOneInlineInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkCreateLocalizationInput = {
  /** Localization input */
  data: ModelLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type ModelLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ModelLinkCreateLocalizationInput>>;
};

export type ModelLinkCreateManyInlineInput = {
  /** Create and connect multiple existing ModelLink documents */
  create?: InputMaybe<Array<ModelLinkCreateInput>>;
};

export type ModelLinkCreateOneInlineInput = {
  /** Create and connect one ModelLink document */
  create?: InputMaybe<ModelLinkCreateInput>;
};

export type ModelLinkCreateWithPositionInput = {
  /** Document to create */
  data: ModelLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ModelLinkEdge = {
  __typename?: 'ModelLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ModelLink;
};

/** Identifies documents */
export type ModelLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  model?: InputMaybe<ModelWhereInput>;
};

export enum ModelLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type ModelLinkParent = Link;

export type ModelLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type ModelLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type ModelLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing ModelLinkParent documents */
  create?: InputMaybe<Array<ModelLinkParentCreateInput>>;
};

export type ModelLinkParentCreateOneInlineInput = {
  /** Create and connect one ModelLinkParent document */
  create?: InputMaybe<ModelLinkParentCreateInput>;
};

export type ModelLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type ModelLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type ModelLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple ModelLinkParent component instances */
  create?: InputMaybe<Array<ModelLinkParentCreateWithPositionInput>>;
  /** Delete multiple ModelLinkParent documents */
  delete?: InputMaybe<Array<ModelLinkParentWhereUniqueInput>>;
  /** Update multiple ModelLinkParent component instances */
  update?: InputMaybe<Array<ModelLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ModelLinkParent component instances */
  upsert?: InputMaybe<Array<ModelLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ModelLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type ModelLinkParentUpdateOneInlineInput = {
  /** Create and connect one ModelLinkParent document */
  create?: InputMaybe<ModelLinkParentCreateInput>;
  /** Delete currently connected ModelLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ModelLinkParent document */
  update?: InputMaybe<ModelLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ModelLinkParent document */
  upsert?: InputMaybe<ModelLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type ModelLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type ModelLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type ModelLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type ModelLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type ModelLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type ModelLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type ModelLinkUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<ModelLinkUpdateLocalizationsInput>;
  model?: InputMaybe<ModelUpdateOneInlineInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkUpdateLocalizationInput = {
  data: ModelLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type ModelLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ModelLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ModelLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ModelLinkUpsertLocalizationInput>>;
};

export type ModelLinkUpdateManyInlineInput = {
  /** Create and connect multiple ModelLink component instances */
  create?: InputMaybe<Array<ModelLinkCreateWithPositionInput>>;
  /** Delete multiple ModelLink documents */
  delete?: InputMaybe<Array<ModelLinkWhereUniqueInput>>;
  /** Update multiple ModelLink component instances */
  update?: InputMaybe<Array<ModelLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple ModelLink component instances */
  upsert?: InputMaybe<Array<ModelLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ModelLinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ModelLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ModelLinkUpdateManyLocalizationInput = {
  data: ModelLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ModelLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ModelLinkUpdateManyLocalizationInput>>;
};

export type ModelLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ModelLinkUpdateManyInput;
  /** Document search */
  where: ModelLinkWhereInput;
};

export type ModelLinkUpdateOneInlineInput = {
  /** Create and connect one ModelLink document */
  create?: InputMaybe<ModelLinkCreateInput>;
  /** Delete currently connected ModelLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ModelLink document */
  update?: InputMaybe<ModelLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ModelLink document */
  upsert?: InputMaybe<ModelLinkUpsertWithNestedWhereUniqueInput>;
};

export type ModelLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ModelLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ModelLinkWhereUniqueInput;
};

export type ModelLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ModelLinkUpdateInput;
  /** Unique document search */
  where: ModelLinkWhereUniqueInput;
};

export type ModelLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: ModelLinkCreateInput;
  /** Update document if it exists */
  update: ModelLinkUpdateInput;
};

export type ModelLinkUpsertLocalizationInput = {
  create: ModelLinkCreateLocalizationDataInput;
  locale: Locale;
  update: ModelLinkUpdateLocalizationDataInput;
};

export type ModelLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ModelLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ModelLinkWhereUniqueInput;
};

export type ModelLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ModelLinkUpsertInput;
  /** Unique document search */
  where: ModelLinkWhereUniqueInput;
};

/** Identifies documents */
export type ModelLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ModelLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  model?: InputMaybe<ModelWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References ModelLink record uniquely */
export type ModelLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type ModelManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ModelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ModelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ModelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  childModels_every?: InputMaybe<ModelWhereInput>;
  childModels_none?: InputMaybe<ModelWhereInput>;
  childModels_some?: InputMaybe<ModelWhereInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  current_not?: InputMaybe<Scalars['Boolean']['input']>;
  documentInStages_every?: InputMaybe<ModelWhereStageInput>;
  documentInStages_none?: InputMaybe<ModelWhereStageInput>;
  documentInStages_some?: InputMaybe<ModelWhereStageInput>;
  /** All values containing the given json path. */
  documents_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  documents_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  mipsId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  mipsId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  mipsId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  mipsId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  mipsId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  mipsId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  mipsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  pageExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  parentInsideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  parentInsideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  parentInsideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  parentInsideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  parentInsideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  parentInsideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  parentInsideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  parentInsideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  parentModel?: InputMaybe<ModelWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showOnAllModels?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showOnAllModels_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureExtended?: InputMaybe<ImageWhereInput>;
  thumbnailPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  thumbnailPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  thumbnailPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  thumbnailPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  thumbnailPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  thumbnailPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  thumbnailPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  thumbnailPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  thumbnailPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  thumbnailPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ModelOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrentAsc = 'current_ASC',
  CurrentDesc = 'current_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeroPictureAltTextAsc = 'heroPictureAltText_ASC',
  HeroPictureAltTextDesc = 'heroPictureAltText_DESC',
  HeroPictureUrlAsc = 'heroPictureUrl_ASC',
  HeroPictureUrlDesc = 'heroPictureUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  MipsIdAsc = 'mipsId_ASC',
  MipsIdDesc = 'mipsId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParentInsideIdAsc = 'parentInsideId_ASC',
  ParentInsideIdDesc = 'parentInsideId_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowOnAllModelsAsc = 'showOnAllModels_ASC',
  ShowOnAllModelsDesc = 'showOnAllModels_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  ThumbnailPictureAltTextAsc = 'thumbnailPictureAltText_ASC',
  ThumbnailPictureAltTextDesc = 'thumbnailPictureAltText_DESC',
  ThumbnailPictureUrlAsc = 'thumbnailPictureUrl_ASC',
  ThumbnailPictureUrlDesc = 'thumbnailPictureUrl_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ModelUpdateInput = {
  brand?: InputMaybe<BrandUpdateOneInlineInput>;
  campaignBanners?: InputMaybe<AdUpdateManyInlineInput>;
  childModels?: InputMaybe<ModelUpdateManyInlineInput>;
  cl16amqhkhizx01yw14ky1myh?: InputMaybe<VehicleUpdateManyInlineInput>;
  cll54u4a209ps01ul4nes5d3q?: InputMaybe<ModelLinkUpdateManyInlineInput>;
  cltsf2wny019x06vygqrt9x75?: InputMaybe<AdTargetTileUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<Scalars['Json']['input']>>;
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ModelUpdateLocalizationsInput>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  pageExtension?: InputMaybe<GeneratedPageExtensionUpdateOneInlineInput>;
  pages?: InputMaybe<PageUpdateManyInlineInput>;
  parentInsideId?: InputMaybe<Scalars['Int']['input']>;
  parentModel?: InputMaybe<ModelUpdateOneInlineInput>;
  showOnAllModels?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** thumbnailPictureAltText input for default locale (de) */
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureExtended?: InputMaybe<ImageUpdateOneInlineInput>;
  thumbnailPictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUpdateLocalizationInput = {
  data: ModelUpdateLocalizationDataInput;
  locale: Locale;
};

export type ModelUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ModelCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ModelUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ModelUpsertLocalizationInput>>;
};

export type ModelUpdateManyInlineInput = {
  /** Connect multiple existing Model documents */
  connect?: InputMaybe<Array<ModelConnectInput>>;
  /** Create and connect multiple Model documents */
  create?: InputMaybe<Array<ModelCreateInput>>;
  /** Delete multiple Model documents */
  delete?: InputMaybe<Array<ModelWhereUniqueInput>>;
  /** Disconnect multiple Model documents */
  disconnect?: InputMaybe<Array<ModelWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Model documents */
  set?: InputMaybe<Array<ModelWhereUniqueInput>>;
  /** Update multiple Model documents */
  update?: InputMaybe<Array<ModelUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Model documents */
  upsert?: InputMaybe<Array<ModelUpsertWithNestedWhereUniqueInput>>;
};

export type ModelUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<Scalars['Json']['input']>>;
  /** heroPictureAltText input for default locale (de) */
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ModelUpdateManyLocalizationsInput>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  parentInsideId?: InputMaybe<Scalars['Int']['input']>;
  showOnAllModels?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** thumbnailPictureAltText input for default locale (de) */
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
};

export type ModelUpdateManyLocalizationInput = {
  data: ModelUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ModelUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ModelUpdateManyLocalizationInput>>;
};

export type ModelUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ModelUpdateManyInput;
  /** Document search */
  where: ModelWhereInput;
};

export type ModelUpdateOneInlineInput = {
  /** Connect existing Model document */
  connect?: InputMaybe<ModelWhereUniqueInput>;
  /** Create and connect one Model document */
  create?: InputMaybe<ModelCreateInput>;
  /** Delete currently connected Model document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Model document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Model document */
  update?: InputMaybe<ModelUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Model document */
  upsert?: InputMaybe<ModelUpsertWithNestedWhereUniqueInput>;
};

export type ModelUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ModelUpdateInput;
  /** Unique document search */
  where: ModelWhereUniqueInput;
};

export type ModelUpsertInput = {
  /** Create document if it didn't exist */
  create: ModelCreateInput;
  /** Update document if it exists */
  update: ModelUpdateInput;
};

export type ModelUpsertLocalizationInput = {
  create: ModelCreateLocalizationDataInput;
  locale: Locale;
  update: ModelUpdateLocalizationDataInput;
};

export type ModelUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ModelUpsertInput;
  /** Unique document search */
  where: ModelWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ModelWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ModelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ModelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ModelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ModelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  campaignBanners_every?: InputMaybe<AdWhereInput>;
  campaignBanners_none?: InputMaybe<AdWhereInput>;
  campaignBanners_some?: InputMaybe<AdWhereInput>;
  childModels_every?: InputMaybe<ModelWhereInput>;
  childModels_none?: InputMaybe<ModelWhereInput>;
  childModels_some?: InputMaybe<ModelWhereInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  current?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  current_not?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  documentInStages_every?: InputMaybe<ModelWhereStageInput>;
  documentInStages_none?: InputMaybe<ModelWhereStageInput>;
  documentInStages_some?: InputMaybe<ModelWhereStageInput>;
  /** All values containing the given json path. */
  documents_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  documents_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  heroPictureAltText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureAltText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureAltText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureAltText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureAltText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureAltText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureAltText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureAltText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureAltText_starts_with?: InputMaybe<Scalars['String']['input']>;
  heroPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  heroPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  heroPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  heroPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heroPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  heroPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  heroPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  heroPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  heroPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  heroPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  mipsId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  mipsId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  mipsId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  mipsId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  mipsId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  mipsId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  mipsId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  mipsId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  pageExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  parentInsideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  parentInsideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  parentInsideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  parentInsideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  parentInsideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  parentInsideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  parentInsideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  parentInsideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  parentModel?: InputMaybe<ModelWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  showOnAllModels?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  showOnAllModels_not?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureAltText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  thumbnailPictureAltText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  thumbnailPictureAltText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  thumbnailPictureAltText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  thumbnailPictureAltText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  thumbnailPictureAltText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  thumbnailPictureAltText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  thumbnailPictureAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  thumbnailPictureAltText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  thumbnailPictureAltText_starts_with?: InputMaybe<Scalars['String']['input']>;
  thumbnailPictureExtended?: InputMaybe<ImageWhereInput>;
  thumbnailPictureUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  thumbnailPictureUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  thumbnailPictureUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  thumbnailPictureUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  thumbnailPictureUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  thumbnailPictureUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  thumbnailPictureUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  thumbnailPictureUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  thumbnailPictureUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  thumbnailPictureUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ModelWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ModelWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ModelWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ModelWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ModelWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Model record uniquely */
export type ModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create one accordionChapter */
  createAccordionChapter?: Maybe<AccordionChapter>;
  /** Create one accordionChapterList */
  createAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Create one accordionSmlList */
  createAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Create one ad */
  createAd?: Maybe<Ad>;
  /** Create one article */
  createArticle?: Maybe<Article>;
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Create one banner */
  createBanner?: Maybe<Banner>;
  /** Create one bodyType */
  createBodyType?: Maybe<BodyType>;
  /** Create one bodyTypeGroup */
  createBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Create one brand */
  createBrand?: Maybe<Brand>;
  /** Create one button */
  createButton?: Maybe<Button>;
  /** Create one buttonRow */
  createButtonRow?: Maybe<ButtonRow>;
  /** Create one citatum */
  createCitatum?: Maybe<Citatum>;
  /** Create one colorNorm */
  createColorNorm?: Maybe<ColorNorm>;
  /** Create one colorType */
  createColorType?: Maybe<ColorType>;
  /** Create one contentConfiguration */
  createContentConfiguration?: Maybe<ContentConfiguration>;
  /** Create one dealer */
  createDealer?: Maybe<Dealer>;
  /** Create one dealerEmployee */
  createDealerEmployee?: Maybe<DealerEmployee>;
  /** Create one department */
  createDepartment?: Maybe<Department>;
  /** Create one driveType */
  createDriveType?: Maybe<DriveType>;
  /** Create one emissionClass */
  createEmissionClass?: Maybe<EmissionClass>;
  /** Create one employee */
  createEmployee?: Maybe<Employee>;
  /** Create one factList */
  createFactList?: Maybe<FactList>;
  /** Create one featuredVehiclesRow */
  createFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Create one fuelType */
  createFuelType?: Maybe<FuelType>;
  /** Create one fuelTypeGroup */
  createFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Create one gallery */
  createGallery?: Maybe<Gallery>;
  /** Create one generatedPageExtension */
  createGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Create one heroSlider */
  createHeroSlider?: Maybe<HeroSlider>;
  /** Create one image */
  createImage?: Maybe<Image>;
  /** Create one imageTextComboList */
  createImageTextComboList?: Maybe<ImageTextComboList>;
  /** Create one keywordList */
  createKeywordList?: Maybe<KeywordList>;
  /** Create one model */
  createModel?: Maybe<Model>;
  /** Create one optionFacet */
  createOptionFacet?: Maybe<OptionFacet>;
  /** Create one paPromotion */
  createPaPromotion?: Maybe<PaPromotion>;
  /** Create one page */
  createPage?: Maybe<Page>;
  /** Create one pollutionClass */
  createPollutionClass?: Maybe<PollutionClass>;
  /** Create one qualityChecked */
  createQualityChecked?: Maybe<QualityChecked>;
  /** Create one questionAndAnswer */
  createQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Create one questionAndAnswerList */
  createQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Create one root */
  createRoot?: Maybe<Root>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one service */
  createService?: Maybe<Service>;
  /** Create one specialBox */
  createSpecialBox?: Maybe<SpecialBox>;
  /** Create one transmission */
  createTransmission?: Maybe<Transmission>;
  /** Create one transmissionGroup */
  createTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Create one upholstery */
  createUpholstery?: Maybe<Upholstery>;
  /** Create one vehicle */
  createVehicle?: Maybe<Vehicle>;
  /** Create one vehicleClass */
  createVehicleClass?: Maybe<VehicleClass>;
  /** Create one vehicleCondition */
  createVehicleCondition?: Maybe<VehicleCondition>;
  /** Create one vehicleStatus */
  createVehicleStatus?: Maybe<VehicleStatus>;
  /** Create one vehicleType */
  createVehicleType?: Maybe<VehicleType>;
  /** Create one video */
  createVideo?: Maybe<Video>;
  /** Create one whitelabel */
  createWhitelabel?: Maybe<Whitelabel>;
  /** Delete one accordionChapter from _all_ existing stages. Returns deleted document. */
  deleteAccordionChapter?: Maybe<AccordionChapter>;
  /** Delete one accordionChapterList from _all_ existing stages. Returns deleted document. */
  deleteAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Delete one accordionSmlList from _all_ existing stages. Returns deleted document. */
  deleteAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Delete one ad from _all_ existing stages. Returns deleted document. */
  deleteAd?: Maybe<Ad>;
  /** Delete one article from _all_ existing stages. Returns deleted document. */
  deleteArticle?: Maybe<Article>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one banner from _all_ existing stages. Returns deleted document. */
  deleteBanner?: Maybe<Banner>;
  /** Delete one bodyType from _all_ existing stages. Returns deleted document. */
  deleteBodyType?: Maybe<BodyType>;
  /** Delete one bodyTypeGroup from _all_ existing stages. Returns deleted document. */
  deleteBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Delete one brand from _all_ existing stages. Returns deleted document. */
  deleteBrand?: Maybe<Brand>;
  /** Delete one button from _all_ existing stages. Returns deleted document. */
  deleteButton?: Maybe<Button>;
  /** Delete one buttonRow from _all_ existing stages. Returns deleted document. */
  deleteButtonRow?: Maybe<ButtonRow>;
  /** Delete one citatum from _all_ existing stages. Returns deleted document. */
  deleteCitatum?: Maybe<Citatum>;
  /** Delete one colorNorm from _all_ existing stages. Returns deleted document. */
  deleteColorNorm?: Maybe<ColorNorm>;
  /** Delete one colorType from _all_ existing stages. Returns deleted document. */
  deleteColorType?: Maybe<ColorType>;
  /** Delete one contentConfiguration from _all_ existing stages. Returns deleted document. */
  deleteContentConfiguration?: Maybe<ContentConfiguration>;
  /** Delete one dealer from _all_ existing stages. Returns deleted document. */
  deleteDealer?: Maybe<Dealer>;
  /** Delete one dealerEmployee from _all_ existing stages. Returns deleted document. */
  deleteDealerEmployee?: Maybe<DealerEmployee>;
  /** Delete one department from _all_ existing stages. Returns deleted document. */
  deleteDepartment?: Maybe<Department>;
  /** Delete one driveType from _all_ existing stages. Returns deleted document. */
  deleteDriveType?: Maybe<DriveType>;
  /** Delete one emissionClass from _all_ existing stages. Returns deleted document. */
  deleteEmissionClass?: Maybe<EmissionClass>;
  /** Delete one employee from _all_ existing stages. Returns deleted document. */
  deleteEmployee?: Maybe<Employee>;
  /** Delete one factList from _all_ existing stages. Returns deleted document. */
  deleteFactList?: Maybe<FactList>;
  /** Delete one featuredVehiclesRow from _all_ existing stages. Returns deleted document. */
  deleteFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Delete one fuelType from _all_ existing stages. Returns deleted document. */
  deleteFuelType?: Maybe<FuelType>;
  /** Delete one fuelTypeGroup from _all_ existing stages. Returns deleted document. */
  deleteFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Delete one gallery from _all_ existing stages. Returns deleted document. */
  deleteGallery?: Maybe<Gallery>;
  /** Delete one generatedPageExtension from _all_ existing stages. Returns deleted document. */
  deleteGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Delete one heroSlider from _all_ existing stages. Returns deleted document. */
  deleteHeroSlider?: Maybe<HeroSlider>;
  /** Delete one image from _all_ existing stages. Returns deleted document. */
  deleteImage?: Maybe<Image>;
  /** Delete one imageTextComboList from _all_ existing stages. Returns deleted document. */
  deleteImageTextComboList?: Maybe<ImageTextComboList>;
  /** Delete one keywordList from _all_ existing stages. Returns deleted document. */
  deleteKeywordList?: Maybe<KeywordList>;
  /**
   * Delete many AccordionChapterList documents
   * @deprecated Please use the new paginated many mutation (deleteManyAccordionChapterListsConnection)
   */
  deleteManyAccordionChapterLists: BatchPayload;
  /** Delete many AccordionChapterList documents, return deleted documents */
  deleteManyAccordionChapterListsConnection: AccordionChapterListConnection;
  /**
   * Delete many AccordionChapter documents
   * @deprecated Please use the new paginated many mutation (deleteManyAccordionChaptersConnection)
   */
  deleteManyAccordionChapters: BatchPayload;
  /** Delete many AccordionChapter documents, return deleted documents */
  deleteManyAccordionChaptersConnection: AccordionChapterConnection;
  /**
   * Delete many AccordionSmlList documents
   * @deprecated Please use the new paginated many mutation (deleteManyAccordionSmlListsConnection)
   */
  deleteManyAccordionSmlLists: BatchPayload;
  /** Delete many AccordionSmlList documents, return deleted documents */
  deleteManyAccordionSmlListsConnection: AccordionSmlListConnection;
  /**
   * Delete many Ad documents
   * @deprecated Please use the new paginated many mutation (deleteManyAdsConnection)
   */
  deleteManyAds: BatchPayload;
  /** Delete many Ad documents, return deleted documents */
  deleteManyAdsConnection: AdConnection;
  /**
   * Delete many Article documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlesConnection)
   */
  deleteManyArticles: BatchPayload;
  /** Delete many Article documents, return deleted documents */
  deleteManyArticlesConnection: ArticleConnection;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many Banner documents
   * @deprecated Please use the new paginated many mutation (deleteManyBannersConnection)
   */
  deleteManyBanners: BatchPayload;
  /** Delete many Banner documents, return deleted documents */
  deleteManyBannersConnection: BannerConnection;
  /**
   * Delete many BodyTypeGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyBodyTypeGroupsConnection)
   */
  deleteManyBodyTypeGroups: BatchPayload;
  /** Delete many BodyTypeGroup documents, return deleted documents */
  deleteManyBodyTypeGroupsConnection: BodyTypeGroupConnection;
  /**
   * Delete many BodyType documents
   * @deprecated Please use the new paginated many mutation (deleteManyBodyTypesConnection)
   */
  deleteManyBodyTypes: BatchPayload;
  /** Delete many BodyType documents, return deleted documents */
  deleteManyBodyTypesConnection: BodyTypeConnection;
  /**
   * Delete many Brand documents
   * @deprecated Please use the new paginated many mutation (deleteManyBrandsConnection)
   */
  deleteManyBrands: BatchPayload;
  /** Delete many Brand documents, return deleted documents */
  deleteManyBrandsConnection: BrandConnection;
  /**
   * Delete many ButtonRow documents
   * @deprecated Please use the new paginated many mutation (deleteManyButtonRowsConnection)
   */
  deleteManyButtonRows: BatchPayload;
  /** Delete many ButtonRow documents, return deleted documents */
  deleteManyButtonRowsConnection: ButtonRowConnection;
  /**
   * Delete many Button documents
   * @deprecated Please use the new paginated many mutation (deleteManyButtonsConnection)
   */
  deleteManyButtons: BatchPayload;
  /** Delete many Button documents, return deleted documents */
  deleteManyButtonsConnection: ButtonConnection;
  /**
   * Delete many Citatum documents
   * @deprecated Please use the new paginated many mutation (deleteManyCitatumsConnection)
   */
  deleteManyCitatums: BatchPayload;
  /** Delete many Citatum documents, return deleted documents */
  deleteManyCitatumsConnection: CitatumConnection;
  /**
   * Delete many ColorNorm documents
   * @deprecated Please use the new paginated many mutation (deleteManyColorNormsConnection)
   */
  deleteManyColorNorms: BatchPayload;
  /** Delete many ColorNorm documents, return deleted documents */
  deleteManyColorNormsConnection: ColorNormConnection;
  /**
   * Delete many ColorType documents
   * @deprecated Please use the new paginated many mutation (deleteManyColorTypesConnection)
   */
  deleteManyColorTypes: BatchPayload;
  /** Delete many ColorType documents, return deleted documents */
  deleteManyColorTypesConnection: ColorTypeConnection;
  /**
   * Delete many ContentConfiguration documents
   * @deprecated Please use the new paginated many mutation (deleteManyContentConfigurationsConnection)
   */
  deleteManyContentConfigurations: BatchPayload;
  /** Delete many ContentConfiguration documents, return deleted documents */
  deleteManyContentConfigurationsConnection: ContentConfigurationConnection;
  /**
   * Delete many DealerEmployee documents
   * @deprecated Please use the new paginated many mutation (deleteManyDealerEmployeesConnection)
   */
  deleteManyDealerEmployees: BatchPayload;
  /** Delete many DealerEmployee documents, return deleted documents */
  deleteManyDealerEmployeesConnection: DealerEmployeeConnection;
  /**
   * Delete many Dealer documents
   * @deprecated Please use the new paginated many mutation (deleteManyDealersConnection)
   */
  deleteManyDealers: BatchPayload;
  /** Delete many Dealer documents, return deleted documents */
  deleteManyDealersConnection: DealerConnection;
  /**
   * Delete many Department documents
   * @deprecated Please use the new paginated many mutation (deleteManyDepartmentsConnection)
   */
  deleteManyDepartments: BatchPayload;
  /** Delete many Department documents, return deleted documents */
  deleteManyDepartmentsConnection: DepartmentConnection;
  /**
   * Delete many DriveType documents
   * @deprecated Please use the new paginated many mutation (deleteManyDriveTypesConnection)
   */
  deleteManyDriveTypes: BatchPayload;
  /** Delete many DriveType documents, return deleted documents */
  deleteManyDriveTypesConnection: DriveTypeConnection;
  /**
   * Delete many EmissionClass documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmissionClassesConnection)
   */
  deleteManyEmissionClasses: BatchPayload;
  /** Delete many EmissionClass documents, return deleted documents */
  deleteManyEmissionClassesConnection: EmissionClassConnection;
  /**
   * Delete many Employee documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmployeesConnection)
   */
  deleteManyEmployees: BatchPayload;
  /** Delete many Employee documents, return deleted documents */
  deleteManyEmployeesConnection: EmployeeConnection;
  /**
   * Delete many FactList documents
   * @deprecated Please use the new paginated many mutation (deleteManyFactListsConnection)
   */
  deleteManyFactLists: BatchPayload;
  /** Delete many FactList documents, return deleted documents */
  deleteManyFactListsConnection: FactListConnection;
  /**
   * Delete many FeaturedVehiclesRow documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedVehiclesRowsConnection)
   */
  deleteManyFeaturedVehiclesRows: BatchPayload;
  /** Delete many FeaturedVehiclesRow documents, return deleted documents */
  deleteManyFeaturedVehiclesRowsConnection: FeaturedVehiclesRowConnection;
  /**
   * Delete many FuelTypeGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyFuelTypeGroupsConnection)
   */
  deleteManyFuelTypeGroups: BatchPayload;
  /** Delete many FuelTypeGroup documents, return deleted documents */
  deleteManyFuelTypeGroupsConnection: FuelTypeGroupConnection;
  /**
   * Delete many FuelType documents
   * @deprecated Please use the new paginated many mutation (deleteManyFuelTypesConnection)
   */
  deleteManyFuelTypes: BatchPayload;
  /** Delete many FuelType documents, return deleted documents */
  deleteManyFuelTypesConnection: FuelTypeConnection;
  /**
   * Delete many Gallery documents
   * @deprecated Please use the new paginated many mutation (deleteManyGalleriesConnection)
   */
  deleteManyGalleries: BatchPayload;
  /** Delete many Gallery documents, return deleted documents */
  deleteManyGalleriesConnection: GalleryConnection;
  /**
   * Delete many GeneratedPageExtension documents
   * @deprecated Please use the new paginated many mutation (deleteManyGeneratedPageExtensionsConnection)
   */
  deleteManyGeneratedPageExtensions: BatchPayload;
  /** Delete many GeneratedPageExtension documents, return deleted documents */
  deleteManyGeneratedPageExtensionsConnection: GeneratedPageExtensionConnection;
  /**
   * Delete many HeroSlider documents
   * @deprecated Please use the new paginated many mutation (deleteManyHeroSlidersConnection)
   */
  deleteManyHeroSliders: BatchPayload;
  /** Delete many HeroSlider documents, return deleted documents */
  deleteManyHeroSlidersConnection: HeroSliderConnection;
  /**
   * Delete many ImageTextComboList documents
   * @deprecated Please use the new paginated many mutation (deleteManyImageTextComboListsConnection)
   */
  deleteManyImageTextComboLists: BatchPayload;
  /** Delete many ImageTextComboList documents, return deleted documents */
  deleteManyImageTextComboListsConnection: ImageTextComboListConnection;
  /**
   * Delete many Image documents
   * @deprecated Please use the new paginated many mutation (deleteManyImagesConnection)
   */
  deleteManyImages: BatchPayload;
  /** Delete many Image documents, return deleted documents */
  deleteManyImagesConnection: ImageConnection;
  /**
   * Delete many KeywordList documents
   * @deprecated Please use the new paginated many mutation (deleteManyKeywordListsConnection)
   */
  deleteManyKeywordLists: BatchPayload;
  /** Delete many KeywordList documents, return deleted documents */
  deleteManyKeywordListsConnection: KeywordListConnection;
  /**
   * Delete many Model documents
   * @deprecated Please use the new paginated many mutation (deleteManyModelsConnection)
   */
  deleteManyModels: BatchPayload;
  /** Delete many Model documents, return deleted documents */
  deleteManyModelsConnection: ModelConnection;
  /**
   * Delete many OptionFacet documents
   * @deprecated Please use the new paginated many mutation (deleteManyOptionFacetsConnection)
   */
  deleteManyOptionFacets: BatchPayload;
  /** Delete many OptionFacet documents, return deleted documents */
  deleteManyOptionFacetsConnection: OptionFacetConnection;
  /**
   * Delete many PaPromotion documents
   * @deprecated Please use the new paginated many mutation (deleteManyPaPromotionsConnection)
   */
  deleteManyPaPromotions: BatchPayload;
  /** Delete many PaPromotion documents, return deleted documents */
  deleteManyPaPromotionsConnection: PaPromotionConnection;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: BatchPayload;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: PageConnection;
  /**
   * Delete many PollutionClass documents
   * @deprecated Please use the new paginated many mutation (deleteManyPollutionClassesConnection)
   */
  deleteManyPollutionClasses: BatchPayload;
  /** Delete many PollutionClass documents, return deleted documents */
  deleteManyPollutionClassesConnection: PollutionClassConnection;
  /**
   * Delete many QualityChecked documents
   * @deprecated Please use the new paginated many mutation (deleteManyQualityCheckedsConnection)
   */
  deleteManyQualityCheckeds: BatchPayload;
  /** Delete many QualityChecked documents, return deleted documents */
  deleteManyQualityCheckedsConnection: QualityCheckedConnection;
  /**
   * Delete many QuestionAndAnswerList documents
   * @deprecated Please use the new paginated many mutation (deleteManyQuestionAndAnswerListsConnection)
   */
  deleteManyQuestionAndAnswerLists: BatchPayload;
  /** Delete many QuestionAndAnswerList documents, return deleted documents */
  deleteManyQuestionAndAnswerListsConnection: QuestionAndAnswerListConnection;
  /**
   * Delete many QuestionAndAnswer documents
   * @deprecated Please use the new paginated many mutation (deleteManyQuestionAndAnswersConnection)
   */
  deleteManyQuestionAndAnswers: BatchPayload;
  /** Delete many QuestionAndAnswer documents, return deleted documents */
  deleteManyQuestionAndAnswersConnection: QuestionAndAnswerConnection;
  /**
   * Delete many Root documents
   * @deprecated Please use the new paginated many mutation (deleteManyRootsConnection)
   */
  deleteManyRoots: BatchPayload;
  /** Delete many Root documents, return deleted documents */
  deleteManyRootsConnection: RootConnection;
  /**
   * Delete many Service documents
   * @deprecated Please use the new paginated many mutation (deleteManyServicesConnection)
   */
  deleteManyServices: BatchPayload;
  /** Delete many Service documents, return deleted documents */
  deleteManyServicesConnection: ServiceConnection;
  /**
   * Delete many SpecialBox documents
   * @deprecated Please use the new paginated many mutation (deleteManySpecialBoxesConnection)
   */
  deleteManySpecialBoxes: BatchPayload;
  /** Delete many SpecialBox documents, return deleted documents */
  deleteManySpecialBoxesConnection: SpecialBoxConnection;
  /**
   * Delete many TransmissionGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyTransmissionGroupsConnection)
   */
  deleteManyTransmissionGroups: BatchPayload;
  /** Delete many TransmissionGroup documents, return deleted documents */
  deleteManyTransmissionGroupsConnection: TransmissionGroupConnection;
  /**
   * Delete many Transmission documents
   * @deprecated Please use the new paginated many mutation (deleteManyTransmissionsConnection)
   */
  deleteManyTransmissions: BatchPayload;
  /** Delete many Transmission documents, return deleted documents */
  deleteManyTransmissionsConnection: TransmissionConnection;
  /**
   * Delete many Upholstery documents
   * @deprecated Please use the new paginated many mutation (deleteManyUpholsterysConnection)
   */
  deleteManyUpholsterys: BatchPayload;
  /** Delete many Upholstery documents, return deleted documents */
  deleteManyUpholsterysConnection: UpholsteryConnection;
  /**
   * Delete many VehicleClass documents
   * @deprecated Please use the new paginated many mutation (deleteManyVehicleClassesConnection)
   */
  deleteManyVehicleClasses: BatchPayload;
  /** Delete many VehicleClass documents, return deleted documents */
  deleteManyVehicleClassesConnection: VehicleClassConnection;
  /**
   * Delete many VehicleCondition documents
   * @deprecated Please use the new paginated many mutation (deleteManyVehicleConditionsConnection)
   */
  deleteManyVehicleConditions: BatchPayload;
  /** Delete many VehicleCondition documents, return deleted documents */
  deleteManyVehicleConditionsConnection: VehicleConditionConnection;
  /**
   * Delete many VehicleStatus documents
   * @deprecated Please use the new paginated many mutation (deleteManyVehicleStatusesConnection)
   */
  deleteManyVehicleStatuses: BatchPayload;
  /** Delete many VehicleStatus documents, return deleted documents */
  deleteManyVehicleStatusesConnection: VehicleStatusConnection;
  /**
   * Delete many VehicleType documents
   * @deprecated Please use the new paginated many mutation (deleteManyVehicleTypesConnection)
   */
  deleteManyVehicleTypes: BatchPayload;
  /** Delete many VehicleType documents, return deleted documents */
  deleteManyVehicleTypesConnection: VehicleTypeConnection;
  /**
   * Delete many Vehicle documents
   * @deprecated Please use the new paginated many mutation (deleteManyVehiclesConnection)
   */
  deleteManyVehicles: BatchPayload;
  /** Delete many Vehicle documents, return deleted documents */
  deleteManyVehiclesConnection: VehicleConnection;
  /**
   * Delete many Video documents
   * @deprecated Please use the new paginated many mutation (deleteManyVideosConnection)
   */
  deleteManyVideos: BatchPayload;
  /** Delete many Video documents, return deleted documents */
  deleteManyVideosConnection: VideoConnection;
  /**
   * Delete many Whitelabel documents
   * @deprecated Please use the new paginated many mutation (deleteManyWhitelabelsConnection)
   */
  deleteManyWhitelabels: BatchPayload;
  /** Delete many Whitelabel documents, return deleted documents */
  deleteManyWhitelabelsConnection: WhitelabelConnection;
  /** Delete one model from _all_ existing stages. Returns deleted document. */
  deleteModel?: Maybe<Model>;
  /** Delete one optionFacet from _all_ existing stages. Returns deleted document. */
  deleteOptionFacet?: Maybe<OptionFacet>;
  /** Delete one paPromotion from _all_ existing stages. Returns deleted document. */
  deletePaPromotion?: Maybe<PaPromotion>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage?: Maybe<Page>;
  /** Delete one pollutionClass from _all_ existing stages. Returns deleted document. */
  deletePollutionClass?: Maybe<PollutionClass>;
  /** Delete one qualityChecked from _all_ existing stages. Returns deleted document. */
  deleteQualityChecked?: Maybe<QualityChecked>;
  /** Delete one questionAndAnswer from _all_ existing stages. Returns deleted document. */
  deleteQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Delete one questionAndAnswerList from _all_ existing stages. Returns deleted document. */
  deleteQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Delete one root from _all_ existing stages. Returns deleted document. */
  deleteRoot?: Maybe<Root>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one service from _all_ existing stages. Returns deleted document. */
  deleteService?: Maybe<Service>;
  /** Delete one specialBox from _all_ existing stages. Returns deleted document. */
  deleteSpecialBox?: Maybe<SpecialBox>;
  /** Delete one transmission from _all_ existing stages. Returns deleted document. */
  deleteTransmission?: Maybe<Transmission>;
  /** Delete one transmissionGroup from _all_ existing stages. Returns deleted document. */
  deleteTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Delete one upholstery from _all_ existing stages. Returns deleted document. */
  deleteUpholstery?: Maybe<Upholstery>;
  /** Delete one vehicle from _all_ existing stages. Returns deleted document. */
  deleteVehicle?: Maybe<Vehicle>;
  /** Delete one vehicleClass from _all_ existing stages. Returns deleted document. */
  deleteVehicleClass?: Maybe<VehicleClass>;
  /** Delete one vehicleCondition from _all_ existing stages. Returns deleted document. */
  deleteVehicleCondition?: Maybe<VehicleCondition>;
  /** Delete one vehicleStatus from _all_ existing stages. Returns deleted document. */
  deleteVehicleStatus?: Maybe<VehicleStatus>;
  /** Delete one vehicleType from _all_ existing stages. Returns deleted document. */
  deleteVehicleType?: Maybe<VehicleType>;
  /** Delete one video from _all_ existing stages. Returns deleted document. */
  deleteVideo?: Maybe<Video>;
  /** Delete one whitelabel from _all_ existing stages. Returns deleted document. */
  deleteWhitelabel?: Maybe<Whitelabel>;
  /** Publish one accordionChapter */
  publishAccordionChapter?: Maybe<AccordionChapter>;
  /** Publish one accordionChapterList */
  publishAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Publish one accordionSmlList */
  publishAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Publish one ad */
  publishAd?: Maybe<Ad>;
  /** Publish one article */
  publishArticle?: Maybe<Article>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one banner */
  publishBanner?: Maybe<Banner>;
  /** Publish one bodyType */
  publishBodyType?: Maybe<BodyType>;
  /** Publish one bodyTypeGroup */
  publishBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Publish one brand */
  publishBrand?: Maybe<Brand>;
  /** Publish one button */
  publishButton?: Maybe<Button>;
  /** Publish one buttonRow */
  publishButtonRow?: Maybe<ButtonRow>;
  /** Publish one citatum */
  publishCitatum?: Maybe<Citatum>;
  /** Publish one colorNorm */
  publishColorNorm?: Maybe<ColorNorm>;
  /** Publish one colorType */
  publishColorType?: Maybe<ColorType>;
  /** Publish one contentConfiguration */
  publishContentConfiguration?: Maybe<ContentConfiguration>;
  /** Publish one dealer */
  publishDealer?: Maybe<Dealer>;
  /** Publish one dealerEmployee */
  publishDealerEmployee?: Maybe<DealerEmployee>;
  /** Publish one department */
  publishDepartment?: Maybe<Department>;
  /** Publish one driveType */
  publishDriveType?: Maybe<DriveType>;
  /** Publish one emissionClass */
  publishEmissionClass?: Maybe<EmissionClass>;
  /** Publish one employee */
  publishEmployee?: Maybe<Employee>;
  /** Publish one factList */
  publishFactList?: Maybe<FactList>;
  /** Publish one featuredVehiclesRow */
  publishFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Publish one fuelType */
  publishFuelType?: Maybe<FuelType>;
  /** Publish one fuelTypeGroup */
  publishFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Publish one gallery */
  publishGallery?: Maybe<Gallery>;
  /** Publish one generatedPageExtension */
  publishGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Publish one heroSlider */
  publishHeroSlider?: Maybe<HeroSlider>;
  /** Publish one image */
  publishImage?: Maybe<Image>;
  /** Publish one imageTextComboList */
  publishImageTextComboList?: Maybe<ImageTextComboList>;
  /** Publish one keywordList */
  publishKeywordList?: Maybe<KeywordList>;
  /**
   * Publish many AccordionChapterList documents
   * @deprecated Please use the new paginated many mutation (publishManyAccordionChapterListsConnection)
   */
  publishManyAccordionChapterLists: BatchPayload;
  /** Publish many AccordionChapterList documents */
  publishManyAccordionChapterListsConnection: AccordionChapterListConnection;
  /**
   * Publish many AccordionChapter documents
   * @deprecated Please use the new paginated many mutation (publishManyAccordionChaptersConnection)
   */
  publishManyAccordionChapters: BatchPayload;
  /** Publish many AccordionChapter documents */
  publishManyAccordionChaptersConnection: AccordionChapterConnection;
  /**
   * Publish many AccordionSmlList documents
   * @deprecated Please use the new paginated many mutation (publishManyAccordionSmlListsConnection)
   */
  publishManyAccordionSmlLists: BatchPayload;
  /** Publish many AccordionSmlList documents */
  publishManyAccordionSmlListsConnection: AccordionSmlListConnection;
  /**
   * Publish many Ad documents
   * @deprecated Please use the new paginated many mutation (publishManyAdsConnection)
   */
  publishManyAds: BatchPayload;
  /** Publish many Ad documents */
  publishManyAdsConnection: AdConnection;
  /**
   * Publish many Article documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlesConnection)
   */
  publishManyArticles: BatchPayload;
  /** Publish many Article documents */
  publishManyArticlesConnection: ArticleConnection;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many Banner documents
   * @deprecated Please use the new paginated many mutation (publishManyBannersConnection)
   */
  publishManyBanners: BatchPayload;
  /** Publish many Banner documents */
  publishManyBannersConnection: BannerConnection;
  /**
   * Publish many BodyTypeGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyBodyTypeGroupsConnection)
   */
  publishManyBodyTypeGroups: BatchPayload;
  /** Publish many BodyTypeGroup documents */
  publishManyBodyTypeGroupsConnection: BodyTypeGroupConnection;
  /**
   * Publish many BodyType documents
   * @deprecated Please use the new paginated many mutation (publishManyBodyTypesConnection)
   */
  publishManyBodyTypes: BatchPayload;
  /** Publish many BodyType documents */
  publishManyBodyTypesConnection: BodyTypeConnection;
  /**
   * Publish many Brand documents
   * @deprecated Please use the new paginated many mutation (publishManyBrandsConnection)
   */
  publishManyBrands: BatchPayload;
  /** Publish many Brand documents */
  publishManyBrandsConnection: BrandConnection;
  /**
   * Publish many ButtonRow documents
   * @deprecated Please use the new paginated many mutation (publishManyButtonRowsConnection)
   */
  publishManyButtonRows: BatchPayload;
  /** Publish many ButtonRow documents */
  publishManyButtonRowsConnection: ButtonRowConnection;
  /**
   * Publish many Button documents
   * @deprecated Please use the new paginated many mutation (publishManyButtonsConnection)
   */
  publishManyButtons: BatchPayload;
  /** Publish many Button documents */
  publishManyButtonsConnection: ButtonConnection;
  /**
   * Publish many Citatum documents
   * @deprecated Please use the new paginated many mutation (publishManyCitatumsConnection)
   */
  publishManyCitatums: BatchPayload;
  /** Publish many Citatum documents */
  publishManyCitatumsConnection: CitatumConnection;
  /**
   * Publish many ColorNorm documents
   * @deprecated Please use the new paginated many mutation (publishManyColorNormsConnection)
   */
  publishManyColorNorms: BatchPayload;
  /** Publish many ColorNorm documents */
  publishManyColorNormsConnection: ColorNormConnection;
  /**
   * Publish many ColorType documents
   * @deprecated Please use the new paginated many mutation (publishManyColorTypesConnection)
   */
  publishManyColorTypes: BatchPayload;
  /** Publish many ColorType documents */
  publishManyColorTypesConnection: ColorTypeConnection;
  /**
   * Publish many ContentConfiguration documents
   * @deprecated Please use the new paginated many mutation (publishManyContentConfigurationsConnection)
   */
  publishManyContentConfigurations: BatchPayload;
  /** Publish many ContentConfiguration documents */
  publishManyContentConfigurationsConnection: ContentConfigurationConnection;
  /**
   * Publish many DealerEmployee documents
   * @deprecated Please use the new paginated many mutation (publishManyDealerEmployeesConnection)
   */
  publishManyDealerEmployees: BatchPayload;
  /** Publish many DealerEmployee documents */
  publishManyDealerEmployeesConnection: DealerEmployeeConnection;
  /**
   * Publish many Dealer documents
   * @deprecated Please use the new paginated many mutation (publishManyDealersConnection)
   */
  publishManyDealers: BatchPayload;
  /** Publish many Dealer documents */
  publishManyDealersConnection: DealerConnection;
  /**
   * Publish many Department documents
   * @deprecated Please use the new paginated many mutation (publishManyDepartmentsConnection)
   */
  publishManyDepartments: BatchPayload;
  /** Publish many Department documents */
  publishManyDepartmentsConnection: DepartmentConnection;
  /**
   * Publish many DriveType documents
   * @deprecated Please use the new paginated many mutation (publishManyDriveTypesConnection)
   */
  publishManyDriveTypes: BatchPayload;
  /** Publish many DriveType documents */
  publishManyDriveTypesConnection: DriveTypeConnection;
  /**
   * Publish many EmissionClass documents
   * @deprecated Please use the new paginated many mutation (publishManyEmissionClassesConnection)
   */
  publishManyEmissionClasses: BatchPayload;
  /** Publish many EmissionClass documents */
  publishManyEmissionClassesConnection: EmissionClassConnection;
  /**
   * Publish many Employee documents
   * @deprecated Please use the new paginated many mutation (publishManyEmployeesConnection)
   */
  publishManyEmployees: BatchPayload;
  /** Publish many Employee documents */
  publishManyEmployeesConnection: EmployeeConnection;
  /**
   * Publish many FactList documents
   * @deprecated Please use the new paginated many mutation (publishManyFactListsConnection)
   */
  publishManyFactLists: BatchPayload;
  /** Publish many FactList documents */
  publishManyFactListsConnection: FactListConnection;
  /**
   * Publish many FeaturedVehiclesRow documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedVehiclesRowsConnection)
   */
  publishManyFeaturedVehiclesRows: BatchPayload;
  /** Publish many FeaturedVehiclesRow documents */
  publishManyFeaturedVehiclesRowsConnection: FeaturedVehiclesRowConnection;
  /**
   * Publish many FuelTypeGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyFuelTypeGroupsConnection)
   */
  publishManyFuelTypeGroups: BatchPayload;
  /** Publish many FuelTypeGroup documents */
  publishManyFuelTypeGroupsConnection: FuelTypeGroupConnection;
  /**
   * Publish many FuelType documents
   * @deprecated Please use the new paginated many mutation (publishManyFuelTypesConnection)
   */
  publishManyFuelTypes: BatchPayload;
  /** Publish many FuelType documents */
  publishManyFuelTypesConnection: FuelTypeConnection;
  /**
   * Publish many Gallery documents
   * @deprecated Please use the new paginated many mutation (publishManyGalleriesConnection)
   */
  publishManyGalleries: BatchPayload;
  /** Publish many Gallery documents */
  publishManyGalleriesConnection: GalleryConnection;
  /**
   * Publish many GeneratedPageExtension documents
   * @deprecated Please use the new paginated many mutation (publishManyGeneratedPageExtensionsConnection)
   */
  publishManyGeneratedPageExtensions: BatchPayload;
  /** Publish many GeneratedPageExtension documents */
  publishManyGeneratedPageExtensionsConnection: GeneratedPageExtensionConnection;
  /**
   * Publish many HeroSlider documents
   * @deprecated Please use the new paginated many mutation (publishManyHeroSlidersConnection)
   */
  publishManyHeroSliders: BatchPayload;
  /** Publish many HeroSlider documents */
  publishManyHeroSlidersConnection: HeroSliderConnection;
  /**
   * Publish many ImageTextComboList documents
   * @deprecated Please use the new paginated many mutation (publishManyImageTextComboListsConnection)
   */
  publishManyImageTextComboLists: BatchPayload;
  /** Publish many ImageTextComboList documents */
  publishManyImageTextComboListsConnection: ImageTextComboListConnection;
  /**
   * Publish many Image documents
   * @deprecated Please use the new paginated many mutation (publishManyImagesConnection)
   */
  publishManyImages: BatchPayload;
  /** Publish many Image documents */
  publishManyImagesConnection: ImageConnection;
  /**
   * Publish many KeywordList documents
   * @deprecated Please use the new paginated many mutation (publishManyKeywordListsConnection)
   */
  publishManyKeywordLists: BatchPayload;
  /** Publish many KeywordList documents */
  publishManyKeywordListsConnection: KeywordListConnection;
  /**
   * Publish many Model documents
   * @deprecated Please use the new paginated many mutation (publishManyModelsConnection)
   */
  publishManyModels: BatchPayload;
  /** Publish many Model documents */
  publishManyModelsConnection: ModelConnection;
  /**
   * Publish many OptionFacet documents
   * @deprecated Please use the new paginated many mutation (publishManyOptionFacetsConnection)
   */
  publishManyOptionFacets: BatchPayload;
  /** Publish many OptionFacet documents */
  publishManyOptionFacetsConnection: OptionFacetConnection;
  /**
   * Publish many PaPromotion documents
   * @deprecated Please use the new paginated many mutation (publishManyPaPromotionsConnection)
   */
  publishManyPaPromotions: BatchPayload;
  /** Publish many PaPromotion documents */
  publishManyPaPromotionsConnection: PaPromotionConnection;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: BatchPayload;
  /** Publish many Page documents */
  publishManyPagesConnection: PageConnection;
  /**
   * Publish many PollutionClass documents
   * @deprecated Please use the new paginated many mutation (publishManyPollutionClassesConnection)
   */
  publishManyPollutionClasses: BatchPayload;
  /** Publish many PollutionClass documents */
  publishManyPollutionClassesConnection: PollutionClassConnection;
  /**
   * Publish many QualityChecked documents
   * @deprecated Please use the new paginated many mutation (publishManyQualityCheckedsConnection)
   */
  publishManyQualityCheckeds: BatchPayload;
  /** Publish many QualityChecked documents */
  publishManyQualityCheckedsConnection: QualityCheckedConnection;
  /**
   * Publish many QuestionAndAnswerList documents
   * @deprecated Please use the new paginated many mutation (publishManyQuestionAndAnswerListsConnection)
   */
  publishManyQuestionAndAnswerLists: BatchPayload;
  /** Publish many QuestionAndAnswerList documents */
  publishManyQuestionAndAnswerListsConnection: QuestionAndAnswerListConnection;
  /**
   * Publish many QuestionAndAnswer documents
   * @deprecated Please use the new paginated many mutation (publishManyQuestionAndAnswersConnection)
   */
  publishManyQuestionAndAnswers: BatchPayload;
  /** Publish many QuestionAndAnswer documents */
  publishManyQuestionAndAnswersConnection: QuestionAndAnswerConnection;
  /**
   * Publish many Root documents
   * @deprecated Please use the new paginated many mutation (publishManyRootsConnection)
   */
  publishManyRoots: BatchPayload;
  /** Publish many Root documents */
  publishManyRootsConnection: RootConnection;
  /**
   * Publish many Service documents
   * @deprecated Please use the new paginated many mutation (publishManyServicesConnection)
   */
  publishManyServices: BatchPayload;
  /** Publish many Service documents */
  publishManyServicesConnection: ServiceConnection;
  /**
   * Publish many SpecialBox documents
   * @deprecated Please use the new paginated many mutation (publishManySpecialBoxesConnection)
   */
  publishManySpecialBoxes: BatchPayload;
  /** Publish many SpecialBox documents */
  publishManySpecialBoxesConnection: SpecialBoxConnection;
  /**
   * Publish many TransmissionGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyTransmissionGroupsConnection)
   */
  publishManyTransmissionGroups: BatchPayload;
  /** Publish many TransmissionGroup documents */
  publishManyTransmissionGroupsConnection: TransmissionGroupConnection;
  /**
   * Publish many Transmission documents
   * @deprecated Please use the new paginated many mutation (publishManyTransmissionsConnection)
   */
  publishManyTransmissions: BatchPayload;
  /** Publish many Transmission documents */
  publishManyTransmissionsConnection: TransmissionConnection;
  /**
   * Publish many Upholstery documents
   * @deprecated Please use the new paginated many mutation (publishManyUpholsterysConnection)
   */
  publishManyUpholsterys: BatchPayload;
  /** Publish many Upholstery documents */
  publishManyUpholsterysConnection: UpholsteryConnection;
  /**
   * Publish many VehicleClass documents
   * @deprecated Please use the new paginated many mutation (publishManyVehicleClassesConnection)
   */
  publishManyVehicleClasses: BatchPayload;
  /** Publish many VehicleClass documents */
  publishManyVehicleClassesConnection: VehicleClassConnection;
  /**
   * Publish many VehicleCondition documents
   * @deprecated Please use the new paginated many mutation (publishManyVehicleConditionsConnection)
   */
  publishManyVehicleConditions: BatchPayload;
  /** Publish many VehicleCondition documents */
  publishManyVehicleConditionsConnection: VehicleConditionConnection;
  /**
   * Publish many VehicleStatus documents
   * @deprecated Please use the new paginated many mutation (publishManyVehicleStatusesConnection)
   */
  publishManyVehicleStatuses: BatchPayload;
  /** Publish many VehicleStatus documents */
  publishManyVehicleStatusesConnection: VehicleStatusConnection;
  /**
   * Publish many VehicleType documents
   * @deprecated Please use the new paginated many mutation (publishManyVehicleTypesConnection)
   */
  publishManyVehicleTypes: BatchPayload;
  /** Publish many VehicleType documents */
  publishManyVehicleTypesConnection: VehicleTypeConnection;
  /**
   * Publish many Vehicle documents
   * @deprecated Please use the new paginated many mutation (publishManyVehiclesConnection)
   */
  publishManyVehicles: BatchPayload;
  /** Publish many Vehicle documents */
  publishManyVehiclesConnection: VehicleConnection;
  /**
   * Publish many Video documents
   * @deprecated Please use the new paginated many mutation (publishManyVideosConnection)
   */
  publishManyVideos: BatchPayload;
  /** Publish many Video documents */
  publishManyVideosConnection: VideoConnection;
  /**
   * Publish many Whitelabel documents
   * @deprecated Please use the new paginated many mutation (publishManyWhitelabelsConnection)
   */
  publishManyWhitelabels: BatchPayload;
  /** Publish many Whitelabel documents */
  publishManyWhitelabelsConnection: WhitelabelConnection;
  /** Publish one model */
  publishModel?: Maybe<Model>;
  /** Publish one optionFacet */
  publishOptionFacet?: Maybe<OptionFacet>;
  /** Publish one paPromotion */
  publishPaPromotion?: Maybe<PaPromotion>;
  /** Publish one page */
  publishPage?: Maybe<Page>;
  /** Publish one pollutionClass */
  publishPollutionClass?: Maybe<PollutionClass>;
  /** Publish one qualityChecked */
  publishQualityChecked?: Maybe<QualityChecked>;
  /** Publish one questionAndAnswer */
  publishQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Publish one questionAndAnswerList */
  publishQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Publish one root */
  publishRoot?: Maybe<Root>;
  /** Publish one service */
  publishService?: Maybe<Service>;
  /** Publish one specialBox */
  publishSpecialBox?: Maybe<SpecialBox>;
  /** Publish one transmission */
  publishTransmission?: Maybe<Transmission>;
  /** Publish one transmissionGroup */
  publishTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Publish one upholstery */
  publishUpholstery?: Maybe<Upholstery>;
  /** Publish one vehicle */
  publishVehicle?: Maybe<Vehicle>;
  /** Publish one vehicleClass */
  publishVehicleClass?: Maybe<VehicleClass>;
  /** Publish one vehicleCondition */
  publishVehicleCondition?: Maybe<VehicleCondition>;
  /** Publish one vehicleStatus */
  publishVehicleStatus?: Maybe<VehicleStatus>;
  /** Publish one vehicleType */
  publishVehicleType?: Maybe<VehicleType>;
  /** Publish one video */
  publishVideo?: Maybe<Video>;
  /** Publish one whitelabel */
  publishWhitelabel?: Maybe<Whitelabel>;
  /** Schedule to publish one accordionChapter */
  schedulePublishAccordionChapter?: Maybe<AccordionChapter>;
  /** Schedule to publish one accordionChapterList */
  schedulePublishAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Schedule to publish one accordionSmlList */
  schedulePublishAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Schedule to publish one ad */
  schedulePublishAd?: Maybe<Ad>;
  /** Schedule to publish one article */
  schedulePublishArticle?: Maybe<Article>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one banner */
  schedulePublishBanner?: Maybe<Banner>;
  /** Schedule to publish one bodyType */
  schedulePublishBodyType?: Maybe<BodyType>;
  /** Schedule to publish one bodyTypeGroup */
  schedulePublishBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Schedule to publish one brand */
  schedulePublishBrand?: Maybe<Brand>;
  /** Schedule to publish one button */
  schedulePublishButton?: Maybe<Button>;
  /** Schedule to publish one buttonRow */
  schedulePublishButtonRow?: Maybe<ButtonRow>;
  /** Schedule to publish one citatum */
  schedulePublishCitatum?: Maybe<Citatum>;
  /** Schedule to publish one colorNorm */
  schedulePublishColorNorm?: Maybe<ColorNorm>;
  /** Schedule to publish one colorType */
  schedulePublishColorType?: Maybe<ColorType>;
  /** Schedule to publish one contentConfiguration */
  schedulePublishContentConfiguration?: Maybe<ContentConfiguration>;
  /** Schedule to publish one dealer */
  schedulePublishDealer?: Maybe<Dealer>;
  /** Schedule to publish one dealerEmployee */
  schedulePublishDealerEmployee?: Maybe<DealerEmployee>;
  /** Schedule to publish one department */
  schedulePublishDepartment?: Maybe<Department>;
  /** Schedule to publish one driveType */
  schedulePublishDriveType?: Maybe<DriveType>;
  /** Schedule to publish one emissionClass */
  schedulePublishEmissionClass?: Maybe<EmissionClass>;
  /** Schedule to publish one employee */
  schedulePublishEmployee?: Maybe<Employee>;
  /** Schedule to publish one factList */
  schedulePublishFactList?: Maybe<FactList>;
  /** Schedule to publish one featuredVehiclesRow */
  schedulePublishFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Schedule to publish one fuelType */
  schedulePublishFuelType?: Maybe<FuelType>;
  /** Schedule to publish one fuelTypeGroup */
  schedulePublishFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Schedule to publish one gallery */
  schedulePublishGallery?: Maybe<Gallery>;
  /** Schedule to publish one generatedPageExtension */
  schedulePublishGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Schedule to publish one heroSlider */
  schedulePublishHeroSlider?: Maybe<HeroSlider>;
  /** Schedule to publish one image */
  schedulePublishImage?: Maybe<Image>;
  /** Schedule to publish one imageTextComboList */
  schedulePublishImageTextComboList?: Maybe<ImageTextComboList>;
  /** Schedule to publish one keywordList */
  schedulePublishKeywordList?: Maybe<KeywordList>;
  /** Schedule to publish one model */
  schedulePublishModel?: Maybe<Model>;
  /** Schedule to publish one optionFacet */
  schedulePublishOptionFacet?: Maybe<OptionFacet>;
  /** Schedule to publish one paPromotion */
  schedulePublishPaPromotion?: Maybe<PaPromotion>;
  /** Schedule to publish one page */
  schedulePublishPage?: Maybe<Page>;
  /** Schedule to publish one pollutionClass */
  schedulePublishPollutionClass?: Maybe<PollutionClass>;
  /** Schedule to publish one qualityChecked */
  schedulePublishQualityChecked?: Maybe<QualityChecked>;
  /** Schedule to publish one questionAndAnswer */
  schedulePublishQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Schedule to publish one questionAndAnswerList */
  schedulePublishQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Schedule to publish one root */
  schedulePublishRoot?: Maybe<Root>;
  /** Schedule to publish one service */
  schedulePublishService?: Maybe<Service>;
  /** Schedule to publish one specialBox */
  schedulePublishSpecialBox?: Maybe<SpecialBox>;
  /** Schedule to publish one transmission */
  schedulePublishTransmission?: Maybe<Transmission>;
  /** Schedule to publish one transmissionGroup */
  schedulePublishTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Schedule to publish one upholstery */
  schedulePublishUpholstery?: Maybe<Upholstery>;
  /** Schedule to publish one vehicle */
  schedulePublishVehicle?: Maybe<Vehicle>;
  /** Schedule to publish one vehicleClass */
  schedulePublishVehicleClass?: Maybe<VehicleClass>;
  /** Schedule to publish one vehicleCondition */
  schedulePublishVehicleCondition?: Maybe<VehicleCondition>;
  /** Schedule to publish one vehicleStatus */
  schedulePublishVehicleStatus?: Maybe<VehicleStatus>;
  /** Schedule to publish one vehicleType */
  schedulePublishVehicleType?: Maybe<VehicleType>;
  /** Schedule to publish one video */
  schedulePublishVideo?: Maybe<Video>;
  /** Schedule to publish one whitelabel */
  schedulePublishWhitelabel?: Maybe<Whitelabel>;
  /** Unpublish one accordionChapter from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAccordionChapter?: Maybe<AccordionChapter>;
  /** Unpublish one accordionChapterList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Unpublish one accordionSmlList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Unpublish one ad from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAd?: Maybe<Ad>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticle?: Maybe<Article>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one banner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBanner?: Maybe<Banner>;
  /** Unpublish one bodyType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBodyType?: Maybe<BodyType>;
  /** Unpublish one bodyTypeGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Unpublish one brand from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBrand?: Maybe<Brand>;
  /** Unpublish one button from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishButton?: Maybe<Button>;
  /** Unpublish one buttonRow from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishButtonRow?: Maybe<ButtonRow>;
  /** Unpublish one citatum from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCitatum?: Maybe<Citatum>;
  /** Unpublish one colorNorm from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishColorNorm?: Maybe<ColorNorm>;
  /** Unpublish one colorType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishColorType?: Maybe<ColorType>;
  /** Unpublish one contentConfiguration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishContentConfiguration?: Maybe<ContentConfiguration>;
  /** Unpublish one dealer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDealer?: Maybe<Dealer>;
  /** Unpublish one dealerEmployee from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDealerEmployee?: Maybe<DealerEmployee>;
  /** Unpublish one department from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDepartment?: Maybe<Department>;
  /** Unpublish one driveType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDriveType?: Maybe<DriveType>;
  /** Unpublish one emissionClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmissionClass?: Maybe<EmissionClass>;
  /** Unpublish one employee from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmployee?: Maybe<Employee>;
  /** Unpublish one factList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFactList?: Maybe<FactList>;
  /** Unpublish one featuredVehiclesRow from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Unpublish one fuelType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFuelType?: Maybe<FuelType>;
  /** Unpublish one fuelTypeGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Unpublish one gallery from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGallery?: Maybe<Gallery>;
  /** Unpublish one generatedPageExtension from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Unpublish one heroSlider from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHeroSlider?: Maybe<HeroSlider>;
  /** Unpublish one image from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishImage?: Maybe<Image>;
  /** Unpublish one imageTextComboList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishImageTextComboList?: Maybe<ImageTextComboList>;
  /** Unpublish one keywordList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishKeywordList?: Maybe<KeywordList>;
  /** Unpublish one model from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishModel?: Maybe<Model>;
  /** Unpublish one optionFacet from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishOptionFacet?: Maybe<OptionFacet>;
  /** Unpublish one paPromotion from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPaPromotion?: Maybe<PaPromotion>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage?: Maybe<Page>;
  /** Unpublish one pollutionClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPollutionClass?: Maybe<PollutionClass>;
  /** Unpublish one qualityChecked from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishQualityChecked?: Maybe<QualityChecked>;
  /** Unpublish one questionAndAnswer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Unpublish one questionAndAnswerList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Unpublish one root from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRoot?: Maybe<Root>;
  /** Unpublish one service from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishService?: Maybe<Service>;
  /** Unpublish one specialBox from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSpecialBox?: Maybe<SpecialBox>;
  /** Unpublish one transmission from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTransmission?: Maybe<Transmission>;
  /** Unpublish one transmissionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Unpublish one upholstery from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishUpholstery?: Maybe<Upholstery>;
  /** Unpublish one vehicle from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVehicle?: Maybe<Vehicle>;
  /** Unpublish one vehicleClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVehicleClass?: Maybe<VehicleClass>;
  /** Unpublish one vehicleCondition from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVehicleCondition?: Maybe<VehicleCondition>;
  /** Unpublish one vehicleStatus from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVehicleStatus?: Maybe<VehicleStatus>;
  /** Unpublish one vehicleType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVehicleType?: Maybe<VehicleType>;
  /** Unpublish one video from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVideo?: Maybe<Video>;
  /** Unpublish one whitelabel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishWhitelabel?: Maybe<Whitelabel>;
  /** Unpublish one accordionChapter from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAccordionChapter?: Maybe<AccordionChapter>;
  /** Unpublish one accordionChapterList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Unpublish one accordionSmlList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Unpublish one ad from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAd?: Maybe<Ad>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticle?: Maybe<Article>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one banner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBanner?: Maybe<Banner>;
  /** Unpublish one bodyType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBodyType?: Maybe<BodyType>;
  /** Unpublish one bodyTypeGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Unpublish one brand from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBrand?: Maybe<Brand>;
  /** Unpublish one button from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishButton?: Maybe<Button>;
  /** Unpublish one buttonRow from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishButtonRow?: Maybe<ButtonRow>;
  /** Unpublish one citatum from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCitatum?: Maybe<Citatum>;
  /** Unpublish one colorNorm from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishColorNorm?: Maybe<ColorNorm>;
  /** Unpublish one colorType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishColorType?: Maybe<ColorType>;
  /** Unpublish one contentConfiguration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishContentConfiguration?: Maybe<ContentConfiguration>;
  /** Unpublish one dealer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDealer?: Maybe<Dealer>;
  /** Unpublish one dealerEmployee from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDealerEmployee?: Maybe<DealerEmployee>;
  /** Unpublish one department from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDepartment?: Maybe<Department>;
  /** Unpublish one driveType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDriveType?: Maybe<DriveType>;
  /** Unpublish one emissionClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmissionClass?: Maybe<EmissionClass>;
  /** Unpublish one employee from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmployee?: Maybe<Employee>;
  /** Unpublish one factList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFactList?: Maybe<FactList>;
  /** Unpublish one featuredVehiclesRow from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Unpublish one fuelType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFuelType?: Maybe<FuelType>;
  /** Unpublish one fuelTypeGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Unpublish one gallery from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGallery?: Maybe<Gallery>;
  /** Unpublish one generatedPageExtension from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Unpublish one heroSlider from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHeroSlider?: Maybe<HeroSlider>;
  /** Unpublish one image from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishImage?: Maybe<Image>;
  /** Unpublish one imageTextComboList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishImageTextComboList?: Maybe<ImageTextComboList>;
  /** Unpublish one keywordList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishKeywordList?: Maybe<KeywordList>;
  /**
   * Unpublish many AccordionChapterList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAccordionChapterListsConnection)
   */
  unpublishManyAccordionChapterLists: BatchPayload;
  /** Find many AccordionChapterList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAccordionChapterListsConnection: AccordionChapterListConnection;
  /**
   * Unpublish many AccordionChapter documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAccordionChaptersConnection)
   */
  unpublishManyAccordionChapters: BatchPayload;
  /** Find many AccordionChapter documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAccordionChaptersConnection: AccordionChapterConnection;
  /**
   * Unpublish many AccordionSmlList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAccordionSmlListsConnection)
   */
  unpublishManyAccordionSmlLists: BatchPayload;
  /** Find many AccordionSmlList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAccordionSmlListsConnection: AccordionSmlListConnection;
  /**
   * Unpublish many Ad documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAdsConnection)
   */
  unpublishManyAds: BatchPayload;
  /** Find many Ad documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAdsConnection: AdConnection;
  /**
   * Unpublish many Article documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlesConnection)
   */
  unpublishManyArticles: BatchPayload;
  /** Find many Article documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlesConnection: ArticleConnection;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many Banner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBannersConnection)
   */
  unpublishManyBanners: BatchPayload;
  /** Find many Banner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBannersConnection: BannerConnection;
  /**
   * Unpublish many BodyTypeGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBodyTypeGroupsConnection)
   */
  unpublishManyBodyTypeGroups: BatchPayload;
  /** Find many BodyTypeGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBodyTypeGroupsConnection: BodyTypeGroupConnection;
  /**
   * Unpublish many BodyType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBodyTypesConnection)
   */
  unpublishManyBodyTypes: BatchPayload;
  /** Find many BodyType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBodyTypesConnection: BodyTypeConnection;
  /**
   * Unpublish many Brand documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBrandsConnection)
   */
  unpublishManyBrands: BatchPayload;
  /** Find many Brand documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBrandsConnection: BrandConnection;
  /**
   * Unpublish many ButtonRow documents
   * @deprecated Please use the new paginated many mutation (unpublishManyButtonRowsConnection)
   */
  unpublishManyButtonRows: BatchPayload;
  /** Find many ButtonRow documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyButtonRowsConnection: ButtonRowConnection;
  /**
   * Unpublish many Button documents
   * @deprecated Please use the new paginated many mutation (unpublishManyButtonsConnection)
   */
  unpublishManyButtons: BatchPayload;
  /** Find many Button documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyButtonsConnection: ButtonConnection;
  /**
   * Unpublish many Citatum documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCitatumsConnection)
   */
  unpublishManyCitatums: BatchPayload;
  /** Find many Citatum documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCitatumsConnection: CitatumConnection;
  /**
   * Unpublish many ColorNorm documents
   * @deprecated Please use the new paginated many mutation (unpublishManyColorNormsConnection)
   */
  unpublishManyColorNorms: BatchPayload;
  /** Find many ColorNorm documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyColorNormsConnection: ColorNormConnection;
  /**
   * Unpublish many ColorType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyColorTypesConnection)
   */
  unpublishManyColorTypes: BatchPayload;
  /** Find many ColorType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyColorTypesConnection: ColorTypeConnection;
  /**
   * Unpublish many ContentConfiguration documents
   * @deprecated Please use the new paginated many mutation (unpublishManyContentConfigurationsConnection)
   */
  unpublishManyContentConfigurations: BatchPayload;
  /** Find many ContentConfiguration documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyContentConfigurationsConnection: ContentConfigurationConnection;
  /**
   * Unpublish many DealerEmployee documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDealerEmployeesConnection)
   */
  unpublishManyDealerEmployees: BatchPayload;
  /** Find many DealerEmployee documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDealerEmployeesConnection: DealerEmployeeConnection;
  /**
   * Unpublish many Dealer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDealersConnection)
   */
  unpublishManyDealers: BatchPayload;
  /** Find many Dealer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDealersConnection: DealerConnection;
  /**
   * Unpublish many Department documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDepartmentsConnection)
   */
  unpublishManyDepartments: BatchPayload;
  /** Find many Department documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDepartmentsConnection: DepartmentConnection;
  /**
   * Unpublish many DriveType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDriveTypesConnection)
   */
  unpublishManyDriveTypes: BatchPayload;
  /** Find many DriveType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDriveTypesConnection: DriveTypeConnection;
  /**
   * Unpublish many EmissionClass documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmissionClassesConnection)
   */
  unpublishManyEmissionClasses: BatchPayload;
  /** Find many EmissionClass documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmissionClassesConnection: EmissionClassConnection;
  /**
   * Unpublish many Employee documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmployeesConnection)
   */
  unpublishManyEmployees: BatchPayload;
  /** Find many Employee documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmployeesConnection: EmployeeConnection;
  /**
   * Unpublish many FactList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFactListsConnection)
   */
  unpublishManyFactLists: BatchPayload;
  /** Find many FactList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFactListsConnection: FactListConnection;
  /**
   * Unpublish many FeaturedVehiclesRow documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedVehiclesRowsConnection)
   */
  unpublishManyFeaturedVehiclesRows: BatchPayload;
  /** Find many FeaturedVehiclesRow documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedVehiclesRowsConnection: FeaturedVehiclesRowConnection;
  /**
   * Unpublish many FuelTypeGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFuelTypeGroupsConnection)
   */
  unpublishManyFuelTypeGroups: BatchPayload;
  /** Find many FuelTypeGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFuelTypeGroupsConnection: FuelTypeGroupConnection;
  /**
   * Unpublish many FuelType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFuelTypesConnection)
   */
  unpublishManyFuelTypes: BatchPayload;
  /** Find many FuelType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFuelTypesConnection: FuelTypeConnection;
  /**
   * Unpublish many Gallery documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGalleriesConnection)
   */
  unpublishManyGalleries: BatchPayload;
  /** Find many Gallery documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGalleriesConnection: GalleryConnection;
  /**
   * Unpublish many GeneratedPageExtension documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGeneratedPageExtensionsConnection)
   */
  unpublishManyGeneratedPageExtensions: BatchPayload;
  /** Find many GeneratedPageExtension documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGeneratedPageExtensionsConnection: GeneratedPageExtensionConnection;
  /**
   * Unpublish many HeroSlider documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHeroSlidersConnection)
   */
  unpublishManyHeroSliders: BatchPayload;
  /** Find many HeroSlider documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHeroSlidersConnection: HeroSliderConnection;
  /**
   * Unpublish many ImageTextComboList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyImageTextComboListsConnection)
   */
  unpublishManyImageTextComboLists: BatchPayload;
  /** Find many ImageTextComboList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyImageTextComboListsConnection: ImageTextComboListConnection;
  /**
   * Unpublish many Image documents
   * @deprecated Please use the new paginated many mutation (unpublishManyImagesConnection)
   */
  unpublishManyImages: BatchPayload;
  /** Find many Image documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyImagesConnection: ImageConnection;
  /**
   * Unpublish many KeywordList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyKeywordListsConnection)
   */
  unpublishManyKeywordLists: BatchPayload;
  /** Find many KeywordList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyKeywordListsConnection: KeywordListConnection;
  /**
   * Unpublish many Model documents
   * @deprecated Please use the new paginated many mutation (unpublishManyModelsConnection)
   */
  unpublishManyModels: BatchPayload;
  /** Find many Model documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyModelsConnection: ModelConnection;
  /**
   * Unpublish many OptionFacet documents
   * @deprecated Please use the new paginated many mutation (unpublishManyOptionFacetsConnection)
   */
  unpublishManyOptionFacets: BatchPayload;
  /** Find many OptionFacet documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyOptionFacetsConnection: OptionFacetConnection;
  /**
   * Unpublish many PaPromotion documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPaPromotionsConnection)
   */
  unpublishManyPaPromotions: BatchPayload;
  /** Find many PaPromotion documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPaPromotionsConnection: PaPromotionConnection;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: BatchPayload;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: PageConnection;
  /**
   * Unpublish many PollutionClass documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPollutionClassesConnection)
   */
  unpublishManyPollutionClasses: BatchPayload;
  /** Find many PollutionClass documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPollutionClassesConnection: PollutionClassConnection;
  /**
   * Unpublish many QualityChecked documents
   * @deprecated Please use the new paginated many mutation (unpublishManyQualityCheckedsConnection)
   */
  unpublishManyQualityCheckeds: BatchPayload;
  /** Find many QualityChecked documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyQualityCheckedsConnection: QualityCheckedConnection;
  /**
   * Unpublish many QuestionAndAnswerList documents
   * @deprecated Please use the new paginated many mutation (unpublishManyQuestionAndAnswerListsConnection)
   */
  unpublishManyQuestionAndAnswerLists: BatchPayload;
  /** Find many QuestionAndAnswerList documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyQuestionAndAnswerListsConnection: QuestionAndAnswerListConnection;
  /**
   * Unpublish many QuestionAndAnswer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyQuestionAndAnswersConnection)
   */
  unpublishManyQuestionAndAnswers: BatchPayload;
  /** Find many QuestionAndAnswer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyQuestionAndAnswersConnection: QuestionAndAnswerConnection;
  /**
   * Unpublish many Root documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRootsConnection)
   */
  unpublishManyRoots: BatchPayload;
  /** Find many Root documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRootsConnection: RootConnection;
  /**
   * Unpublish many Service documents
   * @deprecated Please use the new paginated many mutation (unpublishManyServicesConnection)
   */
  unpublishManyServices: BatchPayload;
  /** Find many Service documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyServicesConnection: ServiceConnection;
  /**
   * Unpublish many SpecialBox documents
   * @deprecated Please use the new paginated many mutation (unpublishManySpecialBoxesConnection)
   */
  unpublishManySpecialBoxes: BatchPayload;
  /** Find many SpecialBox documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySpecialBoxesConnection: SpecialBoxConnection;
  /**
   * Unpublish many TransmissionGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTransmissionGroupsConnection)
   */
  unpublishManyTransmissionGroups: BatchPayload;
  /** Find many TransmissionGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTransmissionGroupsConnection: TransmissionGroupConnection;
  /**
   * Unpublish many Transmission documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTransmissionsConnection)
   */
  unpublishManyTransmissions: BatchPayload;
  /** Find many Transmission documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTransmissionsConnection: TransmissionConnection;
  /**
   * Unpublish many Upholstery documents
   * @deprecated Please use the new paginated many mutation (unpublishManyUpholsterysConnection)
   */
  unpublishManyUpholsterys: BatchPayload;
  /** Find many Upholstery documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyUpholsterysConnection: UpholsteryConnection;
  /**
   * Unpublish many VehicleClass documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVehicleClassesConnection)
   */
  unpublishManyVehicleClasses: BatchPayload;
  /** Find many VehicleClass documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVehicleClassesConnection: VehicleClassConnection;
  /**
   * Unpublish many VehicleCondition documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVehicleConditionsConnection)
   */
  unpublishManyVehicleConditions: BatchPayload;
  /** Find many VehicleCondition documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVehicleConditionsConnection: VehicleConditionConnection;
  /**
   * Unpublish many VehicleStatus documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVehicleStatusesConnection)
   */
  unpublishManyVehicleStatuses: BatchPayload;
  /** Find many VehicleStatus documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVehicleStatusesConnection: VehicleStatusConnection;
  /**
   * Unpublish many VehicleType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVehicleTypesConnection)
   */
  unpublishManyVehicleTypes: BatchPayload;
  /** Find many VehicleType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVehicleTypesConnection: VehicleTypeConnection;
  /**
   * Unpublish many Vehicle documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVehiclesConnection)
   */
  unpublishManyVehicles: BatchPayload;
  /** Find many Vehicle documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVehiclesConnection: VehicleConnection;
  /**
   * Unpublish many Video documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVideosConnection)
   */
  unpublishManyVideos: BatchPayload;
  /** Find many Video documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVideosConnection: VideoConnection;
  /**
   * Unpublish many Whitelabel documents
   * @deprecated Please use the new paginated many mutation (unpublishManyWhitelabelsConnection)
   */
  unpublishManyWhitelabels: BatchPayload;
  /** Find many Whitelabel documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyWhitelabelsConnection: WhitelabelConnection;
  /** Unpublish one model from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishModel?: Maybe<Model>;
  /** Unpublish one optionFacet from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishOptionFacet?: Maybe<OptionFacet>;
  /** Unpublish one paPromotion from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPaPromotion?: Maybe<PaPromotion>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage?: Maybe<Page>;
  /** Unpublish one pollutionClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPollutionClass?: Maybe<PollutionClass>;
  /** Unpublish one qualityChecked from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishQualityChecked?: Maybe<QualityChecked>;
  /** Unpublish one questionAndAnswer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Unpublish one questionAndAnswerList from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Unpublish one root from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRoot?: Maybe<Root>;
  /** Unpublish one service from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishService?: Maybe<Service>;
  /** Unpublish one specialBox from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSpecialBox?: Maybe<SpecialBox>;
  /** Unpublish one transmission from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTransmission?: Maybe<Transmission>;
  /** Unpublish one transmissionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Unpublish one upholstery from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishUpholstery?: Maybe<Upholstery>;
  /** Unpublish one vehicle from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVehicle?: Maybe<Vehicle>;
  /** Unpublish one vehicleClass from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVehicleClass?: Maybe<VehicleClass>;
  /** Unpublish one vehicleCondition from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVehicleCondition?: Maybe<VehicleCondition>;
  /** Unpublish one vehicleStatus from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVehicleStatus?: Maybe<VehicleStatus>;
  /** Unpublish one vehicleType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVehicleType?: Maybe<VehicleType>;
  /** Unpublish one video from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVideo?: Maybe<Video>;
  /** Unpublish one whitelabel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishWhitelabel?: Maybe<Whitelabel>;
  /** Update one accordionChapter */
  updateAccordionChapter?: Maybe<AccordionChapter>;
  /** Update one accordionChapterList */
  updateAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Update one accordionSmlList */
  updateAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Update one ad */
  updateAd?: Maybe<Ad>;
  /** Update one article */
  updateArticle?: Maybe<Article>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one banner */
  updateBanner?: Maybe<Banner>;
  /** Update one bodyType */
  updateBodyType?: Maybe<BodyType>;
  /** Update one bodyTypeGroup */
  updateBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Update one brand */
  updateBrand?: Maybe<Brand>;
  /** Update one button */
  updateButton?: Maybe<Button>;
  /** Update one buttonRow */
  updateButtonRow?: Maybe<ButtonRow>;
  /** Update one citatum */
  updateCitatum?: Maybe<Citatum>;
  /** Update one colorNorm */
  updateColorNorm?: Maybe<ColorNorm>;
  /** Update one colorType */
  updateColorType?: Maybe<ColorType>;
  /** Update one contentConfiguration */
  updateContentConfiguration?: Maybe<ContentConfiguration>;
  /** Update one dealer */
  updateDealer?: Maybe<Dealer>;
  /** Update one dealerEmployee */
  updateDealerEmployee?: Maybe<DealerEmployee>;
  /** Update one department */
  updateDepartment?: Maybe<Department>;
  /** Update one driveType */
  updateDriveType?: Maybe<DriveType>;
  /** Update one emissionClass */
  updateEmissionClass?: Maybe<EmissionClass>;
  /** Update one employee */
  updateEmployee?: Maybe<Employee>;
  /** Update one factList */
  updateFactList?: Maybe<FactList>;
  /** Update one featuredVehiclesRow */
  updateFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Update one fuelType */
  updateFuelType?: Maybe<FuelType>;
  /** Update one fuelTypeGroup */
  updateFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Update one gallery */
  updateGallery?: Maybe<Gallery>;
  /** Update one generatedPageExtension */
  updateGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Update one heroSlider */
  updateHeroSlider?: Maybe<HeroSlider>;
  /** Update one image */
  updateImage?: Maybe<Image>;
  /** Update one imageTextComboList */
  updateImageTextComboList?: Maybe<ImageTextComboList>;
  /** Update one keywordList */
  updateKeywordList?: Maybe<KeywordList>;
  /**
   * Update many accordionChapterLists
   * @deprecated Please use the new paginated many mutation (updateManyAccordionChapterListsConnection)
   */
  updateManyAccordionChapterLists: BatchPayload;
  /** Update many AccordionChapterList documents */
  updateManyAccordionChapterListsConnection: AccordionChapterListConnection;
  /**
   * Update many accordionChapters
   * @deprecated Please use the new paginated many mutation (updateManyAccordionChaptersConnection)
   */
  updateManyAccordionChapters: BatchPayload;
  /** Update many AccordionChapter documents */
  updateManyAccordionChaptersConnection: AccordionChapterConnection;
  /**
   * Update many accordionSmlLists
   * @deprecated Please use the new paginated many mutation (updateManyAccordionSmlListsConnection)
   */
  updateManyAccordionSmlLists: BatchPayload;
  /** Update many AccordionSmlList documents */
  updateManyAccordionSmlListsConnection: AccordionSmlListConnection;
  /**
   * Update many ads
   * @deprecated Please use the new paginated many mutation (updateManyAdsConnection)
   */
  updateManyAds: BatchPayload;
  /** Update many Ad documents */
  updateManyAdsConnection: AdConnection;
  /**
   * Update many articles
   * @deprecated Please use the new paginated many mutation (updateManyArticlesConnection)
   */
  updateManyArticles: BatchPayload;
  /** Update many Article documents */
  updateManyArticlesConnection: ArticleConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many banners
   * @deprecated Please use the new paginated many mutation (updateManyBannersConnection)
   */
  updateManyBanners: BatchPayload;
  /** Update many Banner documents */
  updateManyBannersConnection: BannerConnection;
  /**
   * Update many bodyTypeGroups
   * @deprecated Please use the new paginated many mutation (updateManyBodyTypeGroupsConnection)
   */
  updateManyBodyTypeGroups: BatchPayload;
  /** Update many BodyTypeGroup documents */
  updateManyBodyTypeGroupsConnection: BodyTypeGroupConnection;
  /**
   * Update many bodyTypes
   * @deprecated Please use the new paginated many mutation (updateManyBodyTypesConnection)
   */
  updateManyBodyTypes: BatchPayload;
  /** Update many BodyType documents */
  updateManyBodyTypesConnection: BodyTypeConnection;
  /**
   * Update many brands
   * @deprecated Please use the new paginated many mutation (updateManyBrandsConnection)
   */
  updateManyBrands: BatchPayload;
  /** Update many Brand documents */
  updateManyBrandsConnection: BrandConnection;
  /**
   * Update many buttonRows
   * @deprecated Please use the new paginated many mutation (updateManyButtonRowsConnection)
   */
  updateManyButtonRows: BatchPayload;
  /** Update many ButtonRow documents */
  updateManyButtonRowsConnection: ButtonRowConnection;
  /**
   * Update many buttons
   * @deprecated Please use the new paginated many mutation (updateManyButtonsConnection)
   */
  updateManyButtons: BatchPayload;
  /** Update many Button documents */
  updateManyButtonsConnection: ButtonConnection;
  /**
   * Update many citatums
   * @deprecated Please use the new paginated many mutation (updateManyCitatumsConnection)
   */
  updateManyCitatums: BatchPayload;
  /** Update many Citatum documents */
  updateManyCitatumsConnection: CitatumConnection;
  /**
   * Update many colorNorms
   * @deprecated Please use the new paginated many mutation (updateManyColorNormsConnection)
   */
  updateManyColorNorms: BatchPayload;
  /** Update many ColorNorm documents */
  updateManyColorNormsConnection: ColorNormConnection;
  /**
   * Update many colorTypes
   * @deprecated Please use the new paginated many mutation (updateManyColorTypesConnection)
   */
  updateManyColorTypes: BatchPayload;
  /** Update many ColorType documents */
  updateManyColorTypesConnection: ColorTypeConnection;
  /**
   * Update many contentConfigurations
   * @deprecated Please use the new paginated many mutation (updateManyContentConfigurationsConnection)
   */
  updateManyContentConfigurations: BatchPayload;
  /** Update many ContentConfiguration documents */
  updateManyContentConfigurationsConnection: ContentConfigurationConnection;
  /**
   * Update many dealerEmployees
   * @deprecated Please use the new paginated many mutation (updateManyDealerEmployeesConnection)
   */
  updateManyDealerEmployees: BatchPayload;
  /** Update many DealerEmployee documents */
  updateManyDealerEmployeesConnection: DealerEmployeeConnection;
  /**
   * Update many dealers
   * @deprecated Please use the new paginated many mutation (updateManyDealersConnection)
   */
  updateManyDealers: BatchPayload;
  /** Update many Dealer documents */
  updateManyDealersConnection: DealerConnection;
  /**
   * Update many departments
   * @deprecated Please use the new paginated many mutation (updateManyDepartmentsConnection)
   */
  updateManyDepartments: BatchPayload;
  /** Update many Department documents */
  updateManyDepartmentsConnection: DepartmentConnection;
  /**
   * Update many driveTypes
   * @deprecated Please use the new paginated many mutation (updateManyDriveTypesConnection)
   */
  updateManyDriveTypes: BatchPayload;
  /** Update many DriveType documents */
  updateManyDriveTypesConnection: DriveTypeConnection;
  /**
   * Update many emissionClasses
   * @deprecated Please use the new paginated many mutation (updateManyEmissionClassesConnection)
   */
  updateManyEmissionClasses: BatchPayload;
  /** Update many EmissionClass documents */
  updateManyEmissionClassesConnection: EmissionClassConnection;
  /**
   * Update many employees
   * @deprecated Please use the new paginated many mutation (updateManyEmployeesConnection)
   */
  updateManyEmployees: BatchPayload;
  /** Update many Employee documents */
  updateManyEmployeesConnection: EmployeeConnection;
  /**
   * Update many factLists
   * @deprecated Please use the new paginated many mutation (updateManyFactListsConnection)
   */
  updateManyFactLists: BatchPayload;
  /** Update many FactList documents */
  updateManyFactListsConnection: FactListConnection;
  /**
   * Update many featuredVehiclesRows
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedVehiclesRowsConnection)
   */
  updateManyFeaturedVehiclesRows: BatchPayload;
  /** Update many FeaturedVehiclesRow documents */
  updateManyFeaturedVehiclesRowsConnection: FeaturedVehiclesRowConnection;
  /**
   * Update many fuelTypeGroups
   * @deprecated Please use the new paginated many mutation (updateManyFuelTypeGroupsConnection)
   */
  updateManyFuelTypeGroups: BatchPayload;
  /** Update many FuelTypeGroup documents */
  updateManyFuelTypeGroupsConnection: FuelTypeGroupConnection;
  /**
   * Update many fuelTypes
   * @deprecated Please use the new paginated many mutation (updateManyFuelTypesConnection)
   */
  updateManyFuelTypes: BatchPayload;
  /** Update many FuelType documents */
  updateManyFuelTypesConnection: FuelTypeConnection;
  /**
   * Update many galleries
   * @deprecated Please use the new paginated many mutation (updateManyGalleriesConnection)
   */
  updateManyGalleries: BatchPayload;
  /** Update many Gallery documents */
  updateManyGalleriesConnection: GalleryConnection;
  /**
   * Update many generatedPageExtensions
   * @deprecated Please use the new paginated many mutation (updateManyGeneratedPageExtensionsConnection)
   */
  updateManyGeneratedPageExtensions: BatchPayload;
  /** Update many GeneratedPageExtension documents */
  updateManyGeneratedPageExtensionsConnection: GeneratedPageExtensionConnection;
  /**
   * Update many heroSliders
   * @deprecated Please use the new paginated many mutation (updateManyHeroSlidersConnection)
   */
  updateManyHeroSliders: BatchPayload;
  /** Update many HeroSlider documents */
  updateManyHeroSlidersConnection: HeroSliderConnection;
  /**
   * Update many imageTextComboLists
   * @deprecated Please use the new paginated many mutation (updateManyImageTextComboListsConnection)
   */
  updateManyImageTextComboLists: BatchPayload;
  /** Update many ImageTextComboList documents */
  updateManyImageTextComboListsConnection: ImageTextComboListConnection;
  /**
   * Update many images
   * @deprecated Please use the new paginated many mutation (updateManyImagesConnection)
   */
  updateManyImages: BatchPayload;
  /** Update many Image documents */
  updateManyImagesConnection: ImageConnection;
  /**
   * Update many keywordLists
   * @deprecated Please use the new paginated many mutation (updateManyKeywordListsConnection)
   */
  updateManyKeywordLists: BatchPayload;
  /** Update many KeywordList documents */
  updateManyKeywordListsConnection: KeywordListConnection;
  /**
   * Update many models
   * @deprecated Please use the new paginated many mutation (updateManyModelsConnection)
   */
  updateManyModels: BatchPayload;
  /** Update many Model documents */
  updateManyModelsConnection: ModelConnection;
  /**
   * Update many optionFacets
   * @deprecated Please use the new paginated many mutation (updateManyOptionFacetsConnection)
   */
  updateManyOptionFacets: BatchPayload;
  /** Update many OptionFacet documents */
  updateManyOptionFacetsConnection: OptionFacetConnection;
  /**
   * Update many paPromotions
   * @deprecated Please use the new paginated many mutation (updateManyPaPromotionsConnection)
   */
  updateManyPaPromotions: BatchPayload;
  /** Update many PaPromotion documents */
  updateManyPaPromotionsConnection: PaPromotionConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: BatchPayload;
  /** Update many Page documents */
  updateManyPagesConnection: PageConnection;
  /**
   * Update many pollutionClasses
   * @deprecated Please use the new paginated many mutation (updateManyPollutionClassesConnection)
   */
  updateManyPollutionClasses: BatchPayload;
  /** Update many PollutionClass documents */
  updateManyPollutionClassesConnection: PollutionClassConnection;
  /**
   * Update many qualityCheckeds
   * @deprecated Please use the new paginated many mutation (updateManyQualityCheckedsConnection)
   */
  updateManyQualityCheckeds: BatchPayload;
  /** Update many QualityChecked documents */
  updateManyQualityCheckedsConnection: QualityCheckedConnection;
  /**
   * Update many questionAndAnswerLists
   * @deprecated Please use the new paginated many mutation (updateManyQuestionAndAnswerListsConnection)
   */
  updateManyQuestionAndAnswerLists: BatchPayload;
  /** Update many QuestionAndAnswerList documents */
  updateManyQuestionAndAnswerListsConnection: QuestionAndAnswerListConnection;
  /**
   * Update many questionAndAnswers
   * @deprecated Please use the new paginated many mutation (updateManyQuestionAndAnswersConnection)
   */
  updateManyQuestionAndAnswers: BatchPayload;
  /** Update many QuestionAndAnswer documents */
  updateManyQuestionAndAnswersConnection: QuestionAndAnswerConnection;
  /**
   * Update many roots
   * @deprecated Please use the new paginated many mutation (updateManyRootsConnection)
   */
  updateManyRoots: BatchPayload;
  /** Update many Root documents */
  updateManyRootsConnection: RootConnection;
  /**
   * Update many services
   * @deprecated Please use the new paginated many mutation (updateManyServicesConnection)
   */
  updateManyServices: BatchPayload;
  /** Update many Service documents */
  updateManyServicesConnection: ServiceConnection;
  /**
   * Update many specialBoxes
   * @deprecated Please use the new paginated many mutation (updateManySpecialBoxesConnection)
   */
  updateManySpecialBoxes: BatchPayload;
  /** Update many SpecialBox documents */
  updateManySpecialBoxesConnection: SpecialBoxConnection;
  /**
   * Update many transmissionGroups
   * @deprecated Please use the new paginated many mutation (updateManyTransmissionGroupsConnection)
   */
  updateManyTransmissionGroups: BatchPayload;
  /** Update many TransmissionGroup documents */
  updateManyTransmissionGroupsConnection: TransmissionGroupConnection;
  /**
   * Update many transmissions
   * @deprecated Please use the new paginated many mutation (updateManyTransmissionsConnection)
   */
  updateManyTransmissions: BatchPayload;
  /** Update many Transmission documents */
  updateManyTransmissionsConnection: TransmissionConnection;
  /**
   * Update many upholsterys
   * @deprecated Please use the new paginated many mutation (updateManyUpholsterysConnection)
   */
  updateManyUpholsterys: BatchPayload;
  /** Update many Upholstery documents */
  updateManyUpholsterysConnection: UpholsteryConnection;
  /**
   * Update many vehicleClasses
   * @deprecated Please use the new paginated many mutation (updateManyVehicleClassesConnection)
   */
  updateManyVehicleClasses: BatchPayload;
  /** Update many VehicleClass documents */
  updateManyVehicleClassesConnection: VehicleClassConnection;
  /**
   * Update many vehicleConditions
   * @deprecated Please use the new paginated many mutation (updateManyVehicleConditionsConnection)
   */
  updateManyVehicleConditions: BatchPayload;
  /** Update many VehicleCondition documents */
  updateManyVehicleConditionsConnection: VehicleConditionConnection;
  /**
   * Update many vehicleStatuses
   * @deprecated Please use the new paginated many mutation (updateManyVehicleStatusesConnection)
   */
  updateManyVehicleStatuses: BatchPayload;
  /** Update many VehicleStatus documents */
  updateManyVehicleStatusesConnection: VehicleStatusConnection;
  /**
   * Update many vehicleTypes
   * @deprecated Please use the new paginated many mutation (updateManyVehicleTypesConnection)
   */
  updateManyVehicleTypes: BatchPayload;
  /** Update many VehicleType documents */
  updateManyVehicleTypesConnection: VehicleTypeConnection;
  /**
   * Update many vehicles
   * @deprecated Please use the new paginated many mutation (updateManyVehiclesConnection)
   */
  updateManyVehicles: BatchPayload;
  /** Update many Vehicle documents */
  updateManyVehiclesConnection: VehicleConnection;
  /**
   * Update many videos
   * @deprecated Please use the new paginated many mutation (updateManyVideosConnection)
   */
  updateManyVideos: BatchPayload;
  /** Update many Video documents */
  updateManyVideosConnection: VideoConnection;
  /**
   * Update many whitelabels
   * @deprecated Please use the new paginated many mutation (updateManyWhitelabelsConnection)
   */
  updateManyWhitelabels: BatchPayload;
  /** Update many Whitelabel documents */
  updateManyWhitelabelsConnection: WhitelabelConnection;
  /** Update one model */
  updateModel?: Maybe<Model>;
  /** Update one optionFacet */
  updateOptionFacet?: Maybe<OptionFacet>;
  /** Update one paPromotion */
  updatePaPromotion?: Maybe<PaPromotion>;
  /** Update one page */
  updatePage?: Maybe<Page>;
  /** Update one pollutionClass */
  updatePollutionClass?: Maybe<PollutionClass>;
  /** Update one qualityChecked */
  updateQualityChecked?: Maybe<QualityChecked>;
  /** Update one questionAndAnswer */
  updateQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Update one questionAndAnswerList */
  updateQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Update one root */
  updateRoot?: Maybe<Root>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one service */
  updateService?: Maybe<Service>;
  /** Update one specialBox */
  updateSpecialBox?: Maybe<SpecialBox>;
  /** Update one transmission */
  updateTransmission?: Maybe<Transmission>;
  /** Update one transmissionGroup */
  updateTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Update one upholstery */
  updateUpholstery?: Maybe<Upholstery>;
  /** Update one vehicle */
  updateVehicle?: Maybe<Vehicle>;
  /** Update one vehicleClass */
  updateVehicleClass?: Maybe<VehicleClass>;
  /** Update one vehicleCondition */
  updateVehicleCondition?: Maybe<VehicleCondition>;
  /** Update one vehicleStatus */
  updateVehicleStatus?: Maybe<VehicleStatus>;
  /** Update one vehicleType */
  updateVehicleType?: Maybe<VehicleType>;
  /** Update one video */
  updateVideo?: Maybe<Video>;
  /** Update one whitelabel */
  updateWhitelabel?: Maybe<Whitelabel>;
  /** Upsert one accordionChapter */
  upsertAccordionChapter?: Maybe<AccordionChapter>;
  /** Upsert one accordionChapterList */
  upsertAccordionChapterList?: Maybe<AccordionChapterList>;
  /** Upsert one accordionSmlList */
  upsertAccordionSmlList?: Maybe<AccordionSmlList>;
  /** Upsert one ad */
  upsertAd?: Maybe<Ad>;
  /** Upsert one article */
  upsertArticle?: Maybe<Article>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one banner */
  upsertBanner?: Maybe<Banner>;
  /** Upsert one bodyType */
  upsertBodyType?: Maybe<BodyType>;
  /** Upsert one bodyTypeGroup */
  upsertBodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Upsert one brand */
  upsertBrand?: Maybe<Brand>;
  /** Upsert one button */
  upsertButton?: Maybe<Button>;
  /** Upsert one buttonRow */
  upsertButtonRow?: Maybe<ButtonRow>;
  /** Upsert one citatum */
  upsertCitatum?: Maybe<Citatum>;
  /** Upsert one colorNorm */
  upsertColorNorm?: Maybe<ColorNorm>;
  /** Upsert one colorType */
  upsertColorType?: Maybe<ColorType>;
  /** Upsert one contentConfiguration */
  upsertContentConfiguration?: Maybe<ContentConfiguration>;
  /** Upsert one dealer */
  upsertDealer?: Maybe<Dealer>;
  /** Upsert one dealerEmployee */
  upsertDealerEmployee?: Maybe<DealerEmployee>;
  /** Upsert one department */
  upsertDepartment?: Maybe<Department>;
  /** Upsert one driveType */
  upsertDriveType?: Maybe<DriveType>;
  /** Upsert one emissionClass */
  upsertEmissionClass?: Maybe<EmissionClass>;
  /** Upsert one employee */
  upsertEmployee?: Maybe<Employee>;
  /** Upsert one factList */
  upsertFactList?: Maybe<FactList>;
  /** Upsert one featuredVehiclesRow */
  upsertFeaturedVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Upsert one fuelType */
  upsertFuelType?: Maybe<FuelType>;
  /** Upsert one fuelTypeGroup */
  upsertFuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Upsert one gallery */
  upsertGallery?: Maybe<Gallery>;
  /** Upsert one generatedPageExtension */
  upsertGeneratedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Upsert one heroSlider */
  upsertHeroSlider?: Maybe<HeroSlider>;
  /** Upsert one image */
  upsertImage?: Maybe<Image>;
  /** Upsert one imageTextComboList */
  upsertImageTextComboList?: Maybe<ImageTextComboList>;
  /** Upsert one keywordList */
  upsertKeywordList?: Maybe<KeywordList>;
  /** Upsert one model */
  upsertModel?: Maybe<Model>;
  /** Upsert one optionFacet */
  upsertOptionFacet?: Maybe<OptionFacet>;
  /** Upsert one paPromotion */
  upsertPaPromotion?: Maybe<PaPromotion>;
  /** Upsert one page */
  upsertPage?: Maybe<Page>;
  /** Upsert one pollutionClass */
  upsertPollutionClass?: Maybe<PollutionClass>;
  /** Upsert one qualityChecked */
  upsertQualityChecked?: Maybe<QualityChecked>;
  /** Upsert one questionAndAnswer */
  upsertQuestionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Upsert one questionAndAnswerList */
  upsertQuestionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Upsert one root */
  upsertRoot?: Maybe<Root>;
  /** Upsert one service */
  upsertService?: Maybe<Service>;
  /** Upsert one specialBox */
  upsertSpecialBox?: Maybe<SpecialBox>;
  /** Upsert one transmission */
  upsertTransmission?: Maybe<Transmission>;
  /** Upsert one transmissionGroup */
  upsertTransmissionGroup?: Maybe<TransmissionGroup>;
  /** Upsert one upholstery */
  upsertUpholstery?: Maybe<Upholstery>;
  /** Upsert one vehicle */
  upsertVehicle?: Maybe<Vehicle>;
  /** Upsert one vehicleClass */
  upsertVehicleClass?: Maybe<VehicleClass>;
  /** Upsert one vehicleCondition */
  upsertVehicleCondition?: Maybe<VehicleCondition>;
  /** Upsert one vehicleStatus */
  upsertVehicleStatus?: Maybe<VehicleStatus>;
  /** Upsert one vehicleType */
  upsertVehicleType?: Maybe<VehicleType>;
  /** Upsert one video */
  upsertVideo?: Maybe<Video>;
  /** Upsert one whitelabel */
  upsertWhitelabel?: Maybe<Whitelabel>;
};


export type MutationCreateAccordionChapterArgs = {
  data: AccordionChapterCreateInput;
};


export type MutationCreateAccordionChapterListArgs = {
  data: AccordionChapterListCreateInput;
};


export type MutationCreateAccordionSmlListArgs = {
  data: AccordionSmlListCreateInput;
};


export type MutationCreateAdArgs = {
  data: AdCreateInput;
};


export type MutationCreateArticleArgs = {
  data: ArticleCreateInput;
};


export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};


export type MutationCreateBannerArgs = {
  data: BannerCreateInput;
};


export type MutationCreateBodyTypeArgs = {
  data: BodyTypeCreateInput;
};


export type MutationCreateBodyTypeGroupArgs = {
  data: BodyTypeGroupCreateInput;
};


export type MutationCreateBrandArgs = {
  data: BrandCreateInput;
};


export type MutationCreateButtonArgs = {
  data: ButtonCreateInput;
};


export type MutationCreateButtonRowArgs = {
  data: ButtonRowCreateInput;
};


export type MutationCreateCitatumArgs = {
  data: CitatumCreateInput;
};


export type MutationCreateColorNormArgs = {
  data: ColorNormCreateInput;
};


export type MutationCreateColorTypeArgs = {
  data: ColorTypeCreateInput;
};


export type MutationCreateContentConfigurationArgs = {
  data: ContentConfigurationCreateInput;
};


export type MutationCreateDealerArgs = {
  data: DealerCreateInput;
};


export type MutationCreateDealerEmployeeArgs = {
  data: DealerEmployeeCreateInput;
};


export type MutationCreateDepartmentArgs = {
  data: DepartmentCreateInput;
};


export type MutationCreateDriveTypeArgs = {
  data: DriveTypeCreateInput;
};


export type MutationCreateEmissionClassArgs = {
  data: EmissionClassCreateInput;
};


export type MutationCreateEmployeeArgs = {
  data: EmployeeCreateInput;
};


export type MutationCreateFactListArgs = {
  data: FactListCreateInput;
};


export type MutationCreateFeaturedVehiclesRowArgs = {
  data: FeaturedVehiclesRowCreateInput;
};


export type MutationCreateFuelTypeArgs = {
  data: FuelTypeCreateInput;
};


export type MutationCreateFuelTypeGroupArgs = {
  data: FuelTypeGroupCreateInput;
};


export type MutationCreateGalleryArgs = {
  data: GalleryCreateInput;
};


export type MutationCreateGeneratedPageExtensionArgs = {
  data: GeneratedPageExtensionCreateInput;
};


export type MutationCreateHeroSliderArgs = {
  data: HeroSliderCreateInput;
};


export type MutationCreateImageArgs = {
  data: ImageCreateInput;
};


export type MutationCreateImageTextComboListArgs = {
  data: ImageTextComboListCreateInput;
};


export type MutationCreateKeywordListArgs = {
  data: KeywordListCreateInput;
};


export type MutationCreateModelArgs = {
  data: ModelCreateInput;
};


export type MutationCreateOptionFacetArgs = {
  data: OptionFacetCreateInput;
};


export type MutationCreatePaPromotionArgs = {
  data: PaPromotionCreateInput;
};


export type MutationCreatePageArgs = {
  data: PageCreateInput;
};


export type MutationCreatePollutionClassArgs = {
  data: PollutionClassCreateInput;
};


export type MutationCreateQualityCheckedArgs = {
  data: QualityCheckedCreateInput;
};


export type MutationCreateQuestionAndAnswerArgs = {
  data: QuestionAndAnswerCreateInput;
};


export type MutationCreateQuestionAndAnswerListArgs = {
  data: QuestionAndAnswerListCreateInput;
};


export type MutationCreateRootArgs = {
  data: RootCreateInput;
};


export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};


export type MutationCreateServiceArgs = {
  data: ServiceCreateInput;
};


export type MutationCreateSpecialBoxArgs = {
  data: SpecialBoxCreateInput;
};


export type MutationCreateTransmissionArgs = {
  data: TransmissionCreateInput;
};


export type MutationCreateTransmissionGroupArgs = {
  data: TransmissionGroupCreateInput;
};


export type MutationCreateUpholsteryArgs = {
  data: UpholsteryCreateInput;
};


export type MutationCreateVehicleArgs = {
  data: VehicleCreateInput;
};


export type MutationCreateVehicleClassArgs = {
  data: VehicleClassCreateInput;
};


export type MutationCreateVehicleConditionArgs = {
  data: VehicleConditionCreateInput;
};


export type MutationCreateVehicleStatusArgs = {
  data: VehicleStatusCreateInput;
};


export type MutationCreateVehicleTypeArgs = {
  data: VehicleTypeCreateInput;
};


export type MutationCreateVideoArgs = {
  data: VideoCreateInput;
};


export type MutationCreateWhitelabelArgs = {
  data: WhitelabelCreateInput;
};


export type MutationDeleteAccordionChapterArgs = {
  where: AccordionChapterWhereUniqueInput;
};


export type MutationDeleteAccordionChapterListArgs = {
  where: AccordionChapterListWhereUniqueInput;
};


export type MutationDeleteAccordionSmlListArgs = {
  where: AccordionSmlListWhereUniqueInput;
};


export type MutationDeleteAdArgs = {
  where: AdWhereUniqueInput;
};


export type MutationDeleteArticleArgs = {
  where: ArticleWhereUniqueInput;
};


export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};


export type MutationDeleteBannerArgs = {
  where: BannerWhereUniqueInput;
};


export type MutationDeleteBodyTypeArgs = {
  where: BodyTypeWhereUniqueInput;
};


export type MutationDeleteBodyTypeGroupArgs = {
  where: BodyTypeGroupWhereUniqueInput;
};


export type MutationDeleteBrandArgs = {
  where: BrandWhereUniqueInput;
};


export type MutationDeleteButtonArgs = {
  where: ButtonWhereUniqueInput;
};


export type MutationDeleteButtonRowArgs = {
  where: ButtonRowWhereUniqueInput;
};


export type MutationDeleteCitatumArgs = {
  where: CitatumWhereUniqueInput;
};


export type MutationDeleteColorNormArgs = {
  where: ColorNormWhereUniqueInput;
};


export type MutationDeleteColorTypeArgs = {
  where: ColorTypeWhereUniqueInput;
};


export type MutationDeleteContentConfigurationArgs = {
  where: ContentConfigurationWhereUniqueInput;
};


export type MutationDeleteDealerArgs = {
  where: DealerWhereUniqueInput;
};


export type MutationDeleteDealerEmployeeArgs = {
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationDeleteDepartmentArgs = {
  where: DepartmentWhereUniqueInput;
};


export type MutationDeleteDriveTypeArgs = {
  where: DriveTypeWhereUniqueInput;
};


export type MutationDeleteEmissionClassArgs = {
  where: EmissionClassWhereUniqueInput;
};


export type MutationDeleteEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type MutationDeleteFactListArgs = {
  where: FactListWhereUniqueInput;
};


export type MutationDeleteFeaturedVehiclesRowArgs = {
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type MutationDeleteFuelTypeArgs = {
  where: FuelTypeWhereUniqueInput;
};


export type MutationDeleteFuelTypeGroupArgs = {
  where: FuelTypeGroupWhereUniqueInput;
};


export type MutationDeleteGalleryArgs = {
  where: GalleryWhereUniqueInput;
};


export type MutationDeleteGeneratedPageExtensionArgs = {
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type MutationDeleteHeroSliderArgs = {
  where: HeroSliderWhereUniqueInput;
};


export type MutationDeleteImageArgs = {
  where: ImageWhereUniqueInput;
};


export type MutationDeleteImageTextComboListArgs = {
  where: ImageTextComboListWhereUniqueInput;
};


export type MutationDeleteKeywordListArgs = {
  where: KeywordListWhereUniqueInput;
};


export type MutationDeleteManyAccordionChapterListsArgs = {
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationDeleteManyAccordionChapterListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationDeleteManyAccordionChaptersArgs = {
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationDeleteManyAccordionChaptersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationDeleteManyAccordionSmlListsArgs = {
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationDeleteManyAccordionSmlListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationDeleteManyAdsArgs = {
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationDeleteManyAdsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationDeleteManyArticlesArgs = {
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationDeleteManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationDeleteManyBannersArgs = {
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationDeleteManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationDeleteManyBodyTypeGroupsArgs = {
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationDeleteManyBodyTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationDeleteManyBodyTypesArgs = {
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationDeleteManyBodyTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationDeleteManyBrandsArgs = {
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationDeleteManyBrandsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationDeleteManyButtonRowsArgs = {
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationDeleteManyButtonRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationDeleteManyButtonsArgs = {
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationDeleteManyButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationDeleteManyCitatumsArgs = {
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationDeleteManyCitatumsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationDeleteManyColorNormsArgs = {
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationDeleteManyColorNormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationDeleteManyColorTypesArgs = {
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationDeleteManyColorTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationDeleteManyContentConfigurationsArgs = {
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationDeleteManyContentConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationDeleteManyDealerEmployeesArgs = {
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationDeleteManyDealerEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationDeleteManyDealersArgs = {
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationDeleteManyDealersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationDeleteManyDepartmentsArgs = {
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationDeleteManyDepartmentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationDeleteManyDriveTypesArgs = {
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationDeleteManyDriveTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationDeleteManyEmissionClassesArgs = {
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationDeleteManyEmissionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationDeleteManyEmployeesArgs = {
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationDeleteManyEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationDeleteManyFactListsArgs = {
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationDeleteManyFactListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationDeleteManyFeaturedVehiclesRowsArgs = {
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationDeleteManyFeaturedVehiclesRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationDeleteManyFuelTypeGroupsArgs = {
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationDeleteManyFuelTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationDeleteManyFuelTypesArgs = {
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationDeleteManyFuelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationDeleteManyGalleriesArgs = {
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationDeleteManyGalleriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationDeleteManyGeneratedPageExtensionsArgs = {
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationDeleteManyGeneratedPageExtensionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationDeleteManyHeroSlidersArgs = {
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationDeleteManyHeroSlidersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationDeleteManyImageTextComboListsArgs = {
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationDeleteManyImageTextComboListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationDeleteManyImagesArgs = {
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationDeleteManyImagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationDeleteManyKeywordListsArgs = {
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationDeleteManyKeywordListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationDeleteManyModelsArgs = {
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationDeleteManyModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationDeleteManyOptionFacetsArgs = {
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationDeleteManyOptionFacetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationDeleteManyPaPromotionsArgs = {
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationDeleteManyPaPromotionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationDeleteManyPagesArgs = {
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationDeleteManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationDeleteManyPollutionClassesArgs = {
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationDeleteManyPollutionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationDeleteManyQualityCheckedsArgs = {
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationDeleteManyQualityCheckedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationDeleteManyQuestionAndAnswerListsArgs = {
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationDeleteManyQuestionAndAnswerListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationDeleteManyQuestionAndAnswersArgs = {
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationDeleteManyQuestionAndAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationDeleteManyRootsArgs = {
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationDeleteManyRootsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationDeleteManyServicesArgs = {
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationDeleteManyServicesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationDeleteManySpecialBoxesArgs = {
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationDeleteManySpecialBoxesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationDeleteManyTransmissionGroupsArgs = {
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationDeleteManyTransmissionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationDeleteManyTransmissionsArgs = {
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationDeleteManyTransmissionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationDeleteManyUpholsterysArgs = {
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationDeleteManyUpholsterysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationDeleteManyVehicleClassesArgs = {
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationDeleteManyVehicleClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationDeleteManyVehicleConditionsArgs = {
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationDeleteManyVehicleConditionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationDeleteManyVehicleStatusesArgs = {
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationDeleteManyVehicleStatusesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationDeleteManyVehicleTypesArgs = {
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationDeleteManyVehicleTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationDeleteManyVehiclesArgs = {
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationDeleteManyVehiclesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationDeleteManyVideosArgs = {
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationDeleteManyVideosConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationDeleteManyWhitelabelsArgs = {
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationDeleteManyWhitelabelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationDeleteModelArgs = {
  where: ModelWhereUniqueInput;
};


export type MutationDeleteOptionFacetArgs = {
  where: OptionFacetWhereUniqueInput;
};


export type MutationDeletePaPromotionArgs = {
  where: PaPromotionWhereUniqueInput;
};


export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};


export type MutationDeletePollutionClassArgs = {
  where: PollutionClassWhereUniqueInput;
};


export type MutationDeleteQualityCheckedArgs = {
  where: QualityCheckedWhereUniqueInput;
};


export type MutationDeleteQuestionAndAnswerArgs = {
  where: QuestionAndAnswerWhereUniqueInput;
};


export type MutationDeleteQuestionAndAnswerListArgs = {
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type MutationDeleteRootArgs = {
  where: RootWhereUniqueInput;
};


export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};


export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};


export type MutationDeleteServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteSpecialBoxArgs = {
  where: SpecialBoxWhereUniqueInput;
};


export type MutationDeleteTransmissionArgs = {
  where: TransmissionWhereUniqueInput;
};


export type MutationDeleteTransmissionGroupArgs = {
  where: TransmissionGroupWhereUniqueInput;
};


export type MutationDeleteUpholsteryArgs = {
  where: UpholsteryWhereUniqueInput;
};


export type MutationDeleteVehicleArgs = {
  where: VehicleWhereUniqueInput;
};


export type MutationDeleteVehicleClassArgs = {
  where: VehicleClassWhereUniqueInput;
};


export type MutationDeleteVehicleConditionArgs = {
  where: VehicleConditionWhereUniqueInput;
};


export type MutationDeleteVehicleStatusArgs = {
  where: VehicleStatusWhereUniqueInput;
};


export type MutationDeleteVehicleTypeArgs = {
  where: VehicleTypeWhereUniqueInput;
};


export type MutationDeleteVideoArgs = {
  where: VideoWhereUniqueInput;
};


export type MutationDeleteWhitelabelArgs = {
  where: WhitelabelWhereUniqueInput;
};


export type MutationPublishAccordionChapterArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: AccordionChapterWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishAccordionChapterListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: AccordionChapterListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishAccordionSmlListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: AccordionSmlListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishAdArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: AdWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: BannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishBodyTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: BodyTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishBodyTypeGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: BodyTypeGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishBrandArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: BrandWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishButtonArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ButtonWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishButtonRowArgs = {
  to?: Array<Stage>;
  where: ButtonRowWhereUniqueInput;
};


export type MutationPublishCitatumArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: CitatumWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishColorNormArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ColorNormWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishColorTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ColorTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishContentConfigurationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ContentConfigurationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishDealerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: DealerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishDealerEmployeeArgs = {
  to?: Array<Stage>;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationPublishDepartmentArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: DepartmentWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishDriveTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: DriveTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishEmissionClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: EmissionClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishEmployeeArgs = {
  to?: Array<Stage>;
  where: EmployeeWhereUniqueInput;
};


export type MutationPublishFactListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: FactListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishFeaturedVehiclesRowArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: FeaturedVehiclesRowWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishFuelTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: FuelTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishFuelTypeGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: FuelTypeGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishGalleryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: GalleryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishGeneratedPageExtensionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: GeneratedPageExtensionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishHeroSliderArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: HeroSliderWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ImageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishImageTextComboListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ImageTextComboListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishKeywordListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: KeywordListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionChapterListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionChapterListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionChaptersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionChaptersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionSmlListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAccordionSmlListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAdsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AdManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAdsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AdManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyArticlesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBannersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBodyTypeGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBodyTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBodyTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBodyTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBrandsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BrandManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyBrandsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<BrandManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyButtonRowsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationPublishManyButtonRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationPublishManyButtonsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ButtonManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ButtonManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyCitatumsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<CitatumManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyCitatumsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<CitatumManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyColorNormsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ColorNormManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyColorNormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ColorNormManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyColorTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyColorTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyContentConfigurationsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyContentConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDealerEmployeesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationPublishManyDealerEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationPublishManyDealersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DealerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDealersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DealerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDepartmentsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DepartmentManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDepartmentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DepartmentManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDriveTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyDriveTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyEmissionClassesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyEmissionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyEmployeesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationPublishManyEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationPublishManyFactListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FactListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFactListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FactListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFeaturedVehiclesRowsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFeaturedVehiclesRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFuelTypeGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFuelTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFuelTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyFuelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyGalleriesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<GalleryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyGalleriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<GalleryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyGeneratedPageExtensionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyGeneratedPageExtensionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyHeroSlidersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyHeroSlidersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyImageTextComboListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyImageTextComboListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyImagesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ImageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyImagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ImageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyKeywordListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeywordListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyKeywordListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<KeywordListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyModelsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ModelManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ModelManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyOptionFacetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyOptionFacetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPaPromotionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPaPromotionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPagesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPollutionClassesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyPollutionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQualityCheckedsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQualityCheckedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQuestionAndAnswerListsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQuestionAndAnswerListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQuestionAndAnswersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyQuestionAndAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyRootsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationPublishManyRootsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationPublishManyServicesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ServiceManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyServicesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<ServiceManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManySpecialBoxesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationPublishManySpecialBoxesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationPublishManyTransmissionGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyTransmissionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyTransmissionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<TransmissionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyTransmissionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<TransmissionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyUpholsterysArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyUpholsterysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleClassesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleConditionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleConditionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleStatusesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleStatusesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehicleTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehiclesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVehiclesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VehicleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVideosArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VideoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyVideosConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<VideoManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishManyWhitelabelsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationPublishManyWhitelabelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  to?: Array<Stage>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationPublishModelArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ModelWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishOptionFacetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: OptionFacetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishPaPromotionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: PaPromotionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishPageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishPollutionClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: PollutionClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishQualityCheckedArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: QualityCheckedWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishQuestionAndAnswerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: QuestionAndAnswerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishQuestionAndAnswerListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: QuestionAndAnswerListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishRootArgs = {
  to?: Array<Stage>;
  where: RootWhereUniqueInput;
};


export type MutationPublishServiceArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: ServiceWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishSpecialBoxArgs = {
  to?: Array<Stage>;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationPublishTransmissionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: TransmissionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishTransmissionGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: TransmissionGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishUpholsteryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: UpholsteryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVehicleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VehicleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVehicleClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VehicleClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVehicleConditionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VehicleConditionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVehicleStatusArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VehicleStatusWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVehicleTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VehicleTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishVideoArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  to?: Array<Stage>;
  where: VideoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPublishWhitelabelArgs = {
  to?: Array<Stage>;
  where: WhitelabelWhereUniqueInput;
};


export type MutationSchedulePublishAccordionChapterArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: AccordionChapterWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishAccordionChapterListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: AccordionChapterListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishAccordionSmlListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: AccordionSmlListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishAdArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: AdWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: BannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishBodyTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: BodyTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishBodyTypeGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: BodyTypeGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishBrandArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: BrandWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishButtonArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ButtonWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishButtonRowArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ButtonRowWhereUniqueInput;
};


export type MutationSchedulePublishCitatumArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: CitatumWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishColorNormArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ColorNormWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishColorTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ColorTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishContentConfigurationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ContentConfigurationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishDealerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: DealerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishDealerEmployeeArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationSchedulePublishDepartmentArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: DepartmentWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishDriveTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: DriveTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishEmissionClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: EmissionClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishEmployeeArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: EmployeeWhereUniqueInput;
};


export type MutationSchedulePublishFactListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: FactListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishFeaturedVehiclesRowArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: FeaturedVehiclesRowWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishFuelTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: FuelTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishFuelTypeGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: FuelTypeGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishGalleryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: GalleryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishGeneratedPageExtensionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: GeneratedPageExtensionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishHeroSliderArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: HeroSliderWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishImageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ImageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishImageTextComboListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ImageTextComboListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishKeywordListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: KeywordListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishModelArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ModelWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishOptionFacetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: OptionFacetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishPaPromotionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: PaPromotionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishPageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishPollutionClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: PollutionClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishQualityCheckedArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: QualityCheckedWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishQuestionAndAnswerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: QuestionAndAnswerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishQuestionAndAnswerListArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: QuestionAndAnswerListWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishRootArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: RootWhereUniqueInput;
};


export type MutationSchedulePublishServiceArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: ServiceWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishSpecialBoxArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationSchedulePublishTransmissionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: TransmissionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishTransmissionGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: TransmissionGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishUpholsteryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: UpholsteryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVehicleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VehicleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVehicleClassArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VehicleClassWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVehicleConditionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VehicleConditionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVehicleStatusArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VehicleStatusWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVehicleTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VehicleTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishVideoArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: VideoWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSchedulePublishWhitelabelArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  to?: Array<Stage>;
  where: WhitelabelWhereUniqueInput;
};


export type MutationScheduleUnpublishAccordionChapterArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionChapterWhereUniqueInput;
};


export type MutationScheduleUnpublishAccordionChapterListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionChapterListWhereUniqueInput;
};


export type MutationScheduleUnpublishAccordionSmlListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionSmlListWhereUniqueInput;
};


export type MutationScheduleUnpublishAdArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AdWhereUniqueInput;
};


export type MutationScheduleUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ArticleWhereUniqueInput;
};


export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AssetWhereUniqueInput;
};


export type MutationScheduleUnpublishBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BannerWhereUniqueInput;
};


export type MutationScheduleUnpublishBodyTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BodyTypeWhereUniqueInput;
};


export type MutationScheduleUnpublishBodyTypeGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BodyTypeGroupWhereUniqueInput;
};


export type MutationScheduleUnpublishBrandArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BrandWhereUniqueInput;
};


export type MutationScheduleUnpublishButtonArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ButtonWhereUniqueInput;
};


export type MutationScheduleUnpublishButtonRowArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: ButtonRowWhereUniqueInput;
};


export type MutationScheduleUnpublishCitatumArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: CitatumWhereUniqueInput;
};


export type MutationScheduleUnpublishColorNormArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ColorNormWhereUniqueInput;
};


export type MutationScheduleUnpublishColorTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ColorTypeWhereUniqueInput;
};


export type MutationScheduleUnpublishContentConfigurationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ContentConfigurationWhereUniqueInput;
};


export type MutationScheduleUnpublishDealerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DealerWhereUniqueInput;
};


export type MutationScheduleUnpublishDealerEmployeeArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationScheduleUnpublishDepartmentArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DepartmentWhereUniqueInput;
};


export type MutationScheduleUnpublishDriveTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DriveTypeWhereUniqueInput;
};


export type MutationScheduleUnpublishEmissionClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: EmissionClassWhereUniqueInput;
};


export type MutationScheduleUnpublishEmployeeArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: EmployeeWhereUniqueInput;
};


export type MutationScheduleUnpublishFactListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FactListWhereUniqueInput;
};


export type MutationScheduleUnpublishFeaturedVehiclesRowArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type MutationScheduleUnpublishFuelTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FuelTypeWhereUniqueInput;
};


export type MutationScheduleUnpublishFuelTypeGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FuelTypeGroupWhereUniqueInput;
};


export type MutationScheduleUnpublishGalleryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: GalleryWhereUniqueInput;
};


export type MutationScheduleUnpublishGeneratedPageExtensionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type MutationScheduleUnpublishHeroSliderArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: HeroSliderWhereUniqueInput;
};


export type MutationScheduleUnpublishImageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ImageWhereUniqueInput;
};


export type MutationScheduleUnpublishImageTextComboListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ImageTextComboListWhereUniqueInput;
};


export type MutationScheduleUnpublishKeywordListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: KeywordListWhereUniqueInput;
};


export type MutationScheduleUnpublishModelArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ModelWhereUniqueInput;
};


export type MutationScheduleUnpublishOptionFacetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: OptionFacetWhereUniqueInput;
};


export type MutationScheduleUnpublishPaPromotionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PaPromotionWhereUniqueInput;
};


export type MutationScheduleUnpublishPageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PageWhereUniqueInput;
};


export type MutationScheduleUnpublishPollutionClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PollutionClassWhereUniqueInput;
};


export type MutationScheduleUnpublishQualityCheckedArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QualityCheckedWhereUniqueInput;
};


export type MutationScheduleUnpublishQuestionAndAnswerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QuestionAndAnswerWhereUniqueInput;
};


export type MutationScheduleUnpublishQuestionAndAnswerListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type MutationScheduleUnpublishRootArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: RootWhereUniqueInput;
};


export type MutationScheduleUnpublishServiceArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ServiceWhereUniqueInput;
};


export type MutationScheduleUnpublishSpecialBoxArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationScheduleUnpublishTransmissionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: TransmissionWhereUniqueInput;
};


export type MutationScheduleUnpublishTransmissionGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: TransmissionGroupWhereUniqueInput;
};


export type MutationScheduleUnpublishUpholsteryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: UpholsteryWhereUniqueInput;
};


export type MutationScheduleUnpublishVehicleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleWhereUniqueInput;
};


export type MutationScheduleUnpublishVehicleClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleClassWhereUniqueInput;
};


export type MutationScheduleUnpublishVehicleConditionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleConditionWhereUniqueInput;
};


export type MutationScheduleUnpublishVehicleStatusArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleStatusWhereUniqueInput;
};


export type MutationScheduleUnpublishVehicleTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleTypeWhereUniqueInput;
};


export type MutationScheduleUnpublishVideoArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VideoWhereUniqueInput;
};


export type MutationScheduleUnpublishWhitelabelArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  releaseId?: InputMaybe<Scalars['String']['input']>;
  where: WhitelabelWhereUniqueInput;
};


export type MutationUnpublishAccordionChapterArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionChapterWhereUniqueInput;
};


export type MutationUnpublishAccordionChapterListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionChapterListWhereUniqueInput;
};


export type MutationUnpublishAccordionSmlListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AccordionSmlListWhereUniqueInput;
};


export type MutationUnpublishAdArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AdWhereUniqueInput;
};


export type MutationUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ArticleWhereUniqueInput;
};


export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: AssetWhereUniqueInput;
};


export type MutationUnpublishBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BannerWhereUniqueInput;
};


export type MutationUnpublishBodyTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BodyTypeWhereUniqueInput;
};


export type MutationUnpublishBodyTypeGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BodyTypeGroupWhereUniqueInput;
};


export type MutationUnpublishBrandArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: BrandWhereUniqueInput;
};


export type MutationUnpublishButtonArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ButtonWhereUniqueInput;
};


export type MutationUnpublishButtonRowArgs = {
  from?: Array<Stage>;
  where: ButtonRowWhereUniqueInput;
};


export type MutationUnpublishCitatumArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: CitatumWhereUniqueInput;
};


export type MutationUnpublishColorNormArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ColorNormWhereUniqueInput;
};


export type MutationUnpublishColorTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ColorTypeWhereUniqueInput;
};


export type MutationUnpublishContentConfigurationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ContentConfigurationWhereUniqueInput;
};


export type MutationUnpublishDealerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DealerWhereUniqueInput;
};


export type MutationUnpublishDealerEmployeeArgs = {
  from?: Array<Stage>;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationUnpublishDepartmentArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DepartmentWhereUniqueInput;
};


export type MutationUnpublishDriveTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: DriveTypeWhereUniqueInput;
};


export type MutationUnpublishEmissionClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: EmissionClassWhereUniqueInput;
};


export type MutationUnpublishEmployeeArgs = {
  from?: Array<Stage>;
  where: EmployeeWhereUniqueInput;
};


export type MutationUnpublishFactListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FactListWhereUniqueInput;
};


export type MutationUnpublishFeaturedVehiclesRowArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type MutationUnpublishFuelTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FuelTypeWhereUniqueInput;
};


export type MutationUnpublishFuelTypeGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: FuelTypeGroupWhereUniqueInput;
};


export type MutationUnpublishGalleryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: GalleryWhereUniqueInput;
};


export type MutationUnpublishGeneratedPageExtensionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type MutationUnpublishHeroSliderArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: HeroSliderWhereUniqueInput;
};


export type MutationUnpublishImageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ImageWhereUniqueInput;
};


export type MutationUnpublishImageTextComboListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ImageTextComboListWhereUniqueInput;
};


export type MutationUnpublishKeywordListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: KeywordListWhereUniqueInput;
};


export type MutationUnpublishManyAccordionChapterListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationUnpublishManyAccordionChapterListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationUnpublishManyAccordionChaptersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationUnpublishManyAccordionChaptersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationUnpublishManyAccordionSmlListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationUnpublishManyAccordionSmlListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationUnpublishManyAdsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationUnpublishManyAdsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationUnpublishManyArticlesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationUnpublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUnpublishManyBannersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUnpublishManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUnpublishManyBodyTypeGroupsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationUnpublishManyBodyTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationUnpublishManyBodyTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationUnpublishManyBodyTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationUnpublishManyBrandsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationUnpublishManyBrandsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationUnpublishManyButtonRowsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationUnpublishManyButtonRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationUnpublishManyButtonsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationUnpublishManyButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationUnpublishManyCitatumsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationUnpublishManyCitatumsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationUnpublishManyColorNormsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationUnpublishManyColorNormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationUnpublishManyColorTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationUnpublishManyColorTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationUnpublishManyContentConfigurationsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationUnpublishManyContentConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationUnpublishManyDealerEmployeesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationUnpublishManyDealerEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationUnpublishManyDealersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationUnpublishManyDealersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationUnpublishManyDepartmentsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationUnpublishManyDepartmentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationUnpublishManyDriveTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationUnpublishManyDriveTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationUnpublishManyEmissionClassesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationUnpublishManyEmissionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationUnpublishManyEmployeesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationUnpublishManyEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationUnpublishManyFactListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationUnpublishManyFactListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationUnpublishManyFeaturedVehiclesRowsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationUnpublishManyFeaturedVehiclesRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationUnpublishManyFuelTypeGroupsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationUnpublishManyFuelTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationUnpublishManyFuelTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationUnpublishManyFuelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationUnpublishManyGalleriesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationUnpublishManyGalleriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationUnpublishManyGeneratedPageExtensionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationUnpublishManyGeneratedPageExtensionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationUnpublishManyHeroSlidersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationUnpublishManyHeroSlidersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationUnpublishManyImageTextComboListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationUnpublishManyImageTextComboListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationUnpublishManyImagesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationUnpublishManyImagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationUnpublishManyKeywordListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationUnpublishManyKeywordListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationUnpublishManyModelsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationUnpublishManyModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationUnpublishManyOptionFacetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationUnpublishManyOptionFacetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationUnpublishManyPaPromotionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationUnpublishManyPaPromotionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationUnpublishManyPagesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationUnpublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationUnpublishManyPollutionClassesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationUnpublishManyPollutionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationUnpublishManyQualityCheckedsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationUnpublishManyQualityCheckedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationUnpublishManyQuestionAndAnswerListsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationUnpublishManyQuestionAndAnswerListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationUnpublishManyQuestionAndAnswersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationUnpublishManyQuestionAndAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationUnpublishManyRootsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationUnpublishManyRootsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationUnpublishManyServicesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationUnpublishManyServicesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationUnpublishManySpecialBoxesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationUnpublishManySpecialBoxesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationUnpublishManyTransmissionGroupsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationUnpublishManyTransmissionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationUnpublishManyTransmissionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationUnpublishManyTransmissionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationUnpublishManyUpholsterysArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationUnpublishManyUpholsterysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationUnpublishManyVehicleClassesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationUnpublishManyVehicleClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationUnpublishManyVehicleConditionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationUnpublishManyVehicleConditionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationUnpublishManyVehicleStatusesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationUnpublishManyVehicleStatusesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationUnpublishManyVehicleTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationUnpublishManyVehicleTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationUnpublishManyVehiclesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationUnpublishManyVehiclesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationUnpublishManyVideosArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationUnpublishManyVideosConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationUnpublishManyWhitelabelsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationUnpublishManyWhitelabelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationUnpublishModelArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ModelWhereUniqueInput;
};


export type MutationUnpublishOptionFacetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: OptionFacetWhereUniqueInput;
};


export type MutationUnpublishPaPromotionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PaPromotionWhereUniqueInput;
};


export type MutationUnpublishPageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PageWhereUniqueInput;
};


export type MutationUnpublishPollutionClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: PollutionClassWhereUniqueInput;
};


export type MutationUnpublishQualityCheckedArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QualityCheckedWhereUniqueInput;
};


export type MutationUnpublishQuestionAndAnswerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QuestionAndAnswerWhereUniqueInput;
};


export type MutationUnpublishQuestionAndAnswerListArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type MutationUnpublishRootArgs = {
  from?: Array<Stage>;
  where: RootWhereUniqueInput;
};


export type MutationUnpublishServiceArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: ServiceWhereUniqueInput;
};


export type MutationUnpublishSpecialBoxArgs = {
  from?: Array<Stage>;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationUnpublishTransmissionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: TransmissionWhereUniqueInput;
};


export type MutationUnpublishTransmissionGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: TransmissionGroupWhereUniqueInput;
};


export type MutationUnpublishUpholsteryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: UpholsteryWhereUniqueInput;
};


export type MutationUnpublishVehicleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleWhereUniqueInput;
};


export type MutationUnpublishVehicleClassArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleClassWhereUniqueInput;
};


export type MutationUnpublishVehicleConditionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleConditionWhereUniqueInput;
};


export type MutationUnpublishVehicleStatusArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleStatusWhereUniqueInput;
};


export type MutationUnpublishVehicleTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VehicleTypeWhereUniqueInput;
};


export type MutationUnpublishVideoArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>;
  where: VideoWhereUniqueInput;
};


export type MutationUnpublishWhitelabelArgs = {
  from?: Array<Stage>;
  where: WhitelabelWhereUniqueInput;
};


export type MutationUpdateAccordionChapterArgs = {
  data: AccordionChapterUpdateInput;
  where: AccordionChapterWhereUniqueInput;
};


export type MutationUpdateAccordionChapterListArgs = {
  data: AccordionChapterListUpdateInput;
  where: AccordionChapterListWhereUniqueInput;
};


export type MutationUpdateAccordionSmlListArgs = {
  data: AccordionSmlListUpdateInput;
  where: AccordionSmlListWhereUniqueInput;
};


export type MutationUpdateAdArgs = {
  data: AdUpdateInput;
  where: AdWhereUniqueInput;
};


export type MutationUpdateArticleArgs = {
  data: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
};


export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};


export type MutationUpdateBannerArgs = {
  data: BannerUpdateInput;
  where: BannerWhereUniqueInput;
};


export type MutationUpdateBodyTypeArgs = {
  data: BodyTypeUpdateInput;
  where: BodyTypeWhereUniqueInput;
};


export type MutationUpdateBodyTypeGroupArgs = {
  data: BodyTypeGroupUpdateInput;
  where: BodyTypeGroupWhereUniqueInput;
};


export type MutationUpdateBrandArgs = {
  data: BrandUpdateInput;
  where: BrandWhereUniqueInput;
};


export type MutationUpdateButtonArgs = {
  data: ButtonUpdateInput;
  where: ButtonWhereUniqueInput;
};


export type MutationUpdateButtonRowArgs = {
  data: ButtonRowUpdateInput;
  where: ButtonRowWhereUniqueInput;
};


export type MutationUpdateCitatumArgs = {
  data: CitatumUpdateInput;
  where: CitatumWhereUniqueInput;
};


export type MutationUpdateColorNormArgs = {
  data: ColorNormUpdateInput;
  where: ColorNormWhereUniqueInput;
};


export type MutationUpdateColorTypeArgs = {
  data: ColorTypeUpdateInput;
  where: ColorTypeWhereUniqueInput;
};


export type MutationUpdateContentConfigurationArgs = {
  data: ContentConfigurationUpdateInput;
  where: ContentConfigurationWhereUniqueInput;
};


export type MutationUpdateDealerArgs = {
  data: DealerUpdateInput;
  where: DealerWhereUniqueInput;
};


export type MutationUpdateDealerEmployeeArgs = {
  data: DealerEmployeeUpdateInput;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationUpdateDepartmentArgs = {
  data: DepartmentUpdateInput;
  where: DepartmentWhereUniqueInput;
};


export type MutationUpdateDriveTypeArgs = {
  data: DriveTypeUpdateInput;
  where: DriveTypeWhereUniqueInput;
};


export type MutationUpdateEmissionClassArgs = {
  data: EmissionClassUpdateInput;
  where: EmissionClassWhereUniqueInput;
};


export type MutationUpdateEmployeeArgs = {
  data: EmployeeUpdateInput;
  where: EmployeeWhereUniqueInput;
};


export type MutationUpdateFactListArgs = {
  data: FactListUpdateInput;
  where: FactListWhereUniqueInput;
};


export type MutationUpdateFeaturedVehiclesRowArgs = {
  data: FeaturedVehiclesRowUpdateInput;
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type MutationUpdateFuelTypeArgs = {
  data: FuelTypeUpdateInput;
  where: FuelTypeWhereUniqueInput;
};


export type MutationUpdateFuelTypeGroupArgs = {
  data: FuelTypeGroupUpdateInput;
  where: FuelTypeGroupWhereUniqueInput;
};


export type MutationUpdateGalleryArgs = {
  data: GalleryUpdateInput;
  where: GalleryWhereUniqueInput;
};


export type MutationUpdateGeneratedPageExtensionArgs = {
  data: GeneratedPageExtensionUpdateInput;
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type MutationUpdateHeroSliderArgs = {
  data: HeroSliderUpdateInput;
  where: HeroSliderWhereUniqueInput;
};


export type MutationUpdateImageArgs = {
  data: ImageUpdateInput;
  where: ImageWhereUniqueInput;
};


export type MutationUpdateImageTextComboListArgs = {
  data: ImageTextComboListUpdateInput;
  where: ImageTextComboListWhereUniqueInput;
};


export type MutationUpdateKeywordListArgs = {
  data: KeywordListUpdateInput;
  where: KeywordListWhereUniqueInput;
};


export type MutationUpdateManyAccordionChapterListsArgs = {
  data: AccordionChapterListUpdateManyInput;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationUpdateManyAccordionChapterListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: AccordionChapterListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterListManyWhereInput>;
};


export type MutationUpdateManyAccordionChaptersArgs = {
  data: AccordionChapterUpdateManyInput;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationUpdateManyAccordionChaptersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: AccordionChapterUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionChapterManyWhereInput>;
};


export type MutationUpdateManyAccordionSmlListsArgs = {
  data: AccordionSmlListUpdateManyInput;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationUpdateManyAccordionSmlListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: AccordionSmlListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AccordionSmlListManyWhereInput>;
};


export type MutationUpdateManyAdsArgs = {
  data: AdUpdateManyInput;
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationUpdateManyAdsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: AdUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AdManyWhereInput>;
};


export type MutationUpdateManyArticlesArgs = {
  data: ArticleUpdateManyInput;
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationUpdateManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ArticleUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};


export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetManyWhereInput>;
};


export type MutationUpdateManyBannersArgs = {
  data: BannerUpdateManyInput;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUpdateManyBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: BannerUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BannerManyWhereInput>;
};


export type MutationUpdateManyBodyTypeGroupsArgs = {
  data: BodyTypeGroupUpdateManyInput;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationUpdateManyBodyTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: BodyTypeGroupUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeGroupManyWhereInput>;
};


export type MutationUpdateManyBodyTypesArgs = {
  data: BodyTypeUpdateManyInput;
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationUpdateManyBodyTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: BodyTypeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BodyTypeManyWhereInput>;
};


export type MutationUpdateManyBrandsArgs = {
  data: BrandUpdateManyInput;
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationUpdateManyBrandsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: BrandUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandManyWhereInput>;
};


export type MutationUpdateManyButtonRowsArgs = {
  data: ButtonRowUpdateManyInput;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationUpdateManyButtonRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ButtonRowUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonRowManyWhereInput>;
};


export type MutationUpdateManyButtonsArgs = {
  data: ButtonUpdateManyInput;
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationUpdateManyButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ButtonUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ButtonManyWhereInput>;
};


export type MutationUpdateManyCitatumsArgs = {
  data: CitatumUpdateManyInput;
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationUpdateManyCitatumsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: CitatumUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CitatumManyWhereInput>;
};


export type MutationUpdateManyColorNormsArgs = {
  data: ColorNormUpdateManyInput;
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationUpdateManyColorNormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ColorNormUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ColorNormManyWhereInput>;
};


export type MutationUpdateManyColorTypesArgs = {
  data: ColorTypeUpdateManyInput;
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationUpdateManyColorTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ColorTypeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ColorTypeManyWhereInput>;
};


export type MutationUpdateManyContentConfigurationsArgs = {
  data: ContentConfigurationUpdateManyInput;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationUpdateManyContentConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ContentConfigurationUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContentConfigurationManyWhereInput>;
};


export type MutationUpdateManyDealerEmployeesArgs = {
  data: DealerEmployeeUpdateManyInput;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationUpdateManyDealerEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: DealerEmployeeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerEmployeeManyWhereInput>;
};


export type MutationUpdateManyDealersArgs = {
  data: DealerUpdateManyInput;
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationUpdateManyDealersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: DealerUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerManyWhereInput>;
};


export type MutationUpdateManyDepartmentsArgs = {
  data: DepartmentUpdateManyInput;
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationUpdateManyDepartmentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: DepartmentUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DepartmentManyWhereInput>;
};


export type MutationUpdateManyDriveTypesArgs = {
  data: DriveTypeUpdateManyInput;
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationUpdateManyDriveTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: DriveTypeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DriveTypeManyWhereInput>;
};


export type MutationUpdateManyEmissionClassesArgs = {
  data: EmissionClassUpdateManyInput;
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationUpdateManyEmissionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: EmissionClassUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmissionClassManyWhereInput>;
};


export type MutationUpdateManyEmployeesArgs = {
  data: EmployeeUpdateManyInput;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationUpdateManyEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: EmployeeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EmployeeManyWhereInput>;
};


export type MutationUpdateManyFactListsArgs = {
  data: FactListUpdateManyInput;
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationUpdateManyFactListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: FactListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FactListManyWhereInput>;
};


export type MutationUpdateManyFeaturedVehiclesRowsArgs = {
  data: FeaturedVehiclesRowUpdateManyInput;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationUpdateManyFeaturedVehiclesRowsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: FeaturedVehiclesRowUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FeaturedVehiclesRowManyWhereInput>;
};


export type MutationUpdateManyFuelTypeGroupsArgs = {
  data: FuelTypeGroupUpdateManyInput;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationUpdateManyFuelTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: FuelTypeGroupUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FuelTypeGroupManyWhereInput>;
};


export type MutationUpdateManyFuelTypesArgs = {
  data: FuelTypeUpdateManyInput;
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationUpdateManyFuelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: FuelTypeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FuelTypeManyWhereInput>;
};


export type MutationUpdateManyGalleriesArgs = {
  data: GalleryUpdateManyInput;
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationUpdateManyGalleriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: GalleryUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GalleryManyWhereInput>;
};


export type MutationUpdateManyGeneratedPageExtensionsArgs = {
  data: GeneratedPageExtensionUpdateManyInput;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationUpdateManyGeneratedPageExtensionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: GeneratedPageExtensionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GeneratedPageExtensionManyWhereInput>;
};


export type MutationUpdateManyHeroSlidersArgs = {
  data: HeroSliderUpdateManyInput;
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationUpdateManyHeroSlidersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: HeroSliderUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HeroSliderManyWhereInput>;
};


export type MutationUpdateManyImageTextComboListsArgs = {
  data: ImageTextComboListUpdateManyInput;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationUpdateManyImageTextComboListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ImageTextComboListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageTextComboListManyWhereInput>;
};


export type MutationUpdateManyImagesArgs = {
  data: ImageUpdateManyInput;
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationUpdateManyImagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ImageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImageManyWhereInput>;
};


export type MutationUpdateManyKeywordListsArgs = {
  data: KeywordListUpdateManyInput;
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationUpdateManyKeywordListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: KeywordListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListManyWhereInput>;
};


export type MutationUpdateManyModelsArgs = {
  data: ModelUpdateManyInput;
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationUpdateManyModelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ModelUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ModelManyWhereInput>;
};


export type MutationUpdateManyOptionFacetsArgs = {
  data: OptionFacetUpdateManyInput;
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationUpdateManyOptionFacetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: OptionFacetUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionFacetManyWhereInput>;
};


export type MutationUpdateManyPaPromotionsArgs = {
  data: PaPromotionUpdateManyInput;
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationUpdateManyPaPromotionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: PaPromotionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaPromotionManyWhereInput>;
};


export type MutationUpdateManyPagesArgs = {
  data: PageUpdateManyInput;
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationUpdateManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: PageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageManyWhereInput>;
};


export type MutationUpdateManyPollutionClassesArgs = {
  data: PollutionClassUpdateManyInput;
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationUpdateManyPollutionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: PollutionClassUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PollutionClassManyWhereInput>;
};


export type MutationUpdateManyQualityCheckedsArgs = {
  data: QualityCheckedUpdateManyInput;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationUpdateManyQualityCheckedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: QualityCheckedUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QualityCheckedManyWhereInput>;
};


export type MutationUpdateManyQuestionAndAnswerListsArgs = {
  data: QuestionAndAnswerListUpdateManyInput;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationUpdateManyQuestionAndAnswerListsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: QuestionAndAnswerListUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerListManyWhereInput>;
};


export type MutationUpdateManyQuestionAndAnswersArgs = {
  data: QuestionAndAnswerUpdateManyInput;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationUpdateManyQuestionAndAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: QuestionAndAnswerUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerManyWhereInput>;
};


export type MutationUpdateManyRootsArgs = {
  data: RootUpdateManyInput;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationUpdateManyRootsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: RootUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RootManyWhereInput>;
};


export type MutationUpdateManyServicesArgs = {
  data: ServiceUpdateManyInput;
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationUpdateManyServicesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: ServiceUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceManyWhereInput>;
};


export type MutationUpdateManySpecialBoxesArgs = {
  data: SpecialBoxUpdateManyInput;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationUpdateManySpecialBoxesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: SpecialBoxUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SpecialBoxManyWhereInput>;
};


export type MutationUpdateManyTransmissionGroupsArgs = {
  data: TransmissionGroupUpdateManyInput;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationUpdateManyTransmissionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: TransmissionGroupUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransmissionGroupManyWhereInput>;
};


export type MutationUpdateManyTransmissionsArgs = {
  data: TransmissionUpdateManyInput;
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationUpdateManyTransmissionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: TransmissionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransmissionManyWhereInput>;
};


export type MutationUpdateManyUpholsterysArgs = {
  data: UpholsteryUpdateManyInput;
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationUpdateManyUpholsterysConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: UpholsteryUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UpholsteryManyWhereInput>;
};


export type MutationUpdateManyVehicleClassesArgs = {
  data: VehicleClassUpdateManyInput;
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationUpdateManyVehicleClassesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VehicleClassUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleClassManyWhereInput>;
};


export type MutationUpdateManyVehicleConditionsArgs = {
  data: VehicleConditionUpdateManyInput;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationUpdateManyVehicleConditionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VehicleConditionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleConditionManyWhereInput>;
};


export type MutationUpdateManyVehicleStatusesArgs = {
  data: VehicleStatusUpdateManyInput;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationUpdateManyVehicleStatusesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VehicleStatusUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleStatusManyWhereInput>;
};


export type MutationUpdateManyVehicleTypesArgs = {
  data: VehicleTypeUpdateManyInput;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationUpdateManyVehicleTypesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VehicleTypeUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleTypeManyWhereInput>;
};


export type MutationUpdateManyVehiclesArgs = {
  data: VehicleUpdateManyInput;
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationUpdateManyVehiclesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VehicleUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleManyWhereInput>;
};


export type MutationUpdateManyVideosArgs = {
  data: VideoUpdateManyInput;
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationUpdateManyVideosConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: VideoUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VideoManyWhereInput>;
};


export type MutationUpdateManyWhitelabelsArgs = {
  data: WhitelabelUpdateManyInput;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationUpdateManyWhitelabelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>;
  before?: InputMaybe<Scalars['ID']['input']>;
  data: WhitelabelUpdateManyInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WhitelabelManyWhereInput>;
};


export type MutationUpdateModelArgs = {
  data: ModelUpdateInput;
  where: ModelWhereUniqueInput;
};


export type MutationUpdateOptionFacetArgs = {
  data: OptionFacetUpdateInput;
  where: OptionFacetWhereUniqueInput;
};


export type MutationUpdatePaPromotionArgs = {
  data: PaPromotionUpdateInput;
  where: PaPromotionWhereUniqueInput;
};


export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};


export type MutationUpdatePollutionClassArgs = {
  data: PollutionClassUpdateInput;
  where: PollutionClassWhereUniqueInput;
};


export type MutationUpdateQualityCheckedArgs = {
  data: QualityCheckedUpdateInput;
  where: QualityCheckedWhereUniqueInput;
};


export type MutationUpdateQuestionAndAnswerArgs = {
  data: QuestionAndAnswerUpdateInput;
  where: QuestionAndAnswerWhereUniqueInput;
};


export type MutationUpdateQuestionAndAnswerListArgs = {
  data: QuestionAndAnswerListUpdateInput;
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type MutationUpdateRootArgs = {
  data: RootUpdateInput;
  where: RootWhereUniqueInput;
};


export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};


export type MutationUpdateServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpdateSpecialBoxArgs = {
  data: SpecialBoxUpdateInput;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationUpdateTransmissionArgs = {
  data: TransmissionUpdateInput;
  where: TransmissionWhereUniqueInput;
};


export type MutationUpdateTransmissionGroupArgs = {
  data: TransmissionGroupUpdateInput;
  where: TransmissionGroupWhereUniqueInput;
};


export type MutationUpdateUpholsteryArgs = {
  data: UpholsteryUpdateInput;
  where: UpholsteryWhereUniqueInput;
};


export type MutationUpdateVehicleArgs = {
  data: VehicleUpdateInput;
  where: VehicleWhereUniqueInput;
};


export type MutationUpdateVehicleClassArgs = {
  data: VehicleClassUpdateInput;
  where: VehicleClassWhereUniqueInput;
};


export type MutationUpdateVehicleConditionArgs = {
  data: VehicleConditionUpdateInput;
  where: VehicleConditionWhereUniqueInput;
};


export type MutationUpdateVehicleStatusArgs = {
  data: VehicleStatusUpdateInput;
  where: VehicleStatusWhereUniqueInput;
};


export type MutationUpdateVehicleTypeArgs = {
  data: VehicleTypeUpdateInput;
  where: VehicleTypeWhereUniqueInput;
};


export type MutationUpdateVideoArgs = {
  data: VideoUpdateInput;
  where: VideoWhereUniqueInput;
};


export type MutationUpdateWhitelabelArgs = {
  data: WhitelabelUpdateInput;
  where: WhitelabelWhereUniqueInput;
};


export type MutationUpsertAccordionChapterArgs = {
  upsert: AccordionChapterUpsertInput;
  where: AccordionChapterWhereUniqueInput;
};


export type MutationUpsertAccordionChapterListArgs = {
  upsert: AccordionChapterListUpsertInput;
  where: AccordionChapterListWhereUniqueInput;
};


export type MutationUpsertAccordionSmlListArgs = {
  upsert: AccordionSmlListUpsertInput;
  where: AccordionSmlListWhereUniqueInput;
};


export type MutationUpsertAdArgs = {
  upsert: AdUpsertInput;
  where: AdWhereUniqueInput;
};


export type MutationUpsertArticleArgs = {
  upsert: ArticleUpsertInput;
  where: ArticleWhereUniqueInput;
};


export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};


export type MutationUpsertBannerArgs = {
  upsert: BannerUpsertInput;
  where: BannerWhereUniqueInput;
};


export type MutationUpsertBodyTypeArgs = {
  upsert: BodyTypeUpsertInput;
  where: BodyTypeWhereUniqueInput;
};


export type MutationUpsertBodyTypeGroupArgs = {
  upsert: BodyTypeGroupUpsertInput;
  where: BodyTypeGroupWhereUniqueInput;
};


export type MutationUpsertBrandArgs = {
  upsert: BrandUpsertInput;
  where: BrandWhereUniqueInput;
};


export type MutationUpsertButtonArgs = {
  upsert: ButtonUpsertInput;
  where: ButtonWhereUniqueInput;
};


export type MutationUpsertButtonRowArgs = {
  upsert: ButtonRowUpsertInput;
  where: ButtonRowWhereUniqueInput;
};


export type MutationUpsertCitatumArgs = {
  upsert: CitatumUpsertInput;
  where: CitatumWhereUniqueInput;
};


export type MutationUpsertColorNormArgs = {
  upsert: ColorNormUpsertInput;
  where: ColorNormWhereUniqueInput;
};


export type MutationUpsertColorTypeArgs = {
  upsert: ColorTypeUpsertInput;
  where: ColorTypeWhereUniqueInput;
};


export type MutationUpsertContentConfigurationArgs = {
  upsert: ContentConfigurationUpsertInput;
  where: ContentConfigurationWhereUniqueInput;
};


export type MutationUpsertDealerArgs = {
  upsert: DealerUpsertInput;
  where: DealerWhereUniqueInput;
};


export type MutationUpsertDealerEmployeeArgs = {
  upsert: DealerEmployeeUpsertInput;
  where: DealerEmployeeWhereUniqueInput;
};


export type MutationUpsertDepartmentArgs = {
  upsert: DepartmentUpsertInput;
  where: DepartmentWhereUniqueInput;
};


export type MutationUpsertDriveTypeArgs = {
  upsert: DriveTypeUpsertInput;
  where: DriveTypeWhereUniqueInput;
};


export type MutationUpsertEmissionClassArgs = {
  upsert: EmissionClassUpsertInput;
  where: EmissionClassWhereUniqueInput;
};


export type MutationUpsertEmployeeArgs = {
  upsert: EmployeeUpsertInput;
  where: EmployeeWhereUniqueInput;
};


export type MutationUpsertFactListArgs = {
  upsert: FactListUpsertInput;
  where: FactListWhereUniqueInput;
};


export type MutationUpsertFeaturedVehiclesRowArgs = {
  upsert: FeaturedVehiclesRowUpsertInput;
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type MutationUpsertFuelTypeArgs = {
  upsert: FuelTypeUpsertInput;
  where: FuelTypeWhereUniqueInput;
};


export type MutationUpsertFuelTypeGroupArgs = {
  upsert: FuelTypeGroupUpsertInput;
  where: FuelTypeGroupWhereUniqueInput;
};


export type MutationUpsertGalleryArgs = {
  upsert: GalleryUpsertInput;
  where: GalleryWhereUniqueInput;
};


export type MutationUpsertGeneratedPageExtensionArgs = {
  upsert: GeneratedPageExtensionUpsertInput;
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type MutationUpsertHeroSliderArgs = {
  upsert: HeroSliderUpsertInput;
  where: HeroSliderWhereUniqueInput;
};


export type MutationUpsertImageArgs = {
  upsert: ImageUpsertInput;
  where: ImageWhereUniqueInput;
};


export type MutationUpsertImageTextComboListArgs = {
  upsert: ImageTextComboListUpsertInput;
  where: ImageTextComboListWhereUniqueInput;
};


export type MutationUpsertKeywordListArgs = {
  upsert: KeywordListUpsertInput;
  where: KeywordListWhereUniqueInput;
};


export type MutationUpsertModelArgs = {
  upsert: ModelUpsertInput;
  where: ModelWhereUniqueInput;
};


export type MutationUpsertOptionFacetArgs = {
  upsert: OptionFacetUpsertInput;
  where: OptionFacetWhereUniqueInput;
};


export type MutationUpsertPaPromotionArgs = {
  upsert: PaPromotionUpsertInput;
  where: PaPromotionWhereUniqueInput;
};


export type MutationUpsertPageArgs = {
  upsert: PageUpsertInput;
  where: PageWhereUniqueInput;
};


export type MutationUpsertPollutionClassArgs = {
  upsert: PollutionClassUpsertInput;
  where: PollutionClassWhereUniqueInput;
};


export type MutationUpsertQualityCheckedArgs = {
  upsert: QualityCheckedUpsertInput;
  where: QualityCheckedWhereUniqueInput;
};


export type MutationUpsertQuestionAndAnswerArgs = {
  upsert: QuestionAndAnswerUpsertInput;
  where: QuestionAndAnswerWhereUniqueInput;
};


export type MutationUpsertQuestionAndAnswerListArgs = {
  upsert: QuestionAndAnswerListUpsertInput;
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type MutationUpsertRootArgs = {
  upsert: RootUpsertInput;
  where: RootWhereUniqueInput;
};


export type MutationUpsertServiceArgs = {
  upsert: ServiceUpsertInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpsertSpecialBoxArgs = {
  upsert: SpecialBoxUpsertInput;
  where: SpecialBoxWhereUniqueInput;
};


export type MutationUpsertTransmissionArgs = {
  upsert: TransmissionUpsertInput;
  where: TransmissionWhereUniqueInput;
};


export type MutationUpsertTransmissionGroupArgs = {
  upsert: TransmissionGroupUpsertInput;
  where: TransmissionGroupWhereUniqueInput;
};


export type MutationUpsertUpholsteryArgs = {
  upsert: UpholsteryUpsertInput;
  where: UpholsteryWhereUniqueInput;
};


export type MutationUpsertVehicleArgs = {
  upsert: VehicleUpsertInput;
  where: VehicleWhereUniqueInput;
};


export type MutationUpsertVehicleClassArgs = {
  upsert: VehicleClassUpsertInput;
  where: VehicleClassWhereUniqueInput;
};


export type MutationUpsertVehicleConditionArgs = {
  upsert: VehicleConditionUpsertInput;
  where: VehicleConditionWhereUniqueInput;
};


export type MutationUpsertVehicleStatusArgs = {
  upsert: VehicleStatusUpsertInput;
  where: VehicleStatusWhereUniqueInput;
};


export type MutationUpsertVehicleTypeArgs = {
  upsert: VehicleTypeUpsertInput;
  where: VehicleTypeWhereUniqueInput;
};


export type MutationUpsertVideoArgs = {
  upsert: VideoUpsertInput;
  where: VideoWhereUniqueInput;
};


export type MutationUpsertWhitelabelArgs = {
  upsert: WhitelabelUpsertInput;
  where: WhitelabelWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
  /** The Stage of an object */
  stage: Stage;
};

export type OptionFacet = Entity & Node & {
  __typename?: 'OptionFacet';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<OptionFacet>;
  /** List of OptionFacet versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<OptionFacet>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type OptionFacetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type OptionFacetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type OptionFacetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type OptionFacetHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type OptionFacetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type OptionFacetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type OptionFacetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type OptionFacetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type OptionFacetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type OptionFacetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type OptionFacetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: OptionFacetWhereUniqueInput;
};

/** A connection to a list of items. */
export type OptionFacetConnection = {
  __typename?: 'OptionFacetConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<OptionFacetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type OptionFacetCreateInput = {
  cl332wmsi3s8h01xq10uy71xc?: InputMaybe<VehicleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<OptionFacetCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OptionFacetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OptionFacetCreateLocalizationInput = {
  /** Localization input */
  data: OptionFacetCreateLocalizationDataInput;
  locale: Locale;
};

export type OptionFacetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<OptionFacetCreateLocalizationInput>>;
};

export type OptionFacetCreateManyInlineInput = {
  /** Connect multiple existing OptionFacet documents */
  connect?: InputMaybe<Array<OptionFacetWhereUniqueInput>>;
  /** Create and connect multiple existing OptionFacet documents */
  create?: InputMaybe<Array<OptionFacetCreateInput>>;
};

export type OptionFacetCreateOneInlineInput = {
  /** Connect one existing OptionFacet document */
  connect?: InputMaybe<OptionFacetWhereUniqueInput>;
  /** Create and connect one OptionFacet document */
  create?: InputMaybe<OptionFacetCreateInput>;
};

/** An edge in a connection. */
export type OptionFacetEdge = {
  __typename?: 'OptionFacetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OptionFacet;
};

/** Identifies documents */
export type OptionFacetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<OptionFacetWhereStageInput>;
  documentInStages_none?: InputMaybe<OptionFacetWhereStageInput>;
  documentInStages_some?: InputMaybe<OptionFacetWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum OptionFacetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type OptionFacetUpdateInput = {
  cl332wmsi3s8h01xq10uy71xc?: InputMaybe<VehicleUpdateManyInlineInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<OptionFacetUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type OptionFacetUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type OptionFacetUpdateLocalizationInput = {
  data: OptionFacetUpdateLocalizationDataInput;
  locale: Locale;
};

export type OptionFacetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<OptionFacetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<OptionFacetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<OptionFacetUpsertLocalizationInput>>;
};

export type OptionFacetUpdateManyInlineInput = {
  /** Connect multiple existing OptionFacet documents */
  connect?: InputMaybe<Array<OptionFacetConnectInput>>;
  /** Create and connect multiple OptionFacet documents */
  create?: InputMaybe<Array<OptionFacetCreateInput>>;
  /** Delete multiple OptionFacet documents */
  delete?: InputMaybe<Array<OptionFacetWhereUniqueInput>>;
  /** Disconnect multiple OptionFacet documents */
  disconnect?: InputMaybe<Array<OptionFacetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing OptionFacet documents */
  set?: InputMaybe<Array<OptionFacetWhereUniqueInput>>;
  /** Update multiple OptionFacet documents */
  update?: InputMaybe<Array<OptionFacetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple OptionFacet documents */
  upsert?: InputMaybe<Array<OptionFacetUpsertWithNestedWhereUniqueInput>>;
};

export type OptionFacetUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<OptionFacetUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type OptionFacetUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type OptionFacetUpdateManyLocalizationInput = {
  data: OptionFacetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type OptionFacetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<OptionFacetUpdateManyLocalizationInput>>;
};

export type OptionFacetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: OptionFacetUpdateManyInput;
  /** Document search */
  where: OptionFacetWhereInput;
};

export type OptionFacetUpdateOneInlineInput = {
  /** Connect existing OptionFacet document */
  connect?: InputMaybe<OptionFacetWhereUniqueInput>;
  /** Create and connect one OptionFacet document */
  create?: InputMaybe<OptionFacetCreateInput>;
  /** Delete currently connected OptionFacet document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected OptionFacet document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single OptionFacet document */
  update?: InputMaybe<OptionFacetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single OptionFacet document */
  upsert?: InputMaybe<OptionFacetUpsertWithNestedWhereUniqueInput>;
};

export type OptionFacetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: OptionFacetUpdateInput;
  /** Unique document search */
  where: OptionFacetWhereUniqueInput;
};

export type OptionFacetUpsertInput = {
  /** Create document if it didn't exist */
  create: OptionFacetCreateInput;
  /** Update document if it exists */
  update: OptionFacetUpdateInput;
};

export type OptionFacetUpsertLocalizationInput = {
  create: OptionFacetCreateLocalizationDataInput;
  locale: Locale;
  update: OptionFacetUpdateLocalizationDataInput;
};

export type OptionFacetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: OptionFacetUpsertInput;
  /** Unique document search */
  where: OptionFacetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type OptionFacetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type OptionFacetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OptionFacetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<OptionFacetWhereStageInput>;
  documentInStages_none?: InputMaybe<OptionFacetWhereStageInput>;
  documentInStages_some?: InputMaybe<OptionFacetWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type OptionFacetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OptionFacetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OptionFacetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OptionFacetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<OptionFacetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References OptionFacet record uniquely */
export type OptionFacetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export enum OptionType {
  A = 'A',
  O = 'O',
  P = 'P',
  S = 'S'
}

export type PaPromotion = Entity & Node & {
  __typename?: 'PaPromotion';
  active: Scalars['Boolean']['output'];
  brand?: Maybe<Brand>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  /** Get the document in other stages */
  documentInStages: Array<PaPromotion>;
  /** List of PaPromotion versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  legalLine?: Maybe<Scalars['String']['output']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PaPromotion>;
  name: Scalars['String']['output'];
  paPromotionComponentId: Scalars['Int']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  slug: Scalars['String']['output'];
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  validFromDate?: Maybe<Scalars['Date']['output']>;
  validUntilDate?: Maybe<Scalars['Date']['output']>;
};


export type PaPromotionBrandArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PaPromotionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PaPromotionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PaPromotionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type PaPromotionHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type PaPromotionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type PaPromotionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PaPromotionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PaPromotionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type PaPromotionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PaPromotionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PaPromotionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PaPromotionWhereUniqueInput;
};

/** A connection to a list of items. */
export type PaPromotionConnection = {
  __typename?: 'PaPromotionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PaPromotionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PaPromotionCreateInput = {
  active: Scalars['Boolean']['input'];
  brand?: InputMaybe<BrandCreateOneInlineInput>;
  clyfmnpau024607um268f5eek?: InputMaybe<VehiclePromotionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** legalLine input for default locale (de) */
  legalLine?: InputMaybe<Scalars['String']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PaPromotionCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  paPromotionComponentId: Scalars['Int']['input'];
  /** slug input for default locale (de) */
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  validFromDate?: InputMaybe<Scalars['Date']['input']>;
  validUntilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PaPromotionCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  legalLine?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PaPromotionCreateLocalizationInput = {
  /** Localization input */
  data: PaPromotionCreateLocalizationDataInput;
  locale: Locale;
};

export type PaPromotionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PaPromotionCreateLocalizationInput>>;
};

export type PaPromotionCreateManyInlineInput = {
  /** Connect multiple existing PaPromotion documents */
  connect?: InputMaybe<Array<PaPromotionWhereUniqueInput>>;
  /** Create and connect multiple existing PaPromotion documents */
  create?: InputMaybe<Array<PaPromotionCreateInput>>;
};

export type PaPromotionCreateOneInlineInput = {
  /** Connect one existing PaPromotion document */
  connect?: InputMaybe<PaPromotionWhereUniqueInput>;
  /** Create and connect one PaPromotion document */
  create?: InputMaybe<PaPromotionCreateInput>;
};

/** An edge in a connection. */
export type PaPromotionEdge = {
  __typename?: 'PaPromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PaPromotion;
};

/** Identifies documents */
export type PaPromotionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PaPromotionWhereStageInput>;
  documentInStages_none?: InputMaybe<PaPromotionWhereStageInput>;
  documentInStages_some?: InputMaybe<PaPromotionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  paPromotionComponentId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  paPromotionComponentId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  paPromotionComponentId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  paPromotionComponentId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  paPromotionComponentId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  paPromotionComponentId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  paPromotionComponentId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validFromDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  validFromDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  validFromDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  validFromDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  validFromDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  validFromDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  validFromDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  validFromDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  validUntilDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  validUntilDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  validUntilDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  validUntilDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  validUntilDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  validUntilDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  validUntilDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  validUntilDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export enum PaPromotionOrderByInput {
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageUrlAsc = 'imageUrl_ASC',
  ImageUrlDesc = 'imageUrl_DESC',
  LegalLineAsc = 'legalLine_ASC',
  LegalLineDesc = 'legalLine_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaPromotionComponentIdAsc = 'paPromotionComponentId_ASC',
  PaPromotionComponentIdDesc = 'paPromotionComponentId_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValidFromDateAsc = 'validFromDate_ASC',
  ValidFromDateDesc = 'validFromDate_DESC',
  ValidUntilDateAsc = 'validUntilDate_ASC',
  ValidUntilDateDesc = 'validUntilDate_DESC'
}

export type PaPromotionUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<BrandUpdateOneInlineInput>;
  clyfmnpau024607um268f5eek?: InputMaybe<VehiclePromotionUpdateManyInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** legalLine input for default locale (de) */
  legalLine?: InputMaybe<Scalars['String']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PaPromotionUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>;
  validFromDate?: InputMaybe<Scalars['Date']['input']>;
  validUntilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PaPromotionUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  legalLine?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PaPromotionUpdateLocalizationInput = {
  data: PaPromotionUpdateLocalizationDataInput;
  locale: Locale;
};

export type PaPromotionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PaPromotionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PaPromotionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PaPromotionUpsertLocalizationInput>>;
};

export type PaPromotionUpdateManyInlineInput = {
  /** Connect multiple existing PaPromotion documents */
  connect?: InputMaybe<Array<PaPromotionConnectInput>>;
  /** Create and connect multiple PaPromotion documents */
  create?: InputMaybe<Array<PaPromotionCreateInput>>;
  /** Delete multiple PaPromotion documents */
  delete?: InputMaybe<Array<PaPromotionWhereUniqueInput>>;
  /** Disconnect multiple PaPromotion documents */
  disconnect?: InputMaybe<Array<PaPromotionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PaPromotion documents */
  set?: InputMaybe<Array<PaPromotionWhereUniqueInput>>;
  /** Update multiple PaPromotion documents */
  update?: InputMaybe<Array<PaPromotionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PaPromotion documents */
  upsert?: InputMaybe<Array<PaPromotionUpsertWithNestedWhereUniqueInput>>;
};

export type PaPromotionUpdateManyInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** legalLine input for default locale (de) */
  legalLine?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PaPromotionUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  validFromDate?: InputMaybe<Scalars['Date']['input']>;
  validUntilDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PaPromotionUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  legalLine?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PaPromotionUpdateManyLocalizationInput = {
  data: PaPromotionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PaPromotionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PaPromotionUpdateManyLocalizationInput>>;
};

export type PaPromotionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PaPromotionUpdateManyInput;
  /** Document search */
  where: PaPromotionWhereInput;
};

export type PaPromotionUpdateOneInlineInput = {
  /** Connect existing PaPromotion document */
  connect?: InputMaybe<PaPromotionWhereUniqueInput>;
  /** Create and connect one PaPromotion document */
  create?: InputMaybe<PaPromotionCreateInput>;
  /** Delete currently connected PaPromotion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected PaPromotion document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single PaPromotion document */
  update?: InputMaybe<PaPromotionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PaPromotion document */
  upsert?: InputMaybe<PaPromotionUpsertWithNestedWhereUniqueInput>;
};

export type PaPromotionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PaPromotionUpdateInput;
  /** Unique document search */
  where: PaPromotionWhereUniqueInput;
};

export type PaPromotionUpsertInput = {
  /** Create document if it didn't exist */
  create: PaPromotionCreateInput;
  /** Update document if it exists */
  update: PaPromotionUpdateInput;
};

export type PaPromotionUpsertLocalizationInput = {
  create: PaPromotionCreateLocalizationDataInput;
  locale: Locale;
  update: PaPromotionUpdateLocalizationDataInput;
};

export type PaPromotionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PaPromotionUpsertInput;
  /** Unique document search */
  where: PaPromotionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PaPromotionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type PaPromotionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PaPromotionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  active_not?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  documentInStages_every?: InputMaybe<PaPromotionWhereStageInput>;
  documentInStages_none?: InputMaybe<PaPromotionWhereStageInput>;
  documentInStages_some?: InputMaybe<PaPromotionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  legalLine?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  legalLine_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  legalLine_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  legalLine_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  legalLine_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  legalLine_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  legalLine_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  legalLine_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  legalLine_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  legalLine_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  paPromotionComponentId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  paPromotionComponentId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  paPromotionComponentId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  paPromotionComponentId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  paPromotionComponentId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  paPromotionComponentId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  paPromotionComponentId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  validFromDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  validFromDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  validFromDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  validFromDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  validFromDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  validFromDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  validFromDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  validFromDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  validUntilDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  validUntilDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  validUntilDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  validUntilDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  validUntilDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  validUntilDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  validUntilDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  validUntilDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PaPromotionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PaPromotionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PaPromotionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PaPromotionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PaPromotionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PaPromotion record uniquely */
export type PaPromotionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
};

export type Page = Entity & Node & {
  __typename?: 'Page';
  abstract: Scalars['String']['output'];
  childPages: Array<Page>;
  content: Array<PageContentRichText>;
  contentConfiguration?: Maybe<ContentConfiguration>;
  contentConfigurationAboutCarmarket?: Maybe<ContentConfiguration>;
  contentConfigurationPrivacyPolicy?: Maybe<ContentConfiguration>;
  contentConfigurationVehicleBuy?: Maybe<ContentConfiguration>;
  contentConfigurationVehicleEvaluation?: Maybe<ContentConfiguration>;
  contentConfigurationVehicleInsurance?: Maybe<ContentConfiguration>;
  contentConfigurationVehicleLease?: Maybe<ContentConfiguration>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  date: Scalars['Date']['output'];
  /** Get the document in other stages */
  documentInStages: Array<Page>;
  /** Ratio 50:17 */
  heroImageDesktop?: Maybe<Image>;
  /** Ratio 4:3 */
  heroImageMobile?: Maybe<Image>;
  heroSlider?: Maybe<HeroSlider>;
  /** List of Page versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  inSearchIndex: Scalars['Boolean']['output'];
  languages: Array<Language>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Page>;
  pages: Array<Page>;
  parent?: Maybe<PageParent>;
  parentPage?: Maybe<Page>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  qualityCheckeds: Array<QualityChecked>;
  /** Only needed if page hast no parent page */
  rootPath?: Maybe<RootPath>;
  scheduledIn: Array<ScheduledOperation>;
  slug?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String']['output'];
  titleAndLead?: Maybe<TitleAndLead>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type PageChildPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type PageContentConfigurationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationAboutCarmarketArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationPrivacyPolicyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationVehicleBuyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationVehicleEvaluationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationVehicleInsuranceArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageContentConfigurationVehicleLeaseArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type PageHeroImageDesktopArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageHeroImageMobileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageHeroSliderArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type PageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type PagePagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type PageParentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageParentPageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageQualityCheckedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<QualityCheckedOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QualityCheckedWhereInput>;
};


export type PageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type PageTitleAndLeadArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type PageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type PageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: 'PageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageContentRichText = {
  __typename?: 'PageContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String']['output'];
  json: Scalars['RichTextAST']['output'];
  /** Returns Markdown representation */
  markdown: Scalars['String']['output'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output'];
  references: Array<PageContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output'];
};


export type PageContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type PageContentRichTextEmbeddedTypes = AccordionChapterList | AccordionSmlList | Button | ButtonRow | Citatum | FactList | FeaturedVehiclesRow | Gallery | Image | ImageTextComboList | QuestionAndAnswerList | SpecialBox | Video;

export type PageCreateInput = {
  /** abstract input for default locale (de) */
  abstract: Scalars['String']['input'];
  childPages?: InputMaybe<PageCreateManyInlineInput>;
  cleh4xwg51ix401t3hkwc6jiw?: InputMaybe<PageLinkCreateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  contentConfiguration?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationAboutCarmarket?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationPrivacyPolicy?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationVehicleBuy?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationVehicleEvaluation?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationVehicleInsurance?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  contentConfigurationVehicleLease?: InputMaybe<ContentConfigurationCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['Date']['input'];
  heroImageDesktop?: InputMaybe<ImageCreateOneInlineInput>;
  heroImageMobile?: InputMaybe<ImageCreateOneInlineInput>;
  heroSlider?: InputMaybe<HeroSliderCreateOneInlineInput>;
  inSearchIndex: Scalars['Boolean']['input'];
  languages?: InputMaybe<Array<Language>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PageCreateLocalizationsInput>;
  pages?: InputMaybe<PageCreateManyInlineInput>;
  parent?: InputMaybe<PageParentCreateOneInlineInput>;
  parentPage?: InputMaybe<PageCreateOneInlineInput>;
  qualityCheckeds?: InputMaybe<QualityCheckedCreateManyInlineInput>;
  rootPath?: InputMaybe<RootPath>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title: Scalars['String']['input'];
  titleAndLead?: InputMaybe<TitleAndLeadCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PageCreateLocalizationDataInput = {
  abstract: Scalars['String']['input'];
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PageCreateLocalizationInput = {
  /** Localization input */
  data: PageCreateLocalizationDataInput;
  locale: Locale;
};

export type PageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
};

export type PageCreateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Create and connect multiple existing Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageCreateOneInlineInput = {
  /** Connect one existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Page;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']['output']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageLink = Entity & {
  __typename?: 'PageLink';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PageLink>;
  name?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Page>;
  /** System stage field */
  stage: Stage;
};


export type PageLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type PageLinkPageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageLinkConnection = {
  __typename?: 'PageLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageLinkCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PageLinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageCreateOneInlineInput>;
};

export type PageLinkCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PageLinkCreateLocalizationInput = {
  /** Localization input */
  data: PageLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type PageLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PageLinkCreateLocalizationInput>>;
};

export type PageLinkCreateManyInlineInput = {
  /** Create and connect multiple existing PageLink documents */
  create?: InputMaybe<Array<PageLinkCreateInput>>;
};

export type PageLinkCreateOneInlineInput = {
  /** Create and connect one PageLink document */
  create?: InputMaybe<PageLinkCreateInput>;
};

export type PageLinkCreateWithPositionInput = {
  /** Document to create */
  data: PageLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PageLinkEdge = {
  __typename?: 'PageLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PageLink;
};

/** Identifies documents */
export type PageLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<PageWhereInput>;
};

export enum PageLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC'
}

export type PageLinkParent = ContentConfiguration | Link;

export type PageLinkParentConnectInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationConnectInput>;
  Link?: InputMaybe<LinkConnectInput>;
};

export type PageLinkParentCreateInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationCreateInput>;
  Link?: InputMaybe<LinkCreateInput>;
};

export type PageLinkParentCreateManyInlineInput = {
  /** Connect multiple existing PageLinkParent documents */
  connect?: InputMaybe<Array<PageLinkParentWhereUniqueInput>>;
  /** Create and connect multiple existing PageLinkParent documents */
  create?: InputMaybe<Array<PageLinkParentCreateInput>>;
};

export type PageLinkParentCreateOneInlineInput = {
  /** Connect one existing PageLinkParent document */
  connect?: InputMaybe<PageLinkParentWhereUniqueInput>;
  /** Create and connect one PageLinkParent document */
  create?: InputMaybe<PageLinkParentCreateInput>;
};

export type PageLinkParentUpdateInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateInput>;
  Link?: InputMaybe<LinkUpdateInput>;
};

export type PageLinkParentUpdateManyInlineInput = {
  /** Connect multiple existing PageLinkParent documents */
  connect?: InputMaybe<Array<PageLinkParentConnectInput>>;
  /** Create and connect multiple PageLinkParent documents */
  create?: InputMaybe<Array<PageLinkParentCreateInput>>;
  /** Delete multiple PageLinkParent documents */
  delete?: InputMaybe<Array<PageLinkParentWhereUniqueInput>>;
  /** Disconnect multiple PageLinkParent documents */
  disconnect?: InputMaybe<Array<PageLinkParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PageLinkParent documents */
  set?: InputMaybe<Array<PageLinkParentWhereUniqueInput>>;
  /** Update multiple PageLinkParent documents */
  update?: InputMaybe<Array<PageLinkParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PageLinkParent documents */
  upsert?: InputMaybe<Array<PageLinkParentUpsertWithNestedWhereUniqueInput>>;
};

export type PageLinkParentUpdateManyWithNestedWhereInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateManyWithNestedWhereInput>;
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type PageLinkParentUpdateOneInlineInput = {
  /** Connect existing PageLinkParent document */
  connect?: InputMaybe<PageLinkParentWhereUniqueInput>;
  /** Create and connect one PageLinkParent document */
  create?: InputMaybe<PageLinkParentCreateInput>;
  /** Delete currently connected PageLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected PageLinkParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single PageLinkParent document */
  update?: InputMaybe<PageLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageLinkParent document */
  upsert?: InputMaybe<PageLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type PageLinkParentUpdateWithNestedWhereUniqueInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationUpdateWithNestedWhereUniqueInput>;
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type PageLinkParentUpsertWithNestedWhereUniqueInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationUpsertWithNestedWhereUniqueInput>;
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type PageLinkParentWhereInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationWhereInput>;
  Link?: InputMaybe<LinkWhereInput>;
};

export type PageLinkParentWhereUniqueInput = {
  ContentConfiguration?: InputMaybe<ContentConfigurationWhereUniqueInput>;
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type PageLinkUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<PageLinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageUpdateOneInlineInput>;
};

export type PageLinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PageLinkUpdateLocalizationInput = {
  data: PageLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type PageLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PageLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PageLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PageLinkUpsertLocalizationInput>>;
};

export type PageLinkUpdateManyInlineInput = {
  /** Create and connect multiple PageLink component instances */
  create?: InputMaybe<Array<PageLinkCreateWithPositionInput>>;
  /** Delete multiple PageLink documents */
  delete?: InputMaybe<Array<PageLinkWhereUniqueInput>>;
  /** Update multiple PageLink component instances */
  update?: InputMaybe<Array<PageLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple PageLink component instances */
  upsert?: InputMaybe<Array<PageLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type PageLinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<PageLinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PageLinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PageLinkUpdateManyLocalizationInput = {
  data: PageLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PageLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PageLinkUpdateManyLocalizationInput>>;
};

export type PageLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageLinkUpdateManyInput;
  /** Document search */
  where: PageLinkWhereInput;
};

export type PageLinkUpdateOneInlineInput = {
  /** Create and connect one PageLink document */
  create?: InputMaybe<PageLinkCreateInput>;
  /** Delete currently connected PageLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single PageLink document */
  update?: InputMaybe<PageLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageLink document */
  upsert?: InputMaybe<PageLinkUpsertWithNestedWhereUniqueInput>;
};

export type PageLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PageLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PageLinkWhereUniqueInput;
};

export type PageLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageLinkUpdateInput;
  /** Unique document search */
  where: PageLinkWhereUniqueInput;
};

export type PageLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: PageLinkCreateInput;
  /** Update document if it exists */
  update: PageLinkUpdateInput;
};

export type PageLinkUpsertLocalizationInput = {
  create: PageLinkCreateLocalizationDataInput;
  locale: Locale;
  update: PageLinkUpdateLocalizationDataInput;
};

export type PageLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PageLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PageLinkWhereUniqueInput;
};

export type PageLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageLinkUpsertInput;
  /** Unique document search */
  where: PageLinkWhereUniqueInput;
};

/** Identifies documents */
export type PageLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageWhereInput>;
};

/** References PageLink record uniquely */
export type PageLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type PageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  childPages_every?: InputMaybe<PageWhereInput>;
  childPages_none?: InputMaybe<PageWhereInput>;
  childPages_some?: InputMaybe<PageWhereInput>;
  contentConfiguration?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationAboutCarmarket?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationPrivacyPolicy?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleBuy?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleEvaluation?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleInsurance?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleLease?: InputMaybe<ContentConfigurationWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  heroImageDesktop?: InputMaybe<ImageWhereInput>;
  heroImageMobile?: InputMaybe<ImageWhereInput>;
  heroSlider?: InputMaybe<HeroSliderWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inSearchIndex?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  inSearchIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  /** All values in which the union is connected to the given models */
  parent?: InputMaybe<PageParentWhereInput>;
  parentPage?: InputMaybe<PageWhereInput>;
  /** All values in which the union is empty */
  parent_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityCheckeds_every?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_none?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_some?: InputMaybe<QualityCheckedWhereInput>;
  rootPath?: InputMaybe<RootPath>;
  /** All values that are contained in given list. */
  rootPath_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  /** Any other value that exists and is not equal to the given value. */
  rootPath_not?: InputMaybe<RootPath>;
  /** All values that are not contained in given list. */
  rootPath_not_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageOrderByInput {
  AbstractAsc = 'abstract_ASC',
  AbstractDesc = 'abstract_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InSearchIndexAsc = 'inSearchIndex_ASC',
  InSearchIndexDesc = 'inSearchIndex_DESC',
  LanguagesAsc = 'languages_ASC',
  LanguagesDesc = 'languages_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RootPathAsc = 'rootPath_ASC',
  RootPathDesc = 'rootPath_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PageParent = Brand | Model | Page | Root;

export type PageParentConnectInput = {
  Brand?: InputMaybe<BrandConnectInput>;
  Model?: InputMaybe<ModelConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
  Root?: InputMaybe<RootConnectInput>;
};

export type PageParentCreateInput = {
  Brand?: InputMaybe<BrandCreateInput>;
  Model?: InputMaybe<ModelCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
  Root?: InputMaybe<RootCreateInput>;
};

export type PageParentCreateManyInlineInput = {
  /** Connect multiple existing PageParent documents */
  connect?: InputMaybe<Array<PageParentWhereUniqueInput>>;
  /** Create and connect multiple existing PageParent documents */
  create?: InputMaybe<Array<PageParentCreateInput>>;
};

export type PageParentCreateOneInlineInput = {
  /** Connect one existing PageParent document */
  connect?: InputMaybe<PageParentWhereUniqueInput>;
  /** Create and connect one PageParent document */
  create?: InputMaybe<PageParentCreateInput>;
};

export type PageParentUpdateInput = {
  Brand?: InputMaybe<BrandUpdateInput>;
  Model?: InputMaybe<ModelUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
  Root?: InputMaybe<RootUpdateInput>;
};

export type PageParentUpdateManyInlineInput = {
  /** Connect multiple existing PageParent documents */
  connect?: InputMaybe<Array<PageParentConnectInput>>;
  /** Create and connect multiple PageParent documents */
  create?: InputMaybe<Array<PageParentCreateInput>>;
  /** Delete multiple PageParent documents */
  delete?: InputMaybe<Array<PageParentWhereUniqueInput>>;
  /** Disconnect multiple PageParent documents */
  disconnect?: InputMaybe<Array<PageParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PageParent documents */
  set?: InputMaybe<Array<PageParentWhereUniqueInput>>;
  /** Update multiple PageParent documents */
  update?: InputMaybe<Array<PageParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PageParent documents */
  upsert?: InputMaybe<Array<PageParentUpsertWithNestedWhereUniqueInput>>;
};

export type PageParentUpdateManyWithNestedWhereInput = {
  Brand?: InputMaybe<BrandUpdateManyWithNestedWhereInput>;
  Model?: InputMaybe<ModelUpdateManyWithNestedWhereInput>;
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
  Root?: InputMaybe<RootUpdateManyWithNestedWhereInput>;
};

export type PageParentUpdateOneInlineInput = {
  /** Connect existing PageParent document */
  connect?: InputMaybe<PageParentWhereUniqueInput>;
  /** Create and connect one PageParent document */
  create?: InputMaybe<PageParentCreateInput>;
  /** Delete currently connected PageParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected PageParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single PageParent document */
  update?: InputMaybe<PageParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageParent document */
  upsert?: InputMaybe<PageParentUpsertWithNestedWhereUniqueInput>;
};

export type PageParentUpdateWithNestedWhereUniqueInput = {
  Brand?: InputMaybe<BrandUpdateWithNestedWhereUniqueInput>;
  Model?: InputMaybe<ModelUpdateWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  Root?: InputMaybe<RootUpdateWithNestedWhereUniqueInput>;
};

export type PageParentUpsertWithNestedWhereUniqueInput = {
  Brand?: InputMaybe<BrandUpsertWithNestedWhereUniqueInput>;
  Model?: InputMaybe<ModelUpsertWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
  Root?: InputMaybe<RootUpsertWithNestedWhereUniqueInput>;
};

export type PageParentWhereInput = {
  Brand?: InputMaybe<BrandWhereInput>;
  Model?: InputMaybe<ModelWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
  Root?: InputMaybe<RootWhereInput>;
};

export type PageParentWhereUniqueInput = {
  Brand?: InputMaybe<BrandWhereUniqueInput>;
  Model?: InputMaybe<ModelWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
  Root?: InputMaybe<RootWhereUniqueInput>;
};

export type PageUpdateInput = {
  /** abstract input for default locale (de) */
  abstract?: InputMaybe<Scalars['String']['input']>;
  childPages?: InputMaybe<PageUpdateManyInlineInput>;
  cleh4xwg51ix401t3hkwc6jiw?: InputMaybe<PageLinkUpdateManyInlineInput>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  contentConfiguration?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationAboutCarmarket?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationPrivacyPolicy?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationVehicleBuy?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationVehicleEvaluation?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationVehicleInsurance?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  contentConfigurationVehicleLease?: InputMaybe<ContentConfigurationUpdateOneInlineInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  heroImageDesktop?: InputMaybe<ImageUpdateOneInlineInput>;
  heroImageMobile?: InputMaybe<ImageUpdateOneInlineInput>;
  heroSlider?: InputMaybe<HeroSliderUpdateOneInlineInput>;
  inSearchIndex?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Manage document localizations */
  localizations?: InputMaybe<PageUpdateLocalizationsInput>;
  pages?: InputMaybe<PageUpdateManyInlineInput>;
  parent?: InputMaybe<PageParentUpdateOneInlineInput>;
  parentPage?: InputMaybe<PageUpdateOneInlineInput>;
  qualityCheckeds?: InputMaybe<QualityCheckedUpdateManyInlineInput>;
  rootPath?: InputMaybe<RootPath>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  titleAndLead?: InputMaybe<TitleAndLeadUpdateOneInlineInput>;
};

export type PageUpdateLocalizationDataInput = {
  abstract?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PageUpdateLocalizationInput = {
  data: PageUpdateLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PageUpsertLocalizationInput>>;
};

export type PageUpdateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Create and connect multiple Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
  /** Delete multiple Page documents */
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: InputMaybe<Array<PageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: InputMaybe<Array<PageUpsertWithNestedWhereUniqueInput>>;
};

export type PageUpdateManyInput = {
  /** abstract input for default locale (de) */
  abstract?: InputMaybe<Scalars['String']['input']>;
  /** content input for default locale (de) */
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  inSearchIndex?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Language>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PageUpdateManyLocalizationsInput>;
  rootPath?: InputMaybe<RootPath>;
};

export type PageUpdateManyLocalizationDataInput = {
  abstract?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Array<Scalars['RichTextAST']['input']>>;
};

export type PageUpdateManyLocalizationInput = {
  data: PageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateManyLocalizationInput>>;
};

export type PageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageUpdateManyInput;
  /** Document search */
  where: PageWhereInput;
};

export type PageUpdateOneInlineInput = {
  /** Connect existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
  /** Delete currently connected Page document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Page document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Page document */
  update?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageUpdateInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

export type PageUpsertInput = {
  /** Create document if it didn't exist */
  create: PageCreateInput;
  /** Update document if it exists */
  update: PageUpdateInput;
};

export type PageUpsertLocalizationInput = {
  create: PageCreateLocalizationDataInput;
  locale: Locale;
  update: PageUpdateLocalizationDataInput;
};

export type PageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageUpsertInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type PageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  abstract?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  abstract_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  abstract_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  abstract_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  abstract_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  abstract_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  abstract_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  abstract_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  abstract_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  abstract_starts_with?: InputMaybe<Scalars['String']['input']>;
  childPages_every?: InputMaybe<PageWhereInput>;
  childPages_none?: InputMaybe<PageWhereInput>;
  childPages_some?: InputMaybe<PageWhereInput>;
  contentConfiguration?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationAboutCarmarket?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationPrivacyPolicy?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleBuy?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleEvaluation?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleInsurance?: InputMaybe<ContentConfigurationWhereInput>;
  contentConfigurationVehicleLease?: InputMaybe<ContentConfigurationWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  date?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  heroImageDesktop?: InputMaybe<ImageWhereInput>;
  heroImageMobile?: InputMaybe<ImageWhereInput>;
  heroSlider?: InputMaybe<HeroSliderWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  inSearchIndex?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  inSearchIndex_not?: InputMaybe<Scalars['Boolean']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  languages?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains *all* items provided to the filter */
  languages_contains_all?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  languages_contains_none?: InputMaybe<Array<Language>>;
  /** Matches if the field array contains at least one item provided to the filter */
  languages_contains_some?: InputMaybe<Array<Language>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  languages_not?: InputMaybe<Array<Language>>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  /** All values in which the union is connected to the given models */
  parent?: InputMaybe<PageParentWhereInput>;
  parentPage?: InputMaybe<PageWhereInput>;
  /** All values in which the union is empty */
  parent_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityCheckeds_every?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_none?: InputMaybe<QualityCheckedWhereInput>;
  qualityCheckeds_some?: InputMaybe<QualityCheckedWhereInput>;
  rootPath?: InputMaybe<RootPath>;
  /** All values that are contained in given list. */
  rootPath_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  /** Any other value that exists and is not equal to the given value. */
  rootPath_not?: InputMaybe<RootPath>;
  /** All values that are not contained in given list. */
  rootPath_not_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleAndLead?: InputMaybe<TitleAndLeadWhereInput>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Page record uniquely */
export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Euro 1, Euro 2, etc. */
export type PollutionClass = Entity & Node & {
  __typename?: 'PollutionClass';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<PollutionClass>;
  /** List of PollutionClass versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PollutionClass>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Euro 1, Euro 2, etc. */
export type PollutionClassUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PollutionClassConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PollutionClassWhereUniqueInput;
};

/** A connection to a list of items. */
export type PollutionClassConnection = {
  __typename?: 'PollutionClassConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PollutionClassEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PollutionClassCreateInput = {
  cl16ied42hsoq01ywgq77bunc?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PollutionClassCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PollutionClassCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PollutionClassCreateLocalizationInput = {
  /** Localization input */
  data: PollutionClassCreateLocalizationDataInput;
  locale: Locale;
};

export type PollutionClassCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PollutionClassCreateLocalizationInput>>;
};

export type PollutionClassCreateManyInlineInput = {
  /** Connect multiple existing PollutionClass documents */
  connect?: InputMaybe<Array<PollutionClassWhereUniqueInput>>;
  /** Create and connect multiple existing PollutionClass documents */
  create?: InputMaybe<Array<PollutionClassCreateInput>>;
};

export type PollutionClassCreateOneInlineInput = {
  /** Connect one existing PollutionClass document */
  connect?: InputMaybe<PollutionClassWhereUniqueInput>;
  /** Create and connect one PollutionClass document */
  create?: InputMaybe<PollutionClassCreateInput>;
};

/** An edge in a connection. */
export type PollutionClassEdge = {
  __typename?: 'PollutionClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PollutionClass;
};

/** Identifies documents */
export type PollutionClassManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PollutionClassWhereStageInput>;
  documentInStages_none?: InputMaybe<PollutionClassWhereStageInput>;
  documentInStages_some?: InputMaybe<PollutionClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PollutionClassOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type PollutionClassUpdateInput = {
  cl16ied42hsoq01ywgq77bunc?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PollutionClassUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type PollutionClassUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type PollutionClassUpdateLocalizationInput = {
  data: PollutionClassUpdateLocalizationDataInput;
  locale: Locale;
};

export type PollutionClassUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PollutionClassCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PollutionClassUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PollutionClassUpsertLocalizationInput>>;
};

export type PollutionClassUpdateManyInlineInput = {
  /** Connect multiple existing PollutionClass documents */
  connect?: InputMaybe<Array<PollutionClassConnectInput>>;
  /** Create and connect multiple PollutionClass documents */
  create?: InputMaybe<Array<PollutionClassCreateInput>>;
  /** Delete multiple PollutionClass documents */
  delete?: InputMaybe<Array<PollutionClassWhereUniqueInput>>;
  /** Disconnect multiple PollutionClass documents */
  disconnect?: InputMaybe<Array<PollutionClassWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PollutionClass documents */
  set?: InputMaybe<Array<PollutionClassWhereUniqueInput>>;
  /** Update multiple PollutionClass documents */
  update?: InputMaybe<Array<PollutionClassUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PollutionClass documents */
  upsert?: InputMaybe<Array<PollutionClassUpsertWithNestedWhereUniqueInput>>;
};

export type PollutionClassUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PollutionClassUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type PollutionClassUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type PollutionClassUpdateManyLocalizationInput = {
  data: PollutionClassUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PollutionClassUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PollutionClassUpdateManyLocalizationInput>>;
};

export type PollutionClassUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PollutionClassUpdateManyInput;
  /** Document search */
  where: PollutionClassWhereInput;
};

export type PollutionClassUpdateOneInlineInput = {
  /** Connect existing PollutionClass document */
  connect?: InputMaybe<PollutionClassWhereUniqueInput>;
  /** Create and connect one PollutionClass document */
  create?: InputMaybe<PollutionClassCreateInput>;
  /** Delete currently connected PollutionClass document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected PollutionClass document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single PollutionClass document */
  update?: InputMaybe<PollutionClassUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PollutionClass document */
  upsert?: InputMaybe<PollutionClassUpsertWithNestedWhereUniqueInput>;
};

export type PollutionClassUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PollutionClassUpdateInput;
  /** Unique document search */
  where: PollutionClassWhereUniqueInput;
};

export type PollutionClassUpsertInput = {
  /** Create document if it didn't exist */
  create: PollutionClassCreateInput;
  /** Update document if it exists */
  update: PollutionClassUpdateInput;
};

export type PollutionClassUpsertLocalizationInput = {
  create: PollutionClassCreateLocalizationDataInput;
  locale: Locale;
  update: PollutionClassUpdateLocalizationDataInput;
};

export type PollutionClassUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PollutionClassUpsertInput;
  /** Unique document search */
  where: PollutionClassWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PollutionClassWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type PollutionClassWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PollutionClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PollutionClassWhereStageInput>;
  documentInStages_none?: InputMaybe<PollutionClassWhereStageInput>;
  documentInStages_some?: InputMaybe<PollutionClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PollutionClassWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PollutionClassWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PollutionClassWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PollutionClassWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PollutionClassWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PollutionClass record uniquely */
export type PollutionClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type QualityChecked = Entity & Node & {
  __typename?: 'QualityChecked';
  brand?: Maybe<Brand>;
  checkpoints?: Maybe<Scalars['Int']['output']>;
  coverageMileage?: Maybe<Scalars['Int']['output']>;
  coverageMonths?: Maybe<Scalars['Int']['output']>;
  coverageText?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  description: Scalars['String']['output'];
  /** Get the document in other stages */
  documentInStages: Array<QualityChecked>;
  /** List of QualityChecked versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<QualityChecked>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  maxVehicleAge?: Maybe<Scalars['Int']['output']>;
  maxVehicleMileage?: Maybe<Scalars['Int']['output']>;
  minVehicleAge?: Maybe<Scalars['Int']['output']>;
  minVehicleMileage?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  page?: Maybe<Page>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  refundPolicyMaxMileage?: Maybe<Scalars['Int']['output']>;
  refundPolicyRegistrationDays?: Maybe<Scalars['Int']['output']>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type QualityCheckedBrandArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QualityCheckedCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QualityCheckedCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QualityCheckedDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type QualityCheckedHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type QualityCheckedKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type QualityCheckedLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type QualityCheckedPageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QualityCheckedPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QualityCheckedPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QualityCheckedScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QualityCheckedUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QualityCheckedUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type QualityCheckedConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: QualityCheckedWhereUniqueInput;
};

/** A connection to a list of items. */
export type QualityCheckedConnection = {
  __typename?: 'QualityCheckedConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<QualityCheckedEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type QualityCheckedCreateInput = {
  brand?: InputMaybe<BrandCreateOneInlineInput>;
  checkpoints?: InputMaybe<Scalars['Int']['input']>;
  cly5u632u00od07ux5sz89tzl?: InputMaybe<VehicleCreateManyInlineInput>;
  coverageMileage?: InputMaybe<Scalars['Int']['input']>;
  coverageMonths?: InputMaybe<Scalars['Int']['input']>;
  /** coverageText input for default locale (de) */
  coverageText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** description input for default locale (de) */
  description: Scalars['String']['input'];
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<QualityCheckedCreateLocalizationsInput>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  maxVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  maxVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  minVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  minVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  page?: InputMaybe<PageCreateOneInlineInput>;
  refundPolicyMaxMileage?: InputMaybe<Scalars['Int']['input']>;
  refundPolicyRegistrationDays?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QualityCheckedCreateLocalizationDataInput = {
  coverageText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QualityCheckedCreateLocalizationInput = {
  /** Localization input */
  data: QualityCheckedCreateLocalizationDataInput;
  locale: Locale;
};

export type QualityCheckedCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<QualityCheckedCreateLocalizationInput>>;
};

export type QualityCheckedCreateManyInlineInput = {
  /** Connect multiple existing QualityChecked documents */
  connect?: InputMaybe<Array<QualityCheckedWhereUniqueInput>>;
  /** Create and connect multiple existing QualityChecked documents */
  create?: InputMaybe<Array<QualityCheckedCreateInput>>;
};

export type QualityCheckedCreateOneInlineInput = {
  /** Connect one existing QualityChecked document */
  connect?: InputMaybe<QualityCheckedWhereUniqueInput>;
  /** Create and connect one QualityChecked document */
  create?: InputMaybe<QualityCheckedCreateInput>;
};

/** An edge in a connection. */
export type QualityCheckedEdge = {
  __typename?: 'QualityCheckedEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: QualityChecked;
};

/** Identifies documents */
export type QualityCheckedManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  checkpoints?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  checkpoints_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  checkpoints_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  checkpoints_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  checkpoints_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  checkpoints_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  checkpoints_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  checkpoints_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coverageMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  coverageMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  coverageMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  coverageMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  coverageMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  coverageMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  coverageMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  coverageMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coverageMonths?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  coverageMonths_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  coverageMonths_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  coverageMonths_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  coverageMonths_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  coverageMonths_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  coverageMonths_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  coverageMonths_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<QualityCheckedWhereStageInput>;
  documentInStages_none?: InputMaybe<QualityCheckedWhereStageInput>;
  documentInStages_some?: InputMaybe<QualityCheckedWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoImageUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoImageUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoImageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoImageUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoImageUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoImageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoImageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoImageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoImageUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  maxVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxVehicleAge_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxVehicleAge_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxVehicleAge_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxVehicleAge_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxVehicleAge_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxVehicleAge_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxVehicleAge_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  maxVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxVehicleMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxVehicleMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxVehicleMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxVehicleMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxVehicleMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxVehicleMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxVehicleMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  minVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  minVehicleAge_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  minVehicleAge_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  minVehicleAge_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  minVehicleAge_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  minVehicleAge_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  minVehicleAge_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  minVehicleAge_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  minVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  minVehicleMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  minVehicleMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  minVehicleMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  minVehicleMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  minVehicleMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  minVehicleMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  minVehicleMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  page?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  refundPolicyMaxMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  refundPolicyMaxMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  refundPolicyMaxMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  refundPolicyMaxMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  refundPolicyMaxMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  refundPolicyMaxMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  refundPolicyMaxMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  refundPolicyMaxMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  refundPolicyRegistrationDays?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  refundPolicyRegistrationDays_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  refundPolicyRegistrationDays_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  refundPolicyRegistrationDays_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  refundPolicyRegistrationDays_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  refundPolicyRegistrationDays_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  refundPolicyRegistrationDays_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  refundPolicyRegistrationDays_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum QualityCheckedOrderByInput {
  CheckpointsAsc = 'checkpoints_ASC',
  CheckpointsDesc = 'checkpoints_DESC',
  CoverageMileageAsc = 'coverageMileage_ASC',
  CoverageMileageDesc = 'coverageMileage_DESC',
  CoverageMonthsAsc = 'coverageMonths_ASC',
  CoverageMonthsDesc = 'coverageMonths_DESC',
  CoverageTextAsc = 'coverageText_ASC',
  CoverageTextDesc = 'coverageText_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  LogoImageUrlAsc = 'logoImageUrl_ASC',
  LogoImageUrlDesc = 'logoImageUrl_DESC',
  MaxVehicleAgeAsc = 'maxVehicleAge_ASC',
  MaxVehicleAgeDesc = 'maxVehicleAge_DESC',
  MaxVehicleMileageAsc = 'maxVehicleMileage_ASC',
  MaxVehicleMileageDesc = 'maxVehicleMileage_DESC',
  MinVehicleAgeAsc = 'minVehicleAge_ASC',
  MinVehicleAgeDesc = 'minVehicleAge_DESC',
  MinVehicleMileageAsc = 'minVehicleMileage_ASC',
  MinVehicleMileageDesc = 'minVehicleMileage_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RefundPolicyMaxMileageAsc = 'refundPolicyMaxMileage_ASC',
  RefundPolicyMaxMileageDesc = 'refundPolicyMaxMileage_DESC',
  RefundPolicyRegistrationDaysAsc = 'refundPolicyRegistrationDays_ASC',
  RefundPolicyRegistrationDaysDesc = 'refundPolicyRegistrationDays_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type QualityCheckedUpdateInput = {
  brand?: InputMaybe<BrandUpdateOneInlineInput>;
  checkpoints?: InputMaybe<Scalars['Int']['input']>;
  cly5u632u00od07ux5sz89tzl?: InputMaybe<VehicleUpdateManyInlineInput>;
  coverageMileage?: InputMaybe<Scalars['Int']['input']>;
  coverageMonths?: InputMaybe<Scalars['Int']['input']>;
  /** coverageText input for default locale (de) */
  coverageText?: InputMaybe<Scalars['String']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<QualityCheckedUpdateLocalizationsInput>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  maxVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  maxVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  minVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  minVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageUpdateOneInlineInput>;
  refundPolicyMaxMileage?: InputMaybe<Scalars['Int']['input']>;
  refundPolicyRegistrationDays?: InputMaybe<Scalars['Int']['input']>;
};

export type QualityCheckedUpdateLocalizationDataInput = {
  coverageText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QualityCheckedUpdateLocalizationInput = {
  data: QualityCheckedUpdateLocalizationDataInput;
  locale: Locale;
};

export type QualityCheckedUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<QualityCheckedCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<QualityCheckedUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<QualityCheckedUpsertLocalizationInput>>;
};

export type QualityCheckedUpdateManyInlineInput = {
  /** Connect multiple existing QualityChecked documents */
  connect?: InputMaybe<Array<QualityCheckedConnectInput>>;
  /** Create and connect multiple QualityChecked documents */
  create?: InputMaybe<Array<QualityCheckedCreateInput>>;
  /** Delete multiple QualityChecked documents */
  delete?: InputMaybe<Array<QualityCheckedWhereUniqueInput>>;
  /** Disconnect multiple QualityChecked documents */
  disconnect?: InputMaybe<Array<QualityCheckedWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing QualityChecked documents */
  set?: InputMaybe<Array<QualityCheckedWhereUniqueInput>>;
  /** Update multiple QualityChecked documents */
  update?: InputMaybe<Array<QualityCheckedUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple QualityChecked documents */
  upsert?: InputMaybe<Array<QualityCheckedUpsertWithNestedWhereUniqueInput>>;
};

export type QualityCheckedUpdateManyInput = {
  checkpoints?: InputMaybe<Scalars['Int']['input']>;
  coverageMileage?: InputMaybe<Scalars['Int']['input']>;
  coverageMonths?: InputMaybe<Scalars['Int']['input']>;
  /** coverageText input for default locale (de) */
  coverageText?: InputMaybe<Scalars['String']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<QualityCheckedUpdateManyLocalizationsInput>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  maxVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  maxVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  minVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  minVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  refundPolicyMaxMileage?: InputMaybe<Scalars['Int']['input']>;
  refundPolicyRegistrationDays?: InputMaybe<Scalars['Int']['input']>;
};

export type QualityCheckedUpdateManyLocalizationDataInput = {
  coverageText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QualityCheckedUpdateManyLocalizationInput = {
  data: QualityCheckedUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type QualityCheckedUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<QualityCheckedUpdateManyLocalizationInput>>;
};

export type QualityCheckedUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: QualityCheckedUpdateManyInput;
  /** Document search */
  where: QualityCheckedWhereInput;
};

export type QualityCheckedUpdateOneInlineInput = {
  /** Connect existing QualityChecked document */
  connect?: InputMaybe<QualityCheckedWhereUniqueInput>;
  /** Create and connect one QualityChecked document */
  create?: InputMaybe<QualityCheckedCreateInput>;
  /** Delete currently connected QualityChecked document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected QualityChecked document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single QualityChecked document */
  update?: InputMaybe<QualityCheckedUpdateWithNestedWhereUniqueInput>;
  /** Upsert single QualityChecked document */
  upsert?: InputMaybe<QualityCheckedUpsertWithNestedWhereUniqueInput>;
};

export type QualityCheckedUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: QualityCheckedUpdateInput;
  /** Unique document search */
  where: QualityCheckedWhereUniqueInput;
};

export type QualityCheckedUpsertInput = {
  /** Create document if it didn't exist */
  create: QualityCheckedCreateInput;
  /** Update document if it exists */
  update: QualityCheckedUpdateInput;
};

export type QualityCheckedUpsertLocalizationInput = {
  create: QualityCheckedCreateLocalizationDataInput;
  locale: Locale;
  update: QualityCheckedUpdateLocalizationDataInput;
};

export type QualityCheckedUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: QualityCheckedUpsertInput;
  /** Unique document search */
  where: QualityCheckedWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type QualityCheckedWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type QualityCheckedWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QualityCheckedWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<BrandWhereInput>;
  checkpoints?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  checkpoints_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  checkpoints_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  checkpoints_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  checkpoints_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  checkpoints_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  checkpoints_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  checkpoints_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coverageMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  coverageMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  coverageMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  coverageMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  coverageMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  coverageMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  coverageMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  coverageMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coverageMonths?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  coverageMonths_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  coverageMonths_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  coverageMonths_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  coverageMonths_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  coverageMonths_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  coverageMonths_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  coverageMonths_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  coverageText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  coverageText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  coverageText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  coverageText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  coverageText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  coverageText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  coverageText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  coverageText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  coverageText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  coverageText_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  documentInStages_every?: InputMaybe<QualityCheckedWhereStageInput>;
  documentInStages_none?: InputMaybe<QualityCheckedWhereStageInput>;
  documentInStages_some?: InputMaybe<QualityCheckedWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  logoImageUrl_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  logoImageUrl_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  logoImageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  logoImageUrl_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  logoImageUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  logoImageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  logoImageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  logoImageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  logoImageUrl_starts_with?: InputMaybe<Scalars['String']['input']>;
  maxVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxVehicleAge_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxVehicleAge_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxVehicleAge_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxVehicleAge_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxVehicleAge_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxVehicleAge_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxVehicleAge_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  maxVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  maxVehicleMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  maxVehicleMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  maxVehicleMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  maxVehicleMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  maxVehicleMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  maxVehicleMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  maxVehicleMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  minVehicleAge?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  minVehicleAge_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  minVehicleAge_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  minVehicleAge_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  minVehicleAge_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  minVehicleAge_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  minVehicleAge_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  minVehicleAge_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  minVehicleMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  minVehicleMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  minVehicleMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  minVehicleMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  minVehicleMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  minVehicleMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  minVehicleMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  minVehicleMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  refundPolicyMaxMileage?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  refundPolicyMaxMileage_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  refundPolicyMaxMileage_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  refundPolicyMaxMileage_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  refundPolicyMaxMileage_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  refundPolicyMaxMileage_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  refundPolicyMaxMileage_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  refundPolicyMaxMileage_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  refundPolicyRegistrationDays?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  refundPolicyRegistrationDays_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  refundPolicyRegistrationDays_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  refundPolicyRegistrationDays_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  refundPolicyRegistrationDays_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  refundPolicyRegistrationDays_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  refundPolicyRegistrationDays_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  refundPolicyRegistrationDays_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type QualityCheckedWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QualityCheckedWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QualityCheckedWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QualityCheckedWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<QualityCheckedWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References QualityChecked record uniquely */
export type QualityCheckedWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a single accordionChapter */
  accordionChapter?: Maybe<AccordionChapter>;
  /** Retrieve a single accordionChapterList */
  accordionChapterList?: Maybe<AccordionChapterList>;
  /** Retrieve document version */
  accordionChapterListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple accordionChapterLists */
  accordionChapterLists: Array<AccordionChapterList>;
  /** Retrieve multiple accordionChapterLists using the Relay connection interface */
  accordionChapterListsConnection: AccordionChapterListConnection;
  /** Retrieve document version */
  accordionChapterVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple accordionChapters */
  accordionChapters: Array<AccordionChapter>;
  /** Retrieve multiple accordionChapters using the Relay connection interface */
  accordionChaptersConnection: AccordionChapterConnection;
  /** Retrieve a single accordionSmlList */
  accordionSmlList?: Maybe<AccordionSmlList>;
  /** Retrieve document version */
  accordionSmlListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple accordionSmlLists */
  accordionSmlLists: Array<AccordionSmlList>;
  /** Retrieve multiple accordionSmlLists using the Relay connection interface */
  accordionSmlListsConnection: AccordionSmlListConnection;
  /** Retrieve a single ad */
  ad?: Maybe<Ad>;
  /** Retrieve document version */
  adVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple ads */
  ads: Array<Ad>;
  /** Retrieve multiple ads using the Relay connection interface */
  adsConnection: AdConnection;
  /** Retrieve a single article */
  article?: Maybe<Article>;
  /** Retrieve document version */
  articleVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple articles */
  articles: Array<Article>;
  /** Retrieve multiple articles using the Relay connection interface */
  articlesConnection: ArticleConnection;
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single banner */
  banner?: Maybe<Banner>;
  /** Retrieve document version */
  bannerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple banners */
  banners: Array<Banner>;
  /** Retrieve multiple banners using the Relay connection interface */
  bannersConnection: BannerConnection;
  /** Retrieve a single bodyType */
  bodyType?: Maybe<BodyType>;
  /** Retrieve a single bodyTypeGroup */
  bodyTypeGroup?: Maybe<BodyTypeGroup>;
  /** Retrieve document version */
  bodyTypeGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple bodyTypeGroups */
  bodyTypeGroups: Array<BodyTypeGroup>;
  /** Retrieve multiple bodyTypeGroups using the Relay connection interface */
  bodyTypeGroupsConnection: BodyTypeGroupConnection;
  /** Retrieve document version */
  bodyTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple bodyTypes */
  bodyTypes: Array<BodyType>;
  /** Retrieve multiple bodyTypes using the Relay connection interface */
  bodyTypesConnection: BodyTypeConnection;
  /** Retrieve a single brand */
  brand?: Maybe<Brand>;
  /** Retrieve document version */
  brandVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple brands */
  brands: Array<Brand>;
  /** Retrieve multiple brands using the Relay connection interface */
  brandsConnection: BrandConnection;
  /** Retrieve a single button */
  button?: Maybe<Button>;
  /** Retrieve a single buttonRow */
  buttonRow?: Maybe<ButtonRow>;
  /** Retrieve document version */
  buttonRowVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple buttonRows */
  buttonRows: Array<ButtonRow>;
  /** Retrieve multiple buttonRows using the Relay connection interface */
  buttonRowsConnection: ButtonRowConnection;
  /** Retrieve document version */
  buttonVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple buttons */
  buttons: Array<Button>;
  /** Retrieve multiple buttons using the Relay connection interface */
  buttonsConnection: ButtonConnection;
  /** Retrieve a single citatum */
  citatum?: Maybe<Citatum>;
  /** Retrieve document version */
  citatumVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple citatums */
  citatums: Array<Citatum>;
  /** Retrieve multiple citatums using the Relay connection interface */
  citatumsConnection: CitatumConnection;
  /** Retrieve a single colorNorm */
  colorNorm?: Maybe<ColorNorm>;
  /** Retrieve document version */
  colorNormVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple colorNorms */
  colorNorms: Array<ColorNorm>;
  /** Retrieve multiple colorNorms using the Relay connection interface */
  colorNormsConnection: ColorNormConnection;
  /** Retrieve a single colorType */
  colorType?: Maybe<ColorType>;
  /** Retrieve document version */
  colorTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple colorTypes */
  colorTypes: Array<ColorType>;
  /** Retrieve multiple colorTypes using the Relay connection interface */
  colorTypesConnection: ColorTypeConnection;
  /** Retrieve a single contentConfiguration */
  contentConfiguration?: Maybe<ContentConfiguration>;
  /** Retrieve document version */
  contentConfigurationVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple contentConfigurations */
  contentConfigurations: Array<ContentConfiguration>;
  /** Retrieve multiple contentConfigurations using the Relay connection interface */
  contentConfigurationsConnection: ContentConfigurationConnection;
  /** Retrieve a single dealer */
  dealer?: Maybe<Dealer>;
  /** Retrieve a single dealerEmployee */
  dealerEmployee?: Maybe<DealerEmployee>;
  /** Retrieve document version */
  dealerEmployeeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple dealerEmployees */
  dealerEmployees: Array<DealerEmployee>;
  /** Retrieve multiple dealerEmployees using the Relay connection interface */
  dealerEmployeesConnection: DealerEmployeeConnection;
  /** Retrieve document version */
  dealerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple dealers */
  dealers: Array<Dealer>;
  /** Retrieve multiple dealers using the Relay connection interface */
  dealersConnection: DealerConnection;
  /** Retrieve a single department */
  department?: Maybe<Department>;
  /** Retrieve document version */
  departmentVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple departments */
  departments: Array<Department>;
  /** Retrieve multiple departments using the Relay connection interface */
  departmentsConnection: DepartmentConnection;
  /** Retrieve a single driveType */
  driveType?: Maybe<DriveType>;
  /** Retrieve document version */
  driveTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple driveTypes */
  driveTypes: Array<DriveType>;
  /** Retrieve multiple driveTypes using the Relay connection interface */
  driveTypesConnection: DriveTypeConnection;
  /** Retrieve a single emissionClass */
  emissionClass?: Maybe<EmissionClass>;
  /** Retrieve document version */
  emissionClassVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple emissionClasses */
  emissionClasses: Array<EmissionClass>;
  /** Retrieve multiple emissionClasses using the Relay connection interface */
  emissionClassesConnection: EmissionClassConnection;
  /** Retrieve a single employee */
  employee?: Maybe<Employee>;
  /** Retrieve document version */
  employeeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple employees */
  employees: Array<Employee>;
  /** Retrieve multiple employees using the Relay connection interface */
  employeesConnection: EmployeeConnection;
  /** Fetches an object given its ID */
  entities?: Maybe<Array<Entity>>;
  /** Retrieve a single factList */
  factList?: Maybe<FactList>;
  /** Retrieve document version */
  factListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple factLists */
  factLists: Array<FactList>;
  /** Retrieve multiple factLists using the Relay connection interface */
  factListsConnection: FactListConnection;
  /** Retrieve a single featuredVehiclesRow */
  featuredVehiclesRow?: Maybe<FeaturedVehiclesRow>;
  /** Retrieve document version */
  featuredVehiclesRowVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple featuredVehiclesRows */
  featuredVehiclesRows: Array<FeaturedVehiclesRow>;
  /** Retrieve multiple featuredVehiclesRows using the Relay connection interface */
  featuredVehiclesRowsConnection: FeaturedVehiclesRowConnection;
  /** Retrieve a single fuelType */
  fuelType?: Maybe<FuelType>;
  /** Retrieve a single fuelTypeGroup */
  fuelTypeGroup?: Maybe<FuelTypeGroup>;
  /** Retrieve document version */
  fuelTypeGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple fuelTypeGroups */
  fuelTypeGroups: Array<FuelTypeGroup>;
  /** Retrieve multiple fuelTypeGroups using the Relay connection interface */
  fuelTypeGroupsConnection: FuelTypeGroupConnection;
  /** Retrieve document version */
  fuelTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple fuelTypes */
  fuelTypes: Array<FuelType>;
  /** Retrieve multiple fuelTypes using the Relay connection interface */
  fuelTypesConnection: FuelTypeConnection;
  /** Retrieve multiple galleries */
  galleries: Array<Gallery>;
  /** Retrieve multiple galleries using the Relay connection interface */
  galleriesConnection: GalleryConnection;
  /** Retrieve a single gallery */
  gallery?: Maybe<Gallery>;
  /** Retrieve document version */
  galleryVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single generatedPageExtension */
  generatedPageExtension?: Maybe<GeneratedPageExtension>;
  /** Retrieve document version */
  generatedPageExtensionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple generatedPageExtensions */
  generatedPageExtensions: Array<GeneratedPageExtension>;
  /** Retrieve multiple generatedPageExtensions using the Relay connection interface */
  generatedPageExtensionsConnection: GeneratedPageExtensionConnection;
  /** Retrieve a single heroSlider */
  heroSlider?: Maybe<HeroSlider>;
  /** Retrieve document version */
  heroSliderVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple heroSliders */
  heroSliders: Array<HeroSlider>;
  /** Retrieve multiple heroSliders using the Relay connection interface */
  heroSlidersConnection: HeroSliderConnection;
  /** Retrieve a single image */
  image?: Maybe<Image>;
  /** Retrieve a single imageTextComboList */
  imageTextComboList?: Maybe<ImageTextComboList>;
  /** Retrieve document version */
  imageTextComboListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple imageTextComboLists */
  imageTextComboLists: Array<ImageTextComboList>;
  /** Retrieve multiple imageTextComboLists using the Relay connection interface */
  imageTextComboListsConnection: ImageTextComboListConnection;
  /** Retrieve document version */
  imageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple images */
  images: Array<Image>;
  /** Retrieve multiple images using the Relay connection interface */
  imagesConnection: ImageConnection;
  /** Retrieve a single keywordList */
  keywordList?: Maybe<KeywordList>;
  /** Retrieve document version */
  keywordListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple keywordLists */
  keywordLists: Array<KeywordList>;
  /** Retrieve multiple keywordLists using the Relay connection interface */
  keywordListsConnection: KeywordListConnection;
  /** Retrieve a single model */
  model?: Maybe<Model>;
  /** Retrieve document version */
  modelVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple models */
  models: Array<Model>;
  /** Retrieve multiple models using the Relay connection interface */
  modelsConnection: ModelConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single optionFacet */
  optionFacet?: Maybe<OptionFacet>;
  /** Retrieve document version */
  optionFacetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple optionFacets */
  optionFacets: Array<OptionFacet>;
  /** Retrieve multiple optionFacets using the Relay connection interface */
  optionFacetsConnection: OptionFacetConnection;
  /** Retrieve a single paPromotion */
  paPromotion?: Maybe<PaPromotion>;
  /** Retrieve document version */
  paPromotionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple paPromotions */
  paPromotions: Array<PaPromotion>;
  /** Retrieve multiple paPromotions using the Relay connection interface */
  paPromotionsConnection: PaPromotionConnection;
  /** Retrieve a single page */
  page?: Maybe<Page>;
  /** Retrieve document version */
  pageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pages */
  pages: Array<Page>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: PageConnection;
  /** Retrieve a single pollutionClass */
  pollutionClass?: Maybe<PollutionClass>;
  /** Retrieve document version */
  pollutionClassVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pollutionClasses */
  pollutionClasses: Array<PollutionClass>;
  /** Retrieve multiple pollutionClasses using the Relay connection interface */
  pollutionClassesConnection: PollutionClassConnection;
  promotion: InsideHubApi_Promotion;
  /** Retrieve a single qualityChecked */
  qualityChecked?: Maybe<QualityChecked>;
  /** Retrieve document version */
  qualityCheckedVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple qualityCheckeds */
  qualityCheckeds: Array<QualityChecked>;
  /** Retrieve multiple qualityCheckeds using the Relay connection interface */
  qualityCheckedsConnection: QualityCheckedConnection;
  /** Retrieve a single questionAndAnswer */
  questionAndAnswer?: Maybe<QuestionAndAnswer>;
  /** Retrieve a single questionAndAnswerList */
  questionAndAnswerList?: Maybe<QuestionAndAnswerList>;
  /** Retrieve document version */
  questionAndAnswerListVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple questionAndAnswerLists */
  questionAndAnswerLists: Array<QuestionAndAnswerList>;
  /** Retrieve multiple questionAndAnswerLists using the Relay connection interface */
  questionAndAnswerListsConnection: QuestionAndAnswerListConnection;
  /** Retrieve document version */
  questionAndAnswerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple questionAndAnswers */
  questionAndAnswers: Array<QuestionAndAnswer>;
  /** Retrieve multiple questionAndAnswers using the Relay connection interface */
  questionAndAnswersConnection: QuestionAndAnswerConnection;
  /** Retrieve a single root */
  root?: Maybe<Root>;
  /** Retrieve document version */
  rootVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple roots */
  roots: Array<Root>;
  /** Retrieve multiple roots using the Relay connection interface */
  rootsConnection: RootConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single service */
  service?: Maybe<Service>;
  /** Retrieve document version */
  serviceVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple services */
  services: Array<Service>;
  /** Retrieve multiple services using the Relay connection interface */
  servicesConnection: ServiceConnection;
  /** Retrieve a single specialBox */
  specialBox?: Maybe<SpecialBox>;
  /** Retrieve document version */
  specialBoxVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple specialBoxes */
  specialBoxes: Array<SpecialBox>;
  /** Retrieve multiple specialBoxes using the Relay connection interface */
  specialBoxesConnection: SpecialBoxConnection;
  /** Retrieve a single transmission */
  transmission?: Maybe<Transmission>;
  /** Retrieve a single transmissionGroup */
  transmissionGroup?: Maybe<TransmissionGroup>;
  /** Retrieve document version */
  transmissionGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple transmissionGroups */
  transmissionGroups: Array<TransmissionGroup>;
  /** Retrieve multiple transmissionGroups using the Relay connection interface */
  transmissionGroupsConnection: TransmissionGroupConnection;
  /** Retrieve document version */
  transmissionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple transmissions */
  transmissions: Array<Transmission>;
  /** Retrieve multiple transmissions using the Relay connection interface */
  transmissionsConnection: TransmissionConnection;
  /** Retrieve a single upholstery */
  upholstery?: Maybe<Upholstery>;
  /** Retrieve document version */
  upholsteryVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple upholsterys */
  upholsterys: Array<Upholstery>;
  /** Retrieve multiple upholsterys using the Relay connection interface */
  upholsterysConnection: UpholsteryConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
  /** Retrieve a single vehicle */
  vehicle?: Maybe<Vehicle>;
  /** Retrieve a single vehicleClass */
  vehicleClass?: Maybe<VehicleClass>;
  /** Retrieve document version */
  vehicleClassVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vehicleClasses */
  vehicleClasses: Array<VehicleClass>;
  /** Retrieve multiple vehicleClasses using the Relay connection interface */
  vehicleClassesConnection: VehicleClassConnection;
  /** Retrieve a single vehicleCondition */
  vehicleCondition?: Maybe<VehicleCondition>;
  /** Retrieve document version */
  vehicleConditionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vehicleConditions */
  vehicleConditions: Array<VehicleCondition>;
  /** Retrieve multiple vehicleConditions using the Relay connection interface */
  vehicleConditionsConnection: VehicleConditionConnection;
  /** Retrieve a single vehicleStatus */
  vehicleStatus?: Maybe<VehicleStatus>;
  /** Retrieve document version */
  vehicleStatusVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vehicleStatuses */
  vehicleStatuses: Array<VehicleStatus>;
  /** Retrieve multiple vehicleStatuses using the Relay connection interface */
  vehicleStatusesConnection: VehicleStatusConnection;
  /** Retrieve a single vehicleType */
  vehicleType?: Maybe<VehicleType>;
  /** Retrieve document version */
  vehicleTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vehicleTypes */
  vehicleTypes: Array<VehicleType>;
  /** Retrieve multiple vehicleTypes using the Relay connection interface */
  vehicleTypesConnection: VehicleTypeConnection;
  /** Retrieve document version */
  vehicleVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple vehicles */
  vehicles: Array<Vehicle>;
  /** Retrieve multiple vehicles using the Relay connection interface */
  vehiclesConnection: VehicleConnection;
  /** Retrieve a single video */
  video?: Maybe<Video>;
  /** Retrieve document version */
  videoVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple videos */
  videos: Array<Video>;
  /** Retrieve multiple videos using the Relay connection interface */
  videosConnection: VideoConnection;
  /** Retrieve a single whitelabel */
  whitelabel?: Maybe<Whitelabel>;
  /** Retrieve document version */
  whitelabelVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple whitelabels */
  whitelabels: Array<Whitelabel>;
  /** Retrieve multiple whitelabels using the Relay connection interface */
  whitelabelsConnection: WhitelabelConnection;
};


export type QueryAccordionChapterArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AccordionChapterWhereUniqueInput;
};


export type QueryAccordionChapterListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AccordionChapterListWhereUniqueInput;
};


export type QueryAccordionChapterListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAccordionChapterListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionChapterListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionChapterListWhereInput>;
};


export type QueryAccordionChapterListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionChapterListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionChapterListWhereInput>;
};


export type QueryAccordionChapterVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAccordionChaptersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionChapterOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionChapterWhereInput>;
};


export type QueryAccordionChaptersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionChapterOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionChapterWhereInput>;
};


export type QueryAccordionSmlListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AccordionSmlListWhereUniqueInput;
};


export type QueryAccordionSmlListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAccordionSmlListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionSmlListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionSmlListWhereInput>;
};


export type QueryAccordionSmlListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AccordionSmlListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AccordionSmlListWhereInput>;
};


export type QueryAdArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AdWhereUniqueInput;
};


export type QueryAdVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AdOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AdWhereInput>;
};


export type QueryAdsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AdOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AdWhereInput>;
};


export type QueryArticleArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ArticleWhereUniqueInput;
};


export type QueryArticleVersionArgs = {
  where: VersionWhereInput;
};


export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ArticleWhereInput>;
};


export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};


export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};


export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};


export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};


export type QueryBannerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BannerWhereUniqueInput;
};


export type QueryBannerVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBannersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryBannersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BannerWhereInput>;
};


export type QueryBodyTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BodyTypeWhereUniqueInput;
};


export type QueryBodyTypeGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BodyTypeGroupWhereUniqueInput;
};


export type QueryBodyTypeGroupVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBodyTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BodyTypeGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BodyTypeGroupWhereInput>;
};


export type QueryBodyTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BodyTypeGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BodyTypeGroupWhereInput>;
};


export type QueryBodyTypeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBodyTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BodyTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BodyTypeWhereInput>;
};


export type QueryBodyTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BodyTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BodyTypeWhereInput>;
};


export type QueryBrandArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BrandWhereUniqueInput;
};


export type QueryBrandVersionArgs = {
  where: VersionWhereInput;
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BrandWhereInput>;
};


export type QueryBrandsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<BrandWhereInput>;
};


export type QueryButtonArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ButtonWhereUniqueInput;
};


export type QueryButtonRowArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ButtonRowWhereUniqueInput;
};


export type QueryButtonRowVersionArgs = {
  where: VersionWhereInput;
};


export type QueryButtonRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ButtonRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ButtonRowWhereInput>;
};


export type QueryButtonRowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ButtonRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ButtonRowWhereInput>;
};


export type QueryButtonVersionArgs = {
  where: VersionWhereInput;
};


export type QueryButtonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ButtonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ButtonWhereInput>;
};


export type QueryButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ButtonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ButtonWhereInput>;
};


export type QueryCitatumArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CitatumWhereUniqueInput;
};


export type QueryCitatumVersionArgs = {
  where: VersionWhereInput;
};


export type QueryCitatumsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CitatumOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<CitatumWhereInput>;
};


export type QueryCitatumsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CitatumOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<CitatumWhereInput>;
};


export type QueryColorNormArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ColorNormWhereUniqueInput;
};


export type QueryColorNormVersionArgs = {
  where: VersionWhereInput;
};


export type QueryColorNormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ColorNormOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ColorNormWhereInput>;
};


export type QueryColorNormsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ColorNormOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ColorNormWhereInput>;
};


export type QueryColorTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ColorTypeWhereUniqueInput;
};


export type QueryColorTypeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryColorTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ColorTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ColorTypeWhereInput>;
};


export type QueryColorTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ColorTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ColorTypeWhereInput>;
};


export type QueryContentConfigurationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ContentConfigurationWhereUniqueInput;
};


export type QueryContentConfigurationVersionArgs = {
  where: VersionWhereInput;
};


export type QueryContentConfigurationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ContentConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ContentConfigurationWhereInput>;
};


export type QueryContentConfigurationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ContentConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ContentConfigurationWhereInput>;
};


export type QueryDealerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DealerWhereUniqueInput;
};


export type QueryDealerEmployeeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DealerEmployeeWhereUniqueInput;
};


export type QueryDealerEmployeeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryDealerEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DealerEmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DealerEmployeeWhereInput>;
};


export type QueryDealerEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DealerEmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DealerEmployeeWhereInput>;
};


export type QueryDealerVersionArgs = {
  where: VersionWhereInput;
};


export type QueryDealersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryDealersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryDepartmentArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DepartmentWhereUniqueInput;
};


export type QueryDepartmentVersionArgs = {
  where: VersionWhereInput;
};


export type QueryDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DepartmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DepartmentWhereInput>;
};


export type QueryDepartmentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DepartmentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DepartmentWhereInput>;
};


export type QueryDriveTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DriveTypeWhereUniqueInput;
};


export type QueryDriveTypeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryDriveTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DriveTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DriveTypeWhereInput>;
};


export type QueryDriveTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DriveTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<DriveTypeWhereInput>;
};


export type QueryEmissionClassArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmissionClassWhereUniqueInput;
};


export type QueryEmissionClassVersionArgs = {
  where: VersionWhereInput;
};


export type QueryEmissionClassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmissionClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<EmissionClassWhereInput>;
};


export type QueryEmissionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmissionClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<EmissionClassWhereInput>;
};


export type QueryEmployeeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmployeeWhereUniqueInput;
};


export type QueryEmployeeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryEmployeesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmployeeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryEntitiesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  where: Array<EntityWhereInput>;
};


export type QueryFactListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FactListWhereUniqueInput;
};


export type QueryFactListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryFactListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FactListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FactListWhereInput>;
};


export type QueryFactListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FactListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FactListWhereInput>;
};


export type QueryFeaturedVehiclesRowArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FeaturedVehiclesRowWhereUniqueInput;
};


export type QueryFeaturedVehiclesRowVersionArgs = {
  where: VersionWhereInput;
};


export type QueryFeaturedVehiclesRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedVehiclesRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FeaturedVehiclesRowWhereInput>;
};


export type QueryFeaturedVehiclesRowsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedVehiclesRowOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FeaturedVehiclesRowWhereInput>;
};


export type QueryFuelTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FuelTypeWhereUniqueInput;
};


export type QueryFuelTypeGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FuelTypeGroupWhereUniqueInput;
};


export type QueryFuelTypeGroupVersionArgs = {
  where: VersionWhereInput;
};


export type QueryFuelTypeGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FuelTypeGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FuelTypeGroupWhereInput>;
};


export type QueryFuelTypeGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FuelTypeGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FuelTypeGroupWhereInput>;
};


export type QueryFuelTypeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryFuelTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FuelTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FuelTypeWhereInput>;
};


export type QueryFuelTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FuelTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<FuelTypeWhereInput>;
};


export type QueryGalleriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GalleryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<GalleryWhereInput>;
};


export type QueryGalleriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GalleryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<GalleryWhereInput>;
};


export type QueryGalleryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GalleryWhereUniqueInput;
};


export type QueryGalleryVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGeneratedPageExtensionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GeneratedPageExtensionWhereUniqueInput;
};


export type QueryGeneratedPageExtensionVersionArgs = {
  where: VersionWhereInput;
};


export type QueryGeneratedPageExtensionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GeneratedPageExtensionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<GeneratedPageExtensionWhereInput>;
};


export type QueryGeneratedPageExtensionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GeneratedPageExtensionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<GeneratedPageExtensionWhereInput>;
};


export type QueryHeroSliderArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: HeroSliderWhereUniqueInput;
};


export type QueryHeroSliderVersionArgs = {
  where: VersionWhereInput;
};


export type QueryHeroSlidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HeroSliderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<HeroSliderWhereInput>;
};


export type QueryHeroSlidersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<HeroSliderOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<HeroSliderWhereInput>;
};


export type QueryImageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ImageWhereUniqueInput;
};


export type QueryImageTextComboListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ImageTextComboListWhereUniqueInput;
};


export type QueryImageTextComboListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryImageTextComboListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ImageTextComboListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ImageTextComboListWhereInput>;
};


export type QueryImageTextComboListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ImageTextComboListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ImageTextComboListWhereInput>;
};


export type QueryImageVersionArgs = {
  where: VersionWhereInput;
};


export type QueryImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ImageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ImageWhereInput>;
};


export type QueryImagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ImageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ImageWhereInput>;
};


export type QueryKeywordListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: KeywordListWhereUniqueInput;
};


export type QueryKeywordListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeywordListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type QueryKeywordListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<KeywordListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type QueryModelArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ModelWhereUniqueInput;
};


export type QueryModelVersionArgs = {
  where: VersionWhereInput;
};


export type QueryModelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ModelWhereInput>;
};


export type QueryModelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ModelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ModelWhereInput>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
  locales?: Array<Locale>;
  stage?: Stage;
};


export type QueryOptionFacetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: OptionFacetWhereUniqueInput;
};


export type QueryOptionFacetVersionArgs = {
  where: VersionWhereInput;
};


export type QueryOptionFacetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<OptionFacetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<OptionFacetWhereInput>;
};


export type QueryOptionFacetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<OptionFacetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<OptionFacetWhereInput>;
};


export type QueryPaPromotionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PaPromotionWhereUniqueInput;
};


export type QueryPaPromotionVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPaPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PaPromotionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PaPromotionWhereInput>;
};


export type QueryPaPromotionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PaPromotionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PaPromotionWhereInput>;
};


export type QueryPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageWhereUniqueInput;
};


export type QueryPageVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};


export type QueryPollutionClassArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PollutionClassWhereUniqueInput;
};


export type QueryPollutionClassVersionArgs = {
  where: VersionWhereInput;
};


export type QueryPollutionClassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PollutionClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PollutionClassWhereInput>;
};


export type QueryPollutionClassesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PollutionClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<PollutionClassWhereInput>;
};


export type QueryPromotionArgs = {
  promotionInput: InsideHubApi_PromotionInput;
};


export type QueryQualityCheckedArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: QualityCheckedWhereUniqueInput;
};


export type QueryQualityCheckedVersionArgs = {
  where: VersionWhereInput;
};


export type QueryQualityCheckedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QualityCheckedOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QualityCheckedWhereInput>;
};


export type QueryQualityCheckedsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QualityCheckedOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QualityCheckedWhereInput>;
};


export type QueryQuestionAndAnswerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: QuestionAndAnswerWhereUniqueInput;
};


export type QueryQuestionAndAnswerListArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: QuestionAndAnswerListWhereUniqueInput;
};


export type QueryQuestionAndAnswerListVersionArgs = {
  where: VersionWhereInput;
};


export type QueryQuestionAndAnswerListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QuestionAndAnswerListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QuestionAndAnswerListWhereInput>;
};


export type QueryQuestionAndAnswerListsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QuestionAndAnswerListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QuestionAndAnswerListWhereInput>;
};


export type QueryQuestionAndAnswerVersionArgs = {
  where: VersionWhereInput;
};


export type QueryQuestionAndAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QuestionAndAnswerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QuestionAndAnswerWhereInput>;
};


export type QueryQuestionAndAnswersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<QuestionAndAnswerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<QuestionAndAnswerWhereInput>;
};


export type QueryRootArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: RootWhereUniqueInput;
};


export type QueryRootVersionArgs = {
  where: VersionWhereInput;
};


export type QueryRootsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RootOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<RootWhereInput>;
};


export type QueryRootsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RootOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<RootWhereInput>;
};


export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};


export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};


export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};


export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};


export type QueryServiceArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ServiceWhereUniqueInput;
};


export type QueryServiceVersionArgs = {
  where: VersionWhereInput;
};


export type QueryServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QueryServicesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ServiceOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<ServiceWhereInput>;
};


export type QuerySpecialBoxArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SpecialBoxWhereUniqueInput;
};


export type QuerySpecialBoxVersionArgs = {
  where: VersionWhereInput;
};


export type QuerySpecialBoxesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SpecialBoxOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<SpecialBoxWhereInput>;
};


export type QuerySpecialBoxesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SpecialBoxOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<SpecialBoxWhereInput>;
};


export type QueryTransmissionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TransmissionWhereUniqueInput;
};


export type QueryTransmissionGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TransmissionGroupWhereUniqueInput;
};


export type QueryTransmissionGroupVersionArgs = {
  where: VersionWhereInput;
};


export type QueryTransmissionGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TransmissionGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<TransmissionGroupWhereInput>;
};


export type QueryTransmissionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TransmissionGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<TransmissionGroupWhereInput>;
};


export type QueryTransmissionVersionArgs = {
  where: VersionWhereInput;
};


export type QueryTransmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TransmissionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<TransmissionWhereInput>;
};


export type QueryTransmissionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TransmissionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<TransmissionWhereInput>;
};


export type QueryUpholsteryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UpholsteryWhereUniqueInput;
};


export type QueryUpholsteryVersionArgs = {
  where: VersionWhereInput;
};


export type QueryUpholsterysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UpholsteryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<UpholsteryWhereInput>;
};


export type QueryUpholsterysConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UpholsteryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<UpholsteryWhereInput>;
};


export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVehicleArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VehicleWhereUniqueInput;
};


export type QueryVehicleClassArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VehicleClassWhereUniqueInput;
};


export type QueryVehicleClassVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVehicleClassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleClassWhereInput>;
};


export type QueryVehicleClassesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleClassOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleClassWhereInput>;
};


export type QueryVehicleConditionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VehicleConditionWhereUniqueInput;
};


export type QueryVehicleConditionVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVehicleConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleConditionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleConditionWhereInput>;
};


export type QueryVehicleConditionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleConditionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleConditionWhereInput>;
};


export type QueryVehicleStatusArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VehicleStatusWhereUniqueInput;
};


export type QueryVehicleStatusVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVehicleStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleStatusOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleStatusWhereInput>;
};


export type QueryVehicleStatusesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleStatusOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleStatusWhereInput>;
};


export type QueryVehicleTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VehicleTypeWhereUniqueInput;
};


export type QueryVehicleTypeVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVehicleTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleTypeWhereInput>;
};


export type QueryVehicleTypesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleTypeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleTypeWhereInput>;
};


export type QueryVehicleVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleWhereInput>;
};


export type QueryVehiclesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VehicleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VehicleWhereInput>;
};


export type QueryVideoArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: VideoWhereUniqueInput;
};


export type QueryVideoVersionArgs = {
  where: VersionWhereInput;
};


export type QueryVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryVideosConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<VideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<VideoWhereInput>;
};


export type QueryWhitelabelArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: WhitelabelWhereUniqueInput;
};


export type QueryWhitelabelVersionArgs = {
  where: VersionWhereInput;
};


export type QueryWhitelabelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<WhitelabelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<WhitelabelWhereInput>;
};


export type QueryWhitelabelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<WhitelabelOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  stage?: Stage;
  where?: InputMaybe<WhitelabelWhereInput>;
};

export type QuestionAndAnswer = Entity & Node & {
  __typename?: 'QuestionAndAnswer';
  answerText: RichText;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<QuestionAndAnswer>;
  /** List of QuestionAndAnswer versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<QuestionAndAnswer>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  questionAndAnswerLists: Array<QuestionAndAnswerList>;
  questionName: Scalars['String']['output'];
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type QuestionAndAnswerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QuestionAndAnswerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type QuestionAndAnswerHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type QuestionAndAnswerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type QuestionAndAnswerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QuestionAndAnswerQuestionAndAnswerListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<QuestionAndAnswerListOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerListWhereInput>;
};


export type QuestionAndAnswerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QuestionAndAnswerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type QuestionAndAnswerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: QuestionAndAnswerWhereUniqueInput;
};

/** A connection to a list of items. */
export type QuestionAndAnswerConnection = {
  __typename?: 'QuestionAndAnswerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<QuestionAndAnswerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type QuestionAndAnswerCreateInput = {
  /** answerText input for default locale (de) */
  answerText: Scalars['RichTextAST']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<QuestionAndAnswerCreateLocalizationsInput>;
  questionAndAnswerLists?: InputMaybe<QuestionAndAnswerListCreateManyInlineInput>;
  /** questionName input for default locale (de) */
  questionName: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuestionAndAnswerCreateLocalizationDataInput = {
  answerText: Scalars['RichTextAST']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  questionName: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuestionAndAnswerCreateLocalizationInput = {
  /** Localization input */
  data: QuestionAndAnswerCreateLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<QuestionAndAnswerCreateLocalizationInput>>;
};

export type QuestionAndAnswerCreateManyInlineInput = {
  /** Connect multiple existing QuestionAndAnswer documents */
  connect?: InputMaybe<Array<QuestionAndAnswerWhereUniqueInput>>;
  /** Create and connect multiple existing QuestionAndAnswer documents */
  create?: InputMaybe<Array<QuestionAndAnswerCreateInput>>;
};

export type QuestionAndAnswerCreateOneInlineInput = {
  /** Connect one existing QuestionAndAnswer document */
  connect?: InputMaybe<QuestionAndAnswerWhereUniqueInput>;
  /** Create and connect one QuestionAndAnswer document */
  create?: InputMaybe<QuestionAndAnswerCreateInput>;
};

/** An edge in a connection. */
export type QuestionAndAnswerEdge = {
  __typename?: 'QuestionAndAnswerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: QuestionAndAnswer;
};

export type QuestionAndAnswerList = Entity & Node & {
  __typename?: 'QuestionAndAnswerList';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<QuestionAndAnswerList>;
  /** List of QuestionAndAnswerList versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<QuestionAndAnswerList>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  questionAndAnswers: Array<QuestionAndAnswer>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type QuestionAndAnswerListCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerListCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QuestionAndAnswerListDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type QuestionAndAnswerListHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type QuestionAndAnswerListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type QuestionAndAnswerListPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerListPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type QuestionAndAnswerListQuestionAndAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<QuestionAndAnswerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<QuestionAndAnswerWhereInput>;
};


export type QuestionAndAnswerListScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type QuestionAndAnswerListUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type QuestionAndAnswerListUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type QuestionAndAnswerListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: QuestionAndAnswerListWhereUniqueInput;
};

/** A connection to a list of items. */
export type QuestionAndAnswerListConnection = {
  __typename?: 'QuestionAndAnswerListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<QuestionAndAnswerListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type QuestionAndAnswerListCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<QuestionAndAnswerListCreateLocalizationsInput>;
  questionAndAnswers?: InputMaybe<QuestionAndAnswerCreateManyInlineInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuestionAndAnswerListCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QuestionAndAnswerListCreateLocalizationInput = {
  /** Localization input */
  data: QuestionAndAnswerListCreateLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<QuestionAndAnswerListCreateLocalizationInput>>;
};

export type QuestionAndAnswerListCreateManyInlineInput = {
  /** Connect multiple existing QuestionAndAnswerList documents */
  connect?: InputMaybe<Array<QuestionAndAnswerListWhereUniqueInput>>;
  /** Create and connect multiple existing QuestionAndAnswerList documents */
  create?: InputMaybe<Array<QuestionAndAnswerListCreateInput>>;
};

export type QuestionAndAnswerListCreateOneInlineInput = {
  /** Connect one existing QuestionAndAnswerList document */
  connect?: InputMaybe<QuestionAndAnswerListWhereUniqueInput>;
  /** Create and connect one QuestionAndAnswerList document */
  create?: InputMaybe<QuestionAndAnswerListCreateInput>;
};

/** An edge in a connection. */
export type QuestionAndAnswerListEdge = {
  __typename?: 'QuestionAndAnswerListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: QuestionAndAnswerList;
};

/** Identifies documents */
export type QuestionAndAnswerListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  documentInStages_none?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  documentInStages_some?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  questionAndAnswers_every?: InputMaybe<QuestionAndAnswerWhereInput>;
  questionAndAnswers_none?: InputMaybe<QuestionAndAnswerWhereInput>;
  questionAndAnswers_some?: InputMaybe<QuestionAndAnswerWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum QuestionAndAnswerListOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type QuestionAndAnswerListUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<QuestionAndAnswerListUpdateLocalizationsInput>;
  questionAndAnswers?: InputMaybe<QuestionAndAnswerUpdateManyInlineInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerListUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerListUpdateLocalizationInput = {
  data: QuestionAndAnswerListUpdateLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<QuestionAndAnswerListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<QuestionAndAnswerListUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<QuestionAndAnswerListUpsertLocalizationInput>>;
};

export type QuestionAndAnswerListUpdateManyInlineInput = {
  /** Connect multiple existing QuestionAndAnswerList documents */
  connect?: InputMaybe<Array<QuestionAndAnswerListConnectInput>>;
  /** Create and connect multiple QuestionAndAnswerList documents */
  create?: InputMaybe<Array<QuestionAndAnswerListCreateInput>>;
  /** Delete multiple QuestionAndAnswerList documents */
  delete?: InputMaybe<Array<QuestionAndAnswerListWhereUniqueInput>>;
  /** Disconnect multiple QuestionAndAnswerList documents */
  disconnect?: InputMaybe<Array<QuestionAndAnswerListWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing QuestionAndAnswerList documents */
  set?: InputMaybe<Array<QuestionAndAnswerListWhereUniqueInput>>;
  /** Update multiple QuestionAndAnswerList documents */
  update?: InputMaybe<Array<QuestionAndAnswerListUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple QuestionAndAnswerList documents */
  upsert?: InputMaybe<Array<QuestionAndAnswerListUpsertWithNestedWhereUniqueInput>>;
};

export type QuestionAndAnswerListUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<QuestionAndAnswerListUpdateManyLocalizationsInput>;
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerListUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerListUpdateManyLocalizationInput = {
  data: QuestionAndAnswerListUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<QuestionAndAnswerListUpdateManyLocalizationInput>>;
};

export type QuestionAndAnswerListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: QuestionAndAnswerListUpdateManyInput;
  /** Document search */
  where: QuestionAndAnswerListWhereInput;
};

export type QuestionAndAnswerListUpdateOneInlineInput = {
  /** Connect existing QuestionAndAnswerList document */
  connect?: InputMaybe<QuestionAndAnswerListWhereUniqueInput>;
  /** Create and connect one QuestionAndAnswerList document */
  create?: InputMaybe<QuestionAndAnswerListCreateInput>;
  /** Delete currently connected QuestionAndAnswerList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected QuestionAndAnswerList document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single QuestionAndAnswerList document */
  update?: InputMaybe<QuestionAndAnswerListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single QuestionAndAnswerList document */
  upsert?: InputMaybe<QuestionAndAnswerListUpsertWithNestedWhereUniqueInput>;
};

export type QuestionAndAnswerListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: QuestionAndAnswerListUpdateInput;
  /** Unique document search */
  where: QuestionAndAnswerListWhereUniqueInput;
};

export type QuestionAndAnswerListUpsertInput = {
  /** Create document if it didn't exist */
  create: QuestionAndAnswerListCreateInput;
  /** Update document if it exists */
  update: QuestionAndAnswerListUpdateInput;
};

export type QuestionAndAnswerListUpsertLocalizationInput = {
  create: QuestionAndAnswerListCreateLocalizationDataInput;
  locale: Locale;
  update: QuestionAndAnswerListUpdateLocalizationDataInput;
};

export type QuestionAndAnswerListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: QuestionAndAnswerListUpsertInput;
  /** Unique document search */
  where: QuestionAndAnswerListWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type QuestionAndAnswerListWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type QuestionAndAnswerListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  documentInStages_none?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  documentInStages_some?: InputMaybe<QuestionAndAnswerListWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  questionAndAnswers_every?: InputMaybe<QuestionAndAnswerWhereInput>;
  questionAndAnswers_none?: InputMaybe<QuestionAndAnswerWhereInput>;
  questionAndAnswers_some?: InputMaybe<QuestionAndAnswerWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type QuestionAndAnswerListWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerListWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerListWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerListWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<QuestionAndAnswerListWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References QuestionAndAnswerList record uniquely */
export type QuestionAndAnswerListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type QuestionAndAnswerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  documentInStages_none?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  documentInStages_some?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  questionAndAnswerLists_every?: InputMaybe<QuestionAndAnswerListWhereInput>;
  questionAndAnswerLists_none?: InputMaybe<QuestionAndAnswerListWhereInput>;
  questionAndAnswerLists_some?: InputMaybe<QuestionAndAnswerListWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum QuestionAndAnswerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  QuestionNameAsc = 'questionName_ASC',
  QuestionNameDesc = 'questionName_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type QuestionAndAnswerUpdateInput = {
  /** answerText input for default locale (de) */
  answerText?: InputMaybe<Scalars['RichTextAST']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<QuestionAndAnswerUpdateLocalizationsInput>;
  questionAndAnswerLists?: InputMaybe<QuestionAndAnswerListUpdateManyInlineInput>;
  /** questionName input for default locale (de) */
  questionName?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerUpdateLocalizationDataInput = {
  answerText?: InputMaybe<Scalars['RichTextAST']['input']>;
  questionName?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerUpdateLocalizationInput = {
  data: QuestionAndAnswerUpdateLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<QuestionAndAnswerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<QuestionAndAnswerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<QuestionAndAnswerUpsertLocalizationInput>>;
};

export type QuestionAndAnswerUpdateManyInlineInput = {
  /** Connect multiple existing QuestionAndAnswer documents */
  connect?: InputMaybe<Array<QuestionAndAnswerConnectInput>>;
  /** Create and connect multiple QuestionAndAnswer documents */
  create?: InputMaybe<Array<QuestionAndAnswerCreateInput>>;
  /** Delete multiple QuestionAndAnswer documents */
  delete?: InputMaybe<Array<QuestionAndAnswerWhereUniqueInput>>;
  /** Disconnect multiple QuestionAndAnswer documents */
  disconnect?: InputMaybe<Array<QuestionAndAnswerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing QuestionAndAnswer documents */
  set?: InputMaybe<Array<QuestionAndAnswerWhereUniqueInput>>;
  /** Update multiple QuestionAndAnswer documents */
  update?: InputMaybe<Array<QuestionAndAnswerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple QuestionAndAnswer documents */
  upsert?: InputMaybe<Array<QuestionAndAnswerUpsertWithNestedWhereUniqueInput>>;
};

export type QuestionAndAnswerUpdateManyInput = {
  /** answerText input for default locale (de) */
  answerText?: InputMaybe<Scalars['RichTextAST']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<QuestionAndAnswerUpdateManyLocalizationsInput>;
  /** questionName input for default locale (de) */
  questionName?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerUpdateManyLocalizationDataInput = {
  answerText?: InputMaybe<Scalars['RichTextAST']['input']>;
  questionName?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAndAnswerUpdateManyLocalizationInput = {
  data: QuestionAndAnswerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type QuestionAndAnswerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<QuestionAndAnswerUpdateManyLocalizationInput>>;
};

export type QuestionAndAnswerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: QuestionAndAnswerUpdateManyInput;
  /** Document search */
  where: QuestionAndAnswerWhereInput;
};

export type QuestionAndAnswerUpdateOneInlineInput = {
  /** Connect existing QuestionAndAnswer document */
  connect?: InputMaybe<QuestionAndAnswerWhereUniqueInput>;
  /** Create and connect one QuestionAndAnswer document */
  create?: InputMaybe<QuestionAndAnswerCreateInput>;
  /** Delete currently connected QuestionAndAnswer document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected QuestionAndAnswer document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single QuestionAndAnswer document */
  update?: InputMaybe<QuestionAndAnswerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single QuestionAndAnswer document */
  upsert?: InputMaybe<QuestionAndAnswerUpsertWithNestedWhereUniqueInput>;
};

export type QuestionAndAnswerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: QuestionAndAnswerUpdateInput;
  /** Unique document search */
  where: QuestionAndAnswerWhereUniqueInput;
};

export type QuestionAndAnswerUpsertInput = {
  /** Create document if it didn't exist */
  create: QuestionAndAnswerCreateInput;
  /** Update document if it exists */
  update: QuestionAndAnswerUpdateInput;
};

export type QuestionAndAnswerUpsertLocalizationInput = {
  create: QuestionAndAnswerCreateLocalizationDataInput;
  locale: Locale;
  update: QuestionAndAnswerUpdateLocalizationDataInput;
};

export type QuestionAndAnswerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: QuestionAndAnswerUpsertInput;
  /** Unique document search */
  where: QuestionAndAnswerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type QuestionAndAnswerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type QuestionAndAnswerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  documentInStages_none?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  documentInStages_some?: InputMaybe<QuestionAndAnswerWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  questionAndAnswerLists_every?: InputMaybe<QuestionAndAnswerListWhereInput>;
  questionAndAnswerLists_none?: InputMaybe<QuestionAndAnswerListWhereInput>;
  questionAndAnswerLists_some?: InputMaybe<QuestionAndAnswerListWhereInput>;
  questionName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  questionName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  questionName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  questionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  questionName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  questionName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  questionName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  questionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  questionName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  questionName_starts_with?: InputMaybe<Scalars['String']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type QuestionAndAnswerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<QuestionAndAnswerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<QuestionAndAnswerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<QuestionAndAnswerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<QuestionAndAnswerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References QuestionAndAnswer record uniquely */
export type QuestionAndAnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA';
  a: Scalars['RGBATransparency']['output'];
  b: Scalars['RGBAHue']['output'];
  g: Scalars['RGBAHue']['output'];
  r: Scalars['RGBAHue']['output'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars['RGBATransparency']['input'];
  b: Scalars['RGBAHue']['input'];
  g: Scalars['RGBAHue']['input'];
  r: Scalars['RGBAHue']['input'];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText';
  /** Returns HTMl representation */
  html: Scalars['String']['output'];
  /** Returns Markdown representation */
  markdown: Scalars['String']['output'];
  /** Returns AST representation */
  raw: Scalars['RichTextAST']['output'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output'];
};

export type Root = Entity & Node & {
  __typename?: 'Root';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Root>;
  /** List of Root versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  pages: Array<Page>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  rootPath: RootPath;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type RootCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type RootDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type RootHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type RootPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};


export type RootPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type RootScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type RootUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RootConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RootWhereUniqueInput;
};

/** A connection to a list of items. */
export type RootConnection = {
  __typename?: 'RootConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RootEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RootCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  pages?: InputMaybe<PageCreateManyInlineInput>;
  rootPath: RootPath;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RootCreateManyInlineInput = {
  /** Connect multiple existing Root documents */
  connect?: InputMaybe<Array<RootWhereUniqueInput>>;
  /** Create and connect multiple existing Root documents */
  create?: InputMaybe<Array<RootCreateInput>>;
};

export type RootCreateOneInlineInput = {
  /** Connect one existing Root document */
  connect?: InputMaybe<RootWhereUniqueInput>;
  /** Create and connect one Root document */
  create?: InputMaybe<RootCreateInput>;
};

/** An edge in a connection. */
export type RootEdge = {
  __typename?: 'RootEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Root;
};

/** Identifies documents */
export type RootManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RootWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RootWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RootWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RootWhereStageInput>;
  documentInStages_none?: InputMaybe<RootWhereStageInput>;
  documentInStages_some?: InputMaybe<RootWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rootPath?: InputMaybe<RootPath>;
  /** All values that are contained in given list. */
  rootPath_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  /** Any other value that exists and is not equal to the given value. */
  rootPath_not?: InputMaybe<RootPath>;
  /** All values that are not contained in given list. */
  rootPath_not_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum RootOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RootPathAsc = 'rootPath_ASC',
  RootPathDesc = 'rootPath_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum RootPath {
  General = 'general',
  Yourvehicle = 'yourvehicle'
}

export type RootUpdateInput = {
  pages?: InputMaybe<PageUpdateManyInlineInput>;
  rootPath?: InputMaybe<RootPath>;
};

export type RootUpdateManyInlineInput = {
  /** Connect multiple existing Root documents */
  connect?: InputMaybe<Array<RootConnectInput>>;
  /** Create and connect multiple Root documents */
  create?: InputMaybe<Array<RootCreateInput>>;
  /** Delete multiple Root documents */
  delete?: InputMaybe<Array<RootWhereUniqueInput>>;
  /** Disconnect multiple Root documents */
  disconnect?: InputMaybe<Array<RootWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Root documents */
  set?: InputMaybe<Array<RootWhereUniqueInput>>;
  /** Update multiple Root documents */
  update?: InputMaybe<Array<RootUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Root documents */
  upsert?: InputMaybe<Array<RootUpsertWithNestedWhereUniqueInput>>;
};

export type RootUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type RootUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RootUpdateManyInput;
  /** Document search */
  where: RootWhereInput;
};

export type RootUpdateOneInlineInput = {
  /** Connect existing Root document */
  connect?: InputMaybe<RootWhereUniqueInput>;
  /** Create and connect one Root document */
  create?: InputMaybe<RootCreateInput>;
  /** Delete currently connected Root document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Root document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Root document */
  update?: InputMaybe<RootUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Root document */
  upsert?: InputMaybe<RootUpsertWithNestedWhereUniqueInput>;
};

export type RootUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RootUpdateInput;
  /** Unique document search */
  where: RootWhereUniqueInput;
};

export type RootUpsertInput = {
  /** Create document if it didn't exist */
  create: RootCreateInput;
  /** Update document if it exists */
  update: RootUpdateInput;
};

export type RootUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RootUpsertInput;
  /** Unique document search */
  where: RootWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type RootWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type RootWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RootWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RootWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RootWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RootWhereStageInput>;
  documentInStages_none?: InputMaybe<RootWhereStageInput>;
  documentInStages_some?: InputMaybe<RootWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  rootPath?: InputMaybe<RootPath>;
  /** All values that are contained in given list. */
  rootPath_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  /** Any other value that exists and is not equal to the given value. */
  rootPath_not?: InputMaybe<RootPath>;
  /** All values that are not contained in given list. */
  rootPath_not_in?: InputMaybe<Array<InputMaybe<RootPath>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type RootWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RootWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RootWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RootWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<RootWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Root record uniquely */
export type RootWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  rootPath?: InputMaybe<RootPath>;
};

export type SalesAdvisor = Entity & {
  __typename?: 'SalesAdvisor';
  brands: Array<Brand>;
  employee?: Maybe<Employee>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System stage field */
  stage: Stage;
  vehicleCondition: SalesAdvisorVehicleCondition;
};


export type SalesAdvisorBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BrandOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BrandWhereInput>;
};


export type SalesAdvisorEmployeeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SalesAdvisorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SalesAdvisorWhereUniqueInput;
};

/** A connection to a list of items. */
export type SalesAdvisorConnection = {
  __typename?: 'SalesAdvisorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SalesAdvisorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SalesAdvisorCreateInput = {
  brands?: InputMaybe<BrandCreateManyInlineInput>;
  employee?: InputMaybe<EmployeeCreateOneInlineInput>;
  vehicleCondition: SalesAdvisorVehicleCondition;
};

export type SalesAdvisorCreateManyInlineInput = {
  /** Create and connect multiple existing SalesAdvisor documents */
  create?: InputMaybe<Array<SalesAdvisorCreateInput>>;
};

export type SalesAdvisorCreateOneInlineInput = {
  /** Create and connect one SalesAdvisor document */
  create?: InputMaybe<SalesAdvisorCreateInput>;
};

export type SalesAdvisorCreateWithPositionInput = {
  /** Document to create */
  data: SalesAdvisorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SalesAdvisorEdge = {
  __typename?: 'SalesAdvisorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesAdvisor;
};

export type SalesAdvisorList = Entity & {
  __typename?: 'SalesAdvisorList';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  salesAdvisors: Array<SalesAdvisor>;
  /** System stage field */
  stage: Stage;
};


export type SalesAdvisorListSalesAdvisorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SalesAdvisorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SalesAdvisorWhereInput>;
};

export type SalesAdvisorListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SalesAdvisorListWhereUniqueInput;
};

/** A connection to a list of items. */
export type SalesAdvisorListConnection = {
  __typename?: 'SalesAdvisorListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SalesAdvisorListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SalesAdvisorListCreateInput = {
  salesAdvisors?: InputMaybe<SalesAdvisorCreateManyInlineInput>;
};

export type SalesAdvisorListCreateManyInlineInput = {
  /** Create and connect multiple existing SalesAdvisorList documents */
  create?: InputMaybe<Array<SalesAdvisorListCreateInput>>;
};

export type SalesAdvisorListCreateOneInlineInput = {
  /** Create and connect one SalesAdvisorList document */
  create?: InputMaybe<SalesAdvisorListCreateInput>;
};

export type SalesAdvisorListCreateWithPositionInput = {
  /** Document to create */
  data: SalesAdvisorListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SalesAdvisorListEdge = {
  __typename?: 'SalesAdvisorListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SalesAdvisorList;
};

/** Identifies documents */
export type SalesAdvisorListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  salesAdvisors_every?: InputMaybe<SalesAdvisorWhereInput>;
  salesAdvisors_none?: InputMaybe<SalesAdvisorWhereInput>;
  salesAdvisors_some?: InputMaybe<SalesAdvisorWhereInput>;
};

export enum SalesAdvisorListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type SalesAdvisorListParent = Dealer;

export type SalesAdvisorListParentConnectInput = {
  Dealer?: InputMaybe<DealerConnectInput>;
};

export type SalesAdvisorListParentCreateInput = {
  Dealer?: InputMaybe<DealerCreateInput>;
};

export type SalesAdvisorListParentCreateManyInlineInput = {
  /** Connect multiple existing SalesAdvisorListParent documents */
  connect?: InputMaybe<Array<SalesAdvisorListParentWhereUniqueInput>>;
  /** Create and connect multiple existing SalesAdvisorListParent documents */
  create?: InputMaybe<Array<SalesAdvisorListParentCreateInput>>;
};

export type SalesAdvisorListParentCreateOneInlineInput = {
  /** Connect one existing SalesAdvisorListParent document */
  connect?: InputMaybe<SalesAdvisorListParentWhereUniqueInput>;
  /** Create and connect one SalesAdvisorListParent document */
  create?: InputMaybe<SalesAdvisorListParentCreateInput>;
};

export type SalesAdvisorListParentUpdateInput = {
  Dealer?: InputMaybe<DealerUpdateInput>;
};

export type SalesAdvisorListParentUpdateManyInlineInput = {
  /** Connect multiple existing SalesAdvisorListParent documents */
  connect?: InputMaybe<Array<SalesAdvisorListParentConnectInput>>;
  /** Create and connect multiple SalesAdvisorListParent documents */
  create?: InputMaybe<Array<SalesAdvisorListParentCreateInput>>;
  /** Delete multiple SalesAdvisorListParent documents */
  delete?: InputMaybe<Array<SalesAdvisorListParentWhereUniqueInput>>;
  /** Disconnect multiple SalesAdvisorListParent documents */
  disconnect?: InputMaybe<Array<SalesAdvisorListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SalesAdvisorListParent documents */
  set?: InputMaybe<Array<SalesAdvisorListParentWhereUniqueInput>>;
  /** Update multiple SalesAdvisorListParent documents */
  update?: InputMaybe<Array<SalesAdvisorListParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SalesAdvisorListParent documents */
  upsert?: InputMaybe<Array<SalesAdvisorListParentUpsertWithNestedWhereUniqueInput>>;
};

export type SalesAdvisorListParentUpdateManyWithNestedWhereInput = {
  Dealer?: InputMaybe<DealerUpdateManyWithNestedWhereInput>;
};

export type SalesAdvisorListParentUpdateOneInlineInput = {
  /** Connect existing SalesAdvisorListParent document */
  connect?: InputMaybe<SalesAdvisorListParentWhereUniqueInput>;
  /** Create and connect one SalesAdvisorListParent document */
  create?: InputMaybe<SalesAdvisorListParentCreateInput>;
  /** Delete currently connected SalesAdvisorListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected SalesAdvisorListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single SalesAdvisorListParent document */
  update?: InputMaybe<SalesAdvisorListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SalesAdvisorListParent document */
  upsert?: InputMaybe<SalesAdvisorListParentUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorListParentUpdateWithNestedWhereUniqueInput = {
  Dealer?: InputMaybe<DealerUpdateWithNestedWhereUniqueInput>;
};

export type SalesAdvisorListParentUpsertWithNestedWhereUniqueInput = {
  Dealer?: InputMaybe<DealerUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorListParentWhereInput = {
  Dealer?: InputMaybe<DealerWhereInput>;
};

export type SalesAdvisorListParentWhereUniqueInput = {
  Dealer?: InputMaybe<DealerWhereUniqueInput>;
};

export type SalesAdvisorListUpdateInput = {
  salesAdvisors?: InputMaybe<SalesAdvisorUpdateManyInlineInput>;
};

export type SalesAdvisorListUpdateManyInlineInput = {
  /** Create and connect multiple SalesAdvisorList component instances */
  create?: InputMaybe<Array<SalesAdvisorListCreateWithPositionInput>>;
  /** Delete multiple SalesAdvisorList documents */
  delete?: InputMaybe<Array<SalesAdvisorListWhereUniqueInput>>;
  /** Update multiple SalesAdvisorList component instances */
  update?: InputMaybe<Array<SalesAdvisorListUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple SalesAdvisorList component instances */
  upsert?: InputMaybe<Array<SalesAdvisorListUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SalesAdvisorListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type SalesAdvisorListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SalesAdvisorListUpdateManyInput;
  /** Document search */
  where: SalesAdvisorListWhereInput;
};

export type SalesAdvisorListUpdateOneInlineInput = {
  /** Create and connect one SalesAdvisorList document */
  create?: InputMaybe<SalesAdvisorListCreateInput>;
  /** Delete currently connected SalesAdvisorList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single SalesAdvisorList document */
  update?: InputMaybe<SalesAdvisorListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SalesAdvisorList document */
  upsert?: InputMaybe<SalesAdvisorListUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SalesAdvisorListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SalesAdvisorListWhereUniqueInput;
};

export type SalesAdvisorListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SalesAdvisorListUpdateInput;
  /** Unique document search */
  where: SalesAdvisorListWhereUniqueInput;
};

export type SalesAdvisorListUpsertInput = {
  /** Create document if it didn't exist */
  create: SalesAdvisorListCreateInput;
  /** Update document if it exists */
  update: SalesAdvisorListUpdateInput;
};

export type SalesAdvisorListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SalesAdvisorListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SalesAdvisorListWhereUniqueInput;
};

export type SalesAdvisorListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SalesAdvisorListUpsertInput;
  /** Unique document search */
  where: SalesAdvisorListWhereUniqueInput;
};

/** Identifies documents */
export type SalesAdvisorListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SalesAdvisorListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  salesAdvisors_every?: InputMaybe<SalesAdvisorWhereInput>;
  salesAdvisors_none?: InputMaybe<SalesAdvisorWhereInput>;
  salesAdvisors_some?: InputMaybe<SalesAdvisorWhereInput>;
};

/** References SalesAdvisorList record uniquely */
export type SalesAdvisorListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type SalesAdvisorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  employee?: InputMaybe<EmployeeWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  vehicleCondition?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are contained in given list. */
  vehicleCondition_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  vehicleCondition_not?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are not contained in given list. */
  vehicleCondition_not_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
};

export enum SalesAdvisorOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VehicleConditionAsc = 'vehicleCondition_ASC',
  VehicleConditionDesc = 'vehicleCondition_DESC'
}

export type SalesAdvisorParent = SalesAdvisorList;

export type SalesAdvisorParentConnectInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListConnectInput>;
};

export type SalesAdvisorParentCreateInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListCreateInput>;
};

export type SalesAdvisorParentCreateManyInlineInput = {
  /** Create and connect multiple existing SalesAdvisorParent documents */
  create?: InputMaybe<Array<SalesAdvisorParentCreateInput>>;
};

export type SalesAdvisorParentCreateOneInlineInput = {
  /** Create and connect one SalesAdvisorParent document */
  create?: InputMaybe<SalesAdvisorParentCreateInput>;
};

export type SalesAdvisorParentCreateWithPositionInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListCreateWithPositionInput>;
};

export type SalesAdvisorParentUpdateInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpdateInput>;
};

export type SalesAdvisorParentUpdateManyInlineInput = {
  /** Create and connect multiple SalesAdvisorParent component instances */
  create?: InputMaybe<Array<SalesAdvisorParentCreateWithPositionInput>>;
  /** Delete multiple SalesAdvisorParent documents */
  delete?: InputMaybe<Array<SalesAdvisorParentWhereUniqueInput>>;
  /** Update multiple SalesAdvisorParent component instances */
  update?: InputMaybe<Array<SalesAdvisorParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple SalesAdvisorParent component instances */
  upsert?: InputMaybe<Array<SalesAdvisorParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SalesAdvisorParentUpdateManyWithNestedWhereInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpdateManyWithNestedWhereInput>;
};

export type SalesAdvisorParentUpdateOneInlineInput = {
  /** Create and connect one SalesAdvisorParent document */
  create?: InputMaybe<SalesAdvisorParentCreateInput>;
  /** Delete currently connected SalesAdvisorParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single SalesAdvisorParent document */
  update?: InputMaybe<SalesAdvisorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SalesAdvisorParent document */
  upsert?: InputMaybe<SalesAdvisorParentUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type SalesAdvisorParentUpdateWithNestedWhereUniqueInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpdateWithNestedWhereUniqueInput>;
};

export type SalesAdvisorParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type SalesAdvisorParentUpsertWithNestedWhereUniqueInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorParentWhereInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListWhereInput>;
};

export type SalesAdvisorParentWhereUniqueInput = {
  SalesAdvisorList?: InputMaybe<SalesAdvisorListWhereUniqueInput>;
};

export type SalesAdvisorUpdateInput = {
  brands?: InputMaybe<BrandUpdateManyInlineInput>;
  employee?: InputMaybe<EmployeeUpdateOneInlineInput>;
  vehicleCondition?: InputMaybe<SalesAdvisorVehicleCondition>;
};

export type SalesAdvisorUpdateManyInlineInput = {
  /** Create and connect multiple SalesAdvisor component instances */
  create?: InputMaybe<Array<SalesAdvisorCreateWithPositionInput>>;
  /** Delete multiple SalesAdvisor documents */
  delete?: InputMaybe<Array<SalesAdvisorWhereUniqueInput>>;
  /** Update multiple SalesAdvisor component instances */
  update?: InputMaybe<Array<SalesAdvisorUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple SalesAdvisor component instances */
  upsert?: InputMaybe<Array<SalesAdvisorUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SalesAdvisorUpdateManyInput = {
  vehicleCondition?: InputMaybe<SalesAdvisorVehicleCondition>;
};

export type SalesAdvisorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SalesAdvisorUpdateManyInput;
  /** Document search */
  where: SalesAdvisorWhereInput;
};

export type SalesAdvisorUpdateOneInlineInput = {
  /** Create and connect one SalesAdvisor document */
  create?: InputMaybe<SalesAdvisorCreateInput>;
  /** Delete currently connected SalesAdvisor document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single SalesAdvisor document */
  update?: InputMaybe<SalesAdvisorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SalesAdvisor document */
  upsert?: InputMaybe<SalesAdvisorUpsertWithNestedWhereUniqueInput>;
};

export type SalesAdvisorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SalesAdvisorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SalesAdvisorWhereUniqueInput;
};

export type SalesAdvisorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SalesAdvisorUpdateInput;
  /** Unique document search */
  where: SalesAdvisorWhereUniqueInput;
};

export type SalesAdvisorUpsertInput = {
  /** Create document if it didn't exist */
  create: SalesAdvisorCreateInput;
  /** Update document if it exists */
  update: SalesAdvisorUpdateInput;
};

export type SalesAdvisorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SalesAdvisorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SalesAdvisorWhereUniqueInput;
};

export type SalesAdvisorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SalesAdvisorUpsertInput;
  /** Unique document search */
  where: SalesAdvisorWhereUniqueInput;
};

export enum SalesAdvisorVehicleCondition {
  New = 'NEW',
  Used = 'USED'
}

/** Identifies documents */
export type SalesAdvisorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SalesAdvisorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  brands_every?: InputMaybe<BrandWhereInput>;
  brands_none?: InputMaybe<BrandWhereInput>;
  brands_some?: InputMaybe<BrandWhereInput>;
  employee?: InputMaybe<EmployeeWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  vehicleCondition?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are contained in given list. */
  vehicleCondition_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  vehicleCondition_not?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are not contained in given list. */
  vehicleCondition_not_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
};

/** References SalesAdvisor record uniquely */
export type SalesAdvisorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Scheduled Operation system model */
export type ScheduledOperation = Entity & Node & {
  __typename?: 'ScheduledOperation';
  affectedDocuments: Array<ScheduledOperationAffectedDocument>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Operation description */
  description?: Maybe<Scalars['String']['output']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledOperation>;
  /** Operation error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['Json']['output'];
  /** The release this operation is scheduled for */
  release?: Maybe<ScheduledRelease>;
  /** System stage field */
  stage: Stage;
  /** operation Status */
  status: ScheduledOperationStatus;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument = AccordionChapter | AccordionChapterList | AccordionSmlList | Ad | Article | Asset | Banner | BodyType | BodyTypeGroup | Brand | Button | ButtonRow | Citatum | ColorNorm | ColorType | ContentConfiguration | Dealer | DealerEmployee | Department | DriveType | EmissionClass | Employee | FactList | FeaturedVehiclesRow | FuelType | FuelTypeGroup | Gallery | GeneratedPageExtension | HeroSlider | Image | ImageTextComboList | KeywordList | Model | OptionFacet | PaPromotion | Page | PollutionClass | QualityChecked | QuestionAndAnswer | QuestionAndAnswerList | Root | Service | SpecialBox | Transmission | TransmissionGroup | Upholstery | Vehicle | VehicleClass | VehicleCondition | VehicleStatus | VehicleType | Video | Whitelabel;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Entity & Node & {
  __typename?: 'ScheduledRelease';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Release description */
  description?: Maybe<Scalars['String']['output']>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledRelease>;
  /** Release error message */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean']['output'];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean']['output'];
  /** Operations to run with this release */
  operations: Array<ScheduledOperation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Release date and time */
  releaseAt?: Maybe<Scalars['DateTime']['output']>;
  /** System stage field */
  stage: Stage;
  /** Release Status */
  status: ScheduledReleaseStatus;
  /** Release Title */
  title?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
  isImplicit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING'
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
  isImplicit?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** expressService, carWash, gasStation, etc. */
export type Service = Entity & Node & {
  __typename?: 'Service';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Service>;
  /** List of Service versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Service>;
  name: Scalars['String']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** expressService, carWash, gasStation, etc. */
export type ServicePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** expressService, carWash, gasStation, etc. */
export type ServicePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** expressService, carWash, gasStation, etc. */
export type ServiceUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ServiceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ServiceWhereUniqueInput;
};

/** A connection to a list of items. */
export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ServiceEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ServiceCreateInput = {
  cl1ahdjt1kus901yw6kpycz2k?: InputMaybe<DealerCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ServiceCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ServiceCreateLocalizationInput = {
  /** Localization input */
  data: ServiceCreateLocalizationDataInput;
  locale: Locale;
};

export type ServiceCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ServiceCreateLocalizationInput>>;
};

export type ServiceCreateManyInlineInput = {
  /** Connect multiple existing Service documents */
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  /** Create and connect multiple existing Service documents */
  create?: InputMaybe<Array<ServiceCreateInput>>;
};

export type ServiceCreateOneInlineInput = {
  /** Connect one existing Service document */
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  /** Create and connect one Service document */
  create?: InputMaybe<ServiceCreateInput>;
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename?: 'ServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Service;
};

/** Identifies documents */
export type ServiceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ServiceWhereStageInput>;
  documentInStages_none?: InputMaybe<ServiceWhereStageInput>;
  documentInStages_some?: InputMaybe<ServiceWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ServiceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type ServiceUpdateInput = {
  cl1ahdjt1kus901yw6kpycz2k?: InputMaybe<DealerUpdateManyInlineInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ServiceUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceUpdateLocalizationInput = {
  data: ServiceUpdateLocalizationDataInput;
  locale: Locale;
};

export type ServiceUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ServiceCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ServiceUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ServiceUpsertLocalizationInput>>;
};

export type ServiceUpdateManyInlineInput = {
  /** Connect multiple existing Service documents */
  connect?: InputMaybe<Array<ServiceConnectInput>>;
  /** Create and connect multiple Service documents */
  create?: InputMaybe<Array<ServiceCreateInput>>;
  /** Delete multiple Service documents */
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  /** Disconnect multiple Service documents */
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Service documents */
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  /** Update multiple Service documents */
  update?: InputMaybe<Array<ServiceUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Service documents */
  upsert?: InputMaybe<Array<ServiceUpsertWithNestedWhereUniqueInput>>;
};

export type ServiceUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ServiceUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceUpdateManyLocalizationInput = {
  data: ServiceUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ServiceUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ServiceUpdateManyLocalizationInput>>;
};

export type ServiceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ServiceUpdateManyInput;
  /** Document search */
  where: ServiceWhereInput;
};

export type ServiceUpdateOneInlineInput = {
  /** Connect existing Service document */
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  /** Create and connect one Service document */
  create?: InputMaybe<ServiceCreateInput>;
  /** Delete currently connected Service document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Service document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Service document */
  update?: InputMaybe<ServiceUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Service document */
  upsert?: InputMaybe<ServiceUpsertWithNestedWhereUniqueInput>;
};

export type ServiceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ServiceUpdateInput;
  /** Unique document search */
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertInput = {
  /** Create document if it didn't exist */
  create: ServiceCreateInput;
  /** Update document if it exists */
  update: ServiceUpdateInput;
};

export type ServiceUpsertLocalizationInput = {
  create: ServiceCreateLocalizationDataInput;
  locale: Locale;
  update: ServiceUpdateLocalizationDataInput;
};

export type ServiceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ServiceUpsertInput;
  /** Unique document search */
  where: ServiceWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ServiceWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type ServiceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ServiceWhereStageInput>;
  documentInStages_none?: InputMaybe<ServiceWhereStageInput>;
  documentInStages_some?: InputMaybe<ServiceWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ServiceWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ServiceWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ServiceWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ServiceWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ServiceWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Service record uniquely */
export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type SpecialBox = Entity & Node & {
  __typename?: 'SpecialBox';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<SpecialBox>;
  /** List of SpecialBox versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  type: SpecialBoxType;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type SpecialBoxCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type SpecialBoxDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type SpecialBoxHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type SpecialBoxPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type SpecialBoxScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type SpecialBoxUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SpecialBoxConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SpecialBoxWhereUniqueInput;
};

/** A connection to a list of items. */
export type SpecialBoxConnection = {
  __typename?: 'SpecialBoxConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SpecialBoxEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SpecialBoxCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  type: SpecialBoxType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SpecialBoxCreateManyInlineInput = {
  /** Connect multiple existing SpecialBox documents */
  connect?: InputMaybe<Array<SpecialBoxWhereUniqueInput>>;
  /** Create and connect multiple existing SpecialBox documents */
  create?: InputMaybe<Array<SpecialBoxCreateInput>>;
};

export type SpecialBoxCreateOneInlineInput = {
  /** Connect one existing SpecialBox document */
  connect?: InputMaybe<SpecialBoxWhereUniqueInput>;
  /** Create and connect one SpecialBox document */
  create?: InputMaybe<SpecialBoxCreateInput>;
};

/** An edge in a connection. */
export type SpecialBoxEdge = {
  __typename?: 'SpecialBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SpecialBox;
};

/** Identifies documents */
export type SpecialBoxManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SpecialBoxWhereStageInput>;
  documentInStages_none?: InputMaybe<SpecialBoxWhereStageInput>;
  documentInStages_some?: InputMaybe<SpecialBoxWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<SpecialBoxType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<SpecialBoxType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<SpecialBoxType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<SpecialBoxType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SpecialBoxOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export enum SpecialBoxType {
  Divider = 'Divider',
  MatchingVehicles = 'MatchingVehicles',
  ReasonsForDealers = 'ReasonsForDealers',
  ReasonsForInside = 'ReasonsForInside',
  ReasonsForMyCarmarket = 'ReasonsForMyCarmarket',
  StatsVehiclesDealers = 'StatsVehiclesDealers'
}

export type SpecialBoxUpdateInput = {
  type?: InputMaybe<SpecialBoxType>;
};

export type SpecialBoxUpdateManyInlineInput = {
  /** Connect multiple existing SpecialBox documents */
  connect?: InputMaybe<Array<SpecialBoxConnectInput>>;
  /** Create and connect multiple SpecialBox documents */
  create?: InputMaybe<Array<SpecialBoxCreateInput>>;
  /** Delete multiple SpecialBox documents */
  delete?: InputMaybe<Array<SpecialBoxWhereUniqueInput>>;
  /** Disconnect multiple SpecialBox documents */
  disconnect?: InputMaybe<Array<SpecialBoxWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SpecialBox documents */
  set?: InputMaybe<Array<SpecialBoxWhereUniqueInput>>;
  /** Update multiple SpecialBox documents */
  update?: InputMaybe<Array<SpecialBoxUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SpecialBox documents */
  upsert?: InputMaybe<Array<SpecialBoxUpsertWithNestedWhereUniqueInput>>;
};

export type SpecialBoxUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type SpecialBoxUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SpecialBoxUpdateManyInput;
  /** Document search */
  where: SpecialBoxWhereInput;
};

export type SpecialBoxUpdateOneInlineInput = {
  /** Connect existing SpecialBox document */
  connect?: InputMaybe<SpecialBoxWhereUniqueInput>;
  /** Create and connect one SpecialBox document */
  create?: InputMaybe<SpecialBoxCreateInput>;
  /** Delete currently connected SpecialBox document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected SpecialBox document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single SpecialBox document */
  update?: InputMaybe<SpecialBoxUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SpecialBox document */
  upsert?: InputMaybe<SpecialBoxUpsertWithNestedWhereUniqueInput>;
};

export type SpecialBoxUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SpecialBoxUpdateInput;
  /** Unique document search */
  where: SpecialBoxWhereUniqueInput;
};

export type SpecialBoxUpsertInput = {
  /** Create document if it didn't exist */
  create: SpecialBoxCreateInput;
  /** Update document if it exists */
  update: SpecialBoxUpdateInput;
};

export type SpecialBoxUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SpecialBoxUpsertInput;
  /** Unique document search */
  where: SpecialBoxWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SpecialBoxWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type SpecialBoxWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpecialBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SpecialBoxWhereStageInput>;
  documentInStages_none?: InputMaybe<SpecialBoxWhereStageInput>;
  documentInStages_some?: InputMaybe<SpecialBoxWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<SpecialBoxType>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<SpecialBoxType>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<SpecialBoxType>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<SpecialBoxType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SpecialBoxWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpecialBoxWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpecialBoxWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpecialBoxWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SpecialBoxWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References SpecialBox record uniquely */
export type SpecialBoxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<SpecialBoxType>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED'
}

export enum StructuredDataItemCondition {
  NewCondition = 'NewCondition',
  UsedCondition = 'UsedCondition'
}

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION'
}

export type TargetGeo = Entity & {
  __typename?: 'TargetGeo';
  center: Location;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** in km */
  radius: Scalars['Int']['output'];
  /** System stage field */
  stage: Stage;
};

export type TargetGeoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TargetGeoWhereUniqueInput;
};

/** A connection to a list of items. */
export type TargetGeoConnection = {
  __typename?: 'TargetGeoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TargetGeoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TargetGeoCreateInput = {
  center: LocationInput;
  radius: Scalars['Int']['input'];
};

export type TargetGeoCreateManyInlineInput = {
  /** Create and connect multiple existing TargetGeo documents */
  create?: InputMaybe<Array<TargetGeoCreateInput>>;
};

export type TargetGeoCreateOneInlineInput = {
  /** Create and connect one TargetGeo document */
  create?: InputMaybe<TargetGeoCreateInput>;
};

export type TargetGeoCreateWithPositionInput = {
  /** Document to create */
  data: TargetGeoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TargetGeoEdge = {
  __typename?: 'TargetGeoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TargetGeo;
};

/** Identifies documents */
export type TargetGeoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  radius_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  radius_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  radius_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  radius_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  radius_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  radius_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  radius_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum TargetGeoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RadiusAsc = 'radius_ASC',
  RadiusDesc = 'radius_DESC'
}

export type TargetGeoParent = AdTargetHero | AdTargetTile | AdTargetTileHomeRow;

export type TargetGeoParentConnectInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroConnectInput>;
  AdTargetTile?: InputMaybe<AdTargetTileConnectInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowConnectInput>;
};

export type TargetGeoParentCreateInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroCreateInput>;
  AdTargetTile?: InputMaybe<AdTargetTileCreateInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowCreateInput>;
};

export type TargetGeoParentCreateManyInlineInput = {
  /** Create and connect multiple existing TargetGeoParent documents */
  create?: InputMaybe<Array<TargetGeoParentCreateInput>>;
};

export type TargetGeoParentCreateOneInlineInput = {
  /** Create and connect one TargetGeoParent document */
  create?: InputMaybe<TargetGeoParentCreateInput>;
};

export type TargetGeoParentCreateWithPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroCreateWithPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileCreateWithPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowCreateWithPositionInput>;
};

export type TargetGeoParentUpdateInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateInput>;
};

export type TargetGeoParentUpdateManyInlineInput = {
  /** Create and connect multiple TargetGeoParent component instances */
  create?: InputMaybe<Array<TargetGeoParentCreateWithPositionInput>>;
  /** Delete multiple TargetGeoParent documents */
  delete?: InputMaybe<Array<TargetGeoParentWhereUniqueInput>>;
  /** Update multiple TargetGeoParent component instances */
  update?: InputMaybe<Array<TargetGeoParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple TargetGeoParent component instances */
  upsert?: InputMaybe<Array<TargetGeoParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TargetGeoParentUpdateManyWithNestedWhereInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateManyWithNestedWhereInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateManyWithNestedWhereInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateManyWithNestedWhereInput>;
};

export type TargetGeoParentUpdateOneInlineInput = {
  /** Create and connect one TargetGeoParent document */
  create?: InputMaybe<TargetGeoParentCreateInput>;
  /** Delete currently connected TargetGeoParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TargetGeoParent document */
  update?: InputMaybe<TargetGeoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TargetGeoParent document */
  upsert?: InputMaybe<TargetGeoParentUpsertWithNestedWhereUniqueInput>;
};

export type TargetGeoParentUpdateWithNestedWhereUniqueAndPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateWithNestedWhereUniqueAndPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateWithNestedWhereUniqueAndPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TargetGeoParentUpdateWithNestedWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpdateWithNestedWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpdateWithNestedWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpdateWithNestedWhereUniqueInput>;
};

export type TargetGeoParentUpsertWithNestedWhereUniqueAndPositionInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpsertWithNestedWhereUniqueAndPositionInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpsertWithNestedWhereUniqueAndPositionInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TargetGeoParentUpsertWithNestedWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroUpsertWithNestedWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileUpsertWithNestedWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowUpsertWithNestedWhereUniqueInput>;
};

export type TargetGeoParentWhereInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroWhereInput>;
  AdTargetTile?: InputMaybe<AdTargetTileWhereInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowWhereInput>;
};

export type TargetGeoParentWhereUniqueInput = {
  AdTargetHero?: InputMaybe<AdTargetHeroWhereUniqueInput>;
  AdTargetTile?: InputMaybe<AdTargetTileWhereUniqueInput>;
  AdTargetTileHomeRow?: InputMaybe<AdTargetTileHomeRowWhereUniqueInput>;
};

export type TargetGeoUpdateInput = {
  center?: InputMaybe<LocationInput>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type TargetGeoUpdateManyInlineInput = {
  /** Create and connect multiple TargetGeo component instances */
  create?: InputMaybe<Array<TargetGeoCreateWithPositionInput>>;
  /** Delete multiple TargetGeo documents */
  delete?: InputMaybe<Array<TargetGeoWhereUniqueInput>>;
  /** Update multiple TargetGeo component instances */
  update?: InputMaybe<Array<TargetGeoUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple TargetGeo component instances */
  upsert?: InputMaybe<Array<TargetGeoUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TargetGeoUpdateManyInput = {
  center?: InputMaybe<LocationInput>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type TargetGeoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TargetGeoUpdateManyInput;
  /** Document search */
  where: TargetGeoWhereInput;
};

export type TargetGeoUpdateOneInlineInput = {
  /** Create and connect one TargetGeo document */
  create?: InputMaybe<TargetGeoCreateInput>;
  /** Delete currently connected TargetGeo document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TargetGeo document */
  update?: InputMaybe<TargetGeoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TargetGeo document */
  upsert?: InputMaybe<TargetGeoUpsertWithNestedWhereUniqueInput>;
};

export type TargetGeoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TargetGeoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TargetGeoWhereUniqueInput;
};

export type TargetGeoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TargetGeoUpdateInput;
  /** Unique document search */
  where: TargetGeoWhereUniqueInput;
};

export type TargetGeoUpsertInput = {
  /** Create document if it didn't exist */
  create: TargetGeoCreateInput;
  /** Update document if it exists */
  update: TargetGeoUpdateInput;
};

export type TargetGeoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TargetGeoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TargetGeoWhereUniqueInput;
};

export type TargetGeoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TargetGeoUpsertInput;
  /** Unique document search */
  where: TargetGeoWhereUniqueInput;
};

/** Identifies documents */
export type TargetGeoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TargetGeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  radius_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  radius_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  radius_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  radius_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  radius_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  radius_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  radius_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** References TargetGeo record uniquely */
export type TargetGeoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TelLink = Entity & {
  __typename?: 'TelLink';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  phoneNumber: Scalars['String']['output'];
  /** System stage field */
  stage: Stage;
};

export type TelLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TelLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type TelLinkConnection = {
  __typename?: 'TelLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TelLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TelLinkCreateInput = {
  phoneNumber: Scalars['String']['input'];
};

export type TelLinkCreateManyInlineInput = {
  /** Create and connect multiple existing TelLink documents */
  create?: InputMaybe<Array<TelLinkCreateInput>>;
};

export type TelLinkCreateOneInlineInput = {
  /** Create and connect one TelLink document */
  create?: InputMaybe<TelLinkCreateInput>;
};

export type TelLinkCreateWithPositionInput = {
  /** Document to create */
  data: TelLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TelLinkEdge = {
  __typename?: 'TelLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TelLink;
};

/** Identifies documents */
export type TelLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum TelLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC'
}

export type TelLinkParent = Link;

export type TelLinkParentConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type TelLinkParentCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type TelLinkParentCreateManyInlineInput = {
  /** Create and connect multiple existing TelLinkParent documents */
  create?: InputMaybe<Array<TelLinkParentCreateInput>>;
};

export type TelLinkParentCreateOneInlineInput = {
  /** Create and connect one TelLinkParent document */
  create?: InputMaybe<TelLinkParentCreateInput>;
};

export type TelLinkParentCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type TelLinkParentUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type TelLinkParentUpdateManyInlineInput = {
  /** Create and connect multiple TelLinkParent component instances */
  create?: InputMaybe<Array<TelLinkParentCreateWithPositionInput>>;
  /** Delete multiple TelLinkParent documents */
  delete?: InputMaybe<Array<TelLinkParentWhereUniqueInput>>;
  /** Update multiple TelLinkParent component instances */
  update?: InputMaybe<Array<TelLinkParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple TelLinkParent component instances */
  upsert?: InputMaybe<Array<TelLinkParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TelLinkParentUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type TelLinkParentUpdateOneInlineInput = {
  /** Create and connect one TelLinkParent document */
  create?: InputMaybe<TelLinkParentCreateInput>;
  /** Delete currently connected TelLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TelLinkParent document */
  update?: InputMaybe<TelLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TelLinkParent document */
  upsert?: InputMaybe<TelLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type TelLinkParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TelLinkParentUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type TelLinkParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TelLinkParentUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type TelLinkParentWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type TelLinkParentWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export type TelLinkUpdateInput = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TelLinkUpdateManyInlineInput = {
  /** Create and connect multiple TelLink component instances */
  create?: InputMaybe<Array<TelLinkCreateWithPositionInput>>;
  /** Delete multiple TelLink documents */
  delete?: InputMaybe<Array<TelLinkWhereUniqueInput>>;
  /** Update multiple TelLink component instances */
  update?: InputMaybe<Array<TelLinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple TelLink component instances */
  upsert?: InputMaybe<Array<TelLinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TelLinkUpdateManyInput = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TelLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TelLinkUpdateManyInput;
  /** Document search */
  where: TelLinkWhereInput;
};

export type TelLinkUpdateOneInlineInput = {
  /** Create and connect one TelLink document */
  create?: InputMaybe<TelLinkCreateInput>;
  /** Delete currently connected TelLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TelLink document */
  update?: InputMaybe<TelLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TelLink document */
  upsert?: InputMaybe<TelLinkUpsertWithNestedWhereUniqueInput>;
};

export type TelLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TelLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TelLinkWhereUniqueInput;
};

export type TelLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TelLinkUpdateInput;
  /** Unique document search */
  where: TelLinkWhereUniqueInput;
};

export type TelLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: TelLinkCreateInput;
  /** Update document if it exists */
  update: TelLinkUpdateInput;
};

export type TelLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TelLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TelLinkWhereUniqueInput;
};

export type TelLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TelLinkUpsertInput;
  /** Unique document search */
  where: TelLinkWhereUniqueInput;
};

/** Identifies documents */
export type TelLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TelLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References TelLink record uniquely */
export type TelLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum TermsAndConditionsAttribute {
  NewVehicleTcUrl = 'newVehicleTcUrl',
  UsedVehicleTcUrl = 'usedVehicleTcUrl'
}

export type TitleAndLead = Entity & {
  __typename?: 'TitleAndLead';
  headerLine1: Scalars['String']['output'];
  headerLine2?: Maybe<Scalars['String']['output']>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageBadge?: Maybe<Image>;
  lead?: Maybe<Scalars['String']['output']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TitleAndLead>;
  /** System stage field */
  stage: Stage;
};


export type TitleAndLeadImageBadgeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type TitleAndLeadLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type TitleAndLeadConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TitleAndLeadWhereUniqueInput;
};

/** A connection to a list of items. */
export type TitleAndLeadConnection = {
  __typename?: 'TitleAndLeadConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TitleAndLeadEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TitleAndLeadCreateInput = {
  /** headerLine1 input for default locale (de) */
  headerLine1: Scalars['String']['input'];
  /** headerLine2 input for default locale (de) */
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  imageBadge?: InputMaybe<ImageCreateOneInlineInput>;
  /** lead input for default locale (de) */
  lead?: InputMaybe<Scalars['String']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TitleAndLeadCreateLocalizationsInput>;
};

export type TitleAndLeadCreateLocalizationDataInput = {
  headerLine1: Scalars['String']['input'];
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
};

export type TitleAndLeadCreateLocalizationInput = {
  /** Localization input */
  data: TitleAndLeadCreateLocalizationDataInput;
  locale: Locale;
};

export type TitleAndLeadCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TitleAndLeadCreateLocalizationInput>>;
};

export type TitleAndLeadCreateManyInlineInput = {
  /** Create and connect multiple existing TitleAndLead documents */
  create?: InputMaybe<Array<TitleAndLeadCreateInput>>;
};

export type TitleAndLeadCreateOneInlineInput = {
  /** Create and connect one TitleAndLead document */
  create?: InputMaybe<TitleAndLeadCreateInput>;
};

export type TitleAndLeadCreateWithPositionInput = {
  /** Document to create */
  data: TitleAndLeadCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TitleAndLeadEdge = {
  __typename?: 'TitleAndLeadEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TitleAndLead;
};

/** Identifies documents */
export type TitleAndLeadManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageBadge?: InputMaybe<ImageWhereInput>;
};

export enum TitleAndLeadOrderByInput {
  HeaderLine1Asc = 'headerLine1_ASC',
  HeaderLine1Desc = 'headerLine1_DESC',
  HeaderLine2Asc = 'headerLine2_ASC',
  HeaderLine2Desc = 'headerLine2_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LeadAsc = 'lead_ASC',
  LeadDesc = 'lead_DESC'
}

export type TitleAndLeadParent = AccordionSmlList | GeneratedPageExtension | ImageTextComboList | Page;

export type TitleAndLeadParentConnectInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListConnectInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionConnectInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
};

export type TitleAndLeadParentCreateInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListCreateInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionCreateInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
};

export type TitleAndLeadParentCreateManyInlineInput = {
  /** Connect multiple existing TitleAndLeadParent documents */
  connect?: InputMaybe<Array<TitleAndLeadParentWhereUniqueInput>>;
  /** Create and connect multiple existing TitleAndLeadParent documents */
  create?: InputMaybe<Array<TitleAndLeadParentCreateInput>>;
};

export type TitleAndLeadParentCreateOneInlineInput = {
  /** Connect one existing TitleAndLeadParent document */
  connect?: InputMaybe<TitleAndLeadParentWhereUniqueInput>;
  /** Create and connect one TitleAndLeadParent document */
  create?: InputMaybe<TitleAndLeadParentCreateInput>;
};

export type TitleAndLeadParentUpdateInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionUpdateInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
};

export type TitleAndLeadParentUpdateManyInlineInput = {
  /** Connect multiple existing TitleAndLeadParent documents */
  connect?: InputMaybe<Array<TitleAndLeadParentConnectInput>>;
  /** Create and connect multiple TitleAndLeadParent documents */
  create?: InputMaybe<Array<TitleAndLeadParentCreateInput>>;
  /** Delete multiple TitleAndLeadParent documents */
  delete?: InputMaybe<Array<TitleAndLeadParentWhereUniqueInput>>;
  /** Disconnect multiple TitleAndLeadParent documents */
  disconnect?: InputMaybe<Array<TitleAndLeadParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TitleAndLeadParent documents */
  set?: InputMaybe<Array<TitleAndLeadParentWhereUniqueInput>>;
  /** Update multiple TitleAndLeadParent documents */
  update?: InputMaybe<Array<TitleAndLeadParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TitleAndLeadParent documents */
  upsert?: InputMaybe<Array<TitleAndLeadParentUpsertWithNestedWhereUniqueInput>>;
};

export type TitleAndLeadParentUpdateManyWithNestedWhereInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateManyWithNestedWhereInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionUpdateManyWithNestedWhereInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateManyWithNestedWhereInput>;
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type TitleAndLeadParentUpdateOneInlineInput = {
  /** Connect existing TitleAndLeadParent document */
  connect?: InputMaybe<TitleAndLeadParentWhereUniqueInput>;
  /** Create and connect one TitleAndLeadParent document */
  create?: InputMaybe<TitleAndLeadParentCreateInput>;
  /** Delete currently connected TitleAndLeadParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected TitleAndLeadParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TitleAndLeadParent document */
  update?: InputMaybe<TitleAndLeadParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TitleAndLeadParent document */
  upsert?: InputMaybe<TitleAndLeadParentUpsertWithNestedWhereUniqueInput>;
};

export type TitleAndLeadParentUpdateWithNestedWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpdateWithNestedWhereUniqueInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionUpdateWithNestedWhereUniqueInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListUpdateWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type TitleAndLeadParentUpsertWithNestedWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListUpsertWithNestedWhereUniqueInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionUpsertWithNestedWhereUniqueInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListUpsertWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type TitleAndLeadParentWhereInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListWhereInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionWhereInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
};

export type TitleAndLeadParentWhereUniqueInput = {
  AccordionSmlList?: InputMaybe<AccordionSmlListWhereUniqueInput>;
  GeneratedPageExtension?: InputMaybe<GeneratedPageExtensionWhereUniqueInput>;
  ImageTextComboList?: InputMaybe<ImageTextComboListWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type TitleAndLeadUpdateInput = {
  /** headerLine1 input for default locale (de) */
  headerLine1?: InputMaybe<Scalars['String']['input']>;
  /** headerLine2 input for default locale (de) */
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  imageBadge?: InputMaybe<ImageUpdateOneInlineInput>;
  /** lead input for default locale (de) */
  lead?: InputMaybe<Scalars['String']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TitleAndLeadUpdateLocalizationsInput>;
};

export type TitleAndLeadUpdateLocalizationDataInput = {
  headerLine1?: InputMaybe<Scalars['String']['input']>;
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
};

export type TitleAndLeadUpdateLocalizationInput = {
  data: TitleAndLeadUpdateLocalizationDataInput;
  locale: Locale;
};

export type TitleAndLeadUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TitleAndLeadCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TitleAndLeadUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TitleAndLeadUpsertLocalizationInput>>;
};

export type TitleAndLeadUpdateManyInlineInput = {
  /** Create and connect multiple TitleAndLead component instances */
  create?: InputMaybe<Array<TitleAndLeadCreateWithPositionInput>>;
  /** Delete multiple TitleAndLead documents */
  delete?: InputMaybe<Array<TitleAndLeadWhereUniqueInput>>;
  /** Update multiple TitleAndLead component instances */
  update?: InputMaybe<Array<TitleAndLeadUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple TitleAndLead component instances */
  upsert?: InputMaybe<Array<TitleAndLeadUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TitleAndLeadUpdateManyInput = {
  /** headerLine1 input for default locale (de) */
  headerLine1?: InputMaybe<Scalars['String']['input']>;
  /** headerLine2 input for default locale (de) */
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  /** lead input for default locale (de) */
  lead?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TitleAndLeadUpdateManyLocalizationsInput>;
};

export type TitleAndLeadUpdateManyLocalizationDataInput = {
  headerLine1?: InputMaybe<Scalars['String']['input']>;
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  lead?: InputMaybe<Scalars['String']['input']>;
};

export type TitleAndLeadUpdateManyLocalizationInput = {
  data: TitleAndLeadUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TitleAndLeadUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TitleAndLeadUpdateManyLocalizationInput>>;
};

export type TitleAndLeadUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TitleAndLeadUpdateManyInput;
  /** Document search */
  where: TitleAndLeadWhereInput;
};

export type TitleAndLeadUpdateOneInlineInput = {
  /** Create and connect one TitleAndLead document */
  create?: InputMaybe<TitleAndLeadCreateInput>;
  /** Delete currently connected TitleAndLead document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TitleAndLead document */
  update?: InputMaybe<TitleAndLeadUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TitleAndLead document */
  upsert?: InputMaybe<TitleAndLeadUpsertWithNestedWhereUniqueInput>;
};

export type TitleAndLeadUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TitleAndLeadUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TitleAndLeadWhereUniqueInput;
};

export type TitleAndLeadUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TitleAndLeadUpdateInput;
  /** Unique document search */
  where: TitleAndLeadWhereUniqueInput;
};

export type TitleAndLeadUpsertInput = {
  /** Create document if it didn't exist */
  create: TitleAndLeadCreateInput;
  /** Update document if it exists */
  update: TitleAndLeadUpdateInput;
};

export type TitleAndLeadUpsertLocalizationInput = {
  create: TitleAndLeadCreateLocalizationDataInput;
  locale: Locale;
  update: TitleAndLeadUpdateLocalizationDataInput;
};

export type TitleAndLeadUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TitleAndLeadUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TitleAndLeadWhereUniqueInput;
};

export type TitleAndLeadUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TitleAndLeadUpsertInput;
  /** Unique document search */
  where: TitleAndLeadWhereUniqueInput;
};

/** Identifies documents */
export type TitleAndLeadWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TitleAndLeadWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  headerLine1?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  headerLine1_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  headerLine1_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  headerLine1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  headerLine1_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  headerLine1_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  headerLine1_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  headerLine1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  headerLine1_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  headerLine1_starts_with?: InputMaybe<Scalars['String']['input']>;
  headerLine2?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  headerLine2_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  headerLine2_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  headerLine2_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  headerLine2_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  headerLine2_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  headerLine2_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  headerLine2_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  headerLine2_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  headerLine2_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  imageBadge?: InputMaybe<ImageWhereInput>;
  lead?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  lead_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  lead_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  lead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  lead_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  lead_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  lead_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  lead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  lead_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  lead_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References TitleAndLead record uniquely */
export type TitleAndLeadWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Semi-Automatic, Manual Transmission, etc. */
export type Transmission = Entity & Node & {
  __typename?: 'Transmission';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Transmission>;
  group?: Maybe<TransmissionGroup>;
  /** List of Transmission versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Transmission>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Semi-Automatic, Manual Transmission, etc. */
export type TransmissionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TransmissionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TransmissionWhereUniqueInput;
};

/** A connection to a list of items. */
export type TransmissionConnection = {
  __typename?: 'TransmissionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TransmissionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TransmissionCreateInput = {
  cl16idnd1hvpf01z1e5hpag6e?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<TransmissionGroupCreateOneInlineInput>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TransmissionCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransmissionCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransmissionCreateLocalizationInput = {
  /** Localization input */
  data: TransmissionCreateLocalizationDataInput;
  locale: Locale;
};

export type TransmissionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TransmissionCreateLocalizationInput>>;
};

export type TransmissionCreateManyInlineInput = {
  /** Connect multiple existing Transmission documents */
  connect?: InputMaybe<Array<TransmissionWhereUniqueInput>>;
  /** Create and connect multiple existing Transmission documents */
  create?: InputMaybe<Array<TransmissionCreateInput>>;
};

export type TransmissionCreateOneInlineInput = {
  /** Connect one existing Transmission document */
  connect?: InputMaybe<TransmissionWhereUniqueInput>;
  /** Create and connect one Transmission document */
  create?: InputMaybe<TransmissionCreateInput>;
};

/** An edge in a connection. */
export type TransmissionEdge = {
  __typename?: 'TransmissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Transmission;
};

export type TransmissionGroup = Entity & Node & {
  __typename?: 'TransmissionGroup';
  basicFilterValue: Scalars['Boolean']['output'];
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<TransmissionGroup>;
  groupId: Scalars['Int']['output'];
  /** List of TransmissionGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TransmissionGroup>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  transmissions: Array<Transmission>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


export type TransmissionGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type TransmissionGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type TransmissionGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type TransmissionGroupHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type TransmissionGroupKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


export type TransmissionGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type TransmissionGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type TransmissionGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type TransmissionGroupScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type TransmissionGroupTransmissionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TransmissionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TransmissionWhereInput>;
};


export type TransmissionGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type TransmissionGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TransmissionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TransmissionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TransmissionGroupConnection = {
  __typename?: 'TransmissionGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TransmissionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TransmissionGroupCreateInput = {
  basicFilterValue: Scalars['Boolean']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  groupId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TransmissionGroupCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  transmissions?: InputMaybe<TransmissionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransmissionGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TransmissionGroupCreateLocalizationInput = {
  /** Localization input */
  data: TransmissionGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type TransmissionGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TransmissionGroupCreateLocalizationInput>>;
};

export type TransmissionGroupCreateManyInlineInput = {
  /** Connect multiple existing TransmissionGroup documents */
  connect?: InputMaybe<Array<TransmissionGroupWhereUniqueInput>>;
  /** Create and connect multiple existing TransmissionGroup documents */
  create?: InputMaybe<Array<TransmissionGroupCreateInput>>;
};

export type TransmissionGroupCreateOneInlineInput = {
  /** Connect one existing TransmissionGroup document */
  connect?: InputMaybe<TransmissionGroupWhereUniqueInput>;
  /** Create and connect one TransmissionGroup document */
  create?: InputMaybe<TransmissionGroupCreateInput>;
};

/** An edge in a connection. */
export type TransmissionGroupEdge = {
  __typename?: 'TransmissionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TransmissionGroup;
};

/** Identifies documents */
export type TransmissionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TransmissionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TransmissionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TransmissionGroupWhereStageInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  transmissions_every?: InputMaybe<TransmissionWhereInput>;
  transmissions_none?: InputMaybe<TransmissionWhereInput>;
  transmissions_some?: InputMaybe<TransmissionWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TransmissionGroupOrderByInput {
  BasicFilterValueAsc = 'basicFilterValue_ASC',
  BasicFilterValueDesc = 'basicFilterValue_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  GroupIdAsc = 'groupId_ASC',
  GroupIdDesc = 'groupId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TransmissionGroupUpdateInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<TransmissionGroupUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  transmissions?: InputMaybe<TransmissionUpdateManyInlineInput>;
};

export type TransmissionGroupUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionGroupUpdateLocalizationInput = {
  data: TransmissionGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type TransmissionGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TransmissionGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TransmissionGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TransmissionGroupUpsertLocalizationInput>>;
};

export type TransmissionGroupUpdateManyInlineInput = {
  /** Connect multiple existing TransmissionGroup documents */
  connect?: InputMaybe<Array<TransmissionGroupConnectInput>>;
  /** Create and connect multiple TransmissionGroup documents */
  create?: InputMaybe<Array<TransmissionGroupCreateInput>>;
  /** Delete multiple TransmissionGroup documents */
  delete?: InputMaybe<Array<TransmissionGroupWhereUniqueInput>>;
  /** Disconnect multiple TransmissionGroup documents */
  disconnect?: InputMaybe<Array<TransmissionGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TransmissionGroup documents */
  set?: InputMaybe<Array<TransmissionGroupWhereUniqueInput>>;
  /** Update multiple TransmissionGroup documents */
  update?: InputMaybe<Array<TransmissionGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TransmissionGroup documents */
  upsert?: InputMaybe<Array<TransmissionGroupUpsertWithNestedWhereUniqueInput>>;
};

export type TransmissionGroupUpdateManyInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TransmissionGroupUpdateManyLocalizationsInput>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionGroupUpdateManyLocalizationDataInput = {
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionGroupUpdateManyLocalizationInput = {
  data: TransmissionGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TransmissionGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TransmissionGroupUpdateManyLocalizationInput>>;
};

export type TransmissionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TransmissionGroupUpdateManyInput;
  /** Document search */
  where: TransmissionGroupWhereInput;
};

export type TransmissionGroupUpdateOneInlineInput = {
  /** Connect existing TransmissionGroup document */
  connect?: InputMaybe<TransmissionGroupWhereUniqueInput>;
  /** Create and connect one TransmissionGroup document */
  create?: InputMaybe<TransmissionGroupCreateInput>;
  /** Delete currently connected TransmissionGroup document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected TransmissionGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single TransmissionGroup document */
  update?: InputMaybe<TransmissionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TransmissionGroup document */
  upsert?: InputMaybe<TransmissionGroupUpsertWithNestedWhereUniqueInput>;
};

export type TransmissionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TransmissionGroupUpdateInput;
  /** Unique document search */
  where: TransmissionGroupWhereUniqueInput;
};

export type TransmissionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TransmissionGroupCreateInput;
  /** Update document if it exists */
  update: TransmissionGroupUpdateInput;
};

export type TransmissionGroupUpsertLocalizationInput = {
  create: TransmissionGroupCreateLocalizationDataInput;
  locale: Locale;
  update: TransmissionGroupUpdateLocalizationDataInput;
};

export type TransmissionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TransmissionGroupUpsertInput;
  /** Unique document search */
  where: TransmissionGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TransmissionGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type TransmissionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TransmissionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<TransmissionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<TransmissionGroupWhereStageInput>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  groupId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  groupId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  groupId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  groupId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  groupId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  groupId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  groupId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  transmissions_every?: InputMaybe<TransmissionWhereInput>;
  transmissions_none?: InputMaybe<TransmissionWhereInput>;
  transmissions_some?: InputMaybe<TransmissionWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TransmissionGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TransmissionGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TransmissionGroup record uniquely */
export type TransmissionGroupWhereUniqueInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type TransmissionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TransmissionWhereStageInput>;
  documentInStages_none?: InputMaybe<TransmissionWhereStageInput>;
  documentInStages_some?: InputMaybe<TransmissionWhereStageInput>;
  group?: InputMaybe<TransmissionGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TransmissionOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type TransmissionUpdateInput = {
  cl16idnd1hvpf01z1e5hpag6e?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<TransmissionGroupUpdateOneInlineInput>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TransmissionUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionUpdateLocalizationInput = {
  data: TransmissionUpdateLocalizationDataInput;
  locale: Locale;
};

export type TransmissionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TransmissionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TransmissionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TransmissionUpsertLocalizationInput>>;
};

export type TransmissionUpdateManyInlineInput = {
  /** Connect multiple existing Transmission documents */
  connect?: InputMaybe<Array<TransmissionConnectInput>>;
  /** Create and connect multiple Transmission documents */
  create?: InputMaybe<Array<TransmissionCreateInput>>;
  /** Delete multiple Transmission documents */
  delete?: InputMaybe<Array<TransmissionWhereUniqueInput>>;
  /** Disconnect multiple Transmission documents */
  disconnect?: InputMaybe<Array<TransmissionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Transmission documents */
  set?: InputMaybe<Array<TransmissionWhereUniqueInput>>;
  /** Update multiple Transmission documents */
  update?: InputMaybe<Array<TransmissionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Transmission documents */
  upsert?: InputMaybe<Array<TransmissionUpsertWithNestedWhereUniqueInput>>;
};

export type TransmissionUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TransmissionUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type TransmissionUpdateManyLocalizationInput = {
  data: TransmissionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TransmissionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TransmissionUpdateManyLocalizationInput>>;
};

export type TransmissionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TransmissionUpdateManyInput;
  /** Document search */
  where: TransmissionWhereInput;
};

export type TransmissionUpdateOneInlineInput = {
  /** Connect existing Transmission document */
  connect?: InputMaybe<TransmissionWhereUniqueInput>;
  /** Create and connect one Transmission document */
  create?: InputMaybe<TransmissionCreateInput>;
  /** Delete currently connected Transmission document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Transmission document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Transmission document */
  update?: InputMaybe<TransmissionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Transmission document */
  upsert?: InputMaybe<TransmissionUpsertWithNestedWhereUniqueInput>;
};

export type TransmissionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TransmissionUpdateInput;
  /** Unique document search */
  where: TransmissionWhereUniqueInput;
};

export type TransmissionUpsertInput = {
  /** Create document if it didn't exist */
  create: TransmissionCreateInput;
  /** Update document if it exists */
  update: TransmissionUpdateInput;
};

export type TransmissionUpsertLocalizationInput = {
  create: TransmissionCreateLocalizationDataInput;
  locale: Locale;
  update: TransmissionUpdateLocalizationDataInput;
};

export type TransmissionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TransmissionUpsertInput;
  /** Unique document search */
  where: TransmissionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TransmissionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type TransmissionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TransmissionWhereStageInput>;
  documentInStages_none?: InputMaybe<TransmissionWhereStageInput>;
  documentInStages_some?: InputMaybe<TransmissionWhereStageInput>;
  group?: InputMaybe<TransmissionGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TransmissionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TransmissionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TransmissionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TransmissionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TransmissionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Transmission record uniquely */
export type TransmissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** Alcantara, Cloth, Full Leather, etc. */
export type Upholstery = Entity & Node & {
  __typename?: 'Upholstery';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Upholstery>;
  /** List of Upholstery versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Upholstery>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Alcantara, Cloth, Full Leather, etc. */
export type UpholsteryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type UpholsteryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UpholsteryWhereUniqueInput;
};

/** A connection to a list of items. */
export type UpholsteryConnection = {
  __typename?: 'UpholsteryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UpholsteryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UpholsteryCreateInput = {
  cl16idcpuhvow01z18gazdw07?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<UpholsteryCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpholsteryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpholsteryCreateLocalizationInput = {
  /** Localization input */
  data: UpholsteryCreateLocalizationDataInput;
  locale: Locale;
};

export type UpholsteryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<UpholsteryCreateLocalizationInput>>;
};

export type UpholsteryCreateManyInlineInput = {
  /** Connect multiple existing Upholstery documents */
  connect?: InputMaybe<Array<UpholsteryWhereUniqueInput>>;
  /** Create and connect multiple existing Upholstery documents */
  create?: InputMaybe<Array<UpholsteryCreateInput>>;
};

export type UpholsteryCreateOneInlineInput = {
  /** Connect one existing Upholstery document */
  connect?: InputMaybe<UpholsteryWhereUniqueInput>;
  /** Create and connect one Upholstery document */
  create?: InputMaybe<UpholsteryCreateInput>;
};

/** An edge in a connection. */
export type UpholsteryEdge = {
  __typename?: 'UpholsteryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Upholstery;
};

/** Identifies documents */
export type UpholsteryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<UpholsteryWhereStageInput>;
  documentInStages_none?: InputMaybe<UpholsteryWhereStageInput>;
  documentInStages_some?: InputMaybe<UpholsteryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum UpholsteryOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UpholsteryUpdateInput = {
  cl16idcpuhvow01z18gazdw07?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<UpholsteryUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type UpholsteryUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type UpholsteryUpdateLocalizationInput = {
  data: UpholsteryUpdateLocalizationDataInput;
  locale: Locale;
};

export type UpholsteryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<UpholsteryCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<UpholsteryUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<UpholsteryUpsertLocalizationInput>>;
};

export type UpholsteryUpdateManyInlineInput = {
  /** Connect multiple existing Upholstery documents */
  connect?: InputMaybe<Array<UpholsteryConnectInput>>;
  /** Create and connect multiple Upholstery documents */
  create?: InputMaybe<Array<UpholsteryCreateInput>>;
  /** Delete multiple Upholstery documents */
  delete?: InputMaybe<Array<UpholsteryWhereUniqueInput>>;
  /** Disconnect multiple Upholstery documents */
  disconnect?: InputMaybe<Array<UpholsteryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Upholstery documents */
  set?: InputMaybe<Array<UpholsteryWhereUniqueInput>>;
  /** Update multiple Upholstery documents */
  update?: InputMaybe<Array<UpholsteryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Upholstery documents */
  upsert?: InputMaybe<Array<UpholsteryUpsertWithNestedWhereUniqueInput>>;
};

export type UpholsteryUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<UpholsteryUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type UpholsteryUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type UpholsteryUpdateManyLocalizationInput = {
  data: UpholsteryUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type UpholsteryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<UpholsteryUpdateManyLocalizationInput>>;
};

export type UpholsteryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: UpholsteryUpdateManyInput;
  /** Document search */
  where: UpholsteryWhereInput;
};

export type UpholsteryUpdateOneInlineInput = {
  /** Connect existing Upholstery document */
  connect?: InputMaybe<UpholsteryWhereUniqueInput>;
  /** Create and connect one Upholstery document */
  create?: InputMaybe<UpholsteryCreateInput>;
  /** Delete currently connected Upholstery document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Upholstery document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Upholstery document */
  update?: InputMaybe<UpholsteryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Upholstery document */
  upsert?: InputMaybe<UpholsteryUpsertWithNestedWhereUniqueInput>;
};

export type UpholsteryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: UpholsteryUpdateInput;
  /** Unique document search */
  where: UpholsteryWhereUniqueInput;
};

export type UpholsteryUpsertInput = {
  /** Create document if it didn't exist */
  create: UpholsteryCreateInput;
  /** Update document if it exists */
  update: UpholsteryUpdateInput;
};

export type UpholsteryUpsertLocalizationInput = {
  create: UpholsteryCreateLocalizationDataInput;
  locale: Locale;
  update: UpholsteryUpdateLocalizationDataInput;
};

export type UpholsteryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: UpholsteryUpsertInput;
  /** Unique document search */
  where: UpholsteryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type UpholsteryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type UpholsteryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UpholsteryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<UpholsteryWhereStageInput>;
  documentInStages_none?: InputMaybe<UpholsteryWhereStageInput>;
  documentInStages_some?: InputMaybe<UpholsteryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UpholsteryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UpholsteryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UpholsteryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UpholsteryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UpholsteryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Upholstery record uniquely */
export type UpholsteryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type UrlComponent = Entity & {
  __typename?: 'UrlComponent';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<UrlComponent>;
  /** System stage field */
  stage: Stage;
  url: Scalars['String']['output'];
};


export type UrlComponentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type UrlComponentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UrlComponentWhereUniqueInput;
};

/** A connection to a list of items. */
export type UrlComponentConnection = {
  __typename?: 'UrlComponentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UrlComponentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UrlComponentCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<UrlComponentCreateLocalizationsInput>;
  /** url input for default locale (de) */
  url: Scalars['String']['input'];
};

export type UrlComponentCreateLocalizationDataInput = {
  url: Scalars['String']['input'];
};

export type UrlComponentCreateLocalizationInput = {
  /** Localization input */
  data: UrlComponentCreateLocalizationDataInput;
  locale: Locale;
};

export type UrlComponentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<UrlComponentCreateLocalizationInput>>;
};

export type UrlComponentCreateManyInlineInput = {
  /** Create and connect multiple existing UrlComponent documents */
  create?: InputMaybe<Array<UrlComponentCreateInput>>;
};

export type UrlComponentCreateOneInlineInput = {
  /** Create and connect one UrlComponent document */
  create?: InputMaybe<UrlComponentCreateInput>;
};

export type UrlComponentCreateWithPositionInput = {
  /** Document to create */
  data: UrlComponentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type UrlComponentEdge = {
  __typename?: 'UrlComponentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UrlComponent;
};

/** Identifies documents */
export type UrlComponentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum UrlComponentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC'
}

export type UrlComponentParent = AbsoluteUrlLink | Article | Image | Video;

export type UrlComponentParentConnectInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkConnectInput>;
  Article?: InputMaybe<ArticleConnectInput>;
  Image?: InputMaybe<ImageConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type UrlComponentParentCreateInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkCreateInput>;
  Article?: InputMaybe<ArticleCreateInput>;
  Image?: InputMaybe<ImageCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type UrlComponentParentCreateManyInlineInput = {
  /** Connect multiple existing UrlComponentParent documents */
  connect?: InputMaybe<Array<UrlComponentParentWhereUniqueInput>>;
  /** Create and connect multiple existing UrlComponentParent documents */
  create?: InputMaybe<Array<UrlComponentParentCreateInput>>;
};

export type UrlComponentParentCreateOneInlineInput = {
  /** Connect one existing UrlComponentParent document */
  connect?: InputMaybe<UrlComponentParentWhereUniqueInput>;
  /** Create and connect one UrlComponentParent document */
  create?: InputMaybe<UrlComponentParentCreateInput>;
};

export type UrlComponentParentUpdateInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateInput>;
  Article?: InputMaybe<ArticleUpdateInput>;
  Image?: InputMaybe<ImageUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type UrlComponentParentUpdateManyInlineInput = {
  /** Connect multiple existing UrlComponentParent documents */
  connect?: InputMaybe<Array<UrlComponentParentConnectInput>>;
  /** Create and connect multiple UrlComponentParent documents */
  create?: InputMaybe<Array<UrlComponentParentCreateInput>>;
  /** Delete multiple UrlComponentParent documents */
  delete?: InputMaybe<Array<UrlComponentParentWhereUniqueInput>>;
  /** Disconnect multiple UrlComponentParent documents */
  disconnect?: InputMaybe<Array<UrlComponentParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing UrlComponentParent documents */
  set?: InputMaybe<Array<UrlComponentParentWhereUniqueInput>>;
  /** Update multiple UrlComponentParent documents */
  update?: InputMaybe<Array<UrlComponentParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple UrlComponentParent documents */
  upsert?: InputMaybe<Array<UrlComponentParentUpsertWithNestedWhereUniqueInput>>;
};

export type UrlComponentParentUpdateManyWithNestedWhereInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateManyWithNestedWhereInput>;
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
  Image?: InputMaybe<ImageUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type UrlComponentParentUpdateOneInlineInput = {
  /** Connect existing UrlComponentParent document */
  connect?: InputMaybe<UrlComponentParentWhereUniqueInput>;
  /** Create and connect one UrlComponentParent document */
  create?: InputMaybe<UrlComponentParentCreateInput>;
  /** Delete currently connected UrlComponentParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected UrlComponentParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single UrlComponentParent document */
  update?: InputMaybe<UrlComponentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single UrlComponentParent document */
  upsert?: InputMaybe<UrlComponentParentUpsertWithNestedWhereUniqueInput>;
};

export type UrlComponentParentUpdateWithNestedWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpdateWithNestedWhereUniqueInput>;
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  Image?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type UrlComponentParentUpsertWithNestedWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkUpsertWithNestedWhereUniqueInput>;
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
  Image?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type UrlComponentParentWhereInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkWhereInput>;
  Article?: InputMaybe<ArticleWhereInput>;
  Image?: InputMaybe<ImageWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type UrlComponentParentWhereUniqueInput = {
  AbsoluteUrlLink?: InputMaybe<AbsoluteUrlLinkWhereUniqueInput>;
  Article?: InputMaybe<ArticleWhereUniqueInput>;
  Image?: InputMaybe<ImageWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type UrlComponentUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<UrlComponentUpdateLocalizationsInput>;
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UrlComponentUpdateLocalizationDataInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UrlComponentUpdateLocalizationInput = {
  data: UrlComponentUpdateLocalizationDataInput;
  locale: Locale;
};

export type UrlComponentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<UrlComponentCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<UrlComponentUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<UrlComponentUpsertLocalizationInput>>;
};

export type UrlComponentUpdateManyInlineInput = {
  /** Create and connect multiple UrlComponent component instances */
  create?: InputMaybe<Array<UrlComponentCreateWithPositionInput>>;
  /** Delete multiple UrlComponent documents */
  delete?: InputMaybe<Array<UrlComponentWhereUniqueInput>>;
  /** Update multiple UrlComponent component instances */
  update?: InputMaybe<Array<UrlComponentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple UrlComponent component instances */
  upsert?: InputMaybe<Array<UrlComponentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type UrlComponentUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<UrlComponentUpdateManyLocalizationsInput>;
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UrlComponentUpdateManyLocalizationDataInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UrlComponentUpdateManyLocalizationInput = {
  data: UrlComponentUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type UrlComponentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<UrlComponentUpdateManyLocalizationInput>>;
};

export type UrlComponentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: UrlComponentUpdateManyInput;
  /** Document search */
  where: UrlComponentWhereInput;
};

export type UrlComponentUpdateOneInlineInput = {
  /** Create and connect one UrlComponent document */
  create?: InputMaybe<UrlComponentCreateInput>;
  /** Delete currently connected UrlComponent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single UrlComponent document */
  update?: InputMaybe<UrlComponentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single UrlComponent document */
  upsert?: InputMaybe<UrlComponentUpsertWithNestedWhereUniqueInput>;
};

export type UrlComponentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<UrlComponentUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: UrlComponentWhereUniqueInput;
};

export type UrlComponentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: UrlComponentUpdateInput;
  /** Unique document search */
  where: UrlComponentWhereUniqueInput;
};

export type UrlComponentUpsertInput = {
  /** Create document if it didn't exist */
  create: UrlComponentCreateInput;
  /** Update document if it exists */
  update: UrlComponentUpdateInput;
};

export type UrlComponentUpsertLocalizationInput = {
  create: UrlComponentCreateLocalizationDataInput;
  locale: Locale;
  update: UrlComponentUpdateLocalizationDataInput;
};

export type UrlComponentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<UrlComponentUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: UrlComponentWhereUniqueInput;
};

export type UrlComponentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: UrlComponentUpsertInput;
  /** Unique document search */
  where: UrlComponentWhereUniqueInput;
};

/** Identifies documents */
export type UrlComponentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UrlComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** References UrlComponent record uniquely */
export type UrlComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** User system model */
export type User = Entity & Node & {
  __typename?: 'User';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** Get the document in other stages */
  documentInStages: Array<User>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean']['output'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind;
  /** The username */
  name: Scalars['String']['output'];
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
};


/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK'
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

export enum UserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Vehicle = Entity & Node & {
  __typename?: 'Vehicle';
  batteryCapacity?: Maybe<Scalars['Float']['output']>;
  batteryRange?: Maybe<Scalars['Float']['output']>;
  bodyType?: Maybe<BodyType>;
  brand?: Maybe<Brand>;
  buildYear?: Maybe<Scalars['Int']['output']>;
  ccm?: Maybe<Scalars['Int']['output']>;
  co2?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  commissionNumber?: Maybe<Scalars['String']['output']>;
  consumptionCity?: Maybe<Scalars['Float']['output']>;
  consumptionComb?: Maybe<Scalars['Float']['output']>;
  consumptionCountry?: Maybe<Scalars['Float']['output']>;
  consumptionPower?: Maybe<Scalars['Float']['output']>;
  contractOptions: Array<ContractOption>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  currency?: Maybe<Currency>;
  cylinderNum?: Maybe<Scalars['Int']['output']>;
  dealer?: Maybe<Dealer>;
  description?: Maybe<Scalars['String']['output']>;
  discountList?: Maybe<VehicleDiscountList>;
  /** Get the document in other stages */
  documentInStages: Array<Vehicle>;
  doorNum?: Maybe<Scalars['Int']['output']>;
  driveType?: Maybe<DriveType>;
  electroKw?: Maybe<Scalars['Int']['output']>;
  emissionClass?: Maybe<EmissionClass>;
  emptyWeight?: Maybe<Scalars['Int']['output']>;
  enabled: Scalars['Boolean']['output'];
  equipmentPackage?: Maybe<Scalars['String']['output']>;
  exteriorColor?: Maybe<ColorNorm>;
  exteriorColorType?: Maybe<ColorType>;
  factoryCode?: Maybe<Scalars['String']['output']>;
  factoryExteriorColorCode?: Maybe<Scalars['String']['output']>;
  factoryExteriorColorName?: Maybe<Scalars['String']['output']>;
  factoryInteriorColorCode?: Maybe<Scalars['String']['output']>;
  factoryInteriorColorName?: Maybe<Scalars['String']['output']>;
  firstRegistration?: Maybe<Scalars['Date']['output']>;
  fuelType?: Maybe<FuelType>;
  gearNum?: Maybe<Scalars['Int']['output']>;
  hadAccident: Scalars['Boolean']['output'];
  /** List of Vehicle versions */
  history: Array<Version>;
  hpTotal?: Maybe<Scalars['Int']['output']>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  imageUrls: Array<Scalars['String']['output']>;
  interiorColor?: Maybe<ColorNorm>;
  km: Scalars['Int']['output'];
  kw?: Maybe<Scalars['Int']['output']>;
  lastInspection?: Maybe<Scalars['Boolean']['output']>;
  lastInspectionDate?: Maybe<Scalars['Date']['output']>;
  leasingRateFrom?: Maybe<Scalars['Float']['output']>;
  leasingRateFromCalcBasis?: Maybe<VehicleLeasingRateCalculation>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Vehicle>;
  marketplaces: Array<Marketplace>;
  model?: Maybe<Model>;
  optionFacets: Array<OptionFacet>;
  options: Array<Scalars['Json']['output']>;
  pollutionClass?: Maybe<PollutionClass>;
  priceB2cGross: Scalars['Float']['output'];
  priceDownpaymentGross?: Maybe<Scalars['Float']['output']>;
  priceInitialB2cGross: Scalars['Float']['output'];
  priceNewTotalGross?: Maybe<Scalars['Float']['output']>;
  pricePreparationGross: Scalars['Float']['output'];
  productionDate?: Maybe<Scalars['Date']['output']>;
  promotionList?: Maybe<VehiclePromotionList>;
  ps?: Maybe<Scalars['Int']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  qualityChecked?: Maybe<QualityChecked>;
  reserved: Scalars['Boolean']['output'];
  scheduledIn: Array<ScheduledOperation>;
  seatNum?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  /** System stage field */
  stage: Stage;
  teaser?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  /** Created with regex pattern from title */
  titleFacet?: Maybe<Scalars['String']['output']>;
  trailerWeightMax?: Maybe<Scalars['Int']['output']>;
  transmission?: Maybe<Transmission>;
  typenschein?: Maybe<Scalars['String']['output']>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  upholstery?: Maybe<Upholstery>;
  vehicleClass?: Maybe<VehicleClass>;
  vehicleCondition?: Maybe<VehicleCondition>;
  /** ObjectID is vehicleId as string */
  vehicleId: Scalars['Int']['output'];
  vehicleStatus?: Maybe<VehicleStatus>;
  vehicleType?: Maybe<VehicleType>;
  videoUrls: Array<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  warranty?: Maybe<Scalars['Json']['output']>;
  weightMax?: Maybe<Scalars['Int']['output']>;
};


export type VehicleBodyTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleBrandArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VehicleCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleDealerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleDiscountListArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type VehicleDriveTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleEmissionClassArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleExteriorColorArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleExteriorColorTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleFuelTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type VehicleInteriorColorArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleLeasingRateFromCalcBasisArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type VehicleModelArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleOptionFacetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<OptionFacetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionFacetWhereInput>;
};


export type VehiclePollutionClassArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehiclePromotionListArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehiclePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VehiclePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleQualityCheckedArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type VehicleTransmissionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VehicleUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleUpholsteryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleVehicleClassArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleVehicleConditionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleVehicleStatusArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VehicleVehicleTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClass = Entity & Node & {
  __typename?: 'VehicleClass';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<VehicleClass>;
  /** List of VehicleClass versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleClass>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Micro, Untere Mittelklasse, Oberklasse, etc. */
export type VehicleClassUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VehicleClassConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleClassWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleClassConnection = {
  __typename?: 'VehicleClassConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleClassEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleClassCreateInput = {
  cl16j1ff9i5o501z0blst0n0e?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleClassCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleClassCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleClassCreateLocalizationInput = {
  /** Localization input */
  data: VehicleClassCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleClassCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleClassCreateLocalizationInput>>;
};

export type VehicleClassCreateManyInlineInput = {
  /** Connect multiple existing VehicleClass documents */
  connect?: InputMaybe<Array<VehicleClassWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleClass documents */
  create?: InputMaybe<Array<VehicleClassCreateInput>>;
};

export type VehicleClassCreateOneInlineInput = {
  /** Connect one existing VehicleClass document */
  connect?: InputMaybe<VehicleClassWhereUniqueInput>;
  /** Create and connect one VehicleClass document */
  create?: InputMaybe<VehicleClassCreateInput>;
};

/** An edge in a connection. */
export type VehicleClassEdge = {
  __typename?: 'VehicleClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleClass;
};

/** Identifies documents */
export type VehicleClassManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleClassWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleClassWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum VehicleClassOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VehicleClassUpdateInput = {
  cl16j1ff9i5o501z0blst0n0e?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleClassUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleClassUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleClassUpdateLocalizationInput = {
  data: VehicleClassUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleClassUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleClassCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleClassUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleClassUpsertLocalizationInput>>;
};

export type VehicleClassUpdateManyInlineInput = {
  /** Connect multiple existing VehicleClass documents */
  connect?: InputMaybe<Array<VehicleClassConnectInput>>;
  /** Create and connect multiple VehicleClass documents */
  create?: InputMaybe<Array<VehicleClassCreateInput>>;
  /** Delete multiple VehicleClass documents */
  delete?: InputMaybe<Array<VehicleClassWhereUniqueInput>>;
  /** Disconnect multiple VehicleClass documents */
  disconnect?: InputMaybe<Array<VehicleClassWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleClass documents */
  set?: InputMaybe<Array<VehicleClassWhereUniqueInput>>;
  /** Update multiple VehicleClass documents */
  update?: InputMaybe<Array<VehicleClassUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleClass documents */
  upsert?: InputMaybe<Array<VehicleClassUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleClassUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleClassUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleClassUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleClassUpdateManyLocalizationInput = {
  data: VehicleClassUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleClassUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleClassUpdateManyLocalizationInput>>;
};

export type VehicleClassUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleClassUpdateManyInput;
  /** Document search */
  where: VehicleClassWhereInput;
};

export type VehicleClassUpdateOneInlineInput = {
  /** Connect existing VehicleClass document */
  connect?: InputMaybe<VehicleClassWhereUniqueInput>;
  /** Create and connect one VehicleClass document */
  create?: InputMaybe<VehicleClassCreateInput>;
  /** Delete currently connected VehicleClass document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleClass document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleClass document */
  update?: InputMaybe<VehicleClassUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleClass document */
  upsert?: InputMaybe<VehicleClassUpsertWithNestedWhereUniqueInput>;
};

export type VehicleClassUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleClassUpdateInput;
  /** Unique document search */
  where: VehicleClassWhereUniqueInput;
};

export type VehicleClassUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleClassCreateInput;
  /** Update document if it exists */
  update: VehicleClassUpdateInput;
};

export type VehicleClassUpsertLocalizationInput = {
  create: VehicleClassCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleClassUpdateLocalizationDataInput;
};

export type VehicleClassUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleClassUpsertInput;
  /** Unique document search */
  where: VehicleClassWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VehicleClassWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VehicleClassWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleClassWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleClassWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleClassWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleClassWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VehicleClassWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleClassWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleClassWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleClassWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VehicleClassWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References VehicleClass record uniquely */
export type VehicleClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

/** New, Used, Demo, Salon, etc. */
export type VehicleCondition = Entity & Node & {
  __typename?: 'VehicleCondition';
  basicFilterValue?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<VehicleCondition>;
  ffuEnum?: Maybe<Scalars['String']['output']>;
  /** List of VehicleCondition versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  keywordLists: Array<KeywordList>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleCondition>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  salesAdvisor: SalesAdvisorVehicleCondition;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  structuredData?: Maybe<StructuredDataItemCondition>;
  termsAndConditionsAttribute?: Maybe<TermsAndConditionsAttribute>;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionKeywordListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<KeywordListWhereInput>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** New, Used, Demo, Salon, etc. */
export type VehicleConditionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VehicleConditionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleConditionWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleConditionConnection = {
  __typename?: 'VehicleConditionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleConditionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleConditionCreateInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  cl16j1ovhhwy201z13o261lgw?: InputMaybe<VehicleCreateManyInlineInput>;
  cltsf442f01c706vyb46hgqh9?: InputMaybe<AdTargetTileCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  ffuEnum?: InputMaybe<Scalars['String']['input']>;
  insideId: Scalars['Int']['input'];
  keywordLists?: InputMaybe<KeywordListCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleConditionCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  salesAdvisor: SalesAdvisorVehicleCondition;
  structuredData?: InputMaybe<StructuredDataItemCondition>;
  termsAndConditionsAttribute?: InputMaybe<TermsAndConditionsAttribute>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleConditionCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleConditionCreateLocalizationInput = {
  /** Localization input */
  data: VehicleConditionCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleConditionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleConditionCreateLocalizationInput>>;
};

export type VehicleConditionCreateManyInlineInput = {
  /** Connect multiple existing VehicleCondition documents */
  connect?: InputMaybe<Array<VehicleConditionWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleCondition documents */
  create?: InputMaybe<Array<VehicleConditionCreateInput>>;
};

export type VehicleConditionCreateOneInlineInput = {
  /** Connect one existing VehicleCondition document */
  connect?: InputMaybe<VehicleConditionWhereUniqueInput>;
  /** Create and connect one VehicleCondition document */
  create?: InputMaybe<VehicleConditionCreateInput>;
};

/** An edge in a connection. */
export type VehicleConditionEdge = {
  __typename?: 'VehicleConditionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleCondition;
};

/** Identifies documents */
export type VehicleConditionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleConditionWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleConditionWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleConditionWhereStageInput>;
  ffuEnum?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  ffuEnum_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  ffuEnum_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  ffuEnum_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ffuEnum_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  ffuEnum_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  ffuEnum_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  ffuEnum_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  ffuEnum_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  ffuEnum_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisor?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are contained in given list. */
  salesAdvisor_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  salesAdvisor_not?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are not contained in given list. */
  salesAdvisor_not_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  structuredData?: InputMaybe<StructuredDataItemCondition>;
  /** All values that are contained in given list. */
  structuredData_in?: InputMaybe<Array<InputMaybe<StructuredDataItemCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  structuredData_not?: InputMaybe<StructuredDataItemCondition>;
  /** All values that are not contained in given list. */
  structuredData_not_in?: InputMaybe<Array<InputMaybe<StructuredDataItemCondition>>>;
  termsAndConditionsAttribute?: InputMaybe<TermsAndConditionsAttribute>;
  /** All values that are contained in given list. */
  termsAndConditionsAttribute_in?: InputMaybe<Array<InputMaybe<TermsAndConditionsAttribute>>>;
  /** Any other value that exists and is not equal to the given value. */
  termsAndConditionsAttribute_not?: InputMaybe<TermsAndConditionsAttribute>;
  /** All values that are not contained in given list. */
  termsAndConditionsAttribute_not_in?: InputMaybe<Array<InputMaybe<TermsAndConditionsAttribute>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum VehicleConditionOrderByInput {
  BasicFilterValueAsc = 'basicFilterValue_ASC',
  BasicFilterValueDesc = 'basicFilterValue_DESC',
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  FfuEnumAsc = 'ffuEnum_ASC',
  FfuEnumDesc = 'ffuEnum_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SalesAdvisorAsc = 'salesAdvisor_ASC',
  SalesAdvisorDesc = 'salesAdvisor_DESC',
  StructuredDataAsc = 'structuredData_ASC',
  StructuredDataDesc = 'structuredData_DESC',
  TermsAndConditionsAttributeAsc = 'termsAndConditionsAttribute_ASC',
  TermsAndConditionsAttributeDesc = 'termsAndConditionsAttribute_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VehicleConditionUpdateInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  cl16j1ovhhwy201z13o261lgw?: InputMaybe<VehicleUpdateManyInlineInput>;
  cltsf442f01c706vyb46hgqh9?: InputMaybe<AdTargetTileUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  ffuEnum?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  keywordLists?: InputMaybe<KeywordListUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleConditionUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  salesAdvisor?: InputMaybe<SalesAdvisorVehicleCondition>;
  structuredData?: InputMaybe<StructuredDataItemCondition>;
  termsAndConditionsAttribute?: InputMaybe<TermsAndConditionsAttribute>;
};

export type VehicleConditionUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleConditionUpdateLocalizationInput = {
  data: VehicleConditionUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleConditionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleConditionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleConditionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleConditionUpsertLocalizationInput>>;
};

export type VehicleConditionUpdateManyInlineInput = {
  /** Connect multiple existing VehicleCondition documents */
  connect?: InputMaybe<Array<VehicleConditionConnectInput>>;
  /** Create and connect multiple VehicleCondition documents */
  create?: InputMaybe<Array<VehicleConditionCreateInput>>;
  /** Delete multiple VehicleCondition documents */
  delete?: InputMaybe<Array<VehicleConditionWhereUniqueInput>>;
  /** Disconnect multiple VehicleCondition documents */
  disconnect?: InputMaybe<Array<VehicleConditionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleCondition documents */
  set?: InputMaybe<Array<VehicleConditionWhereUniqueInput>>;
  /** Update multiple VehicleCondition documents */
  update?: InputMaybe<Array<VehicleConditionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleCondition documents */
  upsert?: InputMaybe<Array<VehicleConditionUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleConditionUpdateManyInput = {
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  ffuEnum?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleConditionUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  salesAdvisor?: InputMaybe<SalesAdvisorVehicleCondition>;
  structuredData?: InputMaybe<StructuredDataItemCondition>;
  termsAndConditionsAttribute?: InputMaybe<TermsAndConditionsAttribute>;
};

export type VehicleConditionUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleConditionUpdateManyLocalizationInput = {
  data: VehicleConditionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleConditionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleConditionUpdateManyLocalizationInput>>;
};

export type VehicleConditionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleConditionUpdateManyInput;
  /** Document search */
  where: VehicleConditionWhereInput;
};

export type VehicleConditionUpdateOneInlineInput = {
  /** Connect existing VehicleCondition document */
  connect?: InputMaybe<VehicleConditionWhereUniqueInput>;
  /** Create and connect one VehicleCondition document */
  create?: InputMaybe<VehicleConditionCreateInput>;
  /** Delete currently connected VehicleCondition document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleCondition document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleCondition document */
  update?: InputMaybe<VehicleConditionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleCondition document */
  upsert?: InputMaybe<VehicleConditionUpsertWithNestedWhereUniqueInput>;
};

export type VehicleConditionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleConditionUpdateInput;
  /** Unique document search */
  where: VehicleConditionWhereUniqueInput;
};

export type VehicleConditionUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleConditionCreateInput;
  /** Update document if it exists */
  update: VehicleConditionUpdateInput;
};

export type VehicleConditionUpsertLocalizationInput = {
  create: VehicleConditionCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleConditionUpdateLocalizationDataInput;
};

export type VehicleConditionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleConditionUpsertInput;
  /** Unique document search */
  where: VehicleConditionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VehicleConditionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VehicleConditionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleConditionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  basicFilterValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  basicFilterValue_not?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleConditionWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleConditionWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleConditionWhereStageInput>;
  ffuEnum?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  ffuEnum_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  ffuEnum_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  ffuEnum_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ffuEnum_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  ffuEnum_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  ffuEnum_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  ffuEnum_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  ffuEnum_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  ffuEnum_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  keywordLists_every?: InputMaybe<KeywordListWhereInput>;
  keywordLists_none?: InputMaybe<KeywordListWhereInput>;
  keywordLists_some?: InputMaybe<KeywordListWhereInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  salesAdvisor?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are contained in given list. */
  salesAdvisor_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  salesAdvisor_not?: InputMaybe<SalesAdvisorVehicleCondition>;
  /** All values that are not contained in given list. */
  salesAdvisor_not_in?: InputMaybe<Array<InputMaybe<SalesAdvisorVehicleCondition>>>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  structuredData?: InputMaybe<StructuredDataItemCondition>;
  /** All values that are contained in given list. */
  structuredData_in?: InputMaybe<Array<InputMaybe<StructuredDataItemCondition>>>;
  /** Any other value that exists and is not equal to the given value. */
  structuredData_not?: InputMaybe<StructuredDataItemCondition>;
  /** All values that are not contained in given list. */
  structuredData_not_in?: InputMaybe<Array<InputMaybe<StructuredDataItemCondition>>>;
  termsAndConditionsAttribute?: InputMaybe<TermsAndConditionsAttribute>;
  /** All values that are contained in given list. */
  termsAndConditionsAttribute_in?: InputMaybe<Array<InputMaybe<TermsAndConditionsAttribute>>>;
  /** Any other value that exists and is not equal to the given value. */
  termsAndConditionsAttribute_not?: InputMaybe<TermsAndConditionsAttribute>;
  /** All values that are not contained in given list. */
  termsAndConditionsAttribute_not_in?: InputMaybe<Array<InputMaybe<TermsAndConditionsAttribute>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VehicleConditionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleConditionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleConditionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleConditionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VehicleConditionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References VehicleCondition record uniquely */
export type VehicleConditionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleCreateInput = {
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  batteryRange?: InputMaybe<Scalars['Float']['input']>;
  bodyType?: InputMaybe<BodyTypeCreateOneInlineInput>;
  brand?: InputMaybe<BrandCreateOneInlineInput>;
  buildYear?: InputMaybe<Scalars['Int']['input']>;
  ccm?: InputMaybe<Scalars['Int']['input']>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commissionNumber?: InputMaybe<Scalars['String']['input']>;
  consumptionCity?: InputMaybe<Scalars['Float']['input']>;
  consumptionComb?: InputMaybe<Scalars['Float']['input']>;
  consumptionCountry?: InputMaybe<Scalars['Float']['input']>;
  consumptionPower?: InputMaybe<Scalars['Float']['input']>;
  contractOptions?: InputMaybe<Array<ContractOption>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Currency>;
  cylinderNum?: InputMaybe<Scalars['Int']['input']>;
  dealer?: InputMaybe<DealerCreateOneInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  discountList?: InputMaybe<VehicleDiscountListCreateOneInlineInput>;
  doorNum?: InputMaybe<Scalars['Int']['input']>;
  driveType?: InputMaybe<DriveTypeCreateOneInlineInput>;
  electroKw?: InputMaybe<Scalars['Int']['input']>;
  emissionClass?: InputMaybe<EmissionClassCreateOneInlineInput>;
  emptyWeight?: InputMaybe<Scalars['Int']['input']>;
  enabled: Scalars['Boolean']['input'];
  equipmentPackage?: InputMaybe<Scalars['String']['input']>;
  exteriorColor?: InputMaybe<ColorNormCreateOneInlineInput>;
  exteriorColorType?: InputMaybe<ColorTypeCreateOneInlineInput>;
  factoryCode?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryExteriorColorName input for default locale (de) */
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryInteriorColorName input for default locale (de) */
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  fuelType?: InputMaybe<FuelTypeCreateOneInlineInput>;
  gearNum?: InputMaybe<Scalars['Int']['input']>;
  hadAccident: Scalars['Boolean']['input'];
  hpTotal?: InputMaybe<Scalars['Int']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  interiorColor?: InputMaybe<ColorNormCreateOneInlineInput>;
  km: Scalars['Int']['input'];
  kw?: InputMaybe<Scalars['Int']['input']>;
  lastInspection?: InputMaybe<Scalars['Boolean']['input']>;
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  leasingRateFrom?: InputMaybe<Scalars['Float']['input']>;
  leasingRateFromCalcBasis?: InputMaybe<VehicleLeasingRateCalculationCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleCreateLocalizationsInput>;
  marketplaces?: InputMaybe<Array<Marketplace>>;
  model?: InputMaybe<ModelCreateOneInlineInput>;
  optionFacets?: InputMaybe<OptionFacetCreateManyInlineInput>;
  /** options input for default locale (de) */
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  pollutionClass?: InputMaybe<PollutionClassCreateOneInlineInput>;
  priceB2cGross: Scalars['Float']['input'];
  priceDownpaymentGross?: InputMaybe<Scalars['Float']['input']>;
  priceInitialB2cGross: Scalars['Float']['input'];
  priceNewTotalGross?: InputMaybe<Scalars['Float']['input']>;
  pricePreparationGross: Scalars['Float']['input'];
  productionDate?: InputMaybe<Scalars['Date']['input']>;
  promotionList?: InputMaybe<VehiclePromotionListCreateOneInlineInput>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  qualityChecked?: InputMaybe<QualityCheckedCreateOneInlineInput>;
  reserved: Scalars['Boolean']['input'];
  seatNum?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** teaser input for default locale (de) */
  teaser?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  titleFacet?: InputMaybe<Scalars['String']['input']>;
  trailerWeightMax?: InputMaybe<Scalars['Int']['input']>;
  transmission?: InputMaybe<TransmissionCreateOneInlineInput>;
  typenschein?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  upholstery?: InputMaybe<UpholsteryCreateOneInlineInput>;
  vehicleClass?: InputMaybe<VehicleClassCreateOneInlineInput>;
  vehicleCondition?: InputMaybe<VehicleConditionCreateOneInlineInput>;
  vehicleId: Scalars['Int']['input'];
  vehicleStatus?: InputMaybe<VehicleStatusCreateOneInlineInput>;
  vehicleType?: InputMaybe<VehicleTypeCreateOneInlineInput>;
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Json']['input']>;
  weightMax?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleCreateLocalizationInput = {
  /** Localization input */
  data: VehicleCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleCreateLocalizationInput>>;
};

export type VehicleCreateManyInlineInput = {
  /** Connect multiple existing Vehicle documents */
  connect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  /** Create and connect multiple existing Vehicle documents */
  create?: InputMaybe<Array<VehicleCreateInput>>;
};

export type VehicleCreateOneInlineInput = {
  /** Connect one existing Vehicle document */
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  /** Create and connect one Vehicle document */
  create?: InputMaybe<VehicleCreateInput>;
};

export type VehicleDiscount = Entity & {
  __typename?: 'VehicleDiscount';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId?: Maybe<Scalars['Int']['output']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleDiscount>;
  name?: Maybe<Scalars['String']['output']>;
  priceGross: Scalars['Float']['output'];
  /** System stage field */
  stage: Stage;
};


export type VehicleDiscountLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type VehicleDiscountConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleDiscountWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleDiscountConnection = {
  __typename?: 'VehicleDiscountConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleDiscountEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleDiscountCreateInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleDiscountCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  priceGross: Scalars['Float']['input'];
};

export type VehicleDiscountCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleDiscountCreateLocalizationInput = {
  /** Localization input */
  data: VehicleDiscountCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleDiscountCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleDiscountCreateLocalizationInput>>;
};

export type VehicleDiscountCreateManyInlineInput = {
  /** Create and connect multiple existing VehicleDiscount documents */
  create?: InputMaybe<Array<VehicleDiscountCreateInput>>;
};

export type VehicleDiscountCreateOneInlineInput = {
  /** Create and connect one VehicleDiscount document */
  create?: InputMaybe<VehicleDiscountCreateInput>;
};

export type VehicleDiscountCreateWithPositionInput = {
  /** Document to create */
  data: VehicleDiscountCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VehicleDiscountEdge = {
  __typename?: 'VehicleDiscountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleDiscount;
};

export type VehicleDiscountList = Entity & {
  __typename?: 'VehicleDiscountList';
  discounts: Array<VehicleDiscount>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System stage field */
  stage: Stage;
};


export type VehicleDiscountListDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VehicleDiscountOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehicleDiscountWhereInput>;
};

export type VehicleDiscountListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleDiscountListWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleDiscountListConnection = {
  __typename?: 'VehicleDiscountListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleDiscountListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleDiscountListCreateInput = {
  discounts?: InputMaybe<VehicleDiscountCreateManyInlineInput>;
};

export type VehicleDiscountListCreateManyInlineInput = {
  /** Create and connect multiple existing VehicleDiscountList documents */
  create?: InputMaybe<Array<VehicleDiscountListCreateInput>>;
};

export type VehicleDiscountListCreateOneInlineInput = {
  /** Create and connect one VehicleDiscountList document */
  create?: InputMaybe<VehicleDiscountListCreateInput>;
};

export type VehicleDiscountListCreateWithPositionInput = {
  /** Document to create */
  data: VehicleDiscountListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VehicleDiscountListEdge = {
  __typename?: 'VehicleDiscountListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleDiscountList;
};

/** Identifies documents */
export type VehicleDiscountListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  discounts_every?: InputMaybe<VehicleDiscountWhereInput>;
  discounts_none?: InputMaybe<VehicleDiscountWhereInput>;
  discounts_some?: InputMaybe<VehicleDiscountWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

export enum VehicleDiscountListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type VehicleDiscountListParent = Vehicle;

export type VehicleDiscountListParentConnectInput = {
  Vehicle?: InputMaybe<VehicleConnectInput>;
};

export type VehicleDiscountListParentCreateInput = {
  Vehicle?: InputMaybe<VehicleCreateInput>;
};

export type VehicleDiscountListParentCreateManyInlineInput = {
  /** Connect multiple existing VehicleDiscountListParent documents */
  connect?: InputMaybe<Array<VehicleDiscountListParentWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleDiscountListParent documents */
  create?: InputMaybe<Array<VehicleDiscountListParentCreateInput>>;
};

export type VehicleDiscountListParentCreateOneInlineInput = {
  /** Connect one existing VehicleDiscountListParent document */
  connect?: InputMaybe<VehicleDiscountListParentWhereUniqueInput>;
  /** Create and connect one VehicleDiscountListParent document */
  create?: InputMaybe<VehicleDiscountListParentCreateInput>;
};

export type VehicleDiscountListParentUpdateInput = {
  Vehicle?: InputMaybe<VehicleUpdateInput>;
};

export type VehicleDiscountListParentUpdateManyInlineInput = {
  /** Connect multiple existing VehicleDiscountListParent documents */
  connect?: InputMaybe<Array<VehicleDiscountListParentConnectInput>>;
  /** Create and connect multiple VehicleDiscountListParent documents */
  create?: InputMaybe<Array<VehicleDiscountListParentCreateInput>>;
  /** Delete multiple VehicleDiscountListParent documents */
  delete?: InputMaybe<Array<VehicleDiscountListParentWhereUniqueInput>>;
  /** Disconnect multiple VehicleDiscountListParent documents */
  disconnect?: InputMaybe<Array<VehicleDiscountListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleDiscountListParent documents */
  set?: InputMaybe<Array<VehicleDiscountListParentWhereUniqueInput>>;
  /** Update multiple VehicleDiscountListParent documents */
  update?: InputMaybe<Array<VehicleDiscountListParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleDiscountListParent documents */
  upsert?: InputMaybe<Array<VehicleDiscountListParentUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleDiscountListParentUpdateManyWithNestedWhereInput = {
  Vehicle?: InputMaybe<VehicleUpdateManyWithNestedWhereInput>;
};

export type VehicleDiscountListParentUpdateOneInlineInput = {
  /** Connect existing VehicleDiscountListParent document */
  connect?: InputMaybe<VehicleDiscountListParentWhereUniqueInput>;
  /** Create and connect one VehicleDiscountListParent document */
  create?: InputMaybe<VehicleDiscountListParentCreateInput>;
  /** Delete currently connected VehicleDiscountListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleDiscountListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleDiscountListParent document */
  update?: InputMaybe<VehicleDiscountListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleDiscountListParent document */
  upsert?: InputMaybe<VehicleDiscountListParentUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountListParentUpdateWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpdateWithNestedWhereUniqueInput>;
};

export type VehicleDiscountListParentUpsertWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountListParentWhereInput = {
  Vehicle?: InputMaybe<VehicleWhereInput>;
};

export type VehicleDiscountListParentWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleWhereUniqueInput>;
};

export type VehicleDiscountListUpdateInput = {
  discounts?: InputMaybe<VehicleDiscountUpdateManyInlineInput>;
};

export type VehicleDiscountListUpdateManyInlineInput = {
  /** Create and connect multiple VehicleDiscountList component instances */
  create?: InputMaybe<Array<VehicleDiscountListCreateWithPositionInput>>;
  /** Delete multiple VehicleDiscountList documents */
  delete?: InputMaybe<Array<VehicleDiscountListWhereUniqueInput>>;
  /** Update multiple VehicleDiscountList component instances */
  update?: InputMaybe<Array<VehicleDiscountListUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehicleDiscountList component instances */
  upsert?: InputMaybe<Array<VehicleDiscountListUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehicleDiscountListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleDiscountListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleDiscountListUpdateManyInput;
  /** Document search */
  where: VehicleDiscountListWhereInput;
};

export type VehicleDiscountListUpdateOneInlineInput = {
  /** Create and connect one VehicleDiscountList document */
  create?: InputMaybe<VehicleDiscountListCreateInput>;
  /** Delete currently connected VehicleDiscountList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleDiscountList document */
  update?: InputMaybe<VehicleDiscountListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleDiscountList document */
  upsert?: InputMaybe<VehicleDiscountListUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VehicleDiscountListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleDiscountListWhereUniqueInput;
};

export type VehicleDiscountListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleDiscountListUpdateInput;
  /** Unique document search */
  where: VehicleDiscountListWhereUniqueInput;
};

export type VehicleDiscountListUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleDiscountListCreateInput;
  /** Update document if it exists */
  update: VehicleDiscountListUpdateInput;
};

export type VehicleDiscountListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VehicleDiscountListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleDiscountListWhereUniqueInput;
};

export type VehicleDiscountListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleDiscountListUpsertInput;
  /** Unique document search */
  where: VehicleDiscountListWhereUniqueInput;
};

/** Identifies documents */
export type VehicleDiscountListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleDiscountListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  discounts_every?: InputMaybe<VehicleDiscountWhereInput>;
  discounts_none?: InputMaybe<VehicleDiscountWhereInput>;
  discounts_some?: InputMaybe<VehicleDiscountWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
};

/** References VehicleDiscountList record uniquely */
export type VehicleDiscountListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type VehicleDiscountManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export enum VehicleDiscountOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PriceGrossAsc = 'priceGross_ASC',
  PriceGrossDesc = 'priceGross_DESC'
}

export type VehicleDiscountParent = VehicleDiscountList;

export type VehicleDiscountParentConnectInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListConnectInput>;
};

export type VehicleDiscountParentCreateInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListCreateInput>;
};

export type VehicleDiscountParentCreateManyInlineInput = {
  /** Create and connect multiple existing VehicleDiscountParent documents */
  create?: InputMaybe<Array<VehicleDiscountParentCreateInput>>;
};

export type VehicleDiscountParentCreateOneInlineInput = {
  /** Create and connect one VehicleDiscountParent document */
  create?: InputMaybe<VehicleDiscountParentCreateInput>;
};

export type VehicleDiscountParentCreateWithPositionInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListCreateWithPositionInput>;
};

export type VehicleDiscountParentUpdateInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpdateInput>;
};

export type VehicleDiscountParentUpdateManyInlineInput = {
  /** Create and connect multiple VehicleDiscountParent component instances */
  create?: InputMaybe<Array<VehicleDiscountParentCreateWithPositionInput>>;
  /** Delete multiple VehicleDiscountParent documents */
  delete?: InputMaybe<Array<VehicleDiscountParentWhereUniqueInput>>;
  /** Update multiple VehicleDiscountParent component instances */
  update?: InputMaybe<Array<VehicleDiscountParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehicleDiscountParent component instances */
  upsert?: InputMaybe<Array<VehicleDiscountParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehicleDiscountParentUpdateManyWithNestedWhereInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpdateManyWithNestedWhereInput>;
};

export type VehicleDiscountParentUpdateOneInlineInput = {
  /** Create and connect one VehicleDiscountParent document */
  create?: InputMaybe<VehicleDiscountParentCreateInput>;
  /** Delete currently connected VehicleDiscountParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleDiscountParent document */
  update?: InputMaybe<VehicleDiscountParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleDiscountParent document */
  upsert?: InputMaybe<VehicleDiscountParentUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountParentUpdateWithNestedWhereUniqueAndPositionInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type VehicleDiscountParentUpdateWithNestedWhereUniqueInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpdateWithNestedWhereUniqueInput>;
};

export type VehicleDiscountParentUpsertWithNestedWhereUniqueAndPositionInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type VehicleDiscountParentUpsertWithNestedWhereUniqueInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountParentWhereInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListWhereInput>;
};

export type VehicleDiscountParentWhereUniqueInput = {
  VehicleDiscountList?: InputMaybe<VehicleDiscountListWhereUniqueInput>;
};

export type VehicleDiscountUpdateInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleDiscountUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
};

export type VehicleDiscountUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleDiscountUpdateLocalizationInput = {
  data: VehicleDiscountUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleDiscountUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleDiscountCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleDiscountUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleDiscountUpsertLocalizationInput>>;
};

export type VehicleDiscountUpdateManyInlineInput = {
  /** Create and connect multiple VehicleDiscount component instances */
  create?: InputMaybe<Array<VehicleDiscountCreateWithPositionInput>>;
  /** Delete multiple VehicleDiscount documents */
  delete?: InputMaybe<Array<VehicleDiscountWhereUniqueInput>>;
  /** Update multiple VehicleDiscount component instances */
  update?: InputMaybe<Array<VehicleDiscountUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehicleDiscount component instances */
  upsert?: InputMaybe<Array<VehicleDiscountUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehicleDiscountUpdateManyInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleDiscountUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
};

export type VehicleDiscountUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleDiscountUpdateManyLocalizationInput = {
  data: VehicleDiscountUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleDiscountUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleDiscountUpdateManyLocalizationInput>>;
};

export type VehicleDiscountUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleDiscountUpdateManyInput;
  /** Document search */
  where: VehicleDiscountWhereInput;
};

export type VehicleDiscountUpdateOneInlineInput = {
  /** Create and connect one VehicleDiscount document */
  create?: InputMaybe<VehicleDiscountCreateInput>;
  /** Delete currently connected VehicleDiscount document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleDiscount document */
  update?: InputMaybe<VehicleDiscountUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleDiscount document */
  upsert?: InputMaybe<VehicleDiscountUpsertWithNestedWhereUniqueInput>;
};

export type VehicleDiscountUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VehicleDiscountUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleDiscountWhereUniqueInput;
};

export type VehicleDiscountUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleDiscountUpdateInput;
  /** Unique document search */
  where: VehicleDiscountWhereUniqueInput;
};

export type VehicleDiscountUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleDiscountCreateInput;
  /** Update document if it exists */
  update: VehicleDiscountUpdateInput;
};

export type VehicleDiscountUpsertLocalizationInput = {
  create: VehicleDiscountCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleDiscountUpdateLocalizationDataInput;
};

export type VehicleDiscountUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VehicleDiscountUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleDiscountWhereUniqueInput;
};

export type VehicleDiscountUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleDiscountUpsertInput;
  /** Unique document search */
  where: VehicleDiscountWhereUniqueInput;
};

/** Identifies documents */
export type VehicleDiscountWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleDiscountWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** References VehicleDiscount record uniquely */
export type VehicleDiscountWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** An edge in a connection. */
export type VehicleEdge = {
  __typename?: 'VehicleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Vehicle;
};

export type VehicleLeasingRateCalculation = Entity & {
  __typename?: 'VehicleLeasingRateCalculation';
  downPayment: Scalars['Float']['output'];
  effectiveInterest: Scalars['Float']['output'];
  /** The unique identifier */
  id: Scalars['ID']['output'];
  kmPerYear: Scalars['Int']['output'];
  leasingInterest: Scalars['Float']['output'];
  legalText: Scalars['String']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleLeasingRateCalculation>;
  residualValue: Scalars['Float']['output'];
  /** System stage field */
  stage: Stage;
  totalRuntime: Scalars['Int']['output'];
};


export type VehicleLeasingRateCalculationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};

export type VehicleLeasingRateCalculationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleLeasingRateCalculationWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleLeasingRateCalculationConnection = {
  __typename?: 'VehicleLeasingRateCalculationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleLeasingRateCalculationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleLeasingRateCalculationCreateInput = {
  downPayment: Scalars['Float']['input'];
  effectiveInterest: Scalars['Float']['input'];
  kmPerYear: Scalars['Int']['input'];
  leasingInterest: Scalars['Float']['input'];
  /** legalText input for default locale (de) */
  legalText: Scalars['String']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleLeasingRateCalculationCreateLocalizationsInput>;
  residualValue: Scalars['Float']['input'];
  totalRuntime: Scalars['Int']['input'];
};

export type VehicleLeasingRateCalculationCreateLocalizationDataInput = {
  legalText: Scalars['String']['input'];
};

export type VehicleLeasingRateCalculationCreateLocalizationInput = {
  /** Localization input */
  data: VehicleLeasingRateCalculationCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleLeasingRateCalculationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationCreateLocalizationInput>>;
};

export type VehicleLeasingRateCalculationCreateManyInlineInput = {
  /** Create and connect multiple existing VehicleLeasingRateCalculation documents */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationCreateInput>>;
};

export type VehicleLeasingRateCalculationCreateOneInlineInput = {
  /** Create and connect one VehicleLeasingRateCalculation document */
  create?: InputMaybe<VehicleLeasingRateCalculationCreateInput>;
};

export type VehicleLeasingRateCalculationCreateWithPositionInput = {
  /** Document to create */
  data: VehicleLeasingRateCalculationCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VehicleLeasingRateCalculationEdge = {
  __typename?: 'VehicleLeasingRateCalculationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleLeasingRateCalculation;
};

/** Identifies documents */
export type VehicleLeasingRateCalculationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  downPayment?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  downPayment_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  downPayment_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  downPayment_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  downPayment_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  downPayment_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  downPayment_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  downPayment_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  effectiveInterest?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  effectiveInterest_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  effectiveInterest_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  effectiveInterest_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  effectiveInterest_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  effectiveInterest_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  effectiveInterest_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  effectiveInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  kmPerYear?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  kmPerYear_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  kmPerYear_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  kmPerYear_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  kmPerYear_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  kmPerYear_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  kmPerYear_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  kmPerYear_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  leasingInterest?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  leasingInterest_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  leasingInterest_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  leasingInterest_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  leasingInterest_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  leasingInterest_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingInterest_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  leasingInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  residualValue?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  residualValue_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  residualValue_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  residualValue_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  residualValue_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  residualValue_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  residualValue_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  residualValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalRuntime?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  totalRuntime_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  totalRuntime_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  totalRuntime_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  totalRuntime_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  totalRuntime_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  totalRuntime_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  totalRuntime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum VehicleLeasingRateCalculationOrderByInput {
  DownPaymentAsc = 'downPayment_ASC',
  DownPaymentDesc = 'downPayment_DESC',
  EffectiveInterestAsc = 'effectiveInterest_ASC',
  EffectiveInterestDesc = 'effectiveInterest_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KmPerYearAsc = 'kmPerYear_ASC',
  KmPerYearDesc = 'kmPerYear_DESC',
  LeasingInterestAsc = 'leasingInterest_ASC',
  LeasingInterestDesc = 'leasingInterest_DESC',
  LegalTextAsc = 'legalText_ASC',
  LegalTextDesc = 'legalText_DESC',
  ResidualValueAsc = 'residualValue_ASC',
  ResidualValueDesc = 'residualValue_DESC',
  TotalRuntimeAsc = 'totalRuntime_ASC',
  TotalRuntimeDesc = 'totalRuntime_DESC'
}

export type VehicleLeasingRateCalculationParent = Vehicle;

export type VehicleLeasingRateCalculationParentConnectInput = {
  Vehicle?: InputMaybe<VehicleConnectInput>;
};

export type VehicleLeasingRateCalculationParentCreateInput = {
  Vehicle?: InputMaybe<VehicleCreateInput>;
};

export type VehicleLeasingRateCalculationParentCreateManyInlineInput = {
  /** Connect multiple existing VehicleLeasingRateCalculationParent documents */
  connect?: InputMaybe<Array<VehicleLeasingRateCalculationParentWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleLeasingRateCalculationParent documents */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationParentCreateInput>>;
};

export type VehicleLeasingRateCalculationParentCreateOneInlineInput = {
  /** Connect one existing VehicleLeasingRateCalculationParent document */
  connect?: InputMaybe<VehicleLeasingRateCalculationParentWhereUniqueInput>;
  /** Create and connect one VehicleLeasingRateCalculationParent document */
  create?: InputMaybe<VehicleLeasingRateCalculationParentCreateInput>;
};

export type VehicleLeasingRateCalculationParentUpdateInput = {
  Vehicle?: InputMaybe<VehicleUpdateInput>;
};

export type VehicleLeasingRateCalculationParentUpdateManyInlineInput = {
  /** Connect multiple existing VehicleLeasingRateCalculationParent documents */
  connect?: InputMaybe<Array<VehicleLeasingRateCalculationParentConnectInput>>;
  /** Create and connect multiple VehicleLeasingRateCalculationParent documents */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationParentCreateInput>>;
  /** Delete multiple VehicleLeasingRateCalculationParent documents */
  delete?: InputMaybe<Array<VehicleLeasingRateCalculationParentWhereUniqueInput>>;
  /** Disconnect multiple VehicleLeasingRateCalculationParent documents */
  disconnect?: InputMaybe<Array<VehicleLeasingRateCalculationParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleLeasingRateCalculationParent documents */
  set?: InputMaybe<Array<VehicleLeasingRateCalculationParentWhereUniqueInput>>;
  /** Update multiple VehicleLeasingRateCalculationParent documents */
  update?: InputMaybe<Array<VehicleLeasingRateCalculationParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleLeasingRateCalculationParent documents */
  upsert?: InputMaybe<Array<VehicleLeasingRateCalculationParentUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleLeasingRateCalculationParentUpdateManyWithNestedWhereInput = {
  Vehicle?: InputMaybe<VehicleUpdateManyWithNestedWhereInput>;
};

export type VehicleLeasingRateCalculationParentUpdateOneInlineInput = {
  /** Connect existing VehicleLeasingRateCalculationParent document */
  connect?: InputMaybe<VehicleLeasingRateCalculationParentWhereUniqueInput>;
  /** Create and connect one VehicleLeasingRateCalculationParent document */
  create?: InputMaybe<VehicleLeasingRateCalculationParentCreateInput>;
  /** Delete currently connected VehicleLeasingRateCalculationParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleLeasingRateCalculationParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleLeasingRateCalculationParent document */
  update?: InputMaybe<VehicleLeasingRateCalculationParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleLeasingRateCalculationParent document */
  upsert?: InputMaybe<VehicleLeasingRateCalculationParentUpsertWithNestedWhereUniqueInput>;
};

export type VehicleLeasingRateCalculationParentUpdateWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpdateWithNestedWhereUniqueInput>;
};

export type VehicleLeasingRateCalculationParentUpsertWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpsertWithNestedWhereUniqueInput>;
};

export type VehicleLeasingRateCalculationParentWhereInput = {
  Vehicle?: InputMaybe<VehicleWhereInput>;
};

export type VehicleLeasingRateCalculationParentWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleWhereUniqueInput>;
};

export type VehicleLeasingRateCalculationUpdateInput = {
  downPayment?: InputMaybe<Scalars['Float']['input']>;
  effectiveInterest?: InputMaybe<Scalars['Float']['input']>;
  kmPerYear?: InputMaybe<Scalars['Int']['input']>;
  leasingInterest?: InputMaybe<Scalars['Float']['input']>;
  /** legalText input for default locale (de) */
  legalText?: InputMaybe<Scalars['String']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleLeasingRateCalculationUpdateLocalizationsInput>;
  residualValue?: InputMaybe<Scalars['Float']['input']>;
  totalRuntime?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleLeasingRateCalculationUpdateLocalizationDataInput = {
  legalText?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleLeasingRateCalculationUpdateLocalizationInput = {
  data: VehicleLeasingRateCalculationUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleLeasingRateCalculationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleLeasingRateCalculationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleLeasingRateCalculationUpsertLocalizationInput>>;
};

export type VehicleLeasingRateCalculationUpdateManyInlineInput = {
  /** Create and connect multiple VehicleLeasingRateCalculation component instances */
  create?: InputMaybe<Array<VehicleLeasingRateCalculationCreateWithPositionInput>>;
  /** Delete multiple VehicleLeasingRateCalculation documents */
  delete?: InputMaybe<Array<VehicleLeasingRateCalculationWhereUniqueInput>>;
  /** Update multiple VehicleLeasingRateCalculation component instances */
  update?: InputMaybe<Array<VehicleLeasingRateCalculationUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehicleLeasingRateCalculation component instances */
  upsert?: InputMaybe<Array<VehicleLeasingRateCalculationUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehicleLeasingRateCalculationUpdateManyInput = {
  downPayment?: InputMaybe<Scalars['Float']['input']>;
  effectiveInterest?: InputMaybe<Scalars['Float']['input']>;
  kmPerYear?: InputMaybe<Scalars['Int']['input']>;
  leasingInterest?: InputMaybe<Scalars['Float']['input']>;
  /** legalText input for default locale (de) */
  legalText?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleLeasingRateCalculationUpdateManyLocalizationsInput>;
  residualValue?: InputMaybe<Scalars['Float']['input']>;
  totalRuntime?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleLeasingRateCalculationUpdateManyLocalizationDataInput = {
  legalText?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleLeasingRateCalculationUpdateManyLocalizationInput = {
  data: VehicleLeasingRateCalculationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleLeasingRateCalculationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleLeasingRateCalculationUpdateManyLocalizationInput>>;
};

export type VehicleLeasingRateCalculationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleLeasingRateCalculationUpdateManyInput;
  /** Document search */
  where: VehicleLeasingRateCalculationWhereInput;
};

export type VehicleLeasingRateCalculationUpdateOneInlineInput = {
  /** Create and connect one VehicleLeasingRateCalculation document */
  create?: InputMaybe<VehicleLeasingRateCalculationCreateInput>;
  /** Delete currently connected VehicleLeasingRateCalculation document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleLeasingRateCalculation document */
  update?: InputMaybe<VehicleLeasingRateCalculationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleLeasingRateCalculation document */
  upsert?: InputMaybe<VehicleLeasingRateCalculationUpsertWithNestedWhereUniqueInput>;
};

export type VehicleLeasingRateCalculationUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VehicleLeasingRateCalculationUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleLeasingRateCalculationWhereUniqueInput;
};

export type VehicleLeasingRateCalculationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleLeasingRateCalculationUpdateInput;
  /** Unique document search */
  where: VehicleLeasingRateCalculationWhereUniqueInput;
};

export type VehicleLeasingRateCalculationUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleLeasingRateCalculationCreateInput;
  /** Update document if it exists */
  update: VehicleLeasingRateCalculationUpdateInput;
};

export type VehicleLeasingRateCalculationUpsertLocalizationInput = {
  create: VehicleLeasingRateCalculationCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleLeasingRateCalculationUpdateLocalizationDataInput;
};

export type VehicleLeasingRateCalculationUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VehicleLeasingRateCalculationUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehicleLeasingRateCalculationWhereUniqueInput;
};

export type VehicleLeasingRateCalculationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleLeasingRateCalculationUpsertInput;
  /** Unique document search */
  where: VehicleLeasingRateCalculationWhereUniqueInput;
};

/** Identifies documents */
export type VehicleLeasingRateCalculationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleLeasingRateCalculationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  downPayment?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  downPayment_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  downPayment_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  downPayment_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  downPayment_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  downPayment_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  downPayment_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  downPayment_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  effectiveInterest?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  effectiveInterest_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  effectiveInterest_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  effectiveInterest_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  effectiveInterest_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  effectiveInterest_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  effectiveInterest_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  effectiveInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  kmPerYear?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  kmPerYear_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  kmPerYear_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  kmPerYear_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  kmPerYear_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  kmPerYear_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  kmPerYear_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  kmPerYear_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  leasingInterest?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  leasingInterest_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  leasingInterest_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  leasingInterest_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  leasingInterest_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  leasingInterest_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingInterest_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  leasingInterest_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  legalText?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  legalText_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  legalText_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  legalText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  legalText_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  legalText_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  legalText_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  legalText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  legalText_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  legalText_starts_with?: InputMaybe<Scalars['String']['input']>;
  residualValue?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  residualValue_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  residualValue_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  residualValue_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  residualValue_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  residualValue_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  residualValue_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  residualValue_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  totalRuntime?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  totalRuntime_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  totalRuntime_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  totalRuntime_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  totalRuntime_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  totalRuntime_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  totalRuntime_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  totalRuntime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** References VehicleLeasingRateCalculation record uniquely */
export type VehicleLeasingRateCalculationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type VehicleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  batteryCapacity_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  batteryCapacity_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  batteryCapacity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  batteryCapacity_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  batteryCapacity_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  batteryCapacity_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  batteryCapacity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  batteryRange?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  batteryRange_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  batteryRange_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  batteryRange_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  batteryRange_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  batteryRange_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  batteryRange_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  batteryRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  bodyType?: InputMaybe<BodyTypeWhereInput>;
  brand?: InputMaybe<BrandWhereInput>;
  buildYear?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  buildYear_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  buildYear_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  buildYear_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  buildYear_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  buildYear_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  buildYear_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  buildYear_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ccm?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  ccm_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  ccm_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  ccm_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  ccm_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  ccm_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  ccm_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  ccm_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  co2_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  co2_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  co2_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  co2_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  co2_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  co2_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  co2_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  comment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  comment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  comment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  comment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  comment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  comment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  comment_starts_with?: InputMaybe<Scalars['String']['input']>;
  commissionNumber?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  commissionNumber_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  commissionNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  commissionNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  commissionNumber_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  commissionNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  commissionNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  commissionNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  commissionNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  commissionNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  consumptionCity?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionCity_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionCity_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionCity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionCity_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionCity_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionCity_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionCity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionComb?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionComb_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionComb_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionComb_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionComb_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionComb_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionComb_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionComb_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionCountry?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionCountry_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionCountry_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionCountry_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionCountry_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionCountry_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionCountry_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionCountry_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionPower?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionPower_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionPower_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionPower_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionPower_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionPower_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionPower_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionPower_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  contractOptions?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array contains *all* items provided to the filter */
  contractOptions_contains_all?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  contractOptions_contains_none?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array contains at least one item provided to the filter */
  contractOptions_contains_some?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  contractOptions_not?: InputMaybe<Array<ContractOption>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currency>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currency>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  cylinderNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  cylinderNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  cylinderNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  cylinderNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  cylinderNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  cylinderNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  cylinderNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  cylinderNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dealer?: InputMaybe<DealerWhereInput>;
  discountList?: InputMaybe<VehicleDiscountListWhereInput>;
  documentInStages_every?: InputMaybe<VehicleWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleWhereStageInput>;
  doorNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  doorNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  doorNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  doorNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  doorNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  doorNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  doorNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  doorNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  driveType?: InputMaybe<DriveTypeWhereInput>;
  electroKw?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  electroKw_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  electroKw_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  electroKw_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  electroKw_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  electroKw_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  electroKw_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  electroKw_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  emissionClass?: InputMaybe<EmissionClassWhereInput>;
  emptyWeight?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  emptyWeight_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  emptyWeight_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  emptyWeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  emptyWeight_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  emptyWeight_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  emptyWeight_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  emptyWeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentPackage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  equipmentPackage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  equipmentPackage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  equipmentPackage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  equipmentPackage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  equipmentPackage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  equipmentPackage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  equipmentPackage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  equipmentPackage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  equipmentPackage_starts_with?: InputMaybe<Scalars['String']['input']>;
  exteriorColor?: InputMaybe<ColorNormWhereInput>;
  exteriorColorType?: InputMaybe<ColorTypeWhereInput>;
  factoryCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryExteriorColorCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryExteriorColorCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryExteriorColorCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryExteriorColorCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryExteriorColorCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryExteriorColorCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryExteriorColorCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryExteriorColorCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryExteriorColorCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryInteriorColorCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryInteriorColorCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryInteriorColorCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryInteriorColorCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryInteriorColorCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryInteriorColorCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryInteriorColorCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryInteriorColorCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryInteriorColorCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  firstRegistration_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  firstRegistration_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  firstRegistration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  firstRegistration_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  firstRegistration_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  firstRegistration_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  firstRegistration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  fuelType?: InputMaybe<FuelTypeWhereInput>;
  gearNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  gearNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  gearNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  gearNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  gearNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  gearNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  gearNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  gearNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hadAccident?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  hadAccident_not?: InputMaybe<Scalars['Boolean']['input']>;
  hpTotal?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  hpTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  hpTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  hpTotal_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  hpTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  hpTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  hpTotal_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  hpTotal_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  imageUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  imageUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  imageUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  imageUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  interiorColor?: InputMaybe<ColorNormWhereInput>;
  km?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  km_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  km_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  km_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  km_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  km_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  km_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  km_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  kw?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  kw_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  kw_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  kw_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  kw_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  kw_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  kw_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  kw_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lastInspection?: InputMaybe<Scalars['Boolean']['input']>;
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  lastInspectionDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  lastInspectionDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  lastInspectionDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  lastInspectionDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  lastInspectionDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  lastInspectionDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  lastInspectionDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  lastInspection_not?: InputMaybe<Scalars['Boolean']['input']>;
  leasingRateFrom?: InputMaybe<Scalars['Float']['input']>;
  leasingRateFromCalcBasis?: InputMaybe<VehicleLeasingRateCalculationWhereInput>;
  /** All values greater than the given value. */
  leasingRateFrom_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  leasingRateFrom_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  leasingRateFrom_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  leasingRateFrom_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  leasingRateFrom_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingRateFrom_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  leasingRateFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  marketplaces?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array contains *all* items provided to the filter */
  marketplaces_contains_all?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  marketplaces_contains_none?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array contains at least one item provided to the filter */
  marketplaces_contains_some?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  marketplaces_not?: InputMaybe<Array<Marketplace>>;
  model?: InputMaybe<ModelWhereInput>;
  optionFacets_every?: InputMaybe<OptionFacetWhereInput>;
  optionFacets_none?: InputMaybe<OptionFacetWhereInput>;
  optionFacets_some?: InputMaybe<OptionFacetWhereInput>;
  pollutionClass?: InputMaybe<PollutionClassWhereInput>;
  priceB2cGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceB2cGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceB2cGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceB2cGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceB2cGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceB2cGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceB2cGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceB2cGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceDownpaymentGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceDownpaymentGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceDownpaymentGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceDownpaymentGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceDownpaymentGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceDownpaymentGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceDownpaymentGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceDownpaymentGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceInitialB2cGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceInitialB2cGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceInitialB2cGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceInitialB2cGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceInitialB2cGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceInitialB2cGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceInitialB2cGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceInitialB2cGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceNewTotalGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceNewTotalGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceNewTotalGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceNewTotalGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceNewTotalGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceNewTotalGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceNewTotalGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceNewTotalGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  pricePreparationGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  pricePreparationGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  pricePreparationGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  pricePreparationGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  pricePreparationGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  pricePreparationGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  pricePreparationGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  pricePreparationGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  productionDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  productionDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  productionDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  productionDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  productionDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  productionDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  productionDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  productionDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  promotionList?: InputMaybe<VehiclePromotionListWhereInput>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  ps_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  ps_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  ps_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  ps_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  ps_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  ps_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  ps_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityChecked?: InputMaybe<QualityCheckedWhereInput>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  reserved_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seatNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  seatNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  seatNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  seatNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  seatNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  seatNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  seatNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  seatNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleFacet?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  titleFacet_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  titleFacet_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  titleFacet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  titleFacet_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  titleFacet_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  titleFacet_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  titleFacet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  titleFacet_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  titleFacet_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  trailerWeightMax?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  trailerWeightMax_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  trailerWeightMax_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  trailerWeightMax_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  trailerWeightMax_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  trailerWeightMax_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  trailerWeightMax_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  trailerWeightMax_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  transmission?: InputMaybe<TransmissionWhereInput>;
  typenschein?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  typenschein_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  typenschein_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  typenschein_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  typenschein_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  typenschein_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  typenschein_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  typenschein_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  typenschein_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  typenschein_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  upholstery?: InputMaybe<UpholsteryWhereInput>;
  vehicleClass?: InputMaybe<VehicleClassWhereInput>;
  vehicleCondition?: InputMaybe<VehicleConditionWhereInput>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  vehicleId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  vehicleId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  vehicleId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  vehicleId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  vehicleId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  vehicleId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  vehicleId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleStatus?: InputMaybe<VehicleStatusWhereInput>;
  vehicleType?: InputMaybe<VehicleTypeWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  videoUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  videoUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  videoUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  videoUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  vin_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  vin_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  vin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  vin_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  vin_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  vin_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  vin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  vin_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  vin_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given json path. */
  warranty_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  warranty_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  weightMax?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  weightMax_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  weightMax_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  weightMax_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  weightMax_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  weightMax_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  weightMax_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  weightMax_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum VehicleOrderByInput {
  BatteryCapacityAsc = 'batteryCapacity_ASC',
  BatteryCapacityDesc = 'batteryCapacity_DESC',
  BatteryRangeAsc = 'batteryRange_ASC',
  BatteryRangeDesc = 'batteryRange_DESC',
  BuildYearAsc = 'buildYear_ASC',
  BuildYearDesc = 'buildYear_DESC',
  CcmAsc = 'ccm_ASC',
  CcmDesc = 'ccm_DESC',
  Co2Asc = 'co2_ASC',
  Co2Desc = 'co2_DESC',
  CommentAsc = 'comment_ASC',
  CommentDesc = 'comment_DESC',
  CommissionNumberAsc = 'commissionNumber_ASC',
  CommissionNumberDesc = 'commissionNumber_DESC',
  ConsumptionCityAsc = 'consumptionCity_ASC',
  ConsumptionCityDesc = 'consumptionCity_DESC',
  ConsumptionCombAsc = 'consumptionComb_ASC',
  ConsumptionCombDesc = 'consumptionComb_DESC',
  ConsumptionCountryAsc = 'consumptionCountry_ASC',
  ConsumptionCountryDesc = 'consumptionCountry_DESC',
  ConsumptionPowerAsc = 'consumptionPower_ASC',
  ConsumptionPowerDesc = 'consumptionPower_DESC',
  ContractOptionsAsc = 'contractOptions_ASC',
  ContractOptionsDesc = 'contractOptions_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  CylinderNumAsc = 'cylinderNum_ASC',
  CylinderNumDesc = 'cylinderNum_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DoorNumAsc = 'doorNum_ASC',
  DoorNumDesc = 'doorNum_DESC',
  ElectroKwAsc = 'electroKw_ASC',
  ElectroKwDesc = 'electroKw_DESC',
  EmptyWeightAsc = 'emptyWeight_ASC',
  EmptyWeightDesc = 'emptyWeight_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  EquipmentPackageAsc = 'equipmentPackage_ASC',
  EquipmentPackageDesc = 'equipmentPackage_DESC',
  FactoryCodeAsc = 'factoryCode_ASC',
  FactoryCodeDesc = 'factoryCode_DESC',
  FactoryExteriorColorCodeAsc = 'factoryExteriorColorCode_ASC',
  FactoryExteriorColorCodeDesc = 'factoryExteriorColorCode_DESC',
  FactoryExteriorColorNameAsc = 'factoryExteriorColorName_ASC',
  FactoryExteriorColorNameDesc = 'factoryExteriorColorName_DESC',
  FactoryInteriorColorCodeAsc = 'factoryInteriorColorCode_ASC',
  FactoryInteriorColorCodeDesc = 'factoryInteriorColorCode_DESC',
  FactoryInteriorColorNameAsc = 'factoryInteriorColorName_ASC',
  FactoryInteriorColorNameDesc = 'factoryInteriorColorName_DESC',
  FirstRegistrationAsc = 'firstRegistration_ASC',
  FirstRegistrationDesc = 'firstRegistration_DESC',
  GearNumAsc = 'gearNum_ASC',
  GearNumDesc = 'gearNum_DESC',
  HadAccidentAsc = 'hadAccident_ASC',
  HadAccidentDesc = 'hadAccident_DESC',
  HpTotalAsc = 'hpTotal_ASC',
  HpTotalDesc = 'hpTotal_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageUrlsAsc = 'imageUrls_ASC',
  ImageUrlsDesc = 'imageUrls_DESC',
  KmAsc = 'km_ASC',
  KmDesc = 'km_DESC',
  KwAsc = 'kw_ASC',
  KwDesc = 'kw_DESC',
  LastInspectionDateAsc = 'lastInspectionDate_ASC',
  LastInspectionDateDesc = 'lastInspectionDate_DESC',
  LastInspectionAsc = 'lastInspection_ASC',
  LastInspectionDesc = 'lastInspection_DESC',
  LeasingRateFromAsc = 'leasingRateFrom_ASC',
  LeasingRateFromDesc = 'leasingRateFrom_DESC',
  MarketplacesAsc = 'marketplaces_ASC',
  MarketplacesDesc = 'marketplaces_DESC',
  PriceB2cGrossAsc = 'priceB2cGross_ASC',
  PriceB2cGrossDesc = 'priceB2cGross_DESC',
  PriceDownpaymentGrossAsc = 'priceDownpaymentGross_ASC',
  PriceDownpaymentGrossDesc = 'priceDownpaymentGross_DESC',
  PriceInitialB2cGrossAsc = 'priceInitialB2cGross_ASC',
  PriceInitialB2cGrossDesc = 'priceInitialB2cGross_DESC',
  PriceNewTotalGrossAsc = 'priceNewTotalGross_ASC',
  PriceNewTotalGrossDesc = 'priceNewTotalGross_DESC',
  PricePreparationGrossAsc = 'pricePreparationGross_ASC',
  PricePreparationGrossDesc = 'pricePreparationGross_DESC',
  ProductionDateAsc = 'productionDate_ASC',
  ProductionDateDesc = 'productionDate_DESC',
  PsAsc = 'ps_ASC',
  PsDesc = 'ps_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReservedAsc = 'reserved_ASC',
  ReservedDesc = 'reserved_DESC',
  SeatNumAsc = 'seatNum_ASC',
  SeatNumDesc = 'seatNum_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TeaserAsc = 'teaser_ASC',
  TeaserDesc = 'teaser_DESC',
  TitleFacetAsc = 'titleFacet_ASC',
  TitleFacetDesc = 'titleFacet_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TrailerWeightMaxAsc = 'trailerWeightMax_ASC',
  TrailerWeightMaxDesc = 'trailerWeightMax_DESC',
  TypenscheinAsc = 'typenschein_ASC',
  TypenscheinDesc = 'typenschein_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VehicleIdAsc = 'vehicleId_ASC',
  VehicleIdDesc = 'vehicleId_DESC',
  VideoUrlsAsc = 'videoUrls_ASC',
  VideoUrlsDesc = 'videoUrls_DESC',
  VinAsc = 'vin_ASC',
  VinDesc = 'vin_DESC',
  WeightMaxAsc = 'weightMax_ASC',
  WeightMaxDesc = 'weightMax_DESC'
}

export type VehiclePromotion = Entity & {
  __typename?: 'VehiclePromotion';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId?: Maybe<Scalars['Int']['output']>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehiclePromotion>;
  name?: Maybe<Scalars['String']['output']>;
  paPromotion?: Maybe<PaPromotion>;
  paPromotionComponentId?: Maybe<Scalars['Int']['output']>;
  priceGross: Scalars['Float']['output'];
  /** System stage field */
  stage: Stage;
};


export type VehiclePromotionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type VehiclePromotionPaPromotionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VehiclePromotionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehiclePromotionWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehiclePromotionConnection = {
  __typename?: 'VehiclePromotionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehiclePromotionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehiclePromotionCreateInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehiclePromotionCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  paPromotion?: InputMaybe<PaPromotionCreateOneInlineInput>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  priceGross: Scalars['Float']['input'];
};

export type VehiclePromotionCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehiclePromotionCreateLocalizationInput = {
  /** Localization input */
  data: VehiclePromotionCreateLocalizationDataInput;
  locale: Locale;
};

export type VehiclePromotionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehiclePromotionCreateLocalizationInput>>;
};

export type VehiclePromotionCreateManyInlineInput = {
  /** Create and connect multiple existing VehiclePromotion documents */
  create?: InputMaybe<Array<VehiclePromotionCreateInput>>;
};

export type VehiclePromotionCreateOneInlineInput = {
  /** Create and connect one VehiclePromotion document */
  create?: InputMaybe<VehiclePromotionCreateInput>;
};

export type VehiclePromotionCreateWithPositionInput = {
  /** Document to create */
  data: VehiclePromotionCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VehiclePromotionEdge = {
  __typename?: 'VehiclePromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehiclePromotion;
};

export type VehiclePromotionList = Entity & {
  __typename?: 'VehiclePromotionList';
  /** The unique identifier */
  id: Scalars['ID']['output'];
  promotions: Array<VehiclePromotion>;
  /** System stage field */
  stage: Stage;
};


export type VehiclePromotionListPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<VehiclePromotionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<VehiclePromotionWhereInput>;
};

export type VehiclePromotionListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehiclePromotionListWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehiclePromotionListConnection = {
  __typename?: 'VehiclePromotionListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehiclePromotionListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehiclePromotionListCreateInput = {
  promotions?: InputMaybe<VehiclePromotionCreateManyInlineInput>;
};

export type VehiclePromotionListCreateManyInlineInput = {
  /** Create and connect multiple existing VehiclePromotionList documents */
  create?: InputMaybe<Array<VehiclePromotionListCreateInput>>;
};

export type VehiclePromotionListCreateOneInlineInput = {
  /** Create and connect one VehiclePromotionList document */
  create?: InputMaybe<VehiclePromotionListCreateInput>;
};

export type VehiclePromotionListCreateWithPositionInput = {
  /** Document to create */
  data: VehiclePromotionListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VehiclePromotionListEdge = {
  __typename?: 'VehiclePromotionListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehiclePromotionList;
};

/** Identifies documents */
export type VehiclePromotionListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  promotions_every?: InputMaybe<VehiclePromotionWhereInput>;
  promotions_none?: InputMaybe<VehiclePromotionWhereInput>;
  promotions_some?: InputMaybe<VehiclePromotionWhereInput>;
};

export enum VehiclePromotionListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC'
}

export type VehiclePromotionListParent = Vehicle;

export type VehiclePromotionListParentConnectInput = {
  Vehicle?: InputMaybe<VehicleConnectInput>;
};

export type VehiclePromotionListParentCreateInput = {
  Vehicle?: InputMaybe<VehicleCreateInput>;
};

export type VehiclePromotionListParentCreateManyInlineInput = {
  /** Connect multiple existing VehiclePromotionListParent documents */
  connect?: InputMaybe<Array<VehiclePromotionListParentWhereUniqueInput>>;
  /** Create and connect multiple existing VehiclePromotionListParent documents */
  create?: InputMaybe<Array<VehiclePromotionListParentCreateInput>>;
};

export type VehiclePromotionListParentCreateOneInlineInput = {
  /** Connect one existing VehiclePromotionListParent document */
  connect?: InputMaybe<VehiclePromotionListParentWhereUniqueInput>;
  /** Create and connect one VehiclePromotionListParent document */
  create?: InputMaybe<VehiclePromotionListParentCreateInput>;
};

export type VehiclePromotionListParentUpdateInput = {
  Vehicle?: InputMaybe<VehicleUpdateInput>;
};

export type VehiclePromotionListParentUpdateManyInlineInput = {
  /** Connect multiple existing VehiclePromotionListParent documents */
  connect?: InputMaybe<Array<VehiclePromotionListParentConnectInput>>;
  /** Create and connect multiple VehiclePromotionListParent documents */
  create?: InputMaybe<Array<VehiclePromotionListParentCreateInput>>;
  /** Delete multiple VehiclePromotionListParent documents */
  delete?: InputMaybe<Array<VehiclePromotionListParentWhereUniqueInput>>;
  /** Disconnect multiple VehiclePromotionListParent documents */
  disconnect?: InputMaybe<Array<VehiclePromotionListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehiclePromotionListParent documents */
  set?: InputMaybe<Array<VehiclePromotionListParentWhereUniqueInput>>;
  /** Update multiple VehiclePromotionListParent documents */
  update?: InputMaybe<Array<VehiclePromotionListParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehiclePromotionListParent documents */
  upsert?: InputMaybe<Array<VehiclePromotionListParentUpsertWithNestedWhereUniqueInput>>;
};

export type VehiclePromotionListParentUpdateManyWithNestedWhereInput = {
  Vehicle?: InputMaybe<VehicleUpdateManyWithNestedWhereInput>;
};

export type VehiclePromotionListParentUpdateOneInlineInput = {
  /** Connect existing VehiclePromotionListParent document */
  connect?: InputMaybe<VehiclePromotionListParentWhereUniqueInput>;
  /** Create and connect one VehiclePromotionListParent document */
  create?: InputMaybe<VehiclePromotionListParentCreateInput>;
  /** Delete currently connected VehiclePromotionListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehiclePromotionListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehiclePromotionListParent document */
  update?: InputMaybe<VehiclePromotionListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehiclePromotionListParent document */
  upsert?: InputMaybe<VehiclePromotionListParentUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionListParentUpdateWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpdateWithNestedWhereUniqueInput>;
};

export type VehiclePromotionListParentUpsertWithNestedWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionListParentWhereInput = {
  Vehicle?: InputMaybe<VehicleWhereInput>;
};

export type VehiclePromotionListParentWhereUniqueInput = {
  Vehicle?: InputMaybe<VehicleWhereUniqueInput>;
};

export type VehiclePromotionListUpdateInput = {
  promotions?: InputMaybe<VehiclePromotionUpdateManyInlineInput>;
};

export type VehiclePromotionListUpdateManyInlineInput = {
  /** Create and connect multiple VehiclePromotionList component instances */
  create?: InputMaybe<Array<VehiclePromotionListCreateWithPositionInput>>;
  /** Delete multiple VehiclePromotionList documents */
  delete?: InputMaybe<Array<VehiclePromotionListWhereUniqueInput>>;
  /** Update multiple VehiclePromotionList component instances */
  update?: InputMaybe<Array<VehiclePromotionListUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehiclePromotionList component instances */
  upsert?: InputMaybe<Array<VehiclePromotionListUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehiclePromotionListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>;
};

export type VehiclePromotionListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehiclePromotionListUpdateManyInput;
  /** Document search */
  where: VehiclePromotionListWhereInput;
};

export type VehiclePromotionListUpdateOneInlineInput = {
  /** Create and connect one VehiclePromotionList document */
  create?: InputMaybe<VehiclePromotionListCreateInput>;
  /** Delete currently connected VehiclePromotionList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehiclePromotionList document */
  update?: InputMaybe<VehiclePromotionListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehiclePromotionList document */
  upsert?: InputMaybe<VehiclePromotionListUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VehiclePromotionListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehiclePromotionListWhereUniqueInput;
};

export type VehiclePromotionListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehiclePromotionListUpdateInput;
  /** Unique document search */
  where: VehiclePromotionListWhereUniqueInput;
};

export type VehiclePromotionListUpsertInput = {
  /** Create document if it didn't exist */
  create: VehiclePromotionListCreateInput;
  /** Update document if it exists */
  update: VehiclePromotionListUpdateInput;
};

export type VehiclePromotionListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VehiclePromotionListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehiclePromotionListWhereUniqueInput;
};

export type VehiclePromotionListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehiclePromotionListUpsertInput;
  /** Unique document search */
  where: VehiclePromotionListWhereUniqueInput;
};

/** Identifies documents */
export type VehiclePromotionListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehiclePromotionListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  promotions_every?: InputMaybe<VehiclePromotionWhereInput>;
  promotions_none?: InputMaybe<VehiclePromotionWhereInput>;
  promotions_some?: InputMaybe<VehiclePromotionWhereInput>;
};

/** References VehiclePromotionList record uniquely */
export type VehiclePromotionListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Identifies documents */
export type VehiclePromotionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  paPromotion?: InputMaybe<PaPromotionWhereInput>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  paPromotionComponentId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  paPromotionComponentId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  paPromotionComponentId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  paPromotionComponentId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  paPromotionComponentId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  paPromotionComponentId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  paPromotionComponentId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export enum VehiclePromotionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PaPromotionComponentIdAsc = 'paPromotionComponentId_ASC',
  PaPromotionComponentIdDesc = 'paPromotionComponentId_DESC',
  PriceGrossAsc = 'priceGross_ASC',
  PriceGrossDesc = 'priceGross_DESC'
}

export type VehiclePromotionParent = VehiclePromotionList;

export type VehiclePromotionParentConnectInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListConnectInput>;
};

export type VehiclePromotionParentCreateInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListCreateInput>;
};

export type VehiclePromotionParentCreateManyInlineInput = {
  /** Create and connect multiple existing VehiclePromotionParent documents */
  create?: InputMaybe<Array<VehiclePromotionParentCreateInput>>;
};

export type VehiclePromotionParentCreateOneInlineInput = {
  /** Create and connect one VehiclePromotionParent document */
  create?: InputMaybe<VehiclePromotionParentCreateInput>;
};

export type VehiclePromotionParentCreateWithPositionInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListCreateWithPositionInput>;
};

export type VehiclePromotionParentUpdateInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpdateInput>;
};

export type VehiclePromotionParentUpdateManyInlineInput = {
  /** Create and connect multiple VehiclePromotionParent component instances */
  create?: InputMaybe<Array<VehiclePromotionParentCreateWithPositionInput>>;
  /** Delete multiple VehiclePromotionParent documents */
  delete?: InputMaybe<Array<VehiclePromotionParentWhereUniqueInput>>;
  /** Update multiple VehiclePromotionParent component instances */
  update?: InputMaybe<Array<VehiclePromotionParentUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehiclePromotionParent component instances */
  upsert?: InputMaybe<Array<VehiclePromotionParentUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehiclePromotionParentUpdateManyWithNestedWhereInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpdateManyWithNestedWhereInput>;
};

export type VehiclePromotionParentUpdateOneInlineInput = {
  /** Create and connect one VehiclePromotionParent document */
  create?: InputMaybe<VehiclePromotionParentCreateInput>;
  /** Delete currently connected VehiclePromotionParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehiclePromotionParent document */
  update?: InputMaybe<VehiclePromotionParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehiclePromotionParent document */
  upsert?: InputMaybe<VehiclePromotionParentUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionParentUpdateWithNestedWhereUniqueAndPositionInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type VehiclePromotionParentUpdateWithNestedWhereUniqueInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpdateWithNestedWhereUniqueInput>;
};

export type VehiclePromotionParentUpsertWithNestedWhereUniqueAndPositionInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type VehiclePromotionParentUpsertWithNestedWhereUniqueInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionParentWhereInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListWhereInput>;
};

export type VehiclePromotionParentWhereUniqueInput = {
  VehiclePromotionList?: InputMaybe<VehiclePromotionListWhereUniqueInput>;
};

export type VehiclePromotionUpdateInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehiclePromotionUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  paPromotion?: InputMaybe<PaPromotionUpdateOneInlineInput>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
};

export type VehiclePromotionUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehiclePromotionUpdateLocalizationInput = {
  data: VehiclePromotionUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehiclePromotionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehiclePromotionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehiclePromotionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehiclePromotionUpsertLocalizationInput>>;
};

export type VehiclePromotionUpdateManyInlineInput = {
  /** Create and connect multiple VehiclePromotion component instances */
  create?: InputMaybe<Array<VehiclePromotionCreateWithPositionInput>>;
  /** Delete multiple VehiclePromotion documents */
  delete?: InputMaybe<Array<VehiclePromotionWhereUniqueInput>>;
  /** Update multiple VehiclePromotion component instances */
  update?: InputMaybe<Array<VehiclePromotionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VehiclePromotion component instances */
  upsert?: InputMaybe<Array<VehiclePromotionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VehiclePromotionUpdateManyInput = {
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehiclePromotionUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
};

export type VehiclePromotionUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VehiclePromotionUpdateManyLocalizationInput = {
  data: VehiclePromotionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehiclePromotionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehiclePromotionUpdateManyLocalizationInput>>;
};

export type VehiclePromotionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehiclePromotionUpdateManyInput;
  /** Document search */
  where: VehiclePromotionWhereInput;
};

export type VehiclePromotionUpdateOneInlineInput = {
  /** Create and connect one VehiclePromotion document */
  create?: InputMaybe<VehiclePromotionCreateInput>;
  /** Delete currently connected VehiclePromotion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehiclePromotion document */
  update?: InputMaybe<VehiclePromotionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehiclePromotion document */
  upsert?: InputMaybe<VehiclePromotionUpsertWithNestedWhereUniqueInput>;
};

export type VehiclePromotionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VehiclePromotionUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehiclePromotionWhereUniqueInput;
};

export type VehiclePromotionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehiclePromotionUpdateInput;
  /** Unique document search */
  where: VehiclePromotionWhereUniqueInput;
};

export type VehiclePromotionUpsertInput = {
  /** Create document if it didn't exist */
  create: VehiclePromotionCreateInput;
  /** Update document if it exists */
  update: VehiclePromotionUpdateInput;
};

export type VehiclePromotionUpsertLocalizationInput = {
  create: VehiclePromotionCreateLocalizationDataInput;
  locale: Locale;
  update: VehiclePromotionUpdateLocalizationDataInput;
};

export type VehiclePromotionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VehiclePromotionUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VehiclePromotionWhereUniqueInput;
};

export type VehiclePromotionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehiclePromotionUpsertInput;
  /** Unique document search */
  where: VehiclePromotionWhereUniqueInput;
};

/** Identifies documents */
export type VehiclePromotionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehiclePromotionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  paPromotion?: InputMaybe<PaPromotionWhereInput>;
  paPromotionComponentId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  paPromotionComponentId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  paPromotionComponentId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  paPromotionComponentId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  paPromotionComponentId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  paPromotionComponentId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  paPromotionComponentId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  paPromotionComponentId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  priceGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

/** References VehiclePromotion record uniquely */
export type VehiclePromotionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatus = Entity & Node & {
  __typename?: 'VehicleStatus';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<VehicleStatus>;
  /** List of VehicleStatus versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleStatus>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** STOCK, ORDER, PIPELINE, etc. */
export type VehicleStatusUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VehicleStatusConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleStatusWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleStatusConnection = {
  __typename?: 'VehicleStatusConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleStatusEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleStatusCreateInput = {
  cl16j15iehxx001za0czf9enr?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleStatusCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleStatusCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleStatusCreateLocalizationInput = {
  /** Localization input */
  data: VehicleStatusCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleStatusCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleStatusCreateLocalizationInput>>;
};

export type VehicleStatusCreateManyInlineInput = {
  /** Connect multiple existing VehicleStatus documents */
  connect?: InputMaybe<Array<VehicleStatusWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleStatus documents */
  create?: InputMaybe<Array<VehicleStatusCreateInput>>;
};

export type VehicleStatusCreateOneInlineInput = {
  /** Connect one existing VehicleStatus document */
  connect?: InputMaybe<VehicleStatusWhereUniqueInput>;
  /** Create and connect one VehicleStatus document */
  create?: InputMaybe<VehicleStatusCreateInput>;
};

/** An edge in a connection. */
export type VehicleStatusEdge = {
  __typename?: 'VehicleStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleStatus;
};

/** Identifies documents */
export type VehicleStatusManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleStatusWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleStatusWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleStatusWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum VehicleStatusOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VehicleStatusUpdateInput = {
  cl16j15iehxx001za0czf9enr?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleStatusUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleStatusUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleStatusUpdateLocalizationInput = {
  data: VehicleStatusUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleStatusUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleStatusCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleStatusUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleStatusUpsertLocalizationInput>>;
};

export type VehicleStatusUpdateManyInlineInput = {
  /** Connect multiple existing VehicleStatus documents */
  connect?: InputMaybe<Array<VehicleStatusConnectInput>>;
  /** Create and connect multiple VehicleStatus documents */
  create?: InputMaybe<Array<VehicleStatusCreateInput>>;
  /** Delete multiple VehicleStatus documents */
  delete?: InputMaybe<Array<VehicleStatusWhereUniqueInput>>;
  /** Disconnect multiple VehicleStatus documents */
  disconnect?: InputMaybe<Array<VehicleStatusWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleStatus documents */
  set?: InputMaybe<Array<VehicleStatusWhereUniqueInput>>;
  /** Update multiple VehicleStatus documents */
  update?: InputMaybe<Array<VehicleStatusUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleStatus documents */
  upsert?: InputMaybe<Array<VehicleStatusUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleStatusUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleStatusUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleStatusUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleStatusUpdateManyLocalizationInput = {
  data: VehicleStatusUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleStatusUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleStatusUpdateManyLocalizationInput>>;
};

export type VehicleStatusUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleStatusUpdateManyInput;
  /** Document search */
  where: VehicleStatusWhereInput;
};

export type VehicleStatusUpdateOneInlineInput = {
  /** Connect existing VehicleStatus document */
  connect?: InputMaybe<VehicleStatusWhereUniqueInput>;
  /** Create and connect one VehicleStatus document */
  create?: InputMaybe<VehicleStatusCreateInput>;
  /** Delete currently connected VehicleStatus document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleStatus document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleStatus document */
  update?: InputMaybe<VehicleStatusUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleStatus document */
  upsert?: InputMaybe<VehicleStatusUpsertWithNestedWhereUniqueInput>;
};

export type VehicleStatusUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleStatusUpdateInput;
  /** Unique document search */
  where: VehicleStatusWhereUniqueInput;
};

export type VehicleStatusUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleStatusCreateInput;
  /** Update document if it exists */
  update: VehicleStatusUpdateInput;
};

export type VehicleStatusUpsertLocalizationInput = {
  create: VehicleStatusCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleStatusUpdateLocalizationDataInput;
};

export type VehicleStatusUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleStatusUpsertInput;
  /** Unique document search */
  where: VehicleStatusWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VehicleStatusWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VehicleStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleStatusWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleStatusWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleStatusWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleStatusWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VehicleStatusWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleStatusWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleStatusWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleStatusWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VehicleStatusWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References VehicleStatus record uniquely */
export type VehicleStatusWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

/** Car, Commercial, etc. */
export type VehicleType = Entity & Node & {
  __typename?: 'VehicleType';
  code?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<VehicleType>;
  /** List of VehicleType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  insideId: Scalars['Int']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VehicleType>;
  name: Scalars['String']['output'];
  nameNormalized?: Maybe<Scalars['String']['output']>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};


/** Car, Commercial, etc. */
export type VehicleTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Car, Commercial, etc. */
export type VehicleTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Car, Commercial, etc. */
export type VehicleTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


/** Car, Commercial, etc. */
export type VehicleTypeHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


/** Car, Commercial, etc. */
export type VehicleTypeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


/** Car, Commercial, etc. */
export type VehicleTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Car, Commercial, etc. */
export type VehicleTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


/** Car, Commercial, etc. */
export type VehicleTypeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


/** Car, Commercial, etc. */
export type VehicleTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


/** Car, Commercial, etc. */
export type VehicleTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VehicleTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VehicleTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type VehicleTypeConnection = {
  __typename?: 'VehicleTypeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VehicleTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VehicleTypeCreateInput = {
  cl16j1zpghwyu01z1gi4h1r79?: InputMaybe<VehicleCreateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  insideId: Scalars['Int']['input'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VehicleTypeCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars['String']['input'];
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VehicleTypeCreateLocalizationInput = {
  /** Localization input */
  data: VehicleTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type VehicleTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VehicleTypeCreateLocalizationInput>>;
};

export type VehicleTypeCreateManyInlineInput = {
  /** Connect multiple existing VehicleType documents */
  connect?: InputMaybe<Array<VehicleTypeWhereUniqueInput>>;
  /** Create and connect multiple existing VehicleType documents */
  create?: InputMaybe<Array<VehicleTypeCreateInput>>;
};

export type VehicleTypeCreateOneInlineInput = {
  /** Connect one existing VehicleType document */
  connect?: InputMaybe<VehicleTypeWhereUniqueInput>;
  /** Create and connect one VehicleType document */
  create?: InputMaybe<VehicleTypeCreateInput>;
};

/** An edge in a connection. */
export type VehicleTypeEdge = {
  __typename?: 'VehicleTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VehicleType;
};

/** Identifies documents */
export type VehicleTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum VehicleTypeOrderByInput {
  CodeAsc = 'code_ASC',
  CodeDesc = 'code_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InsideIdAsc = 'insideId_ASC',
  InsideIdDesc = 'insideId_DESC',
  NameNormalizedAsc = 'nameNormalized_ASC',
  NameNormalizedDesc = 'nameNormalized_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC'
}

export type VehicleTypeUpdateInput = {
  cl16j1zpghwyu01z1gi4h1r79?: InputMaybe<VehicleUpdateManyInlineInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleTypeUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleTypeUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleTypeUpdateLocalizationInput = {
  data: VehicleTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleTypeUpsertLocalizationInput>>;
};

export type VehicleTypeUpdateManyInlineInput = {
  /** Connect multiple existing VehicleType documents */
  connect?: InputMaybe<Array<VehicleTypeConnectInput>>;
  /** Create and connect multiple VehicleType documents */
  create?: InputMaybe<Array<VehicleTypeCreateInput>>;
  /** Delete multiple VehicleType documents */
  delete?: InputMaybe<Array<VehicleTypeWhereUniqueInput>>;
  /** Disconnect multiple VehicleType documents */
  disconnect?: InputMaybe<Array<VehicleTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VehicleType documents */
  set?: InputMaybe<Array<VehicleTypeWhereUniqueInput>>;
  /** Update multiple VehicleType documents */
  update?: InputMaybe<Array<VehicleTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VehicleType documents */
  upsert?: InputMaybe<Array<VehicleTypeUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleTypeUpdateManyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleTypeUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** nameNormalized input for default locale (de) */
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleTypeUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleTypeUpdateManyLocalizationInput = {
  data: VehicleTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleTypeUpdateManyLocalizationInput>>;
};

export type VehicleTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleTypeUpdateManyInput;
  /** Document search */
  where: VehicleTypeWhereInput;
};

export type VehicleTypeUpdateOneInlineInput = {
  /** Connect existing VehicleType document */
  connect?: InputMaybe<VehicleTypeWhereUniqueInput>;
  /** Create and connect one VehicleType document */
  create?: InputMaybe<VehicleTypeCreateInput>;
  /** Delete currently connected VehicleType document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected VehicleType document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VehicleType document */
  update?: InputMaybe<VehicleTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VehicleType document */
  upsert?: InputMaybe<VehicleTypeUpsertWithNestedWhereUniqueInput>;
};

export type VehicleTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleTypeUpdateInput;
  /** Unique document search */
  where: VehicleTypeWhereUniqueInput;
};

export type VehicleTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleTypeCreateInput;
  /** Update document if it exists */
  update: VehicleTypeUpdateInput;
};

export type VehicleTypeUpsertLocalizationInput = {
  create: VehicleTypeCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleTypeUpdateLocalizationDataInput;
};

export type VehicleTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleTypeUpsertInput;
  /** Unique document search */
  where: VehicleTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VehicleTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VehicleTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  code_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  code_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  code_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  code_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  code_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  code_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  code_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  code_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VehicleTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleTypeWhereStageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  insideId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  insideId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  insideId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  insideId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  insideId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  insideId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  insideId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameNormalized?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  nameNormalized_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  nameNormalized_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  nameNormalized_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  nameNormalized_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  nameNormalized_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  nameNormalized_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  nameNormalized_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  nameNormalized_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  nameNormalized_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VehicleTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VehicleTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References VehicleType record uniquely */
export type VehicleTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  insideId?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleUpdateInput = {
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  batteryRange?: InputMaybe<Scalars['Float']['input']>;
  bodyType?: InputMaybe<BodyTypeUpdateOneInlineInput>;
  brand?: InputMaybe<BrandUpdateOneInlineInput>;
  buildYear?: InputMaybe<Scalars['Int']['input']>;
  ccm?: InputMaybe<Scalars['Int']['input']>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commissionNumber?: InputMaybe<Scalars['String']['input']>;
  consumptionCity?: InputMaybe<Scalars['Float']['input']>;
  consumptionComb?: InputMaybe<Scalars['Float']['input']>;
  consumptionCountry?: InputMaybe<Scalars['Float']['input']>;
  consumptionPower?: InputMaybe<Scalars['Float']['input']>;
  contractOptions?: InputMaybe<Array<ContractOption>>;
  currency?: InputMaybe<Currency>;
  cylinderNum?: InputMaybe<Scalars['Int']['input']>;
  dealer?: InputMaybe<DealerUpdateOneInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  discountList?: InputMaybe<VehicleDiscountListUpdateOneInlineInput>;
  doorNum?: InputMaybe<Scalars['Int']['input']>;
  driveType?: InputMaybe<DriveTypeUpdateOneInlineInput>;
  electroKw?: InputMaybe<Scalars['Int']['input']>;
  emissionClass?: InputMaybe<EmissionClassUpdateOneInlineInput>;
  emptyWeight?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentPackage?: InputMaybe<Scalars['String']['input']>;
  exteriorColor?: InputMaybe<ColorNormUpdateOneInlineInput>;
  exteriorColorType?: InputMaybe<ColorTypeUpdateOneInlineInput>;
  factoryCode?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryExteriorColorName input for default locale (de) */
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryInteriorColorName input for default locale (de) */
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  fuelType?: InputMaybe<FuelTypeUpdateOneInlineInput>;
  gearNum?: InputMaybe<Scalars['Int']['input']>;
  hadAccident?: InputMaybe<Scalars['Boolean']['input']>;
  hpTotal?: InputMaybe<Scalars['Int']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  interiorColor?: InputMaybe<ColorNormUpdateOneInlineInput>;
  km?: InputMaybe<Scalars['Int']['input']>;
  kw?: InputMaybe<Scalars['Int']['input']>;
  lastInspection?: InputMaybe<Scalars['Boolean']['input']>;
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  leasingRateFrom?: InputMaybe<Scalars['Float']['input']>;
  leasingRateFromCalcBasis?: InputMaybe<VehicleLeasingRateCalculationUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<VehicleUpdateLocalizationsInput>;
  marketplaces?: InputMaybe<Array<Marketplace>>;
  model?: InputMaybe<ModelUpdateOneInlineInput>;
  optionFacets?: InputMaybe<OptionFacetUpdateManyInlineInput>;
  /** options input for default locale (de) */
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  pollutionClass?: InputMaybe<PollutionClassUpdateOneInlineInput>;
  priceB2cGross?: InputMaybe<Scalars['Float']['input']>;
  priceDownpaymentGross?: InputMaybe<Scalars['Float']['input']>;
  priceInitialB2cGross?: InputMaybe<Scalars['Float']['input']>;
  priceNewTotalGross?: InputMaybe<Scalars['Float']['input']>;
  pricePreparationGross?: InputMaybe<Scalars['Float']['input']>;
  productionDate?: InputMaybe<Scalars['Date']['input']>;
  promotionList?: InputMaybe<VehiclePromotionListUpdateOneInlineInput>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  qualityChecked?: InputMaybe<QualityCheckedUpdateOneInlineInput>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
  seatNum?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** teaser input for default locale (de) */
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleFacet?: InputMaybe<Scalars['String']['input']>;
  trailerWeightMax?: InputMaybe<Scalars['Int']['input']>;
  transmission?: InputMaybe<TransmissionUpdateOneInlineInput>;
  typenschein?: InputMaybe<Scalars['String']['input']>;
  upholstery?: InputMaybe<UpholsteryUpdateOneInlineInput>;
  vehicleClass?: InputMaybe<VehicleClassUpdateOneInlineInput>;
  vehicleCondition?: InputMaybe<VehicleConditionUpdateOneInlineInput>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  vehicleStatus?: InputMaybe<VehicleStatusUpdateOneInlineInput>;
  vehicleType?: InputMaybe<VehicleTypeUpdateOneInlineInput>;
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Json']['input']>;
  weightMax?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  teaser?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleUpdateLocalizationInput = {
  data: VehicleUpdateLocalizationDataInput;
  locale: Locale;
};

export type VehicleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VehicleCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VehicleUpsertLocalizationInput>>;
};

export type VehicleUpdateManyInlineInput = {
  /** Connect multiple existing Vehicle documents */
  connect?: InputMaybe<Array<VehicleConnectInput>>;
  /** Create and connect multiple Vehicle documents */
  create?: InputMaybe<Array<VehicleCreateInput>>;
  /** Delete multiple Vehicle documents */
  delete?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  /** Disconnect multiple Vehicle documents */
  disconnect?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Vehicle documents */
  set?: InputMaybe<Array<VehicleWhereUniqueInput>>;
  /** Update multiple Vehicle documents */
  update?: InputMaybe<Array<VehicleUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Vehicle documents */
  upsert?: InputMaybe<Array<VehicleUpsertWithNestedWhereUniqueInput>>;
};

export type VehicleUpdateManyInput = {
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  batteryRange?: InputMaybe<Scalars['Float']['input']>;
  buildYear?: InputMaybe<Scalars['Int']['input']>;
  ccm?: InputMaybe<Scalars['Int']['input']>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  commissionNumber?: InputMaybe<Scalars['String']['input']>;
  consumptionCity?: InputMaybe<Scalars['Float']['input']>;
  consumptionComb?: InputMaybe<Scalars['Float']['input']>;
  consumptionCountry?: InputMaybe<Scalars['Float']['input']>;
  consumptionPower?: InputMaybe<Scalars['Float']['input']>;
  contractOptions?: InputMaybe<Array<ContractOption>>;
  currency?: InputMaybe<Currency>;
  cylinderNum?: InputMaybe<Scalars['Int']['input']>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>;
  doorNum?: InputMaybe<Scalars['Int']['input']>;
  electroKw?: InputMaybe<Scalars['Int']['input']>;
  emptyWeight?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentPackage?: InputMaybe<Scalars['String']['input']>;
  factoryCode?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryExteriorColorName input for default locale (de) */
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** factoryInteriorColorName input for default locale (de) */
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  gearNum?: InputMaybe<Scalars['Int']['input']>;
  hadAccident?: InputMaybe<Scalars['Boolean']['input']>;
  hpTotal?: InputMaybe<Scalars['Int']['input']>;
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  km?: InputMaybe<Scalars['Int']['input']>;
  kw?: InputMaybe<Scalars['Int']['input']>;
  lastInspection?: InputMaybe<Scalars['Boolean']['input']>;
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  leasingRateFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VehicleUpdateManyLocalizationsInput>;
  marketplaces?: InputMaybe<Array<Marketplace>>;
  /** options input for default locale (de) */
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  priceB2cGross?: InputMaybe<Scalars['Float']['input']>;
  priceDownpaymentGross?: InputMaybe<Scalars['Float']['input']>;
  priceInitialB2cGross?: InputMaybe<Scalars['Float']['input']>;
  priceNewTotalGross?: InputMaybe<Scalars['Float']['input']>;
  pricePreparationGross?: InputMaybe<Scalars['Float']['input']>;
  productionDate?: InputMaybe<Scalars['Date']['input']>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
  seatNum?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** teaser input for default locale (de) */
  teaser?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleFacet?: InputMaybe<Scalars['String']['input']>;
  trailerWeightMax?: InputMaybe<Scalars['Int']['input']>;
  typenschein?: InputMaybe<Scalars['String']['input']>;
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  warranty?: InputMaybe<Scalars['Json']['input']>;
  weightMax?: InputMaybe<Scalars['Int']['input']>;
};

export type VehicleUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Array<Scalars['Json']['input']>>;
  teaser?: InputMaybe<Scalars['String']['input']>;
};

export type VehicleUpdateManyLocalizationInput = {
  data: VehicleUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VehicleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VehicleUpdateManyLocalizationInput>>;
};

export type VehicleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VehicleUpdateManyInput;
  /** Document search */
  where: VehicleWhereInput;
};

export type VehicleUpdateOneInlineInput = {
  /** Connect existing Vehicle document */
  connect?: InputMaybe<VehicleWhereUniqueInput>;
  /** Create and connect one Vehicle document */
  create?: InputMaybe<VehicleCreateInput>;
  /** Delete currently connected Vehicle document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Vehicle document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Vehicle document */
  update?: InputMaybe<VehicleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Vehicle document */
  upsert?: InputMaybe<VehicleUpsertWithNestedWhereUniqueInput>;
};

export type VehicleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VehicleUpdateInput;
  /** Unique document search */
  where: VehicleWhereUniqueInput;
};

export type VehicleUpsertInput = {
  /** Create document if it didn't exist */
  create: VehicleCreateInput;
  /** Update document if it exists */
  update: VehicleUpdateInput;
};

export type VehicleUpsertLocalizationInput = {
  create: VehicleCreateLocalizationDataInput;
  locale: Locale;
  update: VehicleUpdateLocalizationDataInput;
};

export type VehicleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VehicleUpsertInput;
  /** Unique document search */
  where: VehicleWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VehicleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VehicleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  batteryCapacity?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  batteryCapacity_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  batteryCapacity_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  batteryCapacity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  batteryCapacity_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  batteryCapacity_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  batteryCapacity_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  batteryCapacity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  batteryRange?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  batteryRange_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  batteryRange_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  batteryRange_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  batteryRange_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  batteryRange_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  batteryRange_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  batteryRange_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  bodyType?: InputMaybe<BodyTypeWhereInput>;
  brand?: InputMaybe<BrandWhereInput>;
  buildYear?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  buildYear_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  buildYear_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  buildYear_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  buildYear_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  buildYear_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  buildYear_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  buildYear_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  ccm?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  ccm_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  ccm_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  ccm_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  ccm_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  ccm_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  ccm_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  ccm_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  co2?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  co2_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  co2_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  co2_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  co2_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  co2_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  co2_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  co2_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  comment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  comment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  comment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  comment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  comment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  comment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  comment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  comment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  comment_starts_with?: InputMaybe<Scalars['String']['input']>;
  commissionNumber?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  commissionNumber_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  commissionNumber_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  commissionNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  commissionNumber_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  commissionNumber_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  commissionNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  commissionNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  commissionNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  commissionNumber_starts_with?: InputMaybe<Scalars['String']['input']>;
  consumptionCity?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionCity_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionCity_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionCity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionCity_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionCity_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionCity_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionCity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionComb?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionComb_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionComb_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionComb_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionComb_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionComb_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionComb_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionComb_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionCountry?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionCountry_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionCountry_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionCountry_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionCountry_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionCountry_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionCountry_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionCountry_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  consumptionPower?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  consumptionPower_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  consumptionPower_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  consumptionPower_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  consumptionPower_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  consumptionPower_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  consumptionPower_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  consumptionPower_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  contractOptions?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array contains *all* items provided to the filter */
  contractOptions_contains_all?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  contractOptions_contains_none?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array contains at least one item provided to the filter */
  contractOptions_contains_some?: InputMaybe<Array<ContractOption>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  contractOptions_not?: InputMaybe<Array<ContractOption>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currency>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currency>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currency>>>;
  cylinderNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  cylinderNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  cylinderNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  cylinderNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  cylinderNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  cylinderNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  cylinderNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  cylinderNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  dealer?: InputMaybe<DealerWhereInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>;
  discountList?: InputMaybe<VehicleDiscountListWhereInput>;
  documentInStages_every?: InputMaybe<VehicleWhereStageInput>;
  documentInStages_none?: InputMaybe<VehicleWhereStageInput>;
  documentInStages_some?: InputMaybe<VehicleWhereStageInput>;
  doorNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  doorNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  doorNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  doorNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  doorNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  doorNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  doorNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  doorNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  driveType?: InputMaybe<DriveTypeWhereInput>;
  electroKw?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  electroKw_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  electroKw_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  electroKw_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  electroKw_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  electroKw_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  electroKw_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  electroKw_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  emissionClass?: InputMaybe<EmissionClassWhereInput>;
  emptyWeight?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  emptyWeight_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  emptyWeight_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  emptyWeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  emptyWeight_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  emptyWeight_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  emptyWeight_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  emptyWeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentPackage?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  equipmentPackage_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  equipmentPackage_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  equipmentPackage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  equipmentPackage_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  equipmentPackage_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  equipmentPackage_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  equipmentPackage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  equipmentPackage_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  equipmentPackage_starts_with?: InputMaybe<Scalars['String']['input']>;
  exteriorColor?: InputMaybe<ColorNormWhereInput>;
  exteriorColorType?: InputMaybe<ColorTypeWhereInput>;
  factoryCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryExteriorColorCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryExteriorColorCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryExteriorColorCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryExteriorColorCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryExteriorColorCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryExteriorColorCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryExteriorColorCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryExteriorColorCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryExteriorColorCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryExteriorColorName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryExteriorColorName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryExteriorColorName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryExteriorColorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryExteriorColorName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryExteriorColorName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryExteriorColorName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryExteriorColorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryExteriorColorName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryExteriorColorName_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorCode?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryInteriorColorCode_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryInteriorColorCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryInteriorColorCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryInteriorColorCode_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryInteriorColorCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryInteriorColorCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryInteriorColorCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryInteriorColorCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryInteriorColorCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  factoryInteriorColorName?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  factoryInteriorColorName_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  factoryInteriorColorName_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  factoryInteriorColorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  factoryInteriorColorName_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  factoryInteriorColorName_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  factoryInteriorColorName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  factoryInteriorColorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  factoryInteriorColorName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  factoryInteriorColorName_starts_with?: InputMaybe<Scalars['String']['input']>;
  firstRegistration?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  firstRegistration_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  firstRegistration_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  firstRegistration_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  firstRegistration_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  firstRegistration_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  firstRegistration_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  firstRegistration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  fuelType?: InputMaybe<FuelTypeWhereInput>;
  gearNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  gearNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  gearNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  gearNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  gearNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  gearNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  gearNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  gearNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hadAccident?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  hadAccident_not?: InputMaybe<Scalars['Boolean']['input']>;
  hpTotal?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  hpTotal_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  hpTotal_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  hpTotal_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  hpTotal_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  hpTotal_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  hpTotal_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  hpTotal_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  imageUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  imageUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  imageUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  imageUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  interiorColor?: InputMaybe<ColorNormWhereInput>;
  km?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  km_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  km_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  km_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  km_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  km_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  km_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  km_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  kw?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  kw_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  kw_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  kw_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  kw_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  kw_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  kw_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  kw_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lastInspection?: InputMaybe<Scalars['Boolean']['input']>;
  lastInspectionDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  lastInspectionDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  lastInspectionDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  lastInspectionDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  lastInspectionDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  lastInspectionDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  lastInspectionDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  lastInspectionDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  lastInspection_not?: InputMaybe<Scalars['Boolean']['input']>;
  leasingRateFrom?: InputMaybe<Scalars['Float']['input']>;
  leasingRateFromCalcBasis?: InputMaybe<VehicleLeasingRateCalculationWhereInput>;
  /** All values greater than the given value. */
  leasingRateFrom_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  leasingRateFrom_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  leasingRateFrom_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  leasingRateFrom_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  leasingRateFrom_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  leasingRateFrom_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  leasingRateFrom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  marketplaces?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array contains *all* items provided to the filter */
  marketplaces_contains_all?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  marketplaces_contains_none?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array contains at least one item provided to the filter */
  marketplaces_contains_some?: InputMaybe<Array<Marketplace>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  marketplaces_not?: InputMaybe<Array<Marketplace>>;
  model?: InputMaybe<ModelWhereInput>;
  optionFacets_every?: InputMaybe<OptionFacetWhereInput>;
  optionFacets_none?: InputMaybe<OptionFacetWhereInput>;
  optionFacets_some?: InputMaybe<OptionFacetWhereInput>;
  /** All values containing the given json path. */
  options_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  options_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  pollutionClass?: InputMaybe<PollutionClassWhereInput>;
  priceB2cGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceB2cGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceB2cGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceB2cGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceB2cGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceB2cGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceB2cGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceB2cGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceDownpaymentGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceDownpaymentGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceDownpaymentGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceDownpaymentGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceDownpaymentGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceDownpaymentGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceDownpaymentGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceDownpaymentGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceInitialB2cGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceInitialB2cGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceInitialB2cGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceInitialB2cGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceInitialB2cGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceInitialB2cGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceInitialB2cGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceInitialB2cGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  priceNewTotalGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  priceNewTotalGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  priceNewTotalGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  priceNewTotalGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  priceNewTotalGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  priceNewTotalGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  priceNewTotalGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  priceNewTotalGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  pricePreparationGross?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than the given value. */
  pricePreparationGross_gt?: InputMaybe<Scalars['Float']['input']>;
  /** All values greater than or equal the given value. */
  pricePreparationGross_gte?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are contained in given list. */
  pricePreparationGross_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  /** All values less than the given value. */
  pricePreparationGross_lt?: InputMaybe<Scalars['Float']['input']>;
  /** All values less than or equal the given value. */
  pricePreparationGross_lte?: InputMaybe<Scalars['Float']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  pricePreparationGross_not?: InputMaybe<Scalars['Float']['input']>;
  /** All values that are not contained in given list. */
  pricePreparationGross_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  productionDate?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than the given value. */
  productionDate_gt?: InputMaybe<Scalars['Date']['input']>;
  /** All values greater than or equal the given value. */
  productionDate_gte?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are contained in given list. */
  productionDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  /** All values less than the given value. */
  productionDate_lt?: InputMaybe<Scalars['Date']['input']>;
  /** All values less than or equal the given value. */
  productionDate_lte?: InputMaybe<Scalars['Date']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  productionDate_not?: InputMaybe<Scalars['Date']['input']>;
  /** All values that are not contained in given list. */
  productionDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  promotionList?: InputMaybe<VehiclePromotionListWhereInput>;
  ps?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  ps_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  ps_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  ps_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  ps_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  ps_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  ps_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  ps_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  qualityChecked?: InputMaybe<QualityCheckedWhereInput>;
  reserved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  reserved_not?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seatNum?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  seatNum_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  seatNum_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  seatNum_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  seatNum_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  seatNum_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  seatNum_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  seatNum_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  teaser_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  teaser_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  teaser_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  teaser_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  teaser_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  teaser_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  teaser_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  teaser_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  teaser_starts_with?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleFacet?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  titleFacet_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  titleFacet_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  titleFacet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  titleFacet_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  titleFacet_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  titleFacet_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  titleFacet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  titleFacet_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  titleFacet_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>;
  trailerWeightMax?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  trailerWeightMax_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  trailerWeightMax_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  trailerWeightMax_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  trailerWeightMax_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  trailerWeightMax_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  trailerWeightMax_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  trailerWeightMax_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  transmission?: InputMaybe<TransmissionWhereInput>;
  typenschein?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  typenschein_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  typenschein_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  typenschein_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  typenschein_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  typenschein_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  typenschein_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  typenschein_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  typenschein_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  typenschein_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  upholstery?: InputMaybe<UpholsteryWhereInput>;
  vehicleClass?: InputMaybe<VehicleClassWhereInput>;
  vehicleCondition?: InputMaybe<VehicleConditionWhereInput>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  vehicleId_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  vehicleId_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  vehicleId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  vehicleId_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  vehicleId_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  vehicleId_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  vehicleId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  vehicleStatus?: InputMaybe<VehicleStatusWhereInput>;
  vehicleType?: InputMaybe<VehicleTypeWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  videoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  videoUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  videoUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  videoUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  videoUrls_not?: InputMaybe<Array<Scalars['String']['input']>>;
  vin?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  vin_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  vin_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  vin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  vin_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  vin_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  vin_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  vin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  vin_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  vin_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given json path. */
  warranty_json_path_exists?: InputMaybe<Scalars['String']['input']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  warranty_value_recursive?: InputMaybe<Scalars['Json']['input']>;
  weightMax?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than the given value. */
  weightMax_gt?: InputMaybe<Scalars['Int']['input']>;
  /** All values greater than or equal the given value. */
  weightMax_gte?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are contained in given list. */
  weightMax_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  /** All values less than the given value. */
  weightMax_lt?: InputMaybe<Scalars['Int']['input']>;
  /** All values less than or equal the given value. */
  weightMax_lte?: InputMaybe<Scalars['Int']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  weightMax_not?: InputMaybe<Scalars['Int']['input']>;
  /** All values that are not contained in given list. */
  weightMax_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VehicleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VehicleWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VehicleWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VehicleWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VehicleWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Vehicle record uniquely */
export type VehicleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  revision: Scalars['Int']['output'];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars['ID']['input'];
  revision: Scalars['Int']['input'];
  stage: Stage;
};

export type Video = Entity & Node & {
  __typename?: 'Video';
  caption?: Maybe<Scalars['String']['output']>;
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Video>;
  /** Only in CMS. Content is not rendered. */
  editorComment?: Maybe<Scalars['String']['output']>;
  galleries: Array<Gallery>;
  /** List of Video versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Video>;
  media?: Maybe<VideomediaUnion>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  wideInContent: Scalars['Boolean']['output'];
};


export type VideoCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VideoCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VideoDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type VideoGalleriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GalleryWhereInput>;
};


export type VideoHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type VideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  locales?: Array<Locale>;
};


export type VideoMediaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VideoPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VideoPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type VideoScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type VideoUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};


export type VideoUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VideoCreateInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  galleries?: InputMaybe<GalleryCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VideoCreateLocalizationsInput>;
  media?: InputMaybe<VideomediaUnionCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wideInContent: Scalars['Boolean']['input'];
};

export type VideoCreateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type VideoCreateLocalizationInput = {
  /** Localization input */
  data: VideoCreateLocalizationDataInput;
  locale: Locale;
};

export type VideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VideoCreateLocalizationInput>>;
};

export type VideoCreateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Create and connect multiple existing Video documents */
  create?: InputMaybe<Array<VideoCreateInput>>;
};

export type VideoCreateOneInlineInput = {
  /** Connect one existing Video document */
  connect?: InputMaybe<VideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
};

/** An edge in a connection. */
export type VideoEdge = {
  __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Video;
};

/** Identifies documents */
export type VideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VideoWhereStageInput>;
  documentInStages_none?: InputMaybe<VideoWhereStageInput>;
  documentInStages_some?: InputMaybe<VideoWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  galleries_every?: InputMaybe<GalleryWhereInput>;
  galleries_none?: InputMaybe<GalleryWhereInput>;
  galleries_some?: InputMaybe<GalleryWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  media?: InputMaybe<VideomediaUnionWhereInput>;
  /** All values in which the union is empty. */
  media_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoOrderByInput {
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EditorCommentAsc = 'editorComment_ASC',
  EditorCommentDesc = 'editorComment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WideInContentAsc = 'wideInContent_ASC',
  WideInContentDesc = 'wideInContent_DESC'
}

export type VideoUpdateInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  galleries?: InputMaybe<GalleryUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<VideoUpdateLocalizationsInput>;
  media?: InputMaybe<VideomediaUnionUpdateOneInlineInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VideoUpdateLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type VideoUpdateLocalizationInput = {
  data: VideoUpdateLocalizationDataInput;
  locale: Locale;
};

export type VideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VideoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VideoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VideoUpsertLocalizationInput>>;
};

export type VideoUpdateManyInlineInput = {
  /** Connect multiple existing Video documents */
  connect?: InputMaybe<Array<VideoConnectInput>>;
  /** Create and connect multiple Video documents */
  create?: InputMaybe<Array<VideoCreateInput>>;
  /** Delete multiple Video documents */
  delete?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Disconnect multiple Video documents */
  disconnect?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Video documents */
  set?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Update multiple Video documents */
  update?: InputMaybe<Array<VideoUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Video documents */
  upsert?: InputMaybe<Array<VideoUpsertWithNestedWhereUniqueInput>>;
};

export type VideoUpdateManyInput = {
  /** caption input for default locale (de) */
  caption?: InputMaybe<Scalars['String']['input']>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<VideoUpdateManyLocalizationsInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VideoUpdateManyLocalizationDataInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
};

export type VideoUpdateManyLocalizationInput = {
  data: VideoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VideoUpdateManyLocalizationInput>>;
};

export type VideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VideoUpdateManyInput;
  /** Document search */
  where: VideoWhereInput;
};

export type VideoUpdateOneInlineInput = {
  /** Connect existing Video document */
  connect?: InputMaybe<VideoWhereUniqueInput>;
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
  /** Delete currently connected Video document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Video document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Video document */
  update?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Video document */
  upsert?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type VideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VideoUpdateInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

export type VideoUpsertInput = {
  /** Create document if it didn't exist */
  create: VideoCreateInput;
  /** Update document if it exists */
  update: VideoUpdateInput;
};

export type VideoUpsertLocalizationInput = {
  create: VideoCreateLocalizationDataInput;
  locale: Locale;
  update: VideoUpdateLocalizationDataInput;
};

export type VideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VideoUpsertInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type VideoWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type VideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  caption_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  caption_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  caption_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  caption_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  caption_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  caption_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  caption_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  caption_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  caption_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<VideoWhereStageInput>;
  documentInStages_none?: InputMaybe<VideoWhereStageInput>;
  documentInStages_some?: InputMaybe<VideoWhereStageInput>;
  editorComment?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  editorComment_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  editorComment_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  editorComment_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  editorComment_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  editorComment_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  editorComment_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  editorComment_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  editorComment_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  editorComment_starts_with?: InputMaybe<Scalars['String']['input']>;
  galleries_every?: InputMaybe<GalleryWhereInput>;
  galleries_none?: InputMaybe<GalleryWhereInput>;
  galleries_some?: InputMaybe<GalleryWhereInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values in which the modular component is connected to the given models */
  media?: InputMaybe<VideomediaUnionWhereInput>;
  /** All values in which the union is empty. */
  media_empty?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  wideInContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  wideInContent_not?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VideoWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VideoWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Video record uniquely */
export type VideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type VideomediaUnion = AssetComponent | UrlComponent;

export type VideomediaUnionConnectInput = {
  AssetComponent?: InputMaybe<AssetComponentConnectInput>;
  UrlComponent?: InputMaybe<UrlComponentConnectInput>;
};

export type VideomediaUnionCreateInput = {
  AssetComponent?: InputMaybe<AssetComponentCreateInput>;
  UrlComponent?: InputMaybe<UrlComponentCreateInput>;
};

export type VideomediaUnionCreateManyInlineInput = {
  /** Create and connect multiple existing VideomediaUnion documents */
  create?: InputMaybe<Array<VideomediaUnionCreateInput>>;
};

export type VideomediaUnionCreateOneInlineInput = {
  /** Create and connect one VideomediaUnion document */
  create?: InputMaybe<VideomediaUnionCreateInput>;
};

export type VideomediaUnionCreateWithPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentCreateWithPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentCreateWithPositionInput>;
};

export type VideomediaUnionUpdateInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateInput>;
};

export type VideomediaUnionUpdateManyInlineInput = {
  /** Create and connect multiple VideomediaUnion component instances */
  create?: InputMaybe<Array<VideomediaUnionCreateWithPositionInput>>;
  /** Delete multiple VideomediaUnion documents */
  delete?: InputMaybe<Array<VideomediaUnionWhereUniqueInput>>;
  /** Update multiple VideomediaUnion component instances */
  update?: InputMaybe<Array<VideomediaUnionUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple VideomediaUnion component instances */
  upsert?: InputMaybe<Array<VideomediaUnionUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VideomediaUnionUpdateManyWithNestedWhereInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateManyWithNestedWhereInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateManyWithNestedWhereInput>;
};

export type VideomediaUnionUpdateOneInlineInput = {
  /** Create and connect one VideomediaUnion document */
  create?: InputMaybe<VideomediaUnionCreateInput>;
  /** Delete currently connected VideomediaUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single VideomediaUnion document */
  update?: InputMaybe<VideomediaUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VideomediaUnion document */
  upsert?: InputMaybe<VideomediaUnionUpsertWithNestedWhereUniqueInput>;
};

export type VideomediaUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type VideomediaUnionUpdateWithNestedWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentUpdateWithNestedWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentUpdateWithNestedWhereUniqueInput>;
};

export type VideomediaUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  AssetComponent?: InputMaybe<AssetComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  UrlComponent?: InputMaybe<UrlComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type VideomediaUnionUpsertWithNestedWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentUpsertWithNestedWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentUpsertWithNestedWhereUniqueInput>;
};

export type VideomediaUnionWhereInput = {
  AssetComponent?: InputMaybe<AssetComponentWhereInput>;
  UrlComponent?: InputMaybe<UrlComponentWhereInput>;
};

export type VideomediaUnionWhereUniqueInput = {
  AssetComponent?: InputMaybe<AssetComponentWhereUniqueInput>;
  UrlComponent?: InputMaybe<UrlComponentWhereUniqueInput>;
};

export type Whitelabel = Entity & Node & {
  __typename?: 'Whitelabel';
  /** The time the document was created */
  createdAt: Scalars['DateTime']['output'];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dealers: Array<Dealer>;
  /** Get the document in other stages */
  documentInStages: Array<Whitelabel>;
  domains: Array<Scalars['String']['output']>;
  /** List of Whitelabel versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars['ID']['output'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars['DateTime']['output'];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  utmSource: Scalars['String']['output'];
};


export type WhitelabelCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type WhitelabelDealersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DealerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type WhitelabelDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input'];
  inheritLocale?: Scalars['Boolean']['input'];
  stages?: Array<Stage>;
};


export type WhitelabelHistoryArgs = {
  limit?: Scalars['Int']['input'];
  skip?: Scalars['Int']['input'];
  stageOverride?: InputMaybe<Stage>;
};


export type WhitelabelPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};


export type WhitelabelScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};


export type WhitelabelUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type WhitelabelConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: WhitelabelWhereUniqueInput;
};

/** A connection to a list of items. */
export type WhitelabelConnection = {
  __typename?: 'WhitelabelConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<WhitelabelEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WhitelabelCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dealers?: InputMaybe<DealerCreateManyInlineInput>;
  domains: Array<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  utmSource: Scalars['String']['input'];
};

export type WhitelabelCreateManyInlineInput = {
  /** Connect multiple existing Whitelabel documents */
  connect?: InputMaybe<Array<WhitelabelWhereUniqueInput>>;
  /** Create and connect multiple existing Whitelabel documents */
  create?: InputMaybe<Array<WhitelabelCreateInput>>;
};

export type WhitelabelCreateOneInlineInput = {
  /** Connect one existing Whitelabel document */
  connect?: InputMaybe<WhitelabelWhereUniqueInput>;
  /** Create and connect one Whitelabel document */
  create?: InputMaybe<WhitelabelCreateInput>;
};

/** An edge in a connection. */
export type WhitelabelEdge = {
  __typename?: 'WhitelabelEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Whitelabel;
};

/** Identifies documents */
export type WhitelabelManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  documentInStages_every?: InputMaybe<WhitelabelWhereStageInput>;
  documentInStages_none?: InputMaybe<WhitelabelWhereStageInput>;
  documentInStages_some?: InputMaybe<WhitelabelWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  domains_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  domains_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  domains_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  domains_not?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  utmSource_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  utmSource_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  utmSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  utmSource_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  utmSource_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  utmSource_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  utmSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  utmSource_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  utmSource_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export enum WhitelabelOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DomainsAsc = 'domains_ASC',
  DomainsDesc = 'domains_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UtmSourceAsc = 'utmSource_ASC',
  UtmSourceDesc = 'utmSource_DESC'
}

export type WhitelabelUpdateInput = {
  dealers?: InputMaybe<DealerUpdateManyInlineInput>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type WhitelabelUpdateManyInlineInput = {
  /** Connect multiple existing Whitelabel documents */
  connect?: InputMaybe<Array<WhitelabelConnectInput>>;
  /** Create and connect multiple Whitelabel documents */
  create?: InputMaybe<Array<WhitelabelCreateInput>>;
  /** Delete multiple Whitelabel documents */
  delete?: InputMaybe<Array<WhitelabelWhereUniqueInput>>;
  /** Disconnect multiple Whitelabel documents */
  disconnect?: InputMaybe<Array<WhitelabelWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Whitelabel documents */
  set?: InputMaybe<Array<WhitelabelWhereUniqueInput>>;
  /** Update multiple Whitelabel documents */
  update?: InputMaybe<Array<WhitelabelUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Whitelabel documents */
  upsert?: InputMaybe<Array<WhitelabelUpsertWithNestedWhereUniqueInput>>;
};

export type WhitelabelUpdateManyInput = {
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type WhitelabelUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: WhitelabelUpdateManyInput;
  /** Document search */
  where: WhitelabelWhereInput;
};

export type WhitelabelUpdateOneInlineInput = {
  /** Connect existing Whitelabel document */
  connect?: InputMaybe<WhitelabelWhereUniqueInput>;
  /** Create and connect one Whitelabel document */
  create?: InputMaybe<WhitelabelCreateInput>;
  /** Delete currently connected Whitelabel document */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect currently connected Whitelabel document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update single Whitelabel document */
  update?: InputMaybe<WhitelabelUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Whitelabel document */
  upsert?: InputMaybe<WhitelabelUpsertWithNestedWhereUniqueInput>;
};

export type WhitelabelUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: WhitelabelUpdateInput;
  /** Unique document search */
  where: WhitelabelWhereUniqueInput;
};

export type WhitelabelUpsertInput = {
  /** Create document if it didn't exist */
  create: WhitelabelCreateInput;
  /** Update document if it exists */
  update: WhitelabelUpdateInput;
};

export type WhitelabelUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: WhitelabelUpsertInput;
  /** Unique document search */
  where: WhitelabelWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type WhitelabelWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Identifies documents */
export type WhitelabelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WhitelabelWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dealers_every?: InputMaybe<DealerWhereInput>;
  dealers_none?: InputMaybe<DealerWhereInput>;
  dealers_some?: InputMaybe<DealerWhereInput>;
  documentInStages_every?: InputMaybe<WhitelabelWhereStageInput>;
  documentInStages_none?: InputMaybe<WhitelabelWhereStageInput>;
  documentInStages_some?: InputMaybe<WhitelabelWhereStageInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains *all* items provided to the filter */
  domains_contains_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  domains_contains_none?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array contains at least one item provided to the filter */
  domains_contains_some?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  domains_not?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  /** All values containing the given string. */
  utmSource_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values ending with the given string. */
  utmSource_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are contained in given list. */
  utmSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Any other value that exists and is not equal to the given value. */
  utmSource_not?: InputMaybe<Scalars['String']['input']>;
  /** All values not containing the given string. */
  utmSource_not_contains?: InputMaybe<Scalars['String']['input']>;
  /** All values not ending with the given string */
  utmSource_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  /** All values that are not contained in given list. */
  utmSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** All values not starting with the given string. */
  utmSource_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  /** All values starting with the given string. */
  utmSource_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type WhitelabelWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WhitelabelWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WhitelabelWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WhitelabelWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<WhitelabelWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Whitelabel record uniquely */
export type WhitelabelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum _FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  JsonPathExists = 'json_path_exists',
  JsonValueRecursive = 'json_value_recursive',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with',
  UnionEmpty = 'union_empty',
  UnionEvery = 'union_every',
  UnionNone = 'union_none',
  UnionSingle = 'union_single',
  UnionSome = 'union_some'
}

export enum _MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual'
}

export enum _MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert'
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export enum _RelationInputCardinality {
  Many = 'many',
  One = 'one'
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update'
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union'
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization'
}
