import i18next from "i18next";
import { DeliveryFee } from "../../modules/dealer/types/dealer";
import { Formatters } from "../../modules/common/hooks/useFormatter";
import { Currency, Maybe } from "../../modules/hygraph/vo";

export const getDeliveryFees = (
  { formatCurrency, formatNumber }: Formatters,
  brandId?: number | null,
  currency?: Maybe<Currency>,
  deliveryFees?: Maybe<DeliveryFee[]>,
) => {
  if (deliveryFees?.length === 0) return null;
  if (!deliveryFees) return null;

  let deliveryFee;
  if (deliveryFees?.some(fee => fee?.brandId === brandId)) {
    deliveryFee = deliveryFees?.filter(fee => fee?.brandId === brandId);
  } else {
    deliveryFee = deliveryFees?.filter(fee => fee?.brandId === null);
  }

  const isNoFees = [...deliveryFee]?.every(fee => fee?.rate === 0);
  const feesBuilder = (fee: DeliveryFee) => {
    const { rate, toKm, fromKm } = fee;

    if (fee.toKm) {
      return `${i18next.t("TO")} ${i18next.t("KM PLACEHOLDER", { value: formatNumber(toKm!) })}: ${
        rate === 0 ? i18next.t("FREE OF CHARGE") : formatCurrency(rate, currency)
      }`;
    } else {
      return `${i18next.t("FROM")} ${i18next.t("KM PLACEHOLDER", {
        value: formatNumber(fromKm),
      })}: ${formatCurrency(rate, currency)}`;
    }
  };

  if (isNoFees) {
    return [i18next.t("ALL SWITZERLAND FREE OF CHARGE")];
  } else {
    return [...deliveryFee]
      .sort((a, b) => a?.fromKm - b?.fromKm)
      .flatMap(fee => {
        return feesBuilder(fee);
      });
  }
};
