import { createSelector } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { RootState } from "../core/state/init/store";
import { AuthState } from "./types";

export const selectAuthState = (state: RootState): AuthState => state.auth;
export const selectIsLoggedIn = (state: RootState) => selectAuthState(state).accessToken !== undefined;
export const selectAccessToken = (state: RootState) => selectAuthState(state).accessToken;
export const selectRefreshToken = (state: RootState) => selectAuthState(state).refreshToken;
export const selectInSessionChecker = (state: RootState) => selectAuthState(state).inSessionChecker;

type JwtToken = {
  sub: string;
  exp: number;
};

export const selectParsedAccessToken = createSelector(selectAccessToken, accessToken => {
  return accessToken ? jwtDecode<JwtToken>(accessToken) : null;
});

export const selectUserId = (state: RootState) => selectParsedAccessToken(state)?.sub;
export const selectAccessTokenExpiration = (state: RootState) => selectParsedAccessToken(state)?.exp;
