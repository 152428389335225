import { FilterReferenceData, FilterItemReferenceData } from "../services/reference-data-aggregator/types";
import { KeyOfType } from "../../common/types/KeyOfType";
import { buildFilter } from "../../algolia/services/buildFilter";
import getNameForReferenceId from "./getNameForReferenceId";

export const searchQueryExtractor = (
  param: string[],
  filter: {
    conjunctive?: boolean;
    getSearchValue?: (val: string) => string;
    facetKey: string;
    dataKey: KeyOfType<FilterReferenceData, FilterItemReferenceData>;
  },
  filterData: FilterReferenceData,
): string[] => {
  const CONJUNCTION = !!filter.conjunctive ? " AND " : " OR ";

  return [
    "(" +
      param
        .map(id => {
          const searchValue = filter.getSearchValue?.(id) ?? getNameForReferenceId(id, filter, filterData);
          return buildFilter(filter.facetKey, searchValue);
        })
        .join(CONJUNCTION) +
      ")",
  ];
};
