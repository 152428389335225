import { KeyOfType } from "../../../common/types/KeyOfType";
import { FilterReferenceData, GroupReferenceData } from "../reference-data-aggregator/types";

export enum TagType {
  Group = "g",
  Child = "c",
}

export type AppliedTag = {
  type: TagType;
  value: string;
};
export const GROUP_SELECT_SEPARATOR = "|";

export const mapAppliedTagToQueryParam = ({ type, value }: AppliedTag): string => {
  return `${type}${GROUP_SELECT_SEPARATOR}${value}`;
};

export const buildGroupAppliedTag = (tag: string): AppliedTag => {
  const [type, value] = tag.split(GROUP_SELECT_SEPARATOR);
  return { type: type === TagType.Group ? TagType.Group : TagType.Child, value };
};

export const buildGroupAppliedTagList = (tagList: (string | null)[]): AppliedTag[] => {
  return tagList
    .filter(item => !!item)
    .map(itemArg => {
      const item = itemArg as string;
      return buildGroupAppliedTag(item);
    });
};

export function getValueNameForGroupedReferenceId(
  groupTag: string,
  filter: { dataKey: KeyOfType<FilterReferenceData, GroupReferenceData> },
  filterData: FilterReferenceData,
): { value: string; name: string } {
  const tag = buildGroupAppliedTag(groupTag);
  const groupData = filterData[filter.dataKey];
  // todo: null safe access?
  return {
    value: groupTag,
    name: tag.type === TagType.Group ? groupData.map[tag.value].name : groupData.childMap[tag.value].name,
  };
}

export function getNameForGroupedReferenceId(
  groupTag: string,
  filter: { dataKey: KeyOfType<FilterReferenceData, GroupReferenceData> },
  filterData: FilterReferenceData,
): string {
  return getValueNameForGroupedReferenceId(groupTag, filter, filterData).name;
}
