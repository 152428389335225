import { FILTER_DEFINITIONS } from "../services/filterDefinition";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../services/filterTypeConfiguration";
import { AlgoliaGeoSearchQuery } from "../services/types/filterTypes";
import Locale from "../../common/constants/Locale";
import { FilterReferenceData } from "../services/reference-data-aggregator/types";
import { VEHICLE_FACETS } from "../../algolia/services/vehicleFacetKeys";
import { buildDisjunctiveFilter, buildFilter, buildNumericalFilter } from "../../algolia/services/buildFilter";
import { getSearchQueries } from "./filterTypeHelpers";
import { getActiveAppliedFilterItems } from "./getActiveAppliedFilterItems";

export type VehicleSearchRequestOptions = {
  page: number;
  language: Locale;
  replica?: string;
  filters?: string;
  aroundLatLng?: string;
  aroundRadius?: number;
};

export function getAlgoliaFilters({
  appliedFilters,
  filterData,
  dealerRestrictions,
  brandRestrictions,
}: {
  appliedFilters: Record<string, string[] | string>;
  filterData: FilterReferenceData | undefined;
  dealerRestrictions: number[] | undefined;
  brandRestrictions?: number[];
}): Omit<VehicleSearchRequestOptions, "page" | "language" | "replica"> | undefined {
  if (!filterData) {
    return undefined;
  }

  const filterOptions = getActiveAppliedFilterItems(appliedFilters).map(([queryParam, value]) => {
    const filter = FILTER_DEFINITIONS[queryParam];
    const type = filter.type;
    const filterType = FILTER_TYPE_CONFIGURATION_MAP[type];
    const searchQueries = getSearchQueries(filterType, value, filter, filterData);
    return Array.isArray(searchQueries) ? searchQueries.join(" AND ") : searchQueries;
  });

  if (Array.isArray(dealerRestrictions) && dealerRestrictions.length > 0) {
    filterOptions.push(
      buildDisjunctiveFilter(
        dealerRestrictions.map(dealerId => buildNumericalFilter(VEHICLE_FACETS.DEALER_ID, dealerId)),
      ),
    );
  }
  if (Array.isArray(brandRestrictions) && brandRestrictions.length > 0) {
    const brandsMap = new Map(
      brandRestrictions.map(brandRestriction => [brandRestriction, filterData.brands.map[brandRestriction].name]),
    );

    const brands = brandRestrictions.map(id => buildFilter(VEHICLE_FACETS.BRAND, brandsMap.get(id)!));

    filterOptions.push(buildDisjunctiveFilter(brands));
  }

  const algoliaFilters = filterOptions.filter(query => !(query as AlgoliaGeoSearchQuery).aroundLatLng).join(" AND ");
  const latLngFilter = filterOptions.find(
    query => !!(query as AlgoliaGeoSearchQuery).aroundLatLng,
  ) as AlgoliaGeoSearchQuery;

  return { filters: algoliaFilters, ...latLngFilter };
}
