import React, { ReactNode } from "react";
import { createNullableContext } from "../../common/helpers/contextCreator";

type OverlayContextValue = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};

export const [OverlayContext, useOverlayContext] = createNullableContext<OverlayContextValue>("OverlayContext");

// todo: consider value memoization
export const OverlayContextProvider: React.FC<OverlayContextValue & { children: ReactNode }> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  return (
    <OverlayContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};
