import { MutableRefObject, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getUri } from "../../../modules/vehicle/helpers/getUri";

export const useTracking = (trackingCallback: (arg: string) => boolean) => {
  const location = useLocation();
  const isTrackedRef: MutableRefObject<boolean> = useRef<boolean>(false);
  const prevPathnameRef: MutableRefObject<string> = useRef<string>("");
  const shallTracking = !isTrackedRef.current || prevPathnameRef.current !== location.pathname;

  useEffect(() => {
    if (shallTracking) {
      const url = getUri();
      const hasBeenAbleToTrack = trackingCallback(url);
      if (hasBeenAbleToTrack) {
        isTrackedRef.current = true;
        prevPathnameRef.current = location.pathname;
      }
    }
  });
};
