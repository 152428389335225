import { useEffect, useRef } from "react";
import { filterTrackSummedUp } from "../scripts/searchTrack/filterTrackSummedUp";

export const useFilterTrack = (
  appliedFilters: Record<string, string[] | string>,
  vehicleCount: number | undefined,
  queryId: string | undefined,
) => {
  const previousQueryIdRef = useRef<string | undefined>();

  useEffect(() => {
    const shallTrack = queryId && queryId !== previousQueryIdRef.current;

    if (shallTrack) {
      filterTrackSummedUp(appliedFilters, vehicleCount);
      previousQueryIdRef.current = queryId;
    }
  }, [appliedFilters, vehicleCount, queryId]);
};
