import { isAllOf, isAnyOf } from "@reduxjs/toolkit";
import listenerMiddleware from "../core/state/init/middleware";
import { STORAGE_KEYS } from "../modules/common/constants/Storage";
import localStorageManager from "../modules/common/global/localStorageManager";
import { authenticatedSaveSearchApi } from "../save-search/services/authenticatedSaveSearchApi";
import { favoritesApi } from "../favorites/services/favoritesApi";
import { authenticatedFfuApi } from "../processes/core/services/authenticatedFfuApi";
import { comparisonApi } from "../comparison/services/comparisonApi";
import { loggedIn, loggedOut } from "./authSlice";
import { fusionAuthApi } from "./services/fusionAuthApi";

listenerMiddleware.startListening({
  matcher: isAnyOf(loggedIn, loggedOut),
  effect: async (action, { dispatch }) => {
    if (isAllOf(loggedIn)(action)) {
      localStorageManager.setItem(STORAGE_KEYS.SESSION_TOKENS, action.payload);
    } else {
      dispatch(fusionAuthApi.util.invalidateTags(["session"]));
      dispatch(authenticatedSaveSearchApi.util.invalidateTags(["session"]));
      dispatch(authenticatedFfuApi.util.invalidateTags(["session"]));
      dispatch(favoritesApi.util.invalidateTags(["session"]));
      dispatch(comparisonApi.util.invalidateTags(["session"]));

      localStorageManager.setItem(STORAGE_KEYS.SESSION_TOKENS, null);

      localStorageManager.setItem(STORAGE_KEYS.FAVORITES_LIST, null);
      localStorageManager.setItem(STORAGE_KEYS.COMPARISON_LIST, null);
    }
  },
});
