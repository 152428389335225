import { useGetVehiclesQuery } from "../../algolia/services/vehicleSearchApi";
import useVehicleSearchData from "../../algolia/hooks/useVehicleSearchData";

export const useZeroResultsAlternativeVehicles = ({
  algoliaOptions,
}: {
  algoliaOptions: ReturnType<typeof useVehicleSearchData>["algoliaOptions"];
}) => {
  const appliedFilters = algoliaOptions?.filters;

  const { data: zeroResultsVehicles } = useGetVehiclesQuery({
    query: "",
    options: {
      filters: appliedFilters,
    },
  });

  if (!zeroResultsVehicles || zeroResultsVehicles.nbHits === 0) {
    return null;
  }

  return zeroResultsVehicles.hits;
};
