import { useEffect } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import i18next from "i18next";
import { selectIsLoggedIn } from "../../auth/authSelectors";
import useEvent from "../../modules/common/hooks/useEvent";
import { ComparisonEntry, useAddToComparisonMutation, useGetAllComparedQuery } from "../services/comparisonApi";
import useVehicleComparisonList, { MAX_COMPARED_VEHICLES } from "../../modules/vehicle/hooks/useVehicleComparisonList";
import useAuth from "../../auth/hooks/useAuth";
import { buildInfoNotification } from "../../modules/notification/helpers/notificationFactory";
import useNotification from "../../modules/notification/hooks/useNotification";
import useVehicleList from "../../modules/vehicle/hooks/useVehicleList";
import { STORAGE_KEYS } from "../../modules/common/constants/Storage";

function useComparisonSync() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { list } = useVehicleComparisonList();
  const { addVehicles, overrideVehicles } = useVehicleList(STORAGE_KEYS.COMPARISON_LIST);
  const pushNotification = useNotification();

  const [addToComparison, addToComparisonResult] = useAddToComparisonMutation();
  const authInfo = useAuth();
  const { data: persistedComparison } = useGetAllComparedQuery(authInfo ? authInfo : skipToken);

  const triggerComparisonSync = useEvent(() => {
    if (authInfo && list.length > 0) {
      addToComparison({ ...authInfo, body: list })
        .unwrap()
        .catch(error => {
          let notification;
          if (error.status === 400) {
            notification = buildInfoNotification({
              icon: "compare",
              text: i18next.t("REACHED COMPARISON LIST MAXIMUM", { value: MAX_COMPARED_VEHICLES }),
            });
          } else {
            notification = buildInfoNotification({
              icon: "compare",
              text: i18next.t("GENERIC ERROR MESSAGE RETRY"),
            });
          }

          pushNotification(notification);
        });
    }
  });

  useEffect(() => {
    if (isLoggedIn) {
      triggerComparisonSync();
    }
  }, [isLoggedIn, triggerComparisonSync]);

  const addVehiclesIfNotStored = useEvent((vehicles: ComparisonEntry[], preferNewer = false) => {
    const newVehicleIds = vehicles
      .map(({ vehicleId }) => `${vehicleId}`)
      .filter(vehicleId => !list.includes(vehicleId));

    if (newVehicleIds.length > 0) {
      if (list.length + newVehicleIds.length > MAX_COMPARED_VEHICLES) {
        const notification = buildInfoNotification({
          icon: "compare",
          text: i18next.t("REACHED COMPARISON LIST MAXIMUM", { value: MAX_COMPARED_VEHICLES }),
        });

        pushNotification(notification);

        if (preferNewer) {
          overrideVehicles(newVehicleIds);
        }

        return;
      }

      addVehicles(newVehicleIds);
    }
  });

  useEffect(() => {
    if (addToComparisonResult.data) {
      addVehiclesIfNotStored(addToComparisonResult.data);
    }
  }, [addToComparisonResult.data, addVehiclesIfNotStored]);

  useEffect(() => {
    if (persistedComparison) {
      addVehiclesIfNotStored(persistedComparison, true);
    }
  }, [persistedComparison, addVehiclesIfNotStored]);
}

export default useComparisonSync;
