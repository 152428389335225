import { createNullableContext } from "../common/helpers/contextCreator";
import { NotificationItem } from "./types";

type NotificationContextValue = {
  pushNotification: (notification: NotificationItem) => void;
};

const [NotificationContext, useNotificationContext] =
  createNullableContext<NotificationContextValue>("NotificationContext");

export const NotificationContextProvider = NotificationContext.Provider;
export const useNotificationContextValue = useNotificationContext;
