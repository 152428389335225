import React from "react";
import classNames from "classnames";
import { Icon } from "../../common/types/Icons";

type Props = {
  icon: Icon;
  text: string;
  onDismiss: () => void;
};

const Notification: React.FC<Props> = ({ icon, text, onDismiss }) => {
  return (
    <div className="alert-sml" onClick={onDismiss}>
      <div className="alert__text">
        <span className={classNames("ifont", `ifont--${icon}`)} />
        {text}
      </div>
    </div>
  );
};

export default Notification;
