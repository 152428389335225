import React, { Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import { BannerSlide as BannerSlideType } from "../../hygraph/vo";

type Props = {
  slides?: BannerSlideType[];
  activeSlide: number;
  setActiveSlide: Dispatch<SetStateAction<number>>;
};

const BannerNav = ({ slides = [], activeSlide, setActiveSlide }: Props) => {
  return slides.length > 1 ? (
    <ul className="nav-dot">
      {slides.map((slide, key) => (
        <li
          key={key}
          className={classNames("nav-dot__item", { active: activeSlide === key })}
          onClick={() => {
            setActiveSlide(key);
          }}
        />
      ))}
    </ul>
  ) : null;
};

export default BannerNav;
