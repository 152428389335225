import { useLayoutEffect } from "react";

const OVERFLOW_HIDDEN_AUTO = "prevent-background-scroll-auto";
const OVERFLOW_HIDDEN_STABLE = "prevent-background-scroll-stable";

type GutterType = "auto" | "stable";

function addPreventBackgroundScroll(cssClass: string) {
  document.documentElement.classList.add(cssClass);
}

function removePreventBackgroundScroll(cssClass: string) {
  document.documentElement.classList.remove(cssClass);
}

export const usePreventBackgroundScroll = (prevent: boolean, type: GutterType = "stable") => {
  useLayoutEffect(
    function handlePreventBackgroundScroll() {
      const cssClass = type === "auto" ? OVERFLOW_HIDDEN_AUTO : type === "stable" ? OVERFLOW_HIDDEN_STABLE : "";
      if (prevent) {
        addPreventBackgroundScroll(cssClass);
      } else {
        removePreventBackgroundScroll(cssClass);
      }

      return () => removePreventBackgroundScroll(cssClass);
    },

    [prevent, type],
  );
};
