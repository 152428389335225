const GOOGLE_MAPS_DIRECTION_API_BASE_URL = import.meta.env.VITE_GOOGLE_MAPS_DIRECTION_API_BASE_URL!;

const getGoogleMapsJourneyLink = (dealer: {
  name: string | undefined | null;
  street: string | undefined | null;
  zip: string | undefined | null;
  city: string | undefined | null;
}): string => {
  // as documented in
  // https://developers.google.com/maps/documentation/urls/get-started#directions-action
  return `${GOOGLE_MAPS_DIRECTION_API_BASE_URL}/?api=1&destination=${encodeURIComponent(
    [dealer.name, dealer.street, `${dealer.zip} ${dealer.city}`].filter(item => !!item).join(", "),
  )}`;
};

export default getGoogleMapsJourneyLink;
