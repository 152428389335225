import React from "react";
import i18next from "i18next";
import banner_matchmaker_desktop from "../../../../template/private/assets/img/content/banner_matchmaker_desktop.svg";
import banner_matchmaker from "../../../../template/private/assets/img/content/banner_matchmaker.svg";
import banner_matchmaker_desktop_initial from "../../../../template/private/assets/img/content/banner_matchmaker_desktop_initial.svg";
import banner_matchmaker_initial from "../../../../template/private/assets/img/content/banner_matchmaker_initial.svg";
import { useMatchmaker } from "../../hooks/useMatchmaker";
import BannerAccess from "./BannerAccess";

const Banner: React.FC = () => {
  const { isInitialized, answeredNumberOfQuestions, totalNumberOfQuestions } = useMatchmaker();
  return isInitialized ? (
    <BannerAccess
      buttonText={i18next.t("MATCHMAKER ACCESS POINT EDIT FORMULA")}
      imageDesktop={banner_matchmaker_desktop}
      imageMobile={banner_matchmaker}
      isInitialized={isInitialized}
      title={i18next.t("MATCHMAKER ACCESS POINT TITLE INITIALISED")}
      lead={i18next.t("MATCHMAKER ACCESS POINT LEAD INITIALISED", {
        answeredQuestions: `${answeredNumberOfQuestions}/${totalNumberOfQuestions}`,
      })}
    />
  ) : (
    <BannerAccess
      buttonText={i18next.t("START MATCHMAKER")}
      imageDesktop={banner_matchmaker_desktop_initial}
      imageMobile={banner_matchmaker_initial}
      isInitialized={isInitialized}
      lead={i18next.t("MATCHMAKER ACCESS POINT LEAD INITIAL", { number: totalNumberOfQuestions })}
      title={i18next.t("MATCHMAKER ACCESS POINT TITLE INITIAL")}
    />
  );
};
export default Banner;
