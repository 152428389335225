// determines how many milliseconds before the token expires to refresh it
const EAGER_REFRESH_THRESHOLD_MS = 6 * 1000;

function determineTokenRefreshInterval(accessTokenExpiryDate: number, relativeTo: Date = new Date()): number {
  const expiryDate = new Date(0);
  expiryDate.setUTCSeconds(accessTokenExpiryDate);

  const expiryInMillis = expiryDate.getTime() - relativeTo.getTime();

  const refreshIn = expiryInMillis - EAGER_REFRESH_THRESHOLD_MS;

  return refreshIn;
}

export default determineTokenRefreshInterval;
