import React, { forwardRef, useId } from "react";
import classNames from "classnames";
import Label from "../Label";
import FormErrorMessage from "../FormErrorMessage";

type Props = {
  label: string;
  error?: string;
  optional?: boolean;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "id">;

const InputField = forwardRef<HTMLInputElement, Props>(({ label, error, optional, ...rest }, ref) => {
  const id = useId();
  return (
    <>
      <Label htmlFor={id} label={label} optional={optional} />
      <input ref={ref} className={classNames("input", { "form-error": !!error })} id={id} {...rest} />
      <FormErrorMessage error={error} />
    </>
  );
});

export default InputField;
