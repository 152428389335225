import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useUser from "../../../auth/hooks/useUser";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { selectIsLoggedIn } from "../../../auth/authSelectors";
import { notNil } from "../../common/helpers/isNil";
import { createNullableContext } from "../../common/helpers/contextCreator";
import { digestMessage } from "../scripts/digestMessage";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import useStableFunction from "../../common/hooks/useStableFunction";

type UserId = string;

type UserIdContextType = {
  id: UserId;
  type: "anonymous" | "authenticated";
};

const PENDING_USER_ID_CONTEXT = "pending";

const ANONYMOUS_USER_TOKEN_FALLBACK = "anonymous";

export const [UserIdContext, useUserIdContext] = createNullableContext<
  UserIdContextType | typeof PENDING_USER_ID_CONTEXT
>("UserIdContext");

const generateAnonymousUserToken = () => {
  try {
    return self.crypto.randomUUID();
  } catch (e) {
    // Browser does not support randomUUID, this occurs as of October 2023 mainly in Safari 15.3 and PingDom bots
    return ANONYMOUS_USER_TOKEN_FALLBACK;
  }
};

export const UserIdProvider = ({ children }: { children: ReactNode }) => {
  const [userIdContext, setUserId] = useState<UserIdContextType | typeof PENDING_USER_ID_CONTEXT>(
    PENDING_USER_ID_CONTEXT,
  );

  const user = useUser();
  const email = user?.email;
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [anonymousUserToken, setAnonymousUserToken] = useLocalStorage<string | undefined>(
    STORAGE_KEYS.USER_TOKEN,
    undefined,
  );

  const updateUserId = useStableFunction((email: string | undefined, isLoggedIn: boolean) => {
    const isAnonymous = !isLoggedIn;

    if (notNil(email)) {
      digestMessage(email).then(hash => {
        setUserId({ id: hash, type: "authenticated" });
      });
    } else if (isAnonymous) {
      const alreadyHasAnonymousUserToken = notNil(anonymousUserToken);
      if (alreadyHasAnonymousUserToken) {
        setUserId({
          id: anonymousUserToken,
          type: "anonymous",
        });
      } else {
        const anonymousUserToken = generateAnonymousUserToken();
        setUserId({
          id: anonymousUserToken,
          type: "anonymous",
        });
        setAnonymousUserToken(anonymousUserToken);
      }
    }
  });

  useEffect(() => {
    updateUserId(email, isLoggedIn);
  }, [email, isLoggedIn, updateUserId]);

  return <UserIdContext.Provider value={userIdContext}>{children}</UserIdContext.Provider>;
};

export const useUserId = (isAlgoliaAnalytics?: boolean): { hasSettledUserId: boolean; userId: string | undefined } => {
  const userIdContext = useUserIdContext();

  const isLoadingUserContext = PENDING_USER_ID_CONTEXT === userIdContext;
  if (isLoadingUserContext) {
    return {
      userId: undefined,
      hasSettledUserId: false,
    };
  }

  // anonymous user id shall only be used for Algolia Analytics (and not for GTM)
  if (userIdContext.type === "anonymous" && !isAlgoliaAnalytics) {
    return {
      userId: undefined,
      hasSettledUserId: true,
    };
  }

  return {
    userId: userIdContext.id,
    hasSettledUserId: true,
  };
};
