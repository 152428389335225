export enum SITE_FACETS {
  OBJECT_ID = "objectID",
  MODEL_ID = "modelId",
  KEYWORDS = "keywords",
}

export enum SiteModelId {
  ARTICLE = "article",
  PAGE = "page",
}
