import { ALL_FILTERS, FILTER_DEFINITIONS } from "./filterDefinition";
import { FILTER_TYPE_CONFIGURATION_MAP } from "./filterTypeConfiguration";
import { FilterDefinition } from "./types/filterDefinitionTypes";

/**
 * stores FilterDefinitions per facetKey, this permits obtaining the corresponding filterDefinition for a facetKey
 */
export const FACET_KEY_FILTER_MAP: Record<string, FilterDefinition> = Object.fromEntries(
  ALL_FILTERS.map(queryParam => FILTER_DEFINITIONS[queryParam])
    .map(filterDefinition => {
      const type = filterDefinition.type;
      const filterType = FILTER_TYPE_CONFIGURATION_MAP[type];
      const facetKeys = filterType.facetMapping?.getKeys(filterDefinition) ?? [];
      return facetKeys.map(facetKey => [facetKey, filterDefinition]);
    })
    .flat(),
);
