import { useEffect } from "react";
import i18next from "i18next";
import { buildErrorNotification } from "../helpers/notificationFactory";
import useNotification from "./useNotification";

/**
 * automatically show a generic error message if a mutation fails
 */
function useMutationErrorNotification({ isError, error }: { isError: boolean; error?: unknown }) {
  const pushNotification = useNotification();
  useEffect(() => {
    if (isError) {
      pushNotification(buildErrorNotification({ text: i18next.t("GENERIC ERROR MESSAGE RETRY") }));
    }
  }, [error, isError, pushNotification]);
}

export default useMutationErrorNotification;
