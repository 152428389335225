import { useEffect, useRef, useState } from "react";
import { BannerSlide as BannerSlideType } from "../../../modules/hygraph/vo";
import useEvent from "../../common/hooks/useEvent";

export function useBannerSlider(slides: BannerSlideType[] = []) {
  const slidesRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const listWrapRef = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const slideRef = (slideEl: HTMLDivElement | null, key: number) => {
    if (slideEl) {
      if (!slidesRef.current.get(key)) {
        slidesRef.current.set(key, slideEl);
      }
    }
  };

  const timer = useRef(0);

  const pause = useEvent(() => {
    window.clearTimeout(timer.current);
  });

  const resume = useEvent(() => {
    timer.current = window.setTimeout(() => {
      const nextSlide = activeSlide + 1;
      setActiveSlide(slidesRef.current.has(nextSlide) ? nextSlide : 0);
    }, slides[activeSlide]?.displayDuration);
  });

  useEffect(() => {
    resume();

    return () => pause();
  }, [resume, pause]);

  useEffect(() => {
    listWrapRef.current?.scrollTo({
      top: slidesRef.current.get(activeSlide)?.offsetTop,
      behavior: "smooth",
    });
  }, [activeSlide]);

  return { activeSlide, setActiveSlide, listWrapRef, slideRef, slidesRef, pause, resume };
}
