import algoliasearch from "algoliasearch";
import { RequestOptions } from "@algolia/transporter";
import { SearchOptions } from "@algolia/client-search";
import Locale from "../../common/constants/Locale";

export const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID!;
export const ALGOLIA_API_KEY = import.meta.env.VITE_ALGOLIA_API_KEY!;

export const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

export const buildIndexName = (indexName: Indices, language: Locale, replica?: string | undefined) =>
  `${import.meta.env.VITE_ENVIRONMENT}_${indexName}_${language}${replica ? `_${replica}` : ""}`;

export type AlgoliaRequestOptions = { language: Locale } & RequestOptions & SearchOptions;

export enum Indices {
  site = "site",
  vehicle = "vehicle",
  brandModel = "brand-model",
  dealer = "dealer",
}
