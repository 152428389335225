import i18next, { TFunction } from "i18next";
import { Formatters } from "../../common/hooks/useFormatter";
import { DetailVehicle } from "../types/vehicle";
import isNil from "../../common/helpers/isNil";
import getWarrantyText from "../helpers/getWarrantyText";
import Locale from "../../../modules/common/constants/Locale";

export type TechnicalDataItem<T = any> = {
  icon: string;
  label: (t: TFunction) => string;
  data: (vehicle: DetailVehicle) => T | undefined | null;
  format?: (data: T, formatters: Formatters, vehicle: DetailVehicle, language: Locale) => string;
};

export const mileage: TechnicalDataItem<number> = {
  icon: "ifont--tc_kilometer",
  label: t => t("MILEAGE"),
  data: vehicle => vehicle.km,
  format: (km, { formatNumber }) => i18next.t("KM PLACEHOLDER", { value: formatNumber(km) }),
};

export const driveType: TechnicalDataItem<string> = {
  icon: "ifont--tc_wheel_drive",
  label: t => t("DRIVE TYPE"),
  data: vehicle => vehicle.driveType?.name,
};

const transmission: TechnicalDataItem<string> = {
  icon: "ifont--tc_transmission",
  label: t => t("TRANSMISSION"),
  data: vehicle => vehicle.transmission?.name,
};

const firstRegistration: TechnicalDataItem = {
  icon: "ifont--tc_event",
  label: t => t("FIRST REGISTRATION"),
  data: vehicle => vehicle.firstRegistration,
  format: (firstRegistration: string, { formatDate }) => formatDate(firstRegistration),
};

const fuelType: TechnicalDataItem<string> = {
  icon: "ifont--tc_fuel_outline",
  label: t => t("FUEL TYPE"),
  data: vehicle => vehicle.fuelType?.name,
};

export const lastInspection = {
  icon: "ifont--tc_mfk",
  label: t => t("VALID INSPECTION"),
  data: vehicle => {
    return !isNil(vehicle.lastInspection) || vehicle.lastInspectionDate
      ? {
          lastInspection: vehicle.lastInspection ?? undefined,
          lastInspectionDate: vehicle.lastInspectionDate ?? undefined,
        }
      : undefined;
  },
  format: ({ lastInspection, lastInspectionDate }, { formatDate }) => {
    let label = "";
    // use explicit comparison as the value could also be null which indicates an absence of information
    if (lastInspection === true) {
      label = i18next.t("YES");
    } else if (lastInspection === false) {
      label = i18next.t("NO");
    }
    if (label === "") {
      return formatDate(lastInspectionDate!);
    }
    return lastInspectionDate ? `${label}, ${formatDate(lastInspectionDate)}` : label;
  },
} satisfies TechnicalDataItem<{ lastInspection?: boolean; lastInspectionDate?: string }>;

export const BASE_TECHNICAL_DATA = [mileage, driveType, transmission, firstRegistration, fuelType] as const;

export const TECHNICAL_DATA: TechnicalDataItem[] = [
  ...BASE_TECHNICAL_DATA,
  lastInspection,
  {
    icon: "ifont--tc_warranty",
    label: t => t("WARRANTY"),
    // if warranty object is present, it means that the vehicle has a warranty
    data: vehicle => vehicle.warranty,
    format: () => i18next.t("YES"),
  },
  {
    icon: "ifont--tc_warranty",
    label: t => t("WARRANTY PERFORMANCE"),
    data: vehicle => vehicle.warranty,
    format: (warranty, formatters, vehicle, language) => getWarrantyText(warranty, language, formatters).split(":")[0],
  },
  {
    icon: "ifont--tc_engine",
    label: t => t("ENGINE CAPACITY"),
    data: vehicle => vehicle.ccm,
    format: (ccm: number, { formatNumber }) => `${formatNumber(ccm)} ${i18next.t("CCM")}`,
  },
  {
    icon: "ifont--tc_power",
    label: t => t("POWER"),
    data: vehicle => vehicle.ps,
    format: (ccm: number, { formatNumber }) => `${formatNumber(ccm)} ${i18next.t("PS")}`,
  },
  { icon: "ifont--tc_cylinders", label: t => t("CYLINDER"), data: vehicle => vehicle.cylinderNum },
  { icon: "ifont--tc_paint_bucket", label: t => t("EXTERIOR COLOR"), data: vehicle => vehicle.exteriorColor?.name },
  { icon: "ifont--tc_color_lens", label: t => t("INTERIOR COLOR"), data: vehicle => vehicle.interiorColor?.name },
  {
    icon: "ifont--tc_car_inspect",
    label: t => t("VEHICLE CONDITION"),
    data: vehicle => vehicle.vehicleCondition?.name,
  },
  {
    icon: "ifont--tc_battery_capacity",
    label: t => t("BATTERY CAPACITY"),
    data: vehicle => vehicle.batteryCapacity,
    format: (kWh, { formatNumber }) => i18next.t("KWH PLACEHOLDER", { value: formatNumber(kWh) }),
  },
  {
    icon: "ifont--tc_electro_range",
    label: t => t("BATTERY RANGE"),
    data: vehicle => vehicle.batteryRange,
    format: (km, { formatNumber }) => i18next.t("KM PLACEHOLDER", { value: formatNumber(km) }),
  },
  { icon: "ifont--tc_car_door", label: t => t("DOOR NUM"), data: vehicle => vehicle.doorNum },
  { icon: "ifont--tc_people_outline", label: t => t("SEAT NUM"), data: vehicle => vehicle.seatNum },
  {
    icon: "ifont--tc_weight_empty",
    label: t => t("EMPTY WEIGHT"),
    data: vehicle => vehicle.emptyWeight,
    format: (ccm: number, { formatNumber }) => `${formatNumber(ccm)} ${i18next.t("KG")}`,
  },
  {
    icon: "ifont--tc_trailer_load",
    label: t => t("TRAILER WEIGHT MAX"),
    data: vehicle => vehicle.trailerWeightMax,
    format: (ccm: number, { formatNumber }) => `${formatNumber(ccm)} ${i18next.t("KG")}`,
  },
  { icon: "ifont--tc_typenschein", label: t => t("TYPE CERTIFICATE"), data: vehicle => vehicle.typenschein },
  { icon: "ifont--tc_info", label: t => t("COMMISSION NUMBER"), data: vehicle => vehicle.commissionNumber },
];
