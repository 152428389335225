import React, { useState } from "react";
import i18next from "i18next";
import { AugmentedDetailVehicle } from "../types/vehicle";
import { CURRENCY_LABELS, DEFAULT_CURRENCY } from "../../common/constants/Currency";
import useFormatter from "../../common/hooks/useFormatter";
import { addToCartTrack } from "../../analytics/scripts/addToCartTrack/addToCartTrack";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import useLeaseVehicleLink from "../../../processes/leasing/hooks/useLeaseVehicleLink";
import DialogPortal from "../../common/components/DialogPortal";
import Popup from "../../visual-components/components/Popup";
import PopupContent from "../../visual-components/components/PopupContent";
import useGeneralInquiryLink from "../../../processes/general-inquiry/hooks/useGeneralInquiryLink";
import ContextLink from "../../common/components/ContextLink";
import DetailHeroTileWrap from "./DetailHeroTileWrap";

type Props = {
  vehicle: AugmentedDetailVehicle;
};

const LeaseTile: React.FC<Props & { leasingRateFrom: number }> = ({ leasingRateFrom, vehicle }) => {
  const buildLeaseVehicleLink = useLeaseVehicleLink();
  const { formatCurrencyWithoutSuffix } = useFormatter();

  const [isInfoOverlayOpen, setInfoOverlayOpen] = useState(false);
  const hasLeasingDetailInfo = !!vehicle.leasingRateFromCalcBasis?.legalText;
  const toggleInfoOverlay = () => {
    setInfoOverlayOpen(!isInfoOverlayOpen);
  };

  const currency = vehicle.currency ?? DEFAULT_CURRENCY;
  const aaConversionTrackEvent = useAaConversionTrackEvent();

  const labelId = `label-lease-tile`;

  return (
    <DetailHeroTileWrap reserved={vehicle.reserved}>
      {hasLeasingDetailInfo ? (
        <button aria-label={i18next.t("LEASING")} className="price-tile__info" onClick={toggleInfoOverlay}>
          <span className="ifont ifont--info" />
        </button>
      ) : null}
      <ContextLink
        aria-labelledby={labelId}
        className="price-tile__link"
        {...buildLeaseVehicleLink({ slug: vehicle.slug, id: vehicle.vehicleId })}
        onClick={() => {
          addToCartTrack(vehicle);
          aaConversionTrackEvent(vehicle.vehicleId, ConversionEventName.startVehicleLead);
        }}
      />
      <div className="price-tile-inner">
        <p className="price-tile__label">
          {i18next.t("LEASING STARTING AT")} {CURRENCY_LABELS[currency]}
        </p>
        <p className="price-tile__price">{formatCurrencyWithoutSuffix(leasingRateFrom)}</p>
        <p className="price-tile__text-sml">{i18next.t("PER MONTH")}</p>
      </div>
      <div className="price-tile__btn" id={labelId}>
        <span className="ifont ifont--calc_per_100_bold" />
        {i18next.t("LEASE ONLINE")}
      </div>
      <DialogPortal>
        <Popup keyboardDismissable close={toggleInfoOverlay} open={isInfoOverlayOpen}>
          <PopupContent
            body={<p>{vehicle.leasingRateFromCalcBasis?.legalText}</p>}
            gradient={true}
            title={i18next.t("LEASING")}
          />
        </Popup>
      </DialogPortal>
    </DetailHeroTileWrap>
  );
};

const InquireTile: React.FC<Props> = ({ vehicle }) => {
  const buildGeneralInquiryLink = useGeneralInquiryLink();
  const aaConversionTrackEvent = useAaConversionTrackEvent();

  const labelId = `label-inquire-tile`;

  return (
    <DetailHeroTileWrap reserved={vehicle.reserved}>
      <ContextLink
        aria-labelledby={labelId}
        className="price-tile__link"
        {...buildGeneralInquiryLink({
          slug: vehicle.dealer.slug,
          id: vehicle.dealer.insideId,
          vehicleId: vehicle.vehicleId,
        })}
        onClick={() => {
          aaConversionTrackEvent(vehicle.dealer.insideId, ConversionEventName.startVehicleLead);
        }}
      />
      <div className="price-tile-inner">
        <p className="price-tile__label">{i18next.t("LEASING")}</p>
        <p className="price-tile__price" />
        <p className="price-tile__text-sml">{i18next.t("OFFER ON REQUEST")}</p>
      </div>
      <div className="price-tile__btn" id={labelId}>
        <span className="ifont ifont--mail" />
        {i18next.t("INQUIRE")}
      </div>
    </DetailHeroTileWrap>
  );
};

const DetailHeroRightTile: React.FC<Props> = ({ vehicle }) => {
  return (
    <>
      {vehicle.leasingRateFrom ? (
        <LeaseTile leasingRateFrom={vehicle.leasingRateFrom} vehicle={vehicle} />
      ) : (
        <InquireTile vehicle={vehicle} />
      )}
    </>
  );
};

export default DetailHeroRightTile;
