import { createApi } from "@reduxjs/toolkit/query/react";
import baseAuthenticationQuery from "../../auth/services/baseAuthenticationQuery";
import { AuthInfo } from "../../auth/types";
import { BACKEND_API_ENDPOINT } from "../../modules/common/constants/configuration";

export type FavoriteEntry = {
  vehicleId: number;
};

export const favoritesApi = createApi({
  reducerPath: "favoritesApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  tagTypes: ["favoritesList", "session"],
  endpoints: builder => ({
    getAllFavorites: builder.query<FavoriteEntry[], AuthInfo>({
      providesTags: ["favoritesList", "session"],
      query: ({ userId }) => ({
        method: "GET",
        url: `/user/${userId}/favorite`,
      }),
      transformResponse: (response: { data: FavoriteEntry[] }) => response.data,
    }),
    addFavorites: builder.mutation<FavoriteEntry[], AuthInfo & { body: string[] }>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/favorite`,
        body: body.map(vehicleId => ({ vehicleId: parseInt(vehicleId) })),
      }),
      transformResponse: (response: { data: FavoriteEntry[] }) => response.data,
    }),
    deleteFavorite: builder.mutation<void, AuthInfo & { id: string }>({
      query: ({ userId, id }) => ({
        method: "DELETE",
        url: `/user/${userId}/favorite/${id}`,
      }),
    }),
  }),
});

export const { useGetAllFavoritesQuery, useAddFavoritesMutation, useDeleteFavoriteMutation } = favoritesApi;
