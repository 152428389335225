import { useEffect } from "react";

const usePrerenderReady = (dataFinishedLoading: boolean = true) => {
  useEffect(() => {
    if (dataFinishedLoading) {
      window.prerenderReady = true;
    }
  }, [dataFinishedLoading]);
  return null;
};

export default usePrerenderReady;
