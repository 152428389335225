import { RawBrandFilterItem } from "../vehicleFilterReferenceDataApi";
import { AggregatedFilterItem, FilterItemReferenceData } from "./types";

export const modelFilterItemMapper = (entries: RawBrandFilterItem[]): Record<string, FilterItemReferenceData> => {
  return entries.reduce<Record<string, FilterItemReferenceData>>((agg, brand) => {
    const entries = brand.models.map(
      ({ id, name, nameNormalized }): AggregatedFilterItem => ({
        value: `${id}`,
        name,
        isBasicFilterValue: false,
        nameNormalized: nameNormalized,
      }),
    );
    agg[`${brand.id}`] = {
      list: entries,
      map: Object.fromEntries(entries.map(entry => [entry.value, entry])),
      mapByName: Object.fromEntries(entries.map(entry => [entry.name, entry])),
    };
    return agg;
  }, {});
};
