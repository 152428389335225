import { useRef, useState } from "react";
import { SelectItem } from "../components/form/SingleOptionSelect";
import useSelectKeyboard from "./useSelectKeyboard";

function useKeyboardSelect<OptionType extends SelectItem<any>>(
  options: OptionType[],
  onChange: (option: OptionType) => void,
  closeOnEnter = true,
) {
  const hoveredItem = useRef<OptionType | null>(null);
  const [keyboardNavigatedItem, setKeyboardNavigatedItem] = useState<OptionType | null>(null);

  const isItemStateStillValid = (item: OptionType): boolean => {
    return (
      options.find(({ name: itemName, value: itemValue }) => itemName === item.name && itemValue === item.value) !==
      undefined
    );
  };

  const onChooseFirstItem = (): boolean => {
    if (hoveredItem.current && isItemStateStillValid(hoveredItem.current)) {
      onChange(hoveredItem.current);
    } else {
      options[0] && onChange(options[0]);
    }
    return closeOnEnter;
  };

  const updateMouseHoverState = (item: OptionType | null) => {
    hoveredItem.current = item;
    setKeyboardNavigatedItem(null);
  };

  const { onKeyDown } = useSelectKeyboard(onChooseFirstItem, isNext => {
    if (!options[0]) {
      return;
    }
    if (hoveredItem.current && isItemStateStillValid(hoveredItem.current)) {
      const index = options.indexOf(hoveredItem.current);
      const newItemIndex = isNext ? index + 1 : index - 1;
      const item = options[newItemIndex];
      if (!item) {
        return;
      }
      setKeyboardNavigatedItem(item);
      hoveredItem.current = item;
    } else {
      setKeyboardNavigatedItem(options[0]);
      hoveredItem.current = options[0];
    }
  });

  return {
    updateMouseHoverState,
    onKeyDown,
    keyboardNavigatedItem,
  };
}

export default useKeyboardSelect;
