import { FilterTypeDefinition } from "../types/filterTypes";
import { TagFilterDef } from "../types/filterDefinitionTypes";
import { arrayLengthExtractor } from "../../helpers/arrayLengthExtractor";
import { searchQueryExtractor } from "../../helpers/searchQueryExtractor";
import { appliedFacetValuesExtractor, facetKeyExtractor } from "../../helpers/facetKeyExtractor";
import getNameForReferenceId from "../../helpers/getNameForReferenceId";

export const tagFilterType: FilterTypeDefinition<TagFilterDef, string[]> = {
  getConfigurationCount: arrayLengthExtractor,
  paramType: "stringArray",
  getConfigurationLabels: (val, _, filter, filterData) => {
    return val.map(id => getNameForReferenceId(id, filter, filterData));
  },
  getSearchQueries: searchQueryExtractor,
  facetMapping: {
    getKeys: facetKeyExtractor,
    getAppliedValues: appliedFacetValuesExtractor,
  },
};
