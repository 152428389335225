import i18next from "i18next";
import speechSdk from "microsoft-cognitiveservices-speech-sdk";
import { useEffect, useRef, useState } from "react";
import useLanguage from "../../../router/hooks/useLanguage";
import { useLazyGetSpeechTokenQuery } from "../services/speechToTextApi";
import { useLazyFilterReferenceDataQuery } from "../../vehicle-search/services/vehicleFilterReferenceDataApi";
import { buildErrorNotification } from "../../notification/helpers/notificationFactory";
import useNotification from "../../notification/hooks/useNotification";
import {
  determineIfAzureSpeechTokenIsStillValid,
  setupAzureSpeechToText,
  supportsOnDeviceSpeechRecognition,
} from "../services/azure-speech-to-text";
import isNil, { notNil } from "../../common/helpers/isNil";
import useStableFunction from "../../common/hooks/useStableFunction";

export const useAzureSpeechRecognition = () => {
  const [recognizerIsReady, setRecognizerIsReady] = useState(false);
  const [recognizerIsLoading, setRecognizerIsLoading] = useState(false);
  const language = useLanguage(); // we only want to have the language to create the correct locale for azure
  const [requestSpeechToken, speechTokenResult] = useLazyGetSpeechTokenQuery();
  const pushNotification = useNotification();
  const [requestFilterReferenceData, filterReferenceResult] = useLazyFilterReferenceDataQuery();
  const filterReferenceData = filterReferenceResult.currentData;
  const speechToken = speechTokenResult.currentData;

  const speechRecognizer = useRef<speechSdk.SpeechRecognizer | null>(null);

  const defaultErrorMessage = i18next.t("GENERIC SPEECH RECOGNITION ERROR");

  type LoadOptions = {
    ignoreCachedSpeechToken: boolean;
  };

  // preemptively load the speech sdk
  useEffect(() => {
    const dynamicallyImportSpeechSdk = async () => {
      await import("microsoft-cognitiveservices-speech-sdk");
    };

    if (supportsOnDeviceSpeechRecognition) {
      return;
    }

    const timeout = setTimeout(() => {
      dynamicallyImportSpeechSdk();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const loadRecognizer = useStableFunction(async ({ ignoreCachedSpeechToken = false }: LoadOptions) => {
    // ignore load requests if the recognizer is already loading
    if (recognizerIsLoading) {
      throw new Error("Recognizer is already loading");
    }

    setRecognizerIsReady(false);
    setRecognizerIsLoading(true);
    let filterRefData = filterReferenceData;

    // per default we want to use the cached speech token
    const tokenResult = await requestSpeechToken(undefined, !ignoreCachedSpeechToken);
    const { data: speechToken } = tokenResult;

    if (isNil(filterRefData)) {
      const result = await requestFilterReferenceData(undefined, true);
      const { data } = result;
      filterRefData = data;
    }

    if (isNil(speechToken) || isNil(filterRefData) || isNil(language)) {
      setRecognizerIsLoading(false);
      return null;
    }

    const recognizer = await setupAzureSpeechToText({
      speechToken,
      filterReferenceData: filterRefData,
      locale: language,
    });

    speechRecognizer.current = recognizer;
    setRecognizerIsReady(true);
    setRecognizerIsLoading(false);
  });

  const start = useStableFunction(async () => {
    try {
      let recognizerNeedsReload = false;

      if (notNil(speechToken)) {
        const speechTokenIsStillValid = determineIfAzureSpeechTokenIsStillValid(speechToken.token);
        recognizerNeedsReload = !speechTokenIsStillValid;
      }

      if (recognizerNeedsReload) {
        // force a new token
        await loadRecognizer({ ignoreCachedSpeechToken: true });
      }

      // this should only be the case on the first load
      if (!recognizerIsReady) {
        await loadRecognizer({ ignoreCachedSpeechToken: false });
      }
      speechRecognizer.current?.recognizeOnceAsync();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn("Could not start azure speech recognition: ", error);
      pushNotification(
        buildErrorNotification({
          icon: "mic",
          text: defaultErrorMessage,
        }),
      );
    }
  });

  const stop = useStableFunction(() => {
    speechRecognizer.current?.stopContinuousRecognitionAsync();
  });

  return {
    recognizerIsReady,
    loadRecognizer,
    speechRecognizer,
    start,
    stop,
  };
};
