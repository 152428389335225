import { VehicleSearchResponse, VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import { Ad } from "../../hygraph/vo";
import { getSearchResultsWithAds } from "../helpers/searchResult";
import { notNil } from "../../common/helpers/isNil";
import useSearchAds from "./useSearchAds";

const AD_ONE_BEFORE_SEARCH_RESULT = 1;
const AD_TWO_BEFORE_SEARCH_RESULT = 15;
const AD_THREE_BEFORE_SEARCH_RESULT = 29;

const SEARCH_RESULT_AD_INDEXES = [
  AD_ONE_BEFORE_SEARCH_RESULT,
  AD_TWO_BEFORE_SEARCH_RESULT,
  AD_THREE_BEFORE_SEARCH_RESULT,
];

type SearchResultItem = VehicleSearchResponseItem | Ad;
export type SearchResultItems = SearchResultItem[];

type SearchResultReturn = {
  searchResults: SearchResultItems;
  isLoaded: boolean;
};

const useSearchResults = (vehicleData: VehicleSearchResponse | undefined, hasAds: boolean): SearchResultReturn => {
  const searchAds = useSearchAds(vehicleData?.queryID ?? "", hasAds, SEARCH_RESULT_AD_INDEXES);
  const vehicles = vehicleData?.hits || [];
  const searchResults =
    hasAds && notNil(searchAds) ? getSearchResultsWithAds(vehicles, searchAds, SEARCH_RESULT_AD_INDEXES) : vehicles;

  const isLoaded = hasAds ? notNil(searchAds) && notNil(vehicleData) : notNil(vehicleData);

  return {
    searchResults,
    isLoaded,
  };
};

export default useSearchResults;
