import React, { useState } from "react";
import classNames from "classnames";
import { ArrayParam } from "use-query-params";
import SelectField from "../../../visual-components/components/form/SelectField";
import CustomSelectOptionCheckbox from "../../../visual-components/components/form/CustomSelectOptionCheckbox";
import {
  AppliedTag,
  buildGroupAppliedTagList,
  mapAppliedTagToQueryParam,
  TagType,
} from "../../services/filter-types/groupSelectFilterTypeHelpers";
import Checkbox from "../../../visual-components/components/form/Checkbox";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import getUniqueEntries from "../../../common/helpers/getUniqueEntries";
import filterDuplicatesByName from "../../../common/helpers/filterDuplicatesByName";
import { FilterItem, GroupFilterItem } from "./FilterItem";
import { TagFilterBlock } from "./TagFilter";
import FilterBlock from "./FilterBlock";

type Props = {
  title: string;
  queryParam: string;
  data: GroupFilterItem[];
  fixOpen: boolean;
  // if renderOnlyGroups is chosen and no child element is selected, this will render a TagFilter instead
  // it also hides groups which are not basic filterValues
  renderOnlyGroups: boolean;
};

const CheckboxGroup = ({
  value,
  label,
  items,
  isSingleChild,
  checked,
  anyChildChecked,
  inactive,
  updateGroup,
  updateChild,
}: {
  value: string;
  label: string;
  checked: boolean;
  anyChildChecked: boolean;
  inactive: boolean;
  // items to display - potentially filtered by search
  items: { value: string; label: string; inactive: boolean; selected: boolean }[];
  // indicates whether the group is a single child group by default
  isSingleChild: boolean;
  updateGroup: (value: string, checked: boolean) => void;
  updateChild: (value: string, checked: boolean) => void;
}) => {
  return (
    <li key={label} aria-selected={checked} className="custom-select__option-group" role="option">
      <div className={classNames("custom-select-option-group__title", { inactive })}>
        <Checkbox
          checked={checked}
          label={label}
          subChecked={anyChildChecked}
          value={value}
          onChange={e => {
            updateGroup(value, e.target.checked);
          }}
        />
      </div>
      {items.length > 0 && !isSingleChild ? (
        <ul className="custom-select__subgroup-list">
          {items.map(({ value, label, inactive, selected }, i) => (
            <CustomSelectOptionCheckbox
              key={`${label}-${i}`}
              checked={selected || checked}
              className="custom-select-subgroup__option"
              inactive={inactive}
              label={label}
              value={value}
              onChange={(value, checked) => {
                updateChild(value, checked);
              }}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

type AggregatedGroupItemType = {
  value: string;
  label: string;
  selected: boolean;
  inactive: boolean;
};

type AggregatedGroupType = {
  value: string;
  label: string;
  isGroupInactive: boolean;
  isGroupSelected: boolean;
  isBasicFilterValue: boolean;
  hasSelectedChild: boolean;
  items: AggregatedGroupItemType[];
};

const GroupSelectFilter: React.FC<Props> = ({ title, queryParam, data, fixOpen, renderOnlyGroups }) => {
  const [persistedTags, setTags] = useFilterQueryParam(queryParam, ArrayParam);
  const tagList = persistedTags || [];
  const hasAppliedTags = tagList.length > 0;

  const appliedTags = buildGroupAppliedTagList(tagList);

  const queryMap = appliedTags.reduce<{ groups: Record<string, boolean>; children: Record<string, boolean> }>(
    (agg, { type, value }) => {
      if (type === "c") {
        agg.children[value] = true;
      } else {
        agg.groups[value] = true;
      }
      return agg;
    },
    { groups: {}, children: {} },
  );

  const childrenNames = data.reduce<Record<string, string>>((agg, group) => {
    group.children.forEach(child => {
      agg[child.value] = child.name;
    });
    return agg;
  }, {});

  const [searchInput, setSearchInput] = useState("");

  const normalizedSearchInput = searchInput.trim().toLowerCase();

  const aggregatedGroups = data.map(({ value, name, isBasicFilterValue, children }): AggregatedGroupType => {
    const uniqueChildren: FilterItem[] = filterDuplicatesByName(children);
    const aggregatedItems = uniqueChildren.map(
      (item: FilterItem): AggregatedGroupItemType => ({
        value: item.value,
        label: item.name,
        selected: queryMap.children[item.value],
        inactive: item.occurrence === 0 && !hasAppliedTags,
      }),
    );
    return {
      value: value,
      label: name,
      isGroupSelected: !!queryMap.groups[value],
      hasSelectedChild: aggregatedItems.some(({ selected }) => selected),
      isBasicFilterValue,
      isGroupInactive: aggregatedItems.every(({ inactive }) => inactive),
      items: aggregatedItems,
    };
  });

  const aggregatedGroupMap = aggregatedGroups.reduce<Record<string, AggregatedGroupType>>((agg, group) => {
    agg[group.value] = group;
    return agg;
  }, {});

  const filteredGroupRecords = aggregatedGroups.reduce<Record<string, AggregatedGroupType>>((agg, group) => {
    if (group.label?.toLowerCase().includes(normalizedSearchInput)) {
      agg[group.value] = group;
    } else {
      const newItems = group.items.filter(({ label }) => label.toLowerCase().includes(normalizedSearchInput));
      if (newItems.length > 0) {
        agg[group.value] = {
          ...group,
          items: newItems,
        };
      }
    }

    return agg;
  }, {});

  const hasSelectedChildItemsExplicitly = aggregatedGroups.some(
    ({ isGroupSelected, hasSelectedChild }) => !isGroupSelected && hasSelectedChild,
  );

  const reset = () => {
    setTags([]);
  };

  const pushTags = (tags: AppliedTag[]) => {
    const serializedTags = tags.map(tag => mapAppliedTagToQueryParam(tag));
    setTags(getUniqueEntries(serializedTags));
  };

  // remove all child elements in list from appliedTags and return new list
  const filterAllChildren = (group: AggregatedGroupType): AppliedTag[] => {
    const childItemValues = group.items.map(({ value }) => value);
    return appliedTags.filter(({ type, value }) => type === TagType.Group || !childItemValues.includes(value));
  };

  const filterGroup = (filterTags: AppliedTag[], groupValue: string): AppliedTag[] => {
    return filterTags.filter(({ type, value }) => type !== TagType.Group || value !== groupValue);
  };

  const addGroup = (groupValue: string) => {
    const childItems = aggregatedGroupMap[groupValue];
    const newFilterTags = filterAllChildren(childItems);

    newFilterTags.push({
      type: TagType.Group,
      value: groupValue,
    });

    pushTags(newFilterTags);
  };
  const removeGroup = (groupValue: string) => {
    const childItems = aggregatedGroupMap[groupValue];
    const newFilterTags = filterAllChildren(childItems);

    pushTags(filterGroup(newFilterTags, groupValue));
  };
  const getGroup = (childValue: string) => {
    return aggregatedGroups.find(({ items }) => items.map(({ value }) => value).find(value => value === childValue));
  };
  const addChild = (childValue: string) => {
    const group = getGroup(childValue);
    if (group === undefined) {
      return;
    }
    const hasNonCheckedItems = group.items.some(({ value }) => {
      const isCurrentlyToggledItem = value === childValue;
      const isCurrentlyAppliedItem = appliedTags.find(
        ({ type, value: appliedValue }) => type === TagType.Child && value === appliedValue,
      );
      return !isCurrentlyToggledItem && !isCurrentlyAppliedItem;
    });

    if (hasNonCheckedItems) {
      pushTags([...appliedTags, { type: TagType.Child, value: childValue }]);
    } else {
      addGroup(group.value);
    }
  };
  const removeChild = (childValue: string) => {
    const group = getGroup(childValue);

    if (group === undefined) {
      return;
    }
    if (group.isGroupSelected) {
      const filterTagsWithoutGroup = filterGroup(appliedTags, group.value);
      const childItemsExceptRemoved = group.items
        .filter(({ value }) => value !== childValue)
        .map(({ value }): AppliedTag => ({ type: TagType.Child, value }));
      pushTags([...filterTagsWithoutGroup, ...childItemsExceptRemoved]);
    } else {
      pushTags([...appliedTags.filter(({ type, value }) => !(type === "c" && value === childValue))]);
    }
  };

  if (renderOnlyGroups && !hasSelectedChildItemsExplicitly) {
    return (
      <TagFilterBlock
        count={appliedTags.length}
        reset={reset}
        title={title}
        data={aggregatedGroups
          // hide groups which are not selected and have no selected children
          .filter(({ isBasicFilterValue, isGroupSelected }) => isBasicFilterValue || isGroupSelected)
          .map(({ value, label, isGroupSelected, isGroupInactive }) => ({
            value,
            name: label,
            inactive: isGroupInactive,
            selected: isGroupSelected,
          }))}
        update={(value, selected) => {
          if (selected) {
            addGroup(value);
          } else {
            removeGroup(value);
          }
        }}
      />
    );
  }

  return (
    <FilterBlock bg className="filter__select" count={appliedTags.length} reset={reset} title={title}>
      <SelectField
        fixOpen={fixOpen}
        search={{
          value: searchInput,
          setValue: setSearchInput,
        }}
        value={appliedTags
          .map(({ type, value }) => {
            if (type === TagType.Group) {
              return aggregatedGroupMap[value].label;
            }
            return childrenNames[value];
          })
          .join(", ")}
      >
        {Object.values(filteredGroupRecords).map(
          ({ value, label, isGroupSelected, isGroupInactive, hasSelectedChild, items }) => (
            <CheckboxGroup
              key={label}
              anyChildChecked={hasSelectedChild}
              checked={isGroupSelected}
              inactive={isGroupInactive}
              isSingleChild={aggregatedGroupMap[value].items.length === 1}
              items={items}
              label={label}
              value={value}
              updateChild={(value, checked) => {
                if (checked) {
                  addChild(value);
                } else {
                  removeChild(value);
                }
              }}
              updateGroup={(value, checked) => {
                if (checked) {
                  addGroup(value);
                } else {
                  removeGroup(value);
                }
              }}
            />
          ),
        )}
      </SelectField>
    </FilterBlock>
  );
};

export default GroupSelectFilter;
