import React from "react";
import classNames from "classnames";
import useMatchmakerLink from "../../hooks/useMatchmakerLink";
import { ButtonLink } from "../../../../modules/visual-components/components/Button";
import BannerImage from "./BannerImage";

type Props = {
  title: string;
  isInitialized: boolean;
  lead: string;
  buttonText: string;
  imageMobile: string;
  imageDesktop: string;
};

const IMAGE_MOBILE_BREAKPOINT = 768;
const IMAGE_DESKTOP_BREAKPOINT = 1260;

const BannerAccess: React.FC<Props> = ({ title, lead, buttonText, imageMobile, imageDesktop, isInitialized }) => {
  const matchmakerLink = useMatchmakerLink();

  return (
    <div className={classNames("banner__item--generic", "banner__item--max", { "banner__item--small": isInitialized })}>
      <p className="banner-item__title">{title}</p>
      <p className="banner-item__copy">{lead}</p>

      <ButtonLink transparent className="banner_item__btn" icon="sparks_stars" {...matchmakerLink}>
        {buttonText}
      </ButtonLink>

      <BannerImage
        alt=""
        className="banner-item__img"
        desktopBreakpoint={IMAGE_DESKTOP_BREAKPOINT}
        desktopSrc={imageDesktop}
        mobileBreakpoint={IMAGE_MOBILE_BREAKPOINT}
        mobileSrc={imageMobile}
        pictureClassName="banner-item__picture banner-item-picture--aside"
      />
    </div>
  );
};
export default BannerAccess;
