import React, { ReactNode, useEffect, useRef, useState } from "react";
import i18next from "i18next";
import classNames from "classnames";
import debounce from "../../common/helpers/debounce";
import useEvent from "../../common/hooks/useEvent";
import { SELECTED_FILTER_BLOCK_CLASS } from "./selected-filter/FilterBlock";

type Props = {
  children: ReactNode;
};

const SelectedFilterPanel: React.FC<Props> = ({ children }) => {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const overflowWrapper = useRef<HTMLDivElement>(null);

  const expandableCheck = useEvent(() => {
    // if the dom element is not accessible (e.g. due to unmount) we'll always show the link
    if (!overflowWrapper.current) {
      return;
    }

    const element = overflowWrapper.current;

    // Get children with querySelector to prevent checking non-filter elements
    const children = element.querySelectorAll(`.${SELECTED_FILTER_BLOCK_CLASS}`);
    const firstChild = children[0] as HTMLElement | undefined;
    const lastChild = children[children.length - 1] as HTMLElement | undefined;

    // If offset top is same, all children are on the same line
    const isSingleLine = firstChild?.offsetTop === lastChild?.offsetTop;
    setIsExpandable(!isSingleLine);

    // Collapse the panel if not expandable
    if (isExpanded && isSingleLine) {
      setIsExpanded(false);
    }
  });

  useEffect(() => {
    expandableCheck();
  }, [isExpanded, expandableCheck]);

  useEffect(() => {
    const observer = new ResizeObserver(debounce(expandableCheck));
    overflowWrapper.current && observer.observe(overflowWrapper.current);
    return () => {
      observer.disconnect();
    };
  }, [expandableCheck]);
  return (
    <div
      ref={overflowWrapper}
      aria-label={i18next.t("FILTER")}
      className={classNames("filter-selected-wrap", { expanded: isExpanded })}
      role="navigation"
    >
      {children}
      {isExpandable ? (
        <div className="toggle-link">
          <span
            className="toggle-link__text"
            onClick={() => {
              setIsExpanded(true);
            }}
          >
            <span className="ifont ifont--angle_down" />
            {i18next.t("SHOW ALL")}
          </span>
          <span
            className="toggle-link__text toggle-link__text--expanded"
            onClick={() => {
              setIsExpanded(false);
            }}
          >
            <span className="ifont ifont--angle_up" />
            {i18next.t("SHOW LESS")}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default SelectedFilterPanel;
