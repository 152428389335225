import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../common/constants/configuration";

export type SpeechToken = {
  token: string;
};

export const speechToTextApi = createApi({
  reducerPath: "speechToTextApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  tagTypes: [],
  endpoints: builder => ({
    getSpeechToken: builder.query<SpeechToken, void>({
      providesTags: [],
      query: () => ({
        method: "POST",
        url: `/speech-service/token`,
      }),
      transformResponse: (response: { data: SpeechToken }) => response.data,
    }),
  }),
});

export const { useLazyGetSpeechTokenQuery } = speechToTextApi;
