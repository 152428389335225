import { useContext } from "react";
import { HistoryState } from "../../../router/constants/State";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { BounceOffType, InBounceOffContext } from "../../../whitelabel/vehicle-detail/components/BounceOffPopup";

function useLeaseVehicleLink(): (args: {
  slug: string | undefined | null;
  id: string | number;
  totalRuntime?: string;
}) => {
  to: string;
  state?: Record<string, number>;
  rel: string;
} {
  const isInBounceOffContext = useContext(InBounceOffContext);
  const { leaseVehiclePath } = usePathBuilder();
  return ({ slug, id, totalRuntime }) => {
    if (isInBounceOffContext) {
      return {
        to: `#${BounceOffType.LEASING}`,
        rel: "nofollow",
      };
    }
    return {
      to: leaseVehiclePath({ slug: slug!, id: `${id}`, totalRuntime }),
      state: { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 },
      rel: "nofollow",
    };
  };
}

export default useLeaseVehicleLink;
