import React from "react";
import classNames from "classnames";
import { buildAdditionalUrlParams, buildSrc, IMAGE_FIT_TYPES } from "./Image";

type ImageSizeDefinition = {
  aspectRatio: string;
  width: number;
};

type ImageSizes = {
  desktop: ImageSizeDefinition;
  mobile: ImageSizeDefinition;
};

export enum IMAGE_TRANSFORM_TYPES {
  homeHero = "homeHero",
  homeBanner = "homeBanner",
  whitelabelBanner = "whitelabelBanner",
}

const IMAGE_TRANSFORMER_MAP: Record<IMAGE_TRANSFORM_TYPES, ImageSizes> = {
  homeHero: {
    desktop: {
      aspectRatio: "50:17",
      width: 1275,
    },
    mobile: {
      aspectRatio: "4:3",
      width: 700,
    },
  },
  homeBanner: {
    mobile: {
      aspectRatio: "335:296",
      width: 670,
    },
    desktop: {
      aspectRatio: "29:11",
      width: 1276,
    },
  },
  whitelabelBanner: {
    mobile: {
      aspectRatio: "52:9",
      width: 1560,
    },
    desktop: {
      aspectRatio: "485:449",
      width: 970,
    },
  },
};

const MOBILE_BREAKPOINT_MAP: Record<IMAGE_TRANSFORM_TYPES, number> = {
  homeHero: 700,
  homeBanner: 768,
  whitelabelBanner: 860,
};

type Props = {
  alt?: string;
  className?: string;
  desktopSrc: string;
  fit: IMAGE_FIT_TYPES;
  grayscale?: boolean;
  mobileSrc: string;
  loading?: "lazy" | "eager";
  pictureClassName?: string;
  imageSizes: IMAGE_TRANSFORM_TYPES;
};

const ImageTwoSizes: React.FC<Props> = ({
  alt = "",
  className,
  desktopSrc,
  fit,
  grayscale = false,
  mobileSrc,
  loading = "lazy",
  pictureClassName,
  imageSizes,
}) => {
  const sizes = IMAGE_TRANSFORMER_MAP[imageSizes];
  const mobileBreakpoint = MOBILE_BREAKPOINT_MAP[imageSizes];
  const additionalUrlParams = buildAdditionalUrlParams({ fit, grayscale });
  return (
    <picture className={pictureClassName}>
      <source
        media={`screen and (min-width:${mobileBreakpoint})`}
        srcSet={buildSrc({
          width: sizes.desktop.width,
          aspectRatio: sizes.desktop.aspectRatio,
          src: desktopSrc,
          withRetina: true,
          additionalUrlParams,
        })}
      />
      <source
        media={`screen and (max-width:${mobileBreakpoint - 1}px)`}
        srcSet={buildSrc({
          width: sizes.mobile.width,
          aspectRatio: sizes.mobile.aspectRatio,
          src: mobileSrc,
          withRetina: true,
          additionalUrlParams,
        })}
      />
      <img
        alt={alt}
        className={classNames("img", className)}
        loading={loading}
        src={buildSrc({
          width: sizes.desktop.width,
          aspectRatio: sizes.desktop.aspectRatio,
          src: desktopSrc,
          withRetina: false,
          additionalUrlParams,
        })}
      />
    </picture>
  );
};

export default ImageTwoSizes;
