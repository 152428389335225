import React, { ComponentProps } from "react";
import classNames from "classnames";
import i18next from "i18next";
import { AnimatePresence } from "framer-motion";
import DialogPortal from "../../common/components/DialogPortal";
import Slideshow from "../../common/components/Slideshow";
import useEscapeKeyListener from "../../common/hooks/useEscapeKeyListener";
import CssPresenceAnimation from "../../common/components/CssPresenceAnimation";
import { IMAGE_TRANSFORM_TYPES } from "./Image";
import IconButton from "./IconButton";

type Props = {
  openIndex: number | undefined;
  header?: {
    title: string;
    subline: string;
  };
  closeOverlay: () => void;
  media: ComponentProps<typeof Slideshow>["media"];
  imageSizes: IMAGE_TRANSFORM_TYPES;
  className: string;
};

const GalleryOverlay: React.FC<Props> = ({ openIndex, header, media, imageSizes, closeOverlay, className }) => {
  const isOpen = openIndex !== undefined;
  useEscapeKeyListener(closeOverlay, isOpen);
  return (
    <DialogPortal>
      <AnimatePresence>
        {isOpen ? (
          <CssPresenceAnimation<HTMLDialogElement>
            animationClassName="gallery-overlay-animation-enter"
            durationMs={300}
          >
            {(ref, classNameOverwrite) => (
              <dialog
                ref={ref}
                open={isOpen}
                className={classNames(
                  "gallery-overlay",
                  "gallery-overlay-animation",
                  { open: isOpen },
                  classNameOverwrite,
                )}
              >
                <IconButton
                  ariaLabel={i18next.t("CLOSE")}
                  className="gallery-overlay__close"
                  icon="close"
                  onClick={closeOverlay}
                />
                {header ? (
                  <div className="gallery-overlay__header">
                    <div className="gallery-overlay__brand">{header.subline}</div>
                    <div className="gallery-overlay__title">{header.title}</div>
                  </div>
                ) : null}
                <div className="gallery-overlay__body">
                  <Slideshow
                    isDetailView
                    appliedIndex={openIndex}
                    className={className}
                    imageSizes={imageSizes}
                    media={media}
                    title={header?.title}
                  />
                </div>
              </dialog>
            )}
          </CssPresenceAnimation>
        ) : null}
      </AnimatePresence>
    </DialogPortal>
  );
};

export default GalleryOverlay;
