import { STORAGE_KEYS } from "../../common/constants/Storage";
import getUniqueEntries from "../../common/helpers/getUniqueEntries";
import useLocalStorage from "../../common/hooks/useLocalStorage";

function useVehicleList(key: STORAGE_KEYS) {
  const [storedComparisonList, setStoredComparisonList] = useLocalStorage<string[]>(key, []);
  const addVehicles = (vehicleIds: string[]) => {
    setStoredComparisonList(getUniqueEntries([...vehicleIds, ...storedComparisonList]));
  };
  const removeVehicles = (vehicleIds: string[]) => {
    setStoredComparisonList(storedComparisonList.filter(id => !vehicleIds.includes(id)));
  };
  const removeVehicle = (vehicleId: string) => {
    removeVehicles([vehicleId]);
  };
  const containsVehicle = (vehicleId: string) => {
    return storedComparisonList.includes(vehicleId);
  };
  return {
    list: storedComparisonList,
    toggleVehicle: (vehicleId: string) => {
      const isInList = containsVehicle(vehicleId);
      if (isInList) {
        removeVehicle(vehicleId);
      } else {
        addVehicles([vehicleId]);
      }
    },
    addVehicle: (vehicleId: string) => {
      addVehicles([vehicleId]);
    },
    overrideVehicles: setStoredComparisonList,
    addVehicles,
    removeVehicle,
    removeVehicles,
    containsVehicle,
    clear: () => {
      setStoredComparisonList([]);
    },
  };
}

export default useVehicleList;
