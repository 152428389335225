import i18next from "i18next";
import { MouseEventHandler } from "react";
import useNotification from "../../notification/hooks/useNotification";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { HistoryState } from "../../../router/constants/State";
import { buildInfoNotification } from "../../notification/helpers/notificationFactory";
import useVehicleComparisonList from "./useVehicleComparisonList";

function useVehicleComparisonListLink(args?: { absolute?: boolean; base?: string }): {
  onClick: MouseEventHandler;
  to: string;
  state: Record<string, boolean>;
} {
  const pushNotification = useNotification();
  const { list } = useVehicleComparisonList();
  const { comparisonPath } = usePathBuilder();

  return {
    to: comparisonPath({ vehicleIds: list, absolute: args?.absolute, base: args?.base }),
    state: { [HistoryState.CONTEXT_DEPENDENT_LINK]: true },
    onClick: e => {
      if (list.length === 0) {
        pushNotification(
          buildInfoNotification({
            icon: "compare",
            text: i18next.t("NO VEHICLES IN COMPARISON LIST SNACKBAR TEXT"),
          }),
        );
        e.preventDefault();
      } else if (list.length === 1) {
        pushNotification(
          buildInfoNotification({
            icon: "compare",
            text: i18next.t("ONLY ONE VEHICLE IN COMPARISON LIST SNACKBAR TEXT"),
          }),
        );
        e.preventDefault();
      }
    },
  };
}

export default useVehicleComparisonListLink;
