import React from "react";
import AlgoliaVehicleSearchCarCard from "../../vehicle/components/AlgoliaVehicleSearchCarCard";
import { buildIndexName, Indices } from "../../algolia/services/search";
import { VehicleSearchResponse, VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import Locale from "../../common/constants/Locale";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";

type Props = {
  index: number;
  language: Locale;
  page: number;
  userId?: string;
  vehicle: VehicleSearchResponseItem;
  vehicleData: VehicleSearchResponse;
};

const SearchResultCarCard: React.FC<Props> = ({ index, language, page, userId, vehicle, vehicleData }) => {
  return (
    <AlgoliaVehicleSearchCarCard
      animationIndex={index}
      firstImageLoading={index === 0 ? "eager" : "lazy"}
      page={page}
      trackingReferrer={TrackingReferrer.None}
      vehicle={vehicle}
      aaEvent={{
        queryID: vehicleData.queryID!,
        objectIDs: [vehicle.objectID],
        index: buildIndexName(Indices.vehicle, language),
        userToken: userId ?? "",
        eventName: "clickVehicle",
        positions: [vehicleData?.page * vehicleData?.hitsPerPage + index + 1],
      }}
    />
  );
};

export default SearchResultCarCard;
