import React from "react";
import i18next from "i18next";
import classNames from "classnames";
import useFormatter from "../../modules/common/hooks/useFormatter";
import { getTechnicalItems } from "../../modules/vehicle/helpers/technicalDataHelpers";
import { AugmentedDetailVehicle } from "../../modules/vehicle/types/vehicle";
import useLanguage from "../../router/hooks/useLanguage";

type Props = {
  vehicle: AugmentedDetailVehicle;
};

const TechnicalData: React.FC<Props> = ({ vehicle }) => {
  const formatters = useFormatter();
  const language = useLanguage();

  const technicalItems = getTechnicalItems(vehicle);

  return (
    <table className="table table--zebra">
      <tbody>
        {technicalItems.map(({ label, data, icon, format }) => (
          <tr key={label(i18next.t)}>
            <th>
              <span className={classNames("ifont", icon)} />
              {label(i18next.t)}
            </th>
            <td className={"value"}>{format ? format(data(vehicle), formatters, vehicle, language) : data(vehicle)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TechnicalData;
