import { createApi } from "@reduxjs/toolkit/query/react";
import { MultipleQueriesResponse, SearchResponse } from "@algolia/client-search";
import { skipToken } from "@reduxjs/toolkit/query";
import useLanguage from "../../../router/hooks/useLanguage";
import { Currency } from "../../hygraph/vo";
import { VehicleConditionCode } from "../constants/VehicleConditionCode";
import { algoliaBaseQuery, algoliaMultiIndexBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";

export type VehicleSearchResponseItem = {
  bodyType: string;
  brand: string;
  currency: Currency;
  dealerCity: string;
  dealerId: number;
  dealerName: string;
  dealerZip: string;
  emissionClass: string;
  exteriorColor: string;
  firstRegistration: string;
  fuelType: string;
  fuelTypeGroup: string;
  images: string[];
  km: number;
  leasingRateFrom: number;
  model: string;
  objectID: string;
  priceB2CGross: number;
  priceInitialB2CGross: number;
  pricePreparationGross?: number;
  promotionList: { name: string; paPromotionComponentId: number }[];
  qualityChecked?: { id: number; name: string };
  ps: number;
  reserved: boolean;
  slug: string;
  title: string;
  transmission: string;
  vehicleCondition: string;
  vehicleConditionCode: VehicleConditionCode;
  vehicleId: number;
  vehicleType: string;
};

export type VehicleSearchResponse = SearchResponse<VehicleSearchResponseItem>;

type VehicleSearchQueryOptions = Omit<AlgoliaRequestOptions, "language">;

// Algolia limits number of maximum items obtained using pagination
// Some components rely on this limit.
// https://www.algolia.com/doc/api-reference/api-parameters/paginationLimitedTo
export const PAGINATION_LIMITED_TO = 1000;

type AlgoliaMultiSearchQueryOptions = [string | undefined, AlgoliaRequestOptions & { replica?: string }][];

export function searchVehicleIndex(
  query = "",
  { language, replica, ...requestOptions }: AlgoliaRequestOptions & { replica?: string },
): Promise<SearchResponse<VehicleSearchResponse>> {
  return client.initIndex(buildIndexName(Indices.vehicle, language, replica)).search(query, requestOptions);
}

export function searchVehicleIndexMultipleResultsets(
  queries: [string | undefined, AlgoliaRequestOptions & { replica?: string }][],
): Promise<MultipleQueriesResponse<any>> {
  return client.multipleQueries(
    queries.map(([query, { language, replica, ...requestOptions }]) => ({
      indexName: buildIndexName(Indices.vehicle, language, replica),
      query: query ?? "",
      params: {
        ...requestOptions,
        hitsPerPage: 0,
        // only nbHits are of interest in this case, this reduces the amount of data which needs to be sent
        responseFields: ["nbHits"],
      },
    })),
  );
}

export function searchVehicleIndexMultiple(
  queries: [string | undefined, AlgoliaRequestOptions & { replica?: string }][],
): Promise<MultipleQueriesResponse<any>> {
  return client.multipleQueries(
    queries.map(([query, { language, replica, ...requestOptions }]) => ({
      indexName: buildIndexName(Indices.vehicle, language, replica),
      query: query ?? "",
      params: {
        ...requestOptions,
        hitsPerPage: 20,
      },
    })),
  );
}

export const vehicleSearchApi = createApi({
  reducerPath: "vehicleSearchApi",
  baseQuery: algoliaBaseQuery(searchVehicleIndex),
  endpoints: builder => ({
    getVehicles: builder.query<VehicleSearchResponse, { query: string; options: AlgoliaRequestOptions }>({
      query: ({ query, options }) => ({
        query,
        options: {
          ...options,
        },
      }),
      transformResponse: (response: VehicleSearchResponse) => response,
    }),
    getMultipleVehicleResultSets: builder.query<{ results: SearchResponse<unknown>[] }, AlgoliaMultiSearchQueryOptions>(
      {
        queryFn: (query, api, extraOptions) => {
          return algoliaMultiIndexBaseQuery<AlgoliaMultiSearchQueryOptions, { results: SearchResponse<unknown>[] }>(
            searchVehicleIndexMultipleResultsets,
          )(query, api, extraOptions);
        },
      },
    ),
    getMultipleVehicles: builder.query<
      { results: SearchResponse<VehicleSearchResponseItem>[] },
      AlgoliaMultiSearchQueryOptions
    >({
      queryFn: (query, api, extraOptions) => {
        return algoliaMultiIndexBaseQuery<
          AlgoliaMultiSearchQueryOptions,
          { results: SearchResponse<VehicleSearchResponseItem>[] }
        >(searchVehicleIndexMultiple)(query, api, extraOptions);
      },
    }),
  }),
});

const useRawGetVehiclesQuery = vehicleSearchApi.useGetVehiclesQuery;

export const useGetVehiclesQuery = (
  request: { query: string; options: VehicleSearchQueryOptions } | typeof skipToken,
) => {
  const language = useLanguage();
  return useRawGetVehiclesQuery(
    request === skipToken ? skipToken : { query: request.query, options: { ...request.options, language } },
  );
};

export const { useGetMultipleVehicleResultSetsQuery, useGetMultipleVehiclesQuery } = vehicleSearchApi;
