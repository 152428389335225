import { useContext } from "react";
import { HistoryState } from "../../../router/constants/State";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { BounceOffType, InBounceOffContext } from "../../../whitelabel/vehicle-detail/components/BounceOffPopup";

function useGeneralInquiryLink(): (args: { slug: string | undefined | null; id: number; vehicleId?: number }) => {
  to: string;
  state?: Record<string, number>;
  rel: string;
} {
  const isInBounceOffContext = useContext(InBounceOffContext);
  const { generalInquiryPath } = usePathBuilder();
  return ({ slug, id, vehicleId }) => {
    if (isInBounceOffContext) {
      return {
        to: `#${BounceOffType.INQUIRY}`,
        rel: "nofollow",
      };
    }

    return {
      to: generalInquiryPath({ slug: slug!, id: id, vehicleId }),
      state: { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 },
      rel: "nofollow",
    };
  };
}

export default useGeneralInquiryLink;
