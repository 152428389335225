import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { FilterReferenceData } from "../../vehicle-search/services/reference-data-aggregator/types";
import getPopularSearchesFromFacets from "../helpers/getPopularSearchesFromFacets";

function usePopularSearchesFromFacets(
  facets: Record<string, Record<string, number>> | undefined,
  filterData: FilterReferenceData | undefined,
  options: {
    appliedFilters?: Record<string, string[] | string>;
    brandRestriction?: string;
    modelRestriction?: string;
  } = {},
  skip?: boolean,
) {
  const pathBuilders = usePathBuilder();

  if (skip || !facets || !Object.keys(facets).length || !filterData) return [];
  return getPopularSearchesFromFacets(facets, filterData, options.appliedFilters ?? {}, pathBuilders, options);
}

export default usePopularSearchesFromFacets;
