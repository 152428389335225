import { logger } from "../scripts/logger";

/**
 * Helper used to detect switch statements that don't have all possible enum values covered.
 * @param context to help with the fix by providing a useful warning message in the logs.
 * @param item the enum value - make sure this is convertable to a readable string.
 */
export function warnSwitchNeverCase(context: string, item: never) {
  logger.warn(context + item);
}

export default warnSwitchNeverCase;
