const ABSOLUTE_THRESHOLD_CHF = 200;
const RELATIVE_THRESHOLD_PERCENT = 4;

export const showInitialPrice = (
  priceB2cGross: number | null | undefined,
  priceInitialB2cGross: number | null | undefined,
  pricePreparationGross: number | null | undefined,
): boolean => {
  if (!priceInitialB2cGross || !priceB2cGross) {
    return false;
  }
  const initialPrice = priceInitialB2cGross + (pricePreparationGross || 0);
  if (initialPrice - ABSOLUTE_THRESHOLD_CHF >= priceB2cGross) {
    return true;
  }
  if ((initialPrice / 100) * (100 - RELATIVE_THRESHOLD_PERCENT) >= priceB2cGross) {
    return true;
  }
  return false;
};
