type DealerPhone = {
  phone: undefined | null | string;
  calltrackingPhone: undefined | null | string;
};

export const getDealerPhone = (dealer: DealerPhone): string | null => {
  const { phone, calltrackingPhone } = dealer;
  if (calltrackingPhone) {
    return calltrackingPhone;
  }
  if (phone) {
    return phone;
  }
  return null;
};
