import React, { ReactNode } from "react";
import classNames from "classnames";
import { Icon } from "../../common/types/Icons";

type HeaderIcon = {
  icon: Icon;
};
type HeaderSvg = {
  svg: string;
};

const isHeaderIcon = (header: HeaderIcon | HeaderSvg): header is HeaderIcon => {
  return (header as HeaderIcon).icon !== undefined;
};

type Props = {
  headerContent?: ReactNode;
  headerIcon?: HeaderIcon | HeaderSvg;
  title?: string;
  body?: ReactNode;
  form?: ReactNode;
  footer?: ReactNode;
  success?: boolean;
  gradient?: boolean;
};

const PopupContent: React.FC<Props> = ({
  headerContent,
  headerIcon,
  title,
  success = false,
  gradient = false,
  form,
  body,
  footer,
}) => {
  return (
    <>
      <div className="popup__header">
        {headerIcon ? (
          <div className={classNames("popup-header__icon", { success: success })}>
            {isHeaderIcon(headerIcon) ? (
              <span className={`ifont ifont--${headerIcon.icon} dialog__top-icon`} />
            ) : (
              <span className="icon-img">
                <img alt="" src={headerIcon.svg} />
              </span>
            )}
          </div>
        ) : null}
        {title ? (
          <div className="popup-header__title">
            <span className={classNames({ "text-gradient": gradient })}>{title}</span>
          </div>
        ) : null}
        {headerContent ? <>{headerContent}</> : null}
      </div>
      {body ? <div className="popup__body">{body}</div> : null}
      {form ? <div className="popup__form-wrap">{form}</div> : null}
      {footer ? <div className="popup__footer">{footer}</div> : null}
    </>
  );
};

export default PopupContent;
