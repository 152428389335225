import React, { ReactNode, useId } from "react";
import i18next from "i18next";
import classNames from "classnames";
import { useOverlayContext } from "../OverlayContext";
import IconButton from "../../../visual-components/components/IconButton";
import { useFilterStateContext } from "./FilterStateContext";

type Props = {
  title: string;
  bg?: boolean;
  reset?: () => void;
  remove?: () => void;
  add?: { label: string; onClick: () => void };
  className?: string;
  count: number;
  // required if filterId needs to be set by filter instance
  filterId?: string;
  children: ReactNode;
};

const FilterBlock: React.FC<Props> = ({
  title: titleLabel,
  count,
  children,
  reset,
  remove,
  add,
  className,
  bg = false,
  filterId: filterIdArg,
}) => {
  const id = useId();
  const filterId = filterIdArg || id;
  const { openFilterId, setOpenFilterId } = useFilterStateContext();
  const { setIsOpen } = useOverlayContext();
  const title = (
    <>
      {titleLabel} {count > 0 ? <span className="circle-counter">{count}</span> : null}
    </>
  );
  return (
    <div className={classNames("filter__block", { bg, open: openFilterId === filterId }, className)}>
      <h2
        className="filter__title"
        onClick={() => {
          setOpenFilterId(filterId);
        }}
      >
        {title}
      </h2>
      <div className="filter__content-wrap">
        <div className="filter__mobile-header">
          <nav
            className="filter-mobile-header__back"
            onClick={() => {
              setOpenFilterId(null);
            }}
          >
            <span className="ifont ifont--angle_left" />
            <span className="back__text">{i18next.t("BACK")}</span>
          </nav>
          <div className="filter__title--mobile">{title}</div>

          <IconButton
            ariaLabel={i18next.t("CLOSE")}
            className="power-search__close"
            icon="close"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="filter__content">
          {children}
          <div className="filter__edit-row">
            {add ? (
              <div className="left edit--add" onClick={add.onClick}>
                <span className="ifont ifont--plus " />
                {add.label}
              </div>
            ) : null}
            {count > 0 && reset ? (
              <div className="right edit--reload" onClick={reset}>
                <span className="ifont ifont--reload " />
                {i18next.t("RESET")}
              </div>
            ) : null}
            {remove ? (
              <div className="right edit--remove" onClick={remove}>
                <span className="ifont ifont--close " />
                {i18next.t("REMOVE")}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBlock;
