import React from "react";
import classNames from "classnames";

type Props = {
  alt?: string;
  desktopSrc: string;
  mobileSrc: string;
  className?: string;
  pictureClassName?: string;
  mobileBreakpoint: number;
  desktopBreakpoint: number;
};

const BannerImage: React.FC<Props> = ({
  desktopSrc,
  mobileSrc,
  alt,
  className,
  pictureClassName,
  mobileBreakpoint,
  desktopBreakpoint,
}) => {
  return (
    <>
      <picture className={pictureClassName}>
        <source media={`screen and (min-width:${desktopBreakpoint}px)`} srcSet={mobileSrc} />
        <source
          media={`screen and (min-width:${mobileBreakpoint + 1}px) and (max-width:${desktopBreakpoint - 1}px))`}
          srcSet={desktopSrc}
        />
        <source media={`screen and (max-width:${mobileBreakpoint}px)`} srcSet={mobileSrc} />

        <img alt={alt} className={classNames("img", className)} src={desktopSrc} />
      </picture>
      <picture className="banner-item__picture">
        <source media={`screen and (max-width:${mobileBreakpoint}px)`} srcSet={mobileSrc} />
        <img alt={alt} className="img banner-item__img" src={desktopSrc} />
      </picture>
    </>
  );
};

export default BannerImage;
