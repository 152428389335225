import React from "react";
import useFilterSearch from "../../../vehicle-search/hooks/useFilterSearch";
import SingleOptionSelect, { SelectItem } from "./SingleOptionSelect";

type Props<T = NonNullable<unknown>> = {
  bg?: boolean;
  label: string;
  value: SelectItem<T> | undefined;
  fallbackValue?: string;
  options: SelectItem[];
  onChange: (value: SelectItem<T>) => void;
  error?: string | undefined;
};

const SearchableSingleOptionSelect: React.FC<Props> = ({
  label,
  value,
  fallbackValue,
  options,
  onChange,
  error,
  bg,
}) => {
  const [searchInput, setSearchInput, filteredOptions] = useFilterSearch(options);

  return (
    <SingleOptionSelect
      showNoResultsInfo
      bg={bg}
      error={error}
      fallbackValue={fallbackValue}
      label={label}
      options={filteredOptions}
      value={value}
      search={{
        value: searchInput,
        setValue: setSearchInput,
      }}
      onChange={onChange}
    />
  );
};

export default SearchableSingleOptionSelect;
