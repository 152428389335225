/**
 * build filter for algolia filter search
 * @param facet
 * @param value
 */
export const buildFilter = (facet: string, value: string) => {
  return `${facet}: '${value.replaceAll("'", "\\'")}'`;
};

export const buildNumericalFilter = (facet: string, value: number) => {
  return `${facet}:${value}`;
};

export const buildBooleanFilter = (facet: string, value: boolean) => {
  return buildFilter(facet, value ? "true" : "false");
};

export const buildNegatedFilter = (facet: string, value: string) => {
  return `NOT ${buildFilter(facet, value)}`;
};

export const buildNegatedNumericalFilter = (facet: string, value: number) => {
  return `NOT ${facet} = ${value}`;
};

export const buildLargerOrEqualFilter = (facet: string, value: number) => {
  return `${facet} >= ${value}`;
};

export const buildSmallerOrEqualFilter = (facet: string, value: number) => {
  return `${facet} <= ${value}`;
};

export const buildConjunctiveFilter = (parts: string[]) => {
  return `${parts.join(" AND ")}`;
};

export const buildDisjunctiveFilter = (parts: string[]) => {
  return `(${parts.join(" OR ")})`;
};

/**
 * Optional Filters have a distinct syntax to normal filters, they cannot use the `buildFilter` helper.
 * This optionalFilter builder should not be used for user input, only for reference values
 * as it permits arbitrary "keyword" injection.
 */
export const buildOptionalFilter = (facet: string, value: string) => {
  return `${facet}:${value}`;
};
