import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STORAGE_KEYS } from "../modules/common/constants/Storage";
import localStorageManager from "../modules/common/global/localStorageManager";
import { AuthState } from "./types";

export type LoggedInPayload = {
  refreshToken: string;
  accessToken: string;
};

const initialState: AuthState = {
  ...(localStorageManager.getItem(STORAGE_KEYS.SESSION_TOKENS) || {
    refreshToken: undefined,
    accessToken: undefined,
  }),
  inSessionChecker: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<LoggedInPayload>) {
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },
    loggedOut(state) {
      state.refreshToken = undefined;
      state.accessToken = undefined;
    },
    inSessionChecker(state, action: PayloadAction<boolean>) {
      state.inSessionChecker = action.payload;
    },
  },
});

export const { loggedIn, loggedOut, inSessionChecker } = authSlice.actions;
export default authSlice.reducer;
