import React, { ReactNode, useRef, useState } from "react";
import classNames from "classnames";
import i18next from "i18next";
import { NumberParam, useQueryParam } from "use-query-params";
import { motion } from "framer-motion";
import { createNullableContext } from "../../common/helpers/contextCreator";
import useEvent from "../../common/hooks/useEvent";
import { accordionTrack } from "../../analytics/scripts/navigationTrack/accordionTrack";
import { tabTrack } from "../../analytics/scripts/navigationTrack/tabTrack";
import { notNil } from "../../common/helpers/isNil";
import { SearchParams } from "../../../router/constants/SearchParams";

type TabsContextValue = {
  tab: number;
  setTab: (i: number) => void;
};

const [TabsContext, useTabsContext] = createNullableContext<TabsContextValue>("TabsContext");

export const useSetActiveTab = () => {
  const [, setTabIndexQueryParam] = useQueryParam(SearchParams.pageTab, NumberParam);
  return (id: number) => {
    setTabIndexQueryParam(id);
  };
};

export const Tab = ({ children, id }: { children: ReactNode; id: number }) => {
  const { tab, setTab } = useTabsContext();
  return (
    <li
      className={classNames("tab", { active: tab === id })}
      onClick={() => {
        setTab(id);
        tabTrack(children as string);
      }}
    >
      <h2>{children}</h2>
    </li>
  );
};

export const TabList = ({ children }: { children: ReactNode }) => {
  return (
    <div className="tab-nav-wrap">
      <ul className="tab-nav scroll-target">{children}</ul>
    </div>
  );
};

export const TabPanel = ({
  children,
  id,
  expandable,
  block,
  unmountIfNotOpen = false,
  orderPrint,
  notInPrint = false,
}: {
  children: ReactNode;
  id: number;
  expandable?: boolean;
  block?: boolean;
  // Permit lazy mounting of tab content for performance reasons
  unmountIfNotOpen?: boolean;
  orderPrint?: number;
  notInPrint?: boolean;
}) => {
  const [expanded, setExpanded] = useState<null | boolean>(expandable ? false : null);
  const { tab } = useTabsContext();
  const isOpen = tab === id;
  return (
    <motion.div
      animate={{ height: expanded === null || expanded ? "auto" : "23.75rem" }}
      initial={false}
      style={notNil(orderPrint) ? ({ "--order-print": orderPrint } as React.CSSProperties) : {}}
      transition={{ ease: "easeInOut", type: "keyframes" }}
      className={classNames("panel", {
        open: isOpen,
        expanded: expanded,
        collapsed: expanded === false,
        "panel-block": block,
        "hidden-print": notInPrint,
      })}
    >
      {unmountIfNotOpen && !isOpen ? null : children}
      {expandable ? (
        <div className="panel__toggle-link" data-accordion="accordion">
          <span
            className="toggle-link__text"
            onClick={() => {
              setExpanded(true);
              accordionTrack(i18next.t("SHOW ALL"), true);
            }}
          >
            <span className="ifont ifont--angle_down" />
            {i18next.t("SHOW ALL")}
          </span>
          <span
            className="toggle-link__text toggle-link__text--expanded"
            onClick={() => {
              setExpanded(false);
              accordionTrack(i18next.t("SHOW LESS"), false);
            }}
          >
            <span className="ifont ifont--angle_up" />
            {i18next.t("SHOW LESS")}
          </span>
        </div>
      ) : null}
    </motion.div>
  );
};

export const TabPanels = ({ children }: { children: ReactNode }) => {
  return <div className="panel-wrap">{children}</div>;
};

export const TabSwitchButton = ({
  to,
  className,
  children,
}: {
  to: number;
  className: string;
  children: ReactNode;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { setTab } = useTabsContext();

  return (
    <button
      ref={buttonRef}
      className={className}
      onClick={() => {
        buttonRef.current
          ?.closest(".tab-panel")
          ?.querySelector(".tab-scroll-target")
          ?.scrollIntoView({ behavior: "smooth" });

        setTab(to);
      }}
    >
      {children}
    </button>
  );
};

const Tabs: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tab, setTabRaw] = useQueryParam(SearchParams.pageTab, NumberParam);
  const currentTab = tab || 0;

  const setTab = useEvent((id: number) => {
    setTabRaw(id === 0 ? undefined : id);
  });

  return (
    <TabsContext.Provider value={{ tab: currentTab, setTab }}>
      <div className="tab-panel">
        <div className="tab-scroll-target scroll-target" />
        {children}
      </div>
    </TabsContext.Provider>
  );
};

export default Tabs;
