import React from "react";
import i18next from "i18next";
import { showInitialPrice } from "../../vehicle-search/helpers/carPriceHelper";
import useFormatter from "../../common/hooks/useFormatter";
import { CURRENCY_LABELS, DEFAULT_CURRENCY } from "../../common/constants/Currency";
import { Currency } from "../../hygraph/vo";

export const CurrentPrice: React.FC<{ priceB2cGross: number; currency: Currency | undefined | null }> = ({
  priceB2cGross,
  currency,
}) => {
  const { formatCurrencyWithoutSuffix } = useFormatter();
  return (
    <>
      <span className="price-unit">{CURRENCY_LABELS[currency ?? DEFAULT_CURRENCY]}</span>
      <span className="price">{formatCurrencyWithoutSuffix(priceB2cGross)}</span>
    </>
  );
};

export const LeasingPrice: React.FC<{
  leasingRateFrom: number | undefined | null;
  currency: Currency | undefined | null;
}> = ({ leasingRateFrom, currency }) => {
  const { formatCurrencyWithoutSuffix } = useFormatter();
  return (
    <>
      {leasingRateFrom ? (
        <div className="price-meta">
          {i18next.t("STARTING AT")} {CURRENCY_LABELS[currency ?? DEFAULT_CURRENCY]}
          <br /> {i18next.t("X PER MONTH SHORT", { value: formatCurrencyWithoutSuffix(leasingRateFrom) })}
        </div>
      ) : null}
    </>
  );
};

const BRANDS_WITHOUT_OLD_PRICE = ["PORSCHE", "MASERATI"];

export const OldPrice: React.FC<{
  brand?: string | null;
  priceB2cGross: number;
  priceInitialB2cGross: number | undefined | null;
  pricePreparationGross: number | undefined | null;
  currency: Currency | undefined | null;
  className?: string;
}> = ({ brand, priceB2cGross, priceInitialB2cGross, pricePreparationGross, currency, className = "old-price" }) => {
  const { formatCurrency } = useFormatter();
  const isInitialPriceShown = showInitialPrice(priceB2cGross, priceInitialB2cGross, pricePreparationGross);
  const displayOldPrice = !BRANDS_WITHOUT_OLD_PRICE.includes(brand ?? "");

  return displayOldPrice ? (
    <>
      {isInitialPriceShown && priceInitialB2cGross ? (
        <div className={className}>
          {i18next.t("INSTEAD OF")} {formatCurrency(priceInitialB2cGross + (pricePreparationGross ?? 0), currency)}
        </div>
      ) : null}
    </>
  ) : null;
};
