import algoliaAnalytics, { InsightsMethodMap } from "search-insights";
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, buildIndexName, Indices } from "../services/search";
import useCookieConsent from "../../common/hooks/useCookieConsent";
import { ActionItemType } from "../constants/ActionItemType";
import Locale from "../../common/constants/Locale";
import { ConversionEventName } from "../constants/ConversionEventName";

const initAnalytics = () => {
  algoliaAnalytics("init", {
    appId: ALGOLIA_APP_ID,
    apiKey: ALGOLIA_API_KEY,
  });

  return algoliaAnalytics;
};

export type EventInfo = {
  eventMethod: keyof InsightsMethodMap;
  event: AlgoliaAnalyticsItemEvent;
};

const buildConversionEvent = (
  language: Locale,
  objectID: number,
  userId: string | undefined,
  eventName: string,
): AlgoliaAnalyticsBaseEvent => {
  return {
    userToken: userId,
    index: buildIndexName(Indices.vehicle, language),
    eventName,
    objectIDs: [`${objectID}`],
  };
};

export const buildViewedObjectIDsEvent = (
  event: Omit<AlgoliaAnalyticsItemViewEvent, "eventName">,
  actionItemType: ActionItemType,
): EventInfo => {
  return {
    eventMethod: "viewedObjectIDs",
    event: { ...event, eventName: `view${actionItemType}` },
  };
};
export const buildConvertedObjectIDsAfterSearchEvent = (
  event: AlgoliaAnalyticsBaseEvent & { queryID?: string; positions?: number[] },
): EventInfo => {
  const { positions, queryID, ...eventProperties } = event;
  return {
    eventMethod: "convertedObjectIDsAfterSearch",
    event: { ...eventProperties, eventName: ConversionEventName.addVehicleToList, queryID },
  };
};
export const buildConvertedObjectIDsEvent = (
  language: Locale,
  objectID: number,
  userId: string | undefined,
  eventName: ConversionEventName,
): EventInfo => {
  const convertedEvent = buildConversionEvent(language, objectID, userId, eventName);
  return {
    eventMethod: "convertedObjectIDs",
    event: convertedEvent,
  };
};
export const buildClickedObjectIDsAfterSearchEvent = (
  event: AlgoliaAnalyticsItemClickEvent,
  actionItemType: ActionItemType,
): EventInfo => {
  return {
    eventMethod: "clickedObjectIDsAfterSearch",
    event: { ...event, eventName: `click${actionItemType}` },
  };
};

export type AlgoliaAnalyticsBaseEvent = {
  eventName: string;
  index: string;
  objectIDs: string[];
  userToken: string | undefined;
};

export type AlgoliaAnalyticsItemClickEvent = AlgoliaAnalyticsBaseEvent & {
  queryID: string;
  positions: number[];
};

export type AlgoliaAnalyticsItemConversionEvent = AlgoliaAnalyticsBaseEvent & {
  queryID: string;
};

export type AlgoliaAnalyticsItemViewEvent = AlgoliaAnalyticsBaseEvent;

export type AlgoliaAnalyticsItemEvent =
  | AlgoliaAnalyticsItemClickEvent
  | AlgoliaAnalyticsItemConversionEvent
  | AlgoliaAnalyticsItemViewEvent;

function useAlgoliaAnalytics() {
  const { permitAnalytics } = useCookieConsent();
  return (eventInfo: EventInfo) => {
    const { eventMethod, event } = eventInfo;
    if (permitAnalytics) {
      const algoliaAnalytics = initAnalytics();
      algoliaAnalytics(eventMethod, event);
    }
  };
}

export default useAlgoliaAnalytics;
