import i18next from "i18next";

/**
 * Build <title> tag for the current page.
 */
function buildTitle(title?: string | string[], withAppSuffix = true): string {
  let titleParts = withAppSuffix ? [i18next.t("TITLE SUFFIX")] : [];
  if (title) {
    const titleArray = Array.isArray(title) ? title : [title];
    titleParts = [...titleArray, ...titleParts];
  }
  return titleParts.join(" | ");
}

export default buildTitle;
