import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePathBuilder from "../../router/hooks/usePathBuilder";
import {
  FFU_STORAGE_KEYS_TO_BE_CLEARED_ON_EXPLICIT_LOGOUT,
  STORAGE_KEYS,
} from "../../modules/common/constants/Storage";
import useLanguage from "../../router/hooks/useLanguage";
import useCurrentPage from "../../router/hooks/useCurrentPage";
import { Page, PAGE_DEFINITION } from "../../router/constants/Page";
import { loggedOut } from "../authSlice";
import clearStorageKeys from "../../modules/common/helpers/clearStorageKeys";
import sessionStorageManager from "../../modules/common/global/sessionStorageManager";
import { selectInSessionChecker } from "../authSelectors";

const APPLICATION_ID = import.meta.env.VITE_AUTH_APPLICATION_ID!;
const DEFAULT_TENANT_ID = import.meta.env.VITE_AUTH_TENANT_ID!;

function useLogin() {
  const { homePath, loginPath, registerPath, logoutPath, pagePath, accountEditPath } = usePathBuilder();
  const language = useLanguage();
  const navigate = useNavigate();
  const inSessionChecker = useSelector(selectInSessionChecker);

  const dispatch = useDispatch();

  const currentPage = useCurrentPage();

  // using pathname and search to attempt to preserve current page state
  // security wise it should not matter as the user would have been able to access the page anyway if a session is established
  const setRedirectUrl = (redirectUrl = `${window.location.pathname}${window.location.search}`) => {
    const { preventAsPostLoginUrl } = currentPage ? PAGE_DEFINITION[currentPage] : { preventAsPostLoginUrl: false };
    if (!preventAsPostLoginUrl) {
      sessionStorage.setItem(STORAGE_KEYS.REDIRECT_URL, redirectUrl);
    }
  };

  const buildSessionEstablishingParams = () => {
    const params = new URLSearchParams({
      client_id: APPLICATION_ID,
      redirect_uri: pagePath({
        page: Page.postLogin,
        absolute: true,
      }),
      tenantId: DEFAULT_TENANT_ID,
      response_type: "code",
      scope: "offline_access",
      locale: language,
    });
    return params.toString();
  };

  return {
    login: () => {
      setRedirectUrl();
      window.location.href = `${loginPath()}?${buildSessionEstablishingParams()}`;
    },
    register: () => {
      setRedirectUrl();
      window.location.href = `${registerPath()}?${buildSessionEstablishingParams()}`;
    },
    /**
     * explicit manual log out
     */
    logout: () => {
      // Navigate outside of session checker context to prevent automatic re-login
      if (inSessionChecker) {
        navigate(homePath());
      }

      dispatch(loggedOut());

      clearStorageKeys(FFU_STORAGE_KEYS_TO_BE_CLEARED_ON_EXPLICIT_LOGOUT, sessionStorageManager);

      setRedirectUrl(homePath());
      const params = new URLSearchParams({
        client_id: APPLICATION_ID,
        post_logout_redirect_uri: pagePath({
          page: Page.postLogin,
          absolute: true,
        }),
        tenantId: DEFAULT_TENANT_ID,
      });
      const stringParams = params.toString();
      window.location.href = `${logoutPath()}?${stringParams}`;
    },
    settings: () => {
      window.location.href = `${accountEditPath()}?${buildSessionEstablishingParams()}`;
    },
  };
}

export default useLogin;
