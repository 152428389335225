import { createApi } from "@reduxjs/toolkit/query/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { SearchResponse } from "@algolia/client-search";
import useLanguage from "../../../router/hooks/useLanguage";
import Locale from "../../common/constants/Locale";
import { buildNumericalFilter } from "./buildFilter";
import { algoliaBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";
import { VehicleSearchResponse, VehicleSearchResponseItem } from "./vehicleSearchApi";
import { VEHICLE_FACETS } from "./vehicleFacetKeys";

export type GetDealerVehicleResponse = SearchResponse<VehicleSearchResponseItem>;

export function searchVehicleIndex(
  query = "",
  { language, ...requestOptions }: AlgoliaRequestOptions,
): Promise<SearchResponse<VehicleSearchResponse>> {
  return client.initIndex(buildIndexName(Indices.vehicle, language)).search(query, requestOptions);
}

export const dealerVehicleApi = createApi({
  reducerPath: "dealerVehicleApi",
  baseQuery: algoliaBaseQuery(searchVehicleIndex),
  endpoints: builder => ({
    getDealerVehiclesMeta: builder.query<GetDealerVehicleResponse, { dealerId: number; language: Locale }>({
      query: ({ dealerId, language }) => ({
        options: {
          hitsPerPage: 0,
          filters: buildNumericalFilter(VEHICLE_FACETS.DEALER_ID, dealerId),
          facets: [VEHICLE_FACETS.BRAND],
          language,
        },
      }),
    }),
  }),
});

const useRawDealerVehiclesMetaQuery = dealerVehicleApi.useGetDealerVehiclesMetaQuery;

export const useGetDealerVehiclesMetaQuery = (request: { dealerId: number } | typeof skipToken) => {
  const language = useLanguage();
  return useRawDealerVehiclesMetaQuery(request === skipToken ? skipToken : { dealerId: request.dealerId, language });
};
