import { ReactNode } from "react";
import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type TabTrackEvent = {
  content_modules: {
    content_module: "Tab Switch";
    content_module_action: "Click";
    content_module_label: ReactNode;
  };
};
export type TabTrackingEvent = BaseTrackingEvent & TabTrackEvent;

export const tabTrack = (children: string) => {
  dataLayer([
    {
      event: "gaEvent",
      event_name: "content_modules",
      content_modules: {
        content_module: "Tab Switch",
        content_module_action: "Click",
        content_module_label: children,
      },
    } satisfies TabTrackingEvent,
  ]);
};
