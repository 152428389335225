// source: https://github.com/ValentinMinder/Swisstopo-WGS84-LV03/blob/master/scripts/js/wgs84_ch1903.js

// script to convert world coordinate system to swiss coordinate system

const worldGeodeticSystemToCH = function (lat: string, lng: string): [number, number] {
  return [WGStoCHy(lat, lng), WGStoCHx(lat, lng)];
};

// Convert angle in decimal degrees to sexagesimal seconds
const DECtoSEX = function (angleArg: string): number {
  const angle = parseFloat(angleArg);
  // Extract DMS
  const deg = parseInt(angleArg);
  const min = Math.floor((angle - deg) * 60);
  const sec = ((angle - deg) * 60 - min) * 60;

  // Result sexagesimal seconds
  return sec + min * 60.0 + deg * 3600.0;
};

// Convert WGS lat/lng (° dec) to CH x
const WGStoCHx = function (latArg: string, lngArg: string) {
  // Convert decimal degrees to sexagesimal seconds
  const lat = DECtoSEX(latArg);
  const lng = DECtoSEX(lngArg);

  // Auxiliary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process X
  const x =
    200147.07 +
    308807.95 * lat_aux +
    3745.25 * Math.pow(lng_aux, 2) +
    76.63 * Math.pow(lat_aux, 2) -
    194.56 * Math.pow(lng_aux, 2) * lat_aux +
    119.79 * Math.pow(lat_aux, 3);

  return x;
};

// Convert WGS lat/lng (° dec) to CH y
const WGStoCHy = function (latArg: string, lngArg: string) {
  // Convert decimal degrees to sexagesimal seconds
  const lat = DECtoSEX(latArg);
  const lng = DECtoSEX(lngArg);

  // Auxiliary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process Y
  const y =
    600072.37 +
    211455.93 * lng_aux -
    10938.51 * lng_aux * lat_aux -
    0.36 * lng_aux * Math.pow(lat_aux, 2) -
    44.54 * Math.pow(lng_aux, 3);

  return y;
};

export default worldGeodeticSystemToCH;
