import { RawFilterReferenceData } from "../vehicleFilterReferenceDataApi";
import { getContractOptions } from "../contractOptions";
import { getOtherFilterTags } from "../otherFilterTags";
import { FilterReferenceData } from "./types";
import { aggregatedFilterItemMapper } from "./aggregatedFilterItemMapper";
import { modelFilterItemMapper } from "./modelFilterItemMapper";
import { aggregatedGroupFilterItemMapper } from "./aggregatedGroupFilterItemMapper";

export function getAggregatedFilterData(filterData: RawFilterReferenceData): FilterReferenceData {
  return {
    brands: aggregatedFilterItemMapper(filterData.brands),
    models: modelFilterItemMapper(filterData.brands),
    driveTypes: aggregatedFilterItemMapper(filterData.driveTypes),
    bodyTypes: aggregatedGroupFilterItemMapper(filterData.bodyTypeGroups),
    fuelTypes: aggregatedGroupFilterItemMapper(filterData.fuelTypeGroups),
    transmissions: aggregatedFilterItemMapper(filterData.transmissionGroups),
    optionFacets: aggregatedFilterItemMapper(filterData.optionFacets),
    exteriorColors: aggregatedFilterItemMapper(filterData.colorNorms),
    interiorColors: aggregatedFilterItemMapper(filterData.colorNorms),
    upholsteries: aggregatedFilterItemMapper(filterData.upholsterys),
    emissionClasses: aggregatedFilterItemMapper(filterData.emissionClasses),
    contractOptions: aggregatedFilterItemMapper(getContractOptions()),
    otherFilterTags: aggregatedFilterItemMapper(getOtherFilterTags()),
    vehicleClasses: aggregatedFilterItemMapper(filterData.vehicleClasses),
    vehicleTypes: aggregatedFilterItemMapper(filterData.vehicleTypes),
    vehicleConditions: aggregatedFilterItemMapper(filterData.vehicleConditions),
  };
}
