import { RootPath } from "../vo";
import { Path } from "../../../router/constants/Path";

export const getRootPath = (hygraphRootPath: RootPath): Path.yourVehicle | Path.general => {
  switch (hygraphRootPath) {
    case RootPath.General:
      return Path.general;
    case RootPath.Yourvehicle:
      return Path.yourVehicle;
  }
};
