import { gql } from "graphql-request";

const geoFragment = gql`
  fragment GeoFragment on TargetGeo {
    center {
      latitude
      longitude
    }
    radius
  }
`;

export default geoFragment;
