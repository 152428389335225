import getUniqueEntries from "../../common/helpers/getUniqueEntries";
import { FilterDefinition } from "../services/types/filterDefinitionTypes";
import { FilterReferenceData } from "../services/reference-data-aggregator/types";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../services/filterTypeConfiguration";

export const getNewQueryParams = (
  filterDefMap: Map<FilterDefinition, string[]>,
  aggregatedFilterData: FilterReferenceData,
  appliedQueries: Record<string, string | string[]>,
): Record<string, string | number | string[]> => {
  const entries = Array.from(filterDefMap.entries())
    .map(([filterDefinition, facetFilters]) => {
      const filterType = FILTER_TYPE_CONFIGURATION_MAP[filterDefinition.type];
      return (
        filterType.facetMapping?.getAppliedValues(
          facetFilters,
          // currently only array types are supported
          (appliedQueries[filterDefinition.queryParam] as string[]) ?? [],
          aggregatedFilterData,
          filterDefinition,
        ) ?? []
      );
    })
    .flat();
  return mergeQueryParams(entries);
};

export const mergeQueryParams = (
  newQueryParamEntries: [string, string][],
): Record<string, string | number | string[]> => {
  return newQueryParamEntries.reduce<Record<string, string | number | string[]>>(
    (newAppliedQueryParams, [queryParam, value]) => {
      const appliedParams = newAppliedQueryParams[queryParam];
      if (Array.isArray(appliedParams)) {
        newAppliedQueryParams[queryParam] = getUniqueEntries([...appliedParams, value]);
      } else {
        newAppliedQueryParams[queryParam] = [value];
      }
      return newAppliedQueryParams;
    },
    {},
  );
};
