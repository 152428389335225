import { STORAGE_KEYS } from "../constants/Storage";
import buildStorageManagerSingleton from "./buildStorageManagerSingleton";

const clearStorageKeys = (keys: STORAGE_KEYS[], store: ReturnType<typeof buildStorageManagerSingleton>) => {
  keys.forEach(key => {
    store.setItem(key, null);
  });
};

export default clearStorageKeys;
