import i18next, { InitOptions } from "i18next";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import filterXSS from "xss";
import Locale from "../../../modules/common/constants/Locale";
import { logger } from "../../../modules/common/scripts/logger";
import { FALLBACK, Params } from "../../../router/constants/Params";
import { loadhash } from "./loadhash";

const initI18next = (fixLanguage?: Locale) => {
  function updateHtmlElement() {
    document.documentElement.lang = i18next.language;
  }

  // add side effects on certain events
  i18next.on("initialized", updateHtmlElement);
  i18next.on("languageChanged", updateHtmlElement);

  // https://github.com/i18next/i18next-http-backend#backend-options
  const backend = {
    loadPath: "/static/i18next/carmarket-{{lng}}.json?loadhash=" + loadhash,
  };

  // https://github.com/i18next/i18next-browser-languageDetector#detector-options
  const detection: DetectorOptions = {
    // application will have /lang/country/ in the pathname
    // therefore the lookup index will be the first one
    lookupFromPathIndex: 0,
    lookupLocalStorage: "i18nextLng",
    order: ["path", "localStorage", "navigator"],
  };

  const options: InitOptions = {
    backend,
    detection,
    debug: process.env.NODE_ENV !== "production" && import.meta.env.VITE_DEV_DISABLE_I18NEXT_DEBUG_LOG !== "true",
    fallbackLng: fixLanguage ? fixLanguage : FALLBACK[Params.lang],
    supportedLngs: fixLanguage ? [fixLanguage] : Object.values(Locale),
    load: "languageOnly",
    react: {
      useSuspense: false,
    },
    interpolation: {
      escape(str: string): string {
        return filterXSS(str);
      },
    },
  };

  // initializer
  i18next
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(options)
    .catch(e => logger.warn(e?.message ?? JSON.stringify(e), e));
};

export default initI18next;
