import React from "react";
import i18next from "i18next";
import { getRangeFilterFormattedLabel } from "../../services/filter-types/rangeFilterType";
import { RangeFilterDef } from "../../services/types/filterDefinitionTypes";
import useFormatter from "../../../common/hooks/useFormatter";
import { TagRemover } from "./TagRemover";
import { FilterBlock } from "./FilterBlock";

type Props = {
  value: string;
  filterDefinition: RangeFilterDef;
  queryParam: string;
  updateFilters: (q: Record<string, any>) => void;
};

export const SelectedRange: React.FC<Props> = ({ value, filterDefinition, queryParam, updateFilters }) => {
  const { formatNumber } = useFormatter();
  const translation = getRangeFilterFormattedLabel(value, filterDefinition, formatNumber);
  return (
    <FilterBlock label={filterDefinition.title(i18next.t)}>
      <TagRemover
        label={translation}
        onClick={() => {
          updateFilters({
            [queryParam]: undefined,
          });
        }}
      />
    </FilterBlock>
  );
};
