import React, { useEffect, useState } from "react";

const useIsInViewport = (ref: React.RefObject<HTMLElement>, threshold = 0.5) => {
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting);
      },
      { threshold },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, threshold]);

  return isInViewport;
};

export default useIsInViewport;
