import i18next from "i18next";

export const getSortOptions = (): { value: string; label: string }[] => {
  return [
    { value: "relevant_first", label: i18next.t("RELEVANT FIRST") },
    { value: "price_asc", label: i18next.t("LOW PRICE FIRST") },
    { value: "price_desc", label: i18next.t("HIGH PRICE FIRST") },
    { value: "km_asc", label: i18next.t("LOW KM FIRST") },
    { value: "first_registration_asc", label: i18next.t("OLD FIRST REGISTRATION FIRST") },
    { value: "first_registration_desc", label: i18next.t("NEW FIRST REGISTRATION FIRST") },
    { value: "ps_desc", label: i18next.t("HIGH PS FIRST") },
  ];
};

export const DEFAULT_SORT_CRITERIA = "relevant_first";
