import { useEffect, useRef, useState } from "react";
import debounce from "../helpers/debounce";
import useEvent from "./useEvent";

export type ScrollPosition = "start" | "middle" | "end" | "no-scroll";

/**
 *
 * @param data change indicator to be notified of dom changes, ensure to pass a stable reference
 * @param itemSelector
 * @param scrollOffset scrollTo will offset by n items (used to e.g. center scrolled to element)
 */
function useCarousel<T extends HTMLElement = HTMLDivElement>(data: any, itemSelector: string, scrollOffset = 0) {
  const [activeCarouselItem, setActiveCarouselItem] = useState(0);
  const carouselScrollContainerRef = useRef<T>(null);

  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>("start");

  useEffect(() => {
    const scrollContainer = carouselScrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }

    const hasReachedEnd = () => {
      return scrollContainer.scrollLeft + scrollContainer.offsetWidth === scrollContainer.scrollWidth;
    };

    const scrollOptions: AddEventListenerOptions = { passive: true };

    const onScroll = debounce(() => {
      if (scrollContainer.scrollLeft === 0) {
        setScrollPosition("start");
      } else if (hasReachedEnd()) {
        setScrollPosition("end");
      } else {
        setScrollPosition("middle");
      }
    });

    scrollContainer.addEventListener("scroll", onScroll, scrollOptions);

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (scrollContainer.offsetWidth === scrollContainer.scrollWidth) {
          setScrollPosition("no-scroll");
        } else {
          onScroll();
        }
      }),
    );
    resizeObserver.observe(scrollContainer);

    const carouselItems = Array.from(scrollContainer.querySelectorAll(itemSelector));
    const options = {
      root: scrollContainer,
      rootMargin: "0px",
      threshold: 0.95,
    };

    const observer = new IntersectionObserver(
      // use debounce to prevent activating every item individually when skipping
      // note for future extensions: debounce may not be used if not only "isIntersecting" is used
      debounce(entries => {
        const intersectingEntries = entries.filter(entry => entry.isIntersecting);
        intersectingEntries.forEach(entry => {
          const index = carouselItems.findIndex(element => entry.target === element);
          setActiveCarouselItem(index);
        });
      }, 200),
      options,
    );

    carouselItems.forEach(node => {
      observer.observe(node as Element);
    });

    return () => {
      resizeObserver.disconnect();
      observer.disconnect();
      scrollContainer.removeEventListener("scroll", onScroll, scrollOptions);
    };
  }, [data, setActiveCarouselItem, itemSelector]);

  const scrollTo = useEvent((i: number | "next" | "prev") => {
    const scrollContainer = carouselScrollContainerRef.current;
    if (scrollContainer) {
      const item = carouselScrollContainerRef.current?.querySelector(itemSelector) as HTMLDivElement | null;
      if (!item) {
        return;
      }

      const itemWidth = item.offsetWidth;
      const innerWidth = scrollContainer.clientWidth;
      const displayedItems = Math.ceil(innerWidth / itemWidth);

      if (i === "next" || i === "prev") {
        // use scrollBy instead of scrollTo to make logic more resilient to wrong "active item states"
        // multiple items might be active and the current index might therefore not be the correct one.
        scrollContainer.scrollBy({ left: i === "next" ? innerWidth : -innerWidth, behavior: "smooth" });
      } else {
        const scrollPosition = Math.floor((innerWidth / displayedItems) * (i - scrollOffset));
        scrollContainer.scrollTo({ left: scrollPosition, behavior: "smooth" });
      }
    }
  });

  return {
    activeCarouselItem,
    carouselScrollContainerRef,
    scrollTo,
    next: () => {
      scrollTo("next");
    },
    prev: () => {
      scrollTo("prev");
    },
    scrollPosition,
  };
}

export default useCarousel;
