import i18next from "i18next";
import { MouseEventHandler } from "react";
import useNotification from "../../notification/hooks/useNotification";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { buildInfoNotification } from "../../notification/helpers/notificationFactory";
import useVehicleFavoritesList from "./useVehicleFavoritesList";

function useVehicleFavoritesListLink(args?: { absolute?: boolean; base?: string }): {
  onClick: MouseEventHandler;
  to: string;
} {
  const pushNotification = useNotification();
  const { list } = useVehicleFavoritesList();
  const { favoritesPath } = usePathBuilder();

  return {
    to: favoritesPath({ absolute: args?.absolute, base: args?.base }),
    onClick: e => {
      if (list.length === 0) {
        pushNotification(
          buildInfoNotification({
            icon: "heart",
            text: i18next.t("NO VEHICLES IN FAVORITES LIST SNACKBAR TEXT"),
          }),
        );
        e.preventDefault();
      }
    },
  };
}

export default useVehicleFavoritesListLink;
