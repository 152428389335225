import { dataLayer } from "../../dataLayer";
import { AugmentedDetailVehicle } from "../../../vehicle/types/vehicle";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { ECommerce } from "../../types/ECommerce";
import { getAddToCartTrackEcommerce } from "./getAddToCartTrackEcommerce";

type AddToCartTrackingEvent = BaseTrackingEvent & ECommerce;

export const addToCartTrack = (vehicle: AugmentedDetailVehicle | undefined | null) => {
  if (!vehicle) return false;
  const eventValues = { event: "eec_add_to_cart", event_name: "add_to_cart" };
  const ecommerceTrackingData = getAddToCartTrackEcommerce(vehicle);

  const eventData = {
    ...eventValues,
    ...ecommerceTrackingData,
  } satisfies AddToCartTrackingEvent;
  dataLayer([eventData]);
  return true;
};
