import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import Tabs, { Tab, TabList, TabPanel, TabPanels, TabSwitchButton } from "../../visual-components/components/Tabs";
import TechnicalData from "../../../vehicle-detail/components/TechnicalData";
import OptionList from "../../../vehicle-detail/components/OptionList";
import getWarrantyText from "../helpers/getWarrantyText";
import { AugmentedDetailVehicle } from "../types/vehicle";
import { getOptions } from "../helpers/optionsExtractor";
import { OptionType, TermsAndConditionsAttribute } from "../../hygraph/vo";
import useLanguage from "../../../router/hooks/useLanguage";
import useFormatter from "../../common/hooks/useFormatter";
import { getDeliveryFees } from "../../../vehicle-detail/scripts/getDeliveryFees";
import { DealerDetail } from "../../dealer/types/dealer";
import Divider from "../../cms/components/Divider";
import QualityCheckedTabDetail from "./QualityCheckedTabDetail";

type Props = {
  vehicle: AugmentedDetailVehicle;
};

export const ALL_DETAILS_ANCHOR_ID = "anchor-all-details";

const SUMMARY_TAB_INDEX = 0;
const STANDARD_EQUIPMENT_TAB_INDEX = 2;
export const QUALITY_CHECKED_TAB_INDEX = 4;
export const HOME_DELIVERY_TAB_INDEX = 5;

const VehicleDetailTabs: React.FC<Props> = ({ vehicle }) => {
  const optionalEquipment = getOptions(vehicle.options, OptionType.O);
  const standardEquipment = getOptions(vehicle.options, OptionType.S);
  const accessoryEquipment = getOptions(vehicle.options, OptionType.A);

  const language = useLanguage();
  const formatters = useFormatter();

  const hasWarranty = vehicle.warranty !== null;
  const hasDescription = !!vehicle.description;
  const hasQualityChecked = vehicle.qualityChecked !== null;

  // assuming that brandId equals to insideId, https://3.basecamp.com/3101128/buckets/25078318/todos/5617659763
  const brandId = vehicle.brand?.insideId;

  const dealer: DealerDetail = vehicle.dealer;

  const isHomeDeliveryAvailable = dealer?.homeDeliveryAvailable;
  const homeDeliveryFee = dealer.homeDeliveryFee;

  const homeDeliveryFees = getDeliveryFees(formatters, brandId, vehicle.currency, homeDeliveryFee);

  const termsAndConditionsAttribute = vehicle?.vehicleCondition?.termsAndConditionsAttribute ?? "";
  const { newVehicleTcUrl = "", usedVehicleTcUrl = "", privacyPolicyUrl = "" } = vehicle?.dealer;

  return (
    <section className="all-details" id={ALL_DETAILS_ANCHOR_ID}>
      <Tabs>
        <TabList>
          <Tab id={SUMMARY_TAB_INDEX}>{i18next.t("SUMMARY")}</Tab>
          {hasDescription ? <Tab id={1}>{i18next.t("EXPERT INFO")}</Tab> : null}
          <Tab id={STANDARD_EQUIPMENT_TAB_INDEX}>{i18next.t("STANDARD EQUIPMENT")}</Tab>
          {hasWarranty ? <Tab id={3}>{i18next.t("WARRANTY")}</Tab> : null}
          {hasQualityChecked ? <Tab id={QUALITY_CHECKED_TAB_INDEX}>{i18next.t("QUALITY CHECKED")}</Tab> : null}
          {isHomeDeliveryAvailable ? <Tab id={HOME_DELIVERY_TAB_INDEX}>{i18next.t("HOME DELIVERY")}</Tab> : null}
          <Tab id={6}>{i18next.t("GENERAL TERMS AND CONDITIONS")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel expandable id={SUMMARY_TAB_INDEX}>
            <div className="col-span-4">
              <h3 className="tab__title">{i18next.t("TECHNICAL DATA")}</h3>
              <TechnicalData vehicle={vehicle} />
            </div>
            <div className="col-span-8">
              <h3 className="tab__title">{i18next.t("OPTIONAL EQUIPMENT")}</h3>
              <div className="auto-text-flow-2col">
                {optionalEquipment.length > 0 || accessoryEquipment.length > 0 ? (
                  <OptionList options={[...optionalEquipment, ...accessoryEquipment]} />
                ) : (
                  <p>{i18next.t("NO OPTIONS AVAILABLE")}</p>
                )}
                <TabSwitchButton className="link" to={STANDARD_EQUIPMENT_TAB_INDEX}>
                  {i18next.t("SHOW STANDARD EQUIPMENT")}
                </TabSwitchButton>
              </div>
            </div>
          </TabPanel>
          {hasDescription ? (
            <TabPanel id={1} orderPrint={-1}>
              <div className="col-span-8">
                <h3 className="tab__title">{i18next.t("EXPERT INFO LONG TITLE")}</h3>
                <p className="text-wrap">{vehicle.description}</p>
              </div>
            </TabPanel>
          ) : null}
          <TabPanel id={STANDARD_EQUIPMENT_TAB_INDEX}>
            <div className="col-span-12">
              <h3 className="tab__title">{i18next.t("STANDARD EQUIPMENT")}</h3>
              <div className="auto-text-flow-3col">
                {standardEquipment.length > 0 ? (
                  <OptionList options={standardEquipment} />
                ) : (
                  <p>{i18next.t("NO OPTIONS AVAILABLE")}</p>
                )}
                <TabSwitchButton className="link" to={SUMMARY_TAB_INDEX}>
                  {i18next.t("SHOW OPTIONAL EQUIPMENT")}
                </TabSwitchButton>
              </div>
            </div>
          </TabPanel>
          {hasWarranty ? (
            <TabPanel id={3}>
              <div className="col-span-8">
                <h3 className="tab__title">{i18next.t("WARRANTY PERFORMANCE")}</h3>
                <p>{getWarrantyText(vehicle.warranty!, language, formatters)}</p>
              </div>
            </TabPanel>
          ) : null}
          {hasQualityChecked ? (
            <TabPanel id={QUALITY_CHECKED_TAB_INDEX}>
              <div className="col-span-7">
                <div className="tab__title-icon-wrap">
                  <span className="ifont ifont--quality_checked media-object__icon" />
                  <h3 className="tab__title">{i18next.t("QUALITY CHECKED TITLE")}</h3>
                </div>
                <p>{i18next.t("QUALITY CHECKED DESCRIPTION")}</p>
              </div>
              <Divider color="grey" />
              <QualityCheckedTabDetail qualityChecked={vehicle.qualityChecked!} />
            </TabPanel>
          ) : null}
          {isHomeDeliveryAvailable ? (
            <TabPanel id={HOME_DELIVERY_TAB_INDEX}>
              <div className="col-span-7">
                <div className="tab__title-icon-wrap">
                  <span className="ifont ifont--home_delivery media-object__icon" />
                  <h3 className="tab__title">{i18next.t("TITLE VEHICLE DELIVERY ON REQUEST")}</h3>
                </div>
                <p>{i18next.t("VEHICLE DELIVERY ON REQUEST")}</p>
                <p>
                  {homeDeliveryFees?.map(fee => (
                    <React.Fragment key={fee}>
                      {fee}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
            </TabPanel>
          ) : null}
          <TabPanel notInPrint id={6}>
            <div className="col-span-12">
              <h3 className="tab__title">{i18next.t("GENERAL TERMS AND CONDITIONS")}</h3>
              <div className="download-list">
                {termsAndConditionsAttribute === TermsAndConditionsAttribute.NewVehicleTcUrl && newVehicleTcUrl ? (
                  <Link className="download-list__item" target="_blank" to={newVehicleTcUrl}>
                    <span aria-label="icon" className="ifont ifont--download download-list-item__icon" role="img" />
                    <div className="download-list-item__text-wrap">
                      <div className="download-list-item__text">{i18next.t("AGB NEW VEHICLE")}</div>
                    </div>
                  </Link>
                ) : null}
                {termsAndConditionsAttribute === TermsAndConditionsAttribute.UsedVehicleTcUrl && usedVehicleTcUrl ? (
                  <Link className="download-list__item" target="_blank" to={usedVehicleTcUrl}>
                    <span aria-label="icon" className="ifont ifont--download download-list-item__icon" role="img" />
                    <div className="download-list-item__text-wrap">
                      <div className="download-list-item__text">{i18next.t("AGB USED VEHICLE")}</div>
                    </div>
                  </Link>
                ) : null}
                {privacyPolicyUrl ? (
                  <Link className="download-list__item" target="_blank" to={privacyPolicyUrl}>
                    <span aria-label="icon" className="ifont ifont--download download-list-item__icon" role="img" />
                    <div className="download-list-item__text-wrap">
                      <div className="download-list-item__text">{i18next.t("PRIVACY POLICIES")}</div>
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
};

export default VehicleDetailTabs;
