import { VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import { Ad } from "../../hygraph/vo";
import { notNil } from "../../common/helpers/isNil";

export function getChunks(
  vehicles: VehicleSearchResponseItem[],
  searchResultIndexAds: number[],
): VehicleSearchResponseItem[][] {
  if (searchResultIndexAds.length === 0) {
    return [vehicles];
  }

  return searchResultIndexAds.reduce((acc: VehicleSearchResponseItem[][], adIndex, i) => {
    const prevIndex = i === 0 ? 0 : searchResultIndexAds[i - 1];
    return [
      ...acc,
      vehicles.slice(prevIndex, adIndex),
      ...(i === searchResultIndexAds.length - 1 ? [vehicles.slice(adIndex)] : []),
    ];
  }, []);
}

export function getAdsShown(ads: Ad[], nrOfResultsShown: number, searchResultIndexAds: number[]): Ad[] {
  return searchResultIndexAds.map((adIndex, i) => (nrOfResultsShown >= adIndex ? ads[i] : null)).filter(notNil);
}

export function getSearchResultsWithAds(
  vehicles: VehicleSearchResponseItem[],
  searchAds: Ad[],
  adIndexes: number[],
): (VehicleSearchResponseItem | Ad)[] {
  const sortedSearchResultIndexAds = adIndexes.sort();
  const vehicleChunks = getChunks(vehicles, sortedSearchResultIndexAds);
  const adsShown = getAdsShown(searchAds, vehicles.length, sortedSearchResultIndexAds);

  return vehicleChunks
    .map((chunk, i) => [...chunk, adsShown[i]])
    .flat()
    .filter(notNil);
}

export function isAd(item: VehicleSearchResponseItem | Ad): item is Ad {
  return "__typename" in item && item?.__typename === "Ad";
}
