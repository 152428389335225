import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type FilterValueChunksObject = Record<`filter_value${number}`, string>;

type FilterTrackSummedUpEvent = {
  search_interactions_overall: {
    filter_action: "select";
    filter_section: "SERP";
    filter_result_count: number;
  } & FilterValueChunksObject;
};
export type FilterTrackingSummedUpEvent = FilterTrackSummedUpEvent & BaseTrackingEvent;

export const filterTrackSummedUp = (appliedFilters: Record<string, string[] | string>, vehicleCount: number = 0) => {
  const filterValuesString = Object.entries(appliedFilters)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}: ${value}`)
    .join("~");

  // 100 characters is the max length of the filter_value as specified
  const chunks = (filterValuesString.match(/.{1,100}/g) as string[]) ?? [];

  const filterValues = chunks.reduce<FilterValueChunksObject>(
    (acc: FilterValueChunksObject, chunk: string, index: number) => {
      acc[`filter_value${index}`] = chunk;
      return acc;
    },
    {},
  );

  dataLayer([
    {
      event: "gaEvent",
      event_name: "search_interactions_overall",
      search_interactions_overall: {
        filter_action: "select",
        filter_section: "SERP",
        ...filterValues,
        filter_result_count: vehicleCount,
      },
    },
  ]);
};
