import React from "react";
import { FILTER_DEFINITIONS } from "..//services/filterDefinition";
import { getActiveAppliedFilterItems } from "..//helpers/getActiveAppliedFilterItems";
import { getValueNameForReferenceId } from "..//helpers/getNameForReferenceId";
import { FilterReferenceData } from "..//services/reference-data-aggregator/types";
import { getValueNameForGroupedReferenceId } from "..//services/filter-types/groupSelectFilterTypeHelpers";
import { logger } from "../../common/scripts/logger";
import { SelectedValue } from "./selected-filter/SelectedValue";
import { SelectedRange } from "./selected-filter/SelectedRange";
import { SelectedBrandModel } from "./selected-filter/SelectedBrandModel";
import { SelectedGeo } from "./selected-filter/SelectedGeo";
import SelectedFilterPanel from "./SelectedFilterPanel";

type Props = {
  filterData: FilterReferenceData;
  appliedFilters: Record<string, any>;
  updateFilters: (updatedFilters: Record<string, any>) => void;
};

const SelectedFilter: React.FC<Props> = ({ appliedFilters, updateFilters, filterData }) => {
  return (
    <SelectedFilterPanel>
      {getActiveAppliedFilterItems(appliedFilters).map(([queryParam, value]) => {
        // todo: could be discriminated based on type
        const valueAsString = value as string;
        const valueAsArray = value as string[];

        const filterDefinition = FILTER_DEFINITIONS[queryParam];
        switch (filterDefinition.type) {
          case "multiSelect":
          case "tag":
            return (
              <SelectedValue
                key={queryParam}
                filterDefinition={filterDefinition}
                values={valueAsArray.map(id => getValueNameForReferenceId(id, filterDefinition, filterData))}
                remove={id => {
                  updateFilters({
                    [queryParam]: appliedFilters[queryParam].filter((item: string) => item !== id),
                  });
                }}
              />
            );
          case "groupSelect":
            return (
              <SelectedValue
                key={queryParam}
                filterDefinition={filterDefinition}
                remove={(_, index) => {
                  updateFilters({
                    [queryParam]: appliedFilters[queryParam].filter((item: any, i: number) => i !== index),
                  });
                }}
                values={valueAsArray.map(appliedTag => {
                  return getValueNameForGroupedReferenceId(appliedTag, filterDefinition, filterData);
                })}
              />
            );
          case "range":
            return (
              <SelectedRange
                key={queryParam}
                filterDefinition={filterDefinition}
                queryParam={queryParam}
                updateFilters={updateFilters}
                value={valueAsString}
              />
            );
          case "brandModel":
            return (
              <SelectedBrandModel
                key={queryParam}
                filterData={filterData}
                queryParam={queryParam}
                updateFilters={updateFilters}
                value={valueAsArray}
              />
            );
          case "geo":
            return (
              <SelectedGeo
                key={queryParam}
                queryParam={queryParam}
                updateFilters={updateFilters}
                value={valueAsString}
              />
            );
        }
        logger.warn(`unknown filter type for selected tags ${(filterDefinition as any).type}`);
        return null;
      })}
    </SelectedFilterPanel>
  );
};

export default SelectedFilter;
