import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import classNames from "classnames";
import IconButton from "../../visual-components/components/IconButton";
import recordingAsset from "../../../template/public/assets/img/layout/recording-gif.gif";
import useSpeechRecognition from "../../speech-recognition/hooks/useSpeechRecognition";
import useStableFunction from "../../common/hooks/useStableFunction";

type Props = {
  postedQuery: string;
  setPostedQuery: (query: string) => void;
  placeholder?: string;
};

const SearchInput: React.FC<Props> = ({ postedQuery, setPostedQuery, placeholder }) => {
  const queryRef = useRef<HTMLInputElement>(null);
  const [currentQuery, setCurrentQuery] = useState(postedQuery);

  useEffect(() => {
    const postedQueryValue = postedQuery.trim();
    if (queryRef.current && postedQueryValue !== queryRef.current.value.trim()) {
      queryRef.current.value = postedQueryValue;
    }
    setCurrentQuery(postedQuery);
  }, [postedQuery]);

  const submitForm = useStableFunction(() => {
    if (!queryRef.current) return;
    setPostedQuery(queryRef.current.value);
  });
  const onSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    submitForm();
  };
  const reset = () => {
    setPostedQuery("");
    if (queryRef.current) {
      queryRef.current.value = "";
    }
  };

  const { supportsSpeechRecognition, isRecording, startRecording } = useSpeechRecognition(value => {
    if (queryRef.current) {
      queryRef.current.value = value;
      submitForm();
    }
  });

  return (
    <div className="search--transparent  search">
      <form className="form" onSubmit={onSubmit}>
        <input
          ref={queryRef}
          aria-label={i18next.t("SEARCH FOR CAR PLACEHOLDER")}
          className="input  input--search"
          defaultValue={postedQuery}
          placeholder={placeholder ?? i18next.t("SEARCH FOR CAR PLACEHOLDER")}
          type="text"
          onBlur={() => {
            submitForm();
          }}
          onChange={() => {
            setCurrentQuery(queryRef.current?.value || "");
          }}
        />
        <div className={classNames("input-event-icon-wrap", { recording: isRecording, filled: !!currentQuery })}>
          <IconButton
            ariaLabel={i18next.t("CLEAR")}
            className="icon-event-close"
            icon="close"
            type="button"
            onClick={reset}
          />
          {supportsSpeechRecognition ? (
            <button
              className="icon-btn icon-event-mic"
              type="button"
              onClick={() => {
                startRecording();
              }}
            >
              <span className="ifont ifont--mic" />
              <span className="icon-gif">
                <img alt="" src={recordingAsset} />
              </span>
              <span className="vshidden">{i18next.t("VOICE INPUT")}</span>
            </button>
          ) : null}
          <IconButton
            ariaLabel={i18next.t("SEARCH")}
            icon="search"
            type="button"
            onClick={() => {
              if (queryRef.current) {
                queryRef.current.focus();
              }
            }}
          />
        </div>

        <div aria-hidden="true" className="search__line" />
      </form>
    </div>
  );
};

export default SearchInput;
