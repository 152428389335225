import { createApi } from "@reduxjs/toolkit/query/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { SearchResponse } from "@algolia/client-search";
import useLanguage from "../../../router/hooks/useLanguage";
import { algoliaBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";
import { VehicleSearchResponse } from "./vehicleSearchApi";

export type DealerResponseItem = {
  objectID: string;
  insideId: number;
  name: string;
  subname?: string;
  street: string;
  zip: string;
  city: string;
  logoPictureUrl: string;
  phone?: string;
  calltrackingPhone?: string;
  slug: string;
  _geoloc:
    | {
        lat: number | undefined;
        lng: number | undefined;
      }
    | undefined;
  _rankingInfo: {
    matchedGeoLocation?: {
      distance: number;
    };
  };
};

export type DealerSearchResponse = SearchResponse<DealerResponseItem>;

type DealerSearchQueryOptions = Omit<AlgoliaRequestOptions, "language">;

export function searchDealerIndex(
  query = "",
  { language, ...requestOptions }: AlgoliaRequestOptions,
): Promise<SearchResponse<VehicleSearchResponse>> {
  return client.initIndex(buildIndexName(Indices.dealer, language)).search(query, requestOptions);
}

export const dealerSearchApi = createApi({
  reducerPath: "dealerSearchApi",
  baseQuery: algoliaBaseQuery(searchDealerIndex),
  endpoints: builder => ({
    getDealers: builder.query<DealerSearchResponse, { query: string; options: AlgoliaRequestOptions }>({
      query: ({ query, options }) => ({
        query,
        options: {
          getRankingInfo: true,
          ...options,
        },
      }),
      transformResponse: (response: DealerSearchResponse) => response,
    }),
  }),
});

const useRawGetDealersQuery = dealerSearchApi.useGetDealersQuery;

export const useGetDealersQuery = (
  request: { query: string; options: DealerSearchQueryOptions } | typeof skipToken,
) => {
  const language = useLanguage();
  return useRawGetDealersQuery(
    request === skipToken ? skipToken : { query: request.query, options: { ...request.options, language } },
  );
};
