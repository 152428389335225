import i18next from "i18next";

const getCarmarketAuth = (actionToken: string) => `Carmarket ${actionToken}`;

export const getActionTokenAuthHeader = (actionToken: string) => ({
  Authorization: getCarmarketAuth(actionToken),
});

export const getAcceptLanguageHeader = () => ({
  "Accept-Language": i18next.resolvedLanguage,
});
