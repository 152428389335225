import { createApi } from "@reduxjs/toolkit/query/react";
import { addDays, formatISO } from "date-fns";
import baseAuthenticationQuery from "../../auth/services/baseAuthenticationQuery";
import { AuthInfo } from "../../auth/types";
import { BACKEND_API_ENDPOINT } from "../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../modules/common/api/headerBuilders";

export const DEFAULT_PROLONG_PERIOD = 60;

export type SaveSearchBody = {
  name: string;
  description: string;
  searchOptions: string;
  query: string;
};

export const SEARCH_OPTION_QUERY_KEY = "userQuery";

export type SaveSearchData = AuthInfo & { body: SaveSearchBody };

export type SavedSearchData = AuthInfo & { id: string };

export type SavedSearch = {
  id: string;
  name: string;
  description: string;
  query: string;
  searchOptions: Record<string, string>;
  notifyUntil: string | null;
  isNotifying: boolean;
};

async function optimisticSubscriptionNotificationUpdate(
  dispatch: (action: any) => void,
  userId: string,
  id: string,
  queryFulfilled: Promise<unknown>,
  isNotifying: boolean,
) {
  dispatch(
    authenticatedSaveSearchApi.util.updateQueryData("getAllSavedSearches", { userId }, draft => {
      const item = draft.find(item => item.id === id);
      if (item) {
        item.notifyUntil = isNotifying ? formatISO(addDays(new Date(), DEFAULT_PROLONG_PERIOD)) : null;
        item.isNotifying = isNotifying;
      }
    }),
  );
  try {
    await queryFulfilled;
  } catch {
    dispatch(authenticatedSaveSearchApi.util.invalidateTags(["savedSearchList"]));
  }
}

export const authenticatedSaveSearchApi = createApi({
  reducerPath: "authenticatedSaveSearchApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  tagTypes: ["savedSearchList", "session"],
  endpoints: builder => ({
    getAllSavedSearches: builder.query<SavedSearch[], AuthInfo>({
      providesTags: ["savedSearchList", "session"],
      query: ({ userId }) => ({ method: "GET", url: `/user/${userId}/saved-search` }),
      transformResponse: (response: { data: SavedSearch[] }) => response.data,
    }),
    saveSearch: builder.mutation<void, SaveSearchData>({
      invalidatesTags: ["savedSearchList"],
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/saved-search`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: body,
      }),
    }),
    deleteSavedSearch: builder.mutation<void, SavedSearchData>({
      invalidatesTags: ["savedSearchList"],
      query: ({ userId, id }) => ({
        method: "DELETE",
        url: `/user/${userId}/saved-search/${id}`,
      }),
    }),
    cancelSubscription: builder.mutation<void, SavedSearchData>({
      query: ({ userId, id }) => ({
        method: "POST",
        url: `/user/${userId}/saved-search/${id}/cancel-subscription`,
      }),
      async onQueryStarted({ userId, id }, { dispatch, queryFulfilled }) {
        await optimisticSubscriptionNotificationUpdate(dispatch, userId, id, queryFulfilled, false);
      },
    }),
    extendSubscription: builder.mutation<void, SavedSearchData>({
      query: ({ userId, id }) => ({
        method: "POST",
        url: `/user/${userId}/saved-search/${id}/extend-subscription`,
      }),
      async onQueryStarted({ userId, id }, { dispatch, queryFulfilled }) {
        await optimisticSubscriptionNotificationUpdate(dispatch, userId, id, queryFulfilled, true);
      },
    }),
  }),
});

export const {
  useGetAllSavedSearchesQuery,
  useSaveSearchMutation,
  useDeleteSavedSearchMutation,
  useCancelSubscriptionMutation,
  useExtendSubscriptionMutation,
} = authenticatedSaveSearchApi;
