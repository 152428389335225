import React, { useId } from "react";
import classNames from "classnames";

type Props = {
  value?: string;
  label: string;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
  className?: string;
  inactive?: boolean;
  hovered?: boolean;
} & Omit<React.LiHTMLAttributes<HTMLLIElement>, "onChange">;

const CustomSelectOptionCheckbox: React.FC<Props> = ({
  value,
  label,
  checked,
  onChange,
  className = "custom-select__option-checkbox",
  inactive = false,
  hovered = false,
  ...rest
}) => {
  const id = useId();
  return (
    <li aria-selected={checked} className={classNames(className, { inactive, hovered })} role="option" {...rest}>
      <div className="checkbox-wrap">
        <input
          checked={checked}
          className="checkbox "
          id={id}
          type="checkbox"
          value={value || label}
          onChange={e => {
            onChange(value || label, e.target.checked);
          }}
        />
        <label className="label label--checkbox " htmlFor={id}>
          {label}
        </label>
      </div>
    </li>
  );
};

export const CustomSelectInfo = ({ label }: { label: string }) => {
  return <li className={classNames("custom-select__info")}>{label}</li>;
};

export const CustomSelectOption = ({
  label,
  selected,
  inactive = false,
  hovered = false,
  ...rest
}: {
  label: string;
  selected: boolean;
  inactive?: boolean;
  hovered?: boolean;
} & React.LiHTMLAttributes<HTMLLIElement>) => {
  return (
    <li
      aria-selected={selected}
      className={classNames("custom-select__option", { selected, inactive, hovered })}
      role="option"
      {...rest}
    >
      {label}
    </li>
  );
};

export default CustomSelectOptionCheckbox;
