import React, { ReactNode, useEffect, useState } from "react";
import { createNullableContext } from "../../../common/helpers/contextCreator";
import { useOverlayContext } from "../OverlayContext";

type FilterStateContextValue = {
  openFilterId: string | null;
  setOpenFilterId: (id: string | null) => void;
};

export const [FilterStateContext, useFilterStateContext] =
  createNullableContext<FilterStateContextValue>("FilterStateContext");

// todo: consider value memoization
export const FilterStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [openFilterId, setOpenFilterId] = useState<string | null>(null);

  // reset open filter id when power search is closed
  // this is required as power search filter ids are not stable
  const { isOpen } = useOverlayContext();
  useEffect(() => {
    if (!isOpen) {
      setOpenFilterId(null);
    }
  }, [isOpen, setOpenFilterId]);

  return (
    <FilterStateContext.Provider
      value={{
        openFilterId,
        setOpenFilterId,
      }}
    >
      {children}
    </FilterStateContext.Provider>
  );
};
