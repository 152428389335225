import React from "react";
import { Currency, VehiclePromotion } from "../../hygraph/vo";
import PromotionInfoDetail from "./PromotionInfoDetail";

type Props = {
  promotions: VehiclePromotion[];
  currency: Currency;
  openPromotionInfo: (promotion: VehiclePromotion) => void;
};

const PromotionInfo: React.FC<Props> = ({ promotions, currency, openPromotionInfo }) => {
  return (
    <div className="detail-vdp__additional">
      {promotions.map((promotion, index) => (
        <PromotionInfoDetail
          key={index}
          currency={currency}
          promotion={promotion}
          openPromotionInfo={() => {
            openPromotionInfo(promotion);
          }}
        />
      ))}
    </div>
  );
};

export default PromotionInfo;
