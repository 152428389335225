import React from "react";
import { isPackage, Option } from "../../modules/vehicle/helpers/optionsExtractor";

type Props = {
  options: Option[];
};

const OptionList: React.FC<Props> = ({ options }) => {
  return (
    <>
      {options.map((option, i) => {
        const key = `${i}-${option.name}`;
        if (isPackage(option)) {
          return (
            <React.Fragment key={key}>
              <h4 className="tab__subtitle">{option.name}</h4>
              <ul>
                {option.items.map(({ name }, i) => (
                  <li key={`${i}-${name}`}>{name}</li>
                ))}
              </ul>
            </React.Fragment>
          );
        } else {
          return <p key={key}>{option.name}</p>;
        }
      })}
    </>
  );
};

export default OptionList;
