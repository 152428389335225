import React from "react";
import { ButtonLink } from "../../visual-components/components/Button";

type Props = {
  searches: { label: string; link: string }[];
  children: React.ReactNode;
};

const PopularSearches: React.FC<Props> = ({ searches, children: title }) => {
  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <section className="search-suggestion__section">
      <h2 className="section-title">{title}</h2>
      <div className="search-suggestion__list">
        {searches.map(search => (
          <ButtonLink key={search.link} transparent icon="search" to={search.link} onClick={scrollToTop}>
            {search.label}
          </ButtonLink>
        ))}
      </div>
    </section>
  );
};

export default PopularSearches;
