import { createApi } from "@reduxjs/toolkit/query/react";
import i18next from "i18next";
import { FALLBACK, Params } from "../../../router/constants/Params";
import { graphqlRequestBaseQuery } from "../helpers/rtk-query-graphql-request-base-query";

function prepareHeaders(headers: Headers): Headers {
  headers.set("gcms-locales", `${i18next.resolvedLanguage}, ${FALLBACK[Params.lang]}`);
  return headers;
}

// base api for all Hygraph queries
export const hygraphApi = createApi({
  reducerPath: "hygraphApi",
  baseQuery: graphqlRequestBaseQuery({
    url: import.meta.env.VITE_HYGRAPH_ENDPOINT!,
    prepareHeaders,
  }),
  // use this tag to invalidate query whenever language changes
  tagTypes: ["languageDependent"],
  endpoints: () => ({}),
});
