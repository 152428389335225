import { ReactNode, useEffect } from "react";
import { QueryStatus } from "@reduxjs/toolkit/query";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { useVehiclesQuery } from "../../hygraph/services/vehicleDetailApi";
import { ViewedVehicleEntry } from "../hooks/useViewedVehicleList";

function ViewedVehiclesHandler(): ReactNode {
  const [lastViewedList, setLastViewedList] = useLocalStorage<ViewedVehicleEntry[]>(STORAGE_KEYS.LAST_VIEWED_LIST, []);
  const { data: lastShownVehicles, status } = useVehiclesQuery(lastViewedList.map(({ id }) => id));

  const disabledVehicleIds = lastShownVehicles
    ?.filter(vehicle => !vehicle.enabled || vehicle.reserved)
    .map(vehicle => `${vehicle.vehicleId}`);

  useEffect(() => {
    if (
      status === QueryStatus.fulfilled &&
      (disabledVehicleIds?.length || (lastShownVehicles && lastViewedList.length > lastShownVehicles.length))
    ) {
      const newVehicles = lastViewedList.filter(vehicle => {
        return (
          !disabledVehicleIds?.includes(vehicle.id) &&
          lastShownVehicles?.map(({ vehicleId }) => `${vehicleId}`).includes(vehicle.id)
        );
      });

      setLastViewedList(newVehicles);
    }
  }, [status, lastShownVehicles, disabledVehicleIds, lastViewedList, setLastViewedList]);

  return null;
}

export default ViewedVehiclesHandler;
