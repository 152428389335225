import React from "react";
import i18next, { TFunction } from "i18next";
import { TagRemover } from "./TagRemover";
import { FilterBlock } from "./FilterBlock";

type Props = {
  values: { value: string; name: string }[];
  filterDefinition: { title: (t: TFunction) => string };
  remove: (value: string, index: number) => void;
};

export const SelectedValue: React.FC<Props> = ({ filterDefinition, values, remove }) => {
  return (
    <FilterBlock label={filterDefinition.title(i18next.t)}>
      {values.map(({ value, name }, i) => (
        <TagRemover
          key={`${name}-${value}`}
          label={name}
          onClick={() => {
            remove(value, i);
          }}
        />
      ))}
    </FilterBlock>
  );
};
