import React from "react";
import i18next from "i18next";
import useFormatter from "../../common/hooks/useFormatter";

type Props = {
  title: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  vehicleCount: number | undefined;
};

const VehicleSearchHeader: React.FC<Props> = ({ title, headingLevel, vehicleCount }) => {
  const { formatNumber } = useFormatter();
  const Tag = headingLevel ?? "h1";

  return (
    <header className="main__header">
      <Tag className="main-header__title">
        {title ? title : <>&nbsp;</>}
        <strong className="text-gradient">
          {vehicleCount !== undefined ? (
            <>
              {formatNumber(vehicleCount)} {i18next.t("VEHICLE", { count: vehicleCount })}
            </>
          ) : (
            <>&nbsp;</>
          )}
        </strong>
      </Tag>
    </header>
  );
};

export default VehicleSearchHeader;
