import { SearchResponse } from "@algolia/client-search";
import { createApi } from "@reduxjs/toolkit/query/react";
import { skipToken } from "@reduxjs/toolkit/query";
import useLanguage from "../../../router/hooks/useLanguage";
import { algoliaBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";

export type ArticleResponseItem = {
  objectID: string;
  title: string;
  slug: string;
  pointe: string;
  abstract: string;
  date: string;
  // used to build image url
  handle: string;
  imageAltText?: string;
};

export type ArticleSearchResponse = SearchResponse<ArticleResponseItem>;

type SiteSearchQueryOptions = Omit<AlgoliaRequestOptions, "language">;

export function searchSiteIndex(
  query = "",
  { language, ...requestOptions }: AlgoliaRequestOptions,
): Promise<SearchResponse<ArticleSearchResponse>> {
  return client.initIndex(buildIndexName(Indices.site, language)).search(query, requestOptions);
}

export const siteSearchApi = createApi({
  reducerPath: "siteSearchApi",
  baseQuery: algoliaBaseQuery(searchSiteIndex),
  endpoints: builder => ({
    getArticles: builder.query<ArticleSearchResponse, { query: string; options: AlgoliaRequestOptions }>({
      query: ({ query, options }) => ({
        query,
        options: { ...options },
      }),
    }),
  }),
});

const useRawGetArticlesQuery = siteSearchApi.useGetArticlesQuery;

export const useGetArticlesQuery = (request: { query: string; options: SiteSearchQueryOptions } | typeof skipToken) => {
  const language = useLanguage();
  return useRawGetArticlesQuery(
    request === skipToken ? skipToken : { query: request.query, options: { ...request.options, language } },
  );
};
