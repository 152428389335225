import { UserResponse, useUserQuery } from "../services/fusionAuthApi";

function useUser(): UserResponse | null {
  const { data: user } = useUserQuery();
  if (!user) {
    return null;
  }
  return user;
}

export default useUser;
