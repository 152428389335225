export type Coordinates = {
  lat: number;
  lng: number;
};

const asin = Math.asin;
const cos = Math.cos;
const sin = Math.sin;
const sqrt = Math.sqrt;
const PI = Math.PI;

// equatorial mean radius of Earth (in meters)
const R = 6378137;
const Rkm = R / 1000;

function squared(x: number) {
  return x * x;
}
function toRad(x: number) {
  return (x * PI) / 180.0;
}
function hav(x: number) {
  return squared(sin(x / 2));
}

// Extracted / Inspired from https://github.com/dcousens/haversine-distance
export function haversine(a: Coordinates, b: Coordinates) {
  const aLat = toRad(a.lat);
  const bLat = toRad(b.lat);
  const aLng = toRad(a.lng);
  const bLng = toRad(b.lng);

  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
  return 2 * Rkm * asin(sqrt(ht));
}

export function isInRadius(point1: Coordinates, point2: Coordinates, radiusInKm: number): boolean {
  const distance = haversine(point1, point2);
  return distance <= radiusInKm;
}
