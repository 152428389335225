import React from "react";
import { AugmentedDetailVehicle } from "../types/vehicle";
import DetailHeroLeftTile from "./DetailHeroLeftTile";
import DetailHeroRightTile from "./DetailHeroRightTile";

type Props = {
  heroTilesRef: React.RefObject<HTMLDivElement>;
  vehicle: AugmentedDetailVehicle;
};

const DetailHeroTiles: React.FC<Props> = ({ heroTilesRef, vehicle }) => {
  return (
    <div ref={heroTilesRef} className="detail-vdp__price-tile-wrap">
      <DetailHeroLeftTile vehicle={vehicle} />
      <DetailHeroRightTile vehicle={vehicle} />
    </div>
  );
};

export default DetailHeroTiles;
