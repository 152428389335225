import React from "react";
import { Ad, AdTargetTile, Maybe } from "../../hygraph/vo";
import { getImgSrcFromAd } from "../../common/helpers/ad";
import AdCard from "./AdCard";

type Props = {
  ad: Ad | null;
  animationIndex?: number;
  loading?: "lazy" | "eager";
  adPosition: number;
};

const AdCardWrap: React.FC<Props> = ({ ad, animationIndex, loading, adPosition }) => {
  if (!ad) {
    return null;
  }

  const imgSrc = getImgSrcFromAd(ad);
  const adTarget = ad.adTarget as Maybe<AdTargetTile>;

  return (
    <AdCard
      adCampaign={ad.campaign}
      adName={ad.name}
      adPosition={adPosition}
      alt={imgSrc.alt}
      animationIndex={animationIndex}
      headline={ad.headline}
      imgSrc={imgSrc.mobileSrc ?? ""}
      link={ad.ctaLink.to}
      loading={loading}
      pointe={adTarget?.pointe ?? ""}
      showSponsoringTag={ad.showSponsoringTag}
      subline={ad.subline}
    />
  );
};

export default AdCardWrap;
