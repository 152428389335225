import React, { useEffect, useState } from "react";
import DialogPortal from "../../../common/components/DialogPortal";
import Popup from "../../../visual-components/components/Popup";
import useUser from "../../../../auth/hooks/useUser";
import useSaveSearch from "../../hooks/useSaveSearch";
import useEvent from "../../../common/hooks/useEvent";
import { SEARCH_OPTION_QUERY_KEY } from "../../../../save-search/services/authenticatedSaveSearchApi";
import SavedSearch from "./SavedSearch";
import SaveSearch from "./SaveSearch";

type Props = {
  open: boolean;
  close: () => void;
  searchInfo: {
    title: string;
    searchParams: string;
    algoliaOptions: Record<string, string | number | undefined>;
    query: string | undefined;
  };
};

const SaveSearchOverlay: React.FC<Props> = ({ open, close, searchInfo }) => {
  const [formState, setFormState] = useState<"save" | "saved">("save");
  const [savedTitle, setSavedTitle] = useState("");
  const user = useUser();
  const hasUser = user !== null;

  const { saveSearch, saveAnonymousSearch, result } = useSaveSearch(hasUser);

  useEffect(() => {
    if (!open) {
      setFormState("save");
    }
  }, [open, setFormState]);

  const proceed = useEvent(() => {
    setFormState("saved");
  });

  useEffect(() => {
    if (result.isSuccess) {
      proceed();
    }
  }, [result.isSuccess, proceed]);

  const formElement =
    formState === "save" ? (
      <SaveSearch
        defaultSubscriptionName={searchInfo.title}
        isLoading={result.isLoading}
        isLoggedIn={hasUser}
        onSubmit={({ mail, subscriptionName }) => {
          const searchOptions = JSON.stringify({
            ...searchInfo.algoliaOptions,
            [SEARCH_OPTION_QUERY_KEY]: searchInfo.query,
          });
          if (mail) {
            saveAnonymousSearch({
              email: mail,
              name: subscriptionName,
              description: searchInfo.title,
              query: searchInfo.searchParams,
              searchOptions,
            });
          } else {
            saveSearch({
              name: subscriptionName,
              description: searchInfo.title,
              query: searchInfo.searchParams,
              searchOptions,
            });
          }
          setSavedTitle(subscriptionName);
        }}
      />
    ) : (
      <SavedSearch fullSearchDescription={searchInfo.title} isLoggedIn={hasUser} searchTitle={savedTitle} />
    );

  return (
    <DialogPortal>
      <Popup close={close} open={open}>
        {formElement}
      </Popup>
    </DialogPortal>
  );
};

export default SaveSearchOverlay;
