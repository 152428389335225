import React, { useEffect, useId, useRef, useState } from "react";
import i18next from "i18next";
import classNames from "classnames";
import Slider from "react-slider";
import { StringParam } from "use-query-params";
import useDebounce, { INPUT_DEBOUNCE_DELAY } from "../../../common/hooks/useDebounce";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../../services/filterTypeConfiguration";
import { denormalize, normalize, parseFormattedIntegerNumber } from "../..//helpers/mathHelpers";
import {
  DEFAULT_RANGE_FILTER_ID,
  findUnit,
  formatRangeFilterValue,
  getUnitLabel,
  MinimalRangeFilterUnit,
  RANGE_VALUE_SEPARATOR,
  RangeFilterUnit,
} from "../../services/filter-types/rangeFilterType";
import { FilterReferenceData } from "../../services/reference-data-aggregator/types";
import useFormatter from "../../../common/hooks/useFormatter";
import Radio from "../../../visual-components/components/form/Radio";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import FilterBlock from "./FilterBlock";

type Props = {
  title: string;
  queryParam: string;
  units: RangeFilterUnit[] | MinimalRangeFilterUnit;
  data: FilterReferenceData;
};

const RangeInput = ({
  unit,
  value,
  onChange,
  label,
}: {
  unit: RangeFilterUnit | MinimalRangeFilterUnit;
  value: number | undefined;
  onChange: (value: number) => void;
  label: string;
}) => {
  const selectInput: React.FocusEventHandler<HTMLInputElement> = e => {
    e.target.select();
  };
  const { formatNumber } = useFormatter();

  return (
    <div className={classNames("range-inputs__input-wrap", { "show-unit": value })}>
      <input
        aria-label={label}
        className="input input--range"
        placeholder={i18next.t("ALL")}
        type="text"
        value={value ? formatRangeFilterValue(value, unit.formatting, formatNumber) : ""}
        onFocus={selectInput}
        onChange={e => {
          const numericalValue = parseFormattedIntegerNumber(e.target.value);
          onChange(numericalValue);
        }}
      />
      <span className="range__unit">{getUnitLabel(unit)}</span>
    </div>
  );
};

const RangeFilter: React.FC<Props> = ({ title, queryParam, units, data }) => {
  const defaultUnitId = Array.isArray(units) ? units[0].id : DEFAULT_RANGE_FILTER_ID;
  // non normalized range in query param as <unit>-<lower>-<upper>
  const [rawRange, setQueryParamRange] = useFilterQueryParam(queryParam, StringParam);
  const separatedRange = (rawRange || "").split(RANGE_VALUE_SEPARATOR);
  const unitId = separatedRange[0] || defaultUnitId;
  // non normalized tuple of numbers (or null) as [lower, upper]
  const parsedRange: [null | number, null | number] = [null, null];
  if (separatedRange[1]) {
    parsedRange[0] = parseInt(separatedRange[1]);
  }
  if (separatedRange[2]) {
    parsedRange[1] = parseInt(separatedRange[2]);
  }

  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const setQueryRange = (unit: string, lower: number, upper: number) => {
    if (lower === null && upper === null && unit === defaultUnitId) {
      setQueryParamRange(undefined);
    } else {
      setQueryParamRange(
        `${unit}${RANGE_VALUE_SEPARATOR}${lower ? lower : ""}${RANGE_VALUE_SEPARATOR}${upper ? upper : ""}`,
      );
    }
  };

  const setRawRange = ([lower, upper]: [number, number]) => {
    setQueryRange(unitId, lower, upper);
  };

  const setRawRangeUnit = (unit: string) => {
    setQueryRange(unit, parsedRange[0]!, parsedRange[1]!);
  };

  // use ref to prevent needless rerenders
  // prefer ref in favor of useCallback to not include unit in side effects
  const setRawRangeRef = useRef(setRawRange);
  useEffect(() => {
    setRawRangeRef.current = setRawRange;
  });

  const [range, setRange] = useState(parsedRange);

  // sync changes from query param with this instance
  const lower = parsedRange[0];
  const upper = parsedRange[1];
  useEffect(() => {
    setRange([lower, upper]);
  }, [lower, upper, setRange]);

  const debouncedRange = useDebounce(range, INPUT_DEBOUNCE_DELAY);

  const rawRangeRef = useRef(rawRange);
  useEffect(() => {
    rawRangeRef.current = rawRange;
  });

  useEffect(() => {
    if (
      !(rawRangeRef.current === undefined && debouncedRange[0] === null && debouncedRange[1] === null) &&
      userHasInteracted
    ) {
      setRawRangeRef.current(debouncedRange as [number, number]);
    }
  }, [debouncedRange, userHasInteracted]);

  const unit = findUnit(unitId, units);

  const min = unit.min(data);
  const max = unit.max(data);

  const normalizedValues = [
    range[0] ? normalize(range[0], min, max, unit.scale) : 0,
    range[1] ? normalize(range[1], min, max, unit.scale) : 100,
  ];

  const setNormalizedValues = (normalizedValue: number, item: "lower" | "upper") => {
    let newValue: number | null = denormalize(normalizedValue, min, max, unit.scale, unit.precision);
    if (item === "lower" && normalizedValue === 0) {
      newValue = null;
    } else if (item === "upper" && normalizedValue === 100) {
      newValue = null;
    }

    setUserHasInteracted(true);

    if (item === "lower") {
      setRange([newValue, range[1]]);
    } else {
      setRange([range[0], newValue]);
    }
  };

  const groupId = useId();

  const count = FILTER_TYPE_CONFIGURATION_MAP.range.getConfigurationCount(rawRange);

  return (
    <FilterBlock
      className="filter__slider"
      count={count}
      title={title}
      reset={() => {
        setQueryParamRange(undefined);
      }}
    >
      <div className="filter__radio-wrap">
        {Array.isArray(units)
          ? units.map(({ id, label }) => (
              <Radio
                key={id}
                checked={unitId === id}
                label={label(i18next.t)}
                name={groupId}
                value={id}
                onChange={e => {
                  if (e.target.checked) {
                    setRawRangeUnit(id);
                  }
                }}
              />
            ))
          : null}
      </div>
      <div className="range-slider-wrap">
        <Slider
          pearling
          ariaLabel={[i18next.t("LOWER THUMB"), i18next.t("UPPER THUMB")]}
          className="range-slider"
          renderThumb={(props, state) => <div {...props} key={state.index} />}
          thumbActiveClassName="grabbing"
          thumbClassName="range-slider__handle"
          trackClassName="range-slider__indicator"
          value={normalizedValues}
          onChange={(val, index) => {
            const [lower, upper] = val;
            setNormalizedValues(index === 0 ? lower : upper, index === 0 ? "lower" : "upper");
          }}
        />

        <div className="range-inputs">
          <RangeInput
            label={i18next.t("LOWER BOUND")}
            unit={unit}
            value={range[0] || undefined}
            onChange={value => {
              setRange([value, range[1]]);
              setUserHasInteracted(true);
            }}
          />
          <RangeInput
            label={i18next.t("UPPER BOUND")}
            unit={unit}
            value={range[1] || undefined}
            onChange={value => {
              setRange([range[0], value]);
              setUserHasInteracted(true);
            }}
          />
        </div>
      </div>
    </FilterBlock>
  );
};

export default RangeFilter;
