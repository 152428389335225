import { STORAGE_KEYS } from "../constants/Storage";

type ListenerMap = Map<(data: any) => void, boolean>;

const notifyListeners = (data: any, listenerMap: ListenerMap): void => {
  listenerMap.forEach((_, listener) => listener(data));
};

// todo: make "data" type generic
// todo: prune data if no listeners are registered?
const createStorageManager = (storage: Storage, global = window) => {
  let data = new Map<
    string,
    {
      data: any;
      listeners: ListenerMap;
      syncListeners: ListenerMap;
    }
  >();

  const storageEventListener = (event: StorageEvent): void => {
    if (event.storageArea !== storage) {
      return;
    }
    if (event.key) {
      const entry = data.get(event.key);
      if (entry) {
        entry.data = event.newValue === null ? null : JSON.parse(event.newValue);
        notifyListeners(entry.data, entry.listeners);
        notifyListeners(entry.data, entry.syncListeners);
      }
    } else {
      // storage cleared
      data.forEach(entry => {
        entry.data = null;
        notifyListeners(entry.data, entry.listeners);
        notifyListeners(entry.data, entry.syncListeners);
      });
    }
  };

  global.addEventListener("storage", storageEventListener);

  const getOrCache = <T = any>(key: string): { data: T | null; listeners: ListenerMap; syncListeners: ListenerMap } => {
    let cacheEntry = data.get(key);
    if (!cacheEntry) {
      // todo: handle error?
      const item = storage.getItem(key);
      let dataObject = null;
      if (item !== null) {
        dataObject = JSON.parse(item) as T;
      }
      cacheEntry = { data: dataObject, listeners: new Map(), syncListeners: new Map() };
      data.set(key, cacheEntry);
    }
    return cacheEntry;
  };

  return {
    /**
     * Registers a listener that will be called whenever the localstorage changes (even when triggered by current tab).
     * @param key
     * @param listener
     */
    register: (key: STORAGE_KEYS, listener: (data: any) => void) => {
      const cacheEntry = getOrCache(key);
      cacheEntry.listeners.set(listener, true);

      return cacheEntry.data;
    },
    /**
     * Registers a listener that will be only be called when data is externally synced to localstorage.
     * @param key
     * @param listener
     */
    registerSyncListener: (key: STORAGE_KEYS, listener: (data: any) => void) => {
      const cacheEntry = getOrCache(key);
      cacheEntry.syncListeners.set(listener, true);

      return cacheEntry.data;
    },
    unsubscribe: (key: STORAGE_KEYS, listener: (data: any) => void) => {
      const cacheEntry = data.get(key);
      if (cacheEntry) {
        cacheEntry.listeners.delete(listener);
      }
    },
    unsubscribeSyncListener: (key: STORAGE_KEYS, listener: (data: any) => void) => {
      const cacheEntry = data.get(key);
      if (cacheEntry) {
        cacheEntry.syncListeners.delete(listener);
      }
    },
    /**
     * Sets a value in localstorage. Pass null to remove the value.
     * @param key
     * @param value
     */
    setItem: (key: STORAGE_KEYS, value: any) => {
      const cacheEntry = data.get(key);
      if (cacheEntry) {
        cacheEntry.data = value;
        notifyListeners(cacheEntry.data, cacheEntry.listeners);
      }
      if (value === null) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(value));
      }
    },
    getItem: <T>(key: STORAGE_KEYS): T => {
      const cacheEntry = getOrCache(key);
      return cacheEntry.data;
    },
    // todo: this is currently not in use as storage manager is a singleton
    destroy: () => {
      global.removeEventListener("storage", storageEventListener);
      data = new Map();
    },
  };
};

export default createStorageManager;
