import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useBack from "../../../router/hooks/useBack";

type Props = {
  fallback: { to: string; label: string };
};

const TopNav: React.FC<Props> = ({ fallback }) => {
  const { hasBack, text } = useBack();

  const navigate = useNavigate();
  return (
    <div className="main__top-nav">
      {hasBack && !!text ? (
        <a
          className="back"
          href={fallback.to}
          onClick={e => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <span className="ifont ifont--angle_left" />
          {text}
        </a>
      ) : (
        <Link className="back" to={fallback.to}>
          <span className="ifont ifont--angle_left" />
          {fallback.label}
        </Link>
      )}
    </div>
  );
};

export default TopNav;
