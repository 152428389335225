import { FilterReferenceData, FilterItemReferenceData } from "../services/reference-data-aggregator/types";
import { KeyOfType } from "../../common/types/KeyOfType";

export function getValueNameForReferenceId(
  referenceId: string,
  filter: { dataKey: KeyOfType<FilterReferenceData, FilterItemReferenceData> },
  filterData: FilterReferenceData,
): { value: string; name: string } {
  const map = filterData[filter.dataKey].map;
  // todo: null safe access?
  return {
    value: map[referenceId].value,
    name: map[referenceId].name,
  };
}

function getNameForReferenceId(
  referenceId: string,
  filter: { dataKey: KeyOfType<FilterReferenceData, FilterItemReferenceData> },
  filterData: FilterReferenceData,
): string {
  return getValueNameForReferenceId(referenceId, filter, filterData).name;
}

export default getNameForReferenceId;
