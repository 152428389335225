import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type InteractionType = "Add Favorite" | "Remove Favorite";

type TrackEvent = {
  favorites_interactions: {
    vehicle_id: string;
    favorites_interaction: InteractionType;
  };
};
export type FavoriteClickTrackingEvent = BaseTrackingEvent & TrackEvent;

export const favoriteClickTrack = (vehicleId: string, interactionType: InteractionType) => {
  const eventObject: FavoriteClickTrackingEvent = {
    event: "gaEvent",
    event_name: "favorites_interactions",
    favorites_interactions: {
      vehicle_id: vehicleId,
      favorites_interaction: interactionType,
    },
  };
  dataLayer([eventObject]);
};
