import { STORAGE_KEYS } from "../constants/Storage";
import useLocalStorage from "./useLocalStorage";

function useCookieConsent() {
  const [{ accepted }, setStorage] = useLocalStorage<{ accepted: boolean | null }>(STORAGE_KEYS.COOKIES_FLAG, {
    accepted: null,
  });

  return {
    cookiesAccepted: accepted,
    showCookieBanner: accepted === null,
    permitAnalytics: accepted === true,
    setFlag: (accept: boolean) => setStorage({ accepted: accept }),
  };
}

export default useCookieConsent;
