import { jwtDecode } from "jwt-decode";
import { FilterReferenceData } from "../../vehicle-search/services/reference-data-aggregator/types";
import Locale from "../../common/constants/Locale";
import { isSafari } from "../../common/helpers/userAgent";
import { notNil } from "../../common/helpers/isNil";
import { SpeechToken } from "./speechToTextApi";

type Options = {
  speechToken: SpeechToken;
  filterReferenceData: FilterReferenceData;
  locale: Locale;
};

// we can't use the swiss locales, as there's no support for the phrase list
// https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=stt
const localeToAzureSpeechServiceLocaleMap: Record<Locale, string> = {
  [Locale.German]: "de-DE",
  [Locale.French]: "fr-FR",
  [Locale.Italian]: "it-IT",
};

export type AzureSpeechServiceJwtPayload = {
  region: string;
  exp: number;
};

export const setupAzureSpeechToText = async ({ speechToken, filterReferenceData, locale }: Options) => {
  const { SpeechConfig, AudioConfig, SpeechRecognizer, ServicePropertyChannel, PhraseListGrammar } = await import(
    "microsoft-cognitiveservices-speech-sdk"
  );
  const { token } = speechToken;
  const region = jwtDecode<AzureSpeechServiceJwtPayload>(token).region;

  const speechConfig = SpeechConfig.fromAuthorizationToken(token, region);
  speechConfig.speechRecognitionLanguage = localeToAzureSpeechServiceLocaleMap[locale];

  // this prevents the automatic punctuation
  speechConfig.setServiceProperty("punctuation", "explicit", ServicePropertyChannel.UriQueryParameter);

  const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = new SpeechRecognizer(speechConfig, audioConfig);
  const phraseList = PhraseListGrammar.fromRecognizer(recognizer);

  const { brands, bodyTypes, exteriorColors, vehicleConditions, vehicleTypes, driveTypes, fuelTypes } =
    filterReferenceData;

  const keywords = [
    ...brands.list.map(brand => brand.name),
    ...bodyTypes.list.map(bodyType => bodyType.name),
    ...exteriorColors.list.map(color => color.name),
    ...vehicleConditions.list.map(condition => condition.name),
    ...vehicleTypes.list.map(vehicleType => vehicleType.name),
    ...driveTypes.list.map(driveType => driveType.name),
    ...fuelTypes.list.map(fuelType => fuelType.name),
  ];

  keywords.forEach(keyword => {
    phraseList.addPhrase(keyword);
  });

  return recognizer;
};

export const OnDeviceSpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

// Disable Speech Recognition on Safari as it's not working good enough and will just lead to frustration for the users (May 2023)
export const supportsOnDeviceSpeechRecognition = notNil(OnDeviceSpeechRecognition) && !isSafari;

const VALIDITY_THRESHOLD_MS = 10 * 1000; // 10 seconds

export const determineIfAzureSpeechTokenIsStillValid = (speechToken: string) => {
  const expirationTime = jwtDecode<AzureSpeechServiceJwtPayload>(speechToken).exp;
  const expiryDate = new Date(0);
  expiryDate.setUTCSeconds(expirationTime);

  const now = new Date();

  const expiryInMillis = expiryDate.getTime() - now.getTime();

  const validTimeInMillis = expiryInMillis - VALIDITY_THRESHOLD_MS;

  return validTimeInMillis > 0;
};
