import React from "react";
import { useMatchmaker } from "../../hooks/useMatchmaker";
import StatusSmallVisual from "./StatusSmallVisual";

const StatusSmall: React.FC = () => {
  const { answeredNumberOfQuestions, totalNumberOfQuestions, isInitialized } = useMatchmaker();

  return (
    <StatusSmallVisual
      answeredNumberOfQuestions={answeredNumberOfQuestions}
      isInitialized={isInitialized}
      totalNumberOfQuestions={totalNumberOfQuestions}
    />
  );
};
export default StatusSmall;
