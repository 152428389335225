import React, { ReactNode, useContext, useEffect, useState } from "react";
import { createNullableContext } from "../../../modules/common/helpers/contextCreator";
import { MessageTypes, registerMessageListenerOnElement, sendMessage } from "../services/messages";
import { WhitelabelConfigType } from "../services/whitelabelConfigSchema";
import WhitelabelType from "../constants/WhitelabelType";
import isNil from "../../../modules/common/helpers/isNil";

export const [WhitelabelConfigContext, useWhitelabelConfig] =
  createNullableContext<WhitelabelConfigType>("WhitelabelConfigContext");

export const useBannerWhitelabelConfig = () => {
  const config = useWhitelabelConfig();

  if (config.type !== WhitelabelType.Banner) {
    throw new Error(`useBannerWhitelabelConfig is only valid if config is of type banner`);
  }

  return config;
};

export const useVehicleRowWhitelabelConfig = () => {
  const config = useWhitelabelConfig();

  if (config.type !== WhitelabelType.VehicleRow) {
    throw new Error(`useVehicleRowWhitelabelConfig is only valid if config is of type vehicleRow`);
  }

  return config;
};

export const useIsInWhitelabelContext = () => {
  const context = useContext(WhitelabelConfigContext);

  return !isNil(context);
};

export const useFullWhitelabelConfig = () => {
  const config = useWhitelabelConfig();

  if (config.type !== WhitelabelType.Whitelabel) {
    throw new Error(`useFullWhitelabelConfig is only valid if config is of type whitelabel`);
  }

  return config;
};

type Props = {
  children: ReactNode;
};

const NO_CONFIG_WARNING_TIMEOUT = 5000;

const WhitelabelConfig: React.FC<Props> = ({ children }) => {
  const [config, setConfig] = useState<null | WhitelabelConfigType>(null);
  useEffect(() => {
    const cleanupEffect = registerMessageListenerOnElement(window, message => {
      switch (message.type) {
        case MessageTypes.INIT:
          setConfig(message.payload);
          break;
        case MessageTypes.HASHCHANGE:
          if (window.location.hash !== message.payload) {
            window.location.hash = message.payload;
          }
          break;
      }
    });
    sendMessage(window.parent, { type: MessageTypes.READY });
    return cleanupEffect;
  }, []);

  useEffect(() => {
    if (config) {
      return;
    }
    const timeout = setTimeout(() => {
      // this is an intentional information message to prevent confusion
      // eslint-disable-next-line no-console
      console.warn(`no config detected, please make sure to provide config`);
    }, NO_CONFIG_WARNING_TIMEOUT);
    return () => {
      clearTimeout(timeout);
    };
  }, [config]);

  if (config === null) {
    return null;
  }
  return <WhitelabelConfigContext.Provider value={config}>{children}</WhitelabelConfigContext.Provider>;
};

export default WhitelabelConfig;
