import React from "react";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import PopupContent from "../../../visual-components/components/PopupContent";
import InputField from "../../../visual-components/components/form/InputField";
import Button from "../../../visual-components/components/Button";
import { mailValidator, requiredValidator } from "../../../form/helpers/validationHelpers";

type Props = {
  isLoggedIn: boolean;
  defaultSubscriptionName: string;
  onSubmit: (data: { subscriptionName: string; mail?: string }) => void;
  isLoading: boolean;
};

type FormData = {
  subscriptionName: string;
  mail?: string;
};

const SaveSearch: React.FC<Props> = ({ isLoggedIn, defaultSubscriptionName, onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      subscriptionName: defaultSubscriptionName,
      mail: "",
    },
  });

  return (
    <PopupContent
      body={i18next.t("SAVE SEARCH DESCRIPTION")}
      headerIcon={{ icon: "bell" }}
      title={i18next.t("SAVE SEARCH")}
      form={
        <form
          onSubmit={handleSubmit(({ subscriptionName, mail }) => {
            onSubmit(isLoggedIn ? { subscriptionName } : { subscriptionName, mail });
          })}
        >
          <div className="form__row">
            <div className="form__col">
              <InputField
                label={i18next.t("NAME OF SEARCH")}
                {...register("subscriptionName", { ...requiredValidator() })}
                error={errors.subscriptionName?.message}
              />
            </div>
          </div>

          {isLoggedIn ? null : (
            <>
              <div className="form__row">
                <div className="form__col">
                  <InputField
                    label={i18next.t("EMAIL")}
                    type="email"
                    {...register("mail", { ...requiredValidator(), ...mailValidator() })}
                    error={errors.mail?.message}
                  />
                </div>
              </div>
              <div className="form__row">
                <p className="text-sml text-meta">{i18next.t("SAVE SEARCH DATA PROTECTION DISCLAIMER")}</p>
              </div>
            </>
          )}
          <div className="form__row right">
            <div className="form__col">
              <Button disabled={isLoading} type="submit">
                {i18next.t("CONTINUE")}
              </Button>
            </div>
          </div>
        </form>
      }
    />
  );
};

export default SaveSearch;
