import React from "react";
import { ArrayParam } from "use-query-params";
import classNames from "classnames";
import i18next from "i18next";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import FilterBlock from "./FilterBlock";
import { FilterItem } from "./FilterItem";

type Props = {
  title: string;
  queryParam: string;
  data: FilterItem[];
  // indicates whether only basic filter values shall be rendered instead of all possible values
  renderOnlyBasicItems: boolean;
};

export const TagFilterBlock = ({
  title,
  count,
  reset,
  data,
  update,
}: {
  title: string;
  count: number;
  reset: () => void;
  update: (value: string, selected: boolean) => void;
  data: { value: string; name: string; inactive: boolean; selected: boolean }[];
}) => {
  return (
    <FilterBlock className="filter__tag" count={count} reset={reset} title={title}>
      <div className="tag-wrap">
        <div className={classNames("tag", { active: count === 0 })} onClick={reset}>
          <span className="tag__text">{i18next.t("ALL")}</span>
        </div>
        {data.map(({ value, name, inactive, selected }, i) => {
          return (
            <div
              key={`${name}-${i}`}
              className={classNames("tag", { active: selected, inactive })}
              onClick={() => {
                update(value, !selected);
              }}
            >
              <span className="tag__text">{name}</span>
            </div>
          );
        })}
      </div>
    </FilterBlock>
  );
};

const TagFilter: React.FC<Props> = ({ title, queryParam, data, renderOnlyBasicItems }) => {
  const [persistedTags, setTags] = useFilterQueryParam(queryParam, ArrayParam);

  const appliedTags: string[] = persistedTags || [];
  const hasAppliedTags = appliedTags.length > 0;
  const tagMap = appliedTags.reduce<Record<string, boolean>>((agg, tag) => {
    if (tag) {
      agg[tag] = true;
    }
    return agg;
  }, {});
  const filteredData = renderOnlyBasicItems ? data.filter(item => item.isBasicFilterValue || tagMap[item.value]) : data;
  return (
    <TagFilterBlock
      count={appliedTags.length}
      title={title}
      data={filteredData.map(({ value, name, occurrence }) => ({
        value,
        name,
        inactive: occurrence === 0 && !hasAppliedTags,
        selected: tagMap[value],
      }))}
      reset={() => {
        setTags(undefined);
      }}
      update={filter => {
        const isSet = tagMap[filter];
        if (isSet) {
          setTags(appliedTags.filter(item => item !== filter));
        } else {
          setTags([...appliedTags, filter]);
        }
      }}
    />
  );
};

export default TagFilter;
