import { skipToken } from "@reduxjs/toolkit/query";
import { useGetIpGeoLocationDataQuery } from "../services/geoLocationApi";
import { Coordinates } from "../helpers/distance";

const useGetIpGeoLocation = ({
  skip,
}: {
  skip?: boolean;
} = {}): { ipGeoLocation: Coordinates | null; isFetching: boolean } => {
  const { data, isFetching } = useGetIpGeoLocationDataQuery(skip ? skipToken : undefined);

  if (data?.latitude && data?.longitude) {
    return {
      ipGeoLocation: { lat: data.latitude, lng: data.longitude },
      isFetching,
    };
  }

  return { ipGeoLocation: null, isFetching };
};

export default useGetIpGeoLocation;
