import React, { ReactNode, RefObject, useEffect } from "react";
import { usePresence } from "framer-motion";

type Props<T extends HTMLElement> = {
  children: (ref: RefObject<T>, className: string | null) => ReactNode;
  animationClassName: string;
  durationMs: number;
};

/**
 * Inspired by react-transition-group. Uses framer-motion's <AnimatePresence /> as a base.
 *
 * Make sure to set both the reference and the className when using this component.
 *
 * @param children
 * @param animationClassName
 * @param durationMs
 * @constructor
 */
const CssPresenceAnimation = <T extends HTMLElement>({ children, animationClassName, durationMs }: Props<T>) => {
  const [isPresent, safeToRemove] = usePresence();

  const dialogRef = React.useRef<T>(null);
  const classNameRef = React.useRef<string | null>(null);

  useEffect(() => {
    const node = dialogRef.current;
    if (node) {
      // This is to force a repaint,
      // which is necessary in order to transition styles when adding a class name.
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      node.scrollTop;
      node.classList.add(animationClassName);
      classNameRef.current = animationClassName;
    }
  }, [animationClassName]);

  useEffect(() => {
    if (!isPresent) {
      const timeout = setTimeout(() => {
        safeToRemove();
      }, durationMs);
      const node = dialogRef.current;
      if (node) {
        node.classList.remove(animationClassName);
        classNameRef.current = null;
      }
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isPresent, safeToRemove, animationClassName, durationMs]);

  return children(dialogRef, classNameRef.current);
};

export default CssPresenceAnimation;
