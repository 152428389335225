import React from "react";
import i18next from "i18next";
import useFormatter from "../../common/hooks/useFormatter";
import isNil from "../../common/helpers/isNil";
import { EnergyEfficiencyGrade } from "../types/EnergyEfficiencyGrade";
import { ENERGY_DATA_ITEMS } from "../services/energyData";
import { DetailVehicle } from "../types/vehicle";

import efficiencyGradeAPlus from "../../../template/public/assets/img/content/energy-efficiency-APlus.svg";
import efficiencyGradeA from "../../../template/public/assets/img/content/energy-efficiency-A.svg";
import efficiencyGradeB from "../../../template/public/assets/img/content/energy-efficiency-B.svg";
import efficiencyGradeC from "../../../template/public/assets/img/content/energy-efficiency-C.svg";
import efficiencyGradeD from "../../../template/public/assets/img/content/energy-efficiency-D.svg";
import efficiencyGradeE from "../../../template/public/assets/img/content/energy-efficiency-E.svg";
import efficiencyGradeF from "../../../template/public/assets/img/content/energy-efficiency-F.svg";
import efficiencyGradeG from "../../../template/public/assets/img/content/energy-efficiency-G.svg";

const EFFICIENCY_GRADE_SVG_MAP: Record<EnergyEfficiencyGrade, string> = {
  "A+": efficiencyGradeAPlus,
  A: efficiencyGradeA,
  B: efficiencyGradeB,
  C: efficiencyGradeC,
  D: efficiencyGradeD,
  E: efficiencyGradeE,
  F: efficiencyGradeF,
  G: efficiencyGradeG,
};

type Props = {
  vehicle: DetailVehicle;
};

const EnergyData: React.FC<Props> = ({ vehicle }) => {
  const formatters = useFormatter();
  const energyDataItems = ENERGY_DATA_ITEMS.filter(({ key }) => !isNil(key(vehicle)));
  return (
    <>
      {!!energyDataItems.length && (
        <h3 className="detail-section__title energy__title">{i18next.t("ENVIRONMENT AND ENERGY DATA")}</h3>
      )}
      {vehicle.emissionClass ? (
        <div className="energy__img">
          <img alt="" src={EFFICIENCY_GRADE_SVG_MAP[vehicle.emissionClass.name as EnergyEfficiencyGrade]} />
        </div>
      ) : null}
      {!!energyDataItems.length && (
        <div className="energy__data">
          <table className="table-simple">
            <tbody>
              {energyDataItems.map(({ label, render }, i) => {
                const labelText = label(i18next.t);
                return (
                  <tr key={labelText}>
                    <th>
                      <p>{labelText}</p>
                    </th>
                    <td>
                      {render(vehicle, formatters).map((item, i) => (
                        <p key={`${item}-${i}`}>{item}</p>
                      ))}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default EnergyData;
