import { VehicleItem } from "../../types/ECommerce";
import { dataLayer } from "../../dataLayer";
import { Page } from "../../../../router/constants/Page";
import debounce from "../../../common/helpers/debounce";
import { IntersectionTrackingVehicle, ProductIntersectionTrackingEvent } from "./types/IntersectionTypes";
import { buildViewItem } from "./buildViewItem";

const GLOBAL_DEBOUNCE_VEHICLE_TRACK_MS = 2000;

const vehicleTrackingSingleton: { pendingVehicleItems: VehicleItem[] } = {
  pendingVehicleItems: [],
};

export const batchVehiclesTrack = (vehicle: IntersectionTrackingVehicle, pageType: Page | null, page?: number) => {
  const item = buildViewItem(vehicle, page);
  vehicleTrackingSingleton.pendingVehicleItems.push(item);
  processItemsInterval(pageType);
};

const processItemsInterval = debounce((pageType: Page | null) => {
  if (vehicleTrackingSingleton.pendingVehicleItems.length > 0) {
    const vehicleItems: VehicleItem[] = [...vehicleTrackingSingleton.pendingVehicleItems];
    vehicleTrackingSingleton.pendingVehicleItems = [];
    vehicleIntersectionTrack(vehicleItems, pageType);
  }
}, GLOBAL_DEBOUNCE_VEHICLE_TRACK_MS);

const vehicleIntersectionTrack = (vehicleItems: VehicleItem[], pageType: Page | null) => {
  return dataLayer([
    {
      event: "eec_view_item_list",
      event_name: "view_item_list",
      page_type: pageType,
      ecommerce: {
        items: [...vehicleItems],
      },
    } satisfies ProductIntersectionTrackingEvent,
  ]);
};
