import React from "react";
import i18next from "i18next";
import classNames from "classnames";
import { AugmentedDetailVehicle } from "../types/vehicle";
import useVehicleCtaItems from "../hooks/useVehicleCtaItems";
import Button from "../../visual-components/components/Button";
import usePopupTrigger from "../../common/hooks/usePopupTrigger";
import useIsInViewport from "../../common/hooks/useIsInViewport";
import Image, { IMAGE_FIT_TYPES, IMAGE_TRANSFORM_TYPES } from "../../visual-components/components/Image";

type Props = {
  alt?: string;
  footerRef: React.RefObject<HTMLDivElement>;
  heroTilesRef: React.RefObject<HTMLDivElement>;
  imgSrc: string;
  vehicle: AugmentedDetailVehicle;
};

const DetailHeroStickyBar: React.FC<Props> = ({ alt, footerRef, heroTilesRef, imgSrc, vehicle }) => {
  const { inquireItem, testDriveItem, dealerPhoneItem } = useVehicleCtaItems(vehicle);
  const belowTiles = usePopupTrigger(heroTilesRef, false);
  const hide = useIsInViewport(footerRef, 0.1);

  return (
    <div className={classNames("detail-vdp__stickybar", { hide__desktop: !belowTiles, hide })}>
      <div className="stickybar-inner">
        <div className="stickybar__img-wrap">
          <Image
            alt={alt}
            baseSrc={imgSrc}
            fit={IMAGE_FIT_TYPES.crop}
            imageSizes={IMAGE_TRANSFORM_TYPES.stickyBarVehicleImage}
          />
        </div>
        <p className="stickybar__text">
          <strong>{vehicle.brand?.name}</strong>&nbsp;
          {vehicle.title}
        </p>
        <div className="stickybar__btn-wrap">
          {inquireItem ? (
            <Button secondary className="btn--mobile-01" icon={inquireItem.icon} onClick={inquireItem.onClick}>
              {inquireItem.label}
            </Button>
          ) : null}

          {testDriveItem ? (
            <Button
              secondary
              transparent
              className="btn--mobile-02"
              icon={testDriveItem.icon}
              mobileText={i18next.t("TEST DRIVE")}
              onClick={testDriveItem.onClick}
            >
              {testDriveItem.label}
            </Button>
          ) : null}

          {dealerPhoneItem ? (
            <Button
              secondary
              transparent
              className="btn--mobile-03"
              icon={dealerPhoneItem.icon}
              mobileText={i18next.t("CALL")}
              onClick={dealerPhoneItem.onClick}
            >
              {dealerPhoneItem.label}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DetailHeroStickyBar;
