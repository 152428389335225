import React, { ReactNode, useRef } from "react";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { usePageScroller } from "../../common/hooks/usePageScroller";
import Pagination from "../../common/components/Pagination";
import { PAGINATION_LIMITED_TO, VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import { PAGE_QUERY_PARAM } from "../hooks/useFilterQueryParam";
import isNil from "../../common/helpers/isNil";
import useSearchResults from "../hooks/useSearchResults";
import SearchResults from "./SearchResults";

type Props = {
  page: number;
  setPage: (p: number) => void;
  vehicleData: VehicleSearchResponse | undefined;
  noResults: ReactNode;
  // Note: Contexts such as whitelabel must not apply the fix as it would lead to issues with the auto-resize of the iframe
  disableMinHeightFix?: boolean;
  hasAds: boolean;
};

const Results: React.FC<Props> = ({ page, setPage, vehicleData, noResults, disableMinHeightFix, hasAds }) => {
  const totalVehicles = vehicleData?.nbHits ? Math.min(vehicleData?.nbHits, PAGINATION_LIMITED_TO) : 0;
  const { searchResults, isLoaded } = useSearchResults(vehicleData, hasAds);

  const { searchPath } = usePathBuilder();

  const isFetching = isNil(vehicleData) || !isLoaded;

  const listStartPointRef = useRef<HTMLDivElement>(null);
  const updateCurrentPage = usePageScroller(listStartPointRef, isFetching, page, setPage);

  const getSearchPath = (p: number): string => {
    const params = new URLSearchParams(window.location.search);
    if (p === 0) {
      params.delete(PAGE_QUERY_PARAM, `${p}`);
    } else {
      params.set(PAGE_QUERY_PARAM, `${p}`);
    }
    const hasQuestionMark = params.size !== 0 || p !== 0;

    return `${searchPath()}${hasQuestionMark ? "?" : ""}${params.toString()}`;
  };

  const didNotFindAnyVehicles = vehicleData?.hits.length === 0;

  return (
    <>
      <div ref={listStartPointRef} className="scroll-target" />
      {didNotFindAnyVehicles ? noResults : null}
      {!disableMinHeightFix && isFetching ? <div className="fix-min-height" /> : null}
      {!isFetching ? (
        <>
          <SearchResults page={page} searchResults={searchResults} vehicleData={vehicleData} />
          <Pagination
            currentPage={page}
            pageSize={vehicleData.hitsPerPage}
            setCurrentPage={updateCurrentPage}
            totalItems={totalVehicles}
            getPath={p => {
              return getSearchPath(p);
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default Results;
