import { useContext } from "react";
import { HistoryState } from "../../../router/constants/State";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { BounceOffType, InBounceOffContext } from "../../../whitelabel/vehicle-detail/components/BounceOffPopup";

function useVehiclePurchaseLink(): (args: { slug: string | undefined | null; id: string | number }) => {
  to: string;
  state?: Record<string, number>;
  rel: string;
} {
  const isInBounceOffContext = useContext(InBounceOffContext);
  const { vehiclePurchasePath } = usePathBuilder();
  return ({ slug, id }) => {
    if (isInBounceOffContext) {
      return {
        to: `#${BounceOffType.PURCHASE}`,
        rel: "nofollow",
      };
    }
    return {
      to: vehiclePurchasePath({ slug: slug!, id: `${id}` }),
      state: { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 },
      rel: "nofollow",
    };
  };
}

export default useVehiclePurchaseLink;
