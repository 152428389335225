import { useEffect } from "react";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";
import { selectIsLoggedIn } from "../../auth/authSelectors";
import useEvent from "../../modules/common/hooks/useEvent";
import { FavoriteEntry, useAddFavoritesMutation, useGetAllFavoritesQuery } from "../services/favoritesApi";
import useVehicleFavoritesList from "../../modules/vehicle/hooks/useVehicleFavoritesList";
import useAuth from "../../auth/hooks/useAuth";

function useFavoritesSync() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { list, addVehicles } = useVehicleFavoritesList();

  const [addFavorites, addFavoritesResult] = useAddFavoritesMutation();
  const authInfo = useAuth();
  const { data: persistedFavorites } = useGetAllFavoritesQuery(authInfo ? authInfo : skipToken);

  const triggerFavoritesSync = useEvent(() => {
    if (authInfo && list.length > 0) {
      addFavorites({ ...authInfo, body: list });
    }
  });
  useEffect(() => {
    if (isLoggedIn) {
      triggerFavoritesSync();
    }
  }, [isLoggedIn, triggerFavoritesSync]);

  const addVehiclesIfNotStored = useEvent((vehicles: FavoriteEntry[]) => {
    const vehiclesMap = list.map(id => [parseInt(id), true]);
    const hasNewVehicles = vehicles.some(({ vehicleId }) => !vehiclesMap[`${vehicleId}`]);
    if (hasNewVehicles) {
      addVehicles(vehicles.map(({ vehicleId }) => `${vehicleId}`));
    }
  });

  useEffect(() => {
    if (addFavoritesResult.data) {
      addVehiclesIfNotStored(addFavoritesResult.data);
    }
  }, [addFavoritesResult.data, addVehiclesIfNotStored]);

  useEffect(() => {
    if (persistedFavorites) {
      addVehiclesIfNotStored(persistedFavorites);
    }
  }, [persistedFavorites, addVehiclesIfNotStored]);
}

export default useFavoritesSync;
