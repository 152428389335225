import React from "react";

type Props = {
  error: string | undefined;
};

const FormErrorMessage: React.FC<Props> = ({ error }) => {
  return <>{error ? <div className="form-error-message">{error}</div> : null}</>;
};

export default FormErrorMessage;
