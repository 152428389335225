import { useGetVehiclesQuery } from "../../modules/algolia/services/vehicleSearchApi";
import { getAppliedFilters } from "../../modules/vehicle/helpers/getAppliedFilters";
import { BaseVehicle } from "../../modules/vehicle/types/vehicle";

const SHOWN_RELATED_VEHICLES = 20;

function useRelatedVehicles(vehicle: BaseVehicle, dealerRestriction: number | null) {
  const { filters, optionalFilters } = getAppliedFilters(vehicle, dealerRestriction);

  const { data: similarVehicles } = useGetVehiclesQuery({
    query: "",
    options: {
      hitsPerPage: SHOWN_RELATED_VEHICLES,
      filters,
      optionalFilters,
    },
  });

  return similarVehicles;
}

export default useRelatedVehicles;
