import * as Sentry from "@sentry/react";
import { notNil } from "../helpers/isNil";

// logger may actually use console
/* eslint-disable no-console */

// too many requests on sentry will cause a http status "429 Too many requests"
// the limit is chosen arbitrarily
const MESSAGE_LOG_LIMIT = 5;
const logMessageMap: Map<string, number> = new Map();

const updateLogMessageMapFor = (message: string) => {
  const mapEntry = logMessageMap.get(message) ?? 0;
  if (mapEntry > MESSAGE_LOG_LIMIT) {
    console.log(`Suppressing log message: ${message}`);
    return false;
  }
  logMessageMap.set(message, mapEntry + 1);
  return true;
};

export const logger = {
  error: (message: string, data?: Record<string, unknown> & { exception?: unknown }) => {
    console.error(message, data);
    const shallCapture = updateLogMessageMapFor(message);
    if (!shallCapture) {
      return;
    }
    if (data?.exception && data?.exception instanceof Error) {
      const { exception, ...properties } = data;
      Sentry.withScope(scope => {
        scope.setExtras({
          ...properties,
          message,
        });
        Sentry.captureException(exception);
      });
    } else {
      Sentry.withScope(scope => {
        if (notNil(data)) {
          scope.setExtras(data);
        }
        Sentry.captureMessage(message, "error");
      });
    }
  },
  warn: (message: string, data?: Record<string, unknown> & { exception?: unknown }) => {
    console.warn(message, data);
    const shallCapture = updateLogMessageMapFor(message);
    if (!shallCapture) {
      return;
    }
    Sentry.withScope(scope => {
      if (notNil(data)) {
        scope.setExtras(data);
      }
      Sentry.captureMessage(message, "warning");
    });
  },
};
