import React, { useState } from "react";
import i18next from "i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { StringParam } from "use-query-params";
import SingleOptionSelect from "../../../visual-components/components/form/SingleOptionSelect";
import { FILTER_TYPE_CONFIGURATION_MAP } from "../../services/filterTypeConfiguration";
import { DEFAULT_PERIMETER, getDefaultGeoFilterTuple } from "../../services/filter-types/geoFilterType";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import GeoInputField from "../../../geo/components/GeoInputField";
import { GEO_FILTER_SEPARATOR } from "../../../geo/constants";
import FilterBlock from "./FilterBlock";

type Props = {
  queryParam: string;
};

export const PERIMETER_OPTIONS = [5, 10, 20, 30, 50, 75, 100, 200];

const GeoFilter: React.FC<Props> = ({ queryParam }) => {
  // <lat>|<lng>|<perimeter>|(<displayName>)
  const [persistedGeo, setGeoQuery] = useFilterQueryParam(queryParam, StringParam);
  const updateQuery = (
    lat: string | number,
    lng: string | number,
    perimeter: string = DEFAULT_PERIMETER,
    locationName?: string,
  ) => {
    setGeoQuery([lat, lng, perimeter, locationName].join(GEO_FILTER_SEPARATOR));
  };

  const [lat, lng, perimeter, displayName] = getDefaultGeoFilterTuple(persistedGeo);

  const [findPlaceQuery, setFindPlaceQuery] = useState<typeof skipToken | [string, string]>(skipToken);

  const setPerimeter = (perimeter: string) => {
    updateQuery(lat, lng, perimeter, displayName);
  };

  const perimeterOptions = PERIMETER_OPTIONS.map(value => ({
    value: `${value}`,
    label: i18next.t("KM PLACEHOLDER", { value }),
  }));

  const reset = () => {
    setGeoQuery(undefined);
    setFindPlaceQuery(skipToken);
  };

  return (
    <FilterBlock
      className="filter__combi"
      count={FILTER_TYPE_CONFIGURATION_MAP.geo.getConfigurationCount(persistedGeo)}
      reset={reset}
      title={i18next.t("LOCATION TITLE")}
    >
      <div className="box">
        <GeoInputField
          findPlaceQuery={findPlaceQuery}
          geoLocation={{ lat, lng, displayName }}
          resetGeoLocation={reset}
          setFindPlaceQuery={setFindPlaceQuery}
          setGeoLocation={({ lat, lng }) => {
            updateQuery(lat, lng, perimeter);
          }}
          setResolvedGeoLocation={({ lat, lng, displayName }) => {
            updateQuery(lat, lng, perimeter, displayName ?? undefined);
          }}
        />
        <SingleOptionSelect
          label={i18next.t("PERIMETER")}
          options={perimeterOptions.map(({ value, label }) => ({ value, name: label }))}
          value={{
            value: perimeter,
            name: i18next.t("KM PLACEHOLDER", { value: perimeter }),
          }}
          onChange={({ value }) => {
            value && setPerimeter(value);
          }}
        />
      </div>
    </FilterBlock>
  );
};

export default GeoFilter;
