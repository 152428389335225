import React from "react";
import i18next from "i18next";
import classNames from "classnames";
import InlineSvg from "../../visual-components/components/InlineSvg";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import useShare from "../../common/hooks/useShare";
import buildTitle from "../../common/helpers/buildTitle";
import { AugmentedDetailVehicle } from "../types/vehicle";

type Props = {
  vehicle: AugmentedDetailVehicle;
  // todo: this is a temporary quick fix to prevent having a wrong share link on whitelabel, see: https://3.basecamp.com/3101128/buckets/25078318/todos/5687994862
  hideShare?: boolean;
  comparisonList?: {
    inList: boolean;
    toggle: (title: string, vehicleId: string) => void;
  };
  favoriteList?: {
    inList: boolean;
    toggle: (title: string, vehicleId: string) => void;
  };
};

const DetailHeroMetaNav: React.FC<Props> = ({ vehicle, hideShare = false, comparisonList, favoriteList }) => {
  const { inList: isInComparisonList, toggle: toggleComparisonListVehicle } = comparisonList || {};
  const { inList: isInFavoritesList, toggle: toggleFavoriteListVehicle } = favoriteList || {};

  // todo: this link is not correct for whitelabel, see: https://3.basecamp.com/3101128/buckets/25078318/todos/5687994862
  const { vehicleDetailPath } = usePathBuilder();
  const { openShare, shareOverlay } = useShare({
    text: buildTitle(vehicle.title),
    url: vehicleDetailPath({ slug: vehicle.slug, id: vehicle.vehicleId, absolute: true }),
  });

  return (
    <div className="detail-vdp__share">
      <ul className="inline-meta-nav-list">
        {toggleFavoriteListVehicle ? (
          <li>
            <button
              aria-label={i18next.t("FAVORITE")}
              onClick={() => {
                toggleFavoriteListVehicle(vehicle.title, `${vehicle.vehicleId}`);
              }}
            >
              <InlineSvg className={classNames({ liked: isInFavoritesList })} type="heart" />
            </button>
          </li>
        ) : null}

        {toggleComparisonListVehicle ? (
          <li>
            <button
              aria-label={i18next.t("COMPARE")}
              onClick={() => {
                toggleComparisonListVehicle(vehicle.title, `${vehicle.vehicleId}`);
              }}
            >
              <InlineSvg className={classNames({ selected: isInComparisonList })} type="compare" />
            </button>
          </li>
        ) : null}

        {hideShare ? null : (
          <li>
            <button aria-label={i18next.t("SHARE")} onClick={openShare}>
              <InlineSvg type="share" />
            </button>
            {shareOverlay}
          </li>
        )}
        <li className="tablet-hide">
          <button aria-label={i18next.t("PRINT")} onClick={window.print}>
            <InlineSvg type="printer" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default DetailHeroMetaNav;
