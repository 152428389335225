import React, { forwardRef, useId } from "react";
import classNames from "classnames";
import i18next from "i18next";
import FormErrorMessage from "../FormErrorMessage";

// use either label or textLabel
type Props = {
  value: string;
  // label for default cases
  label?: string;
  // textLabel passing down a ReactNode
  textLabel?: React.ReactNode;
  checked?: boolean;
  subChecked?: boolean;
  optional?: boolean;
  error?: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "id">;

const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ value, optional, label = "", checked, subChecked = false, error, textLabel, ...rest }, ref) => {
    const id: string = useId();

    return (
      <>
        <div className="checkbox-wrap">
          <input
            ref={ref}
            checked={checked}
            className="checkbox"
            id={id}
            name={label}
            type="checkbox"
            value={value}
            {...rest}
          />
          <label
            htmlFor={id}
            className={classNames("label label--checkbox checkbox-text-fix", {
              "sub-checked": subChecked,
            })}
          >
            {label}
            {optional ? (
              <>
                {" "}
                <span className="label__meta">({i18next.t("OPTIONAL")})</span>
              </>
            ) : null}
          </label>
          {textLabel}
        </div>
        <FormErrorMessage error={error} />
      </>
    );
  },
);

export default Checkbox;
