import i18next, { TFunction } from "i18next";
import { Warranty, WarrantyType } from "../types/warranty";
import Locale from "../../common/constants/Locale";
import { Formatters } from "../../common/hooks/useFormatter";
import { logger } from "../../common/scripts/logger";

const TRANSLATION_MAP: Record<WarrantyType, (t: TFunction) => string> = {
  [WarrantyType.FROM_TAKEOVER]: t => t("WARRANTY FROM TAKEOVER"),
  [WarrantyType.FROM_REGISTRATION]: t => t("WARRANTY FROM REGISTRATION"),
  [WarrantyType.FROM_DATE]: t => t("WARRANTY FROM DATE"),
};

function getWarrantyText(warranty: Warranty, locale: Locale, { formatDate, formatNumber }: Formatters): string {
  const translationFn = TRANSLATION_MAP[warranty.typeId];
  if (!translationFn) {
    logger.warn(`unknown warranty type ${(warranty as any).type}`);
    return "";
  }
  const label = translationFn(i18next.t);
  const text = warranty.text[locale];
  const warrantyItems = [
    warranty.date ? formatDate(new Date(warranty.date)) : null,
    warranty.months ? i18next.t("MONTHS PLACEHOLDER", { value: warranty.months }) : null,
    warranty.maxKm ? i18next.t("KM PLACEHOLDER", { value: formatNumber(warranty.maxKm) }) : null,
  ];
  return `${label} ${warrantyItems.filter(item => item !== null).join(` ${i18next.t("OR")} `)}${
    text ? `: ${text}` : ""
  }`;
}

export default getWarrantyText;
