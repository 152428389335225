import React from "react";

type Props = {
  caption: string;
  videoId: string;
  controls?: boolean;
};

const YoutubePlayer: React.FC<Props> = ({ caption, videoId, controls = false }) => {
  return (
    <iframe
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      className="iframe-video"
      frameBorder="0"
      src={`https://www.youtube.com/embed/${videoId}?controls=${controls ? 1 : 0}`}
      title={caption}
    />
  );
};

export default YoutubePlayer;
