import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { selectAccessToken, selectRefreshToken } from "../authSelectors";
import { HttpStatus } from "../../modules/common/constants/HttpStatus";
import { RootState } from "../../core/state/init/store";
import { getNewAccessToken } from "./refreshAccessTokenSingleton";

const baseAuthenticatedFetch = ({
  baseUrl,
  url,
  method,
  headers = {},
  body,
  accessToken,
}: {
  baseUrl: string;
  url: string;
  method: string;
  headers?: Record<string, string>;
  body?: Record<string, number | string>;
  accessToken: string;
}) => {
  const authorizationHeader = `Bearer ${accessToken}`;
  return fetch(baseUrl + url, {
    method,
    headers: {
      ...headers,
      Authorization: authorizationHeader,
    },
    body: body ? JSON.stringify(body) : undefined,
  });
};

export enum AUTHENTICATION_ERRORS {
  NO_SESSION_TOKEN = "NO_SESSION_TOKEN",
  REFRESH_TOKEN_EXPIRED = "REFRESH_TOKEN_EXPIRED",
}

const baseAuthenticationQuery =
  ({
    baseUrl,
    fetchFn = baseAuthenticatedFetch,
    refreshTokenFetchFn,
  }: {
    baseUrl: string;
    fetchFn?: typeof baseAuthenticatedFetch;
    refreshTokenFetchFn?: typeof fetch;
  }): BaseQueryFn =>
  async ({ method, url, headers, body }, { getState, dispatch }) => {
    const accessToken = selectAccessToken(getState() as RootState);
    const refreshToken = selectRefreshToken(getState() as RootState);

    if (!accessToken || !refreshToken) {
      return { error: { message: AUTHENTICATION_ERRORS.NO_SESSION_TOKEN } };
    }

    const fetch = (accessToken: string) => {
      return fetchFn({ baseUrl, url, method, accessToken, headers, body });
    };

    let response = await fetch(accessToken);

    if (response.status === HttpStatus.UNAUTHORIZED) {
      const newToken = await getNewAccessToken(refreshToken, dispatch, refreshTokenFetchFn);

      if (newToken === null) {
        return { error: { message: AUTHENTICATION_ERRORS.REFRESH_TOKEN_EXPIRED } };
      }

      response = await fetch(newToken);
    }

    try {
      const data = await response.json();

      if (response.ok) {
        return { data };
      } else {
        return { error: { data, status: response.status } };
      }
    } catch (e) {
      // response might not be json, in this case there is simply no data available
      if (response.ok) {
        return { data: null };
      } else {
        return { error: { data: true, status: response.status } };
      }
    }
  };

export default baseAuthenticationQuery;
