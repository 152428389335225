import React from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";
import Button from "../../visual-components/components/Button";
import VehiclesCarousel from "../../vehicle/components/VehiclesCarousel";
import AlgoliaVehicleSearchCarCard from "../../vehicle/components/AlgoliaVehicleSearchCarCard";
import { VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";

type Props = {
  postedQuery: string;
  vehicles: VehicleSearchResponseItem[];
  resetFilters: () => void;
};

const ZeroResultsVehicles: React.FC<Props> = ({ postedQuery, vehicles, resetFilters }) => {
  return (
    <section className="section-carousel">
      <h2 className="section-title section-carousel__title">
        <Trans i18nKey="SUITABLE ALTERNATIVES">
          <span className="title__line-1" />
          <span className="title__line-2" />
        </Trans>
      </h2>
      <div className="section-carousel__btn">
        <Button transparent onClick={resetFilters}>
          {i18next.t("SHOW ALL VEHICLES")}
        </Button>
      </div>
      <p className="suggestion-header__text">
        {i18next.t("NOT CONTAINS")}: <s>{postedQuery}</s>
      </p>
      <VehiclesCarousel title={i18next.t("SUITABLE ALTERNATIVES")} totalItems={vehicles.length}>
        {vehicles.map(vehicle => (
          <AlgoliaVehicleSearchCarCard
            key={vehicle.vehicleId}
            singleImage
            trackingReferrer={TrackingReferrer.SuitableAlternativesZeroResults}
            vehicle={vehicle}
          />
        ))}
      </VehiclesCarousel>
    </section>
  );
};
export default ZeroResultsVehicles;
