import { NotificationItem } from "../types";

let id = 0;

export const buildInfoNotification = ({
  icon = "info",
  autoDismissMs = 5000,
  text,
}: Partial<Pick<NotificationItem, "icon" | "autoDismissMs">> & Pick<NotificationItem, "text">): NotificationItem => ({
  id: `${++id}`,
  icon,
  text,
  autoDismissMs,
  explicitClose: false,
});

export const buildErrorNotification = ({
  icon = "close",
  autoDismissMs = 8000,
  text,
}: Partial<Pick<NotificationItem, "icon" | "autoDismissMs">> & Pick<NotificationItem, "text">): NotificationItem => ({
  id: `${++id}`,
  icon,
  text,
  autoDismissMs,
  explicitClose: false,
});
