import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";

export type NewsletterSubscribeRequest = {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
};

type NewsletterSubscribeResponse = {
  status: number;
} | null;

export const newsletterApi = createApi({
  reducerPath: "newsletterApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    subscribe: builder.mutation<NewsletterSubscribeResponse, NewsletterSubscribeRequest>({
      query: data => ({
        method: "POST",
        url: "/newsletter/subscribe",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
      transformResponse: (_, meta) => {
        if (meta?.response?.status) {
          return { status: meta.response.status };
        }
        return null;
      },
    }),
  }),
});

export const { useSubscribeMutation } = newsletterApi;
