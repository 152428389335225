import React, { RefObject, useEffect, useState } from "react";
import ReactDOM from "react-dom";

const DIALOG_ROOT_SELECTOR = "#dialog-root";

type Props = {
  children: React.ReactNode;
  containerRef?: RefObject<HTMLDivElement>;
};

const DialogPortal: React.FC<Props> = ({ children, containerRef }) => {
  const [container] = useState(() => {
    return document.createElement("div");
  });

  useEffect(() => {
    let root: Element | undefined | null = containerRef?.current;
    if (!root) {
      root = document.querySelector(DIALOG_ROOT_SELECTOR);
    }
    root?.appendChild(container);
    return () => {
      root?.removeChild(container);
    };
  }, [containerRef, container]);

  return ReactDOM.createPortal(children, container);
};

export default DialogPortal;
