const askForCurrentPosition = async () => {
  const position = await new Promise<GeolocationPosition>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject),
  ).catch(() => {
    throw new Error("Location could not be fetched: Permission not granted.");
  });

  return {
    lat: `${position.coords.latitude}`,
    lng: `${position.coords.longitude}`,
  };
};

export const getCurrentPosition = async (args?: { askIfNotGranted?: boolean }) => {
  if (args?.askIfNotGranted) {
    return askForCurrentPosition();
  }

  if (!navigator.permissions) {
    throw new Error("Location could not be fetched: Could not validate permission.");
  }

  const permission = await navigator.permissions.query({
    name: "geolocation",
  });

  if (permission.state !== "granted") {
    throw new Error("Location could not be fetched: Permission not granted and not asking.");
  }

  return askForCurrentPosition();
};
