import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";

export type VehicleEstimationRequest = {
  vin: string;
  firstRegistration: string;
  mileage: number;
  email: string;
};

export type VehicleEstimationRequestResponse = {
  redirectUrl: string;
  orderId: string;
};

export type VehicleEstimationReceiveRequest = {
  requestId: string;
};

export type VehicleEstimationReceiveResponse = {
  estimation: number;
  vin: string;
  firstRegistration: string;
  mileage: number;
};

export const vehicleProfileApi = createApi({
  reducerPath: "vehicleProfileApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    vehicleEstimationRequest: builder.mutation<VehicleEstimationRequestResponse, VehicleEstimationRequest>({
      query: data => ({
        method: "POST",
        url: "/vehicle-profile/estimation/request",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
    vehicleEstimationReceive: builder.query<VehicleEstimationReceiveResponse, VehicleEstimationReceiveRequest>({
      query: data => ({
        url: `/vehicle-profile/estimation/receive/${data.requestId}`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
      }),
    }),
  }),
});

export const { useVehicleEstimationRequestMutation, useVehicleEstimationReceiveQuery } = vehicleProfileApi;
