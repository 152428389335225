import React from "react";
import i18next from "i18next";
import classNames from "classnames";

type Props = {
  label: string;
  optional?: boolean;
  htmlFor?: string;
  className?: string;
};

const Label: React.FC<Props> = ({ label, optional = false, htmlFor, className }) => {
  return (
    <label className={classNames("label", className)} htmlFor={htmlFor}>
      {label}
      {optional ? (
        <>
          {" "}
          <span className="label__meta">({i18next.t("OPTIONAL")})</span>
        </>
      ) : null}
    </label>
  );
};

export default Label;
