import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import React from "react";
import { AugmentedDetailVehicle } from "../types/vehicle";
import useGeneralInquiryLink from "../../../processes/general-inquiry/hooks/useGeneralInquiryLink";
import useTestDriveLink from "../../../processes/test-drive/hooks/useTestDriveLink";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { addToCartTrack } from "../../analytics/scripts/addToCartTrack/addToCartTrack";
import { getDealerPhone } from "../../dealer/services/getDealerPhone";
import { Icon } from "../../common/types/Icons";
import { outboundClickTrack } from "../../analytics/scripts/navigationTrack/outboundClickTrack";
import { OutboundClickType } from "../../analytics/constants/OutboundClickType";

type CtaItem = {
  icon: Icon;
  label: string;
  onClick?: (ev: React.MouseEvent<HTMLSpanElement>) => void;
};

const useVehicleCtaItems = (vehicle: AugmentedDetailVehicle) => {
  const buildGeneralInquiryLink = useGeneralInquiryLink();
  const buildTestDriveLink = useTestDriveLink();
  const navigate = useNavigate();
  const aaConversionTrackEvent = useAaConversionTrackEvent();

  const inquireItem: CtaItem = {
    icon: "mail",
    label: i18next.t("INQUIRE"),
    onClick: () => {
      const { to, state } = buildGeneralInquiryLink({
        slug: vehicle.dealer.slug,
        id: vehicle.dealer.insideId,
        vehicleId: vehicle.vehicleId,
      });
      navigate(to, { state });
      aaConversionTrackEvent(vehicle.dealer.insideId, ConversionEventName.startVehicleLead);
    },
  };

  const testDriveItem: CtaItem = {
    icon: "calendar",
    label: i18next.t("BOOK TEST DRIVE"),
    onClick: () => {
      const { to, state } = buildTestDriveLink({ slug: vehicle.slug, id: vehicle.vehicleId });
      navigate(to, { state });
      addToCartTrack(vehicle);
      aaConversionTrackEvent(vehicle.vehicleId, ConversionEventName.startVehicleLead);
    },
  };

  const dealerPhone = getDealerPhone({
    phone: vehicle.dealer.phone,
    calltrackingPhone: vehicle.dealer.calltrackingPhone,
  });

  const dealerPhoneItem: CtaItem | null = dealerPhone
    ? {
        icon: "phone",
        label: dealerPhone,
        onClick: (ev: React.MouseEvent<HTMLSpanElement>) => {
          window.location.href = `tel:${dealerPhone}`;
          aaConversionTrackEvent(vehicle.dealer.insideId, ConversionEventName.startVehicleLead);
          outboundClickTrack({
            ev,
            dealer: vehicle.dealer,
            outboundLinkType: OutboundClickType.phone,
            vehicle: {
              vehicleId: vehicle.vehicleId,
              model: vehicle.model,
              brand: vehicle.brand,
              title: vehicle?.title,
              ps: vehicle.ps,
              priceB2cGross: vehicle.priceB2cGross,
            },
            calltrackingSubscriberId: vehicle.dealer.calltrackingSubscriberId,
          });
        },
      }
    : null;

  return { inquireItem, testDriveItem, dealerPhoneItem };
};

export default useVehicleCtaItems;
