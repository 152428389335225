import { VEHICLE_FACETS } from "../../algolia/services/vehicleFacetKeys";
import isNil from "../../common/helpers/isNil";
import { convertYearToUnixTimestamp, getYearFromDateString } from "../../common/helpers/date";
import { BaseVehicle } from "../types/vehicle";
import {
  buildConjunctiveFilter,
  buildFilter,
  buildLargerOrEqualFilter,
  buildNegatedNumericalFilter,
  buildNumericalFilter,
  buildOptionalFilter,
  buildSmallerOrEqualFilter,
} from "../../algolia/services/buildFilter";

const PS_PERCENTAGE_DIFFERENCE = 20;
const YEAR_FILTER_DIFFERENCE = 3;

type FilterBuilderDefinitions = Partial<Record<VEHICLE_FACETS, FilterBuilder<string> | FilterBuilder<number>>>;

type FilterBuilderBuildMethod<T extends number | string> = (facet: string, value: T) => string;

export type FilterBuilder<T extends number | string> = {
  data: (vehicle: BaseVehicle) => T | null | undefined;
  buildFilter: FilterBuilderBuildMethod<T>;
};

export const MANDATORY_FILTERS: FilterBuilderDefinitions = {
  [VEHICLE_FACETS.FIRST_REGISTRATION]: {
    data: vehicle => vehicle.firstRegistration,
    buildFilter: (facet: string, dateString: string) => {
      const year = getYearFromDateString(dateString);
      const max = convertYearToUnixTimestamp(year + YEAR_FILTER_DIFFERENCE);
      const min = convertYearToUnixTimestamp(year - YEAR_FILTER_DIFFERENCE);
      return buildConjunctiveFilter([buildLargerOrEqualFilter(facet, min), buildSmallerOrEqualFilter(facet, max)]);
    },
  },
  [VEHICLE_FACETS.PS]: {
    data: vehicle => vehicle.ps,
    buildFilter: (facet: string, ps: number) =>
      buildConjunctiveFilter([
        buildLargerOrEqualFilter(facet, Math.round((ps / 100) * (100 - PS_PERCENTAGE_DIFFERENCE))),
        buildSmallerOrEqualFilter(facet, Math.round((ps / 100) * (100 + PS_PERCENTAGE_DIFFERENCE))),
      ]),
  },
  [VEHICLE_FACETS.BODY_TYPE_GROUP]: {
    data: vehicle => vehicle.bodyType?.group?.name,
    buildFilter: (facet: string, name: string) => buildFilter(facet, name),
  },
  [VEHICLE_FACETS.TRANSMISSION_GROUP]: {
    data: vehicle => vehicle.transmission?.group?.name,
    buildFilter: (facet: string, name: string) => buildFilter(facet, name),
  },
  [VEHICLE_FACETS.FUEL_TYPE_GROUP]: {
    data: vehicle => vehicle.fuelType?.group?.name,
    buildFilter: (facet: string, name: string) => buildFilter(facet, name),
  },
};

export const OPTIONAL_FILTERS: FilterBuilderDefinitions = {
  [VEHICLE_FACETS.DRIVE_TYPE]: {
    data: vehicle => vehicle.driveType?.name,
    buildFilter: (facet: string, name: string) => buildOptionalFilter(facet, name),
  },
  [VEHICLE_FACETS.DEALER_ID]: {
    data: vehicle => vehicle.dealer?.insideId,
    buildFilter: (facet: string, id: number) => buildOptionalFilter(facet, `${id}`),
  },
};

const getFilters = (vehicle: BaseVehicle, filters: FilterBuilderDefinitions): string[] => {
  const applicableFilters = Object.entries(filters).filter(([, { data }]) => {
    return !isNil(data(vehicle));
  });
  return applicableFilters.map(([facetKey, { data, buildFilter }]) => {
    // typecast as we're sure that types will match due to previous filter
    const builder = buildFilter as FilterBuilderBuildMethod<any>;
    return builder(facetKey, data(vehicle));
  });
};

/**
 * only a single dealer restriction is possible in combination with the other filters
 * Therefore it's a singular number instead of a list of numbers
 * @param dealerId
 */
const getDealerRestrictionFilter = (dealerId: number | null): string | null => {
  if (isNil(dealerId)) {
    return null;
  }
  return buildNumericalFilter(VEHICLE_FACETS.DEALER_ID, dealerId);
};

export const getAppliedFilters = (
  vehicle: BaseVehicle,
  dealerRestrictions: number | null,
): {
  filters: string;
  optionalFilters: string[];
} => {
  const mandatoryFilters = getFilters(vehicle, MANDATORY_FILTERS);
  const optionalFilters = getFilters(vehicle, OPTIONAL_FILTERS);
  const dealerFilter = getDealerRestrictionFilter(dealerRestrictions);

  return {
    filters: buildConjunctiveFilter([
      ...mandatoryFilters,
      ...(dealerFilter ? [dealerFilter] : []),
      buildNegatedNumericalFilter(VEHICLE_FACETS.VEHICLE_ID, vehicle.vehicleId),
    ]),
    optionalFilters: optionalFilters,
  };
};
