import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type SortValueEvent = {
  search_interactions: {
    filter_action: "select";
    filter_section: "SERP";
    sort_value: string;
  };
};
export type SortValueTrackingEvent = SortValueEvent & BaseTrackingEvent;

export const currentSortValueTrack = (sortValue: string | undefined) => {
  dataLayer([
    {
      event: "gaEvent",
      event_name: "search_interactions",
      search_interactions: {
        filter_action: "select",
        filter_section: "SERP",
        sort_value: sortValue,
      },
    },
  ]);
};
