import { useLocation, useNavigate } from "react-router-dom";
import { HistoryState } from "../constants/State";
import usePathBuilder from "./usePathBuilder";

function useBack(): { text: string | undefined; hasBack: boolean; goBack: () => void } {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { homePath } = usePathBuilder();

  const anyState = state as undefined | Record<string, string | boolean>;

  const linkLabel = anyState?.[HistoryState.CONTEXT_DEPENDENT_LINK_LABEL];
  const backText = typeof linkLabel === "string" ? linkLabel : undefined;

  // it's possible to have a back link without a label
  const hasBackText = !!backText;

  const hasBack = !!anyState?.[HistoryState.CONTEXT_DEPENDENT_LINK];

  const goBack = () => {
    if (hasBack) {
      navigate(-1);
    } else {
      navigate(homePath());
    }
  };

  return {
    text: backText,
    hasBack: hasBackText || hasBack,
    goBack,
  };
}
export default useBack;
