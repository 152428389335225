// Function is explicitly desired here
// eslint-disable-next-line @typescript-eslint/ban-types
function debounce<T extends Function>(callback: T, delay = 300): T {
  let timeout: number | null = null;
  const fun = (...args: any) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => callback(...args), delay) as unknown as number;
  };
  return fun as unknown as T;
}

export default debounce;
