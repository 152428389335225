import React from "react";
import i18next from "i18next";
import useMatchmakerLink from "../../hooks/useMatchmakerLink";
import ContextLink from "../../../../modules/common/components/ContextLink";

type Props = {
  isInitialized: boolean;
  answeredNumberOfQuestions: number;
  totalNumberOfQuestions: number;
};
const StatusSmallVisual: React.FC<Props> = ({ isInitialized, answeredNumberOfQuestions, totalNumberOfQuestions }) => {
  const matchmakerLink = useMatchmakerLink();
  return (
    <ContextLink className="link-icon link-icon__matchmaker" tabIndex={0} {...matchmakerLink}>
      <span aria-label="icon" className="ifont ifont--sparks_stars" role="img" />
      <span className="link-icon__text">{i18next.t("MATCHMAKER")}</span>
      <span className="link-icon__label">
        {isInitialized && answeredNumberOfQuestions > 0
          ? `${answeredNumberOfQuestions}/${totalNumberOfQuestions}`
          : i18next.t("NEW")}
      </span>
    </ContextLink>
  );
};
export default StatusSmallVisual;
