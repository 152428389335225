import { FilterTypeDefinition } from "../types/filterTypes";
import { MultiSelectFilterDef } from "../types/filterDefinitionTypes";
import { arrayLengthExtractor } from "../../helpers/arrayLengthExtractor";
import { searchQueryExtractor } from "../../helpers/searchQueryExtractor";
import { appliedFacetValuesExtractor, facetKeyExtractor } from "../../helpers/facetKeyExtractor";
import getNameForReferenceId from "../../helpers/getNameForReferenceId";

export const buildMultiSelectQueryParam = (param: string, value: string): Record<string, string> => {
  return {
    [param]: value,
  };
};

export const multiSelectFilterType: FilterTypeDefinition<MultiSelectFilterDef, string[]> = {
  getConfigurationCount: arrayLengthExtractor,
  paramType: "stringArray",
  getConfigurationLabels: (val, formatters, filter, aggregatedData) =>
    val.map(tag => getNameForReferenceId(tag, filter, aggregatedData)),
  getSearchQueries: searchQueryExtractor,
  facetMapping: {
    getKeys: facetKeyExtractor,
    getAppliedValues: appliedFacetValuesExtractor,
  },
};
