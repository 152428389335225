import React from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { QualityChecked } from "../../hygraph/vo";
import Image, {
  IMAGE_FILL_COLORS,
  IMAGE_FIT_TYPES,
  IMAGE_TRANSFORM_TYPES,
} from "../../visual-components/components/Image";
import useFormatter from "../../common/hooks/useFormatter";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { notNil } from "../../common/helpers/isNil";

type Props = {
  qualityChecked: QualityChecked;
};

const QualityCheckedTabDetail: React.FC<Props> = ({ qualityChecked }) => {
  const { formatNumber } = useFormatter();
  const {
    name,
    logoImageUrl,
    description,
    checkpoints,
    coverageMonths,
    coverageMileage,
    coverageText,
    refundPolicyRegistrationDays,
    refundPolicyMaxMileage,
    brand,
  } = qualityChecked;

  const { brandPath } = usePathBuilder();
  const qualityCheckedPath = brand?.slug ? brandPath({ slug: brand.slug }) : "#";

  return (
    <>
      <div className="col-span-7">
        <h4 className="media-object__title">{name}</h4>
        <p>{description}</p>
        <dl className="dl">
          <div>
            <dt className="dl__dt">{i18next.t("QUALITY CHECKED WARRANTY TITLE")}:</dt>
            <dd className="dl__dd">
              {notNil(coverageMonths) ? i18next.t("MONTHS PLACEHOLDER", { value: coverageMonths }) : null}
              {notNil(coverageMonths) && notNil(coverageMileage) ? " / " : null}
              {notNil(coverageMileage) ? i18next.t("KM PLACEHOLDER", { value: formatNumber(coverageMileage) }) : null}
            </dd>
          </div>
          <div>
            <dt className="dl__dt">{i18next.t("QUALITY CHECKED REFUND TITLE")}:</dt>
            <dd className="dl__dd">
              {notNil(refundPolicyRegistrationDays) || notNil(refundPolicyMaxMileage)
                ? `${i18next.t("YES")}, `
                : i18next.t("NO")}
              {notNil(refundPolicyRegistrationDays)
                ? i18next.t("DAYS PLACEHOLDER", { value: refundPolicyRegistrationDays })
                : null}
              {notNil(refundPolicyRegistrationDays) && notNil(refundPolicyMaxMileage) ? " / " : null}
              {notNil(refundPolicyMaxMileage) ? i18next.t("KM PLACEHOLDER", { value: refundPolicyMaxMileage }) : null}
            </dd>
          </div>
          <div>
            <dt className="dl__dt">{i18next.t("QUALITY CHECKED PRICE TITLE")}:</dt>
            <dd className="dl__dd">{i18next.t("QUALITY CHECKED PRICE")}</dd>
          </div>
          {notNil(coverageText) ? (
            <div>
              <dt className="dl__dt">{i18next.t("QUALITY CHECKED COVERAGE TITLE")}:</dt>
              <dd className="dl__dd">{coverageText}</dd>
            </div>
          ) : null}
          {notNil(checkpoints) ? (
            <div>
              <dt className="dl__dt">{i18next.t("QUALITY CHECKED CHECKPOINTS TITLE")}:</dt>
              <dd className="dl__dd">
                {checkpoints > 0
                  ? `${i18next.t("YES")}, ${i18next.t("QUALITY CHECKED CHECKPOINTS", { value: checkpoints })}`
                  : i18next.t("NO")}
              </dd>
            </div>
          ) : null}
        </dl>
      </div>
      <div className="col-span-5">
        <Link className="media-object__teaser" to={qualityCheckedPath}>
          {notNil(logoImageUrl) ? (
            <div className="media-object-teaser__logo-wrap">
              <Image
                alt={name}
                baseSrc={logoImageUrl}
                fillColor={IMAGE_FILL_COLORS.transparent}
                fit={IMAGE_FIT_TYPES.fill}
                imageSizes={IMAGE_TRANSFORM_TYPES.brandLogo}
              />
            </div>
          ) : null}
          <div className="media-object-teaser__text-wrap">
            {brand ? <div className="media-object-teaser__brand">{brand.name}</div> : null}
            <p className="media-object-teaser__link-text">{name}</p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default QualityCheckedTabDetail;
