import React from "react";
import i18next from "i18next";
import { Trans } from "react-i18next";
import useRelatedVehicles from "../../../vehicle-detail/hooks/useRelatedVehicles";
import { BaseVehicle } from "../types/vehicle";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";
import AlgoliaVehicleSearchCarCard from "./AlgoliaVehicleSearchCarCard";
import VehiclesCarousel from "./VehiclesCarousel";

type Props = {
  vehicle: BaseVehicle;
  dealerRestriction?: number;
};

const RelatedVehicles: React.FC<Props> = ({ vehicle, dealerRestriction }) => {
  const similarVehicles = useRelatedVehicles(vehicle, dealerRestriction ?? null);

  if (!similarVehicles || similarVehicles.hits.length === 0) {
    return null;
  }

  return (
    <section className="section-carousel hidden-print">
      <h2 className="section-title section-carousel__title">
        <Trans i18nKey="SIMILAR VEHICLES">
          <span className="title__line-1" />
          <span className="title__line-2" />
        </Trans>
      </h2>
      <VehiclesCarousel
        resetScrollPositionTrigger={similarVehicles.params}
        title={`${i18next.t("SIMILAR VEHICLES")}: ${vehicle.brand} ${vehicle.model}`}
        totalItems={similarVehicles.hits.length}
      >
        {similarVehicles.hits.map(vehicle => (
          <AlgoliaVehicleSearchCarCard
            key={vehicle.vehicleId}
            singleImage
            trackingReferrer={TrackingReferrer.RelatedVehicles}
            vehicle={vehicle}
          />
        ))}
      </VehiclesCarousel>
    </section>
  );
};

export default RelatedVehicles;
