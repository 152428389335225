import React from "react";
import i18next from "i18next";
import useFormatter from "../../common/hooks/useFormatter";
import StarRating from "./StarRating";

type Props = {
  rating: number;
  reviewCount: number;
};

const GarageRating: React.FC<Props> = ({ rating, reviewCount }) => {
  const { formatNumber } = useFormatter();
  return (
    <div className="garage__star">
      <StarRating showCount rating={rating} />
      <div className="text-tertiary">
        {formatNumber(reviewCount)} {i18next.t("GOOGLE REVIEW", { count: reviewCount })}
      </div>
    </div>
  );
};

export default GarageRating;
