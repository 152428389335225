import { QueryParam } from "./getQueryStringFromParams";

export const normalizeQueryParam = (queryParam: QueryParam): string[] => {
  if (!queryParam) {
    return [];
  }

  if (!Array.isArray(queryParam)) {
    return [String(queryParam)];
  }

  return queryParam.map(value => String(value));
};
