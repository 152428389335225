import React, { MouseEventHandler } from "react";
import classNames from "classnames";
import { Icon } from "../../common/types/Icons";
import ContextLink from "../../common/components/ContextLink";

type IconButtonBaseProps = {
  icon: Icon;
  ariaLabel: string;
  toast?: number;
};

const TOAST_THRESHOLD = 9;

const VisualIconButton = ({ icon, ariaLabel, toast = 0 }: IconButtonBaseProps) => {
  return (
    <>
      <span className={classNames("ifont", `ifont--${icon}`)} />
      <span className="vshidden">{ariaLabel}</span>
      {toast > 0 ? <span className="toast">{toast > TOAST_THRESHOLD ? `${TOAST_THRESHOLD}+` : toast}</span> : null}
    </>
  );
};

const IconButton: React.FC<
  {
    type?: "button" | "submit";
    onClick: MouseEventHandler<HTMLButtonElement>;
    className?: string;
  } & IconButtonBaseProps
> = ({ onClick, className, type, ...rest }) => {
  return (
    <button className={classNames("icon-btn", className)} type={type} onClick={onClick}>
      <VisualIconButton {...rest} />
    </button>
  );
};

export const IconButtonLink: React.FC<
  {
    to: string;
    state?: Record<string, string | number | boolean>;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    className?: string;
  } & IconButtonBaseProps
> = ({ to, state, onClick, className, ...rest }) => {
  return (
    <ContextLink className={classNames("icon-btn", className)} state={state} to={to} onClick={onClick}>
      <VisualIconButton {...rest} />
    </ContextLink>
  );
};

export default IconButton;
