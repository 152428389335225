import { Employee, Maybe, SalesAdvisor } from "../../hygraph/vo";

export function getSalesAdvisor(
  salesAdvisors: SalesAdvisor[] = [],
  brandInsideId?: number | null,
  vehicleCondition?: string | null,
): Maybe<Employee> | undefined {
  const advisorsMatchingVehicleCondition = salesAdvisors.filter(
    advisor => advisor.vehicleCondition === vehicleCondition,
  );

  const firstPriority = advisorsMatchingVehicleCondition.find(advisor =>
    advisor.brands.find(brand => brand.insideId === brandInsideId),
  );
  if (firstPriority?.employee) {
    return firstPriority.employee;
  }

  const secondPriority = advisorsMatchingVehicleCondition.find(advisor => advisor.brands.length === 0);
  if (secondPriority?.employee) {
    return secondPriority.employee;
  }

  return undefined;
}
