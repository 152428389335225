import i18next from "i18next";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import useNotification from "../../notification/hooks/useNotification";
import { buildInfoNotification } from "../../notification/helpers/notificationFactory";
import useEvent from "../../common/hooks/useEvent";
import { comparisonClickTrack } from "../../analytics/scripts/productActionTrack/compareClickTrack";
import useAuth from "../../../auth/hooks/useAuth";
import {
  useAddToComparisonMutation,
  useDeleteFromComparisonMutation,
} from "../../../comparison/services/comparisonApi";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import useVehicleList from "./useVehicleList";

export const MAX_COMPARED_VEHICLES = 50;

function useVehicleComparisonList() {
  const {
    list,
    addVehicle: rawAddVehicle,
    addVehicles: rawAddVehicles,
    toggleVehicle: rawToggleVehicle,
    removeVehicle: rawRemoveVehicle,
    removeVehicles: rawRemoveVehicles,
    containsVehicle,
    clear: rawClear,
  } = useVehicleList(STORAGE_KEYS.COMPARISON_LIST);

  const authInfo = useAuth();
  const pushNotification = useNotification();

  const aaConversionTrackEvent = useAaConversionTrackEvent();

  const [deleteFromComparison] = useDeleteFromComparisonMutation();
  const [addToComparison] = useAddToComparisonMutation();

  const isListFull = list.length >= MAX_COMPARED_VEHICLES;
  const listFullNotification = buildInfoNotification({
    icon: "compare",
    text: i18next.t("REACHED COMPARISON LIST MAXIMUM", { value: MAX_COMPARED_VEHICLES }),
  });

  const getVehicleAddedNotification = (title: string) =>
    buildInfoNotification({
      icon: "compare",
      text: i18next.t("VEHICLE ADDED TO COMPARISON LIST", { value: title }),
    });
  const getVehicleRemovedNotification = (title: string) =>
    buildInfoNotification({
      icon: "compare",
      text: i18next.t("VEHICLE REMOVED FROM COMPARISON LIST", { value: title }),
    });
  const clearedVehiclesNotification = buildInfoNotification({
    icon: "compare",
    text: i18next.t("COMPARISON LIST CLEARED"),
  });

  const toggleVehicle = useEvent((title: string, vehicleId: string) => {
    const isVehicleBeingRemoved = containsVehicle(vehicleId);

    let notification;

    if (isVehicleBeingRemoved) {
      notification = getVehicleRemovedNotification(title);
      comparisonClickTrack(vehicleId, "Remove Comparison");

      if (authInfo) {
        deleteFromComparison({ ...authInfo, id: vehicleId });
      }
    } else {
      if (isListFull) {
        pushNotification(listFullNotification);
        return;
      }

      notification = getVehicleAddedNotification(title);
      comparisonClickTrack(vehicleId, "Add Comparison");
      aaConversionTrackEvent(parseInt(vehicleId, 10), ConversionEventName.addVehicleToList);
      if (authInfo) {
        addToComparison({ ...authInfo, body: [vehicleId] });
      }
    }

    rawToggleVehicle(vehicleId);
    pushNotification(notification);
  });

  const addVehicle = useEvent((vehicleId: string) => {
    if (isListFull) {
      pushNotification(listFullNotification);
      return;
    }

    if (authInfo) {
      addToComparison({ ...authInfo, body: [vehicleId] });
    }
    rawAddVehicle(vehicleId);
  });

  const addVehicles = useEvent((vehicleIds: string[]) => {
    if (list.length + vehicleIds.length > MAX_COMPARED_VEHICLES) {
      pushNotification(listFullNotification);
      return;
    }

    if (authInfo) {
      addToComparison({ ...authInfo, body: vehicleIds });
    }
    rawAddVehicles(vehicleIds);
  });

  const removeVehicle = useEvent((vehicleId: string) => {
    if (authInfo) {
      deleteFromComparison({ ...authInfo, id: vehicleId });
    }
    rawRemoveVehicle(vehicleId);
  });

  const removeVehicles = useEvent((vehicleIds: string[]) => {
    if (authInfo) {
      vehicleIds.forEach(vehicleId => {
        deleteFromComparison({ ...authInfo, id: vehicleId });
      });
    }
    rawRemoveVehicles(vehicleIds);
  });

  const clear = useEvent(() => {
    if (authInfo) {
      list.forEach(vehicleId => {
        deleteFromComparison({ ...authInfo, id: vehicleId });
      });
    }
    rawClear();
    pushNotification(clearedVehiclesNotification);
  });

  return {
    list,
    addVehicle,
    addVehicles,
    /**
     * Dispatch snackbar
     */
    toggleVehicle,
    removeVehicle,
    removeVehicles,
    containsVehicle,
    clear,
  };
}

export default useVehicleComparisonList;
