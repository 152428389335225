import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type InteractionType = "Add Comparison" | "Remove Comparison";

type TrackEvent = {
  comparison_interactions: {
    vehicle_id: string;
    comparison_interaction: InteractionType;
  };
};
export type ComparisonClickTrackingEvent = BaseTrackingEvent & TrackEvent;

export const comparisonClickTrack = (vehicleId: string, interactionType: InteractionType) => {
  const eventObject: ComparisonClickTrackingEvent = {
    event: "gaEvent",
    event_name: "comparison_interactions",
    comparison_interactions: {
      vehicle_id: vehicleId,
      comparison_interaction: interactionType,
    },
  };
  dataLayer([eventObject]);
};
