import React, { forwardRef, useId } from "react";
import Label from "../Label";

type Props = {
  label: string;
  value: string;
  name: string | undefined;
  optional?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Radio = forwardRef<HTMLInputElement, Props>(({ label, name, optional, ...rest }, ref) => {
  const id = useId();
  return (
    <div className="radio-wrap">
      <input ref={ref} className="radio" id={id} name={name} type="radio" {...rest} />
      <Label className="label--radio" htmlFor={id} label={label} optional={optional} />
    </div>
  );
});

Radio.displayName = "Radio";

export default Radio;
