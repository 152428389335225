import { gql } from "graphql-request";
import { hygraphApi } from "../../hygraph/services/hygraphApi";
import { FilterReferenceData } from "./reference-data-aggregator/types";
import { getAggregatedFilterData } from "./reference-data-aggregator";

type RawBasicFilterItem = {
  id: number;
  name: string;
  basicFilterValue: boolean;
  nameNormalized: string;
};
type RawMinimalFilterItem = {
  id: number;
  name: string;
  nameNormalized: string;
};

export type RawFilterItem = RawBasicFilterItem | RawMinimalFilterItem;

export const isRawBasicFilterItem = (item: RawFilterItem): item is RawBasicFilterItem => {
  return (item as RawBasicFilterItem).basicFilterValue !== undefined;
};

export type RawBrandFilterItem = {
  id: number;
  name: string;
  nameNormalized: string;
  models: { id: number; name: string; nameNormalized: string }[];
};

export type RawGroupFilterItem = {
  id: number;
  name: string;
  nameNormalized: string;
  basicFilterValue: boolean;
  children: RawMinimalFilterItem[];
};

export type RawFilterReferenceData = {
  vehicleConditions: RawFilterItem[];
  vehicleClasses: RawFilterItem[];
  vehicleTypes: RawFilterItem[];
  driveTypes: RawFilterItem[];
  brands: RawBrandFilterItem[];
  bodyTypeGroups: RawGroupFilterItem[];
  fuelTypeGroups: RawGroupFilterItem[];
  transmissionGroups: RawGroupFilterItem[];
  optionFacets: RawFilterItem[];
  colorNorms: RawFilterItem[];
  upholsterys: RawFilterItem[];
  emissionClasses: RawFilterItem[];
};

export const vehicleFilterReferenceDataApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    filterReferenceData: builder.query<FilterReferenceData, void>({
      providesTags: ["languageDependent"],
      query: () => {
        const orderByName = "orderBy:name_ASC";
        return {
          document: gql`
            query Filters {
              vehicleConditions(${orderByName}) {
                id: insideId
                name
                basicFilterValue
                nameNormalized
              }
              vehicleClasses(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              vehicleTypes(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              driveTypes(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              brands(first: 1000, ${orderByName}) {
                id: insideId
                name
                nameNormalized
                models(first: 1000, ${orderByName}) {
                  id: insideId
                  name
                  nameNormalized
                }
              }
              bodyTypeGroups(${orderByName}) {
                id: groupId
                name
                nameNormalized
                basicFilterValue
                children: bodyTypes(${orderByName}) {
                  id: insideId
                  name
                  nameNormalized
                }
              }
              fuelTypeGroups(${orderByName}) {
                id: groupId
                name
                nameNormalized
                basicFilterValue
                children: fuelTypes(${orderByName}) {
                  id: insideId
                  name
                  nameNormalized
                }
              }
              transmissionGroups(${orderByName}) {
                id: groupId
                name
                nameNormalized
                basicFilterValue
              }
              optionFacets(first: 1000, ${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              colorNorms(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              upholsterys(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
              emissionClasses(${orderByName}) {
                id: insideId
                name
                nameNormalized
              }
            }
          `,
        };
      },
      transformResponse: (response: RawFilterReferenceData): FilterReferenceData => {
        return getAggregatedFilterData(response);
      },
    }),
  }),
});

export const { useFilterReferenceDataQuery, useLazyFilterReferenceDataQuery } = vehicleFilterReferenceDataApi;
