import { createApi } from "@reduxjs/toolkit/query/react";
import { AUTH_BASE_URL } from "../constants";
import { SALUTATIONS } from "../../processes/core/referenceData/salutation";
import baseAuthenticationQuery from "./baseAuthenticationQuery";

export type UserResponse = {
  firstName: string;
  lastName: string;
  email: string;
  data?: {
    salutation?: (typeof SALUTATIONS)[number];
  };
};

export const fusionAuthApi = createApi({
  reducerPath: "fusionAuthApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: AUTH_BASE_URL }),
  tagTypes: ["session"],
  endpoints: builder => ({
    user: builder.query<UserResponse, void>({
      query: () => ({ method: "GET", url: "/api/user" }),
      providesTags: ["session"],
      transformResponse: (response: { user: UserResponse }) => response.user,
    }),
  }),
});

export const useUserQuery = fusionAuthApi.useUserQuery;
