import { ConversionEventName } from "../constants/ConversionEventName";
import useAlgoliaAnalytics, { buildConvertedObjectIDsEvent } from "./useAlgoliaAnalytics";
import { useAaTrackArguments } from "./useAaTrackArguments";

export const useAaConversionTrackEvent = () => {
  const trackEvent = useAlgoliaAnalytics();
  const { language, userId } = useAaTrackArguments();
  return (objectId: number, eventName: ConversionEventName) => {
    trackEvent(buildConvertedObjectIDsEvent(language, objectId, userId, eventName));
  };
};
