import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { Page } from "../../../../router/constants/Page";

type AdImpression = {
  event_name: "adimpression";
  adimpression: AdData;
};

export enum AdType {
  Hero = "hero",
  Tile = "tile",
}

type AdData = {
  ad_campaign: string;
  ad_name: string;
  ad_type: AdType;
  ad_position?: string;
};

export type AdImpressionTrackingEvent = BaseTrackingEvent & AdImpression;

export const adImpressionTrack = ({
  pageType,
  adCampaign,
  adName,
  adType,
  adPosition,
}: {
  pageType: Page | null;
  adCampaign: string;
  adName: string;
  adType: AdType;
  adPosition?: number;
}) => {
  dataLayer([
    {
      event: "adEvent",
      event_name: "adimpression",
      page_type: pageType,
      adimpression: {
        ad_campaign: adCampaign,
        ad_name: adName,
        ad_type: adType,
        ad_position: `${adPosition}`,
      },
    } satisfies AdImpressionTrackingEvent,
  ]);
  return true;
};
