import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { Page } from "../../../../router/constants/Page";

type AdView = {
  event_name: "adview";
  adview: AdData;
};

export enum AdType {
  Hero = "hero",
  Tile = "tile",
}

type AdData = {
  ad_campaign: string;
  ad_name: string;
  ad_type: AdType;
  ad_position?: string;
};

export type AdViewTrackingEvent = BaseTrackingEvent & AdView;

export const adViewTrack = ({
  pageType,
  adCampaign,
  adName,
  adType,
  adPosition,
}: {
  pageType: Page | null;
  adCampaign: string;
  adName: string;
  adType: AdType;
  adPosition?: number;
}) => {
  dataLayer([
    {
      event: "adEvent",
      event_name: "adview",
      page_type: pageType,
      adview: {
        ad_campaign: adCampaign,
        ad_name: adName,
        ad_type: adType,
        ad_position: `${adPosition}`,
      },
    } satisfies AdViewTrackingEvent,
  ]);
  return true;
};
