import React from "react";
import classNames from "classnames";
import i18next from "i18next";
import { ButtonAnchor } from "../../visual-components/components/Button";

type Props = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  totalItems: number;
  getPath: (p: number) => string;
};

const SHOWN_PAGE_LINKS = 4;
const LOOKAHEAD_ITEMS = 1;

const Pagination: React.FC<Props> = ({ currentPage, setCurrentPage, pageSize, totalItems, getPath }) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  let paginationStart = Math.max(currentPage - LOOKAHEAD_ITEMS, 0);
  if (paginationStart + SHOWN_PAGE_LINKS >= totalPages) {
    paginationStart = Math.max(totalPages - SHOWN_PAGE_LINKS, 0);
  }

  const activePages = Math.min(totalPages, SHOWN_PAGE_LINKS);
  const paginationItems = Array.from(Array(activePages), (_, i) => paginationStart + i);
  const hasHiddenPagesBetweenLastAndShownPageLinks = paginationStart + SHOWN_PAGE_LINKS + 1 <= totalPages - 1;
  const isLastPageHidden = paginationStart + SHOWN_PAGE_LINKS < totalPages;

  const hasHiddenPagesBetweenFirstAndShownPageLinks = paginationStart > 1;
  const isFirstPageHidden = paginationStart !== 0;

  const previousPage = currentPage - 1;
  const lastPage = totalPages - 1;
  const nextPage = currentPage + 1;
  const inactiveBack = currentPage === 0;
  const inactiveForward = currentPage === totalPages - 1;

  if (totalPages < 2) {
    return null;
  }

  return (
    <nav className="nav-pagination">
      <div className="pagination">
        <ButtonAnchor
          className={classNames("pagination__btn pagination__btn--prev", { inactive: inactiveBack })}
          href={inactiveBack ? undefined : getPath(previousPage)}
          icon="back"
          onClick={e => {
            setCurrentPage(previousPage);
            e.preventDefault();
          }}
        >
          {i18next.t("BACK")}
        </ButtonAnchor>
        <ul className="pagination__pages">
          {isFirstPageHidden ? (
            <li>
              <a
                className="pagination__link"
                href={getPath(0)}
                onClick={e => {
                  setCurrentPage(0);
                  e.preventDefault();
                }}
              >
                1
              </a>
            </li>
          ) : null}
          {hasHiddenPagesBetweenFirstAndShownPageLinks ? (
            <li>
              <span className="pagination__text">…</span>
            </li>
          ) : null}
          {paginationItems.map(item => (
            <li key={item}>
              <a
                className={classNames("pagination__link", { active: item === currentPage })}
                href={getPath(item)}
                onClick={e => {
                  setCurrentPage(item);
                  e.preventDefault();
                }}
              >
                {item + 1}
              </a>
            </li>
          ))}
          {hasHiddenPagesBetweenLastAndShownPageLinks ? (
            <li>
              <span className="pagination__text">…</span>
            </li>
          ) : null}
          {isLastPageHidden ? (
            <li>
              <a
                className="pagination__link"
                href={getPath(lastPage)}
                onClick={e => {
                  setCurrentPage(lastPage);
                  e.preventDefault();
                }}
              >
                {totalPages}
              </a>
            </li>
          ) : null}
        </ul>

        <ButtonAnchor
          href={inactiveForward ? undefined : getPath(nextPage)}
          icon="next"
          className={classNames("pagination__btn pagination__btn--next", {
            inactive: inactiveForward,
          })}
          onClick={e => {
            setCurrentPage(nextPage);
            e.preventDefault();
          }}
        >
          {i18next.t("FURTHER")}
        </ButtonAnchor>
      </div>
    </nav>
  );
};

export default Pagination;
