import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";

export type LeasingQuoteRequest = {
  vehicleId: number;
  downPayment: number;
  kmPerYear: number;
  totalRuntime: number;
  homeDeliveryFee?: number;
};

export type LeasingQuoteResponse = {
  priceB2CGross: number;
  leasingInterest: number;
  monthlyLeasingRate: number;
  residualValue: number;
  legalDetailLines: string[];
  isFullFlow: boolean;
};

export type HomeDeliveryRequest = {
  dealerId: number;
  streetName: string;
  streetNumber: string;
  zipCode: string;
  city: string;
};

export type HomeDeliveryResponse = {
  priceGross: number;
};

export const eflexApi = createApi({
  reducerPath: "eflexApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    getLeasingQuote: builder.query<LeasingQuoteResponse, LeasingQuoteRequest>({
      query: data => ({
        method: "POST",
        url: "/eflex/leasing/quote",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
      keepUnusedDataFor: 0,
    }),
    getHomeDeliveryQuote: builder.query<HomeDeliveryResponse, HomeDeliveryRequest>({
      query: data => ({
        method: "POST",
        url: "/eflex/home-delivery/quote",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
  }),
});

export const { useGetLeasingQuoteQuery, useGetHomeDeliveryQuoteQuery } = eflexApi;
