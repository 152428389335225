import { brandModelFilterType } from "./filter-types/brandModelFilterType";
import { rangeFilterType } from "./filter-types/rangeFilterType";
import { geoFilterType } from "./filter-types/geoFilterType";
import { groupSelectFilterType } from "./filter-types/groupSelectFilterType";
import { FilterType, FilterTypeDefinition } from "./types/filterTypes";
import { tagFilterType } from "./filter-types/tagFilterType";
import { multiSelectFilterType } from "./filter-types/multiSelectFilterType";

export const FILTER_TYPE_CONFIGURATION_MAP: Record<
  FilterType,
  FilterTypeDefinition<any> | FilterTypeDefinition<any, string[]>
> = {
  brandModel: brandModelFilterType,
  range: rangeFilterType,
  geo: geoFilterType,
  groupSelect: groupSelectFilterType,
  multiSelect: multiSelectFilterType,
  tag: tagFilterType,
};
