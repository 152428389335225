import React from "react";
import classNames from "classnames";
import i18next from "i18next";
import { ScrollPosition } from "../hooks/useCarousel";
import { sliderTrack } from "../../analytics/scripts/navigationTrack/sliderTrack";

type Props = {
  scrollPosition: ScrollPosition;
  totalItems: number;
  next: () => void;
  prev: () => void;
  // carousel buttons behave the same but use different class names
  prevClassName: string;
  nextClassName: string;
  trackingTitle: string;
};

const CarouselArrows: React.FC<Props> = ({
  scrollPosition,
  totalItems,
  prev,
  next,
  prevClassName,
  nextClassName,
  trackingTitle,
}) => {
  const hasMultipleItems = totalItems > 1;
  if (!hasMultipleItems) {
    return null;
  }
  return (
    <>
      {scrollPosition !== "no-scroll" ? (
        <button
          aria-label={i18next.t("PREVIOUS")}
          className={classNames(prevClassName, { inactive: scrollPosition === "start" })}
          type="button"
          onClick={() => {
            prev();
            sliderTrack("prev", trackingTitle ?? "");
          }}
        >
          <span className="ifont ifont--angle_left " />
        </button>
      ) : null}
      {scrollPosition !== "no-scroll" ? (
        <button
          aria-label={i18next.t("NEXT")}
          className={classNames(nextClassName, { inactive: scrollPosition === "end" })}
          type="button"
          onClick={() => {
            next();
            sliderTrack("next", trackingTitle ?? "");
          }}
        >
          <span className="ifont ifont--angle_right " />
        </button>
      ) : null}
    </>
  );
};

export default CarouselArrows;
