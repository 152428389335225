import { TFunction } from "i18next";
import Locale from "../../common/constants/Locale";

export enum WeekdayLiterals {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export const WEEKDAY_INDEX_LITERAL_MAP: Record<number, WeekdayLiterals> = {
  0: WeekdayLiterals.SUNDAY,
  1: WeekdayLiterals.MONDAY,
  2: WeekdayLiterals.TUESDAY,
  3: WeekdayLiterals.WEDNESDAY,
  4: WeekdayLiterals.THURSDAY,
  5: WeekdayLiterals.FRIDAY,
  6: WeekdayLiterals.SATURDAY,
};

export const WEEKDAYS_SORT_ORDER: WeekdayLiterals[] = [
  WeekdayLiterals.MONDAY,
  WeekdayLiterals.TUESDAY,
  WeekdayLiterals.WEDNESDAY,
  WeekdayLiterals.THURSDAY,
  WeekdayLiterals.FRIDAY,
  WeekdayLiterals.SATURDAY,
  WeekdayLiterals.SUNDAY,
];

export const WEEKDAYS_SHORT_LABEL_MAP: Record<WeekdayLiterals, (t: TFunction) => string> = {
  [WeekdayLiterals.MONDAY]: t => t("MONDAY SHORT"),
  [WeekdayLiterals.TUESDAY]: t => t("TUESDAY SHORT"),
  [WeekdayLiterals.WEDNESDAY]: t => t("WEDNESDAY SHORT"),
  [WeekdayLiterals.THURSDAY]: t => t("THURSDAY SHORT"),
  [WeekdayLiterals.FRIDAY]: t => t("FRIDAY SHORT"),
  [WeekdayLiterals.SATURDAY]: t => t("SATURDAY SHORT"),
  [WeekdayLiterals.SUNDAY]: t => t("SUNDAY SHORT"),
};

export type OpeningHoursRecord = {
  openDay: WeekdayLiterals;
  closeDay: WeekdayLiterals;
  openTime: string;
  closeTime: string;
};

export type OpeningHours = {
  service: OpeningHoursRecord[];
  warehouse: OpeningHoursRecord[];
  sales: OpeningHoursRecord[];
};

export type WorkActivityType = "service" | "warehouse" | "sales";

export type WorkingHoursException = {
  start: string | null;
  end: string | null;
  date: number;
  name: string;
  translations: Record<Locale, string> | null;
  workActivityType: WorkActivityType;
};
