import { createApi } from "@reduxjs/toolkit/query/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { SearchResponse } from "@algolia/client-search";
import useLanguage from "../../../router/hooks/useLanguage";
import Locale from "../../common/constants/Locale";
import { buildNumericalFilter } from "./buildFilter";
import { algoliaBaseQuery } from "./baseApi";
import { AlgoliaRequestOptions, buildIndexName, client, Indices } from "./search";
import { VehicleSearchResponse, VehicleSearchResponseItem } from "./vehicleSearchApi";

const ALGOLIA_PA_PROMOTION_ID_KEY = "promotionList.paPromotionComponentId";
const SHOWN_VEHICLES = 20;

export type GetPromotionVehicleResponse = SearchResponse<VehicleSearchResponseItem>;

export function searchVehicleIndex(
  query = "",
  { language, ...requestOptions }: AlgoliaRequestOptions,
): Promise<SearchResponse<VehicleSearchResponse>> {
  return client.initIndex(buildIndexName(Indices.vehicle, language)).search(query, requestOptions);
}

export const promotionVehicleApi = createApi({
  reducerPath: "promotionVehicleApi",
  baseQuery: algoliaBaseQuery(searchVehicleIndex),
  endpoints: builder => ({
    getPromotionVehiclesMeta: builder.query<
      GetPromotionVehicleResponse,
      { paPromotionComponentId: number; language: Locale }
    >({
      query: ({ paPromotionComponentId, language }) => ({
        options: {
          hitsPerPage: SHOWN_VEHICLES,
          filters: buildNumericalFilter(ALGOLIA_PA_PROMOTION_ID_KEY, paPromotionComponentId),
          facets: ["*"],
          language,
        },
      }),
    }),
  }),
});

const useRawPromotionVehiclesMetaQuery = promotionVehicleApi.useGetPromotionVehiclesMetaQuery;

export const useGetPromotionVehiclesMetaQuery = (request: { paPromotionComponentId: number } | typeof skipToken) => {
  const language = useLanguage();
  return useRawPromotionVehiclesMetaQuery(
    request === skipToken ? skipToken : { paPromotionComponentId: request.paPromotionComponentId, language },
  );
};
