const createObserverManager = () => {
  let observer: IntersectionObserver | null = null;
  const elements: Map<Element, () => void> = new Map();
  const observedElements = new Set();

  const initObserver = (threshold: number) => {
    if (!observer) {
      observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const isFirstTimeIntersecting = entry.isIntersecting && !observedElements.has(entry.target);
            if (isFirstTimeIntersecting) {
              observedElements.add(entry.target);
              const trackingCallback = elements.get(entry.target);

              if (trackingCallback) {
                trackingCallback();
              }
            }
          });
        },
        { threshold },
      );
    }
  };

  const observe = (element: HTMLElement, trackingCallback: () => void, threshold: number) => {
    if (!element) return;

    initObserver(threshold);

    const isNewElement = !elements.has(element);
    if (isNewElement) {
      elements.set(element, trackingCallback);
      observer?.observe(element);
    }
  };

  const disconnect = (element: HTMLElement) => {
    if (!element) return;

    if (elements.has(element)) {
      observer?.unobserve(element);
      elements.delete(element);
      observedElements.delete(element);
    }
  };

  return { observe, disconnect };
};

const observerManager = createObserverManager();

export default observerManager;
