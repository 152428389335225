import { useState } from "react";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import { GeoLocation } from "../components/GeoPopup";

export const useGeoPopup = () => {
  const [storedLocation, setStoredLocation] = useLocalStorage<GeoLocation | null>(STORAGE_KEYS.LOCATION, null);

  const [geoPopupLocation, setGeoPopupLocation] = useState(storedLocation);
  const [showGeoPopup, setShowGeoPopup] = useState(false);

  return {
    geoLocation: geoPopupLocation,
    setGeoLocation: ({
      geoLocation,
      rememberLocation,
    }: {
      geoLocation: GeoLocation | null;
      rememberLocation?: boolean;
    }) => {
      const newLocation = geoLocation
        ? { lat: geoLocation.lat, lng: geoLocation.lng, displayName: geoLocation.displayName }
        : null;
      setStoredLocation(rememberLocation ? newLocation : null);
      setGeoPopupLocation(newLocation);
    },
    setShowGeoPopup,
    showGeoPopup,
  };
};
