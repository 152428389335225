import React from "react";
import { Helmet } from "react-helmet-async";
import usePrerenderReady from "../hooks/usePrerenderReady";

type Props = {
  location: string;
};

const RedirectMetaInfo: React.FC<Props> = ({ location }) => {
  usePrerenderReady();
  return (
    <Helmet>
      <meta content="301" name="prerender-status-code" />
      <meta content={`Location: ${location}`} name="prerender-header" />
    </Helmet>
  );
};

export default RedirectMetaInfo;
