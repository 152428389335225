import React from "react";

const FullPageLoader: React.FC = () => {
  return (
    <div className="full-page-loader-container">
      <div className="full-page-loader">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default FullPageLoader;
