import {
  SaveSearchBody,
  SaveSearchData,
  useSaveSearchMutation,
} from "../../../save-search/services/authenticatedSaveSearchApi";
import { useSaveAnonymousSearchMutation } from "../../../save-search/services/saveSearchApi";
import useAuth from "../../../auth/hooks/useAuth";
import useMutationErrorNotification from "../../notification/hooks/useMutationErrorNotification";

function useSaveSearch(hasUser: boolean) {
  const authInfo = useAuth();
  const [saveAnonymousSearch, anonymousResult] = useSaveAnonymousSearchMutation();
  const [saveSearch, result] = useSaveSearchMutation();

  const activeResult = hasUser ? result : anonymousResult;
  useMutationErrorNotification(activeResult);

  return {
    saveSearch: (body: SaveSearchData["body"]) => {
      if (authInfo) {
        saveSearch({ userId: authInfo.userId, body });
      }
    },
    saveAnonymousSearch: (req: SaveSearchBody & { email: string }) => {
      saveAnonymousSearch(req);
    },
    result: activeResult,
  };
}

export default useSaveSearch;
