import isNil from "../../common/helpers/isNil";

export enum TrackingReferrer {
  InterestingVehicles = "interestingVehicles",
  MatchingVehicles = "matchingVehicles",
  RelatedVehicles = "relatedVehicles",
  NearbyVehicles = "nearbyVehicles",
  SuitableAlternatives = "suitableAlternatives",
  SuitableAlternativesZeroResults = "suitableAlternativesZeroResults",
  MatchingVehiclesZeroResults = "matchingVehiclesZeroResults",
  MatchingVehiclesMatchmaker = "matchingVehiclesMatchmaker",
  SuitableVehiclesCms = "suitableVehiclesCms",
  ModelVehicles = "modelVehicles",
  LastViewed = "lastViewed",
  DealerVehicles = "dealerVehicles",
  BrandVehicles = "brandVehicles",
  PromotionVehicles = "promotionVehicles",
  None = "none",
}

export const lookupItemListName = (itemListName: TrackingReferrer | undefined) => {
  return isNil(itemListName) ? undefined : TRACKING_REFERRER_GOOGLE_TAG_MANAGER_ORIGIN_ID[itemListName];
};

export const TRACKING_REFERRER_GOOGLE_TAG_MANAGER_ORIGIN_ID: Record<TrackingReferrer, string> = {
  [TrackingReferrer.InterestingVehicles]: "Interesting Vehicles",
  [TrackingReferrer.MatchingVehicles]: "Matching Vehicles",
  [TrackingReferrer.RelatedVehicles]: "Related Vehicles",
  [TrackingReferrer.NearbyVehicles]: "Nearby Vehicles",
  [TrackingReferrer.SuitableAlternatives]: "Suitable Alternatives",
  [TrackingReferrer.SuitableAlternativesZeroResults]: "Suitable Alternatives Zero Results",
  [TrackingReferrer.MatchingVehiclesZeroResults]: "Matching Vehicles Zero Results",
  [TrackingReferrer.MatchingVehiclesMatchmaker]: "Matching Vehicles Matchmaker",
  [TrackingReferrer.SuitableVehiclesCms]: "Suitable Vehicles CMS",
  [TrackingReferrer.ModelVehicles]: "Model Vehicles",
  [TrackingReferrer.LastViewed]: "Last Viewed",
  [TrackingReferrer.DealerVehicles]: "Dealer Vehicles",
  [TrackingReferrer.BrandVehicles]: "Brand Vehicles",
  [TrackingReferrer.PromotionVehicles]: "Promotion Vehicles",
  [TrackingReferrer.None]: "none",
} as const;
