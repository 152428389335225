import { createApi } from "@reduxjs/toolkit/query/react";
import baseAuthenticationQuery from "../../auth/services/baseAuthenticationQuery";
import { AuthInfo } from "../../auth/types";
import { BACKEND_API_ENDPOINT } from "../../modules/common/constants/configuration";

export type ComparisonEntry = {
  vehicleId: number;
};

export const comparisonApi = createApi({
  reducerPath: "comparisonApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  tagTypes: ["comparisonList", "session"],
  endpoints: builder => ({
    getAllCompared: builder.query<ComparisonEntry[], AuthInfo>({
      providesTags: ["comparisonList", "session"],
      query: ({ userId }) => ({
        method: "GET",
        url: `/user/${userId}/vehicle-comparison`,
      }),
      transformResponse: (response: { data: ComparisonEntry[] }) => response.data,
    }),
    addToComparison: builder.mutation<ComparisonEntry[], AuthInfo & { body: string[] }>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/vehicle-comparison`,
        body: body.map(vehicleId => ({ vehicleId: parseInt(vehicleId) })),
      }),
      transformResponse: (response: { data: ComparisonEntry[] }) => response.data,
    }),
    deleteFromComparison: builder.mutation<void, AuthInfo & { id: string }>({
      query: ({ userId, id }) => ({
        method: "DELETE",
        url: `/user/${userId}/vehicle-comparison/${id}`,
      }),
    }),
  }),
});

export const { useGetAllComparedQuery, useAddToComparisonMutation, useDeleteFromComparisonMutation } = comparisonApi;
