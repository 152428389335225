import React from "react";
import i18next from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "../../../modules/visual-components/components/Popup";
import PopupContent from "../../../modules/visual-components/components/PopupContent";
import carmarketBadge from "../../../template/public/assets/img/layout/carmarket-badge.svg";
import { ButtonAnchor } from "../../../modules/visual-components/components/Button";
import { AugmentedDetailVehicle } from "../../../modules/vehicle/types/vehicle";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import warnSwitchNeverCase from "../../../modules/common/helpers/warnSwitchNeverCase";
import { setUtmParams, UtmCampaignType } from "../../core/services/setUtmParams";

/**
 * Bounce off indicates that processes shall bounce onto external system (carmarket.ch).
 */
export const InBounceOffContext = React.createContext(false);

type Props = {
  vehicle: AugmentedDetailVehicle;
  utmSource?: string;
};

const BOUNCE_HASH_ROUTE_PREFIX = "bounce-";

// NOTE: bounce offs must start with "bounce-" prefix (see BOUNCE_HASH_ROUTE_PREFIX)
export enum BounceOffType {
  TESTDRIVE = "bounce-testdrive",
  LEASING = "bounce-leasing",
  PURCHASE = "bounce-purchase",
  INQUIRY = "bounce-inquiry",
}

const BounceOffPopup: React.FC<Props> = ({ vehicle, utmSource }) => {
  const location = useLocation();
  const open = location.hash.startsWith(`#${BOUNCE_HASH_ROUTE_PREFIX}`);
  const navigate = useNavigate();
  const dismiss = () => {
    // clear hash
    navigate("#");
  };
  const { testDrivePath, leaseVehiclePath, vehiclePurchasePath, generalInquiryPath } = usePathBuilder();

  const bounceOffType = location.hash.replace("#", "") as BounceOffType;

  let link = "";

  if (open) {
    switch (bounceOffType) {
      case BounceOffType.TESTDRIVE:
        link = testDrivePath({
          slug: vehicle.slug!,
          id: `${vehicle.vehicleId}`,
          absolute: true,
        });
        break;
      case BounceOffType.LEASING:
        link = leaseVehiclePath({
          slug: vehicle.slug!,
          id: `${vehicle.vehicleId}`,
          absolute: true,
        });
        break;
      case BounceOffType.PURCHASE:
        link = vehiclePurchasePath({
          slug: vehicle.slug!,
          id: `${vehicle.vehicleId}`,
          absolute: true,
        });
        break;
      case BounceOffType.INQUIRY:
        link = generalInquiryPath({
          slug: vehicle.dealer.slug!,
          id: vehicle.dealer.insideId,
          vehicleId: vehicle.vehicleId,
          absolute: true,
        });
        break;
      default:
        warnSwitchNeverCase("Unknown bounceOffType encountered in BounceOffPopup: ", bounceOffType);
    }
  }

  if (link) {
    link = setUtmParams(link, {
      source: utmSource,
      campaign: UtmCampaignType.Whitelabel,
    });
  }

  return (
    <Popup gradient keyboardDismissable close={dismiss} open={open}>
      <PopupContent
        body={i18next.t("WHITELABEL BOUNCE OFF POPUP BODY")}
        headerIcon={{ svg: carmarketBadge }}
        title={i18next.t("WHITELABEL BOUNCE OFF POPUP TITLE")}
        footer={
          <div className="btn-row btn-row--gap btn-row--reverse right">
            <button
              className="link"
              onClick={() => {
                dismiss();
              }}
            >
              {i18next.t("CANCEL")}
            </button>
            <ButtonAnchor href={link} target="_blank">
              {i18next.t("CONTINUE TO CARMARKET")}
            </ButtonAnchor>
          </div>
        }
      />
    </Popup>
  );
};

export default BounceOffPopup;
