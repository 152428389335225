import { RawGroupFilterItem, isRawBasicFilterItem } from "../vehicleFilterReferenceDataApi";
import { AggregatedFilterItem, AggregatedGroupFilterItem, GroupReferenceData } from "./types";

export const aggregatedGroupFilterItemMapper = (list: RawGroupFilterItem[]): GroupReferenceData => {
  const childMap = list.reduce<Record<string, AggregatedFilterItem>>((agg, group) => {
    group.children.forEach(child => {
      agg[`${child.id}`] = {
        value: `${child.id}`,
        name: child.name,
        isBasicFilterValue: isRawBasicFilterItem(child) ? child.basicFilterValue : true,
        nameNormalized: child.nameNormalized,
      };
    });
    return agg;
  }, {});
  const entries = list.map(
    (group): AggregatedGroupFilterItem => ({
      value: `${group.id}`,
      name: group.name,
      nameNormalized: group.nameNormalized,
      isBasicFilterValue: group.basicFilterValue,
      children: group.children.map(item => childMap[`${item.id}`]),
    }),
  );
  return {
    list: entries,
    map: Object.fromEntries(entries.map(entry => [entry.value, entry])),
    childMap,
    mapByName: Object.fromEntries(entries.map(entry => [entry.name, entry])),
  };
};
