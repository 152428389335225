import { createContext, useContext } from "react";
import { Page } from "../constants/Page";

export const CurrentPageContext = createContext<Page | null>(null);

function useCurrentPage() {
  return useContext(CurrentPageContext);
}

export default useCurrentPage;
