import React from "react";
import classNames from "classnames";
import i18next from "i18next";
import { Link } from "react-router-dom";
import useFormatter from "../../common/hooks/useFormatter";
import { getBaseTechnicalItems } from "../helpers/technicalDataHelpers";
import { smoothAnchorScrollById } from "../../common/helpers/dom";
import { DetailVehicle } from "../types/vehicle";
import useLanguage from "../../../router/hooks/useLanguage";
import { useSetActiveTab } from "../../visual-components/components/Tabs";
import { DEFAULT_CURRENCY } from "../../common/constants/Currency";
import { ALL_DETAILS_ANCHOR_ID, HOME_DELIVERY_TAB_INDEX, QUALITY_CHECKED_TAB_INDEX } from "./VehicleDetailTabs";
import Promotions from "./Promotions";

type Props = {
  vehicle: DetailVehicle;
};

const SHOWN_BASE_ITEMS = 5;

const BaseTechnicalData: React.FC<Props> = ({ vehicle }) => {
  const formatters = useFormatter();
  const language = useLanguage();
  const setActiveTab = useSetActiveTab();
  const hasPromotions = (vehicle.promotionList?.promotions?.length ?? 0) > 0;

  const baseTechnicalItems = getBaseTechnicalItems(vehicle, SHOWN_BASE_ITEMS);
  // note: hasLinks will include more links in the future but should be hidden if there are no links
  const hasLinks = vehicle.dealer?.homeDeliveryAvailable || vehicle.qualityChecked;

  return (
    <div className="detail-vdp-footer">
      <div className="detail-vdp-footer-wrap">
        {hasPromotions ? (
          <Promotions currency={vehicle.currency ?? DEFAULT_CURRENCY} promotions={vehicle.promotionList!.promotions!} />
        ) : null}

        {hasLinks ? (
          <ul className="detail-vdp__links-wrap">
            {vehicle.qualityChecked ? (
              <li>
                <Link
                  className="link-icon"
                  to={`#${ALL_DETAILS_ANCHOR_ID}`}
                  onClick={e => {
                    smoothAnchorScrollById(ALL_DETAILS_ANCHOR_ID, e);
                    setActiveTab(QUALITY_CHECKED_TAB_INDEX);
                  }}
                >
                  <span className="ifont ifont--quality_checked" />
                  <span className="link-icon__text">{i18next.t("QUALITY CHECKED")}</span>
                </Link>
              </li>
            ) : null}
            {vehicle.dealer?.homeDeliveryAvailable ? (
              <li className="hidden-print">
                <Link
                  className="link-icon"
                  to={`#${ALL_DETAILS_ANCHOR_ID}`}
                  onClick={e => {
                    smoothAnchorScrollById(ALL_DETAILS_ANCHOR_ID, e);
                    setActiveTab(HOME_DELIVERY_TAB_INDEX);
                  }}
                >
                  <span className="ifont ifont--home_delivery" />
                  <span className="link-icon__text">{i18next.t("HOME DELIVERY")}</span>
                </Link>
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
      <ul className="detail-vdp__summary">
        {baseTechnicalItems.map(({ label, data, icon, format }) => (
          <li key={label(i18next.t)} className="detail-vpd-summary__item" title={label(i18next.t)}>
            <span className={classNames("ifont", icon)} />
            {format ? format(data(vehicle), formatters, vehicle, language) : data(vehicle)}
          </li>
        ))}
        <li className="hidden-print">
          <a
            className="detail-vpd-summary__item"
            href={`#${ALL_DETAILS_ANCHOR_ID}`}
            onClick={e => {
              smoothAnchorScrollById(ALL_DETAILS_ANCHOR_ID, e);
            }}
          >
            {i18next.t("ALL DETAILS")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BaseTechnicalData;
