import React from "react";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import AdCardWrap from "../../vehicle/components/AdCardWrap";
import useLanguage from "../../../router/hooks/useLanguage";
import { useUserId } from "../../analytics/hooks/useUserId";
import { SearchResultItems } from "../hooks/useSearchResults";
import { isAd } from "../helpers/searchResult";
import SearchResultCarCard from "./SearchResultCarCard";

type Props = {
  page: number;
  searchResults: SearchResultItems;
  vehicleData: VehicleSearchResponse;
};

const SearchResults: React.FC<Props> = ({ page, vehicleData, searchResults }) => {
  const language = useLanguage();
  const { userId } = useUserId(true);

  return (
    <div key={vehicleData?.queryID} className="card-wrap">
      {searchResults.map((item, i) => {
        return isAd(item) ? (
          <AdCardWrap key={`ad-${i}`} ad={item} adPosition={i + 1} animationIndex={i} />
        ) : (
          <SearchResultCarCard
            key={item.objectID}
            index={i}
            language={language}
            page={page}
            userId={userId}
            vehicle={item}
            vehicleData={vehicleData}
          />
        );
      })}
    </div>
  );
};

export default SearchResults;
