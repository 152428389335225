import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../modules/common/constants/configuration";
import { getAcceptLanguageHeader, getActionTokenAuthHeader } from "../../modules/common/api/headerBuilders";
import { SaveSearchBody } from "./authenticatedSaveSearchApi";

type AnonymousSaveSearchAction = {
  id: string;
  actionToken: string;
};

export const saveSearchApi = createApi({
  reducerPath: "saveSearchApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    saveAnonymousSearch: builder.mutation<void, SaveSearchBody & { email: string }>({
      query: body => ({
        method: "POST",
        url: "/saved-search",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: body,
      }),
    }),
    activateSubscription: builder.mutation<void, AnonymousSaveSearchAction>({
      query: ({ id, actionToken }) => ({
        method: "POST",
        url: `/saved-search/${id}/activate`,
        headers: {
          ...getActionTokenAuthHeader(actionToken),
        },
      }),
    }),
    deleteSubscription: builder.mutation<void, AnonymousSaveSearchAction>({
      query: ({ id, actionToken }) => ({
        method: "DELETE",
        url: `/saved-search/${id}`,
        headers: {
          ...getActionTokenAuthHeader(actionToken),
        },
      }),
    }),
    extendSubscription: builder.mutation<void, AnonymousSaveSearchAction>({
      query: ({ id, actionToken }) => ({
        method: "POST",
        url: `/saved-search/${id}/extend-subscription`,
        headers: {
          ...getActionTokenAuthHeader(actionToken),
        },
      }),
    }),
  }),
});

export const {
  useSaveAnonymousSearchMutation,
  useExtendSubscriptionMutation,
  useActivateSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = saveSearchApi;
