import {
  OpeningHours,
  OpeningHoursRecord,
  WEEKDAY_INDEX_LITERAL_MAP,
  WeekdayLiterals,
  WorkActivityType,
  WorkingHoursException,
} from "../types/openingHours";

export const getWeekdayLiteral = (date = new Date()) => {
  const day = date.getDay();
  return WEEKDAY_INDEX_LITERAL_MAP[day];
};

const padWithZero = (number: number) => (number < 10 ? `0${number}` : number);

export const getDateAsTimeString = (date: Date): string => {
  return [date.getHours(), date.getMinutes(), date.getSeconds()].map(n => padWithZero(n)).join(":");
};

export const getOpeningHoursForWeekday = (openingHours: OpeningHoursRecord[], weekday: WeekdayLiterals) => {
  return openingHours.filter(({ closeDay }) => closeDay === weekday);
};

export const getRelevantOpeningHours = (openingHours: OpeningHours) => {
  const hasSalesOpeningHours = openingHours?.sales?.length > 0;

  const openingHourRecords = hasSalesOpeningHours ? openingHours.sales : openingHours?.service;
  const workActivityType: WorkActivityType = hasSalesOpeningHours ? "sales" : "service";

  return { openingHourRecords, workActivityType };
};

/**
 * @return filtered and sorted working hours exceptions
 */
export const getActiveWorkingHourExceptions = (
  workingHourExceptions: WorkingHoursException[],
  workActivityType: WorkActivityType,
  date: Date,
): WorkingHoursException[] => {
  const dateAtMidnight = new Date(date);
  dateAtMidnight.setHours(0, 0, 0, 0);
  const timeAtMidnight = dateAtMidnight.getTime();

  const filteredActiveWorkingHourExceptions = workingHourExceptions.filter(
    exception => exception.workActivityType === workActivityType && exception.date === timeAtMidnight,
  );
  filteredActiveWorkingHourExceptions.sort((a, b) => {
    if (a.end === null) {
      return -1;
    }
    if (b.end === null) {
      return 1;
    }
    return a.end > b.end ? -1 : 1;
  });
  return filteredActiveWorkingHourExceptions;
};

export const hasOpeningHours = (openingHours: OpeningHours | null | undefined): openingHours is OpeningHours => {
  return (openingHours?.sales?.length ?? 0) > 0 || (openingHours?.service?.length ?? 0) > 0;
};
