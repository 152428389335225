import i18next from "i18next";
import { FilterTypeDefinition } from "../types/filterTypes";
import { GeoFilterDef } from "../types/filterDefinitionTypes";
import { GEO_FILTER_SEPARATOR } from "../../../geo/constants";

type GeoFilterLat = string;
type GeoFilterLng = string;
type GeoFilterPerimeter = string;
type GeoFilterDisplayName = string;
type GeoFilterTuple = [
  GeoFilterLat | undefined,
  GeoFilterLng | undefined,
  GeoFilterPerimeter | undefined,
  GeoFilterDisplayName | undefined,
];

type DefaultedGeoFilterTuple = [GeoFilterLat, GeoFilterLng, GeoFilterPerimeter, GeoFilterDisplayName];

export const DEFAULT_PERIMETER = "20";

export function getGeoFilterTuple(val: string | null): GeoFilterTuple {
  return (val?.split(GEO_FILTER_SEPARATOR) as GeoFilterTuple) ?? [];
}

export function getDefaultGeoFilterTuple(val: string | null): DefaultedGeoFilterTuple {
  const [lat, lng, perimeter, displayName] = getGeoFilterTuple(val);
  return [lat ?? "", lng ?? "", perimeter ?? DEFAULT_PERIMETER, displayName ?? ""];
}

export function getGeoFilterFormattedLabel(val: string | null) {
  const [, , perimeter, displayName] = getGeoFilterTuple(val);
  return i18next.t("LOCATION PERIMETER", {
    location: displayName,
    perimeter: i18next.t("KM PLACEHOLDER", { value: perimeter }),
  });
}

export const geoFilterType: FilterTypeDefinition<GeoFilterDef> = {
  getConfigurationCount: val => {
    const [lat, lng] = getGeoFilterTuple(val);
    return lat && lng ? 1 : 0;
  },
  paramType: "string",
  getConfigurationLabels: val => {
    return [getGeoFilterFormattedLabel(val)];
  },
  getSearchQueries: param => {
    const [lat, lng, perimeter] = getDefaultGeoFilterTuple(param);
    return {
      aroundLatLng: `${lat}, ${lng}`,
      aroundRadius: parseInt(perimeter) * 1000,
    };
  },
};
