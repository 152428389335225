import { StringParam, useQueryParam } from "use-query-params";
import { useEffect } from "react";
import { SearchParams } from "../../../router/constants/SearchParams";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import sessionStorageManager from "../../common/global/sessionStorageManager";

function useCampaignUpdater() {
  const [utmCampaign] = useQueryParam(SearchParams.utmCampaign, StringParam);

  useEffect(() => {
    // Update only if present to avoid overriding the value
    if (utmCampaign) {
      sessionStorageManager.setItem(STORAGE_KEYS.CAMPAIGN, { utmCampaign });
    }
  }, [utmCampaign]);
}

export default useCampaignUpdater;
