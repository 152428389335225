import { RawFilterItem, isRawBasicFilterItem } from "../vehicleFilterReferenceDataApi";
import { AggregatedFilterItem, FilterItemReferenceData } from "./types";

export const aggregatedFilterItemMapper = (list: RawFilterItem[]): FilterItemReferenceData => {
  const entries = list.map(
    (item): AggregatedFilterItem => ({
      value: `${item.id}`,
      name: item.name,
      isBasicFilterValue: isRawBasicFilterItem(item) ? item.basicFilterValue : true,
      nameNormalized: item.nameNormalized,
    }),
  );
  return {
    list: entries,
    map: Object.fromEntries(entries.map(entry => [entry.value, entry])),
    mapByName: Object.fromEntries(entries.map(entry => [entry.name, entry])),
  };
};
