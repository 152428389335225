import { FilterDefinition } from "../services/types/filterDefinitionTypes";
import { logger } from "../../common/scripts/logger";

export const getNormalizedFacetValue = (facetFilter: string): [string, string] => {
  const [facetKey, ...rest] = facetFilter.split(":");
  const normalizedLabel = rest.join(":");
  return [facetKey, normalizedLabel];
};

export const getFilterDefMap = (
  facetFilters: (string | string[])[],
  greppedFacets: Record<string, FilterDefinition>,
): Map<FilterDefinition, string[]> => {
  return facetFilters.flat().reduce((agg, facetFilter) => {
    const [facetKey] = getNormalizedFacetValue(facetFilter);
    const filterDef = greppedFacets[facetKey];
    if (filterDef) {
      const existingData = agg.get(filterDef) ?? [];
      agg.set(filterDef, [...existingData, facetFilter]);
    } else {
      logger.warn(`unknown facet key ${facetKey}`);
    }
    return agg;
  }, new Map<FilterDefinition, string[]>());
};
