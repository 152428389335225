import i18next, { TFunction } from "i18next";
import { Formatters } from "../../common/hooks/useFormatter";
import { DetailVehicle } from "../types/vehicle";

export type EnergyDataItem = {
  label: (t: TFunction) => string;
  // generate unique serializable key for data (used to check if all items have same data and whether there is actually data)
  // undefined or null will be handled as absence of data
  key: (vehicle: DetailVehicle) => undefined | null | string | number;
  render: (vehicle: DetailVehicle, formatters: Formatters) => string[];
};

const fuelConsumption: EnergyDataItem = {
  label: t => t("FUEL CONSUMPTION"),
  key: vehicle =>
    !!vehicle.consumptionComb || !!vehicle.consumptionCity || !!vehicle.consumptionCountry
      ? `${vehicle.consumptionComb}-${vehicle.consumptionCity}-${vehicle.consumptionCountry}`
      : undefined,
  render: (vehicle, { formatNumber }) => {
    const items: string[] = [];
    if (vehicle.consumptionComb) {
      items.push(
        `${i18next.t("N LITER FUEL CONSUMPTION PER 100 KM", {
          value: formatNumber(vehicle.consumptionComb),
        })} (${i18next.t("TOTAL")})`,
      );
    }
    if (vehicle.consumptionCity) {
      items.push(
        `${i18next.t("N LITER FUEL CONSUMPTION PER 100 KM", {
          value: formatNumber(vehicle.consumptionCity),
        })} (${i18next.t("CITY")})`,
      );
    }

    if (vehicle.consumptionCountry) {
      items.push(
        `${i18next.t("N LITER FUEL CONSUMPTION PER 100 KM", {
          value: formatNumber(vehicle.consumptionCountry),
        })} (${i18next.t("COUNTRY")})`,
      );
    }

    return items;
  },
};

const co2Emissions: EnergyDataItem = {
  label: t => t("CO2 EMISSIONS"),
  key: vehicle => vehicle.co2,
  render: (vehicle, { formatNumber }) => {
    return [
      `${i18next.t("N GRAM CO2 PER KM", {
        value: formatNumber(vehicle.co2!),
      })} (${i18next.t("COMBINED SHORT")})`,
    ];
  },
};

const pollutionClass: EnergyDataItem = {
  label: t => t("POLLUTION CLASS"),
  key: vehicle => vehicle.pollutionClass?.name,
  render: vehicle => {
    return [vehicle.pollutionClass!.name!];
  },
};

const emissionClass: EnergyDataItem = {
  label: t => t("EMISSION CLASS"),
  key: vehicle => vehicle.emissionClass?.name,
  render: vehicle => {
    return [vehicle.emissionClass!.name!];
  },
};

export const ENERGY_DATA_ITEMS = [fuelConsumption, co2Emissions, pollutionClass, emissionClass];
