import React from "react";
import useFormatter from "../../common/hooks/useFormatter";

type Props = {
  rating: number | null;
  showCount?: boolean;
};

const StarRating: React.FC<Props> = ({ rating, showCount = false }) => {
  const { formatNumber } = useFormatter();

  if (rating === null) {
    return <div className="star-wrap" />;
  }
  const roundedDisplayRating = Math.round(rating * 10) / 10;
  const roundedRating = (Math.round((rating * 10) / 5) / 10) * 5;
  const fullStars = Math.floor(roundedRating);
  const hasHalfStar = roundedRating - fullStars > 0.25 && roundedRating - fullStars < 0.75;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className="star-wrap">
      {showCount ? <span className="star__score">{formatNumber(roundedDisplayRating)}</span> : null}
      {Array.from(Array(fullStars), (_, i) => i).map(i => (
        <span key={i} className="star">
          <span className="ifont ifont--star" />
        </span>
      ))}
      {hasHalfStar ? (
        <span className="star">
          <span className="ifont ifont--star_half" />
        </span>
      ) : null}
      {Array.from(Array(emptyStars), (_, i) => i).map(i => (
        <span key={i} className="star">
          <span className="ifont ifont--star_empty" />
        </span>
      ))}
    </div>
  );
};

export default StarRating;
