import i18next from "i18next";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import useNotification from "../../notification/hooks/useNotification";
import { buildInfoNotification } from "../../notification/helpers/notificationFactory";
import useAuth from "../../../auth/hooks/useAuth";
import { useAddFavoritesMutation, useDeleteFavoriteMutation } from "../../../favorites/services/favoritesApi";
import useEvent from "../../common/hooks/useEvent";
import { favoriteClickTrack } from "../../analytics/scripts/productActionTrack/favoriteClickTrack";
import { ConversionEventName } from "../../algolia/constants/ConversionEventName";
import { useAaConversionTrackEvent } from "../../algolia/hooks/useAaConversionTrackEvent";
import useVehicleList from "./useVehicleList";

function useVehicleFavoritesList() {
  const {
    list,
    toggleVehicle: rawToggleVehicle,
    addVehicle: rawAddVehicle,
    addVehicles: rawAddVehicles,
    removeVehicles: rawRemoveVehicles,
    containsVehicle,
  } = useVehicleList(STORAGE_KEYS.FAVORITES_LIST);

  const authInfo = useAuth();

  const pushNotification = useNotification();

  const aaConversionTrackEvent = useAaConversionTrackEvent();

  const [deleteFavorite] = useDeleteFavoriteMutation();
  const [addFavorites] = useAddFavoritesMutation();

  const toggleVehicle = useEvent((title: string, vehicleId: string) => {
    const isVehicleBeingRemoved = containsVehicle(vehicleId);
    let notification;
    if (isVehicleBeingRemoved) {
      favoriteClickTrack(vehicleId, "Remove Favorite");
      notification = buildInfoNotification({
        icon: "heart",
        text: i18next.t("VEHICLE REMOVED FROM FAVORITES LIST", { value: title }),
      });
    } else {
      favoriteClickTrack(vehicleId, "Add Favorite");
      aaConversionTrackEvent(parseInt(vehicleId, 10), ConversionEventName.addVehicleToList);
      notification = buildInfoNotification({
        icon: "heart",
        text: i18next.t("VEHICLE ADDED TO FAVORITES LIST", { value: title }),
      });
    }
    pushNotification(notification);

    if (authInfo) {
      if (isVehicleBeingRemoved) {
        deleteFavorite({ ...authInfo, id: vehicleId });
      } else {
        addFavorites({ ...authInfo, body: [vehicleId] });
      }
    }
    rawToggleVehicle(vehicleId);
  });

  const addVehicle = useEvent((vehicleId: string) => {
    if (authInfo) {
      addFavorites({ ...authInfo, body: [vehicleId] });
    }
    rawAddVehicle(vehicleId);
  });
  const addVehicles = useEvent((vehicleIds: string[]) => {
    if (authInfo) {
      addFavorites({ ...authInfo, body: vehicleIds });
    }
    rawAddVehicles(vehicleIds);
  });
  const addVehiclesFromComparison = useEvent((vehicleIds: string[]) => {
    addVehicles(vehicleIds);
    pushNotification(
      buildInfoNotification({
        icon: "heart",
        text: i18next.t("VEHICLES ADDED TO FAVORITES LIST"),
      }),
    );
  });

  const removeVehicles = useEvent((vehicleIds: string[]) => {
    if (authInfo) {
      vehicleIds.forEach(vehicleId => {
        deleteFavorite({ ...authInfo, id: vehicleId });
      });
    }
    rawRemoveVehicles(vehicleIds);
  });

  return {
    list,
    toggleVehicle,
    addVehicle,
    addVehicles,
    addVehiclesFromComparison,
    removeVehicles,
    containsVehicle,
  };
}

export default useVehicleFavoritesList;
