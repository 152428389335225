import { MutableRefObject, useEffect, useRef } from "react";
import observerManager from "../scripts/observerManager";
import useEvent from "../../common/hooks/useEvent";

const GLOBAL_INTERSECTION_THRESHOLD = 0.5;

export const useViewportObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  trackIntersectionCallback: () => void,
  threshold = GLOBAL_INTERSECTION_THRESHOLD,
) => {
  const isTrackedRef: MutableRefObject<boolean> = useRef<boolean>(false);

  const trackingCallback = useEvent(() => {
    if (!isTrackedRef.current) {
      trackIntersectionCallback();
      isTrackedRef.current = true;
    }
  });

  useEffect(() => {
    const currentElement = ref.current;
    if (!currentElement) {
      return;
    }

    observerManager.observe(currentElement, trackingCallback, threshold);

    return () => observerManager.disconnect(currentElement);
  }, [ref, threshold, trackingCallback]);
};
