import React, { ReactNode } from "react";

export const SELECTED_FILTER_BLOCK_CLASS = "filter-selected__class";

type Props = {
  label: string;
  children: ReactNode;
};

export const FilterBlock: React.FC<Props> = ({ label, children }) => {
  return (
    <div className={`filter-selected__block ${SELECTED_FILTER_BLOCK_CLASS}`}>
      <div className="label">{label}</div>
      <ul className="filter-selected__tag-list">{children}</ul>
    </div>
  );
};
