import React, { ReactNode, useReducer } from "react";
import { NotificationContextProvider } from "../context";
import useEvent from "../../common/hooks/useEvent";
import { NotificationItem } from "../types";
import Notification from "./Notification";

type Props = {
  children: ReactNode;
};

type AddNotificationAction = {
  type: "add";
  payload: NotificationItem;
};

type RemoveNotificationAction = {
  type: "remove";
  payload: string;
};

type NotificationStateAction = AddNotificationAction | RemoveNotificationAction;

const MAX_ITEMS = 4;

const notificationReducer = (state: NotificationItem[], action: NotificationStateAction) => {
  switch (action.type) {
    case "add":
      return [...state.slice(-MAX_ITEMS + 1), action.payload];
    case "remove":
      return state.filter(({ id }) => id !== action.payload);
  }
};

const NotificationProvider: React.FC<Props> = ({ children }) => {
  const [stack, dispatch] = useReducer(notificationReducer, []);
  const pushNotification = useEvent((notification: NotificationItem): void => {
    if (notification.autoDismissMs) {
      setTimeout(() => {
        dispatch({ type: "remove", payload: notification.id });
      }, notification.autoDismissMs);
    }
    dispatch({ type: "add", payload: notification });
  });
  return (
    <NotificationContextProvider value={{ pushNotification: pushNotification }}>
      {children}
      <div className="alert-stack">
        {stack.map(({ id, icon, text }) => (
          <Notification
            key={id}
            icon={icon}
            text={text}
            onDismiss={() => {
              dispatch({ type: "remove", payload: id });
            }}
          />
        ))}
      </div>
    </NotificationContextProvider>
  );
};

export default NotificationProvider;
