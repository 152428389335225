import { useCallback, useEffect, useState } from "react";
import { STORAGE_KEYS } from "../constants/Storage";
import localStorageManager from "../global/localStorageManager";
import useEvent from "./useEvent";

// key must be stable
function useLocalStorage<T>(key: STORAGE_KEYS, defaultValue: T): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    return localStorageManager.getItem(key) ?? defaultValue;
  });

  const updateStoredValue = useEvent((data: T) => {
    setStoredValue(data ?? defaultValue);
  });

  const listener = useCallback(
    (data: T) => {
      updateStoredValue(data);
    },
    [updateStoredValue],
  );

  useEffect(() => {
    localStorageManager.register(key, listener);
    return () => {
      localStorageManager.unsubscribe(key, listener);
    };
  }, [key, listener]);

  const setValue = (value: T | ((val: T | null) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    localStorageManager.setItem(key, valueToStore);
  };
  return [storedValue, setValue];
}

export default useLocalStorage;
