import React from "react";
import i18next from "i18next";
import { MODEL_BRAND_SEPARATOR } from "../../services/filter-types/brandModelFilterTypeHelpers";
import { FilterReferenceData } from "../../services/reference-data-aggregator/types";
import { TagRemover } from "./TagRemover";
import { FilterBlock } from "./FilterBlock";

type Props = {
  value: string[];
  queryParam: string;
  updateFilters: (q: Record<string, any>) => void;
  filterData: FilterReferenceData;
};

export const SelectedBrandModel: React.FC<Props> = ({ value, queryParam, updateFilters, filterData }) => {
  const filteredModelBrandParts = value.filter(item => !!item).map(query => query.split(MODEL_BRAND_SEPARATOR));
  const brandIds = filteredModelBrandParts.map(modelBrandParts => modelBrandParts[0]);
  const modelIds = filteredModelBrandParts.flatMap(modelBrandParts => {
    const [brand, ...models] = modelBrandParts;
    return models.map(model => `${brand}-${model}`);
  });
  return (
    <>
      <FilterBlock label={i18next.t("BRAND TITLE")}>
        {brandIds.map((brandId, i) => {
          return (
            <TagRemover
              key={`${brandId}-${i}`}
              label={filterData.brands.map[brandId].name}
              onClick={() => {
                updateFilters({
                  [queryParam]: value.filter(item => {
                    if (!item) {
                      return true;
                    }
                    const queryParts = item.split(MODEL_BRAND_SEPARATOR);
                    return queryParts[0] !== brandId;
                  }),
                });
              }}
            />
          );
        })}
      </FilterBlock>
      {modelIds.length > 0 ? (
        <FilterBlock label={i18next.t("MODEL TITLE")}>
          {modelIds.map((brandModelId, i) => {
            const [brandId, modelId] = brandModelId.split("-");
            return (
              <TagRemover
                key={`${brandModelId}-${i}`}
                label={filterData.models[brandId].map[modelId].name}
                onClick={() => {
                  updateFilters({
                    [queryParam]: value.map(item => {
                      if (!item) {
                        return item;
                      }
                      const queryParts = item.split(MODEL_BRAND_SEPARATOR);
                      return queryParts
                        .filter((element, i) => i === 0 || element !== modelId)
                        .join(MODEL_BRAND_SEPARATOR);
                    }),
                  });
                }}
              />
            );
          })}
        </FilterBlock>
      ) : null}
    </>
  );
};
