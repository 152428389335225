import { ThunkDispatch } from "@reduxjs/toolkit";
import { AUTH_BASE_URL } from "../constants";
import { HttpStatus } from "../../modules/common/constants/HttpStatus";
import { loggedIn, loggedOut } from "../authSlice";

type AccessToken = string;

// only one token refresher shall be active at any point in time
const authenticationSingleton: { pendingTokenRefreshInstance: null | (() => Promise<AccessToken | null>) } = {
  pendingTokenRefreshInstance: null,
};

const startTokenRefreshSingleton = async (
  refreshToken: string,
  dispatch: ThunkDispatch<any, any, any>,
  refreshTokenFetchFn: typeof fetch,
): Promise<AccessToken | null> => {
  let newToken: AccessToken | null = null;
  const promises: ((token: AccessToken | null) => void)[] = [];
  authenticationSingleton.pendingTokenRefreshInstance = () => {
    return new Promise(resolve => {
      promises.push(resolve);
    });
  };

  const refreshTokenResponse = await refreshTokenFetchFn(`${AUTH_BASE_URL}/api/jwt/refresh`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refreshToken }),
  });

  if (refreshTokenResponse.status === HttpStatus.BAD_REQUEST) {
    dispatch(loggedOut());
    newToken = null;
  } else {
    const { token, refreshToken: newRefreshToken } = await refreshTokenResponse.json();

    newToken = token;
    dispatch(loggedIn({ refreshToken: newRefreshToken, accessToken: token }));
  }

  promises.forEach(resolve => resolve(newToken));
  authenticationSingleton.pendingTokenRefreshInstance = null;

  return newToken;
};

export const getNewAccessToken = async (
  refreshToken: string,
  dispatch: ThunkDispatch<any, any, any>,
  refreshTokenFetchFn: typeof fetch = fetch,
): Promise<AccessToken | null> => {
  if (!authenticationSingleton.pendingTokenRefreshInstance) {
    return await startTokenRefreshSingleton(refreshToken, dispatch, refreshTokenFetchFn);
  } else {
    return await authenticationSingleton.pendingTokenRefreshInstance();
  }
};
