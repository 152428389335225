import { UrlComponent, Video } from "../../modules/hygraph/vo";

export function getVideoId(video: Video): string | undefined {
  return getVideoIdForUrl((video.media as UrlComponent)?.url);
}

export function getVideoIdForUrl(url: string): string | undefined {
  return url?.split("?v=").pop() ?? undefined;
}

export function getThumbnailForVideo(videoId: string) {
  return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
}
