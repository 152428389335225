import { createApi } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";
import { VehicleDiscount, VehiclePromotion } from "../../../modules/hygraph/vo";
import baseAuthenticationQuery from "../../../auth/services/baseAuthenticationQuery";
import { AuthInfo } from "../../../auth/types";

export type LeasingApplicationSummaryRequest = AuthInfo & {
  body: {
    vehicleId: number;
    downPayment: number;
    kmPerYear: number;
    totalRuntime: number;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    homeDelivery: boolean;
    deliveryStreetName: string | undefined;
    deliveryStreetNumber: string | undefined;
    deliveryCity: string | undefined;
    deliveryZipCode: string | undefined;
  };
};
export type LeasingApplicationSummaryResponse = {
  data: {
    totalPrice: number;
    homeDeliveryPrice: number;
    originalPrice: number;
    priceB2CGross: number;
    pricePreparationGross: number;
    discounts: VehicleDiscount[];
    promotions: VehiclePromotion[];
    financingAmount: number;
    leasingInterest: number;
    monthlyLeasingRate: number;
    downPayment: number;
    residualValue: number;
    kmPerYear: number;
    totalRuntime: number;
    legalDetailLines: string[];
  };
};

export type LeasingApplicationCheckoutRequest = AuthInfo & {
  body: {
    vehicleId: number;
    downPayment: number;
    kmPerYear: number;
    totalRuntime: number;
    contactType: string;
    firstName: string;
    lastName: string;
    salutation: string;
    phone: string | undefined;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    homeDelivery: boolean;
    dueDate: string | undefined;
    dueTime: string | undefined;
    deliveryStreetName: string | undefined;
    deliveryStreetNumber: string | undefined;
    deliveryCity: string | undefined;
    deliveryZipCode: string | undefined;
    utmCampaign?: string;
  };
};

export type VehiclePurchaseSummaryRequest = AuthInfo & {
  body: {
    vehicleId: number;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    homeDelivery: boolean;
    deliveryStreetName: string | undefined;
    deliveryStreetNumber: string | undefined;
    deliveryCity: string | undefined;
    deliveryZipCode: string | undefined;
  };
};

export type VehiclePurchaseSummaryResponse = {
  data: {
    totalPrice: number;
    homeDeliveryPrice: number;
    originalPrice: number;
    priceB2CGross: number;
    pricePreparationGross: number;
    priceDownpayment: number;
    finalPayment: number;
    discounts: VehicleDiscount[];
    promotions: VehiclePromotion[];
  };
};

export type InitVehiclePurchaseRequest = AuthInfo & {
  body: {
    vehicleId: number;
    firstName: string;
    lastName: string;
    salutation: string;
    phone: string | undefined;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    homeDelivery: boolean;
    dueDate: string | undefined;
    dueTime: string | undefined;
    deliveryStreetName: string | undefined;
    deliveryStreetNumber: string | undefined;
    deliveryCity: string | undefined;
    deliveryZipCode: string | undefined;
    insuranceOfferRequested: boolean;
  };
};

export type InitVehiclePurchaseResponse = {
  data: {
    returnUrl: string;
  };
};

export type ConcludeVehiclePurchaseRequest = AuthInfo & {
  body: {
    vehicleId: number;
    utmCampaign?: string;
  };
};

export type ConcludeVehiclePurchaseResponse = {
  data: {
    downPayment: number;
  };
};

export enum LeadType {
  GENERAL_INQUIRY = "general_inquiry",
  LEASING_APPLICATION = "leasing_application",
  LEASING_REQUEST = "leasing_request",
  TEST_DRIVE = "test_drive",
  VEHICLE_PURCHASE = "vehicle_purchase",
}
export type LeadBaseItem = {
  type: LeadType;
  vehicleId: number | null;
  dealerId: number | null;
  updated: string;
};
export type LeadListResponse = {
  data: LeadBaseItem[];
};

export const authenticatedFfuApi = createApi({
  reducerPath: "authenticatedFfuApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  tagTypes: ["session"],
  endpoints: builder => ({
    getLeads: builder.query<LeadListResponse, AuthInfo>({
      query: ({ userId }) => ({
        method: "GET",
        url: `/user/${userId}/leads`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
      }),
    }),
    getLeasingApplicationSummary: builder.query<LeasingApplicationSummaryResponse, LeasingApplicationSummaryRequest>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/ffu/leasing/application/summary`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body,
      }),
    }),
    createLeasingApplication: builder.mutation<null, LeasingApplicationCheckoutRequest>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/ffu/leasing/application`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body,
      }),
    }),
    getVehiclePurchaseSummary: builder.query<VehiclePurchaseSummaryResponse, VehiclePurchaseSummaryRequest>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/ffu/vehicle-purchase/summary`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body,
      }),
    }),
    initializeVehiclePurchase: builder.mutation<InitVehiclePurchaseResponse, InitVehiclePurchaseRequest>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/ffu/vehicle-purchase/payment`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body,
      }),
    }),
    concludeVehiclePurchase: builder.mutation<ConcludeVehiclePurchaseResponse, ConcludeVehiclePurchaseRequest>({
      query: ({ userId, body }) => ({
        method: "POST",
        url: `/user/${userId}/ffu/vehicle-purchase`,
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body,
      }),
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetLeasingApplicationSummaryQuery,
  useCreateLeasingApplicationMutation,
  useGetVehiclePurchaseSummaryQuery,
  useInitializeVehiclePurchaseMutation,
  useConcludeVehiclePurchaseMutation,
} = authenticatedFfuApi;
