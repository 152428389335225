import i18next from "i18next";

export const requiredValidator = () => {
  return {
    required: i18next.t("VALIDATION ERROR FIELD REQUIRED"),
  };
};

export const mailValidator = () => {
  return {
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: i18next.t("VALIDATION ERROR EMAIL IS INVALID"),
    },
  };
};
