import i18next from "i18next";
import { RawFilterItem } from "./vehicleFilterReferenceDataApi";

const getOtherFilterTagLabelValueMap = (): Map<string, string> => {
  const map = new Map<string, string>();
  map.set("1", "VALID INSPECTION");
  map.set("2", "HOME DELIVERY");
  map.set("3", "NO ACCIDENT");
  map.set("4", "PROMOTION");
  map.set("5", "QUALITY CHECKED");

  return map;
};

export function getOtherFilterTags(): RawFilterItem[] {
  return [
    // todo: add normalized other filter tags options?
    { id: 1, name: i18next.t("VALID INSPECTION"), nameNormalized: "" },
    { id: 2, name: i18next.t("HOME DELIVERY"), nameNormalized: "" },
    { id: 3, name: i18next.t("NO ACCIDENT"), nameNormalized: "" },
    { id: 4, name: i18next.t("PROMOTION"), nameNormalized: "" },
    { id: 5, name: i18next.t("QUALITY CHECKED"), nameNormalized: "" },
  ];
}

export function resolveOtherFilterTagLabel(label: string): string {
  const map = getOtherFilterTagLabelValueMap();
  return map.get(label) ?? "";
}

export function resolveOtherFilterTagValue(value: string): string | undefined {
  const map = getOtherFilterTagLabelValueMap();
  const keysAsArray = Array.from(map.keys());
  return keysAsArray.find(key => map.get(key) === value);
}
