import isNil from "../../common/helpers/isNil";

export type QueryParam = string | number | string[] | number[] | undefined | null;
export type QueryParams = Record<string, QueryParam>;

function getQueryStringFromParams(queryParams: QueryParams) {
  const queryParamEntries: [string, string | string[]][] = Object.entries(queryParams)
    .filter((tuple): tuple is [string, string | number] => !isNil(tuple[1]))
    .map(([key, value]) => [key, Array.isArray(value) ? value.map(x => `${x}`) : `${value}`]);

  const params = new URLSearchParams();
  queryParamEntries.forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        params.append(key, item);
      });
    } else {
      params.append(key, `${value}`);
    }
  });

  return params.toString();
}

export default getQueryStringFromParams;
