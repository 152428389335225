import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { Page } from "../../../../router/constants/Page";

type AccordionTrackEvent = {
  content_modules: {
    content_module: "Accordion";
    content_module_action: "open" | "close";
    content_module_label: string;
  };
};
export type AccordionTrackingEvent = BaseTrackingEvent & AccordionTrackEvent;
export const accordionTrack = (trackingTitle: string, expanded: boolean, pageType?: Page | null) => {
  dataLayer([
    {
      event: "gaEvent",
      event_name: "content_modules",
      page_type: pageType,
      content_modules: {
        content_module: "Accordion",
        content_module_action: expanded ? "open" : "close",
        content_module_label: trackingTitle,
      },
    } satisfies AccordionTrackingEvent,
  ]);
};
