import useLanguage from "../../../router/hooks/useLanguage";
import { useUserId } from "../../analytics/hooks/useUserId";

export const useAaTrackArguments = () => {
  const language = useLanguage();
  const { userId, hasSettledUserId } = useUserId(true);

  return {
    language,
    userId,
    hasSettledUserId,
  };
};
