import { createApi } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { AuthInfo } from "../../../auth/types";
import baseAuthenticationQuery from "../../../auth/services/baseAuthenticationQuery";

type NewsletterStatusResponse = {
  subscribed: boolean;
};

export const authenticatedNewsletterApi = createApi({
  reducerPath: "authenticatedNewsletterApi",
  baseQuery: baseAuthenticationQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    state: builder.query<NewsletterStatusResponse, AuthInfo>({
      query: ({ userId }) => ({
        method: "GET",
        url: `/newsletter/${userId}/state`,
      }),
      transformResponse: (response: { data: NewsletterStatusResponse }) => response.data,
    }),
  }),
});

export const { useStateQuery } = authenticatedNewsletterApi;
