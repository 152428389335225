import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import usePrerenderReady from "../hooks/usePrerenderReady";
import buildTitle from "../helpers/buildTitle";
import TopNav from "./TopNav";

export const NotFoundMetaInfo = ({ status = "404" }: { status?: "404" | "410" }) => {
  usePrerenderReady();
  return (
    <Helmet>
      <title>{buildTitle(i18next.t("SEO NOT FOUND TITLE"))}</title>
      <meta content={status} name="prerender-status-code" />
    </Helmet>
  );
};

/**
 * Page component to render if a 404 occurred.
 * It also takes care of notifying prerender.io about the 404 to prevent bloating the cache.
 * @constructor
 */
const NotFound: React.FC = () => {
  const { homePath } = usePathBuilder();
  return (
    <>
      <NotFoundMetaInfo />
      <div className="col-span-10">
        <TopNav fallback={{ to: homePath(), label: i18next.t("GOTO HOME") }} />
        <header className="main__header has-2children space-btm-16">
          <h1 className="main-header__title">{i18next.t("CONTENT NOT FOUND TITLE")}</h1>
        </header>
        <p>
          {i18next.t("CONTENT NOT FOUND DESCRIPTION")} <Link to={homePath()}>{i18next.t("GOTO HOME")}</Link>
        </p>
      </div>
    </>
  );
};

export default NotFound;
