import i18next from "i18next";
import { OpeningHours, WorkingHoursException } from "../types/openingHours";
import {
  getActiveWorkingHourExceptions,
  getOpeningHoursForWeekday,
  getRelevantOpeningHours,
  getWeekdayLiteral,
} from "./openingHours";

export const getWorkingUntilForDate = (
  openingHours: OpeningHours,
  workingHourExceptions: WorkingHoursException[],
  date = new Date(),
): string | null => {
  const { workActivityType, openingHourRecords } = getRelevantOpeningHours(openingHours);
  const filteredWorkingHourExceptions = getActiveWorkingHourExceptions(workingHourExceptions, workActivityType, date);
  const hasWorkingHourExceptions = filteredWorkingHourExceptions.length > 0;

  if (hasWorkingHourExceptions) {
    const maxOpeningHour = filteredWorkingHourExceptions.reduce<string | null>((maxOpeningHour, { end }) => {
      if (maxOpeningHour === null) {
        return end;
      }
      if (end === null) {
        return maxOpeningHour;
      }
      return end > maxOpeningHour ? end : maxOpeningHour;
    }, null);
    return maxOpeningHour;
  }

  const currentWeekday = getWeekdayLiteral(date);

  const openingHoursForDay = openingHourRecords ? getOpeningHoursForWeekday(openingHourRecords, currentWeekday) : [];
  const maxOpeningHour = openingHoursForDay?.reduce<string | null>((maxOpeningHour, { closeTime }) => {
    if (maxOpeningHour === null) {
      return closeTime;
    }
    // this uses lexicographic comparison
    return closeTime > maxOpeningHour ? closeTime : maxOpeningHour;
  }, null);
  return maxOpeningHour;
};

export const getFormattedWorkingUntilLabel = (
  openingHours: OpeningHours,
  workingHourExceptions: WorkingHoursException[],
  formatTime: (timeString: string) => string,
): string => {
  const openingInfo = getWorkingUntilForDate(openingHours, workingHourExceptions);
  return openingInfo === null
    ? i18next.t("CLOSED TODAY")
    : i18next.t("TODAY OPENED UNTIL", { value: formatTime(openingInfo) });
};
