import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BACKEND_API_ENDPOINT } from "../../../modules/common/constants/configuration";
import { getAcceptLanguageHeader } from "../../../modules/common/api/headerBuilders";

export type CompetitionSubmitRequest = {
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
};

export type CompetitionSubmitResponse = {
  instantWinUrl: string;
};

export const tourApi = createApi({
  reducerPath: "tourApi",
  baseQuery: fetchBaseQuery({ baseUrl: BACKEND_API_ENDPOINT }),
  endpoints: builder => ({
    submitCompetition: builder.mutation<CompetitionSubmitResponse, CompetitionSubmitRequest>({
      query: data => ({
        method: "POST",
        url: "/tour/competition/submission",
        headers: {
          ...getAcceptLanguageHeader(),
        },
        body: data,
      }),
    }),
  }),
});

export const { useSubmitCompetitionMutation } = tourApi;
