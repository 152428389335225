import { DealerDetail, DealerDetailResponse } from "../../dealer/types/dealer";

const transformDealerResponse = (dealerRes: DealerDetailResponse): DealerDetail => {
  const { gReviews, ...rest } = dealerRes;

  if (gReviews?.status === "OK") {
    const googleReviews = gReviews?.result;

    if (!googleReviews?.rating || !googleReviews?.user_ratings_total || !googleReviews?.reviews) {
      return {
        ...rest,
        googleReviews: null,
      };
    }

    return {
      ...rest,
      googleReviews: {
        rating: googleReviews.rating,
        userRatingsTotal: googleReviews.user_ratings_total,
        reviews: googleReviews?.reviews.map(review => ({
          authorName: review.author_name,
          profilePhotoUrl: review.profile_photo_url,
          rating: review.rating,
          time: review.time,
          text: review.text,
        })),
      },
    };
  }

  return {
    ...rest,
    googleReviews: null,
  };
};

export default transformDealerResponse;
