import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";

type SliderTrackEvent = {
  content_modules: {
    content_module: "Gallery";
    content_module_action: "Left" | "Right";
    content_module_label: string;
  };
};
export type SliderTrackingEvent = BaseTrackingEvent & SliderTrackEvent;
export const sliderTrack = (direction: "prev" | "next", title: string) => {
  const mappedDirection = direction === "prev" ? "Left" : "Right";
  dataLayer([
    {
      event: "gaEvent",
      event_name: "content_modules",
      content_modules: {
        content_module: "Gallery",
        content_module_action: mappedDirection,
        content_module_label: title,
      },
    } satisfies SliderTrackingEvent,
  ]);
};
