/**
 * keyboard key strings
 */
export enum Key {
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
  ArrowUp = "ArrowUp",
  Escape = "Escape",
  Enter = "Enter",
}
