import { FilterReferenceData } from "../services/reference-data-aggregator/types";
import { FilterTypeDefinition } from "../services/types/filterTypes";
import { FilterDefinition } from "../services/types/filterDefinitionTypes";

export const isAcceptingArrayQueryParams = (
  filterType: FilterTypeDefinition<any, string[]> | FilterTypeDefinition<any>,
): filterType is FilterTypeDefinition<any, string[]> => {
  return filterType.paramType === "stringArray";
};

export const getSearchQueries = (
  filterType: FilterTypeDefinition<any, string[]> | FilterTypeDefinition<any>,
  value: string | string[],
  filter: FilterDefinition,
  filterData: FilterReferenceData,
) => {
  if (isAcceptingArrayQueryParams(filterType)) {
    return filterType.getSearchQueries(value as string[], filter, filterData);
  }
  return filterType.getSearchQueries(value as string, filter, filterData);
};

export const getConfigurationCount = (
  filterType: FilterTypeDefinition<any, string[]> | FilterTypeDefinition<any>,
  value: string | string[],
) => {
  if (isAcceptingArrayQueryParams(filterType)) {
    return filterType.getConfigurationCount(value as string[]);
  }
  return filterType.getConfigurationCount(value as string);
};
