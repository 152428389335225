import React, { useEffect } from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { ChecklistItem, useCompetition } from "../hooks/useCompetition";
import { selectIsLoggedIn } from "../../../auth/authSelectors";

const CompetitionStateHandler: React.FC = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { checkItem } = useCompetition();

  useEffect(() => {
    if (isLoggedIn) {
      checkItem(ChecklistItem.registration, i18next.t("CHECKLIST CHECKED REGISTRATION"));
    }
  }, [checkItem, isLoggedIn]);

  return null;
};

export default CompetitionStateHandler;
