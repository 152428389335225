import { RefObject, useEffect, useState } from "react";

/**
 * scroll to startPointRef when page has changed and new data is loaded
 * @param startPointRef
 * @param isFetching
 * @param page
 * @param setPage
 */
export function usePageScroller(
  startPointRef: RefObject<HTMLElement>,
  isFetching: boolean,
  page: number,
  setPage: (page: number) => void,
) {
  const [pageManuallySet, setPageManuallySet] = useState(false);

  // scroll to top after page change
  useEffect(() => {
    if (pageManuallySet && !isFetching) {
      startPointRef.current?.scrollIntoView();
      setPageManuallySet(false);
    }
  }, [startPointRef, page, pageManuallySet, isFetching]);

  const updateCurrentPage = (page: number) => {
    setPage(page);
    setPageManuallySet(true);
  };
  return updateCurrentPage;
}
