import { useTranslation } from "react-i18next";
import { FALLBACK, Params } from "../../../router/constants/Params";

const DEFAULT_COUNTRY_CODE = "ch";

export function useLocale(): string {
  const { i18n } = useTranslation();

  return `${i18n.resolvedLanguage ?? FALLBACK[Params.lang]}-${DEFAULT_COUNTRY_CODE}`;
}
