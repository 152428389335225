import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CookieConsentCountry } from "../../common/constants/CookieConsentCountry";

type GeoLocationResponse = { country_code: CookieConsentCountry; latitude: number; longitude: number };

export const geoLocationApi = createApi({
  reducerPath: "countryCodeApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_ABSTRACT_API_URL }),
  endpoints: builder => ({
    getIpGeoLocationData: builder.query<GeoLocationResponse, void>({
      query: () => "",
      transformResponse: (response: GeoLocationResponse) => {
        return response;
      },
    }),
  }),
});

export const { useGetIpGeoLocationDataQuery } = geoLocationApi;
