import i18next, { TFunction } from "i18next";
import { RangeFilterDef } from "../types/filterDefinitionTypes";
import { FilterTypeDefinition } from "../types/filterTypes";
import { FilterReferenceData } from "../reference-data-aggregator/types";
import {
  buildConjunctiveFilter,
  buildLargerOrEqualFilter,
  buildSmallerOrEqualFilter,
} from "../../../algolia/services/buildFilter";
import { VEHICLE_FACETS } from "../../../algolia/services/vehicleFacetKeys";
import { notNil } from "../../../common/helpers/isNil";

export type RangeFilterUnitFormatting = "integer" | "year";

export const DEFAULT_RANGE_FILTER_ID = "i";

export type MinimalRangeFilterUnit = {
  scale: "linear" | "log";
  min: (data: FilterReferenceData) => number;
  max: (data: FilterReferenceData) => number;
  precision?: number;
  formatting: RangeFilterUnitFormatting;
  facetKey: VEHICLE_FACETS;
  convert?: (value: number, isLower: boolean) => number;
  unit?: (t: TFunction) => string;
};

export type RangeFilterUnit = MinimalRangeFilterUnit & {
  id: string;
  label: (t: TFunction) => string;
};

const formatWithUnit = (val: string, unit?: string) => {
  return `${val}${unit ? ` ${unit}` : ""}`;
};

export const RANGE_VALUE_SEPARATOR = "-";

export const getRangeFilterValueParts = (
  value: string,
): [string | undefined, number | undefined, number | undefined] => {
  const parts = value.split(RANGE_VALUE_SEPARATOR);
  const unitId = parts[0];
  const lower = parts[1];
  const upper = parts[2];
  return [unitId, lower ? parseInt(lower) : undefined, upper ? parseInt(upper) : undefined];
};

export const findUnit = (
  unitId: string | undefined,
  units: RangeFilterUnit[] | MinimalRangeFilterUnit,
): RangeFilterUnit | MinimalRangeFilterUnit => {
  if (Array.isArray(units)) {
    return units.find(({ id }) => id === unitId) || units[0];
  }
  return units;
};

export function formatRangeFilterValue(
  value: number,
  formatting: RangeFilterUnitFormatting,
  formatNumber: (value: number) => string,
): string {
  const applyFormatter = formatting === "integer";

  const isNumber = Number.isFinite(value);
  return applyFormatter && isNumber ? formatNumber(value as number) : `${value}`;
}

export const getUnitLabel = (unit: RangeFilterUnit | MinimalRangeFilterUnit) =>
  unit.unit ? unit.unit(i18next.t) : undefined;

export function getRangeFilterFormattedLabel(
  value: string,
  filterDefinition: RangeFilterDef,
  formatNumber: (value: number) => string,
) {
  const [unitId, lower, upper] = getRangeFilterValueParts(value);

  const unit = findUnit(unitId, filterDefinition.units);
  const formattedLower = lower ? formatRangeFilterValue(lower, unit.formatting, formatNumber) : "";
  const formattedUpper = upper ? formatRangeFilterValue(upper, unit.formatting, formatNumber) : "";

  const unitLabel = getUnitLabel(unit);

  let translation = "";
  if (!!lower && !!upper) {
    translation = i18next.t("BETWEEN PLACEHOLDER", {
      lower: formattedLower,
      upper: formatWithUnit(formattedUpper, unitLabel),
    });
  } else if (!!lower) {
    translation = i18next.t("STARTING PLACEHOLDER", { value: formatWithUnit(formattedLower, unitLabel) });
  } else if (!!upper) {
    translation = i18next.t("UPTO PLACEHOLDER", { value: formatWithUnit(formattedUpper, unitLabel) });
  }
  return translation;
}

export const rangeFilterType: FilterTypeDefinition<RangeFilterDef> = {
  getConfigurationCount: val => {
    const parts = val?.split(RANGE_VALUE_SEPARATOR) || [];
    let count = 0;
    if (!!parts[1]) {
      count++;
    }
    if (!!parts[2]) {
      count++;
    }
    return count;
  },
  paramType: "string",
  getConfigurationLabels: (val, formatters, filter) => {
    return [`${filter?.title?.(i18next.t)} ${getRangeFilterFormattedLabel(val, filter, formatters.formatNumber)}`];
  },
  getSearchQueries: (param, filter) => {
    const [unitId, min, max] = getRangeFilterValueParts(param);
    const unit = findUnit(unitId, filter.units);
    const convert = unit.convert ? unit.convert : (val: any) => val;

    const numericMin = min ? convert(min, true) : undefined;
    const numericMax = max ? convert(max, false) : undefined;

    const queryParts: string[] = [];

    if (notNil(numericMin)) {
      queryParts.push(buildLargerOrEqualFilter(unit.facetKey, numericMin));
    }
    if (notNil(numericMax)) {
      queryParts.push(buildSmallerOrEqualFilter(unit.facetKey, numericMax));
    }

    return [buildConjunctiveFilter(queryParts)];
  },
};
