import React, { ReactNode } from "react";
import i18next from "i18next";
import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import useEscapeKeyListener from "../../common/hooks/useEscapeKeyListener";
import CssPresenceAnimation from "../../common/components/CssPresenceAnimation";
import { usePreventBackgroundScroll } from "../hooks/usePreventBackgroundScroll";
import IconButton from "./IconButton";

type Props = {
  open: boolean;
  close: () => void;
  children: ReactNode;
  keyboardDismissable?: boolean;
  gradient?: boolean;
};

const Popup: React.FC<Props> = ({ open, close, children, keyboardDismissable = false, gradient = false }) => {
  useEscapeKeyListener(close, keyboardDismissable && open);
  usePreventBackgroundScroll(open);

  return (
    <AnimatePresence>
      {open ? (
        <CssPresenceAnimation<HTMLDialogElement> animationClassName="overlay-animation-enter" durationMs={300}>
          {(ref, classNameOverwrite) => (
            <dialog
              ref={ref}
              open={open}
              className={classNames(
                "popup",
                "overlay-animation",
                { open: open, "popup--gradient": gradient },
                classNameOverwrite,
              )}
            >
              <div className="popup__container overlay-animation__container">
                <IconButton ariaLabel={i18next.t("CLOSE")} className="popup__close" icon="close" onClick={close} />
                {children}
              </div>
            </dialog>
          )}
        </CssPresenceAnimation>
      ) : null}
    </AnimatePresence>
  );
};

export default Popup;
