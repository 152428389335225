import React from "react";

type Props = {
  label: string;
  onClick: () => void;
};

export const TagRemover: React.FC<Props> = ({ label, onClick }) => {
  return (
    <li onClick={onClick}>
      <div className="tag-remover">
        <span className="tag__text">{label}</span>
        <span className="ifont ifont--close tag-remover__icon" />
      </div>
    </li>
  );
};
