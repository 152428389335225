import React from "react";
import i18next from "i18next";
import { Currency, InsideHubApi_Promotion, VehiclePromotion } from "../../hygraph/vo";
import isNil from "../../common/helpers/isNil";
import useFormatter from "../../common/hooks/useFormatter";
import PopupContent from "../../visual-components/components/PopupContent";

type Props = {
  promotion: VehiclePromotion;
  currency: Currency;
  promotionInfo: InsideHubApi_Promotion | undefined;
};

const PromotionInfoBody: React.FC<Props> = ({ promotion, currency, promotionInfo }) => {
  const { formatCurrency } = useFormatter();

  const priceGrossText = formatCurrency(promotion.priceGross, currency);

  const isLoading = isNil(promotionInfo);
  return (
    <PopupContent
      gradient
      title={promotion.name ?? i18next.t("PROMOTION")}
      body={
        <>
          <p>{priceGrossText}</p>
          {isLoading ? <p>{i18next.t("LOADING")}…</p> : null}
          {promotionInfo ? (
            <>
              <p>{promotionInfo.description}</p>
              <p>{promotionInfo.legalLine}</p>
            </>
          ) : null}
        </>
      }
    />
  );
};

export default PromotionInfoBody;
