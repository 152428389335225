import i18next from "i18next";
import { FilterTypeDefinition } from "../types/filterTypes";
import { buildFilter } from "../../../algolia/services/buildFilter";
import { getNormalizedFacetValue } from "../../helpers/facetApplierHelpers";
import { BrandModelFilterDef } from "../types/filterDefinitionTypes";
import { VEHICLE_FACETS } from "../../../algolia/services/vehicleFacetKeys";
import { AggregatedFilterItem } from "../reference-data-aggregator/types";
import getUniqueEntries from "../../../common/helpers/getUniqueEntries";
import { BRAND_MODEL_QUERY_PARAM } from "../filterDefinition";
import {
  BRAND_FACET_KEY,
  BRAND_MODEL_FACET_KEY,
  buildBrandModelFacetFilter,
  getBrandModelCount,
  MODEL_BRAND_SEPARATOR,
  MODEL_FACET_KEY,
} from "./brandModelFilterTypeHelpers";

export const buildBrandModelQueryParam = (brandInsideId: number, modelInsideId?: number): Record<string, string> => {
  if (modelInsideId) {
    return {
      [BRAND_MODEL_QUERY_PARAM]: `${brandInsideId}${MODEL_BRAND_SEPARATOR}${modelInsideId}`,
    };
  }
  return {
    [BRAND_MODEL_QUERY_PARAM]: `${brandInsideId}`,
  };
};

export const brandModelFilterType: FilterTypeDefinition<BrandModelFilterDef, string[]> = {
  getConfigurationCount: (val: string[]) =>
    val
      ?.map(query => query.split(MODEL_BRAND_SEPARATOR))
      .map(queryPart => getBrandModelCount(queryPart))
      .reduce((agg, count) => agg + count, 0) ?? 0,
  paramType: "stringArray",
  getConfigurationLabels: (val, formatters, filter, aggregatedData) => {
    const filledBrandModelQueries = val.filter(query => !!query);
    const brandModelParts = filledBrandModelQueries[0].split(MODEL_BRAND_SEPARATOR);
    if (filledBrandModelQueries.length > 1 || brandModelParts.length > 2) {
      return [i18next.t("VARIOUS VEHICLES")];
    }
    const [brand, model] = brandModelParts;
    const brandName = aggregatedData.brands.map[brand].name;
    const modelName = model ? aggregatedData.models[brand].map[model].name : undefined;

    return [`${brandName}${modelName ? ` ${modelName}` : ""}`];
  },
  getSearchQueries: (param, filter, filterData) => {
    const filledBrandModelQueries = param.filter(query => !!query);
    return [
      "(" +
        filledBrandModelQueries
          .map(brandModelQuery => {
            const [brand, ...models] = brandModelQuery.split(MODEL_BRAND_SEPARATOR);
            if (models.length > 0) {
              return models.map(model =>
                buildFilter(
                  BRAND_MODEL_FACET_KEY,
                  buildBrandModelFacetFilter(
                    filterData.brands.map[brand].name,
                    filterData.models[brand].map[model].name,
                  ),
                ),
              );
            }
            return buildFilter(BRAND_FACET_KEY, filterData.brands.map[brand].name);
          })
          .flat()
          .join(" OR ") +
        ")",
    ];
  },
  facetMapping: {
    getKeys: () => {
      return [BRAND_FACET_KEY, MODEL_FACET_KEY];
    },
    getAppliedValues: (matchingFacetFilters, appliedQueryParams, filterData) => {
      const facetValueTuples = matchingFacetFilters.map(facetFilter => getNormalizedFacetValue(facetFilter));

      const brands = facetValueTuples.filter(
        (tuple): tuple is [VEHICLE_FACETS.BRAND, string] => tuple[0] === VEHICLE_FACETS.BRAND,
      );
      const models = facetValueTuples.filter(
        (tuple): tuple is [VEHICLE_FACETS.MODEL, string] => tuple[0] === VEHICLE_FACETS.MODEL,
      );
      // todo: filters silently remove invalid items, consider logging items?
      const mappedBrands = brands
        .map(([, normalizedName]) => {
          return filterData.brands.list.find(reference => reference.nameNormalized === normalizedName);
        })
        .filter((brand): brand is AggregatedFilterItem => !!brand);
      const mappedModels = models
        .map(([, normalizedName]) => {
          // todo: also prioritize already applied brand filters
          let entries = mappedBrands
            .map(brand => [
              brand.value,
              filterData.models[brand.value].list.find(reference => reference.nameNormalized === normalizedName),
            ])
            .filter((tuple): tuple is [string, AggregatedFilterItem] => !!tuple[1]);
          if (entries.length === 0) {
            entries = Object.entries(filterData.models)
              .map(([brand, models]) => {
                return [brand, models.list.find(reference => reference.nameNormalized === normalizedName)];
              })
              .filter((tuple): tuple is [string, AggregatedFilterItem] => !!tuple[1]);
          }
          return entries;
        })
        .flat()
        .filter((brand): brand is [string, AggregatedFilterItem] => !!brand);

      const mappedSingleBrands = mappedBrands.filter(({ value }) =>
        mappedModels.every(([brandValue]) => value !== brandValue),
      );

      // todo: appliedFilterMap aggregation should be extracted
      const appliedFilterMap = appliedQueryParams
        .map(query => query.split(MODEL_BRAND_SEPARATOR))
        .reduce<Record<string, string[]>>((agg, [brand, ...models]) => {
          // it's possible to add the same brand multiple times, but it's unlikely
          if (models.length > 0) {
            agg[brand] = models;
          } else {
            agg[brand] = [];
          }
          return agg;
        }, {});

      mappedSingleBrands.forEach(({ value }) => {
        appliedFilterMap[value] = [];
      });

      mappedModels.forEach(([brand, model]) => {
        if (!appliedFilterMap[brand]) {
          appliedFilterMap[brand] = [];
        }
        appliedFilterMap[brand].push(model.value);
      });

      return Object.entries(appliedFilterMap).map(([brand, models]) => {
        return [BRAND_MODEL_QUERY_PARAM, [brand, ...getUniqueEntries(models)].join(MODEL_BRAND_SEPARATOR)];
      });
    },
  },
};
