import { MutableRefObject } from "react";
import { adViewTrack } from "../scripts/adTrack/adViewTrack";
import { AdType } from "../scripts/adTrack/adClickTrack";
import { Page } from "../../../router/constants/Page";
import { useViewportObserver } from "./useViewportObserver";

export const useAdViewTracking = ({
  ref,
  currentPage,
  adCampaign,
  adName,
  adPosition,
  adType,
}: {
  ref: MutableRefObject<HTMLElement | null>;
  currentPage: Page | null;
  adCampaign: string;
  adName: string;
  adPosition?: number;
  adType: AdType;
}) => {
  const adViewThreshold = 0.5;

  useViewportObserver(
    ref,
    () => {
      adViewTrack({ pageType: currentPage, adCampaign, adName, adType, adPosition });
    },
    adViewThreshold,
  );
};
