import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VehicleSearchResponseItem } from "../modules/algolia/services/vehicleSearchApi";

export type VehicleDetailState = {
  data: null | VehicleSearchResponseItem;
};

const initialState: VehicleDetailState = {
  data: null,
};

const vehicleDetailSlice = createSlice({
  name: "vehicleDetail",
  initialState,
  reducers: {
    setVehicleDetail(state, action: PayloadAction<VehicleSearchResponseItem>) {
      state.data = action.payload;
    },
  },
});

export const { setVehicleDetail } = vehicleDetailSlice.actions;
export default vehicleDetailSlice.reducer;
