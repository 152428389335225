import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import worldGeodeticSystemToCH from "../../vehicle-search/services/worldGeodeticSystemToCH";

export type PlaceResponse = {
  id: number;
  attrs: {
    label: string;
    lat: number;
    lon: number;
  };
};

type LatLngTuple = [string, string];

export const UNKNOWN_PLACE = "UNKNOWN_PLACE";

const processGeoName = (name: string) => {
  return name.replace("<b>", "").replace("</b>", "");
};

export const transformPlaceResponse = (response: PlaceResponse): PlaceResponse => {
  return {
    id: response.id,
    attrs: {
      label: processGeoName(response.attrs.label),
      lat: response.attrs.lat,
      lon: response.attrs.lon,
    },
  };
};

export const dropDuplicatePlaces = (places: PlaceResponse[]) => {
  const seenPlaces: Record<string, boolean> = {};
  return places.filter(place => {
    const key = `${place.attrs.lat}-${place.attrs.lon}`;
    if (seenPlaces[key]) {
      return false;
    }
    seenPlaces[key] = true;
    return true;
  });
};

export const geoApi = createApi({
  reducerPath: "geoApi",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_GEOADMIN_API_ENDPOINT }),
  endpoints: builder => ({
    searchPlaces: builder.query<PlaceResponse[], string>({
      query: query => {
        const queryParams = new URLSearchParams({
          searchText: query,
          type: "locations",
          origins: "zipcode,gg25",
        });
        return `SearchServer?${queryParams.toString()}`;
      },
      transformResponse: (response: { results: PlaceResponse[] }) =>
        dropDuplicatePlaces(response.results.map(transformPlaceResponse)),
    }),
    findPlace: builder.query<PlaceResponse | "UNKNOWN_PLACE", LatLngTuple>({
      query: ([lat, lng]) => {
        const [x, y] = worldGeodeticSystemToCH(lat, lng);
        return `SearchServer?type=locations&bbox=${x},${y},${x},${y}&origins=gg25`;
      },
      transformResponse: (response: { results: PlaceResponse[] }) => {
        if (!response.results?.[0]) {
          return UNKNOWN_PLACE;
        }
        return transformPlaceResponse(response.results[0]);
      },
    }),
  }),
});

export const { useSearchPlacesQuery, useFindPlaceQuery } = geoApi;
