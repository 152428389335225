import React from "react";
import useCarousel from "../hooks/useCarousel";
import Image, { IMAGE_FIT_TYPES, IMAGE_TRANSFORM_TYPES } from "../../visual-components/components/Image";
import CarouselNavDot from "./CarouselNavDot";
import CarouselArrows from "./CarouselArrows";
import OptionalLink from "./OptionalLink";
import ReservedTag from "./ReservedTag";

type Props = {
  alt?: string;
  images: string[];
  link: Omit<React.ComponentProps<typeof OptionalLink>, "children" | "ref" | "className">;
  trackingTitle?: string;
  reserved?: boolean;
  firstImageLoading?: "lazy" | "eager";
};

const Carousel: React.FC<Props> = ({ images, link, trackingTitle, reserved, alt, firstImageLoading = "lazy" }) => {
  const { activeCarouselItem, scrollPosition, carouselScrollContainerRef, scrollTo, next, prev } =
    useCarousel<HTMLAnchorElement>(images, ".card__img-wrap");

  return (
    <div className="card__carousel">
      <OptionalLink {...link} ref={carouselScrollContainerRef} className="card__carousel-wrap">
        {images.length === 0 ? <div className="card__img-wrap no-img" /> : null}
        {images.map((image, i) => (
          <div key={`${image}-${i}`} className="card__img-wrap">
            <Image
              alt={alt}
              baseSrc={image}
              fit={IMAGE_FIT_TYPES.crop}
              imageSizes={IMAGE_TRANSFORM_TYPES.cardImage}
              loading={i === 0 ? firstImageLoading : "lazy"}
            />
          </div>
        ))}
      </OptionalLink>
      <CarouselNavDot
        className="card__carousel-nav-dot"
        currentItem={activeCarouselItem}
        scrollTo={scrollTo}
        totalItems={images.length}
      />
      <CarouselArrows
        next={next}
        nextClassName="card__carousel-nav-arrow arrow-right"
        prev={prev}
        prevClassName="card__carousel-nav-arrow arrow-left"
        scrollPosition={scrollPosition}
        totalItems={images.length}
        trackingTitle={trackingTitle ?? ""}
      />
      <ReservedTag isReserved={reserved} />
    </div>
  );
};

export default Carousel;
