import { FilterDefinition, MultiSelectFilterDef } from "..//services/types/filterDefinitionTypes";
import { FilterReferenceData } from "..//services/reference-data-aggregator/types";
import getUniqueEntries from "../../common/helpers/getUniqueEntries";
import { getNormalizedFacetValue } from "./facetApplierHelpers";

export const facetKeyExtractor = (filter: FilterDefinition): string[] => {
  const facetKey = (filter as MultiSelectFilterDef).facetKey;
  return [facetKey];
};

export const appliedFacetValuesExtractor = (
  matchingFacetFilters: string[],
  appliedQueryParams: string[],
  aggregatedData: FilterReferenceData,
  filterDef: FilterDefinition,
): [string, string][] => {
  const facetValueTuples = matchingFacetFilters.map(facetFilter => getNormalizedFacetValue(facetFilter));
  const dataKey = (filterDef as MultiSelectFilterDef).dataKey;

  const newAppliedQueryParams = facetValueTuples
    .map(([, normalizedFilter]) => {
      return aggregatedData[dataKey].list.find(({ nameNormalized }) => normalizedFilter === nameNormalized);
    })
    .filter(<T>(item: T | undefined): item is T => !!item)
    .map((item): string => `${item.value}`);

  return getUniqueEntries([...newAppliedQueryParams, ...appliedQueryParams]).map((value): [string, string] => [
    filterDef.queryParam,
    value,
  ]);
};
