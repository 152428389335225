const SEPARATOR_SIGNS = ["’", "'", ".", ","] as const;

const SEPARATOR_MAP = SEPARATOR_SIGNS.reduce<Record<string, boolean>>((agg, sign) => {
  agg[sign] = true;
  return agg;
}, {});

export function parseFormattedIntegerNumber(formattedNumber: string): number {
  let numericalValue = "";
  for (const char of formattedNumber) {
    if (!SEPARATOR_MAP[char]) {
      numericalValue += char;
    }
  }
  const parsedNumber = parseInt(numericalValue);
  if (isNaN(parsedNumber)) {
    return 0;
  }
  return parsedNumber;
}

const BASE = 2;

function getBaseLog(b: number, x: number) {
  return Math.log(x) / Math.log(b);
}

const normalizedLog = (n: number): number => {
  const lg = getBaseLog(BASE, n);
  if (lg === -Infinity) {
    return 0;
  }
  return lg;
};

export const normalize = (val: number, min: number, max: number, scale: "linear" | "log"): number => {
  let nMin = min;
  let nMax = max;
  let nVal = val;
  if (scale === "log") {
    nMin = normalizedLog(min);
    nMax = normalizedLog(max);
    nVal = normalizedLog(nVal);
  }

  return Math.min(100, Math.max(0, Math.round(((nVal - nMin) / (nMax - nMin)) * 100)));
};

export const denormalize = (
  val: number,
  min: number,
  max: number,
  scale: "linear" | "log",
  precision: number = 1,
): number => {
  let nMin = min;
  let nMax = max;
  const nVal = val;
  let value: number;
  if (scale === "log") {
    nMin = normalizedLog(min);
    nMax = normalizedLog(max);

    value = Math.round(BASE ** (((nMax - nMin) / 100) * nVal + nMin));
  } else {
    value = Math.round(((nMax - nMin) / 100) * nVal + nMin);
  }

  return precision === 1 ? value : Math.round(value / precision) * precision;
};
