import { RefObject, useEffect, useState } from "react";
import debounce from "../helpers/debounce";

/**
 * Used to trigger element appearance if something is out of viewport
 * @param intersectionTriggerRef
 * @param hideIfAbove Indicates whether to hide the element if trigger is below viewport
 */
function usePopupTrigger(intersectionTriggerRef: RefObject<HTMLElement>, hideIfAbove = false, threshold = 0.5) {
  const [scrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    if (!intersectionTriggerRef.current) {
      return;
    }
    const observer = new IntersectionObserver(
      // prevent eager rerendering during scrolling by debouncing
      debounce(entries => {
        // may ever only be one entry
        const entry = entries[0];
        const isBelowViewport = entry.boundingClientRect.top > 0;
        if (entry.isIntersecting) {
          setIsScrolled(false);
        } else {
          if (hideIfAbove) {
            setIsScrolled(!isBelowViewport);
          } else {
            setIsScrolled(true);
          }
        }
      }, 200),
      { threshold },
    );
    observer.observe(intersectionTriggerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [intersectionTriggerRef, hideIfAbove, threshold]);
  return scrolled;
}

export default usePopupTrigger;
