import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
};

const LanguageGuard: React.FC<Props> = ({ children }) => {
  const { ready, i18n } = useTranslation();

  if (!ready) return null;
  if (!i18n.language) return null;

  return <>{children}</>;
};

export default LanguageGuard;
