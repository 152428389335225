import { notNil } from "../../../common/helpers/isNil";

export const getDiscount = (priceB2cGross: number, priceInitialB2cGross: number | null | undefined) => {
  if (notNil(priceInitialB2cGross)) {
    if (priceB2cGross === priceInitialB2cGross) {
      return null;
    }
    return priceInitialB2cGross - priceB2cGross;
  }
  return null;
};
