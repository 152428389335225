import useLanguage from "../../../router/hooks/useLanguage";
import Locale from "../../common/constants/Locale";
import { Language } from "../vo";

const mapLocaleToHygraphLanguage = (locale: Locale) => {
  return locale as unknown as Language;
};

const useHygraphLanguage = () => {
  const language = useLanguage();
  return mapLocaleToHygraphLanguage(language);
};

export default useHygraphLanguage;
