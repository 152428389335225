import { OptionType } from "../../hygraph/vo";
import { RawOption } from "../types/options";

type OptionItem = {
  name: string;
};

type Package = {
  name: string;
  items: OptionItem[];
};

export type Option = OptionItem | Package;

export const isPackage = (option: Option): option is Package => {
  return (option as Package).items?.length > 0;
};

export const getOptions = (options: RawOption[], filter?: OptionType.S | OptionType.O | OptionType.A): Option[] => {
  const packageOptionsMap = options.reduce((agg: Record<number, RawOption[]>, rawOption) => {
    if (rawOption.type === OptionType.P) {
      const packageId = rawOption.packageVehicleOptionId;
      if (!agg[packageId]) {
        agg[packageId] = [];
      }
      agg[packageId].push(rawOption);
    }
    return agg;
  }, {});

  const packages = options.filter(({ type }) => (filter ? filter === type : true) && type !== OptionType.P);

  return packages.map(({ name, vehicleOptionId }): Option => {
    const options = packageOptionsMap[vehicleOptionId];
    if (options) {
      return {
        name,
        items: options.map(({ name }) => ({ name })) ?? [],
      };
    }
    return {
      name,
    };
  });
};
