import React from "react";
import i18next from "i18next";
import PopupContent from "../../../visual-components/components/PopupContent";
import useLogin from "../../../../auth/hooks/useLogin";
import usePathBuilder from "../../../../router/hooks/usePathBuilder";
import Button from "../../../visual-components/components/Button";
import ContextLink from "../../../common/components/ContextLink";

type Props = {
  isLoggedIn: boolean;
  searchTitle: string;
  fullSearchDescription: string;
};

const SavedSearch: React.FC<Props> = ({ isLoggedIn, searchTitle, fullSearchDescription }) => {
  const { login, register } = useLogin();
  const { savedSearchesPath } = usePathBuilder();
  return (
    <PopupContent
      headerIcon={{ icon: "bell" }}
      title={i18next.t("SAVED SEARCH")}
      body={
        <>
          <p className="saved__title">{searchTitle}</p>
          <div className="saved__parameter">{fullSearchDescription}</div>
        </>
      }
      footer={
        <>
          <div className="popup-footer__text">{i18next.t("MANAGE SAVED SEARCHES AND NOTIFICATIONS")}:</div>
          {isLoggedIn ? (
            <ContextLink className="link" to={savedSearchesPath()}>
              {i18next.t("SHOW ALL SAVED SEARCHES")}
            </ContextLink>
          ) : (
            <div className="btn-row">
              <Button onClick={register}>{i18next.t("REGISTER NOW FOR FREE")}</Button>
              <Button transparent onClick={login}>
                {i18next.t("LOGIN")}
              </Button>
            </div>
          )}
        </>
      }
    />
  );
};

export default SavedSearch;
