import { dataLayer } from "../../dataLayer";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { Page } from "../../../../router/constants/Page";

type AdClick = {
  event_name: "adclick";
  adclick: AdData;
};

type AdData = {
  ad_campaign: string;
  ad_name: string;
  ad_position?: string;
  ad_type: AdType;
};

export enum AdType {
  Hero = "hero",
  Tile = "tile",
}

export type AdClickTrackingEvent = BaseTrackingEvent & AdClick;

export const adClickTrack = ({
  pageType,
  adCampaign,
  adName,
  adType,
}: {
  pageType: Page | null;
  adCampaign: string;
  adName: string;
  adType: AdType;
}) => {
  dataLayer([
    {
      event: "adEvent",
      event_name: "adclick",
      page_type: pageType,
      adclick: {
        ad_campaign: adCampaign,
        ad_name: adName,
        ad_type: adType,
      },
    } satisfies AdClickTrackingEvent,
  ]);
};
