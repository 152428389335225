import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserQuery } from "../services/fusionAuthApi";
import { selectAccessTokenExpiration, selectIsLoggedIn, selectRefreshToken } from "../authSelectors";
import useEvent from "../../modules/common/hooks/useEvent";
import localStorageManager from "../../modules/common/global/localStorageManager";
import { STORAGE_KEYS } from "../../modules/common/constants/Storage";
import { loggedIn, LoggedInPayload, loggedOut } from "../authSlice";
import useFavoritesSync from "../../favorites/hooks/useFavoritesSync";
import { getNewAccessToken } from "../services/refreshAccessTokenSingleton";
import determineTokenRefreshInterval from "../helpers/determineTokenRefreshInterval";
import useComparisonSync from "../../comparison/hooks/useComparisonSync";

// ensures to keep the user logged in state up to date
const AuthStateHandler: React.FC = () => {
  const { refetch } = useUserQuery();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const accessTokenExpiryDate = useSelector(selectAccessTokenExpiration);

  const refreshToken = useSelector(selectRefreshToken);

  const reloadUserData = useEvent(() => {
    refetch();
  });

  const dispatch = useDispatch();

  // sync the user state across different tabs
  useEffect(() => {
    const listener = (data: LoggedInPayload) => {
      if (data === null) {
        dispatch(loggedOut());
      } else {
        dispatch(loggedIn(data));
      }
    };
    localStorageManager.registerSyncListener(STORAGE_KEYS.SESSION_TOKENS, listener);
    return () => {
      localStorageManager.unsubscribeSyncListener(STORAGE_KEYS.SESSION_TOKENS, listener);
    };
  });

  useEffect(() => {
    reloadUserData();
  }, [isLoggedIn, reloadUserData]);

  const pollNewRefreshToken = useEvent(() => {
    if (refreshToken) {
      getNewAccessToken(refreshToken, dispatch);
    }
  });

  useEffect(() => {
    if (isLoggedIn && accessTokenExpiryDate) {
      const refreshIn = determineTokenRefreshInterval(accessTokenExpiryDate);
      const timeout = setTimeout(
        () => {
          pollNewRefreshToken();
        },
        Math.max(refreshIn, 0),
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isLoggedIn, accessTokenExpiryDate, pollNewRefreshToken]);

  // ensure to keep favorites synced
  useFavoritesSync();

  // ensure to keep comparison synced
  useComparisonSync();

  return null;
};

export default AuthStateHandler;
